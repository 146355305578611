import * as React from 'react';
import Lang from "../../../common/lang/Lang";
import * as _ from 'lodash';
import {CONST} from "../../../common";
const moment = require("moment");
 
export const OrderCancelInfo = (props?: any) => {

  if(props.info.cancel){
  return (
    <div className="col-xl-4">
    <div className="kt-portlet kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title kt-font-success">
             {Lang.order_cancel}
          </h3>
        </div>               
      </div>
      <div className="kt-portlet__body">
        <div className="kt-widget4">
          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
               {Lang.message}
            </span>
            <span className="kt-widget4__number kt-font-info">{props.info.cancel.cancel_message}</span>
          </div>
          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
               {Lang.cancel_date}
            </span>
            <span className="kt-widget4__number kt-font-info">{moment(props.info.cancel.cancel_when).format(CONST.date_format)}</span>
          </div>   
          <div className="kt-widget4__item">                 
            <span className="kt-widget4__title kt-widget4__title--light">
              {Lang.cancel_by}
            </span>
            <span className="kt-widget4__number kt-font-success">{props.info.cancel.cancel_how}</span>
          </div>
          {props.info.cancel.is_charge === 1 && 
          <>
          <div className="kt-widget4__item">                 
            <span className="kt-widget4__title kt-widget4__title--light">
              {Lang.cancel_paid}
            </span>
            <span className="kt-widget4__number kt-font-success ">{Lang.yes}</span>
          </div>
          <div className="kt-widget4__item">                 
            <span className="kt-widget4__title kt-widget4__title--light">
              {Lang.cancel_amount}
            </span>
            <span className="kt-widget4__number kt-font-success">{props.info.cancel.amount}</span>
          </div>
          </>
          }
        </div>
      </div>
    </div>
  </div>     
 
  )
    } else {
       return null
    }
}