import React from "react";
import style from "../SkanderborgFeedback.module.scss";

const Star = ({ marked, starId }: { marked: boolean; starId: number }) => {
  return (
    <span data-star-id={starId} className={style["star"]} role="button">
      {marked ? "\u2605" : "\u2606"}
    </span>
  );
};

const StarRating = ({
  value,
  parentSetRating,
  className,
}: {
  value: number;
  parentSetRating: (newRating: number) => void;
  className?: string;
}) => {
  const [rating, setRating] = React.useState(value);
  const [selection, setSelection] = React.useState(0);
  const wrappedSetRating = (newRating: number) => {
    setRating(newRating);
    parentSetRating(newRating);
  };
  const hoverOver = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | null) => {
    let val = 0;
    if (event && event.target && Number((event.target as any).dataset.starId))
      val = Number((event.target as any).dataset.starId);
    setSelection(val);
  };
  return (
    <div
      onMouseOut={() => hoverOver(null)}
      onClick={(e) => wrappedSetRating(Number((e.target as any).dataset.starId) || rating)}
      onMouseOver={hoverOver}
      className={className}
    >
      {Array.from({ length: 5 }, (v, i) => (
        <Star
          starId={i + 1}
          key={`star_${i + 1}`}
          marked={selection ? selection >= i + 1 : rating >= i + 1}
        />
      ))}
    </div>
  );
};

export default StarRating;
