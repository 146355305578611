import * as React from 'react';
import {CreateBreadcrumb} from "./Data";
import Lang from "../../common/lang/Lang";
import API from './../../common/AxoisClient';
 import * as _ from 'lodash';
import {RouteComponentProps} from "react-router";
import {DepartmentValidations} from "./Validations";
import {CreateLayout} from "../../layout/CreateLayout";
import {DepartmentModel,CompanyModel} from "../../model";
import {EmailValues} from "../../model/Department";
import {SwitchLoader} from "../../common";
import {LoaderType} from "../../common/Loader";
import {CommonSubmitButton, showNotification, Select} from "../../common";
import {CountryFlags, Select2Wrapper} from "../../common/Select2";
import { CustomerBreadcrumb } from '../../users/customers/Data';
 



declare var window: any;

interface IState{
  form: {
  
  name : string,
  notify_email : string,
  company_id : number,
  status? : number,
  
};
company : Array<any>;
id:number;
uploadDocument?: File;
  
}

export class DepartmentCreate extends React.Component<RouteComponentProps, IState> {
  state: IState;
  
  constructor(props: any){
    super(props);

    

    this.state = {
      form: EmailValues(),
       
      id: props.match.params.id,
      company :[],
      uploadDocument : null,
    }
    DepartmentValidations();
     
  }

  
  
 componentDidMount() {
    
 
  API.get("settings/department/create").then(res => {
    this.setState({
      company: res.data
   })
 });
    

   }

   handleUpload = (e) => {
    this.state.uploadDocument = e.target.files[0];
    
  }

   handleChange=(event: React.ChangeEvent<HTMLElement>) => {
      const target : any = event.target;
        let value =  target.value;
        const name = target.name;
     
     this.setState({ form: { ...this.state.form, [name]: value} });
   }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#customer_form").valid()) {
    API.post('settings/department', this.state.form).then((res) => {
      this.props.history.push(`/settings/department`) 
      showNotification("success", Lang.department_create);
    })
  }   
  }

    resetForm = () =>{ 
        this.setState({
            form: {
                ...this.state.form,
                name : '',
                notify_email : '',
                company_id : 0,
                status : 0,
            },
        });
    }

 
  
  render() {
     
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'department'}>
            <form className="kt-form" id={'customer_form'} onSubmit={this.handleSubmit}>
                <div className="kt-portlet__body">
                    <div className={'row'}>
                        <div className={"col-md-12 col-lg-12"}>
                            <div className={'row'}>
                                <div className={"col-md-6 col-lg-6"}>
                                    <div className="form-group">
                                        <label>{Lang.company} <span className={'required'}>*</span></label>
                                        <Select className={'form-control'} onChange={this.handleChange}  id={'company_id'} name={'company_id'}>
                                            <option value={''}>{'Select Company'}</option>
                                            {this.state.company.map((item: CompanyModel, index: number) => { return <option key={index} value={item.id}>{item.name}</option>
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className={"col-md-6 col-lg-6"}>
                                    <div className="form-group">
                                        <label>{Lang.name} <span className={'required'}>*</span></label>
                                        <input type={'text'}  name="name" onChange={this.handleChange} className="form-control"  />
                                    </div>
                                </div>
                                <div className={"col-md-6 col-lg-6"}>
                                    <div className="form-group">
                                        <label>{Lang.email}</label>
                                        <input type={'text'}  name="notify_email" onChange={this.handleChange} className="form-control"  />
                                    </div>
                                </div>
                            </div>
                          
                            <div className='reset-btn-section'>
                                <CommonSubmitButton/>
                                <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn'} data-skin={'dark'} title={Lang.reset} onClick={this.resetForm}>{Lang.reset}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </CreateLayout>
   
    )
  }
}