import React, {useEffect, useState} from 'react';
import Lang from "../../common/lang/Lang";
import {SwitchLoader, SubmitButton} from "../../common";
declare let window: any;

export interface MyProfileDataUserProps {
    DataUser:any,
}

export function EditProfileImgPopup (props:any) {

    return (
        <div className="modal customModal fade" id="editProfileImagePopup">
            <div className="modal-dialog modal-dialog-centered modal-lg modal-ml">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="title mb-0">
                            <i className="far fa-edit"></i>
                            {Lang.image}
                        </h1>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                            <div className="row">
                                <div className={'col-md-12'}>
                                    <img className={'img-fluid'} id={"uploadedImage"} />
                                </div>
                            </div>
                            <div className="mt-4 btnSet text-center">
                               <SubmitButton className="btn btn-primary"  id={'updateProfilePictureOfUser'} label={Lang.submit}/>
                                {/* <button type={"button"} id={'updateProfilePictureOfUserHideButton'}className={'btn btn-green btn-xl hvr-rectangle-out hide-me'}>{Lang.please_wait}</button> */}
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
