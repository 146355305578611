import * as React from 'react';
import {ActivityLogsDataSchema} from "./Model";
import {IState as CommonState} from "../../common/State";
import {SearchInput, SearchSelect} from "../../common/form/SearchInput";
import {ShowRecords} from "../../common/ShowRecords";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {ExportButton, getQueryStringParams} from "../../common/Common";
import {actionButton} from "../../common/table/BlukActionButtonsEmailSms";
import {Icons} from "../../common/Icon";
import {Select2Wrapper} from "../../common/Select2";
import {default as Lang} from "../../common/lang/Lang";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb} from "./Data";
import axios from 'axios';
import API, {base_url} from "../../common/AxoisClient";
import ActivityLogFilter from './filter/ActivityLogFilter';
import { empty } from 'rxjs';
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";
import moment from 'moment';

interface IState extends CommonState {
    UserData: Array<ActivityLogsDataSchema>;
   }

export class ActivityLogs extends React.Component<RouteComponentProps,IState> {
    state: IState;
    records:React.RefObject<ShowRecords> = React.createRef();

    shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
      return  this.state !== nextState;
       
   }
  
      translatorList = async (params: any) => {
        const translator = await API.post('activitylogs/user-search-list', {params: params});
           return translator.data;
      }
  
      filterRecords = (data?: any) => {
        if( this.records && this.records.current !== null){
          if(data !== undefined){
            this.records.current.setFormFields(data);
          }else {
            this.records.current.setFormFields();
          }
        }
      }
    createMarkup =(content: string) => {
        return { __html: content };
    }

    showContent = (data: ActivityLogsDataSchema, index: number) => {
       if(data.message !== '') {
           let cols = [];
           cols.push(...[

               this.records.current.createdTd("id", index),
               this.records.current.createdTd("message", () => <span dangerouslySetInnerHTML={{ __html: data.message }}></span>),
               this.records.current.createdTd("action_by", () => {
                   return (data.users !== null) ? `${data.users.name} (${data.users.user_code})${ (data.users.role_id === 2 && data.users.company.is_special_company === 1) ? ` (Firma: ${data.users.company.name})` : '' }` : '-----';
               }),
               this.records.current.createdTd("ip", data.ip),
               this.records.current.createdTd("browser", data.browser),
               this.records.current.createdTd("action_at", moment(data.action_at).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss')),
           ]);
           cols = _.compact(cols);
           return (
               <tr key={list_key(data.id)}>{cols}</tr>
           );
       }
    }

   

    actions = (actionType: string) => {
        console.log('not available');
    }

    render() {
        return (
            <ListingLayout breadcrumb={ListBreadcrumb}>
              <ListHeader title={'activity_logs'} icon={Icons.activity_log} >
                <ExportButton records={this.records} fileName={Lang.exportActivityLogs}/>
               
              </ListHeader>
    
              <ListForm
                form={(
                    <ActivityLogFilter getResults={this.filterRecords} />
              )}
    
                table={(
    
                  <ShowRecords
                      url={"activitylogs/activity-logs"}
                      showRecords={this.showContent}
                      showCheckbox={false}
                      ref={this.records}
                    isNeedAction={true}
                    actionButtons={actionButton({records: this.records, 'url': "activitylogs/bulk-action"})}
                  />
              )}
              />
            </ListingLayout>
        )
      }
}
