import React, {useReducer} from 'react';
import {OrderFeedbackModel, OrderModel} from "../../../model";

// all actions for assign translator
export enum ActionType {
  SHOW_POPUP = 'SHOW_POPUP',
  HIDE_POPUP = 'HIDE_POPUP',
  HANDLE_CHANGE = 'HANDLE_CHANGE',
}

export interface PopupState  {
  show: boolean;
  order_data: OrderModel;
  history: Array<OrderFeedbackModel>
}

export interface CAction {
  type: ActionType;
  payload?: PopupState
}

export const InitialState: PopupState = {
  show: false,
  order_data: {} as OrderModel,
  history: []
};

// reducer for fix price popup

export function OrderFeedbackReducer <PopupState, CAction > (state, action) {
  switch (action.type) {
    // show popup and set order data
    case ActionType.SHOW_POPUP:
      return {...state, show: true, order_data:action.payload.data, history: action.payload.history};

    // hide popup and blank old state
    case ActionType.HIDE_POPUP:
      return {...state, ...InitialState};

    case ActionType.HANDLE_CHANGE:
      const {name, value} = action.payload;
      return {...state, form: {...state.form, [name]: value}};

    default:
      throw new Error();
  }
}