import React, {useState, useEffect} from 'react';
import { Modal } from 'react-bootstrap';
import Lang from '../../common/lang/Lang';
import { infoModalService } from '../../services';

const InfoModal = () => {
  const [Show, setShow] = useState<boolean>(false);
  const [Data, setData] = useState<string>('');
  useEffect(() => {
    infoModalService.getPopupState().subscribe((res: any) => {
      const {show, comment} = res;
      setShow(show);
      if(comment) setData(comment);
    })
  }, []);
  return (
    <Modal
      show={Show}
      onHide={() => {
        setShow(false);
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {Lang.hr_comment}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Data}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary">{Lang.close}</button>
      </Modal.Footer>
    </Modal>
  );
}

export default InfoModal;
