import React, { useState, useRef } from 'react';
import {default as Lang} from "../../common/lang/Lang";
import {GeneratePermitLink, Icons, showNotification} from "../../common";
import Swal from "sweetalert2";
import API from "../../common/AxoisClient";
import {Sendemail} from "../../services";

export function CustomerActions(props) {

  

  function approve(event: any,translator_id:number,status:number){
    Swal.fire({
      title: Lang.is_sure,text: Lang.sure_approve,type: 'warning',showCancelButton: true,confirmButtonColor: '#3085d6',cancelButtonColor: '#d33',confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value){
        API.post("users/translator/approve", {translator_id:translator_id, status:status}).then(response => {
          status===1?showNotification("success", Lang.success_approve):showNotification("success", Lang.success_unapprove);
        });
      }
    })
  }

  return (
        <span style={{overflow: 'visible', position: 'relative', width: '80'}}>
          <div className="dropdown">
            <a data-toggle="dropdown" className="btn btn-sm btn-clean btn-icon btn-icon-md" aria-expanded="false">
              <i className="flaticon-more-1"/>
            </a>
            <div className="dropdown-menu dropdown-menu-right"  x-placement="bottom-end">
              <ul className="kt-nav">
               
              <li className="kt-nav__item">
                  <GeneratePermitLink className="kt-nav__link" scope={'customers.index'} to={'#'} onClick={() => Sendemail(props.customer_data)}>
                      <i className={`${Icons.email}`} />&nbsp;&nbsp;<span className="kt-nav__link-text">{Lang.send_login_d_btn}</span>
                  </GeneratePermitLink>
                </li>

                <li className="kt-nav__item">
                  <GeneratePermitLink className="kt-nav__link" scope={'customers.index'} to={'#'}>
                      <i className={`${Icons.contract}`} />&nbsp;&nbsp;<span className="kt-nav__link-text" onClick={props.showTPop} >{ Lang.block_translator_users}
                      </span>
                    </GeneratePermitLink>
                </li>
              </ul>
               {props.SuperCustomer_data === 1 &&
                <li className="kt-nav__item">
                  <GeneratePermitLink className="kt-nav__link" scope={'customers.index'} to={'#'}>
                      <i className={`${Icons.users}`} />&nbsp;&nbsp;<span className="kt-nav__link-text" onClick={props.showSPop} >{ Lang.super_customer}
                      </span>
                    </GeneratePermitLink>
                </li>
              }
              <hr />
              <Action icon={Icons.email} onClick={props.sendConfirmationEmail} label="Send Confirmation Email" />
              <Action icon={Icons.password} onClick={props.sendPasswordReset} label="Send Password Reset" />
              <Action icon={Icons.tick_mark} onClick={props.verifyEmail} label="Verify Email" />
              <Action icon={Icons.password2} onClick={props.unblockLogin} label="Unblock User" />
            </div>
          </div>
        </span>

  )
}

interface ActionProps {
  icon: string
  onClick: () => void
  label: string
  scope?: string
  to?: string
}

const Action = ({ icon, onClick, label, scope = "customers.index", to = "#" }: ActionProps) => {
  const [Loading, setLoading] = useState(false)
  const ref = useRef(null)
  return (
    <li className="kt-nav__item">
      <GeneratePermitLink className="kt-nav__link" scope={scope} to={to}>
        <i className={icon} /> &nbsp;&nbsp; 
        <span className="kt-nav__link-text" onClick={onClick} >
          { label }
        </span>
      </GeneratePermitLink>
    </li>
  )
}