import React from "react";
import {
  CompanyModel,
  ClientData,
  OrderModel,
  OrderStatus,
  OrderTranslatorGender,
  OrderType,
  UserModel,
} from "../model";
import { default as Lang } from "../common/lang/Lang";
import {
  ActionLink,
  EditLink,
  Icons,
  is_permit,
  GeneratePermitLink,
  showNotification,
} from "../common";
import {
  getDistanceTravelTime,
  OrderCorrectionService,
  removeTranslator,
  showOfficeNoteMessage,
  showTelephoneMessage,
  submitSpecialStatus,
} from "../services";
import _, { isEmpty } from "lodash";
import { OrdersinfoService } from "../services";
import { Link } from "react-router-dom";
import { OrderActions } from "../orders/orders/OrderActions";
import API from "../common/AxoisClient";
import "moment/min/locales";
import { type } from "jquery";
import { NumberLiteralType } from "typescript";
const moment = require("moment");
declare let window: any;

export function get_order_gender_and_color(data: OrderModel) {
  let color: string = "",
    gender: string = "";

  switch (data.translator_gender) {
    case OrderTranslatorGender.NOSIGNIFICANE:
      color = "black";
      gender = ""; //Lang.no_significance
      break;

    case OrderTranslatorGender.MALE:
      color = "blue";
      gender = Lang.man;
      break;

    case OrderTranslatorGender.FEMALE:
      color = "red";
      gender = Lang.woman;
      break;
  }

  return { gender: gender, color: color };
}

export function Usergender(data: OrderModel) {
  let bold, fontsize, fcolor;
  bold = "bold";
  fcolor = "red";
  fontsize = "15px";
  /*if(data.translator_gender === OrderTranslatorGender.NOSIGNIFICANE){
 return <span className={'label'} style={{fontWeight: bold}}>{Lang.no_significance}</span>
} else*/ if (data.translator_gender === OrderTranslatorGender.MALE) {
    return (
      <Link to={"#"}>
        <i className={Icons.male} style={{ fontSize: fontsize }} />{" "}
      </Link>
    );
  } else if (data.translator_gender === OrderTranslatorGender.FEMALE) {
    return (
      <Link to={"#"}>
        <i className={Icons.female} style={{ color: fcolor, fontSize: fontsize }} />
      </Link>
    );
  }
}

export function Usertranslategender(data: OrderModel) {
  let bold, fontsize, fcolor, maleiconcolor;
  bold = "bold";
  fcolor = "red";
  maleiconcolor = "blue";
  fontsize = "20px";
  if (data.translate_by) {
    if (data.translate_by.gender === OrderTranslatorGender.MALE) {
      return (
        <Link to={"#"}>
          <i className={Icons.male} style={{ color: maleiconcolor, fontSize: fontsize }} />{" "}
        </Link>
      );
    } else if (data.translate_by.gender === OrderTranslatorGender.FEMALE) {
      return (
        <Link to={"#"}>
          <i className={Icons.female} style={{ color: fcolor, fontSize: fontsize }} />
        </Link>
      );
    }
  }
}

export function SpecificUsergender(data: OrderModel) {
  let bold, fontsize, fcolor;
  bold = "bold";
  fcolor = "red";
  fontsize = "15px";
  if (data.specific_translator) {
    if (data.specific_translator.gender === OrderTranslatorGender.MALE) {
      return (
        <Link to={"#"}>
          <i className={Icons.male} style={{ fontSize: fontsize }} />{" "}
        </Link>
      );
    } else if (data.specific_translator.gender === OrderTranslatorGender.FEMALE) {
      return (
        <Link to={"#"}>
          <i className={Icons.female} style={{ color: fcolor, fontSize: fontsize }} />
        </Link>
      );
    }
  }
}

export function SpecificTranslatorNamecolor(data: OrderModel) {
  let bold, fontsize, fcolor, maleiconcolor;
  bold = "bold";
  fcolor = "red";
  maleiconcolor = "blue";
  fontsize = "15px";

  let returnIcon: any = "";
  let returnName: any = "";
  let agreedText: any = "";
  let notSpecificName: any = "";
  if (!_.isEmpty(data.translator_gender) && data.translator_gender !== "0") {
    if (data.translator_gender === OrderTranslatorGender.MALE) {
      returnIcon = (
        <i
          data-toggle="kt-tooltip"
          title="Male"
          className="fa fa-male"
          style={{ fontSize: "20px", color: maleiconcolor }}
          data-original-title="Male"
        ></i>
      );
    } else {
      returnIcon = (
        <i
          data-toggle="kt-tooltip"
          title="Female"
          className="fa fa-female"
          style={{ fontSize: "20px", color: fcolor }}
          data-original-title="Female"
        ></i>
      );
    }
  }

  if (!_.isEmpty(data.specific_translator)) {
    if (data.specific_translator.gender === OrderTranslatorGender.MALE) {
      returnName = (
        <span style={{ color: maleiconcolor }}>
          <br />
          <br />
          {data.specific_translator.name}
        </span>
      );
    } else if (data.specific_translator.gender === OrderTranslatorGender.FEMALE) {
      returnName = (
        <span style={{ color: fcolor }}>
          <br />
          <br />
          {data.specific_translator.name}
        </span>
      );
    }

    if (!_.isNull(data.is_talk_trans)) {
      if (data.is_talk_trans === 1) {
        agreedText = (
          <span>
            <br />
            <br />
            {"AFTALT"}
          </span>
        );
      } else if (data.is_talk_trans === 0 && data.is_assign_other === 1) {
        agreedText = (
          <span>
            <br />
            <br />
            {"Ønsker - andre OK"}
          </span>
        );
      } else if (data.is_talk_trans === 0 && data.is_assign_other === 2) {
        agreedText = (
          <span>
            <br />
            <br />
            {"Ønsker - KUN"}
          </span>
        );
      }
    }
  }

  if (!_.isEmpty(data.not_specific_translator)) {
    if (data.not_specific_translator.gender === OrderTranslatorGender.MALE) {
      notSpecificName = (
        <span>
          <br />
          <br />
          <span style={{ color: maleiconcolor }}>{data.not_specific_translator.name}</span>
          <br />
          {"Ønsker IKKE"}
        </span>
      );
    } else if (data.not_specific_translator.gender === OrderTranslatorGender.FEMALE) {
      notSpecificName = (
        <span>
          <br />
          <br />
          <span style={{ color: fcolor }}>{data.not_specific_translator.name}</span>
          <br />
          {"Ønsker IKKE"}
        </span>
      );
    }
  }

  return (
    <>
      {returnIcon}
      {returnName}
      {agreedText}
      {notSpecificName}
    </>
  );
}

export function copyCodeToClipboard(data: OrderModel) {
  var customerAddress = "";
  var translateAddress = "";
  var departmentName = "";
  if (!_.isEmpty(data.customer)) {
    /*if(data.customer.old_c_id !== null && data.customer.old_c_id !== ''){
			customerAddress = data.customer.address+"  "+ data.customer.city + ' - ' + data.customer.zip_code;
		}else{
		customerAddress = data.customer.address;
		}*/

    customerAddress = data.customer.address;
  }

  if (!_.isEmpty(data.translate_by)) {
    /*if(data.translate_by.old_t_id !== null && data.translate_by.old_t_id !== ''){
			translateAddress = data.translate_by.address+"  "+ data.translate_by.city + ' - ' + data.translate_by.zip_code;
		}else{
		 translateAddress = data.translate_by.address;
		}*/
    translateAddress = data.translate_by.address;
  }

  if (!_.isEmpty(data.department) && data.department.name !== "-") {
    var strR = data.department.name;
    departmentName = strR.replace("-", "");
  }

  var textField = document.createElement("textarea");
  if (_.lowerCase(data.company.email).search("redcross.dk") >= 0) {
    const order_no = data.order_no;
    const order_to_language = ` - ${data.language_to.language_name}`;
    const location_code = !_.isEmpty(data.department_unit.location_code)
      ? " - " + data.department_unit.location_code
      : "";
    const other_location_code = !_.isEmpty(data.department_unit.location_code_2)
      ? " - " + data.department_unit.location_code_2
      : "";
    const project_code = !_.isEmpty(data.project) ? " - " + data.project.project_code : "";
    const contact_person_name = !_.isEmpty(data.contact_persons)
      ? " - " + data.contact_persons[0].name
      : "";
    textField.innerHTML =
      order_no +
      order_to_language +
      location_code +
      other_location_code +
      project_code +
      contact_person_name;
  } else {
    let relating_to: string;
    if (!_.isEmpty(data.clients)) {
      relating_to = data.clients
        .map((client: ClientData, index: number) => {
          return `${index + 1}) ${client.client_name} CPR-nr. ${client.client_cpr};`;
        })
        .join(" ");
    } else if (data.platform === 'app') {
      relating_to = `${data.client_name}  CPR-nr. ${data.client_cpr}`;
    } else if (data.customer.name !== null) {
      relating_to = `${data.customer.name === "" ? "----" : data.customer.name}`;
    }
    console.log("order: ", data);

    textField.innerHTML = `
Rekvisition nr: ${data.order_no} Dato: ${moment(data.order_date).format("DD-MM-YYYY")} Kl.: ${
      data.translation_type_id === OrderType.DOCUMENT
        ? data.complete_order_to
        : data.order_from !== null
        ? data.order_from
        : ""
    } - ${data.complete_order_to !== null ? data.complete_order_to : ""}
Rekvirent: ${!_.isEmpty(data.contact_persons) ? data.contact_persons[0].name : ""}, ${
      !_.isEmpty(data.company) ? data.company.name : ""
    } ${departmentName.trim()},
${customerAddress}
Tolkensnavn: ${!_.isEmpty(data.translate_by) ? data.translate_by.name : ""} ${
      data.translation_type_id === OrderType.PHYSICAL ? "Kørt fra: " : ""
    }
${translateAddress} ${
      data.meeting_address !== null && data.meeting_address !== ""
        ? "\r\nSted: " + data.meeting_address
        : ""
    }
Vedr.: ${relating_to}
Sprog: ${data.language_from.language_name} - ${order_to_language(data)} Type: ${
      !_.isEmpty(data.translation_type) ? data.translation_type.title : ""
    }
${
  !_.isEmpty(data.created_at)
    ? "Godkendt: " + moment(data.created_at).format("DD-MM-YYYY HH:mm")
    : ""
}
${
  !isEmpty(data.skanderborg_acceptance_status)
    ? `Godkendt af rekvirenten: ${data.feedback.updated_at}`
    : !isEmpty(data.feedback)
    ? `Evaluereret: ${data.feedback.updated_at}`
    : ""
}
`;
  }
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
  showNotification("success", Lang.copy_to_clipboard);
}

export function get_order_address(data: OrderModel) {
  if (data.translation_type_id === OrderType.VIDEO && data.schedule_team_meeting)
    return (
      <span>
        Microsoft Teams{" "}
        {!isEmpty(data.video_meeting_link) ? (
          <a target="blank" href={data.video_meeting_link}>
            (<i className={Icons.external_link} />)
          </a>
        ) : (
          <span>(ingen link endnu)</span>
        )}
      </span>
    );
  if (!_.isEmpty(data.meeting_city)) {
    return data.translation_type_id === OrderType.PHYSICAL ||
      data.translation_type_id === OrderType.SIGN_LANGUAGE
      ? data.meeting_address + ", " + data.meeting_zip_code + " " + data.meeting_city
      : data.address;
  } else {
    return data.translation_type_id === OrderType.PHYSICAL ||
      data.translation_type_id === OrderType.SIGN_LANGUAGE
      ? data.meeting_address
      : data.address;
  }
}

export function translator_name_and_gender(data: OrderModel) {
  let translator: string = "",
    gender: string = "",
    color: string = "";
  if (!_.isEmpty(data.translate_by)) {
    translator = data.translate_by.name;
    switch (data.translate_by.gender) {
      case OrderTranslatorGender.MALE:
        color = "blue";
        gender = Lang.man;
        break;

      case OrderTranslatorGender.FEMALE:
        color = "#f1889a";
        gender = Lang.woman;
        break;
    }
  }
  return { name: translator, gender: gender, color: color };
}

export function order_to_language(data: OrderModel) {
  return data.is_dumb === 0
    ? data.language_to !== null
      ? data.language_to.language_name
      : ""
    : "Sign Order";
}

export function order_time(data: OrderModel) {
  return data.translation_type_id === OrderType.DOCUMENT ||
    data.translation_type_id === OrderType.PHONE_MESSAGE
    ? data.order_to
    : data.order_from + " - " + data.order_to;
}

export function complete_order_time(data: OrderModel) {
  if (data.complete_order_to) {
    return data.translation_type_id === OrderType.DOCUMENT ||
      data.translation_type_id === OrderType.PHONE_MESSAGE
      ? data.complete_order_to
      : data.order_from + " - " + data.complete_order_to;
  }
}

export function chk_late_order(order: OrderModel) {
  if (order.translation_type_id == 5 || order.translation_type_id == 6) {
    return null;
  }
  var previousDate: string;
  var nextDate: string;
  var orderTime: string;
  if (order.order_from) {
    orderTime = order.order_from;
  }
  if (order.complete_order_to && order.status == 5) {
    orderTime = order.complete_order_to;
  } else if (order.order_to) {
    orderTime = order.order_to;
  }

  var d = new Date(order.order_date + " " + orderTime);
  if (parseInt(orderTime) >= 0 && parseInt(orderTime) <= 8) {
    d.setDate(d.getDate() - 1);
  }
  var setYear = d.getFullYear();
  var setMonth: any;
  if (d.getMonth() + 1 <= 9) {
    setMonth = "0" + (d.getMonth() + 1);
  } else {
    setMonth = d.getMonth() + 1;
  }
  var setDate: any;
  if (d.getDate() <= 9) {
    setDate = "0" + d.getDate();
  } else {
    setDate = d.getDate();
  }
  previousDate = setYear + "-" + setMonth + "-" + setDate;
  var d2 = new Date(d.getTime() + 86400000);
  var setYear2 = d2.getFullYear();
  var setMonth2: any;
  if (d2.getMonth() + 1 <= 9) {
    setMonth2 = "0" + (d2.getMonth() + 1);
  } else {
    setMonth2 = d2.getMonth() + 1;
  }
  var setDate2: any;
  if (d2.getDate() <= 9) {
    setDate2 = "0" + d2.getDate();
  } else {
    setDate2 = d2.getDate();
  }
  nextDate = setYear2 + "-" + setMonth2 + "-" + setDate2;

  if (
    Date.parse(order.order_date + " " + orderTime) > Date.parse(previousDate + " " + "17:00") &&
    Date.parse(order.order_date + " " + orderTime) <= Date.parse(nextDate + " " + "08:00")
  ) {
    return <i className={`kt-nav__link-icon ${Icons.moon}`} title="Late Order" />;
  }
}

export function orderContentShow(records: any, data: OrderModel, index: number, callback?: any) {
  let cols = [];

  cols.push(
    ...[
      // <td key={index}>{records.current.checkbox(data.id)}</td>,
      records.current.createdTd("special_status", () => {
        return (
          <div>
            {data.special_status === 0 && (
              <div>
                <a
                  href="javascript:void(0)"
                  className={"btn btn-default w-6rem"}
                  onClick={() => submitSpecialStatus(data.id, callback)}
                  data-skin={"dark"}
                  title="Mark"
                >
                  Mark
                </a>
              </div>
            )}
            {data.special_status === 1 && (
              <div>
                <a
                  href="javascript:void(0)"
                  className={"btn btn-default w-6rem"}
                  onClick={() => submitSpecialStatus(data.id, callback)}
                  data-skin={"dark"}
                  title="Unmark"
                >
                  Afmærk
                </a>
                <span
                  className="badge badge-info special-status-badge w-6rem"
                  title={"Denne admin har reserveret denne ordre"}
                >
                  {data.special_status_update_user.name}
                </span>
                {/* <label style={{backgroundColor: 'orange','color':'white',padding:'10px', textAlign:'center'}}>{data.special_status_update_user.name}</label> */}
              </div>
            )}
          </div>
        );
      }),
      records.current.createdTd("id", index),
      records.current.createdTd("order_no", () => {
        return (
          <div>
            <button
              className={"btn btn-default"}
              onClick={() => OrdersinfoService.ordersinfodata(data)}
              data-skin={"dark"}
              title={Lang.view_order}
            >
              {data.order_no}
            </button>
            {parseFloat(data.time_difference) < 48 && (
              <p>
                <span style={{ color: "red", fontSize: 25 }}> AKUT </span>
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                  style={{ color: "red", fontSize: 25 }}
                />
              </p>
            )}
            <span>
              {data.claim !== null &&
              data.claim[0] !== undefined &&
              data.claim[0].order_id !== "" ? (
                <span className="badge badge-info">claimed</span>
              ) : (
                ""
              )}
            </span>
            <br />
            <br />

            {data.translation_type.title}
            {data.translation_type_id === OrderType.TELEPHONE && (
              <p>
                <i
                  className="fa fa-phone"
                  aria-hidden="true"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    border: "1px solid grey",
                    padding: "5px",
                    color: "green",
                    fontSize: "15px",
                  }}
                />
              </p>
            )}
            {data.translation_type_id === OrderType.PHONE_MESSAGE && (
              <p>
                <button
                  type={"button"}
                  className="btn btn-danger"
                  onClick={() => showTelephoneMessage(data.tel_message)}
                >
                  {Lang.see_message}{" "}
                </button>
              </p>
            )}
            {data.translation_type_id === OrderType.VIDEO && (
              <p>
                <i
                  className="fa fa-laptop"
                  aria-hidden="true"
                  style={{ color: "red", fontSize: "39" }}
                />
              </p>
            )}
            <br />
            {(data.office_note || data.comment_for_translator) && (
              <p>
                <button
                  type={"button"}
                  className="btn btn-danger"
                  onClick={() =>
                    showOfficeNoteMessage(data.office_note, data.comment_for_translator)
                  }
                >
                  {Lang.remark}{" "}
                </button>
              </p>
            )}
            {!_.isEmpty(data.fix_rates) && (
              <p>
                <br />
                <i
                  className="fa fa-tags"
                  title="Fix Price"
                  aria-hidden="true"
                  style={{ color: "red", fontSize: "39" }}
                />
              </p>
            )}
          </div>
        );
      }),

      records.current.createdTd("language", () => {
        const { gender, color } = get_order_gender_and_color(data);
        const to_lang = order_to_language(data);
        let indexOfLast = data.clients.length - 1;
        let clients_array: Array<JSX.Element> = [];
        clients_array = data.clients.map((client, index) => {
          return (
            <React.Fragment key={index}>
              {index + 1}) {client.client_name === "" ? "----" : client.client_name}
              {index === indexOfLast ? "" : " "}
            </React.Fragment>
          );
        });
        if(data.platform == 'app') {
          clients_array = [<React.Fragment>1) {data.client_name} </React.Fragment>];
        }
        return (
          <>
            <div style={{ width: "200px" }}>
              {!_.isEmpty(data.language_from) && (
                <React.Fragment>
                  <img
                    src={
                      window.PUBLIC_URL +
                      "/admin/assets/flags/" +
                      _.lowerCase(data.language_from.country.iso) +
                      ".png"
                    }
                    className="img-flag"
                  />
                  &nbsp;{data.language_from.language_name}
                  <span className="exchangeIcon">
                    {" "}
                    <i className="fas fa-exchange-alt" />{" "}
                  </span>
                </React.Fragment>
              )}
              {data.is_dumb === 0 && !_.isEmpty(data.language_to) && (
                <img
                  src={
                    window.PUBLIC_URL +
                    "/admin/assets/flags/" +
                    _.lowerCase(data.language_to.country.iso) +
                    ".png"
                  }
                  className="img-flag"
                />
              )}
              &nbsp;{to_lang}
            </div>
            <br />

            {!_.isEmpty(data.clients) !== null && (
              <span>
                {Lang.client_name} : {clients_array}
              </span>
            )}
          </>
        );
      }),
      records.current.createdTd("order_date", () => {
        var order_date = moment(data.order_date);
        order_date.locale("da");
        return (
          <>
            {" "}
            {order_date.format("dddd")} <br /> <strong>{order_date.format("DD-MM-YYYY")}</strong>{" "}
          </>
        );
      }),
      records.current.createdTd("order_time", () => {
        return <strong> {order_time(data)} </strong>;
      }),
      records.current.createdTd("company_id", () => {
        return (
          <>
            {OrderHelper.getCompany(data)} / {OrderHelper.getDept(data)} /{" "}
            {OrderHelper.getDeptUnit(data)} <br />
            <hr></hr>
            {/* <p style={{fontWeight: 100}}>{!_.isEmpty(data.contact_persons) &&data.contact_persons[0].name}</p> */}
            <ol className={"list-none"}>
              {!_.isEmpty(data.contact_persons) &&
                data.contact_persons.map((item, index) => {
                  /*return (
                   <li key={index}>{item.name} | {item.phone_no} </li>
               )*/
                  var phone_no = "N/A";
                  if (item.phone_no) {
                    phone_no = item.phone_no;
                  }
                  var withSpace = phone_no.match(/.{1,2}/g);
                  return (
                    <>
                      <span key={index}>
                        {" "}
                        <strong>{item.name}</strong> <br /> <strong>{withSpace.join(" ")}</strong>{" "}
                      </span>
                      <br />
                      {index === 0 && data.contact_persons.length > 1 && (
                        <span>
                          -------------
                          <br />
                        </span>
                      )}
                    </>
                  );
                })}
            </ol>
          </>
        );
      }),

      records.current.createdTd("address", () => {
        return get_order_address(data);
      }),
      records.current.createdTd("translator_id", () => {
        const { color, name } = translator_name_and_gender(data);
        if (!_.isEmpty(data.translate_by) && data.translate_by.mobile_no) {
          var phone_no = data.translate_by.mobile_no;
          var withSpace = phone_no.match(/.{1,2}/g);
        }
        return (
          <>
            <p style={{ color: color }}>{name}</p>
            {data.status === OrderStatus.NEW_ORDER && !_.isNull(data.is_ever_assigned) && (
              <>
                <br />
                <br />
                <p>Tolken er fjernet</p>
                <p>
                  {data.last_assigned_date !== undefined &&
                    !_.isNull(data.last_assigned_date) &&
                    $.trim(data.last_assigned_date) != "" && (
                      <>{moment(data.last_assigned_date).format("DD-MM-YYYY HH:mm")}</>
                    )}
                </p>
              </>
            )}
            {data.status === OrderStatus.TRANS_ASSIGNED && (
              <>
                {!_.isEmpty(data.translate_by) && <strong>{withSpace.join(" ")}</strong>}
                <br />
                {is_permit("orders.remove-translator") && (
                  <button
                    type={"button"}
                    className="btn btn-danger"
                    onClick={() => removeTranslator(data.id, data.translator_id, callback)}
                  >
                    {" "}
                    {Lang.remove_translator}
                  </button>
                )}
              </>
            )}
            {data.status === OrderStatus.TRANS_ASSIGNED &&
              data.translator_id !== null &&
              data.sms_accept[0] !== undefined && (
                <>
                  <br />
                  <br />
                  <span className="badge badge-info">Accepted by SMS</span>
                </>
              )}

            {data.status === OrderStatus.INVITATION_SENT && (
              <>
                <br />
                <br />
                <p style={{ color: "red" }}>Send ud (igen)</p>
                <p>
                  {data.invitations[0] !== undefined &&
                    !_.isNull(data.invitations[0].created_at) &&
                    $.trim(data.invitations[0].created_at) != "" && (
                      <>{moment(data.invitations[0].created_at).format("DD-MM-YYYY HH:mm")}</>
                    )}
                </p>
              </>
            )}
            <br />
            <br />
            {data.status === OrderStatus.TRANS_ASSIGNED &&
              data.specific_translator_id !== null &&
              data.is_talk_trans !== 0 && <span className="badge badge-info">Customer</span>}
            {data.status === OrderStatus.TRANS_ASSIGNED && data.invitations[0] !== undefined && (
              <span className="badge badge-info">Translator</span>
            )}
            {data.status === OrderStatus.TRANS_ASSIGNED &&
              data.is_talk_trans === 0 &&
              data.translator_id !== null &&
              data.invitations[0] === undefined && <span className="badge badge-info">Admin</span>}
          </>
        );
      }),
      records.current.createdTd("preference", () => {
        return (
          <>
            {SpecificTranslatorNamecolor(data)}
            <br />
          </>
        );
      }),

      records.current.createdTd("status", () => {
        if (data.status === OrderStatus.NEW_ORDER) {
          return <span className={"badge badge-danger"}>{Lang.order_new_order}</span>;
        }
        if (data.status === OrderStatus.INVITATION_SENT) {
          return <span className={"badge badge-warning"}>{Lang.order_invitation_sent}</span>;
        }
        if (data.status === OrderStatus.TRANS_ASSIGNED) {
          return <span className={"badge badge-success"}>{Lang.order_assigned}</span>;
        }
        if (data.status === OrderStatus.CANCELLED) {
          return <span className={"badge badge-warning"}>{Lang.order_cancelled}</span>;
        }
        if (data.status === OrderStatus.COMPLETED || data.status === OrderStatus.PAID) {
          return <span className={"badge badge-warning"}>{Lang.order_complete}</span>;
        }
        if (data.status === OrderStatus.UNDER_QA) {
          return <span className={"badge badge-warning"}>{Lang.under_qa}</span>;
        }
        /*if(data.status === OrderStatus.PAID){
        return <span className={'badge badge-warning'}>{Lang.order_paid}</span>;
      }*/
      }),
      records.current.createdTd("created_at", () => {
        var created_date = moment(data.created_at);
        created_date.locale("da");
        var platform = data.platform;
        if (data.ref_source && data.ref_source == "COMDIA") {
          platform = "COMDIA" + ": " + data.ref_id;
        }
        if (!platform) {
          platform = "web";
        }
        return (
          <>
            {" "}
            {created_date.format("dddd")} <br /> {created_date.format("DD-MM-YYYY HH:mm:ss")} <br />{" "}
            {data.created_by?.name ?? "N/A"} <br />
            <br /> <strong>From:</strong> {platform}
          </>
        );
      }),
    ]
  );
  return cols;
}

export function orderRequestContentShow(
  records: any,
  data: OrderModel,
  index: number,
  callback?: any
) {
  let cols = [];
  cols.push(
    ...[
      // <td key={index}>{records.current.checkbox(data.id)}</td>,
      records.current.createdTd("special_status", () => (
        <div>
          <div>
            <a
              href="javascript:void(0)"
              className={"btn btn-default w-6rem"}
              onClick={() => submitSpecialStatus(data.id, callback)}
              data-skin={"dark"}
              title="Mark"
            >
              {data.special_status === 0 ? "Mark" : "Afmærk"}
            </a>
            {data.special_status === 1 && (
              <span
                className="badge badge-info special-status-badge w-6rem"
                title={"Denne admin har reserveret denne ordre"}
              >
                {data.special_status_update_user.name}
              </span>
            )}
          </div>
        </div>
      )),
      records.current.createdTd("id", index),
      records.current.createdTd("order_no", () => {
        return (
          <div>
            <button
              className={"btn btn-default"}
              onClick={() => OrdersinfoService.ordersinfodata(data)}
              data-skin={"dark"}
              title={Lang.view_order}
            >
              {data.order_no}
            </button>
            {parseFloat(data.time_difference) < 48 && (
              <p>
                <span style={{ color: "red", fontSize: 25 }}> AKUT </span>
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                  style={{ color: "red", fontSize: 25 }}
                />
              </p>
            )}
            <span>
              {data.claim !== null &&
              data.claim[0] !== undefined &&
              data.claim[0].order_id !== "" ? (
                <span className="badge badge-info">claimed</span>
              ) : (
                ""
              )}
            </span>
            <br />
            <br />

            {data.translation_type.title}
            {data.translation_type_id === OrderType.TELEPHONE && (
              <p>
                <i
                  className="fa fa-phone"
                  aria-hidden="true"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    border: "1px solid grey",
                    padding: "5px",
                    color: "green",
                    fontSize: "15px",
                  }}
                />
              </p>
            )}
            {data.translation_type_id === OrderType.PHONE_MESSAGE && (
              <p>
                <button
                  type={"button"}
                  className="btn btn-danger"
                  onClick={() => showTelephoneMessage(data.tel_message)}
                >
                  {Lang.see_message}{" "}
                </button>
              </p>
            )}
            {data.translation_type_id === OrderType.VIDEO && (
              <p>
                <i
                  className="fa fa-laptop"
                  aria-hidden="true"
                  style={{ color: "red", fontSize: "39" }}
                />
              </p>
            )}
            <br />
            {(data.office_note || data.comment_for_translator) && (
              <p>
                <button
                  type={"button"}
                  className="btn btn-danger"
                  onClick={() =>
                    showOfficeNoteMessage(data.office_note, data.comment_for_translator)
                  }
                >
                  {Lang.remark}{" "}
                </button>
              </p>
            )}
          </div>
        );
      }),
      records.current.createdTd("language", () => {
        const { gender, color } = get_order_gender_and_color(data);
        const to_lang = order_to_language(data);
        let clients_array: Array<JSX.Element> = [];
        clients_array = data.clients.map((client, index) => {
          return (
            <React.Fragment key={index}>
              {index + 1}) {client.client_name === "" ? "----" : client.client_name}
              {index === data.clients.length - 1 ? "" : " "}
            </React.Fragment>
          );
        });
        if(data.platform == 'app') {
          clients_array = [<React.Fragment>1) {data.client_name} </React.Fragment>];
        }
        return (
          <>
            <div style={{ width: "200px" }}>
              {!_.isEmpty(data.language_from) && (
                <React.Fragment>
                  <img
                    src={
                      window.PUBLIC_URL +
                      "/admin/assets/flags/" +
                      _.lowerCase(data.language_from.country.iso) +
                      ".png"
                    }
                    className="img-flag"
                  />
                  &nbsp;{data.language_from.language_name}
                  <span className="exchangeIcon">
                    {" "}
                    <i className="fas fa-exchange-alt" />
                  </span>
                </React.Fragment>
              )}
              {data.is_dumb === 0 && !_.isEmpty(data.language_to) && (
                <img
                  src={
                    window.PUBLIC_URL +
                    "/admin/assets/flags/" +
                    _.lowerCase(data.language_to.country.iso) +
                    ".png"
                  }
                  className="img-flag"
                />
              )}
              &nbsp;{to_lang}
            </div>
            <br />

            {!_.isEmpty(data.clients) !== null && (
              <span>
                {Lang.client_name} : {clients_array}
              </span>
            )}
          </>
        );
      }),
      records.current.createdTd("order_date", () => {
        var order_date = moment(data.order_date);
        order_date.locale("da");
        return (
          <>
            {" "}
            {order_date.format("dddd")} <br /> {order_date.format("DD-MM-YYYY")}{" "}
          </>
        );
      }),
      records.current.createdTd("order_time", () => {
        return order_time(data);
      }),
      records.current.createdTd("company_id", () => {
        return (
          <>
            {OrderHelper.getCompany(data)} / {OrderHelper.getDept(data)} /{" "}
            {OrderHelper.getDeptUnit(data)} <br />
            <hr></hr>
            {/* <p style={{fontWeight: 100}}>{!_.isEmpty(data.contact_persons) &&data.contact_persons[0].name}</p> */}
            {/*<ol className={'list-none'}>
         { ! _.isEmpty(data.contact_persons) && data.contact_persons.map((item, index) => {
          return (
            <>
             <li key={index}>{item.name} | {item.phone_no} </li>
            </>
          )
         })
       }
       </ol>*/}
            {!_.isEmpty(data.contact_persons) &&
              data.contact_persons.map((item, index) => {
                return (
                  <>
                    <span key={index}>
                      {" "}
                      {item.name} <br /> {item.phone_no}{" "}
                    </span>
                    <br />
                    {index === 0 && data.contact_persons.length > 1 && (
                      <span>
                        -------------
                        <br />
                      </span>
                    )}
                  </>
                );
              })}
          </>
        );
      }),
      records.current.createdTd("address", () => {
        return get_order_address(data);
      }),
      records.current.createdTd("translator_id", () => {
        const { color, name } = translator_name_and_gender(data);
        return (
          <>
            <p style={{ color: color }}>{name}</p>
            {data.status === OrderStatus.TRANS_ASSIGNED && (
              <>
                {!_.isEmpty(data.translate_by) && data.translate_by.mobile_no}
                <br />
                {is_permit("orders.remove-translator") && (
                  <button
                    type={"button"}
                    className="btn btn-danger"
                    onClick={() => removeTranslator(data.id, data.translator_id, callback)}
                  >
                    {" "}
                    {Lang.remove_translator}
                  </button>
                )}
              </>
            )}
            {data.status === OrderStatus.INVITATION_SENT && (
              <>
                <p style={{ color: "red" }}>Send ud (igen)</p>
                {data.invitations[0] !== undefined && (
                  <p>{moment(data.invitations[0].created_at).format("DD-MM-YYYY HH:mm")}</p>
                )}
                <p>
                  Afvist: {data.invitations_not_answer} <br />
                  Ikke svaret: {data.invitations_reject} <br />I alt: {data.invitations_count}
                </p>
              </>
            )}
            {data.specific_translator_id !== null && data.is_talk_trans !== 0 && (
              <span className="badge badge-info">Customer</span>
            )}
            {data.invitations[0] !== undefined && (
              <span className="badge badge-info">Translator</span>
            )}
            {data.is_talk_trans === 0 &&
              data.translator_id !== null &&
              data.invitations[0] === undefined && <span className="badge badge-info">Admin</span>}
          </>
        );
      }),
      records.current.createdTd("preference", () => {
        return <>{SpecificTranslatorNamecolor(data)}</>;
      }),
      records.current.createdTd("status", () => {
        if (data.cancel !== null && data.cancel !== undefined) {
          return (
            <>
              {Number(data.cancel.status) === 0 && (
                <span className={"badge badge-danger"}>{Lang.status_new_cancel_request}</span>
              )}
              {Number(data.cancel.status) === 1 && (
                <span className={"badge badge-success"}>
                  {Lang.status_completed_cancel_request}
                </span>
              )}
              {Number(data.cancel.status) === 2 && (
                <span className={"badge badge-warning"}>{Lang.status_rejected_cancel_request}</span>
              )}
              {Number(data.cancel.status) === 0 && (
                <>
                  <br />
                  <br />
                  <Link to={"#"} className={"btn btn-primary"}>
                    <span onClick={() => OrderCorrectionService.showPopup(data, "cancel")}>
                      {Lang.changeStatusCancelRequect}
                    </span>
                  </Link>
                </>
              )}
            </>
          );
        }

        if (
          data.edit_request !== null &&
          data.edit_request[0] !== undefined &&
          data.edit_request[0] != ""
        ) {
          let i: number = Number($(data.edit_request).length - 1);

          return (
            <>
              {Number(data.edit_request[i].status) === 0 && (
                <span className={"badge badge-danger"}>{Lang.status_new_edit_request}</span>
              )}
              {Number(data.edit_request[i].status) === 1 && (
                <span className={"badge badge-success"}>{Lang.status_completed_edit_request}</span>
              )}
              {Number(data.edit_request[i].status) === 2 && (
                <span className={"badge badge-warning"}>{Lang.status_rejected_edit_request}</span>
              )}
              {Number(data.edit_request[i].status) === 0 && (
                <>
                  <br />
                  <br />
                  <Link to={"#"} className={"btn btn-primary"}>
                    <span onClick={() => OrderCorrectionService.showPopup(data, "edit")}>
                      {Lang.changeStatusEditRequect}
                    </span>
                  </Link>
                </>
              )}
            </>
          );
        }
      }),
      records.current.createdTd("created_at", () => {
        var created_date = moment(data.created_at);
        created_date.locale("da");
        return (
          <>
            {" "}
            {created_date.format("dddd")} <br /> {created_date.format("DD-MM-YYYY HH:mm:ss")} <br />{" "}
            {data.created_by.name}{" "}
          </>
        );
      }),
    ]
  );
  return cols;
}

export const OrderHelper = {
  getCompany: (order: OrderModel) => {
    if (!_.isEmpty(order.company)) {
      return order.company.name;
    }
    return "-";
  },
  getDept: (order: OrderModel) => {
    if (!_.isEmpty(order.department)) {
      return order.department.name;
    }
    return "-";
  },
  getDeptUnit: (order: OrderModel) => {
    if (!_.isEmpty(order.department_unit)) {
      return order.department_unit.name;
    }
    return "-";
  },
};

export const getDept = (companies: Array<CompanyModel>, company_id: number) => {
  if (company_id > 0) {
    const dept = companies.filter((item) => {
      return item.id === company_id;
    });
    if (dept[0]) {
      return [{ label: Lang.select_dept, value: "" }, ...dept[0].departments_for_select2];
    } else {
      return [];
    }
  }
  return [];
};

export const getDeptUnit = (dept: Array<any>, dept_id: number) => {
  if (dept_id > 0) {
    const dept_unit: any = dept.filter((item) => {
      return item.id === Number(dept_id);
    });
    if (dept_unit[0]) {
      return [{ label: Lang.select_dept_unit, value: "" }, ...dept_unit[0].units_with_select2];
    } else {
      return [];
    }
  }
  return [];
};

export const getDistanceAndTravelTime = (order_id: number) => {
  return getDistanceTravelTime(order_id);
};

export function list_key(id: number) {
  let k = _.add(moment(), id) + _.random(99999);
  return k.toString();
}
