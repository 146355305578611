import * as React from 'react';
import {UserModel} from "../../model";
import {Button, Modal, ModalProps} from "react-bootstrap";
import Lang from "../../common/lang/Lang";
import {Link} from "react-router-dom";
import {Icons} from "../../common";
import _ from "lodash";

declare var window:any;

interface InfoProps extends ModalProps{
  UserInfo: UserModel
}
const fontsize = {
  fontSize: '15px'
};

export const TranslatorInfo = (props: InfoProps) => {
  if(props.UserInfo === undefined)
    return null;


  function languages(translator_languages ){
    if(!_.isEmpty(translator_languages)){
      return translator_languages.map((item: any) => {
        return <div>
          { ! _.isEmpty(item.from_language) &&
          <React.Fragment>
            <img src={window.PUBLIC_URL + '/admin/assets/flags/' + _.lowerCase(item.from_language.countries !== null && item.from_language.countries.iso) + '.png'} className="img-flag"/>&nbsp;{item.from_language !== null && item.from_language.language_name}
            <span className="exchangeIcon"><i className="fas fa-exchange-alt"/></span>
          </React.Fragment>
          }
          {!_.isEmpty(item.to_language) &&
          <React.Fragment>
            <img src={window.PUBLIC_URL + '/admin/assets/flags/' + _.lowerCase( item.to_language.countries !== null && item.to_language.countries.iso) + '.png'} className="img-flag"/>
            &nbsp;{item.to_language !== null && item.to_language.language_name }<br/><br/>
          </React.Fragment>
          }

        </div>
      });
    } else {
      return  '---';
    }
  }


  return(
    
    <Modal
    
     {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    >
      
      <Modal.Header closeButton>
        
        <Modal.Title id="contained-modal-title-vcenter">
          {Lang.translator_info}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-xl-12">
            <div className="kt-portlet kt-portlet--height-fluid">
              <div className="kt-portlet__body">
                <div className="kt-widget kt-widget--user-profile-3">
                  <div className="kt-widget__top">
                    <div className="kt-widget__media kt-hidden-">
                      <img src={props.UserInfo.profile_image} alt="profile"/>
                      { props.UserInfo.translator.rating !== null &&
                        <><br/>
                      <span style={{textAlign: 'center',display: 'block',marginTop: '5px'}}>{props.UserInfo.translator.rating+'.0 '} <i className="fa fa-star" style={{color: 'green',fontSize: '20px'}}/></span></>
                      }
                      { props.UserInfo.translator.review > 0 &&
                        <div style={{
                          fontSize: '10px',
                          fontWeight: 'bold',textAlign: 'center',
                          marginTop: '5px'
                        }}>{props.UserInfo.translator.review}&nbsp;&nbsp;{Lang.review_title} </div>
                      }
                    </div>
                    <div className="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-boldest kt-font-light kt-hidden">
                      JM
                    </div>
                    <div className="kt-widget__content">
                      <div className="kt-widget__head">
                        <Link to={'#'} className="kt-widget__username">
                          { (props.UserInfo.status === 1) ? <Link to={'#'} className="kt-widget__username">{props.UserInfo.name}<i className="flaticon2-correct kt-font-success"/></Link>:<Link to={'#'} className="kt-widget__username">{props.UserInfo.name}({( props.UserInfo.user_code !== null ) ? props.UserInfo.user_code : '--'})<i className="flaticon2-close-cross"/></Link>}
                        </Link>
                        {/* <div className="kt-widget__action">
                          <button type="button" className="btn btn-label-success btn-sm btn-upper">{Lang.send_mail}</button>
                        </div> */}
                      </div>
                      <div className="kt-widget__subhead">
                        <Link to={'#'}><i className={Icons.email}/>{props.UserInfo.email}</Link>
                        <Link to={'#'}><i className={Icons.mobile_no}/>{props.UserInfo.mobile_no}</Link>
                        <Link to={'#'}><i className={Icons.address}/>{props.UserInfo.address} {props.UserInfo.zip_code}</Link><br/><br/>
               {(props.UserInfo.gender === "1") ? <Link to={'#'}><i className={Icons.male} style = {fontsize}/>{Lang.male}</Link>:<Link to={'#'}><i className={Icons.female} style = {fontsize}/>{Lang.female}</Link>}
                        <Link to={'#'}><i className={Icons.bank} style = {fontsize}/>{props.UserInfo.translator.account_no}</Link>
                      </div>

                      <div className="kt-widget__info">
                        <div className="kt-widget__desc">
                          <div className="row">
                            <div className="col-md-4 col-lg-4">
                              {Lang.driver_license} - { (props.UserInfo.translator.driving_license === 1) ?  Lang.yes:Lang.no}<br/>
                              {Lang.vehicle} - { (props.UserInfo.translator.own_vehicle !== null && props.UserInfo.translator.own_vehicle === 1) ?  Lang.yes:Lang.no}<br/>
                              {Lang.country_of_origin} - {props.UserInfo.translator.origin_country !== null && props.UserInfo.translator.country.name}<br/>
                              {Lang.bank_account_no} - {props.UserInfo.translator.account_no}<br/>
                              {Lang.physical_rate} - {props.UserInfo.translator.physical_rate}<br/>
                              {Lang.telephone_rate} - {props.UserInfo.translator.telephone_rate}<br/>
                            </div>
                            <div className="col-md-4 col-lg-4">
                              {Lang.police_confirmed} - { (props.UserInfo.translator.police_verified === 1) ?  Lang.yes:Lang.no}<br/>
                              {Lang.certified} - { (props.UserInfo.translator.is_certified === 1) ?  Lang.yes:Lang.no}<br/>
                              {Lang.cpr_number} - {props.UserInfo.translator.social_no }<br/>
                              {Lang.bank_reg_no} - {props.UserInfo.translator.bank_registration_no}<br/>
                              {Lang.video_rate} - {props.UserInfo.translator.video_rate}<br/>
                            </div>
                            <div className="col-md-4 col-lg-4">
                              {Lang.language} - {languages(props.UserInfo.translator_languages)}


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="kt-widget__bottom">

                    <div className="kt-widget__item">
                      <div className="kt-widget__icon">
                        <i className="flaticon-piggy-bank"/>
                      </div>
                      <div className="kt-widget__details">
                        <span className="kt-widget__title">{Lang.total_earning}</span>
                        <span className="kt-widget__value"><span>$</span>{props.UserInfo.translator_totalearning_count}</span>
                      </div>
                    </div>

                    <div className="kt-widget__item">
                      <div className="kt-widget__icon">
                        <i className="flaticon-file-2"/>
                      </div>
                      <div className="kt-widget__details">
                        <span className="kt-widget__title">{Lang.total_order}</span>
                        <span className="kt-widget__value">{props.UserInfo.translator_order_count}</span>
                      </div>
                    </div>

                    <div className="kt-widget__item">
                      <div className="kt-widget__icon">
                        <i className="flaticon-file-2"/>
                      </div>
                      <div className="kt-widget__details">
                        <span className="kt-widget__title">{Lang.active_order}</span>
                        <span className="kt-widget__value">{props.UserInfo.translator_orderactive_count}</span>
                      </div>
                    </div>

                    <div className="kt-widget__item">
                      <div className="kt-widget__icon">
                        <i className="flaticon-file-2"/>
                      </div>
                      <div className="kt-widget__details">
                        <span className="kt-widget__title">{Lang.complete_orders}</span>
                        <span className="kt-widget__value">{props.UserInfo.translator_ordercomplete_count}</span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{Lang.close}</Button>
      </Modal.Footer>
    </Modal>
  )
};