import Lang from "../../common/lang/Lang";
import {RoleModel} from "../../model/Role";

export const urls = {
  users: "/users/",
  list: "/users/admin-users",
}

export const ListBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.admin_users,
    url: "#"
  }
];

export const CreateBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.admin_users,
    url: urls.list
  },
  {
    label: Lang.create,
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.admin_users,
    url: urls.list
  },
  {
    label: Lang.edit,
    url: '#'
  }
];

export interface Form {
  role_id: number|string;
  name: string;
  email: string;
  password:string;
  cn_password:string;
  mobile_no:string;
  country_code:string;
}

export interface IState{
  form: Form;
  id?: number;
  roles: Array<RoleModel>;
  countries?:Array<any>;
  countrycodes?:Array<any>;
  isEmailExists?: number;
}