import { dropRight, set } from 'lodash';
import React from 'react';
import { Icons, is_permit } from '../../../../../../../common';
import Lang from '../../../../../../../common/lang/Lang';
import { OrderType, OrderTypeIcons, TranslatorBusyAssignment } from '../../../../../../../model';
import { removeTranslator } from '../../../../../../../services';
import { Form } from '../../../../../filter/ActiveOrderFilter';
import { FlagIcon } from '../../TranslatorCard';
import InfoPill, { AvailableColors } from '../InfoPill/InfoPill';


// styles
import sheet from './TodayOrdersInfo.module.scss';

interface Props {
  translation_assignments: TranslatorBusyAssignment[]
  ButtonHover: boolean
  setButtonHover: React.Dispatch<React.SetStateAction<boolean>>
  buttonRef: React.MutableRefObject<HTMLAnchorElement>
  updateDayOrders: (translator_id: number, order_id: number) => Promise<void>
}

const emptyFilter: Form = {
  like: {
    q: ''
  },
  equal: {
    filter_status: 0,
    order_date: '',
    from_language: '',
    to_language: '',
    company_id: '',
    department_id: '',
    department_unit_id: '',
    customer_id: '',
    translator_id: '',
    order_type: '',
  },
  between: {
    from: '',
    to: '',
  },
  in: {
    id: []
  },
  request_cancel: 0,
  request_changes: 0
}

export const removeSeconds = (time: string):string => dropRight(time.split(':')).join(':');
const TodayOrdersInfo = ({ translation_assignments, updateDayOrders, setButtonHover, buttonRef }: Props ) => {
  // const overflowText = (text: string) => text.length > 8 ? text.substring(0, 7) + '..' : text
  return (
    <>
      <div className={sheet.assignments_container}>
        <h6 className={sheet.assignments_title}>{translation_assignments.length} oversætterordrer på: {(new Date(translation_assignments[0].order_date)).toLocaleDateString('da-DK')}</h6>
        <ul className={sheet.assignments_list}>
          {
            translation_assignments.map(item => (
              <li className={sheet.assignments_item}>
                <div className={sheet.assignments_info}>
                  <i data-toggle="tooltip" data-placement="bottom" title={item.type} className={OrderTypeIcons[item.translation_type_id]} />
                  <span className={`${sheet.assignments_time} ${sheet.assignments_info_line}`}><InfoPill label={item.order_from && item.order_to ? `${removeSeconds(item.order_from)}-${removeSeconds(item.order_to)}` : removeSeconds(item.order_from ?? item.order_to ?? '')} color={AvailableColors.grey} text_color={"dark"} /></span>
                  <span className={sheet.assignments_orderno}>{item.order_no}</span>
                  <div className={sheet.assignments_info_line} data-toggle="tooltip" data-placement="bottom" title={item.to_language_name}>
                    {item.to_language_name}
                  </div>
                  { is_permit('orders.remove-translator') && (
                    <button
                    className={`btn btn-danger ${sheet.assignments_remove}`}
                    onClick={(e) => {
                      e.preventDefault()
                      removeTranslator(item.id, item.translator_id, () => updateDayOrders(item.translator_id, item.id))
                    }}
                    >
                        Fjerne
                    </button>
                  )}
                </div>
                {
                item.translation_type_id === OrderType.PHYSICAL && <div className={sheet.physical_address_info}>[<span className={sheet.bold_black}>{item.meeting_city}</span>{item.meeting_zip_code && <>, <span className={sheet.bold_black}>{item.meeting_zip_code}</span></>}]</div>
                }                
              </li>
            ))
          }
        </ul>
      </div>
      <div className={sheet.filter_container}>
        <h6 className={sheet.filter_title}>&nbsp;</h6>
        <a
          className={sheet.filter_button}
          href={`/orders/orders?form=${JSON.stringify(set(emptyFilter, 'in.id', translation_assignments.map(item => item.id)))}`}
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={() => setButtonHover(true)}
          onMouseLeave={() => setButtonHover(false)}
        >
          <span ref={buttonRef} className={sheet.filter_button_label}>Se bestillinger</span>
        </a>
      </div>
    </>
  );
};

export default TodayOrdersInfo;