import { ErrorMessage, Field, FieldProps } from 'formik';
import React, {useState} from 'react';
import { LabelHTMLAttributes } from 'react';
import { InputHTMLAttributes } from 'react';
import { HTMLAttributes } from 'react';
import { PropsWithChildren } from 'react';
import { Icons } from '../../../common';

export interface FileProps {
    name: string
    label: string
    required: boolean
    container_props?: HTMLAttributes<any>
    input_props?: InputHTMLAttributes<any>
    label_props?: LabelHTMLAttributes<any>
}

const FileUpload = (props: PropsWithChildren<FileProps>) => {
    const [FileUploaded, setFileUploaded] = useState<boolean>(false);

    const {
        label,
        name,
        required = false,
        container_props = { className: 'custom-file' },
        input_props = { className: 'custom-file-input' },
        label_props = { className: 'custom-file-label' }
    } = props;

    const handleFileUpload = (
        e: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
        fieldName: string
    ) => {
        setFieldValue(fieldName, e.currentTarget.files[0]);
        setFileUploaded(true);
    }

    return (
        <div { ...container_props }>
            <Field name={name}>
                {
                    (props: FieldProps) => {
                        const {field, form, meta} = props;
                        return (
                            <>
                                <input type="file" name={name} id={name} onChange={e => { handleFileUpload(e, form.setFieldValue, field.name) }} {...input_props} />
                                <label htmlFor={name} {...label_props}>{FileUploaded? <span>Fil uploadet med succes <i className={Icons.tick_mark}/></span> : label}</label>
                            </>
                        )
                    }
                }
            </Field>
            <ErrorMessage name={name} />
        </div>
    );
}

export default FileUpload;
