//import Lang from "../../common/lang/Lang";

import { isEmpty } from "lodash";

declare var window: any;
export const CustomerValidations = function () {
  $(function () {
    $.validator.addMethod(
      'checkZipCode',
      (val) =>  !isEmpty($('#zip_code').val()),
      'The address you entered does not have a ZIP Code (Postal Code)'
    )

    $.validator.addMethod(
      'checkCity',
      (val) => !isEmpty($('#city').val()),
      'The address you entered does not have a valid City.'
    )

    $( "#customer_form" ).validate({
      // define validation rules
      rules: {
        name: {
          required: true
        },
        email: {
          required: true,
          email: true
        },
        password: "required",
        cn_password: {
          equalTo: "#password"
        },
        company_id: "required",
        department_id: {
          required: function () {
            return $('#customer_type').val() == 1 && $("#department_id").length > 0
          }
        },
        customer_type: {
          required: function () {
            return $("#customer_type").length > 0
          }
        },
        department_unit_id:{
          required: function () {
            return $("#is_special_company").val() == 1
          }
        },
        address: {
          required: true,
          checkZipCode: true,
          checkCity: true
        },
        zip_code: "required",
        city: "required",
        mobile_no: "required",
        /* ean_number:  {
          required: function () {
            return $("#is_special_company").val() == 1
          }
        },
        cpr_number: {
          required: function () {
            return $("#is_special_company").val() != 1
          }
        }*/
    },

      invalidHandler: function(event, validator) {

      },
    });
  });
}
