import React from 'react';
import LanguageSelector from './LanguageSelector';

const TranslationLangInput = (props) => {
    const { options, remove, index, fromInput, toInput } = props
    const { fromName, fromLabel, ...fromProps } = fromInput;
    const { toName, toLabel, ...toProps } = toInput;
    return (
        <div className="form-group">
            <div className="row">
                <label className="col-lg-6" htmlFor={fromName}>
                    {fromLabel}
                    <span className={"required"}>*</span>
                </label>
                <label className="col-lg-4" htmlFor={toName}>
                    {toLabel}
                    <span className={"required"}>*</span>
                </label>
                {
                    index !== 0 && (
                        <div
                            className={
                                "col-lg-2 kt-align-right"
                            }
                        >
                            <button
                                type={"button"}
                                onClick={() => {
                                    remove(index)
                                }}
                                className={"btn btn-danger btn-close"}
                                style={{
                                    borderRadius: '50%',
                                    padding: '0rem 0.4rem',
                                    margin: '0 0.5rem 0 0.5rem',
                                    fontSize: '1rem'
                                }}
                            >
                                <span>-</span>
                            </button>
                        </div>
                    )
                }
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <LanguageSelector
                        name={fromName}
                        id={fromName}
                        options={options}
                        {...fromProps}
                    />
                </div>
                <div className="col-lg-6">
                    <LanguageSelector
                        name={toName}
                        id={toName}
                        options={options}
                        {...toProps}
                    />
                </div>
            </div>

        </div>
    );
}

export default TranslationLangInput;
