import React from "react";
declare var window: any;
export function NotFound() {
  return (
    <div
      className="kt-container page404container  kt-container--fluid  kt-grid__item kt-grid__item--fluid"
      style={{
        backgroundImage: `url(${window.PUBLIC_URL}/admin/assets/media/error/bg4.jpg)`,
      }}
    >
      <div className={""}>
        <div className="kt-error_container page404">
          <img
            src={`${window.PUBLIC_URL}/admin/assets/media/error/404_screen4.gif`}
          />
          <p className="kt-error_description">
            Beklager!, den side, du leder efter, findes ikke. Fortsæt til vores
            startside eller til dit betjeningspanel
          </p>
        </div>
      </div>
    </div>
  );
}
