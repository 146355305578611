import * as React from 'react';
import { useEffect } from 'react';
import {OrderModel} from "../../model";
import {Button, Modal, ModalProps} from "react-bootstrap";
import Lang from "../../common/lang/Lang";
import * as _ from 'lodash';
import {OrderInfoHeader} from "./order-compnent/orderinfo-header";
import {OrderDetails} from "./order-compnent/order-detail";
import {OrderContactInfo} from "./order-compnent/order-contactInfo";
import {OrderTranslatorInfo} from "./order-compnent/order-translatorInfo";
import {OrderCancelInfo} from "./order-compnent/order-cancelInfo";
import {OrderFeedbackInfo} from "./order-compnent/order-feedbackInfo";
import {OrderPreferenceInfo} from "./order-compnent/order-preferenceInfo";
import API from '../../common/AxoisClient';


interface InfoProps extends ModalProps{
  orderinfo: OrderModel,
}

export const OrderInfo = (props: InfoProps) => {
  useEffect(() => {
    // logging to activity log
    if (props.orderinfo?.order_no) API.get(`orders/orders/view-order/${props.orderinfo.order_no}`);
  }, [props.orderinfo?.order_no])
 if(props.orderinfo === undefined)
    return null;
     
  return(  
   <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          #{props.orderinfo.order_no}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
       <OrderInfoHeader info={props.orderinfo} />
        <div className="row">
         <OrderDetails info={props.orderinfo} />
         <OrderContactInfo info={props.orderinfo} />
          <OrderTranslatorInfo info={props.orderinfo} /> 
          <OrderCancelInfo info={props.orderinfo} />
          <OrderFeedbackInfo info={props.orderinfo} />
          <OrderPreferenceInfo info={props.orderinfo} />
        </div>
      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{Lang.close}</Button>
      </Modal.Footer>
    </Modal>
  )
};