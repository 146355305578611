import React, {useReducer, useState, useEffect} from  'react';
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {sendCustomerMail, SendCustomerMailPopupService} from "../../../services";
import {CONST, Select, showNotification, SubmitButton} from "../../../common";
import {
  ActionType,
  IAction, initialPopupState, PopupState,
  SendMailToCustomerReducer
} from "../order-reducers/SendMailToCustomerReducer";
import {Editor} from "@tinymce/tinymce-react/lib/cjs/main/ts";
import {ClientData, OrderModel, OrderType} from "../../../model";
import _, { indexOf } from 'lodash';
import {order_time, order_to_language} from "../../../helpers/OrderHelper";
import { stringify } from 'querystring';
const moment = require("moment");

export function SendMailToCustomer() {

  const [state, dispatch] = useReducer<React.Reducer<PopupState, IAction>>(SendMailToCustomerReducer, initialPopupState);

  useEffect( () => {
    SendCustomerMailPopupService.getPopupState().subscribe((message:any) => {
      dispatch({type: ActionType.SHOW_POPUP, payload: message.data});
    });
  }, []);

  if(state.show === false){
    return null;
  }


  function HandleChange(e) {
    dispatch({type: ActionType.SET_MAIL, payload: e.target});
  }

  function handleSubmit(event) {
    event.preventDefault();
    console.log('this is order_id:', state.order_id, ' and this is form: ', state.form);
    sendCustomerMail(state.order_id, state.form).then(res => {
      showNotification('success', res)
      dispatch({type: ActionType.HIDE_POPUP, payload: {} as any});
    })

  }

  function handleEditorChange(content : any) {
    dispatch({type: ActionType.MAIL_CONTENT, payload: content});
  }

  const {order_data} = state;
  let trans = '', meeting = '';
  if(order_data.translator_id > 0 ) {
    trans = '<p><b>'+Lang.translator+'</b> - '+ order_data.translate_by.name;
    if(order_data.translation_type_id === OrderType.PHYSICAL) {
      trans += 'Kørt fra '+ order_data.translate_by.address;
      if(!_.isEmpty(order_data.translate_by.city)){
        trans += ", " + order_data.translate_by.city;
      }
      if(!_.isEmpty(order_data.translate_by.zip_code)){
        trans += ", " + order_data.translate_by.zip_code;
      }
    }
  }

  if(order_data.translation_type_id === OrderType.PHYSICAL){
    meeting = '<p><b>'+ Lang.meeting_address +'</b> - '+order_data.meeting_address+' </p>';
  }
  
  function makeClientsString (order_data: OrderModel): string {
    return order_data.clients.map((client: ClientData, index: number) => {
      return `${index + 1}) ${client.client_name === ''? '----' : client.client_name}${index === order_data.clients.length - 1? '' : ' '}`;
    }).join('');
  }
  
  const copy_content = `
        <p><b>${Lang.order_no}</b> - ${order_data.order_no}</p>
        <p><b>${Lang.date}</b> - ${moment(moment(order_data.order_date)).format(CONST.date_format)}</p>
        <p><b>${Lang.time}</b> - ${ order_time(order_data)}</p>
        <p><b>${Lang.client_address}</b> - ${!_.isEmpty(order_data.contact_persons ) ? order_data.contact_persons[0].name : ''}, ${!_.isEmpty(order_data.company) ? order_data.company.name : ''}, ${!_.isEmpty(order_data.department) ?  order_data.department.name : ''} <br/> 
                ${(order_data.customer.address !== undefined) ? order_data.customer.address :''} </p>
         ${trans}
         ${meeting}
         <p><b>${Lang.client}</b> - ${!_.isEmpty(order_data.clients) ? makeClientsString(order_data) : !_.isEmpty(order_data.customer)? order_data.customer.name : '----'}</p>
         <p><b>${Lang.language}</b> - ${order_data.language_from.language_name}- ${order_to_language(order_data)}</p>
         <p><b>${Lang.translation_type}</b> - ${order_data.translation_type.title}</p>
         <p><b>${Lang.order_approved}</b> - ${moment(moment(order_data.created_at)).format(CONST.date_format)}</p>
        
  `;

  function copyContent() {
    handleEditorChange( state.form.content + ' ' +copy_content);
  }


  return(
    <Modal
      show={state.show}
      onHide={() => { dispatch({type: ActionType.HIDE_POPUP}) } }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {Lang.send_mail_customer} - #{order_data.order_no}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
              <div className={'col-md-6'}><b>{Lang.language}</b>:   {order_data.language_from.language_name} - {order_to_language(order_data)}</div>
              <div className={'col-md-6'}><b>{Lang.order_time}</b>:   {order_time(order_data)}</div>
              <div className={'col-md-6'}><b>{Lang.company}</b>:   {order_data.company.name}</div>
              <div className={'col-md-6'}><b>{Lang.customer_info}</b>:   {order_data.customer.name} ({order_data.customer.email})</div>
            </div>
            <br/>
            <div className={'row'}>
              <div className={'col-md-12'}>
                <div className={'form-group'}>
                  <Select className={'form-control'} id='select_type' onChange={HandleChange }>
                    <option value={''}>{Lang.select}</option>
                    <option value={'send_confirmation_for_customer_with_translator'}>{Lang.send_confirmation_for_customer_with_translator}</option>
                    <option value={'send_confirmation_for_customer_without_translator'}>{Lang.send_confirmation_for_customer_without_translator}</option>
                    {order_data.translation_type_id === OrderType.PHYSICAL &&
                    <option value={'send_confirmation_for_customer_with_translator_transport_info'}>{Lang.send_confirmation_for_customer_with_translator_transport_info}</option>
                    }
                    {/*<option value={'send_confirmation_for_customer_with_translator_video_address'}>{Lang.send_confirmation_for_customer_with_translator_video_address}</option>*/}
                    <option value={'send_confirmation_for_customer_custom_mail'}>{Lang.send_confirmation_for_customer_custom_mail}</option>
                  </Select>
                </div>
              </div>
              {
                state.form.mail === 'send_confirmation_for_customer_custom_mail' &&
                  <div className={'col-md-12'} style={{ marginLeft: 10}}>
                    <div className={'row'}>
                      <div dangerouslySetInnerHTML={{__html: copy_content}}/>
                    </div>
                    <div className={'row'} style={{paddingBottom: 10}}>
                      <button className={'btn btn-primary'} type={'button'} onClick={copyContent}>{Lang.copy_content}</button>
                    </div>
                    <Editor id='content'
                      init={{
                        menubar:false,mode: "textareas", selector: 'textarea', branding: false, forced_root_block : false
                      }} value={state.form.content} onEditorChange={handleEditorChange} />
                  </div>
              }
            </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        
        {
          state.form.mail !== '' &&
            <SubmitButton className={'btn btn-primary'} label={Lang.send}/>
        }

      </Modal.Footer>
      </form>
    </Modal>
  )
}