import React from "react";
import {
  LangStatus,
  LeaveStatus,
  TranslatorLeaveModel,
  TranslateLanguageModel,
  UserModel,
  CompanyModel,
} from "../model";
import Lang from "../common/lang/Lang";
import _ from "lodash";
import { Icons } from "../common";

export function convert_comma_to_dot($value) {
  if ($value && $value != "0") {
    if ($value.includes(",00")) {
      var splitVal = $value.split(",");
      return splitVal[0].replace(",", ".");
    }
    return $value.replace(",", ".");
  } else {
    return "0.00";
  }
}

export function convert_dot_to_comma($value) {
  if ($value && $value != "0") {
    if ($value.includes(",00")) {
      var splitVal = $value.split(",");
      return splitVal[0].replace(".", ",");
    }
    return $value.replace(".", ",");
  } else {
    return "0,00";
  }
}

export function convert_to_decimal_format($value) {
  if ($value && $value != "0" && typeof $value == "string") {
    if ($value.includes(".")) {
      return $value.replace(".", ",");
    } else if (!$value.includes(",")) {
      return $value + ",00";
    }
  }
  return $value;
}

export function get_translator_gender_with_color(data: UserModel) {
  let gender, color, icon;
  switch (data.gender) {
    case "1":
      gender = Lang.male;
      color = "blue";
      icon = "fa fa-male";
      break;

    case "2":
      gender = Lang.female;
      color = "#f1889a";
      icon = "fa fa-female";
      break;

    default:
      gender = "";
      color = "";
      icon = "";
  }

  return { gender, color, icon };
}

export function show_expensive_scale($scale) {
  var sc = "";
  for (var i = 2; i <= parseInt($scale); i++) {
    sc += "$";
  }
  return sc;
}

export function is_vehicle(data: UserModel) {
  if (data.translator.own_vehicle === 1) {
    return (
      <i
        style={{ fontSize: "20px", color: "blue" }}
        className={"fa fa-car"}
        data-toggle="kt-tooltip"
        title="Køretøj"
      />
    );
  }
  // else {
  //   return <i style={{fontSize:"20px", color:'red'}} className={'fa fa-blind'} data-toggle="kt-tooltip" title="Køretøj"/>
  // }
}

export function is_police_verified(data: UserModel) {
  if (data.translator.police_verified === 1) {
    return (
      <span className="badge badge-success" data-toggle="kt-tooltip" title="Police Verified">
        Politi
      </span>
    );
  }
  // else {
  //   return <span className="badge badge-danger" data-toggle="kt-tooltip" title="Police Not Verified">Politi</span>
  // }
}

export function translator_status(data: UserModel) {
  let status, info, location;
  /*if(data.status == 1){
    return <><p><span className={'badge badge-primary'}>{Lang.translator_active}</span></p><p><span data-toggle="tooltip" title="Location">{data.translator.locationtext}</span></p></>
  }*/
  if (data.status == 2 || data.status == 3) {
    if (_.isEmpty(data.phone_verified_at) && _.isEmpty(data.email_verified_at)) {
      status = (
        <p>
          <span className={"badge badge-danger"}>{Lang.status_un_verified}</span>
        </p>
      );
    } else if (!_.isEmpty(data.phone_verified_at) || !_.isEmpty(data.email_verified_at)) {
      status = (
        <p>
          <span className={"badge badge-primary"}>{Lang.status_verified}</span>
        </p>
      );
    } else {
      status = (
        <p>
          <span className={"badge badge-danger"}>{Lang.inactive_passive}</span>
        </p>
      );
    }
  }
  // if(data.status == 3){
  //   status = <p><span className={'badge badge-warning'}>{Lang.new_translator}</span></p>
  // }

  // check if any contract or test is pending

  if (data.translator.send_contract !== null || data.translator.send_online_test !== null) {
    if (data.translator.send_contract === 1) {
      info = (
        <p>
          <span className={"badge badge-warning"}>{Lang.status_contract_sent}</span>
        </p>
      );
    }
    if (data.translator.send_contract === 2) {
      info = (
        <p>
          <span className={"badge badge-success"}>{Lang.status_waiting_approval}</span>
        </p>
      );
    }

    if (data.translator.send_online_test === 1) {
      info = (
        <>
          {" "}
          {info}{" "}
          <p>
            <span className={"badge badge-warning"}>{Lang.status_online_test_sent}</span>
          </p>{" "}
        </>
      );
    }
    if (data.translator.send_online_test === 2) {
      info = (
        <>
          {" "}
          {info}{" "}
          <p>
            <span className={"badge badge-success"}>{Lang.status_online_test_taken}</span>
          </p>{" "}
        </>
      );
    }
  }

  if (
    (data.translator.registration_step === 4 ||
      data.translator.registration_step === 5 ||
      data.translator.send_contract === 2 ||
      data.translator.send_online_test === 2) &&
    data.status !== 1
  ) {
    info = (
      <>
        {" "}
        {info}{" "}
        <p>
          <i title={"Profile under review"} className="fa fa-hourglass-half" />
        </p>{" "}
      </>
    );
  }
  /*if(data.translator.locationtext !== null ) {
    location = <><p data-toggle="tooltip" title="Location">{data.translator.locationtext}</p></>
  }*/

  return (
    <React.Fragment>
      {status}
      {info}
      {location}
    </React.Fragment>
  );
}

interface TranslatorStatusesProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  data: UserModel;
}
export const TranslatorStatuses = (props: TranslatorStatusesProps) => {
  const { data, ...wrapperProps } = props;

  const status = {
    email_verified: data.email_verified_at !== null,
    preliminary_interview_taken:
      data.translator.training !== null &&
      data.translator.highest_complete_edu !== null &&
      data.translator.current_employment !== null &&
      data.translator.motivation !== null,
    gtep_test_sent:
      data.translator.send_online_test !== null && data.translator.send_online_test === 1,
    gtep_test_taken:
      data.translator.send_online_test !== null && data.translator.send_online_test === 2,
    contract_sent: data.translator.send_contract !== null && data.translator.send_contract === 1,
    contract_signed: data.translator.send_contract !== null && data.translator.send_contract === 2,
    final_interview_scheduled: !_.isEmpty(data.translator.interview_scheduled_at),
    final_interview_done: data.translator.interview_taken_at ? true : false,
  };

  let {
    email_verified,
    preliminary_interview_taken,
    gtep_test_sent,
    gtep_test_taken,
    final_interview_scheduled,
    final_interview_done,
    contract_sent,
    contract_signed,
  } = status;

  // Rules and Badges
  let badges = {
    badge1: <></>,
    badge2: <></>,
    badge3: <></>,
  };
  let { badge1, badge2, badge3 } = badges;

  // Rule 1
  if (gtep_test_taken)
    badge1 = (
      <p>
        <span title={"GTEP Test Taken"} className={"badge badge-pill translator-badge-success"}>
          {Lang.badge_gtep_test}
        </span>
      </p>
    );
  else if (!email_verified)
    badge1 = (
      <p>
        <span title={"Email Unverified"} className={"badge badge-pill translator-badge-danger"}>
          {Lang.badge_email_verification}
        </span>
      </p>
    );
  else if (!gtep_test_sent)
    badge1 = (
      <p>
        <span title={"GTEP Test Not Sent"} className={"badge badge-pill translator-badge-danger"}>
          {Lang.badge_gtep_test}
        </span>
      </p>
    );
  else if (gtep_test_sent)
    badge1 = (
      <p>
        <span title={"GTEP Test Sent"} className={"badge badge-pill translator-badge-warning"}>
          {Lang.badge_gtep_test}
        </span>
      </p>
    );

  // Rule 2
  if (final_interview_done)
    badge2 = (
      <p>
        <span
          title={"Final Interview Done"}
          className={"badge badge-pill translator-badge-success"}
        >
          {Lang.badge_final_interview}
        </span>
      </p>
    );
  else if (!preliminary_interview_taken)
    badge2 = (
      <p>
        <span
          title={"Preliminary Interview Not Taken"}
          className={"badge badge-pill translator-badge-danger"}
        >
          {Lang.badge_preliminary_interview}
        </span>
      </p>
    );
  else if (!final_interview_scheduled)
    badge2 = (
      <p>
        <span
          title={"Final Interview Not Scheduled"}
          className={"badge badge-pill translator-badge-danger"}
        >
          {Lang.badge_final_interview}
        </span>
      </p>
    );
  else if (final_interview_scheduled)
    badge2 = (
      <p>
        <span
          title={"Final Interview Scheduled"}
          className={"badge badge-pill translator-badge-warning"}
        >
          {Lang.badge_final_interview}
        </span>
      </p>
    );

  // Rule 3
  if (contract_signed)
    badge3 = (
      <p>
        <span title={"Contract Signed"} className={"badge badge-pill translator-badge-success"}>
          {Lang.badge_contract}
        </span>
      </p>
    );
  else if (!contract_sent)
    badge3 = (
      <p>
        <span title={"Contract Not Sent"} className={"badge badge-pill translator-badge-danger"}>
          {Lang.badge_contract}
        </span>
      </p>
    );
  else if (contract_sent)
    badge3 = (
      <p>
        <span title={"Contract Sent"} className={"badge badge-pill translator-badge-warning"}>
          {Lang.badge_contract}
        </span>
      </p>
    );

  const all_true =
    email_verified &&
    preliminary_interview_taken &&
    gtep_test_taken &&
    final_interview_scheduled &&
    final_interview_done &&
    contract_signed &&
    data.profile_image &&
    !data.profile_image.includes("user-placeholder") &&
    data.translator.resume;
  const all_false =
    !email_verified &&
    !preliminary_interview_taken &&
    !gtep_test_sent &&
    !gtep_test_taken &&
    !final_interview_scheduled &&
    !final_interview_done &&
    !contract_sent &&
    !contract_signed &&
    !(data.profile_image && !data.profile_image.includes("user-placeholder")) &&
    !data.translator.resume;

  return (
    <div {...wrapperProps} className="translator_statuses_container">
      <div className="translator_statuses">
        {badge1}
        {badge2}
        {badge3}
        {
          <div className="translator_documents">
            <i
              title={Lang.profile_pic}
              className={`${Icons.portrait} ${
                data.profile_image && !data.profile_image.includes("user-placeholder")
                  ? "green_icon"
                  : "red_icon"
              }`}
            />
            <i
              title={Lang.CV}
              className={`${Icons.paper} ${data.translator.resume ? "green_icon" : "red_icon"}`}
            />
            <i
              title={Lang.criminal_record}
              className={`${Icons.clipboard} ${
                data.translator.criminal_record ? "green_icon" : "red_icon"
              }`}
            />
          </div>
        }
      </div>
      <div className={`info_popover ${all_false ? "red_bg" : all_true ? "green_bg" : "yellow_bg"}`}>
        <div className="info_popover_title_container">
          <h3>Oversætterstatus</h3>
        </div>
        <ul className="info_popover_status_list">
          <li>
            E-mail bekræftet:{" "}
            <span className={email_verified ? "green_info" : "red_info"}>
              {email_verified ? "YES" : "NO"}
            </span>
          </li>
          <li>
            Indledende interview taget:{" "}
            <span className={preliminary_interview_taken ? "green_info" : "red_info"}>
              {preliminary_interview_taken ? "YES" : "NO"}
            </span>
          </li>
          <li>
            GTEP -test sendt:{" "}
            <span className={gtep_test_sent || gtep_test_taken ? "green_info" : "red_info"}>
              {gtep_test_sent || gtep_test_taken ? "YES" : "NO"}
            </span>
          </li>
          <li>
            GTEP -test taget:{" "}
            <span className={gtep_test_taken ? "green_info" : "red_info"}>
              {gtep_test_taken ? "YES" : "NO"}
            </span>
          </li>
          <li>
            Kontrakt sendt:{" "}
            <span className={contract_sent || contract_signed ? "green_info" : "red_info"}>
              {contract_sent || contract_signed ? "YES" : "NO"}
            </span>
          </li>
          <li>
            Kontrakt underskrevet:{" "}
            <span className={contract_signed ? "green_info" : "red_info"}>
              {contract_signed ? "YES" : "NO"}
            </span>
          </li>
          <li>
            Afsluttet interview planlagt:{" "}
            <span className={final_interview_scheduled ? "green_info" : "red_info"}>
              {final_interview_scheduled || final_interview_done ? "YES" : "NO"}
            </span>
          </li>
          <li>
            Sidste interview udført:{" "}
            <span className={final_interview_done ? "green_info" : "red_info"}>
              {final_interview_done ? "YES" : "NO"}
            </span>
          </li>
        </ul>
        <hr />
        <div>
          <h3>Dokumenter</h3>
          <ul className="info_popover_status_list">
            <li>
              Profilbillede:{" "}
              <span
                className={
                  data.profile_image && !data.profile_image.includes("user-placeholder")
                    ? "green_info"
                    : "red_info"
                }
              >
                {data.profile_image && !data.profile_image.includes("user-placeholder")
                  ? "YES"
                  : "NO"}
              </span>
            </li>
            <li>
              C.V:{" "}
              <span className={data.translator.resume ? "green_info" : "red_info"}>
                {data.translator.resume ? "YES" : "NO"}
              </span>
            </li>
            <li>
              Straffeattest:{" "}
              <span className={data.translator.criminal_record ? "green_info" : "red_info"}>
                {data.translator.criminal_record ? "YES" : "NO"}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

/**
 * get translator leave status
 *
 * @param {TranslatorLeaveModel} data
 * @returns {any}
 */
export function get_trans_leave_status(data: TranslatorLeaveModel) {
  switch (data.status) {
    case LeaveStatus.APPROVE:
      return <span className={"badge badge-success"}>Approved</span>;

    case LeaveStatus.PENDING:
      return <span className={"badge badge-warning"}>Pending</span>;

    case LeaveStatus.REJECT:
    default:
      return <span className={"badge badge-danger"}>Rejected</span>;
  }
}

/**
 * get translator language status
 *
 * @param {TranslateLanguageModel} data
 * @returns {any}
 */
export function get_trans_lang_status(data: TranslateLanguageModel) {
  switch (data.status) {
    case LangStatus.ACTIVE:
      return <span className={"badge badge-success"}>Active</span>;

    case LangStatus.PENDING:
      return <span className={"badge badge-warning"}>Pending</span>;

    case LangStatus.REJECT:
    default:
      return <span className={"badge badge-danger"}>Rejected</span>;
  }
}

export function state_to_form_data(state: any) {
  let formData = new FormData();
  _.forEach(state, (value, key) => {
    formData.append(key, value);
  });
  return formData;
}

export const UserHelper = {
  getCompany: (userInfo: UserModel) => {
    if (!_.isEmpty(userInfo.company)) {
      return userInfo.company.name;
    }
    return "-";
  },
  getDept: (userInfo: UserModel) => {
    if (!_.isEmpty(userInfo.customer) && !_.isEmpty(userInfo.customer.department)) {
      return userInfo.customer.department.name;
    }
    return "-";
  },
  getDeptUnit: (userInfo: UserModel) => {
    if (!_.isEmpty(userInfo.customer) && !_.isEmpty(userInfo.customer.department_unit)) {
      return userInfo.customer.department_unit.name;
    }
  },

  getContactPersonContact: (userInfo: UserModel) => {
    if (!_.isEmpty(userInfo.customer) && !_.isEmpty(userInfo.customer.contact_phone)) {
      return userInfo.customer.contact_phone;
    }
    return "-";
  },

  getAddress: (userInfo: UserModel) => {
    let address = "";
    address = userInfo.address;
    if (!_.isEmpty(userInfo.city)) {
      address += " " + userInfo.city;
    }
    if (!_.isEmpty(userInfo.zip_code)) {
      address += " " + userInfo.zip_code;
    }
    return address;
  },

  getAddressRaw: (userInfo: UserModel) => {
    let address = "";
    address = userInfo.address;
    return address;
  },
};

export function certifiedIcon(user: UserModel) {
  if (!_.isEmpty(user.translator)) {
    const {
      translator: { is_certified },
    } = user;

    if (is_certified === 1) {
      return (
        <i
          className={"fa fa-medal"}
          data-toggle="kt-tooltip"
          title={"Certificeret"}
          style={{ color: "green" }}
        />
      );
    } else {
      return (
        <i
          className={"fa fa-times-circle"}
          data-toggle="kt-tooltip"
          title={"Ikke certificeret"}
          style={{ color: "darkred" }}
        />
      );
    }
  } else {
    return null;
  }
}

export const getDept = (companies: Array<CompanyModel>, company_id: number) => {
  if (company_id > 0) {
    const dept = companies.filter((item) => {
      return item.id === company_id;
    });
    if (dept[0]) {
      return [{ label: Lang.select_dept, value: "" }, ...dept[0].departments_for_select2];
    } else {
      return [];
    }
  }
  return [];
};

export const getDeptUnit = (dept: Array<any>, dept_id: number) => {
  if (dept_id > 0) {
    const dept_unit: any = dept.filter((item) => {
      return item.id === Number(dept_id);
    });
    if (dept_unit[0]) {
      return [{ label: Lang.select_dept_unit, value: "" }, ...dept_unit[0].units_with_select2];
    } else {
      return [];
    }
  }
  return [];
};
