import * as React from 'react';
import {ActivityLog} from "./ActivityLog";
import {RecentOrders} from "./RecentOrders";
import {RecentUsers} from "./RecentUsers";
import {OrderChart} from "./OrderChart";
import {TotalOrder} from "./TotalOrders";
import {TotalTranslator} from "./TotalTranslator";
import Lang from "../common/lang/Lang";
import {Claims} from "./Clams";
const moment = require("moment");

export interface IState{
  startDate : string;
  endDate : string;
}

export class Dashboard extends React.Component<IState> {
  state: IState;
  constructor(props:any){
    super(props);
    this.state={
      startDate :'',
      endDate : ''
    }

  }

  handleChange = (start, end) => {
   this.setState({ startDate: start,endDate:end });
  }

  componentDidMount() {
    var start = moment().subtract(29, 'days');
    var end = moment();
    var datadate = this;
    (jQuery('#kt_daterangepicker_6') as any).daterangepicker({

        buttonClasses: ' btn',
        applyClass: 'btn-primary',
        cancelClass: 'btn-secondary',

        startDate: start,
        endDate: end,
         
        ranges: {
           'Today': [moment(), moment()],
           'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
           'Last 7 Days': [moment().subtract(6, 'days'), moment()],
           'Last 30 Days': [moment().subtract(29, 'days'), moment()],
           'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }

    }, function(start, end, label) {
        $('#kt_daterangepicker_6 .form-control').val( start.format('MM/DD/YYYY') + ' / ' + end.format('MM/DD/YYYY'));
        datadate.handleChange(start.format('MM/DD/YYYY'), end.format('MM/DD/YYYY'))
     },
     );



  }

  render() {
    return(
      <div>
        <div className="kt-subheader  kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <h3 className="kt-subheader__title">{Lang.dashboard}</h3>
              <span className="kt-subheader__separator kt-subheader__separator--v"/>
            </div>
          </div>
                 	<div className="col-lg-4 col-md-9 col-sm-12">
												<div className='input-group pull-right' id='kt_daterangepicker_6'>
													<input type='text' className="form-control" readOnly={true} placeholder={Lang.select_date_range} />
													<div className="input-group-append">
														<span className="input-group-text"><i className="la la-calendar-check-o"></i></span>
													</div>
												</div>
											</div>

        </div>


        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="row">
            <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1">
              <TotalOrder startDate={this.state.startDate} endDate={this.state.endDate}/>
            </div>
            <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1">
            <TotalTranslator startDate={this.state.startDate} endDate={this.state.endDate}/>
            </div>
            <div className="col-xl-12 col-lg-12 order-lg-3 order-xl-1">

              <OrderChart startDate={this.state.startDate} endDate={this.state.endDate}/>
            </div>
          </div>


          <div className="row">
            <div className="col-xl-5 col-lg-6 order-lg-3 order-xl-1">
              <RecentUsers/>
            </div>
            <div className="col-xl-7 col-lg-12 order-lg-3 order-xl-1">
              <RecentOrders/>
            </div>


          </div>
          <div className="row">
            <div className="col-xl-8 col-lg-12 order-lg-3 order-xl-1">
            <Claims/>
            </div>
            <div className="col-lg-6 col-xl-4 order-lg-1 order-xl-1">
              <ActivityLog/>
            </div>

          </div>

        </div>
      </div>
    )
  }
}