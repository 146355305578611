import React from 'react'
import { nextJob } from '../InvoicingType'

//? Styles
import sheet from './NextJob.module.scss'

interface Props {
  nextJob: nextJob
}

// The delete functionality is not needed for now so I deleted it.
// If you need to implement it look at commit 7c167bb2
const NextJob = ({ nextJob: { company: { id, name, logo }, order_count } }: Props) => {
  return (
    <div className={sheet.container}>
      <div className={sheet.logo}>
        <img src={logo} alt="Horsens Kommune" />
      </div>
      <div className={sheet.company_details}>
        <h2>{name}</h2>
        <span>{order_count} Orders</span>
      </div>
    </div>
  )
}

export default NextJob