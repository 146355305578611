import * as React from 'react';
import {SearchInput, SearchSelect,IState as CommonState, ShowRecords,
  CONST, ExportButton, getQueryStringParams, actionButton, CreateLink, EditLink, Icons
} from "../../common";
import {default as Lang} from "../../common/lang/Lang"
import {RouteComponentProps} from "react-router";
import {UserModel} from "../../model";
import {ListBreadcrumb, Payouts} from "./Data";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import {SeeOrders} from "../orders/OrdersPopup";
import {SeePayoutOrderService} from "../../services/PayoutServices";
import PaidFilter from './filter/PaidFilter';
import {Link} from "react-router-dom";
import {list_key} from "../../helpers/OrderHelper";

interface IState extends CommonState{
  data: Array<any>;
}


export class PaidPayouts extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }

  showContent = (data: Payouts, index: number) => {
    let cols = [];
    cols.push(...[
      this.records.current.createdTd("id", index),
      this.records.current.createdTd("name", data.user.name),
      this.records.current.createdTd("total_order", data.total_order),
      this.records.current.createdTd("bank_info", () => {
        return (
          <div>
            <p>Account no - {data.user.translator.account_no}</p>
            <p>Bank registration - {data.user.translator.bank_registration_no}</p>
            <p>CPR No - {data.user.translator.social_no}</p>
          </div>
        )
      }),
      this.records.current.createdTd("total_work", data.total_work),
      this.records.current.createdTd("total_amount", data.total_amount),
      this.records.current.createdTd("total_distance", data.total_distance),
      this.records.current.createdTd("total_travel_time", data.total_travel_time),
      this.records.current.createdTd("see_orders", () => {
        return (
          <div>
            <Link to={`/payouts/paid/orders/${data.user.id}`}  className="btn btn-success">{Lang.see_orders}</Link>
          </div>
        )
      })
    ]);
    cols = _.compact(cols);

    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  };

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }




  rowActionButtons = (data: UserModel) => {
    return (
      <EditLink scope={'admin-users.update'} to={`${this.props.match.url}/edit/${data.id}`} />
    )
  }

  render() {
    const status = CONST.status;
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'paid_payout_title'} icon={Icons.dollor} >
            <ExportButton records={this.records} fileName={Lang.exportPaidPayouts}/>
            &nbsp;
          </ListHeader>

          <ListForm
            form={(
              <PaidFilter   getResults={this.filterRecords} />
            )}
            table={(
              <ShowRecords
                url={"/payouts/paid"}
                showRecords={this.showContent}
                ref={this.records}
                showCheckbox={false}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "users/admin-users/bulk-action"})}
                rowActions={this.rowActionButtons}
              />
            )}
          />
          <SeeOrders />
        </ListingLayout>
    )
  }
}
