import React, {useState, useEffect} from 'react';
import {Breadcrumb, RequestWaiting} from "../../../common";
import Lang from "../../../common/lang/Lang";
import {ChangePwdBreadcrumb} from "../Data";
import {RouteComponentProps} from "react-router";
import { QuizModel} from "../../../model";
import {QuizResponseList} from "../QuizResponseList";
import {Modal} from "react-bootstrap";
import {onlineTestAnswerService} from "../../../services";
import {TranslatorOnlineTestAnswerModel} from "../../../model";
import { UserModel} from "../../../model";
import {getOnlineTestAnswers} from "../../../services";
import _ from 'lodash';

export function TranslatorOnlineTestAnswer(props:any) {
  const [quiz, setQuiz] = useState({} as QuizModel);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [content, setContent] = useState([] as Array<TranslatorOnlineTestAnswerModel>);
  const [user, setUser] = useState({} as UserModel);


  useEffect(() => {
    onlineTestAnswerService.getPopupState().subscribe((res:any) => {
        getOnlineTestAnswers(res.user.id).then(res1 => {
            setShow(res.show);
            setQuiz(res1);
        });
    })
  }, []);


  if(show === false){
    return null;
  }
  return(
    <Modal show={show} onHide={() => setShow(false) } size="lg" 
    aria-labelledby="contained-modal-title-vcenter">
      <form>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {Lang.about_translator}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                  <div className="kt-portlet kt-portlet--mobile">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="kt-portlet">
                          <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                              <h3 className="kt-portlet__head-title">{quiz.title}</h3>
                            </div>
                          </div>
                          <br/>
                          <div className="kt-notification-v2">
                            {
                              _.isEmpty(quiz) &&
                                <div className={'col-md-12'}>
                                  <div className={'kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded'}>
                                    <div className="alert alert-outline-warning fade show" role="alert">
                                      <div className="alert-icon"><i className="flaticon-warning"/></div>
                                      <div className="alert-text">{Lang.test_not_taken}</div>
                                    </div>
                                  </div>
                                </div>
                            }
                            <QuizResponseList quiz={quiz}/>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={'btn btn-primary'} onClick={() =>  setShow(false) }>{Lang.close}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
