import * as React from 'react';
import API from "../../../common/AxoisClient";
import {Select2Wrapper} from "../../../common/Select2";
import Localization, {default as Lang} from "../../../common/lang/Lang";
import {getQueryStringParams,SearchSelect, SearchInput, DateInput, Select} from "../../../common";
import { DatetimePickerWrapper } from '../../../common/form/DatetimePicker';
import moment from 'moment'
import _, { cloneDeep } from 'lodash'
import { constants } from 'crypto';
import Selector from '../../../common/form/Selector';
import MultiSelector from '../../../common/form/MultiSelector';
import { CompanyModel } from '../../../model/Company' 
import { DepartmentModel } from '../../../model/Department'
import { DepartmentUnitModel } from '../../../model';
import style from './ActivityLogFilter.module.scss'
import Search from '../../../common/form/Search';
import FocusController from '../../../common/form/FocusController/FocusController';

interface IState {
  form: {
    like: {
      message: string,
    },
    equal: {
      action_by: number | string,
      model: ('App\\Model\\OrderInvitation' | 'App\\Model\\Order' | 'App\\User' | 'App\\Model\\OrderComment' | 'App\\Model\\OrderCancel' | 'App\\Model\\NotificationSetting' | 'App\\Model\\Translator' | 'App\\Model\\Customer' | 'App\\Model\\DepartmentUnit' | 'App\\Model\\OrderEdit' | 'App\\Model\\Department' | 'App\\Model\\Client' | 'App\\Model\\Company' | 'App\\Model\\TranslatorLanguage' | 'App\\Model\\TranslatorLeave' | 'App\\Model\\FavUnFavTranslator' | 'App\\Model\\CallLog' | 'App\\Model\\Enquiry' | 'App\\Model\\TranslatorRate' | 'App\\Model\\EmailTemplates' | 'App\\Model\\BlockTranslator' | 'App\\Model\\SendSms' | 'App\\Model\\TranslatorComment' | 'App\\Model\\ClaimReply' | 'App\\Model\\Claim' | 'App\\Model\\UserTempEmails' | 'App\\Model\\UserPermission' | 'App\\Model\\SmsTemplate' | 'App\\Model\\Location' | 'App\\Model\\Language' | 'App\\Model\\Setting' | 'App\\Model\\Role' | 'App\\Model\\Holiday' | 'App\\Model\\Quiz' | 'App\\Model\\WeLearn')[]
      types: Array<'customer' | 'translator' | 'admin'>
      companies: (number | string)[]
      departments: (number | string)[]
      department_units: (number | string)[]
    },
    between: {
      action_at: {
        from: string
        to: string
      }
    }
  }
  companies: Partial<CompanyModel>[]
  departments: Partial<DepartmentModel>[]
  department_units: Partial<DepartmentUnitModel>[]
 }

export default class SMSFilter extends React.Component<any, IState> {
  state: IState;

  models: IState['form']['equal']['model'] = ['App\\Model\\OrderInvitation' , 'App\\Model\\Order' , 'App\\User' , 'App\\Model\\OrderComment' , 'App\\Model\\OrderCancel' , 'App\\Model\\NotificationSetting' , 'App\\Model\\Translator' , 'App\\Model\\Customer' , 'App\\Model\\DepartmentUnit' , 'App\\Model\\OrderEdit' , 'App\\Model\\Department' , 'App\\Model\\Client' , 'App\\Model\\Company' , 'App\\Model\\TranslatorLanguage' , 'App\\Model\\TranslatorLeave' , 'App\\Model\\FavUnFavTranslator' , 'App\\Model\\CallLog' , 'App\\Model\\Enquiry' , 'App\\Model\\TranslatorRate' , 'App\\Model\\EmailTemplates' , 'App\\Model\\BlockTranslator' , 'App\\Model\\SendSms' , 'App\\Model\\TranslatorComment' , 'App\\Model\\ClaimReply' , 'App\\Model\\Claim' , 'App\\Model\\UserTempEmails' , 'App\\Model\\UserPermission' , 'App\\Model\\SmsTemplate' , 'App\\Model\\Location' , 'App\\Model\\Language' , 'App\\Model\\Setting' , 'App\\Model\\Role' , 'App\\Model\\Holiday' , 'App\\Model\\Quiz' , 'App\\Model\\WeLearn']
  UNSAFE_componentWillMount() {
    let old_data: IState['form'] = JSON.parse(getQueryStringParams("form") as string);
    API.get('settings/company').then(res => {
      this.setState({...this.state, companies: res.data.data})
    })
    this.setState({
      form:  {
        like: {
          message: old_data ? old_data.like.message : '',
        },
        equal: {
          action_by: old_data ? old_data.equal.action_by : '',
          model: [],
          types: [],
          companies: [],
          departments: [],
          department_units: []
  
        },
        between: {
          action_at: {
            from: old_data ? old_data.between.action_at.from : '',
            to: old_data ? old_data.between.action_at.to : ''
          }
        }
      },
      companies: [],
      departments: [],
      department_units: []
    })
  }
  
  handleInputForm = (e: React.ChangeEvent<any>) => {
    const name = e.target.name;
    const value = e.target.value
    const newState: IState = _.cloneDeep(this.state)
    _.set(newState, name, value)
    this.setState(newState, () => this.props.getResults(this.state.form))
  }

  translatorList = async (params: any) => {
    const translator = await API.post('activitylogs/user-search-list', {params: params});
       return translator.data;
  }

   formDataReset = (e:any) => { 
        e.preventDefault();
        this.setState({
          ...this.state,
          form:  {
            like: {
              message: '',
            },
            equal: {
              action_by: 0,
              model: [],
              types: [],
              companies: [],
              departments: [],
              department_units: []
            },
            between: {
              action_at: {
                from: '',
                to: ''
              }
            }
          }
        });     

        const formData: IState['form'] = {
          like: {
            message: '',
          },
          equal: {
            action_by: 0,
            model: [],
            types: [],
            companies: [],
            departments: [],
            department_units: []
          },
          between: {
            action_at: {
              from: '',
              to: ''
            }
          }
        };

         this.setState({...this.state, form: formData},() =>  this.props.getResults(this.state.form));
    }

    handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = moment(event.target.value).format('YYYY-MM-DD HH:mm:ss')
      const newState = cloneDeep(this.state)
      switch (name) {
        case 'from':
          newState.form.between.action_at.from = value
          break
        case 'to':
          newState.form.between.action_at.to = value
          break
      }
      this.setState(newState, () => this.props.getResults(this.state.form))
    }

    // function that eliminates sub-category when parent is deselected
    updateChildList = async (caller: 'companies' | 'departments') => {
      const parentSelectedList = caller === 'companies' ? this.state.form.equal.companies : this.state.form.equal.departments
      const child = caller === 'companies' ? 'departments' : 'department_units'
      const apiUrl = caller === 'companies'? 'settings/department/depts-from-companies/' : 'settings/department-unit/units-from-depts/'
      const newState = _.cloneDeep(this.state)
      if ( parentSelectedList.length > 0 ) {
        // [1] We get & update the child array to render a new selection dropdown based on new selected companies
        // [2] We also remove the selected/checked child items that no longer are in the child select dropdown
        // Here we're doing [1]
        const childListResponse: (Partial<DepartmentModel> | Partial<DepartmentUnitModel>)[] = (await API.get(apiUrl + JSON.stringify(parentSelectedList))).data
        _.set(newState, child, childListResponse)
        // Here we're doing [2]
        const idListOfNewChildList = childListResponse.map((item) => item.id)
        const prunedChildSelectedList: (string | number)[] = [..._.get(this.state.form.equal, child)].filter(item => idListOfNewChildList.includes(Number(item)))
        _.set(newState.form.equal, child, prunedChildSelectedList)
        // Finally updating
      } else {
        // We don't need to fetch new child list here since it will be set to empty array
        _.set(newState, child, [])
        _.set(newState.form.equal, child, [])
      }
      this.setState(newState)
    }

    handleAffiliationChange = (form: IState['form'], caller: 'companies' | 'departments' | 'department_units') => {
      const updateResultsAndChildList = () => {
        this.props.getResults(this.state.form)
        if (caller !== 'department_units') {
          this.updateChildList(caller)
        }
      }
      this.setState({ form }, updateResultsAndChildList)
    }

    makeOptionsList = (target: 'companies' | 'departments' | 'department_units'): { value: number, label: string, checked: boolean }[] => {
        const targetList = _.get(this.state, target)
        return targetList.length > 0 ? (targetList as (Partial<CompanyModel> | Partial<DepartmentModel> | Partial<DepartmentUnitModel>)[]).map(item => ({
          value: item.id,
          label: item.name,
          checked: _.get(this.state.form.equal, target).includes(String(item.id))
        })) : []
    }

    filterTrigger = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
    }

    dynamicStyle: React.CSSProperties = {
      width: '20rem',
    }

  render(){
    const Insta = this;
    const userTypes = [
      {
        label: 'Customer',
        value: 'customer',
        checked: this.state.form.equal.types.includes('customer')
      },
      {
        label: 'Translator',
        value: 'translator',
        checked: this.state.form.equal.types.includes('translator')
      },
      {
        label: 'Admin',
        value: 'admin',
        checked: this.state.form.equal.types.includes('admin')
      }
    ]
    const modelOptions = this.models.map(model => {
      const labelParts = model.split('\\')
      return {
        value: model,
        label: labelParts[labelParts.length - 1],
        checked: this.state.form.equal.model.includes(model)
      }
    })
    const [
      companyOptions,
      departmentOptions,
      departmentUnitOptions
    ] = [
      this.makeOptionsList('companies'),
      this.makeOptionsList('departments'),
      this.makeOptionsList('department_units')
    ]
    
    return (
        <div className={style.container}>
          <FocusController default="expand" value={this.state.form.like.message} className={style.search}>
            <Search
              label="Search"
              name="form.like.message"
              value={this.state.form.like.message}
              onChange={this.handleInputForm}
            />
          </FocusController>
          <FocusController default="contract" value={this.state.form.equal.action_by} className={style.user}>
            <Select2Wrapper
              name={'form.equal.action_by'}
              value={this.state.form.equal.action_by}
              onChange={this.handleInputForm}
              className={'form-control'}
              data= {{
                ajax: {
                transport: function (params,success, failure) {
                  let request = Insta.translatorList(params.data);
                  request.then(success);
                  request.catch(failure);
                  return request;
                }
                },
                placeholder: 'Select User',
                minimumInputLength: 3,
              }}
            />
          </FocusController>
          <FocusController className={style.type} value={this.state.form.equal.types}>
            <MultiSelector<IState['form']>
              label={`User Type ${this.state.form.equal.types.length > 0 ? `(${this.state.form.equal.types.length} Selected)` : ''}`}
              name="equal.types"
              form={this.state.form}
              updateState={(newState: IState['form']) => {
                this.setState(
                  ({form: newState}),
                  () => this.props.getResults(this.state.form)
                )
              }}
              options={userTypes}
            />
          </FocusController>
          {
            companyOptions.length > 0 &&
              <FocusController className={style.type} value={this.state.form.equal.companies}>
                <MultiSelector<IState['form']>
                  label={this.state.form.equal.companies.length > 0 ? `Companies (${this.state.form.equal.companies.length} selected)` : "Companies"}
                  name="equal.companies"
                  form={this.state.form}
                  updateState={(newForm: IState['form']) => { this.handleAffiliationChange(newForm, 'companies') }}
                  options={companyOptions}
                />
              </FocusController>
          }
          {
            departmentOptions.length > 0 &&
              <FocusController value={this.state.form.equal.departments}>
                <MultiSelector<IState['form']>
                  label={this.state.form.equal.departments.length > 0 ? `Departments (${this.state.form.equal.departments.length} selected)` : "Departments"}
                  name="equal.departments"
                  form={this.state.form}
                  updateState={(newForm: IState['form']) => { this.handleAffiliationChange(newForm, 'departments') }}
                  options={departmentOptions}
                />
              </FocusController>
          }
          {
            departmentUnitOptions.length > 0 &&
              <FocusController value={this.state.form.equal.department_units}>
                <MultiSelector<IState['form']>
                  label={this.state.form.equal.department_units.length > 0 ? `Units (${this.state.form.equal.department_units.length} selected)` : "Units"}
                  name="equal.department_units"
                  form={this.state.form}
                  updateState={(newForm: IState['form']) => { this.handleAffiliationChange(newForm, 'department_units') }}
                  options={departmentUnitOptions}
                />
              </FocusController>
          }
          <FocusController value={this.state.form.equal.model} className={style.event}>
            <MultiSelector<IState['form']>
              label="Event Type"
              name="equal.model"
              form={this.state.form}
              updateState={(newState: IState['form']) => {
                this.setState(
                  ({form: newState}),
                  () => this.props.getResults(this.state.form)
                )
              }}
              options={modelOptions}
            />
          </FocusController>
          {/* <FocusController value={this.state.form.between.action_at.from} className={style.timedate}>
            <DatetimePickerWrapper
              value={this.state.form.between.action_at.from}
              name="from"
              className="form-control"
              placeholder="From Date"
              onChange={this.handleDateChange}
            />
          </FocusController>
          <FocusController value={this.state.form.between.action_at.to} className={style.timedate}>
            <DatetimePickerWrapper
              value={this.state.form.between.action_at.to}
              name="to"
              className="form-control"
              placeholder="To Date"
              onChange={this.handleDateChange}
            />
          </FocusController> */}
          <div className={`col-md-4 kt-margin-b-20-tablet-and-mobile custom-reset-button ${style.reset_button}`}>
              <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn'} data-skin={'dark'} title={Lang.reset} onClick={(e)=>this.formDataReset(e)}>{Lang.reset}</button>
          </div>
        </div>
    )
  }
}
