import * as React from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {getOrderForCal} from "../../../services";
import Localization, {default as Lang} from "../../../common/lang/Lang";
import {DateInput, getQueryStringParams,SearchSelect, SearchInput} from "../../../common";
import {EconomicsConstant} from '../Data';
const moment = require('moment');

interface IState {
  form: {
    like: {
      search: string,
    },
    equal: {
      economics_sync_status: number
    }
  },
 }

export default class DepartmentUnitFilter extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
        like: {
          search: old_data ? old_data.like.search : '',
        },
        equal: {
          economics_sync_status: old_data ? old_data.equal.economics_sync_status : ''
        }
       
      }})
  }

  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState({form: newAccess},() =>  this.props.getResults(this.state.form));
  }

    formDataReset = (e:any) => { 
        e.preventDefault();
        this.setState({
            form:  {
                like: {
                  search: '',
                },
                equal: {
                  economics_sync_status: 0
                }
                
            }
        });     

        var formData = {
                        like: {
                            search: '',
                          },
                          equal: {
                            economics_sync_status: 0
                          },
                      };

         this.setState({form: formData},() =>  this.props.getResults(this.state.form));
    }

 

  render(){
  //  const {status} = this.props;
    const economics_sync_status = EconomicsConstant.economics_sync_status;
    return (
      <div className="row align-items-center">
      <SearchInput name={'form.like.search'} value={this.state.form.like.search} onChange={this.handleInputForm} placeholder={'Search for...'} />
      <SearchSelect name={'form.equal.economics_sync_status'} value={this.state.form.equal.economics_sync_status}  onChange={this.handleInputForm} options={economics_sync_status}/>
      <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn custom-reset-button'} data-skin={'dark'} title={Lang.reset} onClick={(e)=>this.formDataReset(e)}>{Lang.reset}</button>
     </div>
    )
  }
}