export const Icons = {
  users: "flaticon-users",
  download: "la la-download",
  excel: "la la-file-excel-o",
  file: "la la-file-text-o",
  add: "la la-plus",
  edit: "la la-edit",
  delete: "flaticon-delete",
  view: "flaticon2-information",
  company_info: "flaticon2-list-3",
  company_time: 'flaticon-time-1',
  users2: 'flaticon-users-1',
  password: 'flaticon-lock',
  claim: 'flaticon-interface-4',
  email: 'flaticon2-new-email',
  mobile_no: 'flaticon2-phone',
  address: 'flaticon2-maps',
  order: 'flaticon2-shopping-cart-1',
  female: 'fa fa-female',
  male: 'fa fa-male',
  bank: 'la la-bank',
  setting: 'flaticon2-gear',
  permission: 'flaticon-lock',
  language: 'fa fa-language',
  user: 'flaticon2-user',
  password2: 'flaticon2-lock',
  order2: 'flaticon2-shopping-cart',
  notification: 'flaticon2-notification',
  tick_mark: 'flaticon2-check-mark',
  user_tick: 'fa fa-user-check',
  user_untick: 'fa fa-user-times',
  contract: 'flaticon2-contract',
  notes: 'flaticon-notes',
  cancel: 'flaticon-cancel',
  comment: 'flaticon-comment',
  price: 'fa fa-dollar-sign',
  restore: 'fa fa-trash-restore',
  complete: 'flaticon2-check-mark',
  sms: 'fa fa-sms',
  dollor: 'fa fa-dollar-sign',
  leaves: 'fa fa-user-cog',
  cross: 'fa fa-times',
  question: 'flaticon-questions-circular-button',
  activity_log : 'fa fa-history',
  refresh : 'flaticon-refresh',
  bell : 'fa fa-bell',
  enquire : 'fa fa-question-circle',
  import:'fa fa-file-import',
  rating_star:'fa fa-star',
  ban: "fa fa-ban",
  minusUser: "fas fa-user-minus",
  plusUser: "fas fa-user-plus",
  moon: "fas fa-moon",
  holiday: "fas fa-umbrella-beach",
  translators: "fa fa-address-card",
  portrait: "fas fa-portrait",
  paper: "fas fa-file",
  clipboard: "fas fa-clipboard",
  external_link: 'fas fa-external-link-alt',
  star: "fas fa-star",
  star_half: "fas fa-star-half-alt",
  star_empty: "far fa-star",
  online: "fas fa-circle",
  offline: "far fa-circle",
  handshake: "fas fa-handshake",
  medal: "fas fa-id-card",
  medal_alt: 'fa fa-medal',
  heart: "fas fa-heart",
  broken_heart: "fas fa-heart-broken",
  order_telephone: "fas fa-video",
  order_physical: "fas fa-map-marker-alt",
  order_video: "fas fa-video",
  order_sign: "fas fa-american-sign-language-interpreting",
  order_message: "fas fa-sms",
  order_document: "fas fa-file-word",
  exchange: 'fas fa-exchange-alt',
  email_at: 'fas fa-at',
  mobile: 'fas fa-mobile',
  calendar: 'fas fa-calendar-alt',
  clock: 'fas fa-clock',
  clock_regular: 'far fa-clock',
  parasol: 'fas fa-umbrella-beach',
  receipt: "fas fa-receipt",
  calendar_plus: "far fa-calendar-plus",
  hardhat: "fas fa-hard-hat",
  next: "fas fa-angle-double-right",
  chart: 'fas fa-chart-area',
  next_page: "fas fa-angle-right",
  prev_page: "fas fa-angle-left"
}
