import { CompanyModel } from "../../model"

export enum INVOICE_JOB_STATUS {
  SUCCESS = 'success',
  FAIL = 'fail',
  PLANNED = 'planned',
  RETRY = 'retry',
  WORKING = 'working',
  NEXT = 'next',
}

export interface currentJob {
  id: number
  company_id: number
  company: Pick<CompanyModel, 'id' | 'name' | 'logo'>
  status: INVOICE_JOB_STATUS
  handled_orders_count: number
  order_count: number
  batch_count: number
  created_at: string
  updated_at: string
}

export interface nextJob {
  id: number
  company_id: number
  company: Pick<CompanyModel, 'id' | 'name' | 'logo'>
  order_count: number
  batch_count: number
}

export interface Statistics {
  queuedOrders: number
  yesterdayOrders: number
}

export type allJobs = Omit<currentJob, 'handled_orders_count'>[]