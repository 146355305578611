import * as React from 'react'
import {Icons} from "../../common/Icon";
import Lang from "../../common/lang/Lang";
import {UserModel} from "../../model/User";
import {Link} from "react-router-dom";


interface InfoProps {
  UserInfo: UserModel

}


export const CustomerWidget = (props: InfoProps) => {
 
  return(
   
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__body">
            <div className="kt-widget kt-widget--user-profile-3">
              <div className="kt-widget__top">
                <div className="kt-widget__top">
                  <div className="kt-widget__media kt-hidden-">
                    <img src={props.UserInfo.profile_image} alt="image"/>
                  </div>
                  <div className="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-boldest kt-font-light kt-hidden">
                    JM
                  </div>
                  <div className="kt-widget__content">
                    <div className="kt-widget__head">
                      <Link to={'#'} className="kt-widget__username">
                        {props.UserInfo.name}
                        <i className="flaticon2-correct"/>
                      </Link>
                    </div>
                    <div className="kt-widget__subhead">
                      <Link to={'#'}><i className={Icons.email}/>{props.UserInfo.email}</Link>
                      <Link to={'#'}><i className={Icons.mobile_no}/>{props.UserInfo.mobile_no}</Link>
                      <Link to={'#'}><i className={Icons.address}/>{props.UserInfo.address}</Link>
                    </div>
                    <div className="kt-widget__info">
                      <div className="kt-widget__desc">
                        <div className={'row'}>
                          <div className={'col-md-6 col-lg-6'}><strong>{Lang.company}</strong> - {props.UserInfo.company.name}</div>
                          {props.UserInfo.customer.department &&
                            <div className={'col-md-6 col-lg-6'}><strong>{Lang.department}</strong> - {props.UserInfo.customer.department.name}</div>
                          }
                          {Number(props.UserInfo.customer.customer_type) === 1 &&
                            <div className={'col-md-6 col-lg-6'}><strong>{Lang.ean_number}</strong> - {props.UserInfo.customer.ean_number}</div>
                          }
                          {Number(props.UserInfo.customer.customer_type) === 2 &&
                            <div className={'col-md-6 col-lg-6'}><strong>{Lang.cpr_number}</strong> - {props.UserInfo.customer.cpr_number}</div>
                          }

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}