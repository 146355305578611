import React, {useRef, useEffect} from 'react';
import {DatetimepickerOptions} from "bootstrap.v3.datetimepicker";
import _ from 'lodash';

//https://www.malot.fr/bootstrap-datetimepicker/index.php

export const datePicker = {format: 'dd-mm-yyyy',modal: true, header: true, type:'date',locale: 'da-da', weekStartDay: 1};
export const timePicker = {format: 'HH:ii', modal: true, header: true, footer: true, type:'time', mode: '24hr'};
export const datetimePicker = {format: 'dd-mm-yyyy hh:ii', modal: true, header: true, footer: true, type:'datetime'};

export interface DatetimePickerProps  extends React.InputHTMLAttributes<HTMLInputElement> {
  options?: DatetimepickerOptions
}


export function DatetimePickerWrapper (props: DatetimePickerProps) {

  const datetime_picker = useRef(null);

  const options = {
    format: 'yyyy-mm-dd hh:ii'
  };

  useEffect(() => {
    const $el = datetime_picker.current;
    if($el !== null ){
      $el.readOnly = true;
      $($el).datetimepicker({...options, ...props.options})
        .on('changeDate', function(ev:any){
         if(props.onChange !== undefined){
           props.onChange(ev)
         }
        });
    }
  });

  return(
    <input type={'text'} ref={datetime_picker} {... _.omit(props, ['options', 'ref'])} />
  )
}