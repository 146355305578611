import * as React from 'react';
import Lang from "../../common/lang/Lang";
import {CreateBreadcrumb} from "./Data";
import API from './../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {showNotification} from "../../common/Common";
import {RoleValues} from "../../model/Role";
import {RoleValidations} from "./Validation";
import {CreateLayout} from "../../layout/CreateLayout";
import {CommonSubmitButton} from "../../common/form/CommonSubmitButton";
import {ChoosePermission} from "./ChoosePermission";
import {PermissionModel} from "../../model";
import {SwitchLoader} from "../../common";
import {LoaderType} from "../../common/Loader";
import _ from 'lodash';
declare var window: any;

interface IState{
  form: {
    name: string,
    permissions: Array<string>
  };
  scopes: Array<any>;
  loading: boolean;
}

interface CheckAllModules {
    key: string;
    value: Array<PermissionModel>
}


export class RoleCreate extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props: RouteComponentProps){
    super(props);
    this.state = {
      form: RoleValues(),
      scopes: [],
      loading: false
    }
   RoleValidations();
  }

  componentDidMount() {
    API.get('permission/role/create').then((res: any) => {
      this.setState({
        scopes: res.data
      }, () => {
        window.KTApp.initTooltips();
      })
    })
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

  handleScopes = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if(event.target.checked === true){
      this.setState(prevState => {
        return { form: { ...this.state.form, permissions: [...prevState.form.permissions, id]} }
      });
    } else {
      this.setState({ form: { ...this.state.form, permissions: this.state.form.permissions.filter((item: string) => {
            return item !== id
          })} });
    }
  }


  CheckAll = (event: React.ChangeEvent<HTMLInputElement>, role: Array<PermissionModel>) => {
    let scopes = [];
    role.map((item: PermissionModel) => {
      scopes.push(item.scope);
    })
  //  let remove_item = scopes.toString();
     if(event.target.checked === true) {
      let old_permissions = this.state.form.permissions;
      let new_permissions = _.concat(old_permissions, scopes);
       this.setState({
         form: { ...this.state.form, permissions: new_permissions}
       });
    } else {
        let old_permissions = this.state.form.permissions;
        let  new_permissions =  _.pullAll(old_permissions, scopes);
       this.setState({
       form: { ...this.state.form, permissions: new_permissions}
     });
   }
 }


    CheckedAllValues = (event: React.ChangeEvent<HTMLInputElement>, rolesPermission) => {
        let scopes = [];
        Object.entries(rolesPermission).map((key : any, value: number) =>  {
            key[1].map((item: PermissionModel) => {
                scopes.push(item.scope);

            })
        })
        if(event.target.checked === true) {
            let old_permissions = this.state.form.permissions;
            let new_permissions = _.concat(old_permissions, scopes);
            this.setState({
                form: { ...this.state.form, permissions: new_permissions}
            });
        } else {
            let old_permissions = this.state.form.permissions;
            let  new_permissions =  _.pullAll(old_permissions, scopes);
            this.setState({
                form: { ...this.state.form, permissions: new_permissions}
            });
        }

    }

 handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#create_role").valid()) {
      this.setState({loading: true});
      API.post('permission/role', this.state.form).then((res) => {
        this.setState({loading: false});
        showNotification("success", Lang.role_created);
        this.props.history.push(`/permission/role`)
      })
    }
  }
    

render() {
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'add_new_role'}>
          <form className="kt-form form-horizontal" onSubmit={this.handleSubmit} id={'create_role'}>
            <div className="kt-portlet__body">
              <div className={'row'}>

                <div className={"col-md-3 col-lg-3"} >
                  <label className={'control-label '}> {Lang.title} <span className={'required'}>*</span></label>
                </div>
                <div className={"col-md-9 col-lg-9"} >
                  <div className="form-group">
                    <input type={'text'} onChange={this.handleChange} value={this.state.form.name} name={'name'} className="form-control" placeholder={Lang.getString(`role_title_placeholder`)} required={true}/>
                  </div>
                </div>
              </div>
              <ChoosePermission CheckAll={this.CheckAll} CheckedAllValues={this.CheckedAllValues} onChange={this.handleScopes}  permissions={this.state.form.permissions} scopes={this.state.scopes}/>
            </div>
            {/*<button className={'btn btn-default hvr-rectangle-out'} data-skin={'dark'} title={Lang.reset} onClick={(e) => {this.resetForm(e)}}>{Lang.reset}</button> &nbsp;&nbsp;*/}
            <CommonSubmitButton/>
          </form>
        </CreateLayout>
    )
  }
}