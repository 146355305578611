import React, {useState,useEffect} from 'react';
import {default as Lang} from "../../common/lang/Lang";
import {OrderModel, OrderStatus,OrderType} from "../../model";
import {
  SendCustomerMailPopupService, SendTransMailPopSubjectService, TranslatorService, CancelOrderService,
  OrderCommentService, FixPriceSubjectService,FixPriceCalculationSubjectService, CalllogSubjectService, OrderHistoryService, restoreOrder, OrderCompleteService,
  changeToActiveOrder, OrderCorrectionService
} from "../../services";
import {copyCodeToClipboard} from "../../helpers/OrderHelper";
import {GeneratePermitLink, Icons, is_permit, base_url} from "../../common";
import _ from "lodash";
import API from "../../common/AxoisClient";
import {Auth} from "../../Auth/Auth";
import {NotificationListNewService} from "../../services";
import {OrderConst} from "./Data";

interface MenuSchema extends React.HTMLAttributes<any>{
  scope: string, icon: string, label: string, show: boolean, to?: string, html?: boolean
}
const CreateMenu = (props: {meun : Array<MenuSchema>,type :string, data:OrderModel} ) => {

  const [oldcustomerCheck, setOldCustmerCheck] = useState(0);
  const [adminname, setAdminName] = useState('');
   
 useEffect(() => {
     
    if(props.data.handle_by !== null){
      setOldCustmerCheck(1)
      if(props.data.admin_user){
        setAdminName(props.data.admin_user.name)
      }
      
    } else{
      setOldCustmerCheck(0)
    }
    
  },[props.data.id]);


  function UpdateOldValue(event:any){
    
   if(event.target.checked === true) {
     setOldCustmerCheck(1)
      CustomerStatusUpdate()
   } else{
    setOldCustmerCheck(0)
    CustomerStatusUnchecked()
   }

  }

   
    function CustomerStatusUpdate(){

      API.post(`orders/orderolddata`,{id:props.data.id,admin_id:Auth.userInfo.id}).then(response => {
        setAdminName(response.data[0].admin_user.name)
        NotificationListNewService.notificationlistdata();
      })
     
    }

    function CustomerStatusUnchecked(){

      API.post(`orders/orderdataunchecked`,{id:props.data.id,admin_id:0}).then(response => {
        setAdminName('')
      })
     
    }
  
  return (
    <span style={{overflow: 'visible', position: 'relative', width: '80'}}>
      <div className="dropdown">
        {props.type === 'order' &&
        <a data-toggle="dropdown" className="btn btn-sm btn-clean btn-icon btn-icon-md" aria-expanded="false">
          <i className="flaticon-more-1"/>
        </a>
        }
         {props.type === 'info' &&
         <a data-toggle="dropdown" className="btn btn-label-success btn-sm btn-bold dropdown-toggle" aria-expanded="false">
          {Lang.action}
        </a>
        }
        <div className="dropdown-menu dropdown-menu-right"  x-placement="bottom-end">
          <ul className="kt-nav">
            {
              props.meun.map((item, index) => {
                 if(item.show){
                  if(item.html === true) {
                    return (
                      <li className="kt-nav__item" key={index}>
                        <a className="kt-nav__link"  href={item.to} target={'_blank'}>
                          <i className={`kt-nav__link-icon ${item.icon}`} />  <span className="kt-nav__link-text">{item.label}</span>
                        </a>
                      </li>
                    )
                  } else {
                    return (
                      <li className="kt-nav__item" key={index}>
                        <GeneratePermitLink className="kt-nav__link" scope={item.scope} to={item.to !== undefined ? item.to : '#'} {... _.omit(item, ['scope', 'to', 'icon', 'label', 'show'])}>
                          <i className={`kt-nav__link-icon ${item.icon}`} />  <span className="kt-nav__link-text">{item.label}</span>
                        </GeneratePermitLink>
                      </li>
                    )
                  }

                }
              })
              
            }
            <li className="kt-nav__item">
              <span className="kt-nav__link">
                <label className="kt-checkbox">
            <input type="checkbox" name= "old_id" checked={oldcustomerCheck === 1} value={1} onChange={(e)=>UpdateOldValue(e)}/><label>{adminname}</label>
            <span></span>
            </label>
            </span>
            </li>
         
          </ul>
        </div>
      </div>
    </span>
  )

};


export function OrderActions(props: {order: OrderModel, refresh: string}) {
  
  const Menus = [
    {scope: 'orders.assign-translator', onClick: () => TranslatorService.showPopup(props.order) , icon: Icons.users2, label: Lang.assign_trans, show: (props.order.status === OrderStatus.NEW_ORDER || props.order.status === OrderStatus.INVITATION_SENT) },
    {scope: 'orders.confirmation-mail-to-customer', onClick: () => SendCustomerMailPopupService.showPopup(props.order) , icon: Icons.email, label: Lang.send_mail_customer, show: true },
    {scope: 'orders.confirmation-mail-to-translator', onClick: () => SendTransMailPopSubjectService.showPopup(props.order) , icon: Icons.email, label: Lang.send_mail_translator, show: true },
    {scope: 'orders.cancel-order', onClick: () => CancelOrderService.showPopup(props.order, false) , icon: Icons.cancel, label: Lang.cancel_without_charge, show: true },
    {scope: 'orders.cancel-order', onClick: () => CancelOrderService.showPopup(props.order, true) , icon: Icons.cancel, label: Lang.cancel_with_charge, show: true },
    {scope: 'orders.fix-price', onClick: () => FixPriceSubjectService.showPopup(props.order, 'active') , icon: Icons.price, label: Lang.fix_price, show: true },
   // {scope: 'orders.comments', onClick: () => OrderCommentService.showPopup(props.order) , icon: Icons.comment, label: Lang.comments + ' ('+props.order.comments_count+')', show: true },
    {scope: 'orders.history', onClick: () => OrderHistoryService.showPopup(props.order) , icon: Icons.comment, label: Lang.order_history_label, show: true },
    {scope: 'orders.complete-order', onClick: () => OrderCompleteService.showPopup(props.order) , icon: Icons.complete, label: Lang.complete_order, show: true },
    {scope: 'orders.correction-request', onClick: () => OrderCorrectionService.showPopup(props.order, 'edit') , icon: Icons.edit, label: Lang.correction_request, show: props.order.edit_request_count >= 0 },
    {scope: 'orders.cancel-request', onClick: () => OrderCorrectionService.showPopup(props.order, 'cancel') , icon: Icons.edit, label: Lang.cancel_request, show: props.order.cancel_count >= 0 },
    {scope: 'orders.store', icon: Icons.file, label: Lang.reorder, to: `/users/customers/${props.order.customer_id}/orders/${props.order.id}/create/`, show: true  },
    
];

  return (

    <CreateMenu meun={Menus} type={props.refresh} data={props.order}/>
  )
}

 

export function CancelOrderActions(props: {order: OrderModel, refresh: () => void, type :string}) {
  const Menus = [
   // {scope: 'orders.comments', onClick: () => OrderCommentService.showPopup(props.order) , icon: Icons.comment, label: Lang.comments + ' ('+props.order.comments_count+')' , show: true},
    {scope: 'orders.history', onClick: () => OrderHistoryService.showPopup(props.order) , icon: Icons.comment, label: Lang.history, show: true },
    {scope: 'orders.restore-order', onClick: () => restoreOrder(props) , icon: Icons.restore, label: Lang.restore, show: true },
  ];
  return (
    <CreateMenu meun={Menus} type={props.type} data={props.order}/>
  )
}

export function CompleteOrderActions(props: {order: OrderModel, refresh: () => void, type :string}) {
  
  const Menus = [
   // {scope: 'orders.comments', onClick: () => OrderCommentService.showPopup(props.order) , icon: Icons.comment, label: Lang.comments + ' ('+props.order.comments_count+')' , show: true},
    {scope: 'orders.history', onClick: () => OrderHistoryService.showPopup(props.order) , icon: Icons.comment, label: Lang.history, show: true },
    {scope: '', icon: Icons.address, label: `${props.order.total_distance !== null ? _.round((props.order.total_distance/2),2): 0} KM`, show: props.order.translation_type_id === OrderType.PHYSICAL },
    {scope: 'orders.fix-price', onClick: () => FixPriceSubjectService.showPopup(props.order, 'active') , icon: Icons.price, label: Lang.fix_price, show: true },
      {scope: 'orders.restore-order', onClick: () => props.order.status === OrderStatus.CANCELLED ? restoreOrder(props) :  changeToActiveOrder(props) , icon: Icons.restore, label: Lang.restore, show: true },
    /* commented 17-Apr-2020 VTS-780
    {scope: 'orders.change-to-active', onClick: () => changeToActiveOrder(props) , icon: Icons.restore, label: Lang.restore, show: true },*/
    //{scope: 'orders.generate-pdf', icon: Icons.file, label: Lang.pdf, show: true, to: `${base_url}api/admin/orders/orders/generate-pdf/${props.order.id}`, html: true },
    {scope: 'orders.call-logs', onClick: () => CalllogSubjectService.showPopup(props.order) , icon: Icons.comment, label: Lang.call_log,   show: props.order.translation_type_id === OrderType.VIDEO },
    // {scope: 'orders.copy-detail', icon: Icons.file, label: Lang.copy_details, onClick: () => copyCodeToClipboard(props.order), show: true  },
    {scope: 'orders.store', icon: Icons.file, label: Lang.reorder, to: `/users/customers/${props.order.customer_id}/orders/${props.order.id}/create/`, show: true  },

  ];
  return (
    <CreateMenu meun={Menus} type={props.type} data={props.order}/>
  )
}