import Lang from "../../common/lang/Lang";

export const urls = {
  home: "/sms/",
  list: "/sms/System SMS",
}

export const ListBreadcrumb = [
  {
    label: Lang.sms,
    url: urls.home
  },
  {
    label: Lang.system_sms,
    url: "#"
  }
];

export const CreateBreadcrumb = [
  {
    label: Lang.email,
    url: urls.home
  },
  {
    label: Lang.email,
    url: urls.list
  },
  {
    label: Lang.create,
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: Lang.email,
    url: urls.home
  },
  {
    label: Lang.email,
    url: urls.list
  },
  {
    label: Lang.edit,
    url: '#'
  }
];