import React, {useEffect, useReducer, useRef} from 'react';
import {CalllogSubjectService} from "../../../services";
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {showNotification, SubmitButton} from "../../../common";
import {
  InitialState, PopupState, ActionType,
  CAction, FixPriceReducer
} from "../order-reducers/FixPriceReducer";
import {OrderModel} from "../../../model";
import {CallInfo} from "../CallInfo";

export function Calllog () {

  const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(FixPriceReducer , InitialState);

  useEffect( () => {
    CalllogSubjectService.getPopupState().subscribe((message:any) => {
      dispatch({type: message.action, payload: message}) ;
    });
  }, []);

  if(state.show === false){
    return null;
  }


  function handleChange(e: React.ChangeEvent<HTMLElement>) {
    const target : any = e.target;
    const value = target.value;
    const name = target.name;
    dispatch({type: ActionType.HANDLE_CHANGE, payload: { value: value, name: name } as any});
  }


  function handleSubmit(event) {
    
  }
  const {order_data} = state;
   
  return(
    <Modal
      show={state.show}
      onHide={() => { dispatch({type: ActionType.HIDE_POPUP}) } }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form onSubmit={handleSubmit} id="fix_price_form">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {Lang.order_no} - #{order_data.order_no}<br/>
            {Lang.customer_name} - {order_data.customer.name}<br/>
            {Lang.translatorname} - {order_data.translate_by.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <CallInfo info={order_data} />
        </Modal.Body>
        <Modal.Footer>

        <button className={'btn btn-primary'} onClick={() => { dispatch({type: ActionType.HIDE_POPUP}) } }>{Lang.close}</button>

        </Modal.Footer>
      </form>
    </Modal>
  )


}