import * as React from 'react';
import API, {base_url} from "../common/AxoisClient";
import {Auth} from "./Auth";
import {SwitchLoader, LoaderType} from "../common";
import { withCookies, Cookies } from 'react-cookie';
import LoginForm from "./form/Login";
import TwoFectorForm from "./form/TwoFector";
const moment = require("moment");
declare var window: any;

interface LoginSchema {
  email: string;
  password: string;
  remember: number;
  platform: string;
  admin: boolean;
  two_fector: boolean;
  received_otp?: number;
}

const loginDefaultValue = {
  email: '',
  password: '',
  remember: 0,
  platform: 'web',
  admin: true,
  two_fector: false
}

interface IState{
  form: LoginSchema
}

class Login extends React.Component <Cookies, IState>{
  state : IState;

  constructor(props: any) {
    super(props);

    this.state ={
      form: loginDefaultValue,
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    API.post(`${base_url}login`, this.state.form).then((res) => {
      this.setState({
        form: {
          ...this.state.form, two_fector: true, received_otp: res.data.otp
        }
      })
       Auth.setToken(res.data._token);
    })
  }

  backToLogin = () => {
    this.setState({
      form: {
        ...this.state.form, two_fector: false
      }
    });
  }

  render() {
    const { cookies } : any = this.props;
    return(
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin" id="kt_login">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" style={{backgroundImage: `url(${window.PUBLIC_URL}/admin/assets/media/bg/bg-3.jpg)` }}>
          <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
            <div className="kt-login__container">
              <div className="kt-login__logo">
                <a className="login_logo" href="#">
                  <img src={`${window.PUBLIC_URL}/admin/assets/media/logos/logo.png`}/>
                </a>
              </div>
              {
                !this.state.form.two_fector &&
                  <LoginForm {...this.state} handleSubmit={this.handleSubmit} handleChange={this.handleChange}/>
              }

              {
                this.state.form.two_fector &&
                <TwoFectorForm cookies={cookies} received_otp={this.state.form.received_otp} remember={this.state.form.remember} showLoginForm={this.backToLogin}/>
              }

            </div>
          </div>
        </div>
      </div>
  </div>
    );
  }
}

export default withCookies(Login);