import Lang from "../../common/lang/Lang";
import {CompanyModel, DepartmentModel, DepartmentUnitModel} from "../../model";
import * as _ from "lodash";
import {CustomerCreate} from "./Create";
import {CustomerEdit} from "./Edit";

export const urls = {
  users: "/users/",
  list: "/users/customers",
}

export const CustomerBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.customers,
    url: "#"
  }
];

export const CreateBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.customers,
    url: urls.list
  },
  {
    label: Lang.create,
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.customers,
    url: urls.list
  },
  {
    label: Lang.info,
    url: '#'
  }
];
export const CreateOrderBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.customers,
    url: urls.list
  },
  {
    label: Lang.order,
    url: '#'
  },
  {
    label: Lang.create_order,
    url: '#'
  }
];
export const OrderBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.customers,
    url: urls.list
  },
  {
    label: Lang.info,
    url: '#'
  },
  {
    label: Lang.order,
    url: '#'
  }
];
export const ChangePwdBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.customers,
    url: urls.list
  },
  {
    label: Lang.info,
    url: '#'
  },
  {
    label: Lang.change_password,
    url: '#'
  }
];

export const ActivityLogBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.customers,
    url: urls.list
  },
  {
    label: Lang.info,
    url: '#'
  },
  {
    label: Lang.activity_logs,
    url: '#'
  }
];

export interface Form {
  customer_type : string|number;
  name: string;
  email: string;
  password:string;
  cn_password:string;
  address:string;
  zip_code:string;
  city:string;
  mobile_no:string;
  order_contact_phone?:string;
  contact_person:string;
  contact_phone:string;
  contact_phone_2?:string;
  ean_number:string;
  cpr_number:string;
  department_id:string|number;
  department_unit_id:number|string;
  economics_department_unit_id:string;
  is_super_customer:number;
  identification_code:string;
  company_id:string;
  company_id_number: number | null;
  country_code?:string;
  profile_image?:string;
  status?:number;
  gender?:string;
  lat?:string;
  lng?:string;
  is_convert_to_public?:string|number;
  converted_to_company_id?: string|number;
  converted_to_department_id?: string|number;
  converted_to_department_unit_id?: string|number;
  converted_to_ean_number?: string;
}

export interface IState{
  form: Form;
  is_special_company: boolean;
  is_ean_found: boolean;
  is_cvr_found: boolean;
  is_contact_found?: boolean;
  departments?: Array<DepartmentModel>;
  department_units?: Array<DepartmentUnitModel>;
  company?: CompanyModel;
  is_prefilled?: boolean;
  id?: number;
  is_department_show?: boolean,
  _address?: boolean,
  old_fields?: Array<string>,
  countries?:Array<any>,
  countrycodes?:Array<any>,
  companies?: Array<any>,
  dept?: Array<any>,
  units?: Array<any>,
  isEmailExists? : number
}

export enum ACTIONS {
  COMPANY_DEFAULT = 'COMPANY_DEFAULT',
  NOT_SPECIAL = 'NOT_SPECIAL',
  SPECIAL = 'SPECIAL',
}

export const companySetup = {
  notSpecialDefault: (payload) => {
    return {
      is_prefilled: false,
      is_special_company: false,
      form: { ...payload,
        // company_id: '',
        // department_id: '',
        // department_unit_id: '',
        //economics_department_unit_id: ''
      },
      departments: [],
      department_units: []
    };
  },

  specialCompany: (company: CompanyModel, form, is_special = false,  edit = false) => {
    let is_department_show = false;
    let department_units = [];
    let form_data = {
      company_id: company.name,
      company_id_number: company.id
    };

    if(is_special){
      form_data['customer_type'] = 1;
    }
    let fields:  Array<string> = [];

    if(!_.isEmpty(company.prefilled_fields)){
      // company have some required fields
      fields = company.prefilled_fields.split(",").filter(field => !['mobile_no'].includes(field));

      // company only have dept units
      if(_.indexOf(fields, 'department_id') < 0 && _.indexOf(fields, 'department_unit_id') > -1){
        // doesn't need to show dept but set dept id default.
        is_department_show = false;
      }
      // company have dept and dept units both
      if(_.indexOf(fields, 'department_id') > -1 && _.indexOf(fields, 'department_unit_id') > -1){
        is_department_show = true;
      }
      // company doesn't have dept and dept units
      /*if(_.indexOf(fields, 'department_id') < 0 && _.indexOf(fields, 'department_unit_id') < 0){
        is_department_show = true;
      }*/

      if(!_.isEmpty(company.departments) ){
        let indexOfDepartment = company.departments.findIndex(department => String(department.id) === form.department_id);
        department_units = indexOfDepartment !== -1? company.departments[indexOfDepartment].units : [];
        // department_units = (company && company.departments && company.departments[0] && company.departments[0].units) ? company.departments[0].units : [];
        if(edit === false){
          // form_data['department_id'] = company.departments[0].id;
        } else {
          let units = company.departments.filter(dept => {
            return String(dept.id) === form.department_id;
          });
          department_units = (units && units[0] &&  units[0].units) ? units[0].units : [];
        }
      }

    }

    return {
      state: {
        is_special_company: is_special,
        is_department_show: is_department_show,
        company: company,
        departments: company.departments,
        department_units: department_units,
        form: { ...form, ...form_data},
        old_fields: fields,
        _address: _.indexOf(fields, 'address') > -1
      },
      required_fields: fields
    }
  },

  readOnly: ($class: CustomerCreate | CustomerEdit, fields: Array<string>, is_readonly: boolean ) => {
    _.forEach(fields, (field: string) => {
      if($class[field]){
        $class[field].readOnly = is_readonly;
      }       
    });
  },
  blank: (fields, form) => {
    let form_data = [];
    _.forEach(fields, (field: string) => {
      form_data[field] = '';
    });
    return {form: { ...form, ...form_data},is_special_company: false, _address: false}
  },

  autoFill: (state, value: string) => {
    type DUType = keyof DepartmentUnitModel;
    let units: Array<DepartmentUnitModel> = state.department_units.filter(dept => {
      return String(dept.id) === value;
    })

    if(!_.isEmpty(state.old_fields)){
      let form_data: any = {
        department_unit_id: value
      };
      if(value !== ''){
        _.forEach(state.old_fields, async (field: string) => {
          if(field !== 'department_unit_id' && field !== 'department_id' ){
            if(field === 'address'){
              form_data['lat'] = units[0]['latitude'];
              form_data['lng'] = units[0]['longitude'];
              form_data['address'] = units[0]['address'];
            }
            form_data[field] = units[0][field as DUType];
          }
        });
      }


      return {is_prefilled: true,form: {...state.form, ...form_data}};
    }
  }
};

export const EconomicsConstant = {
  economics_sync_status: [
    {value:"", label: Lang.economics_sync_status_select},
    {value:1, label: Lang.economics_sync_status_yes},
    {value:2, label: Lang.economics_sync_status_no}
  ]
};

export const SpecialStatus = {
  special_status: [
    {value:"", label: Lang.special_status_select},
    {value:1, label: Lang.special_status_yes},
    {value:2, label: Lang.special_status_no} 
  ]
}
