import Lang from "../../common/lang/Lang";

export const urls = {
    orders: "/orders/",
    list: "/orders/claims",
}

export const ListBreadcrumb = [
    {
        label: Lang.orders,
        url: urls.orders
    },
    {
        label: Lang.claims,
        url: "#"
    }
];

export const CreateBreadcrumb = [
    {
        label: Lang.orders,
        url: urls.orders
    },
    {
        label: Lang.claims,
        url: urls.list
    },
    {
        label: Lang.create,
        url: '#'
    }
];

export const EditBreadcrumb = [
    {
        label: Lang.orders,
        url: urls.orders
    },
    {
        label: Lang.claims,
        url: urls.list
    },
    {
        label: Lang.edit,
        url: '#'
    }
];


export const DetailBreadcrumb = [
    {
        label: Lang.orders,
        url: urls.orders
    },
    {
        label: Lang.claims,
        url: urls.list
    },
    {
        label: Lang.claim_detail,
        url: '#'
    }
];

