import React, {useState, useEffect} from 'react';
import {Icons, LoaderType, RequestWaiting, SetWaitingArea, SwitchLoader} from "../common";
import {OrderModel} from "../model";
import {DashboardService} from "../services";
import _ from 'lodash';
import Lang from "../common/lang/Lang";

const moment = require("moment");


export function TotalOrder(props) {
 
  const [log, setLog] = useState([{} as OrderModel]);

  useEffect(() => {
    getRecentLog();
  }, [props]);


  function getRecentLog() {
    SwitchLoader(LoaderType.NONE);
    SetWaitingArea('Totalorders')
    DashboardService.getOrderCount(props.startDate,props.endDate).then(res => {
       setLog(res)
    })
  }

  return (


    <div className="kt-portlet kt-portlet--fit kt-portlet--head-lg kt-portlet--head-overlay kt-portlet--skin-solid kt-portlet--height-fluid">
      <div className="kt-portlet__head kt-portlet__head--noborder kt-portlet__space-x">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
           
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar">
         
       </div>
      </div>
      <div className="kt-portlet__body kt-portlet__body--fit">
<div className="kt-widget17">
<div className="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides" style={{ backgroundColor:"#fd397a"}} >
<div className="kt-widget17__chart" style={{ height:"200px"}}>
</div>
</div>
<div className="kt-widget17__stats kt-margin-b-30">
<div className="row">

<RequestWaiting item={log} from={'log'}>
{
log.length > 0 && log.map((item: OrderModel, index: number) => {
if(!_.isEmpty(item)){
return (
<div className="col-md-6" key={index}> 
<div className="kt-widget17__items"> 
<div className="kt-widget17__item">
<span className="kt-widget17__icon">
<i className={Icons[item['icon']]} />
</span>
<span className="kt-widget17__subtitle">
{ item['title']}
</span>
<span className="kt-widget17__desc">
{item['count']}
</span>
</div>
</div>
</div>
)
}
})
}
</RequestWaiting>
</div>

</div>

</div>
</div>
      </div>
      

   
   )
}