import Lang from "../../common/lang/Lang";

export const urls = {
  home: "/settings/",
  list: "/settings/department-unit",
}

export const ListBreadcrumb = [
  {
    label: Lang.settings,
    url: urls.home
  },
  {
    label: Lang.department_unit,
    url: "#"
  }
];

export const CreateBreadcrumb = [
  {
    label: Lang.settings,
    url: urls.home
  },
  {
    label: Lang.department_unit,
    url: urls.list
  },
  {
    label: Lang.create,
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: Lang.settings,
    url: urls.home
  },
  {
    label: Lang.department_unit,
    url: urls.list
  },
  {
    label: Lang.edit,
    url: '#'
  }
];

export const EconomicsConstant = {
  economics_sync_status: [
    {value:"", label: Lang.economics_sync_status_select},
    {value:1, label: Lang.economics_sync_status_yes},
    {value:2, label: Lang.economics_sync_status_no}
  ]
};