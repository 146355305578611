
declare var window: any;

export const LanguageValidations = function () {

  $(document).ready(function () {

    $( "#create_role" ).validate({
      // define validation rules
      rules: {
        name: {
          required: true
        },
      }
      
    });
  });
}