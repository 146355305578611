import Lang from "../../common/lang/Lang";

export const urls = {
  home: "/systemInfo/",
  list: "/systemInfo/calllog",
}

export const ListBreadcrumb = [
  {
    label: Lang.system_info,
    url: urls.home
  },
  {
    label: Lang.call_log,
    url: "#"
  }
];

