import React, {useState, useEffect} from 'react';
import {Modal, ModalProps} from "react-bootstrap";
import Lang from "../../../common/lang/Lang";
import {UserModel} from "../../../model";
import API from "../../../common/AxoisClient";
import {EmailTemplateDataSchema} from "../../../settings/email-templates/Model";
import {UserContractModel} from "../../../model/UserContract";
import {Auth} from "../../../Auth/Auth";
import * as _ from 'lodash';


export interface ContractModel {
    subject?: string;
    content?: string;
    contractContent?: string;
    translator_id?: number;
    email_template?: EmailTemplateDataSchema;
    contract?: UserContractModel;
}

interface InfoProps extends ModalProps {
    transInfo: UserModel,
    transID?: number
    ContractInfo?: ContractModel
}

export const BlockTranslator = (props: InfoProps) => {

    // if(props.TransInfo === undefined)
    //  return null;

    const [allUsers, setAllUsers] = useState([]);
    const [searchVal, setSearchVal] = useState();
    const [searchuserslist, setSearchusers] = useState([]);
    const [usermessage, setUsermessage] = useState(false);
    const [selectallIds, setSelectallIds] = useState();
    const [userIds, setUserIds] = useState();


    useEffect(() => {
        API.get(`users/customers/block-userlist/${props.transInfo.id}`).then(response => {
            setAllUsers(response.data);
        })
    }, [props.transInfo.id]);


    function SearchUserlists(searchVal) {
        let searchChar = $.trim(searchVal);
        let customerLength = searchChar.length;
        if (customerLength >= 3) {
            API.post(`users/customers/block-searchlist`, {search: searchVal, id: props.transInfo.id}).then(response => {
                setSearchusers(response.data);
            })
        } else {
            setSearchusers([]);
        }
    }

    function UnfavoriteUseradd(selectallIds) {

        API.post(`users/customers/block-useradd`, {
            id: props.transInfo.id,
            userIds: selectallIds,
            block_by: Auth.userInfo.id
        }).then(response => {
            setAllUsers(response.data);
            SearchUserlists(searchVal)
        })

    }

    function UnfavoriteUserdelete(userIds) {
        API.post(`users/customers/block-userdelete`, {id: props.transInfo.id, userIds: userIds}).then(response => {
            setAllUsers(response.data);
            SearchUserlists(searchVal)
        })

    }


    function searchusers(event: any) {

        if (event.target.value.trim() == '') {
            setUsermessage(false)
            setSearchusers([])
        } else {
            setUsermessage(true)
            setSearchVal(event.target.value)
        }
        if (event.target.value.trim() != '') {
            SearchUserlists(event.target.value)
        }

    }

    function handleChange(event: any) {
        let value = event.target.value;
        const name = event.target.name;

        if (name === 'userIds') {
            if (event.target.checked === true) {
                setSelectallIds(event.target.value)
            } else {
                setSelectallIds(event.target.value)
            }
            UnfavoriteUseradd(event.target.value)
        }
    }

    function deleteuser(id: any) {
        setUserIds(id)
        UnfavoriteUserdelete(id)
    }


    return (


        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <form>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {Lang.block_translator_users}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                        <div className={'row'}>
                            <div className={"col-md-12 col-lg-12"}>
                                <div className="form-group">

                                    <input type="text" placeholder="Please type minimum 3 characters for search" className="form-control"
                                           onChange={(e) => searchusers(e)}/>
                                </div>
                                <div className="form-group">

                                    <div className="kt-checkbox-inline">

                                        {searchuserslist !== null && searchuserslist !== undefined && searchuserslist.length > 0 &&
                                        searchuserslist.map((item, i) => {
                                            return <label key={item.id} className="kt-checkbox">
                                                <input type="Checkbox" name={'userIds'}
                                                       onChange={(e) => handleChange(e)} value={item.id}
                                                />{item.name}
                                                <span></span>
                                            </label>
                                        })
                                        }
                                    </div>

                                    {usermessage === true && searchuserslist.length === 0 &&
                                    <div className="alert alert-outline-warning fade show">Translator not found</div>
                                    }


                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                        {allUsers.length > 0 &&
                        <table className="table table-bordered table-hover">

                            <thead className="kt-datatable__head">
                            <tr className="kt-datatable__row">
                                <th className="kt-datatable__cell kt-datatable__cell--sort kt-datatable__cell--sorted">Name</th>
                                <th className="kt-datatable__cell kt-datatable__cell--sort kt-datatable__cell--sorted">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {allUsers !== null && allUsers !== undefined && allUsers.length > 0 &&
                            allUsers.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{item.translator_userslist.name}</td>
                                        <button type="button" value={item.translator_userslist.id}
                                                onClick={(e) => deleteuser(item.translator_userslist.id)}
                                                className="btn btn-sm btn-danger"><i className="la la-trash"></i>
                                        </button>
                                    </tr>
                                )
                            })
                            }
                            </tbody>
                        </table>

                        }
                        {allUsers !== null && allUsers !== undefined && allUsers.length === 0 &&
                        <div className="alert alert-outline-warning fade show">Block Translator
                            not found</div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </form>
        </Modal>
    )
};