import * as React from 'react';
import Lang from "../../common/lang/Lang";
import {EditBreadcrumb} from "./Data";
import API from './../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {DepartmentModel,CompanyModel} from "../../model";
import {CreateLayout} from "../../layout/CreateLayout";
import {EmailValues} from "../../model/Department";
import {DepartmentValidations} from "./Validations";
import {LoaderType, showNotification, CommonSubmitButton,SwitchLoader, CountryFlags, Select, Breadcrumb} from "../../common";

declare var window: any;

interface IState{
  form: {
  
  name : string,
  notify_email : string,
  company_id : number,
  status? : number,
  
};
company : Array<any>;
id:number;
uploadDocument?: File;
  
}

export class DepartmentEdit extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props: any){
    super(props);

    this.state = {
      form: EmailValues(),
      id: props.match.params.id,
      company :[],
      uploadDocument : null,
    }
    DepartmentValidations();
  }
   
 
  componentDidMount() {
    API.get("settings/department/create").then(res => {
      this.setState({
        company: res.data
     })
   });

    API.get(`settings/department/${this.state.id}`).then((res: any) => {
      this.setState({
        form: EmailValues(res.data)
      })
    })
  }

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

  handleUpload = (e) => {
    this.state.uploadDocument = e.target.files[0];
    
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#customer_form").valid()) {
      API.put(`settings/department/${this.state.id}`, this.state.form, {params: {from: 'info'}}).then((res) => {
        showNotification("success", Lang.department_update);
          this.props.history.push(`/settings/department`);
      })
    }
  }


  

  render() {
     return (
    
      <CreateLayout breadcrumb={EditBreadcrumb} title={'department'}>
            
      <form className="kt-form" id={'customer_form'} onSubmit={this.handleSubmit}>
          <div className="kt-portlet__body">
              <div className={'row'}>
             <div className={"col-md-12 col-lg-12"}>
             <div className={'row'}>
                    <div className={"col-md-6 col-lg-6"}>
             <div className="form-group">
                             <label>{Lang.company} <span className={'required'}>*</span></label>
                             <Select className={'form-control'} onChange={this.handleChange} value={this.state.form.company_id || ''} id={'company_id'} name={'company_id'}  >
                        <option value={''}>{'Select Company'}</option>
                       {this.state.company.map((item: CompanyModel, index: number) => {
                        return <option key={index} value={item.id}>{item.name}</option>
                      })}
                    </Select>
                </div>   
                </div>
                <div className={"col-md-6 col-lg-6"}>
              <div className="form-group">
                          <label>{Lang.name}<span className={'required'}>*</span></label>
                          <input type={'text'}  value={this.state.form.name} name="name" onChange={this.handleChange} className="form-control"  />
               </div>
               </div>
               <div className={"col-md-6 col-lg-6"}>
              <div className="form-group">
                          <label>{Lang.email}</label>
                          <input type={'text'} value={this.state.form.notify_email}  name="notify_email" onChange={this.handleChange} className="form-control"  />
               </div>
               </div>
               </div>
              </div>
          </div>
          </div>
<CommonSubmitButton/>
      </form>
    
  </CreateLayout>

    )
  }
}