import * as React from 'react';
import Lang from "../../../common/lang/Lang";
import {CONST, Icons} from "../../../common";
import { OrderModel, OrderType } from "../../../model";
import * as _ from 'lodash';
import {useEffect, useState} from "react";
import API from "../../../common/AxoisClient";
import TravelDistance from "./TravelDistance";

const moment = require("moment");

interface Props {
  info: OrderModel
}

export const OrderDetails = (props: Props) => {

  const [data,  setData] = useState({travel_time:'', total_distance: ''});

  useEffect(() => {
    if( _.isEmpty(props.info.total_distance)  &&  _.isEmpty(props.info.travel_time)){
      API.get(`orders/orders/get-travel-distance`,{params: {order_id: props.info.id}}).then(res => {
        if(res.data.orderData!='') {
          setData({...data, travel_time: res.data.orderData.travel_time, total_distance: res.data.orderData.total_distance});
        } else {
          setData({...data, travel_time: '0', total_distance: '0'});
        }
      })
    } else {
      setData({...data, travel_time: String(props.info.travel_time), total_distance: String(props.info.total_distance)});
    }
  },[]);

  return (
    <div className="col-xl-4">
    <div className="kt-portlet kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title kt-font-success">
            {Lang.order_detail}                    
          </h3>
        </div>              
      </div>
      <div className="kt-portlet__body">              
        <div className="kt-widget4">

          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
             {Lang.company}
            </span>
            <span className="kt-widget4__number kt-font-info">{props.info.company.name}</span>
          </div>

          <div className="kt-widget4__item">
            <span className="kt-widget4__title kt-widget4__title--light">
             {Lang.customer_company_type}
            </span>
            <span className="kt-widget4__number kt-font-info">

              {props.info.customer.customer !== undefined && props.info.customer.customer.customer_type===1&&
                  <>
                  {Lang.customer_company_type_public}
                  </>
              }
              {props.info.customer.customer !== undefined && props.info.customer.customer.customer_type===2&&
                <>
                  {Lang.customer_company_type_private}
                </>
              }
              {props.info.customer.customer !== undefined && props.info.customer.customer.customer_type===3&&
              <>
                {Lang.customer_company_type_association}
              </>
              }
            </span>
          </div>

        { !_.isEmpty(props.info.department) &&
          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
             {Lang.department_name}
            </span>
            <span className="kt-widget4__number kt-font-info">{(props.info.department.name)}</span>
          </div> 
        }
         { !_.isEmpty(props.info.department_unit) &&
          <div className="kt-widget4__item">
            <span className="kt-widget4__title kt-widget4__title--light">
             {Lang.department_unit}
            </span>
            <span className="kt-widget4__number kt-font-info">{(props.info.department_unit.name)}</span>
          </div>
          }
          { !_.isEmpty(props.info.project) &&
          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
             {Lang.project}
            </span>
            <span className="kt-widget4__number kt-font-info">{!_.isEmpty(props.info.project)? props.info.project.project_detail:'NA'}</span>
          </div> 
               }
          {props.info.translation_type_id === OrderType.DOCUMENT &&
           <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
             {Lang.customer_document}
            </span>
            { !_.isEmpty(props.info.document) && props.info.document !== null &&
              <a href={props.info.document} className="kt-widget4__number kt-font-info">{Lang.download_document}</a>
            }
            </div>
          }
          {(props.info.translation_type_id === OrderType.DOCUMENT && (props.info.status==7 || props.info.status==5)) &&
          <div className="kt-widget4__item">
            <span className="kt-widget4__title kt-widget4__title--light">
             Translator's Document
            </span>
            { props.info.order_files[0]!==undefined && !_.isEmpty(props.info.order_files[0].name) && props.info.order_files !== null  &&
              <a href={props.info.order_files[0].name} className="kt-widget4__number kt-font-info">{Lang.download_document}</a>
            }
          </div>
          }
          <TravelDistance order={props.info} from={'detail'}/>
          {props.info.translation_type_id === OrderType.PHONE_MESSAGE &&
          <>
          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
            {Lang.telephone_number}
            </span>
            <span className="kt-widget4__number kt-font-info">{props.info.tel_mobile}</span>
          </div>

            <div className="kt-widget4__item">
              <span className="kt-widget4__title kt-widget4__title--light">
                {Lang.telephone_message}
              </span>
              <span className="kt-widget4__number kt-font-info text-left">{props.info.tel_message}</span>
            </div>
            {
              !_.isEmpty(props.info.order_files) &&
              <div className="kt-widget4__item">
                <span className="kt-widget4__title kt-widget4__title--light">
                  Translated text
                </span>
                <span className="kt-widget4__number kt-font-info text-left">{props.info.order_files[0].name}</span>
              </div>
            }

          </>
          }
          {/* Generate Microsoft Teams Meeting */}
          {
            props.info.translation_type_id === OrderType.VIDEO && (
              <div className="kt-widget4__item">
                <span className="kt-widget4__title kt-widget4__title--light">
                  Generate Microsoft Teams Meeting
                </span>
                <span className="kt-widget4__number kt-font-info">{ props.info.schedule_team_meeting ? Lang.yes : Lang.no }</span>
              </div>
            )
          }
          {
            props.info.translation_type_id === OrderType.VIDEO && !_.isEmpty(props.info.video_meeting_link) && (
              <div className="kt-widget4__item">
                <span className="kt-widget4__title kt-widget4__title--light">Video Meeting Link</span>
                <span className="kt-widget4__number kt-font-info">
                  <a href={props.info.video_meeting_link} target="_blank" rel="noopener noreferrer">Link <i className={Icons.external_link}/></a>
                </span>
              </div>
            )
          }
          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
               {Lang.created_at}
            </span>
            <span className="kt-widget4__number kt-font-info">{moment(props.info.created_at).format(CONST.date_format)}</span>
          </div> 
           
        </div>
      </div>
    </div>
  </div>
 
  )
}