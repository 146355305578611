import React, {useState,useEffect} from 'react';
import { Modal, ModalProps} from "react-bootstrap";
import Lang from "../../../common/lang/Lang";
import {UserModel} from "../../../model";
import {base_url, showNotification, SubmitButton,SwitchLoader} from "../../../common";
import API from "../../../common/AxoisClient";
import {AxiosResponse} from "axios";
import {EmailTemplateDataSchema} from "../../../settings/email-templates/Model";
import {UserContractModel} from "../../../model/UserContract";
import * as _ from 'lodash';
import {LoaderType} from "../../../common/Loader";



export interface ContractModel {
  subject?: string;
  content?: string;
  contractContent?: string;
  translator_id?: number;
  email_template?: EmailTemplateDataSchema;
  contract?: UserContractModel;
}

interface InfoProps extends ModalProps{
  transInfo: UserModel,
  transID?: number
  ContractInfo?: ContractModel
}

export const TranslatorCalling = (props: InfoProps) => {
  
    // if(props.TransInfo === undefined)
    //  return null;
  
    const [allUsers, setAllUsers] = useState([]);
     

    useEffect(() => {
      API.get(`users/translator/TranslatorCalling-GetStatus/${props.transInfo.id}`).then(response => {
        setAllUsers(response.data[0].translator_calling); 
      })
    

    },[props.transInfo.id]);

   

     function handleChange(event:any){
     // setTranslatorStatus(event.target.value)
      setAllUsers(event.target.value)
      
     }
    
    function handleSubmit(event) {
      event.preventDefault();
      SwitchLoader(LoaderType.BUTTON);
       API.post(`users/translator/TranslatorCalling-status`,{id:props.transInfo.id,translator_calling:allUsers}).then((res: AxiosResponse) => {
        showNotification("success", Lang.translator_calling_status);
        props.onHide();
      })
    }

  
  return(

    
   
      <Modal
          {...props}
          //size=""
          aria-labelledby="contained-modal-title-vcenter"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {Lang.translator_calling}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
              <div className={'row'}>
              <div className={"col-md-12 col-lg-12"}>
                 <div className="form-group">
                  
                  <select className={'form-control '} value={allUsers} onChange={(e)=>handleChange(e)} name="translator_calling">
                        <option value=''>{Lang.select_status}</option>
                        <option value='1'>{Lang.new_applicant}</option>
                        <option  value='2'>{Lang.called_in_for_conversation}</option>
                        <option value='3'>{Lang.not_pick_up_the_phone}</option>
                        <option value='4'>{Lang.nothing_interesting}</option>                           
                  </select>
                  </div>
              
            </div>
               
                 </div>
            </div> 
            
          </Modal.Body>
          <Modal.Footer>
             
              <SubmitButton className={'btn btn-primary'} label={Lang.send}/>
            
          </Modal.Footer>
        </form>
      </Modal>
  )
};