import React, { useRef, useState, useEffect } from 'react'

// styles
import style from './Search.module.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
}

const Search = ({ label, className, value, ...rest }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [PlayAnimation, setPlayAnimation] = useState<boolean>(false)
  useEffect(() => {
    if ( value === '' ) {
      setPlayAnimation(true)
      setTimeout(() => {
        setPlayAnimation(false)
      }, 200);
    }
  }, [ value ])
  const handleDivClick = () => {
    if (inputRef.current) inputRef.current.focus()
  }
  return (
    <div className={style.search} onClick={handleDivClick}>
      <label htmlFor="search_comp" className={style.label}><i className="la la-search"/> <span className={`${(value || PlayAnimation) && style.hover} ${style.hoverable} ${PlayAnimation && style.hover_down_animation}`} >{label}</span></label>
      <input ref={inputRef} className={`${className} ${style.input}`} type="text" id="search_comp" {...rest} />
    </div>
  )
}

export default Search