import { Translator_Data } from "./TypeDefs";

const translator_data_initial_values: Translator_Data = {
    id: null,
    name: '',
    email: '',
    user_code: '',
    country_code: '',
    mobile_no: '',
    gender: null,
    status: null,
    address: '',
    city: '',
    profile_image: '',
    zip_code: '',
    translator:{
        id: null,
        user_id: null,
        about: '',
        title: '',
        account_no: '',
        comments: '',
        social_no: '',
        location: '',
        driving_license: null,
        own_vehicle: null,
        is_certified: null,
        send_contract: null,
        police_verified: null,
        resume: null,
        origin_country: '',
        cvr_number: '',
        bank_registration_no: '',
        training: '',
        highest_complete_edu: '',
        current_employment: '',
        motivation: '',
        interview_scheduled_at: null,
        interview_taken_at: null,
        country: {
            id: null,
            name: '',
        },
    },
    translator_languages: []
}

export {
    translator_data_initial_values,
};