import * as React from 'react';
import {CreateBreadcrumb} from "./Data";
import Lang from "../../common/lang/Lang";
import API from './../../common/AxoisClient';
import {EmailValues,SearchValues} from "../../model/Email";
import * as _ from 'lodash';
import {RouteComponentProps} from "react-router";
import {EmailValidations} from "./Validation";
import {CreateLayout} from "../../layout/CreateLayout";
import {UserModel} from "../../model/User";
import {CountryModel} from "../../model/Country";
import {CONST, SwitchLoader} from "../../common";
import {LoaderType} from "../../common/Loader";
import {CommonSubmitButton, InlineLoader, Select} from "../../common";
import {CountryFlags, Select2Wrapper} from "../../common/Select2";
import { CustomerBreadcrumb } from '../../users/customers/Data';
import { Editor } from '@tinymce/tinymce-react';



declare var window: any;

interface IState{
  form: {
  
  registered_interpreters : string,
  subject : string,
  message : string,
  attachment? : string,
  user_email? : string,
  userIds : Array<any>,
  translator: number,
 customer: number,
 language_from? : number,
 language_to? : number,
};

searchVal:{
  query : string,
  customertypeUsers : number,
  translatortypeUsers : number,
  userlanguagefrom : number,
  userlanguageto : number,
  userStatus : string,
  user_type : number,
};

 allUsers: Array<any>;
  id:number;
  user_type :number;
  userIds : Array<any>;
   
  uploadDocument?: File;
  languagecodes: Array<any>;
  typeUsers? : number;
  userStatus? : string;
  is_allt: boolean;
  is_allc: boolean;
  all_selected : boolean;


  selectallIds : Array<any>;
  usermessage : boolean;
  newsletter_email : string;
  
  
}

export class EmailCreate extends React.Component<RouteComponentProps, IState> {
  state: IState;
  
  constructor(props: any){
    super(props);

    

    this.state = {
      form: EmailValues(),
      searchVal : SearchValues,
      id: props.match.params.id,
      allUsers: [],
      user_type:0,
       
      typeUsers : 0,
      userIds :[],
     
      uploadDocument : null,
      languagecodes:[],
      userStatus: '',
      selectallIds : [],
      is_allt:false,
      is_allc:false,
      all_selected: false,
      usermessage : false,
      newsletter_email : '',
     
    }
    EmailValidations();
    this.handleEditorChange = this.handleEditorChange.bind(this); 
     
  }

  
  getData = () => {
      let searchChar = $.trim(this.state.searchVal.query);
      let strLength = searchChar.length;
      if (strLength > 3) {
    API.get(`email/get-allusers`,{params:this.state.searchVal} ).then(res => {
     
     if(res.data){
      this.setState({
       allUsers: res.data,
       
     });
    }  
    });
   
  }
  
  }

 componentDidMount() {
    

    API.get("email/get-alllanguage").then(res => {
       this.setState({
        languagecodes: res.data.languageCodeArr
      }, () => {
          ($(".selectpicker") as any).selectpicker('refresh');
      });
      
    });

    API.get("email/getnewsletter-email").then((res: any) => {
     
      this.setState({
        newsletter_email: res.data[0].setting_value,
        
      });
    })
   

   }

onChangesearch = (event : React.ChangeEvent<any>) => {
  if(event.target.value == ''){
    this.setState({ 
      usermessage :false,
      searchVal: {...this.state.searchVal, query:  event.target.value},
      allUsers: event.target.value
    });
} else {
  this.setState({
    usermessage :true,
  searchVal: {...this.state.searchVal, query:  event.target.value},  
  }, this.getData);
} 
  
}

handleEditorChange(message : any, editor : any) {
  if(message !== ''){
      $( '.tox-tinymce' ).css( 'border-color', '#ccc' );
      $("#errorEditor").html(" ");
      this.setState({ form: { ...this.state.form, message: message}});
  } else {
      $( '.tox-tinymce' ).css( 'border-color', '#fd397a' );
      $("#errorEditor").html("Dette felt skal udfyldes.");
  }
}


onChangeallselect = (event : React.ChangeEvent<any>)=>{
  const targetCheck = event.target;
  const value = targetCheck.value;
  const name = targetCheck.name;

  let selectIds = [];
  this.state.allUsers.map((item:UserModel, index: number) => {
    selectIds.push(item.id);
  })

  if(event.target.checked===true) {
   this.setState({
    all_selected : true,
    form: { ...this.state.form,  userIds: selectIds},
    selectallIds: selectIds 
  });
 
  } else {

    this.setState({
      all_selected : false,
      form: { ...this.state.form, userIds: []},
      selectallIds: []
    } );
}
}

handleChange = async (event: React.ChangeEvent<HTMLElement>) => {
      const target : any = event.target;
      let value =  target.value;
      const name = target.name;
  
  if(name === 'userIds'){

    

       if(target.checked === true){
        
         await this.setState(prevState => ({ selectallIds : [...prevState.selectallIds , Number(value)] }));
       } else {
        
         await this.setState({selectallIds : this.state.selectallIds .filter(function(item: number) {
          return item !== Number(value)
         })});
       }
       

        let userIds = this.state.form.userIds;
 
        if(_.indexOf(userIds, value) > -1 ){
          userIds = _.pull(userIds, value);
        } else {
         userIds = _.concat(userIds, value);
         }
 
        value = _.compact(userIds);
     
  }
        
     if(name === 'customer'){
      if(target.checked===true) {
       
          this.setState({
          is_allc : true,
         searchVal: {...this.state.searchVal, customertypeUsers: value },
         form: { ...this.state.form, customer: 1}
          },this.getData);
         
      } else {
         
        this.setState({
          is_allc : false,
          searchVal: {...this.state.searchVal, customertypeUsers:0},
          },this.getData);
      }
     
     } 
    
    if(name === 'translator'){
      if(target.checked===true) {
        this.setState({
        is_allt : true,
        searchVal: {...this.state.searchVal, translatortypeUsers:  value},
       
        form: { ...this.state.form, translator: 2}
        },this.getData);
         
    } else {
        
       this.setState({
       is_allt : false,
       form: { ...this.state.form, translator: 1},
       searchVal: {...this.state.searchVal, translatortypeUsers:  0}
        },this.getData);
        
     }
     } 
    
   if(name === 'status'){
      this.setState({
       searchVal: {...this.state.searchVal, userStatus:  value},
     },this.getData);
   } 

   if(name === 'language_from'){
     this.setState({
      searchVal: {...this.state.searchVal, userlanguagefrom:  value}, 
    });
 } 

 if(name === 'language_to'){
  
  this.setState({
    searchVal: {...this.state.searchVal, userlanguageto:  value},
 });
}
   
      
  if(name !== 'translator')
      this.setState({ form: { ...this.state.form, [name]: value} });
  
    }
  


  handleCheckboxChange = (event : React.ChangeEvent<any>) => {
   
    const targetCheck = event.target;
    const checkValue = targetCheck.value;
    const name = targetCheck.name;
     
    
    if(event.target.checked===true) {
      
      if(checkValue == 1){
        
        this.setState({
          [name]: checkValue,
          is_allt : true,
          is_allc : true,
          form: {...this.state.form, translator:2 , customer:  1},
           
     } as any);
      } else {
        this.setState({
            [name]: checkValue,
            is_allt : false,
            is_allc : false,
            form: {...this.state.form, translator:1}, 
            searchVal: {...this.state.searchVal, user_type:  2},
       } as any);
      }
    } else {
      
        this.setState({
            [name]: 0,
            
        } as any);
    }
}

handleUpload = (e) => {
  this.state.uploadDocument = e.target.files;
  
  let formData = new FormData();
  formData.append('attachment', e.target.files[0]);
  API.post('email/uploadDocument', formData).then((res) => {
   this.setState({form: { ...this.state.form, attachment: res.data[0]}});
   
  });
}

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#customer_form").valid()) {
    API.post('email/email', this.state.form).then((res) => {
      this.props.history.push(`/email/email`) 
    })
  }   
  }


    formDataReset = (e:any) => {
        e.preventDefault();
        this.setState({
            form: { ...this.state.form, 
                subject:'', 
                message:'', 
                attachment:'', 
                user_email:'',
               
                customer:0,
                translator:0, 
                language_from:0,
                language_to:0,
            }, 
            searchVal : SearchValues,
            uploadDocument : null,
            userStatus: '',
            is_allt:false,
            is_allc:false,
            all_selected: false,
            usermessage : false,
            newsletter_email : '',
             allUsers: [],
            languagecodes:[],
            selectallIds : [],
            user_type:0,
            typeUsers : 0,
        });
    }


  



  
  render() {
     
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'email'}>
           
            <form className="kt-form" id={'customer_form'} onSubmit={this.handleSubmit}>
                <div className="kt-portlet__body">
                    <div className={'row'}>
                   <div className={"col-md-12 col-lg-12"}>
                     <div className={'row'} >
                    <div className={"col-md-6 col-lg-6"}>

                    <div className="form-group">
                    
                    <label className="d-block" >{Lang.select_users} </label>
                    <label className="kt-checkbox">
                    <input type="radio"  value={1}  onChange={this.handleCheckboxChange}   name="user_type" /><label>{Lang.all_users} </label>&nbsp;
                    <span></span>
                   </label>
                   <label className="kt-checkbox">
                    <input type="radio"  value={2}  id="is_user_type" onChange={this.handleCheckboxChange} checked={this.state.user_type == 2} name="user_type" /><label>{Lang.specific_users} </label>&nbsp;
                    <span></span>
                   </label>
                   <label className="kt-checkbox">
                    <input type="radio"  value={3}    onChange={this.handleCheckboxChange}  name="user_type" /><label>{Lang.custom_users} </label>&nbsp;
                    <span></span>
                   </label>
                       
                    </div>
                


                    <div className="form-group">
                    <label className="kt-checkbox">
                    <input type="checkbox"  value={2}   onChange={this.handleChange} checked={this.state.is_allt}  name="translator"/><label>{Lang.translators} </label>&nbsp;
                    <span></span>
                   </label>
                   <label className="kt-checkbox">
                    <input type="checkbox"  value={1}   onChange={this.handleChange} checked={this.state.is_allc} name="customer" /><label>{Lang.customers} </label>&nbsp;
                    <span></span>
                   </label>
                       
                    </div>
                    {this.state.form.translator>1 &&  
                    <div className="form-group">
                    <div className={"col-md-12 col-lg-12"}>
                    <div className={'row'} >
                    <div className={"col-md-6 col-lg-6"}>
                                <label>{Lang.language_from} <span className={'required'}>*</span></label>
                               <Select2Wrapper value={this.state.form.language_from} onChange={this.handleChange}
                                        className={'form-control'} id={'language'} name={'language_from'}
                                        data={{
                                            placeholder: "Select Options",
                                            data: this.state.languagecodes,
                                            templateResult: CountryFlags,
                                            templateSelection: CountryFlags,
                                        }}
                                   />
                                   </div>
                                   <div className={"col-md-6 col-lg-6"}>
                                   <label>{Lang.language_to} <span className={'required'}>*</span></label>
                                     <Select2Wrapper value={this.state.form.language_to} onChange={this.handleChange}
                                        className={'form-control'} id={'language_to'} name={'language_to'}
                                        data={{
                                            placeholder: "Select Options",
                                            data: this.state.languagecodes,
                                            templateResult: CountryFlags,
                                            templateSelection: CountryFlags,
                                        }}
                                   />    
                                   </div>   
                              </div> 
                              </div>
                              </div>
                              }
                            {this.state.user_type == 3 &&  
                              <div className="form-group">
                                <label>{Lang.email}</label>
                                <input type={'text'} onChange={this.handleChange} name="user_email"  className="form-control"  />
                            </div>
                             }
                              <div className="form-group">
                                <label>{Lang.user_status} <span className={'required'}></span></label>
                                <select className={'form-control '} onChange={this.handleChange}   name="status">
                                <option defaultValue=''>Select option</option>
                                      <option value='1'>Active</option>
                                        <option value='2'>Inactive</option>                           
                                </select>
                            </div>
                        

                       
                            <div className="form-group">
                                <label>{Lang.from} <span className={'required'}>*</span></label>
                                <input type={'text'} onChange={this.handleChange} name="from" value={this.state.newsletter_email} className="form-control"  />
                            </div>
                       
                      
                       
                            <div className="form-group">
                                <label>{Lang.subject} <span className={'required'}>*</span></label>
                                <input type={'text'} onChange={this.handleChange} name="subject" className="form-control"  />
                            </div>
                        
                       


                            <div className="form-group">
                                <label>{Lang.message_content} <span className={'required'}>*</span></label>
                                <Editor
                                  apiKey={CONST.TINY_MCE_KEY}
                                  id='message'
                                  init={{
                                      menubar:false,
                                      mode: "textareas",
                                      selector: 'textarea',
                                      branding: false,
                                      forced_root_block : false
                                  }} value={this.state.form.message} onEditorChange={this.handleEditorChange} />
                                 <span id='errorEditor' style={{color:'#fd397a'}}></span> 
                                {/* <input type={'text'}  onChange={this.handleChange} name="message" className="form-control"  /> */}
                                
                            </div>
                       
                       
                       
                            <div className="form-group">
                                <label>{Lang.mail_attchment} </label>
                                <div className="custom-file">
                                <input type={'file'} onChange={this.handleUpload} name="attachment" className="custom-file-input"  />
                                <label className="custom-file-label" htmlFor="customFile">{Lang.choose_file}</label>
                                      </div>
                            </div>
                        

                    </div>
                    <div className={"col-md-6 col-lg-6"}>
                    
                    {this.state.user_type == 2 &&

                    <div className="form-group">
                      <input type="text" placeholder="Please type minimum 3 characters for search" value={this.state.searchVal.query} onChange={this.onChangesearch} className="form-control" />
                      <div className="kt-checkbox-inline">
                      { this.state.allUsers.length>0 &&
                    <label className="kt-checkbox"><input type ="Checkbox" onChange={this.onChangeallselect} name={'select_all'} value={'all'}   />Select all<span></span></label>}

                       {this.state.allUsers.length === 0 && this.state.usermessage === true &&
                        <div className="alert alert-outline-warning fade show" >Users not found</div> 
                      }
                      {this.state.allUsers.length > 0 && this.state.allUsers.map((item:UserModel, index: number) => {
                        return <label key={item.id} className="kt-checkbox">
                          <input type ="Checkbox" onChange={this.handleChange}name={'userIds'} value={item.id} 
                          checked={_.indexOf(this.state.selectallIds, item.id) > -1}  />{item.name}
                    <span></span>
                    </label>
                 })}
                 
                   </div>
                  </div>
                     }

                    </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className='reset-btn-section'>
                    <CommonSubmitButton/>
                    <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn'} data-skin={'dark'} title={Lang.reset} onClick={(e)=>this.formDataReset(e)}>{Lang.reset}</button>
                </div>

            </form>
        </CreateLayout>
   
   )
  }
}