export interface DataSchema {
  id?: number;
  name: string;
  email: string;
  logo?:string;
  prefilled_fields: string;
  is_special_company?: number;
}

export interface FormFields  {
  name: any;
  type?: string;
}


export const FormDefaultVal = {
  name: '',
  email: '',
  prefilled_fields:'',
}

export const Form: Array<FormFields> = [
  {name: "name"},
  {name: "email"}
];

export const RequiredFields = {
  'address' : 'Address',
  'zip_code' : 'Zip Code',
  'city' : 'City',
  'mobile_no' : 'Mobile No',
  'ean_number' : 'EAN Number',
  'department_id' : 'Department',
  'department_unit_id' : 'Department Unit',
}