import * as React from 'react';
import {Icons} from "../../common/Icon";
import Lang from "../../common/lang/Lang";
import {UserModel} from "../../model";
import API from './../../common/AxoisClient';
import {Link} from "react-router-dom";
import _ from "lodash";
import {showNotification} from "../../common";
import {CropProfileImage} from "../translators/CropProfileImage";
import Cropper from 'cropperjs';
var cropper:any;

interface CustomerDSchemas {
    customer_id: number;
    active: string;
}

export class BasicCustomerDetail extends React.Component <CustomerDSchemas>{
    state: {
        user: UserModel,
        active: string
    };
    constructor(props: CustomerDSchemas) {
        super(props);
        this.state = {
            user:  {} as UserModel,
          active: props.active
        };
    }

    getData = () => {
        API.get(`users/customers/${this.props.customer_id}`).then(res => {

            this.setState({
                user: res.data
            });
        });
    }

    componentDidMount() {
      this.getData();
    }

    render() {

        const fontsize = { fontSize: '15px'};

        const TabsItems = [
          {title: Lang.customer_info, to: `/users/customers/info/${this.props.customer_id}`, active: 'info',fontIcon:Icons.user },
          {title: Lang.change_password, to: `/users/customers/info/${this.props.customer_id}/change-password`, active: 'password',fontIcon:Icons.password2},
          {title: Lang.order, to: `/users/customers/info/${this.props.customer_id}/order?form={"like":{"q":""},"between":{"start":""},"equal":{"customer_id":"${this.props.customer_id}"}}`, active: 'order' ,fontIcon:Icons.order},
         {title: Lang.notification, to: `/users/customers/info/${this.props.customer_id}/notification`, active: 'notification', fontIcon:Icons.notification },
         {title: Lang.customer_calculation, to: `/users/customers/info/${this.props.customer_id}/calculation`, active: 'customercalculation' ,fontIcon:Icons.company_time},
         {title: Lang.activity_logs, to: `/users/customers/info/${this.props.customer_id}/activitylog`, active: 'activitylog', fontIcon:Icons.users },
         {title: Lang.unfavorite_users, to: `/users/customers/info/${this.props.customer_id}/customerunfavorite`, active: 'customerunfavorite', fontIcon:Icons.users },
        ];

        const uploadProfilePic = async (e:React.ChangeEvent<HTMLElement>, callback?: (doc:string)=> void ) => {

            $('#updateProfilePictureOfUser').show();
            $('#ProfilePicturePleaseWait').hide();
            const target:any = e.target;

            var fileData:any = target.files[0];
            var reader:any = new FileReader();
            if (fileData.type.startsWith('image/')) {
                ($('#editTProfileImagePopup') as any).modal('show');
                reader.addEventListener("load", function () {
                    $('#uploadedImage1').attr('src',reader.result);
                    setTimeout(function () {
                        $('#uploadedImage1').css({opacity:'1'});
                        const image:any = document.getElementById('uploadedImage1');
                        if(!_.isEmpty(cropper)){
                            cropper.destroy();
                        }
                        image.src = reader.result;
                        cropper = new Cropper(image, {
                            aspectRatio: 50 / 50,
                            crop(event) {
                            },
                        });
                    },500);
                }, false);
                if (fileData) {
                    reader.readAsDataURL(fileData);
                }
            }
            let this_popup:any = this;
            $('body').on('click','#updateProfilePictureOfUser',function () {
                $('#updateProfilePictureOfUser').hide();
                $('#ProfilePicturePleaseWait').show();
                let imgData = cropper.getCroppedCanvas().toDataURL("image/png");
                let formData = new FormData();
                formData.append('profile_image', imgData);
                formData.append('id', this_popup.state.user.id);
                API.post("users/translators/update-profile-img", formData).then(response => {
                    ($('#editTProfileImagePopup') as any).modal('hide');
                    this_popup.getData();
                    showNotification("success", 'Profile image has been updated successfully.');
                });
            });
        };

        return (
            <div className="kt-widget kt-widget--user-profile-1">

                <div className="kt-widget__head">
                    <div className="kt-widget__media boxShadow ">
                        <div className={'profile_box'}>
                            <div className="imgbox">
                                <img src={this.state.user.profile_image} alt="image" />
                                <div className="overlay_img">
                                    <div className="input-file-container">
                                        <input accept="image/*" className="input-file-trigger" type="file" name="profile_image" id="profile_pic" onChange={(e)=>uploadProfilePic(e)}/>
                                        <label id="change_avatar" htmlFor="profile_pic"
                                               className="input-file-trigger">
                                            <span>
                                                {!_.isUndefined(this.state.user.profile_image) && this.state.user.profile_image.indexOf("user-placeholder") === -1 &&
                                                <i className="fas fa-pencil-alt" aria-hidden="true"/>
                                                }
                                                {!_.isUndefined(this.state.user.profile_image) && this.state.user.profile_image.indexOf("user-placeholder") > -1 &&
                                                <i className="fa fa-camera" aria-hidden="true"/>
                                                }
                                            </span></label>
                                    </div>
                                    <p className="file-return"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-widget__content">
                        <div className="kt-widget__section">
                            <a href="#" className="kt-widget__username">
                                {this.state.user.name}&nbsp;
                                ({( this.state.user.user_code !== null ) ? this.state.user.user_code : '--'})
                                { (this.state.user.status==1) ?
                                    <i className="flaticon2-correct kt-font-success"/>:<i className="flaticon2-close-cross" style={{color:'#299798'}}/>}
                            </a>
                            {/*<span className="kt-widget__subtitle">
                                { (this.state.user.gender=="male") ? <i className={Icons.male} style = {fontsize}/>: <i className={Icons.female} style = {fontsize}/> }  {this.state.user.gender}
                            </span>*/}
                        </div>
                    </div>
                </div>
                <div className="kt-widget__body">
                    <div className="kt-widget__content">
                        <div className="kt-widget__info">
                            <span className="kt-widget__label">{Lang.email}:</span><br/>
                            <span className="kt-widget__data">{this.state.user.email}</span>
                        </div>
                        <div className="kt-widget__info">
                            <span className="kt-widget__label">{Lang.telephone_number}:</span>
                            <span className="kt-widget__data">+ {this.state.user.country_code} - {this.state.user.mobile_no}</span>
                        </div>
                        <div className="kt-widget__info">
                            <span className="kt-widget__label">{Lang.address}:</span>
                            <span className="kt-widget__data">{this.state.user.address}</span>
                        </div>
                    </div>


                  <div className="kt-widget__items">
                    {TabsItems.map((item: any, index: number) => {
                     return (
                       <Link key={index}
                             className={this.state.active === item.active ? 'kt-widget__item kt-widget__item--active kt-widget__desc' : 'kt-widget__item'}
                             to={item.to} data-ktwizard-type="step"
                             onClick={() => {this.setState({active: item.active})}} >
                            <span className="kt-widget__section">
                                <span className="kt-widget__icon"/>
                                <span className="kt-widget__desc" ><i className={item.fontIcon}/> {item.title}</span>
                            </span>
                       </Link>
                     )

                    })}
                      <CropProfileImage/>
                  </div>
                </div>
            </div>
        );
    }
}