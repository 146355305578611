import React, {useReducer} from 'react';
import {OrderModel} from "../../../model";
import {OrderCommentModel} from "../../../model";

// all actions for assign translator
export enum ActionType {
  SHOW_POPUP = 'SHOW_POPUP',
  HIDE_POPUP = 'HIDE_POPUP',
  HANDLE_CHANGE = 'HANDLE_CHANGE',
  ADD_COMMENT = 'ADD_COMMENT',
}

export interface PopupState  {
  show: boolean;
  order_data: OrderModel;
  order_id: number;
  comments: Array<OrderCommentModel>;
  form: {
    comment: string;
  }
}

export interface CAction {
  type: ActionType;
  payload?: PopupState
}

export const InitialState: PopupState = {
  show: false,
  order_data: {} as OrderModel,
  order_id: 0,
  comments: [],
  form: {
    comment: ''
  }
};

// reducer for assign translator popup

export function OrderCommentReducer <CancelState, CAction > (state, action) {
  switch (action.type) {
    // show popup and set order data
    case ActionType.SHOW_POPUP:
      return {...state, show: true, order_data:action.payload.data, order_id: action.payload.data.id, comments: action.payload.comments};

    // hide popup and blank old state
    case ActionType.HIDE_POPUP:
      return {...state, ...InitialState};

      // handle change of mail drop-down
    case ActionType.HANDLE_CHANGE:
      const {name, value} = action.payload;
      return {...state, form: {...state.form, [name]: value}};


      // add new comment
    case ActionType.ADD_COMMENT:
      return {...state, comments: [...state.comments, action.payload], form: {...state.form, comment: ''}};


    default:
      throw new Error();
  }
}

export function CancelOrderValidations() {
  $(document).ready(function () {
    $( "#order_comments" ).validate({
      rules: {
        comment: "required",
      }
    });
  });
}