import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Country_Code_Array, Translator_Data } from './TypeDefs';
import { replace } from 'lodash';
import FormController from '../formik/FormController';
import { Icons } from '../../../common';
import callStyle from './CallStyle.module.scss';

interface Props {
    translator_data: Translator_Data
    country_codes: Country_Code_Array
}

interface FormValues {
    country_code: string;
    mobile_no: string;
}

const InterviewForm: React.FC<Props> = (props) => {
    const { country_codes, translator_data } = props;
    
    // formik initial values
    const call_initial_values = {
        country_code: translator_data.country_code ? translator_data.country_code : '45',
        
        // mobile_no: translator_data.mobile_no ? translator_data.mobile_no.split(' ')[3] : '---',
        mobile_no: translator_data.mobile_no ? translator_data.mobile_no : '---',
    }

    // formik validation schema using yup
    const call_validation_schema = yup.object().shape({
        country_code: yup.string().required('Dette felt skal udfyldes.'),
        mobile_no: yup.string().required('Dette felt skal udfyldes.'),
    })

    const handleCallSubmit = (values: FormValues) => {
        const {
            country_code,
            mobile_no
        } = values;
        window.open(`https://app.relatel.dk/agent/contact/${country_code + replace(mobile_no, /\s/g, '')}`, '_blank');
    }

    return (
        <Formik
            initialValues={call_initial_values}
            validationSchema={call_validation_schema}
            onSubmit={handleCallSubmit}
            enableReinitialize={true}
        >
            <Form
                translate
                className={callStyle.call_form}
            >
                <FormController
                    control="phoneNum"
                    label="Telefonnummer"
                    required
                    containerProps={{
                        className: "col-lg-10"
                    }}
                    countryCode={{
                        countryCode_name: 'country_code',
                        countryCode_options: country_codes,
                        className: 'form-control boxed-input light-placeholder',
                        style: {
                            border: 'none'
                        }
                    }}
                    mobileNum={{
                        mobileNum_name: 'mobile_no',
                        className: 'form-control light-placeholder',
                        placeholder: "Indtaste Telefonnummer",
                        style: {
                            border: 'none'
                        }
                    }}
                />
                
                <button
                    type="submit"
                    className={callStyle.submit_button}
                >
                    <i className={Icons.mobile_no}></i>
                    Ring til
                </button>
            </Form>
        </Formik>
    );
}

export default InterviewForm;
