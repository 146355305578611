import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Axios from '../../../common/AxoisClient'

interface Props {
  show: boolean
  closeModal: () => any
  translatorID: number
}

interface HistoryItem {
  id: number
  action_at: string
  old_cpr: string
  new_cpr: string
}

function CPRHistory({ show, closeModal, translatorID }: Props) {
  const [IsLoading, setIsLoading] = useState<boolean>(true)
  const [History, setHistory] = useState<HistoryItem[]>([])

  useEffect(() => {
    Axios.get(`users/translators/history/${translatorID}`).then(({data}: {data: HistoryItem[]}) => {
      setHistory(data)
      setIsLoading(false)    
    })
  }, [])
  
  return (
    <Modal
      show={show}
      onHide={closeModal}
      size='lg'
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Translator - CPR History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          IsLoading ? 'Loading...' :
          History.length === 0 ? 'No history to display.' : History.map(item => <HistoryItem key={item.id} from={item.old_cpr} to={item.new_cpr} actionAt={item.action_at} />)
        }
      </Modal.Body>
    </Modal>
  )
}

const HistoryItem = ({ actionAt, from, to }: {actionAt:string, from: string, to: string}) => (
  <div>
    <span style={{ display: 'inline-block', width: '150px'  }}>{actionAt}:</span>
    <Cpr val={from} />
    <span>&nbsp;-&gt;&nbsp;</span>
    <Cpr val={to} />
  </div>
)

const Cpr = ({ val }: { val: string }) => <span style={!val ? { color: 'red', fontWeight: 500 } : { color: 'black', fontWeight: 500 }} >{!val ? 'empty' : val}</span>

export default CPRHistory