import * as React from 'react';
import {CreateBreadcrumb} from "./Data";
import Lang from "../../common/lang/Lang";
import API from './../../common/AxoisClient';
 import * as _ from 'lodash';
import {RouteComponentProps} from "react-router";
import {DepartmentValidations} from "./Validations";
import {CreateLayout} from "../../layout/CreateLayout";
import {DepartmentModel,CompanyModel} from "../../model";
import {DepartmentValues} from "../../model/DepartmentUnit";
import {SwitchLoader, InlineLoader} from "../../common";
import {LoaderType} from "../../common/Loader";
import {CommonSubmitButton, showNotification, Select,getMapContent,GooglePlace} from "../../common";
import {CountryFlags, Select2Wrapper} from "../../common/Select2";
import { CustomerBreadcrumb } from '../../users/customers/Data';
import {AxiosResponse} from "axios";
 



declare var window: any;

interface IState{
  form: {
  
  name : string,
  notify_email : string,
  department_id ?: number,
  status? : number,
  ean_number?: number,
  address?: string,
  city?: string,
  country_code?:string,
  mobile_no?: number,
  zip_code?: number,
  location_code? : string,
  location_code_2?: number,
  latitude?: string,
  longitude?: string,
  
};
department : Array<any>;
company : Array<any>;
 id:number;
uploadDocument?: File;
_address: boolean,
countries : Array<any>;
countrycodes : Array<any>;
isNameExists:number;
  
}

export class DepartmentUnitCreate extends React.Component<RouteComponentProps, IState> {
  state: IState;
  
  constructor(props: any){
    super(props);

    

    this.state = {
      form: DepartmentValues(),
       
      id: props.match.params.id,
      department :[],
      company:[],
      uploadDocument : null,
      _address: false,
      countries:[],
      countrycodes:[],
      isNameExists:2
    }
    DepartmentValidations();
     
  }

  googleGetAddress = (place) => {
    const {address, city, lat, lng, zip_code} =  getMapContent(place);
    this.setState(prevState => ({form: {...prevState.form, ...{ latitude:lat,
      longitude:lng,
          address: address,
          zip_code: zip_code, city: city}}}as any
    ));
  }
  
    componentDidMount() {
        API.get("settings/department-unit/companies-list").then(res => {
            this.setState({
                company: res.data
            })
        }); 

        API.get("settings/department-unit/countries").then(res => {
            this.setState({
                countries: res.data.countryArr,
                countrycodes: res.data.countryCodeArr
            }, () => {
                ($(".selectpicker") as any).selectpicker('refresh');
            });
        })

    }

    department($company_id){
        API.get(`settings/department-unit/companies-accordingto-department/${$company_id}`).then(res => {
      this.setState({
        department: res.data
     })
        });
    }


    checkUniqueName = (e) =>{
        var name = e.target.value;
        if(name !== ''){
            SwitchLoader(LoaderType.INLINE);
            API.get(`settings/department-unit/check-unique-department_unit-name/${name}`).then((res: AxiosResponse) => {
                if(res.data.status===0){
                    this.setState({
                        'isNameExists':0
                    });
                } else {
                    this.setState({
                        'isNameExists':1
                    });
                }
            });
        }
    }

 
  handleCompany = (e) => {
    this.department(e.target.value)
  }

   handleChange=(event: React.ChangeEvent<HTMLElement>) => {
      const target : any = event.target;
        let value =  target.value;
        const name = target.name;
     this.setState({ form: { ...this.state.form, [name]: value} });
   }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#customer_form").valid() && this.state.isNameExists==0) {
    API.post('settings/department-unit', this.state.form).then((res) => {
      this.props.history.push(`/settings/department-unit`) 
      showNotification("success", Lang.department_unit_create);
    })
  }   
  }

  resetForm = () =>{ 

    var location_code = document.getElementById('location_code') as HTMLInputElement; 
    location_code.value='';
    var location_code_2 = document.getElementById('location_code_2') as HTMLInputElement; 
    location_code_2.value = '';
    var ean_number = document.getElementById('ean_number') as HTMLInputElement; 
    ean_number.value = '';
    var mobile_no = document.getElementById('mobile_no') as HTMLInputElement; 
    mobile_no.value = '';
    var city = document.getElementById('city') as HTMLInputElement; 
    city.value = '';
    var zip_code = document.getElementById('zip_code') as HTMLInputElement; 
    zip_code.value = '';
    var name = document.getElementById('name') as HTMLInputElement; 
    name.value = '';
    var company_id = document.getElementById('company_id') as HTMLInputElement; 
    company_id.value = '';

        this.setState({
            form: {
                ...this.state.form,
                name: '',
                  department_id: 0,
                  ean_number: 0,
                  address: '',
                  city: '',
                  country_code :'',
                  mobile_no: 0,
                  zip_code:0,
                  location_code: '',
                  location_code_2: 0,
                  status:0,
                  notify_email:'',
                  latitude: '',
                  longitude: '',
                  
            },
            uploadDocument : null,
            _address: false,
            isNameExists:2,
            department:[],
        });
    }

 
  
  render() {
     
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'department_unit'}>
          
            <form className="kt-form" id={'customer_form'} onSubmit={this.handleSubmit}>
                <div className="kt-portlet__body">
                    <div className={'row'}>
                   <div className={"col-md-12 col-lg-12"}>
                   <div className={'row'}>
                    <div className={"col-md-6 col-lg-6"}>
                   <div className="form-group">
                                   <label>{Lang.company} <span className={'required'}>*</span></label>
                                   <Select className={'form-control'} onChange={(e)=>this.handleCompany(e)}  id={'company_id'} name={'company_id'}  >
                              <option value={''}>{'Select Company'}</option>
                              {this.state.company.map((item: CompanyModel, index: number) => {
                              return <option key={index} value={item.id}>{item.name}</option>
                            })}
                          </Select>
                      </div>   
                      </div>
                      <div className={"col-md-6 col-lg-6"}>
                   <div className="form-group">
                                   <label>{Lang.department} <span className={'required'}>*</span></label>
                                   <Select className={'form-control'} onChange={this.handleChange}  id={'department_id'} name={'department_id'}  >
                              <option value={''}>{'Select Department'}</option>
                             {this.state.department.map((item: DepartmentModel, index: number) => {
                              return <option key={index} value={item.id}>{item.name}</option>
                            })}
                          </Select>
                      </div>   
                      </div>
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                                <label>{Lang.name}<span className={'required'}>*</span></label>
                                <InlineLoader>
                                <input type={'text'} id="name"  name="name" onChange={this.handleChange} className="form-control"  onBlur={(e)=>this.checkUniqueName(e)}/>
                                </InlineLoader>
                                { this.state.isNameExists === 1 && <>
                                    <label id="name-exists-error" className={'error'} style={{color:'red'}}>{Lang.name_already_register}</label></>
                                }
                        </div>
                     </div>
                     
                     
                     <div className={"col-md-6 col-lg-6"}>
                    <div className="form-group">
                                <label>{Lang.location_code}</label>
                                <input type={'text'} id="location_code"  name="location_code" onChange={this.handleChange} className="form-control"  />
                     </div>
                     </div>
                     <div className={"col-md-6 col-lg-6"}>
                    <div className="form-group">
                                <label>{Lang.location_code_2}</label>
                                <input type={'text'}  id="location_code_2" name="location_code_2" onChange={this.handleChange} className="form-control"  />
                     </div>
                     </div>
                     <div className={"col-md-6 col-lg-6"}>
                    <div className="form-group">
                                <label>{Lang.ean_number}</label>
                                <input type={'text'} id="ean_number" name="ean_number" onChange={this.handleChange} className="form-control"  />
                     </div>
                     </div>
                     <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group ">
                            <label>{Lang.telephone_number} <span className={'required'}>*</span></label>
                            <div className={"row"}>
                                <div className={"col-md-4 col-lg-4"}>
                                    <Select2Wrapper   onChange={this.handleChange}
                                                    className={'form-control'} id={'country_code'} name={'country_code'}
                                                    value={this.state.form.country_code || '45'}
                                                    data={{
                                                        placeholder: "Select Options",
                                                        data: this.state.countrycodes,
                                                        templateResult: CountryFlags,
                                                        templateSelection: CountryFlags,
                                                    }}
                                    />
                          
                                </div>
                                <div className={"col-md-8 col-lg-8"}>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.mobile_no || ''}  ref={input => {this[`mobile_no`] = input; }} name={'mobile_no'}  className="form-control" placeholder={Lang.getString(`mobile_placeholder`)} id="mobile_no"/>
                                </div>
                            </div>
                        </div>
                       </div>
                  
                     <div className={"col-md-6 col-lg-6"}>
                    <div className="form-group">
                                <label>{Lang.address}</label>
                                {!this.state._address &&
                                  <GooglePlace onChooseAddress={this.googleGetAddress} name={'address'} className="form-control" placeholder={Lang.getString(`address_placeholder`)} />
                                }
                                {this.state._address &&
                                  <input name={'address'} ref={input => {this[`address`] = input; }} defaultValue={this.state.form.address}  className="form-control" placeholder={Lang.getString(`address_placeholder`)} />
                                }

                               
                     </div>
                     </div>
                     <div className={"col-md-6 col-lg-6"}>
                    <div className="form-group">
                                <label>{Lang.city}</label>
                                <input type={'text'} value={this.state.form.city || ''} ref={input => {this[`city`] = input; }} id="city" name={'city'} onChange={this.handleChange} className="form-control"  />
                     </div>
                     </div>
                     <div className={"col-md-6 col-lg-6"}>
                    <div className="form-group">
                    <label>{Lang.zip_code} </label>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.zip_code || ''}  ref={input => {this[`zip_code`] = input; }} name={'zip_code'} className="form-control" placeholder={Lang.getString(`zip_code_placeholder`)} id="zip_code"/>
                     </div>
                     </div>
                     
                     </div>
                    </div>
                </div>
                </div>
                <div className='reset-btn-section'>
                    <CommonSubmitButton/>
                    <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn'} data-skin={'dark'} title={Lang.reset} onClick={this.resetForm}>{Lang.reset}</button>
                </div>

            </form>
            
        </CreateLayout>
   
   )
  }
}