import React from 'react';
import {translatorList} from "../../../services";
import Lang from "../../../common/lang/Lang";
import {Select2Wrapper} from "../../../common";
import {ShowTranslatorGender, TranslatorGender} from "./TranslatorGender";
import _ from "lodash";

export function SpecificTranslator(props: any) {
  let formData:any = props.formData;
  if(Number(formData.specific_translator_id)>0) {
   return (
     <React.Fragment>
       <span className={'badge badge-success'}>
       <ShowTranslatorGender {...formData.specific_translator} /> &nbsp; {(formData.specific_translator.text)?formData.specific_translator.text:formData.specific_translator.name}
       </span> &nbsp;
       <span className={'badge badge-danger specificTranslatorRemove'}
             style={{cursor: 'pointer'}}
             onClick={(e) => {
                 props.removeTranslator(e);
             }}>
       <i className={'fa fa-times'}/></span>
     </React.Fragment>
   )
  }
  else {
    return (
     <div>
       <Select2Wrapper onChange={props.onChange} style={{width:'100%'}} value={formData.specific_translator_id} name={'specific_translator_id'} data={{
         placeholder: Lang.select_translator,
         minimumInputLength: 3,
         templateResult: TranslatorGender,
         templateSelection: TranslatorGender,
         ajax: {
           transport: function (params, success, failure) {
             let request = translatorList(1, params.data, formData,formData.customer_id);
             request.then(success);
             request.catch(failure);
             return request;
           }
         }
       }}>
         <option>{Lang.select_translator}</option>
       </Select2Wrapper>
     </div>
    )
  }
}