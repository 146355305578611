import React, {useEffect, useReducer, useState} from 'react';
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {
  InitialState, PopupState, ActionType,
  CAction, OrderPopupReducer
} from "./OrderPopupReducer";
import { NewletterServices, SeeNewletterService} from "../../../services/NewletterServices";


export function SeeEmail () {

  const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(OrderPopupReducer , InitialState);
  

  useEffect( () => {
    SeeNewletterService.getPopupState().subscribe((message:any) => {
       dispatch({type: ActionType.SHOW_POPUP, payload: message}) ;
    });

  }, []);

  if(!state.show){
    return null;
  }
 
  function createMarkup() {
    return {__html: state.orders.mail_template};
  }
   
  return(
    
    <Modal
    
      show={state.show}
      onHide={() => { dispatch({type: ActionType.HIDE_POPUP}) } }
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form  id="fix_price_form">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {state.orders.subject}({state.orders.user_email !== null && state.orders.user_email} {state.orders.users !== null && state.orders.users.email})<br/>
            {state.orders.attachment !== null &&
            <a href={state.orders.attachment} target="_blank">Attachment</a>
           }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </Modal.Body>
        <Modal.Footer>
        <button type={'button'} className={'btn btn-primary'} onClick={() => { dispatch({type: ActionType.HIDE_POPUP}) }}>{Lang.close}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}