import React from 'react';
import {HalfDayTitle, LeaveTitle, leaveTypes, TranslatorLeaveModel} from "../../model";
import {LeavesBreadcrumb} from "./Data";
import Lang from "../../common/lang/Lang";
import {Breadcrumb, NoRecords, PleaseWait, CommonSubmitButton, FormLayout, TimePickerWrapper, SubmitButton} from "../../common";
import {getTranslatorLeaves} from "../../services";
import {RouteComponentProps} from "react-router";
import {LeaveDatePickerWrapper} from "../../common/LeaveDatePicker/LeaveDatePicker";
import {AddLeaveFormState, GetLeavesState} from "./State";
import {LeaveFromValidations} from "./Validations";
import {LeaveGetSchema} from "./Schema";
import API from "../../common/AxoisClient";
import {AxiosResponse} from "axios";
import {showNotification} from "../../common/Common";
import {get_trans_leave_status} from "../../helpers/UserHelper";
import Swal from "sweetalert2";
import LeaveTypeSelector from './components/LeaveTypeSelector/LeaveTypeSelector';
import sheet from './Leaves.module.scss'
import { set } from 'lodash';
import moment from "moment";
import { removeSeconds } from '../../orders/orders/popup/Components/TranslatorCard/components/TodayOrdersInfo/TodayOrdersInfo';
declare let window:any;

export interface LeavesState {
  translatorId: any;
  leaves: Array<TranslatorLeaveModel>;
  leaveInfo: any;
  getLeaveInfo: any;
  is_loading: boolean;
  is_edit_mode: boolean;
  leave_id: number;
};
export class Leaves extends React.Component<RouteComponentProps> {
  state: LeavesState
  default_state = {
    translatorId: (this.props.match.params as {id: string}).id,
    leaves: [],
    leaveInfo: {
      leave_type:1,
      from_date:'',
      to_date:'',
      from_time: '00:00',
      to_time: '23:59',
      reason:''
    },
    getLeaveInfo: [],
    is_loading: true,
    is_edit_mode: false,
    leave_id: 0
  }
  constructor(props){
    super(props);
    this.state = this.default_state
  }

  getLeaveData(){
    const {id}: any = this.props.match.params;
    API.get(`users/translator/leaves-list/`+id).then((res: AxiosResponse) => {
        const getTranslatorLeaves:any = res.data.translatorLeave;
        this.setState({
          getLeaveInfo: getTranslatorLeaves,
          leaves:res.data,
          is_loading: false
        });
    })
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      $("#"+e.target.name+"-error").css({"display": "none"});
      $(".servicesPicker").css({"color": "rgba(0,0,0,.87)"});
      const {name, value} = e.target;
      this.setState({ leaveInfo: { ...this.state.leaveInfo, [name]: value} });
      //this.setState(prevSate => ({...prevSate, [name]: value}));
  }

  handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
      event.preventDefault();
      if($("#leave_info_form").valid()) {
          if(this.state.is_edit_mode){
            API.post(`users/translator/leave-edit/`+this.state.translatorId, this.state.leaveInfo).then((res: AxiosResponse) => {
                if(res.data.status){
                    showNotification('success', res.data.message);
                    ($('#availability') as any).modal('hide');
                    this.getLeaveData();
                    this.setState({leaveInfo: AddLeaveFormState, is_edit_mode: false, leave_id: 0});
                } else {
                    showNotification('danger',res.data.message);
                }
            })
          }
          else {
            API.post(`users/translator/leave-add/`+this.state.translatorId, this.state.leaveInfo).then((res: AxiosResponse) => {
                if(res.data.status){
                    showNotification('success', res.data.message);
                    ($('#availability') as any).modal('hide');
                    this.getLeaveData();
                    this.setState({leaveInfo: AddLeaveFormState});
                } else {
                    showNotification('danger',res.data.message);
                }
            })
          }
      }
  }

  componentDidMount() {
    LeaveFromValidations();
    this.getLeaveData();
  }

  editLeave(e:any, leave_id:any) {
    API.post(`users/translator/leave-update/`+this.state.translatorId,{'leave_id': leave_id}).then((res: AxiosResponse) => {
        if(res.data){
          this.setState({ leaveInfo: {...this.state.leaveInfo, leave_id: res.data.id, leave_type: res.data.leave_type, type_of_half_day: res.data.type_of_half_day, from_date: res.data.from_date, to_date: res.data.to_date, reason: res.data.reason}});
          this.setState({leave_id: leave_id, is_edit_mode: true});
          ($('#availability') as any).modal('show');
        }
    })
  }

  approveLeave(e:any, leave_id:any){
      let confirm_text = Lang.approve_confirm_leave;
      Swal.fire({
          title: Lang.is_sure,text: confirm_text,type: 'warning',showCancelButton: true,confirmButtonColor: '#3085d6',cancelButtonColor: '#d33',confirmButtonText: Lang.ok, cancelButtonText: Lang.cancel
      }).then((result:any) => {
          if (result.value) {
              API.post(`users/translator/leave-approve/`+this.state.translatorId,{'leave_id': leave_id}).then((res: AxiosResponse) => {
                  showNotification('success', res.data.message);
                  this.getLeaveData();
              })
          }
      })
  }

  rejectLeave(e:any, leave_id:any){
      let confirm_text = Lang.reject_confirm_leave;
      Swal.fire({
          title: Lang.is_sure,text: confirm_text,type: 'warning',showCancelButton: true,confirmButtonColor: '#3085d6',cancelButtonColor: '#d33',confirmButtonText: Lang.ok, cancelButtonText: Lang.cancel
      }).then((result:any) => {
          if (result.value) {
              API.post(`users/translator/leave-reject/`+this.state.translatorId,{'leave_id': leave_id}).then((res: AxiosResponse) => {
                  showNotification('success', res.data.message);
                  this.getLeaveData();
              })
          }
      })
  }

  closePopup() {
    this.setState({
      leaveInfo: AddLeaveFormState
    });
  }

  resetForm = (event:any) => { 
      event.preventDefault();
      this.setState({ leaveInfo: { ...this.state.leaveInfo, leave_type:1, from_date:'', to_date:'', from_time: '00:00', to_time: '23:59', reason:''} });
  }

  handleStateSet = (stateToSet: Partial<LeavesState>) => this.setState(stateToSet)

  handleTimeChange = ({ target: {name} , ...e}: React.ChangeEvent<HTMLInputElement>, time_picker: JQuery<HTMLInputElement>) => {
    // unallowed values are: from_time being after to_time and to_time being after from_time
    const bad_from_time_update = name === 'from_time' && moment((e as any).time.value, 'HH:mm:ss').isAfter(moment(this.state.leaveInfo['to_time'], 'HH:mm:ss'))
    const bad_to_time_update = name === 'to_time' && moment((e as any).time.value, 'HH:mm:ss').isBefore(moment(this.state.leaveInfo['from_time'], 'HH:mm:ss'))
    if (bad_from_time_update) time_picker.timepicker('setTime', this.state.leaveInfo['from_time'])
    if (bad_to_time_update) time_picker.timepicker('setTime', this.state.leaveInfo['to_time'])
    if (!bad_from_time_update && !bad_to_time_update) this.setState(set(this.state, `leaveInfo.${name}`, (e as any).time.value))
  }


  render() {
    const show_to_date = this.state.leaveInfo.leave_type===3 && this.state.leaveInfo.from_date!=''
    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

        <Breadcrumb data={LeavesBreadcrumb}/>

        <div className="kt-portlet kt-portlet--mobile">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">
                        {Lang.leaves}
                      </h3>&nbsp;&nbsp;
                      <input type="button" className="btn btn-primary" data-toggle="modal" data-backdrop='static' data-target="#availability"
                      value={Lang.add_date}/>
                      <div id="profileTab3">
                        <div className="modal customModal fade" id="availability">
                            <div className="modal-dialog modal-mg modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="title m">
                                          <i className="far fa-plus-square"/>{Lang.add_date}
                                        </h1>
                                        <button type="button" className="close" data-dismiss="modal" onClick={() => this.closePopup()}>&nbsp;</button>
                                    </div>
                                      <FormLayout id="leave_info_form" onSubmit={this.handleSubmit}  autoComplete="off">
                                        <div className="modal-body">
                                            <div className="row px-5">
                                                <LeaveTypeSelector state={this.state} setParentState={this.handleStateSet} />
                                                <div className={sheet.date_time_line}>
                                                <label className={sheet.section_label_grid}>Om morgenen eller om eftermiddagen?</label>
                                                  {/* From date */}
                                                  <LeaveDatePickerWrapper
                                                    className="servicesPicker"
                                                    options={{startDate: new Date(moment().subtract(1, 'day').toISOString())}} 
                                                    id={'from_date'}
                                                    name={'from_date'}
                                                    onChange={this.handleChange}
                                                    value={this.state.leaveInfo.from_date}
                                                    placeholder={"Dato"}
                                                    autoComplete="off"
                                                  />
                                                  {/* To date */}
                                                  {  show_to_date && (
                                                    <>
                                                      <LeaveDatePickerWrapper
                                                        className="servicesPicker"
                                                        options={{startDate: new Date(this.state.leaveInfo.from_date)}}
                                                        name={'to_date'}
                                                        onChange={this.handleChange}
                                                        id={'to_date'}
                                                        value={this.state.leaveInfo.to_date}
                                                        placeholder={'Dato'}
                                                        autoComplete="off"
                                                      />
                                                      {/* From Time */}
                                                      <TimePickerWrapper
                                                        value={this.state.leaveInfo.from_time}
                                                        name="from_time"
                                                        onTimeChange={this.handleTimeChange} 
                                                        className="form-control"
                                                        options={{
                                                          defaultTime: this.state.leaveInfo.from_time,
                                                        }}
                                                        type="text"
                                                      />
                                                      {/* To Time */}
                                                      <TimePickerWrapper
                                                        value={this.state.leaveInfo.to_time}
                                                        name="to_time"
                                                        onTimeChange={this.handleTimeChange} 
                                                        className="form-control"
                                                        options={{
                                                          defaultTime: this.state.leaveInfo.to_time,
                                                        }}
                                                        type="text"
                                                      />
                                                    </>
                                                  )}
                                                </div>
                                                <label className={sheet.section_label}>Hvad er årsagen til afbrydelsen?</label>
                                                <input id="reason" name="reason" type="text" className="form-control validate" value={this.state.leaveInfo.reason} onChange={(e) => {this.setState({leaveInfo:{...this.state.leaveInfo, reason: e.target.value}})}} placeholder={Lang.reason_placeholder} />
                                            </div>
                                        </div>
                                        <div className={`mt-4 kt-portlet__foot btnSet text-center modal-footer ${sheet.submit_section}`}>
                                              <div className="kt-form__actions">
                                                    <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                                                <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn'} data-skin={'dark'} title={Lang.reset} onClick={this.resetForm}>{Lang.reset}</button>
                                              </div>
                                        </div>
                                    </FormLayout>
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
                <br/>
                <div className="kt-portlet__body">
                  <div className={'kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded'}>
                    { !this.state.is_loading && this.state.leaves.length > 0 &&
                      <table className={'table'}>
                        <tbody>
                        <tr>
                          <th>#</th>
                          <th>{Lang.leave_type}</th>
                          <th>{Lang.from}</th>
                          <th>{Lang.to}</th>
                          <th>{Lang.reason}</th>
                          <th>{Lang.action}</th>
                        </tr>
                        {
                          this.state.leaves.map((item: TranslatorLeaveModel, index: number) => {
                            return (
                              <tr key={item.id}>
                                <td>{++index}</td>
                                <td>{LeaveTitle[item.leave_type]}
                                  &nbsp;
                                  {item.leave_type === leaveTypes.HALF_DAY &&
                                    <span className={'badge badge-primary'}>{HalfDayTitle[item.type_of_half_day]}</span>
                                  }
                                </td>
                                <td style={{fontWeight: 500}}>{item.from_date} på {removeSeconds(item.from_time)}</td>
                                <td style={{fontWeight: 500}}>{item.to_date} på {removeSeconds(item.to_time)}</td>
                                <td>{item.reason}</td>
                                <td>
                                {get_trans_leave_status(item)}<br/>
                                { (moment().format('YYYY-MM-DD')<item.from_date) &&
                                  <div style={{marginTop:5}}>
                                    {item.status===0 &&
                                      <>
                                      <a className="btnStatusPrimary hvr-push" href="javascript:;" onClick={(e) => this.editLeave(e, item.id)}><i className="fas fa-edit"></i></a>&nbsp;&nbsp;
                                      <a className="btnStatusPrimary hvr-push" href="javascript:;" onClick={(e) => this.approveLeave(e, item.id)}><i className="fas fa-check"></i></a>&nbsp;&nbsp;
                                      <a className="btnStatusPrimary hvr-push" href="javascript:;" onClick={(e) => this.rejectLeave(e, item.id)}><i className="fas fa-times"></i></a>
                                      </>
                                    }
                                    {item.status===1 &&
                                      <>
                                      <a className="btnStatusPrimary hvr-push" href="javascript:;" onClick={(e) => this.editLeave(e, item.id)}><i className="fas fa-edit"></i></a>&nbsp;&nbsp;
                                      <a className="btnStatusPrimary hvr-push" href="javascript:;" onClick={(e) => this.rejectLeave(e, item.id)}><i className="fas fa-times"></i></a>
                                      </>
                                    }
                                    {item.status===2 &&
                                      <a className="btnStatusPrimary hvr-push" href="javascript:;" onClick={(e) => this.approveLeave(e, item.id)}><i className="fas fa-check"></i></a>
                                    }
                                  </div>
                                }
                                </td>
                              </tr>
                            )

                          })
                        }
                        </tbody>

                      </table>
                    }
                    {!this.state.is_loading && this.state.leaves.length == 0 && <NoRecords/>}
                    {this.state.is_loading && <PleaseWait/>}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}