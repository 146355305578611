import * as React from 'react';
import Lang from "../../common/lang/Lang";
import { EditBreadcrumb} from "./Data";
import API from './../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {showNotification} from "../../common/Common";
import {RoleValues} from "../../model/Role";
import {RoleValidations} from "./Validation";
import {LoadingButton} from "../../common/form/Button";
import {CreateLayout} from '../../layout/CreateLayout';
import {ChoosePermission} from "./ChoosePermission";
import {PermissionModel} from "../../model";
import {SubmitButton,SwitchLoader} from "../../common";
import {LoaderType} from "../../common/Loader";
import _ from "lodash";

declare var window: any;

interface IState{
  form: {
    name: string,
    permissions: Array<string>
  };
  scopes: Array<any>;
  loading: boolean;
}

export class RoleEdit extends React.Component<RouteComponentProps<any>, IState> {
  state: IState;

  constructor(props: RouteComponentProps){
    super(props);
    this.state = {
      form: RoleValues(),
      scopes: [],
      loading: false
    }
   RoleValidations();
  }

  componentDidMount() {
    API.get('permission/role/create').then((res: any) => {
      this.setState({
        scopes: res.data
      }, () => {
        window.KTApp.initTooltips();
      })
    });

    API.get(`permission/role/${this.props.match.params.id}`).then((res: any) => {
      this.setState({
        form: RoleValues(res.data)
      })
    })
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

  handleScopes = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if(event.target.checked === true){
      this.setState(prevState => {
        return { form: { ...this.state.form, permissions: [...prevState.form.permissions, id]} }
      });
    } else {
      this.setState({ form: { ...this.state.form, permissions: this.state.form.permissions.filter((item: string) => {
            return item !== id
          })} });
    }
  }

  CheckAll = (event: React.ChangeEvent<HTMLInputElement>, role: Array<PermissionModel>) => {

    let scopes = [];
    role.map((item: PermissionModel) => {
      scopes.push(item.scope);
    })
    //  let remove_item = scopes.toString();
    if(event.target.checked === true) {
      let old_permissions = this.state.form.permissions;
      let new_permissions = _.concat(old_permissions, scopes);
      this.setState({
        form: { ...this.state.form, permissions: new_permissions}
      });
    } else {
      let old_permissions = this.state.form.permissions;
      let  new_permissions =  _.pullAll(old_permissions, scopes);
      this.setState({
        form: { ...this.state.form, permissions: new_permissions}
      });
    }


  }


  CheckedAllValues = (event: React.ChangeEvent<HTMLInputElement>, rolesPermission) => {
    let scopes = [];
    Object.entries(rolesPermission).map((key : any, value: number) =>  {
      key[1].map((item: PermissionModel) => {
        scopes.push(item.scope);

      })
    })
    if(event.target.checked === true) {
      let old_permissions = this.state.form.permissions;
      let new_permissions = _.concat(old_permissions, scopes);
      this.setState({
        form: { ...this.state.form, permissions: new_permissions}
      });
    } else {
      let old_permissions = this.state.form.permissions;
      let  new_permissions =  _.pullAll(old_permissions, scopes);
      this.setState({
        form: { ...this.state.form, permissions: new_permissions}
      });
    }

  }



  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#create_role").valid()) {
      this.setState({loading: true});
      API.put(`permission/role/${this.props.match.params.id}`, this.state.form).then((res) => {
        showNotification("success", Lang.role_updated);
        this.setState({loading: false});
        this.props.history.push(`/permission/role`)
      })
    }
  }


  render() {
    return (
        <CreateLayout breadcrumb={EditBreadcrumb} title={'edit_role'}>
          <form className="kt-form form-horizontal" onSubmit={this.handleSubmit} id={'create_role'}>
            <div className="kt-portlet__body">
              <div className={'row'}>
                <div className={"col-md-3 col-lg-3"} >
                  <label className={'control-label '}>{Lang.title} <span className={'required'}>*</span></label>
                </div>
                <div className={"col-md-9 col-lg-9"} >
                  <div className="form-group">
                    <input type={'text'} onChange={this.handleChange} value={this.state.form.name} name={'name'} className="form-control" placeholder={Lang.getString(`role_title_placeholder`)} required={true}/>
                  </div>
                </div>
              </div>
              <ChoosePermission CheckAll={this.CheckAll} CheckedAllValues={this.CheckedAllValues} onChange={this.handleScopes} permissions={this.state.form.permissions} scopes={this.state.scopes}/>
            </div>
            <div className="kt-portlet__foot">
              <div className="kt-form__actions">
                <div className="row">
                  <div className="col-lg-6">
                  </div>
                  <div className="col-lg-6 kt-align-right">
                  <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </CreateLayout>

    )
  }
}