import * as React from 'react';
import {SearchInput, SearchSelect} from "../../common/form/SearchInput";
import {IState as CommonState} from "../../common/State";
import {ShowRecords} from "../../common/ShowRecords";
import {CONST, ExportButton, getQueryStringParams} from "../../common/Common";
import {Icons} from "../../common/Icon";
import {actionButton} from "../../common/table/BlukActionButtonsEmailSms";
import {RouteComponentProps} from "react-router";
import {EnquireModel} from "../../model/Enquire";
import {ListBreadcrumb} from './Data';
import {ActionLink, CreateLink, EditLink, GeneratePermitLink} from "../../common/page/CreateButton";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {default as Lang} from "../../common/lang/Lang";
import EnquireFilter from './filter/EnquireFilter';
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";


interface IState extends CommonState{
  UserData: Array<EnquireModel>;
}


export class EnquireListing extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

   

    showContent = (data: EnquireModel, index: number) => {
        let cols = [];
        cols.push(...[
             <td key={index}>{this.records.current.checkbox(data.id)}</td>,
            this.records.current.createdTd("id", index),
            this.records.current.createdTd("name", (!_.isEmpty(data.users)?data.users.name:Lang.NA )),
            this.records.current.createdTd("title", data.subject),
            this.records.current.createdTd("message", data.message),
        ]);
        cols = _.compact(cols);

        return (
            <tr key={list_key(data.id)}>{cols}</tr>
        );
    
  }



  render() {

    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'enquiries'} icon={Icons.enquire} >
           
          </ListHeader>

          <ListForm
            form={(
              <EnquireFilter getResults={this.filterRecords} />
          )}

            table={(

              <ShowRecords
                  url={"systemInfo/systemInfo"}
                  showRecords={this.showContent}
                  showCheckbox={true}
                  ref={this.records}
                isNeedAction={true}
                  actionButtons={actionButton({records: this.records, module: 'enquiries', 'url': "systemInfo/systemInfo/bulk-action"})}
              />
          )}
          />
        </ListingLayout>
    )
  }
}