import * as React from 'react';
import {Breadcrumb} from "../../common/page/Breadcrumb";
import Lang from "../../common/lang/Lang";
import {EditBreadcrumb} from "./Data";
import {DataSchema, FormDefaultVal} from "./Model";
import API from './../../common/AxoisClient';
import {showNotification} from "../../common/Common";
import {CommonSubmitButton, InlineLoader, Select,SwitchLoader} from "../../common";
import {CompanyTabs} from "./Tabs";
import {LoaderType} from "../../common/Loader";
import { Icons } from '../../common/Icon'

interface IState{
  form: DataSchema;
  id: number;
  trans_types?: Array<any>,
  calculation?: Array<any>
  uploadDocument?: File;
}

export class Importdepartment extends React.Component<{}, IState> {
  state: IState;

  constructor(props:any){
    super(props);

    this.state = {
      form: FormDefaultVal,
      id: props.match.params.id,
      uploadDocument: null,
    }
  }

  componentDidMount() {
    API.get(`settings/time-calculation/${this.state.id}`, {params: {type: '1'}}).then((res) => {
      this.setState({
        trans_types: res.data.trans_types,
        calculation: res.data.calculation
      })
    })
  }

  handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0]
    this.setState((state) => ({ ...state, uploadDocument: file }))
  }

  handlefileSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#file_form").valid()) {
    let formData = new FormData();
     formData.append('attachment', this.state.uploadDocument);
     formData.append('company_id', `${this.state.id}`);
      API.post('settings/department/Import-file', formData).then((res) => {
         showNotification("success", res.data);
        
      });
  }   
  }


  render() {
    return (
      <div>
        <Breadcrumb data={EditBreadcrumb}/>

        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
               <CompanyTabs active={'import'} company_id={this.state.id}/>
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                  
                  <form className="kt-form" id={'file_form'} onSubmit={this.handlefileSubmit} style={{width: "100%"}}>
                    <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                      <div className="kt-heading kt-heading--md">{Lang.import_department_file}</div>
                      
                        <div>
                          
                        </div>
                       

                      <div className={'row departMentPT'}>
                    <div className={"col-md-12 col-lg-12"}>
                    <div className="form-group" style={{marginTop:10, marginLeft:0}}>
                      <a className={'btn btn-label-primary'} href="/admin/assets/files/department-unit.xlsx" >{Lang.view_file}</a>
                      </div>
                    <div className="form-group" >
                                <div className="row ">
                                <div className="col-md-10 mt-1">
                                  <div className="custom-file">
                                    <input type={'file'} onChange={this.handleUpload} name="attachment" className="custom-file-input"  />
                                    <label className="custom-file-label" htmlFor="customFile">{this.state.uploadDocument? <span>Document Selected <i className={Icons.tick_mark}/></span> : Lang.choose_file}</label>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <CommonSubmitButton/>
                                </div>
                      </div> 
                     
                      </div>
                      
                </div>
                        
                
                     
                </div>
                    </div>
                    
                  </form>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}