import * as React from 'react';
import {default as Lang} from "../../common/lang/Lang"
import {
    ActionLink, EditLink, Icons, IState as CommonState, LoaderType, SetWaitingArea, ShowRecords,
    SwitchLoader, showNotification
} from "../../common";
import {RouteComponentProps} from "react-router";
import {actionButton} from "../../common/table/BlukActionOrderButtons";
import {ListBreadcrumb} from "./Data";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {OrderModel, OrderType} from "../../model";
import {OrderInfo} from "./OrderInfo";
import _ from "lodash";

import {OrderActions} from "./OrderActions";
import {
    AssignTranslator,
    CancelOrder,
    FixPrice,
    HistoryPopup,
    OrderComments,
    SendMailToCustomer,
    SendMailToTranslator,
    FixPriceCalculation
} from "./popup";
import {CompleteOrderPopup} from "./popup/CompleteOrder";
import {list_key, orderContentShow} from "../../helpers/OrderHelper";
import {CorrectionRequestPopup} from "./popup/CorrectionRequest";
import {OrderCommentService, OrdersinfoService, syncComdiaOrders} from "../../services";
import ActiveOrderFilter from './filter/ActiveOrderFilter';
import Files from "./Files";
const moment = require("moment");
moment.locale('da');
declare var window:any;

interface IState extends CommonState {
    ClaimData: Array<OrderModel>;
    ModalData?: OrderModel;
    show?: boolean;
    languages: Array<{value: string, label:string}>;
    companies: Array<{value: string, label:string}>;
}


export class OrderListing extends React.Component<RouteComponentProps, IState> {
    state: IState;
    records:React.RefObject<ShowRecords> = React.createRef();
    interval:any;

    componentDidMount() {
        OrdersinfoService.getPopupState().subscribe((res:any) => {
            this.setState({
                ModalData : res.data,
                show: true
            })
        })
        this.interval = setInterval( () => {
            SetWaitingArea('listing');
            this.filterRecords();
        }, 30000);


        let __this = this;
        $('body').on('click','.order_comment_submit_button',function () {
            let textFieldVal = $(this).parents('.modal-content').find('textarea[name="comment"]').val();
            if(!_.isEmpty(textFieldVal)) {
                setTimeout(function () {
                    SetWaitingArea('listing');
                    __this.filterRecords();
                },1000);
            }
        });

    }

    componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        return  this.state !== nextState;
    }

    showContent = (data: OrderModel, index: number) => {
        let orderDate = data.order_date + ' 00:00:00';
        orderDate = $.trim(orderDate);
        let cols = orderContentShow(this.records, data, index, this.filterRecords);
        cols.push(this.records.current.createdTd("action", () => {
            return (
                <div>
                    <ActionLink scope={'orders.index'} to={`/orders/order/info/${data.id}`} data-skin={'dark'} title={Lang.view_order} >
                        <i className={`kt-nav__link-icon ${Icons.view}`} />
                    </ActionLink>
                    <EditLink scope={'orders.update'} to={`/orders/order/edit/${data.id}`} data-skin={'dark'}  title={Lang.edit_order} />

                    <ActionLink scope={'orders.index'} to={'#'}    onClick={() =>OrderCommentService.showPopup(data)}   title={'Comments'}>
                        <i className="la la-comments"/> <span className="commentCountBox">{data.comments_count}</span>
                    </ActionLink>
                    { data.translation_type_id === OrderType.DOCUMENT && <Files files={data.order_files} /> }
                    <OrderActions order={data} refresh={'order'}/>
                </div>
            )
        }));
        cols = _.compact(cols);
        return (
            <tr key={list_key(data.id)} className={(moment(orderDate) <= moment() && data.status == 1)?'customdataBlink':''}>{cols}</tr>
        );
    }

    filterRecords = (data?: any) => {
        if( this.records && this.records.current !== null){
            SwitchLoader(LoaderType.NONE);
            if(data !== undefined){
                this.records.current.setFormFields(data, true);
            }else {
                this.records.current.setFormFields(undefined, true);
            }
        }
    }

    handleSync = () => {
        syncComdiaOrders().then(res => {
          showNotification('success', 'Orders synchronized successfully');
        });
    }

    render() {

        return (
            <ListingLayout breadcrumb={ListBreadcrumb}>
                <ListHeader title={'active_order_title'} icon={Icons.order} >
                    &nbsp;
                </ListHeader>
                <ListForm
                    form={(
                        <ActiveOrderFilter getResults={this.filterRecords}  languages={this.state !== null ? this.state.languages: []} companies={this.state !== null ? this.state.companies: []} />
                    )}
                    table={(
                        <ShowRecords
                            url={"orders/orders"}
                            showRecords={this.showContent}
                            ref={this.records}
                            isNeedAction={false}
                            showCheckbox={false}
                            loader={false}
                            actionButtons={actionButton({records: this.records, 'url': "orders/orders/bulk-action"})}
                            callBack={(data) => {
                                //window.KTApp.initTooltips();

                            }}
                        />
                    )}
                />
                {
                    this.state !== null &&
                    <OrderInfo
                        show={this.state.show}
                        onHide={() => this.setState({show: false})}
                        orderinfo={this.state.ModalData}/>
                }


                <AssignTranslator refresh={this.filterRecords} />
                <SendMailToCustomer />
                <SendMailToTranslator />
                <CancelOrder refresh={this.filterRecords} />
                <OrderComments/>
                <FixPrice/>
                <HistoryPopup />
                <CompleteOrderPopup refresh={this.filterRecords}/>
                <CorrectionRequestPopup />
            </ListingLayout>

        )
    }
}