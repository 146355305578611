import * as React from 'react';
import {Route, RouteComponentProps} from "react-router-dom";
import {Breadcrumb} from "../../common";
import {EditBreadcrumb} from "../translators/Data";
import _ from "lodash";
import {BasicTranslatorDetail} from "./Side";
import {EditTranslators} from "./edit";
import {TaxAndPayment} from "./taxPayment";
import {Rates} from "./Rates";
import {TChangePassword} from "./ChangePassword";
import {TranslatorOrders} from "./Orders";
import {Notification} from "../../common";
import {Languages} from "./Languages";
import {Translatortype} from "./Translatortype";
import {Leaves} from "./Leaves";
import {ActivityLog} from "./ActivityLog";
import {TranslatorOnlineTest} from "./OnlineTest";
import {TranslatorOnlineQuiz} from "./OnlineQuiz";

export function TranslatorTabs(props: RouteComponentProps) {

    const routes = {
        info: props.match.path,
        tax:`${props.match.path}/tax`,
        //language: `${props.match.path}/languages`,
        services: `${props.match.path}/services`,
        rates: `${props.match.path}/rates`,
        leaves: `${props.match.path}/leaves`,
        password: `${props.match.path}/change-password`,
        order: `${props.match.path}/order`,
        notification: `${props.match.path}/notification`,
        activitylog: `${props.match.path}/activitylog`,
        onlineTest: `${props.match.path}/online-test`,
        onlineQuiz: `${props.match.path}/online-quiz`,
    };

    const {id}: any = props.match.params;

    function activeRoute() {
        return _.findKey(routes, function(v) {
            v = _.replace(v, ':id', id);
            return v === props.location.pathname;
        })
    }
    return (
        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
            <Breadcrumb data={EditBreadcrumb}/>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
                    <div className="kt-grid__item kt-app__toggle kt-app__aside" id="kt_user_profile_aside">
                        <div className="kt-portlet ">
                            <div className="kt-portlet__head  kt-portlet__head--noborder">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body kt-portlet__body--fit-y">
                                <BasicTranslatorDetail  translator_id={id} active={activeRoute()} />
                            </div>
                        </div>
                    </div>
                    <Route exact path={routes.info} component={EditTranslators} />
                    <Route exact path={routes.tax} component={TaxAndPayment} />
                    <Route path={routes.services} component={Translatortype} />
                    <Route path={routes.rates} component={Rates} />
                    <Route path={routes.leaves} component={Leaves} />
                    <Route path={routes.password} component={TChangePassword}  />
                    <Route path={routes.order} component={TranslatorOrders}  />
                    <Route path={routes.notification} component={Notification}  />
                    <Route path={routes.activitylog} component={ActivityLog}  />
                    <Route path={routes.onlineTest} component={TranslatorOnlineTest}  />
                    <Route path={routes.onlineQuiz} component={TranslatorOnlineQuiz}  />
                </div>
            </div>
        </div>
    );
}