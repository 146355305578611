import React from 'react'
import { Statistics as SType } from '../InvoicingType'

//? Styles
import sheet from './Statistics.module.scss'

interface Props {
  statistics: SType
}

const Statistics = ({ statistics: { queuedOrders, yesterdayOrders } }: Props) => {
  return (
    <div className={sheet.container}>
      <div className={sheet.info_piece}>
        <span>{queuedOrders}</span>
        <span>Orders in queue</span>
      </div>
      <div className={sheet.info_piece}>
        <span>{yesterdayOrders}</span>
        <span>Orders invoiced yesterday</span>
      </div>
    </div>
  )
}

export default Statistics