import React, { useReducer, useEffect, useState } from "react";
import Lang from "../../../common/lang/Lang";
import { Modal } from "react-bootstrap";
import {
  initialTranslators,
  TranslatorReducer,
  ActionType,
  PopupState,
  IAction,
} from "../order-reducers";
import { OrderType, TranslatorBusyAssignment, UserModel } from "../../../model";
import {
  getTodayOrdersForTranslator,
  assignTranslators,
  getInvitedTranslators,
  getRejectedTranslators,
  getTranslators,
  TranslatorService,
  updatePopupFlagForOrder,
} from "../../../services";
import {
  CONST,
  Icons,
  Select,
  showNotification,
  SubmitButton,
  SwitchLoader,
  LoaderType,
  RequestWaiting,
  SetWaitingArea,
} from "../../../common";
import { CancelProps } from "../order-reducers/CancelOrderReducer";
import { order_time, order_to_language } from "../../../helpers/OrderHelper";
import _, { cloneDeep, indexOf, isEmpty, omit, set } from "lodash";
import API from "../../../common/AxoisClient";
import "moment/min/locales";
import TranslatorCard from "./Components/TranslatorCard/TranslatorCard";
const moment = require("moment");

declare var window: any;

export function AssignTranslator(props: CancelProps) {
  const [state, dispatchTranslator] = useReducer<React.Reducer<PopupState, IAction>>(
    TranslatorReducer,
    initialTranslators
  );
  const [locationArr, setLocationArr] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [showOriginCountry, setShowOriginCountry] = useState(false);
  const [BusyTranslatorAssignments, setBusyTranslatorAssignments] = useState<{
    [key: string]: TranslatorBusyAssignment[];
  }>({});

  useEffect(() => {
    SetWaitingArea("assign");
    TranslatorService.getPopupState().subscribe((message: any) => {
      dispatchTranslator({ type: ActionType.SHOW_POPUP, payload: message });
    });
  }, []);

  useEffect(() => {
    if (!state.show) setBusyTranslatorAssignments({});
  }, [state.show]);

  function fetchTranslatorsForAssign() {
    getTranslators(state.form).then((res) => {
      dispatchTranslator({ type: ActionType.GET_LIST, payload: res });
      window.KTApp.initTooltips();
    });
  }

  useEffect(() => {
    if (state.show) {
      SetWaitingArea("assign");
      API.get("users/translators/locations").then((res) => {
        setLocationArr(res.data.locationArr);
      });
      fetchTranslatorsForAssign();
    }
  }, [state.form]);

  if (state.show === false) {
    return null;
  }

  function getTranslator(e) {
    dispatchTranslator({ type: ActionType.CHANGE_FORM, payload: e.target });
  }

  function getCheckAll(e) {
    state.translatorsList.forEach((item) => {
      if (Number(item.is_busy) || Number(item.on_holiday)) return;
      if (e.target.checked) {
        dispatchTranslator({ type: ActionType.ADD_TRANS, payload: String(item.id) as any });
        setShowButtons(true);
      } else {
        dispatchTranslator({ type: ActionType.REMOVE_TRANS, payload: String(item.id) as any });
        setShowButtons(false);
      }
    });
  }

  function showOriginCountryFlag(e) {
    if (e.target.checked == true) {
      setShowOriginCountry(true);
    } else {
      setShowOriginCountry(false);
    }
  }

  const updateDayOrders = async (translator_id: number, order_id: number) => {
    fetchTranslatorsForAssign();
    const orders: TranslatorBusyAssignment[] = await getTodayOrdersForTranslator({
      translator_id,
      order_id,
    });
    setBusyTranslatorAssignments(
      set(cloneDeep(BusyTranslatorAssignments), String(translator_id), orders)
    );
  };

  const HandleChange = (e, order_id, is_busy, is_holiday) => {
    const ele = e.target;
    if (ele.checked === true) {
      SetWaitingArea("");
      getTodayOrdersForTranslator({ translator_id: e.target.value, order_id: order_id }).then(
        (res: TranslatorBusyAssignment[]) => {
          setBusyTranslatorAssignments(
            set(cloneDeep(BusyTranslatorAssignments), String(ele.value), res)
          );
          if (is_busy != 1 && is_holiday != 1) {
            dispatchTranslator({ type: ActionType.ADD_TRANS, payload: ele.value });
            setShowButtons(true);
          } else if (state.translators.length === 0) setShowButtons(false);
        }
      );
    } else {
      setShowButtons(true);
      if (is_busy != 1 && is_holiday != 1) {
        dispatchTranslator({ type: ActionType.REMOVE_TRANS, payload: ele.value });
      }
      setBusyTranslatorAssignments(omit(BusyTranslatorAssignments, String(ele.value)));
    }
  };

  const HandleClose = (order_id) => {
    SetWaitingArea("");
    updatePopupFlagForOrder({ order_id: order_id }).then((res) => {
      dispatchTranslator({ type: ActionType.HIDE_POPUP });
    });
  };

  function handleSubmit(type) {
    SwitchLoader(LoaderType.BUTTON);
    SetWaitingArea("assign");
    assignTranslators({
      order_id: state.order_id,
      translators: state.translators,
      type: type,
    }).then((res) => {
      showNotification("success", res);
      props.refresh();
      dispatchTranslator({ type: ActionType.HIDE_POPUP });
    });
  }

  const invitations = getInvitedTranslators(state.order_data.invitations);

  const rejected_by_sms = getRejectedTranslators(state.order_data.invitations);

  const display_order_date = (order_date) => {
    var format_date = moment(order_date);
    format_date.locale("da");
    return (
      <>
        {" "}
        {format_date.format("dddd")} d. {format_date.format("DD-MM-YYYY")}{" "}
      </>
    );
  };

  function resetForm(event: any) {
    event.preventDefault();
    var inputElement = document.getElementsByTagName("input");
    for (var i = 0; i < inputElement.length; i++) {
      if (inputElement[i].type == "checkbox") {
        inputElement[i].checked = false;
      } else if (inputElement[i].type == "text") {
        inputElement[i].value = "";
      }
    }

    var location = document.getElementById("location") as HTMLSelectElement;
    location.selectedIndex = 0;
    var gender = document.getElementById("gender") as HTMLSelectElement;
    gender.selectedIndex = 0;
    var rating = document.getElementById("rating") as HTMLSelectElement;
    rating.selectedIndex = 0;
  }

  return (
    <Modal
      show={state.show}
      onHide={() => {
        HandleClose(state.order_data.id);
      }}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {Lang.assign_translator} - #{state.order_data.order_no}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <form>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <b>Sprog</b>: {state.order_data.language_from.language_name} -{" "}
                {order_to_language(state.order_data)}
              </div>
              <div className={"col-md-12"}>
                <b>Dato</b>: {display_order_date(state.order_data.order_date)}
              </div>
              <div className={"col-md-12"}>
                <b>Tid</b>: {order_time(state.order_data)}
              </div>
              <div className={"col-md-12"}>
                <b>Kunde</b>: {state.order_data.company.name}
              </div>
              {state.order_data.translation_type_id === OrderType.PHYSICAL && (
                <div className={"col-md-12"}>
                  <b>Adresse</b>: {state.order_data.meeting_address}
                </div>
              )}
            </div>
            <br />
            <div className={"row"}>
              <div className={"col-md-12"}>
                <div className={"row"}>
                  <div className={"col-md-4"}>
                    <div className={"form-group"}>
                      <label className={"kt-checkbox kt-checkbox--brand"}>
                        Certified
                        <input type={"checkbox"} onChange={getTranslator} name={"is_certified"} />
                        <span />
                      </label>
                    </div>
                  </div>
                  <div className={"col-md-4"}>
                    <div className={"form-group"}>
                      <label className={"kt-checkbox kt-checkbox--brand"}>
                        Push Agreed
                        <input type={"checkbox"} onChange={getTranslator} name={"push_agreement"} />
                        <span />
                      </label>
                    </div>
                  </div>
                </div>

                <div className={"row"}>
                  <div className={"col-md-4"}>
                    <div className={"form-group"}>
                      <Select
                        className="form-control"
                        id="location"
                        name="location"
                        onChange={getTranslator}
                      >
                        <option value="" selected={true}>
                          {Lang.select} {Lang.location}
                        </option>
                        {locationArr &&
                          locationArr.length > 0 &&
                          locationArr.map((locations) => {
                            return <option value={locations.value}>{locations.text}</option>;
                          })}
                      </Select>
                    </div>
                  </div>
                  <div className={"col-md-4"}>
                    <div className={"form-group"}>
                      <Select
                        className={"form-control"}
                        id="gender"
                        name={"gender"}
                        onChange={getTranslator}
                      >
                        <option value="">All</option>
                        <option value={"1"}>Male</option>
                        <option value={"2"}>Female</option>
                      </Select>
                    </div>
                  </div>
                  <div className={"col-md-4"}>
                    <div className={"form-group"}>
                      <Select
                        className={"form-control"}
                        id="rating"
                        name={"rating"}
                        onChange={getTranslator}
                      >
                        <option value="">{Lang.select_rating}</option>
                        <option value={"1"}>0-1</option>
                        <option value={"2"}>1-2</option>
                        <option value={"3"}>2-3</option>
                        <option value={"4"}>3-4</option>
                        <option value={"5"}>4-5</option>
                      </Select>
                    </div>
                  </div>
                  {state.order_data.translation_type_id === OrderType.PHYSICAL && (
                    <div className={"col-md-4"}>
                      <div className={"form-group"}>
                        <Select
                          className={"form-control"}
                          name={"distance"}
                          onChange={getTranslator}
                        >
                          <option value="">{Lang.select_distance}</option>
                          <option value={"10"}> {"<10 KM"}</option>
                          <option value={"20"}> {"<20 KM"}</option>
                          <option value={"50"}> {"<50 KM"}</option>
                          <option value={"100"}> {"<100 KM"}</option>
                          <option value={"100+"}> {">100 KM"}</option>
                        </Select>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            mokrieger &nbsp;&nbsp;
            <label className={"kt-checkbox kt-checkbox--brand"}>
              Select All
              <input type={"checkbox"} onChange={getCheckAll} name={"check_all"} />
              <span />
            </label>
            &nbsp;&nbsp;
            <label className={"kt-checkbox kt-checkbox--brand"}>
              Vælg oprindelsesland
              <input type={"checkbox"} onChange={showOriginCountryFlag} />
              <span />
            </label>
            <hr />
            <div className={"row"}>
              <RequestWaiting item={state.translatorsList} from={"assign"}>
                {state.translatorsList &&
                  state.translatorsList
                    .filter((trans) => !state.un_fav_translators.includes(trans.id))
                    .sort((trans) => Number(state.fav_translators.includes(trans.id)))
                    .map((item: UserModel, index: number) => {
                      const already_sent = invitations.indexOf(item.id);
                      const rejected = rejected_by_sms.indexOf(item.id);
                      let labelTitle = "";
                      if (already_sent >= 0 && rejected < 0) {
                        labelTitle = "Already Invited";
                      } else if (already_sent >= 0 && rejected >= 0) {
                        labelTitle = "AFVIST";
                      }

                      let checkBoxClassName = "";
                      if (item.is_busy == 1 || item.on_holiday == 1) {
                        checkBoxClassName = "not-selectable";
                      } else {
                        checkBoxClassName = "selectable";
                      }
                      return (
                        <div className={"col-md-12 col-lg-6 col-xl-4"} key={item.id}>
                          <label style={{ width: "100%" }}>
                            <input
                              type="checkbox"
                              style={{ display: "none" }}
                              checked={
                                (state.translators as unknown as string[]).includes(
                                  String(item.id)
                                ) || !isEmpty(BusyTranslatorAssignments[String(item.id)])
                              }
                              onChange={(e) =>
                                HandleChange(e, state.order_data.id, item.is_busy, item.on_holiday)
                              }
                              value={item.id}
                            />
                            <TranslatorCard
                              image_url={item.profile_image}
                              gender={Number(item.gender)}
                              rating={item.rating}
                              reviews={item.totalreviews}
                              name={item.name}
                              is_online={!!item.is_online}
                              user_code={String(item.user_code)}
                              is_busy={!!Number(item.is_busy)}
                              on_holiday={!!Number(item.on_holiday)}
                              expensive={Number(item.translator.exp_scale) as 1 | 2 | 3}
                              already_sent={already_sent !== -1}
                              rejected={rejected !== -1}
                              total_distance={item.total_distance}
                              travel_time={item.travel_time}
                              push_agreement={!!item.translator.push_agreement}
                              is_certified={!!item.translator.is_certified}
                              fav_translator={indexOf(state.fav_translators, item.id) !== -1}
                              unfav_translator={indexOf(state.un_fav_translators, item.id) !== -1}
                              fav={!!Number(item.fav)}
                              show_origin_country={showOriginCountry}
                              selected={
                                indexOf(
                                  state.translators as unknown as string[],
                                  String(item.id)
                                ) !== -1
                              }
                              country_code={item.country_code}
                              mobile_no={item.mobile_no}
                              email={item.email}
                              last_order_invite={item.last_order_invite}
                              country_iso={item.country_iso}
                              updateDayOrders={updateDayOrders}
                              translation_assignments={BusyTranslatorAssignments[String(item.id)]}
                            />
                          </label>
                          <div className="translator-list" style={{ display: "none" }}></div>
                        </div>
                      );
                    })}
              </RequestWaiting>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className={"btn btn-default hvr-rectangle-out btnMove reset-btn"}
          data-skin={"dark"}
          title={Lang.reset}
          onClick={(e) => resetForm(e)}
        >
          {Lang.reset}
        </button>
        {state.translators.length >= 1 && showButtons && (
          <div>
            <SubmitButton
              onClick={() => handleSubmit("send")}
              className={"btn btn-primary"}
              label={Lang.send}
            />
            &nbsp;&nbsp;
            <SubmitButton
              onClick={() => handleSubmit("send_sms")}
              className={"btn btn-primary"}
              label={Lang.send_sms}
            />
          </div>
        )}

        {state.translators.length == 1 && showButtons && (
          <SubmitButton
            onClick={() => handleSubmit("push")}
            className={"btn btn-warning"}
            label={Lang.push}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}
