import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { CONST, Icons } from '../../../../../common';
import Lang from '../../../../../common/lang/Lang';
import { Gender, TranslatorBusyAssignment } from '../../../../../model';
import InfoPill, { AvailableColors } from './components/InfoPill/InfoPill';
import Stars from './components/Stars/Stars';
import TodayOrdersInfo from './components/TodayOrdersInfo/TodayOrdersInfo';


// style
import sheet from './TranslatorCard.module.scss';


interface Props {
  image_url: string
  gender: Gender
  rating: number
  reviews: number
  name: string
  is_online: boolean
  user_code: string
  is_busy: boolean
  expensive: 1 | 2 | 3
  already_sent: boolean
  rejected: boolean
  total_distance: number
  travel_time: number
  push_agreement: boolean
  is_certified: boolean
  fav_translator: boolean
  unfav_translator: boolean
  fav: boolean
  show_origin_country: boolean
  selected: boolean
  on_holiday: boolean
  country_code: string
  mobile_no: string
  email: string
  country_iso: string
  last_order_invite: number | null
  updateDayOrders: (translator_id: number, order_id: number) => Promise<void>
  translation_assignments?: TranslatorBusyAssignment[]
}

const TranslatorCard = ({country_iso, show_origin_country, selected, fav, name, gender, rating, user_code, is_online, reviews, push_agreement, is_certified, fav_translator, unfav_translator, already_sent, rejected, is_busy, on_holiday, expensive, country_code, mobile_no, total_distance, travel_time, updateDayOrders, translation_assignments, email, last_order_invite} : Props) => {
  const [ButtonHover, setButtonHover] = useState<boolean>(false)
  const [Icon, setIcon] = useState<string | undefined>(undefined)
  useEffect(() => {
    if (Icon !== undefined) return
    const index = rngInRange((gender === 1 ? male_icons.length : female_icons.length) - 1)
    setIcon(gender === 1 ? male_icons[index] : female_icons[index])
  }, [])
  const buttonRef = useRef<HTMLAnchorElement>()
  const male_icons = ["👨🏻‍💼", "👨🏼‍💼", "👨🏽‍💼", "👨🏾‍💼", "👳🏽‍♂️"]
  const female_icons = ["👩🏻‍💼", "👩🏼‍💼", "👩🏽‍💼", "👩🏾‍💼", "🧕🏻", "🧕🏽"]
  const rngInRange = (max: number) => Math.floor(Math.random() * (max + 1))
  return (
    <div
      className={`
        ${sheet.outer_container}
        ${!selected && ButtonHover && sheet.outer_container_button_hovered}
        ${selected && sheet.selected}
        ${push_agreement && sheet.push_agreement}
        ${(is_busy || on_holiday )? sheet.unavailable : null}
      `}
    >
      <div className={sheet.container}>
        <div data-toggle="tooltip" className={sheet.gender_icon} data-placement="bottom" title={gender === 1 ? Lang.male : Lang.female}>{Icon}</div>
        <div className={sheet.info}>
          {/* Head data */}
          <div className={`${sheet.info_chain} ${sheet.head_info}`}>
            <Stars count={rating} cap={5} reviews={reviews} />
            {/* Info pills (top ones on the right) */}
            <div>
              { already_sent && rejected ? <InfoPill label='Afvist'/> : already_sent ? <InfoPill label='Sendt' color={AvailableColors.green} /> : null }
              { is_busy && <InfoPill label='Busy' color={AvailableColors.red}/> }
              { on_holiday && <InfoPill label='Unavailable' color={AvailableColors.red}/> }
              { fav && <InfoPill label='Fav' color={AvailableColors.green}/> }
              { expensive >= 2 && <InfoPill label={expensive === 2 ? "$" : "$$"} color={AvailableColors.green} /> }
            </div>
          </div>
          {/* Info */}
          <div className={`${sheet.info_chain} ${sheet.icon_info} ${reviews === 0 && sheet.offset_bottom}`}>
            {/* Name */}
            <div data-toggle="tooltip" data-placement="bottom" title={`+${country_code} - ${mobile_no}`} className={sheet.name}>
              <span>{name}</span>
              <Online is_online={is_online} />
              {/* <span>{last_order_invite} siden sidst</span> <<--- This is to remember the last order data sdource, last_order_invite is days since last order assignment (negative int)*/}
            </div>
            {/* icons (bottom ones on the right) */}
            <div className={`${sheet.info_chain} ${sheet.icons}`}> 
              { push_agreement && <img src={CONST.urls.push_image} data-skin="dark" data-toggle="tooltip" data-placement="bottom" title="Push Agreement" alt="Push Agreement" /> }
              { is_certified && <i data-toggle="tooltip" data-placement="bottom" title="Certificeret" className={`${Icons.medal_alt} ${sheet.medal}`}></i>}
              { (fav_translator || unfav_translator) && <i data-toggle="tooltip" data-placement="bottom" title={fav_translator ? `Favorite Translator` : 'Unwanted Translator'} className={`${fav_translator ? Icons.heart : Icons.broken_heart} ${sheet.heart}`}></i> }
              { show_origin_country && <FlagIcon iso={country_iso} /> }
            </div>
            {total_distance && <div className={sheet.distance}>{total_distance.toLocaleString("da-DK")} km, {travel_time.toLocaleString("da-DK")} min.</div>}
          </div>
        </div>
      </div>
      {
        (selected || !isEmpty(translation_assignments)) && (
          <div className={sheet.selected_contact_info}>
            <span><i className={`${Icons.email_at} ${sheet.contact_icons}`}/> <span className={sheet.contact_info_data}>{email}</span></span>&emsp;
            <span><i className={`${Icons.mobile} ${sheet.contact_icons}`}/> <span className={sheet.contact_info_data}>{mobile_no}</span></span>
          </div>
        )
      }
      { !isEmpty(translation_assignments) && <TodayOrdersInfo translation_assignments={translation_assignments} ButtonHover={ButtonHover} setButtonHover={setButtonHover} buttonRef={buttonRef} updateDayOrders={updateDayOrders} />}
    </div>
  )
};

const Online = ({ is_online }: { is_online: boolean }) => (
  <i 
    className={`${is_online ? Icons.online : Icons.offline} ${sheet.online_status} kt-tooltip`} 
    data-skin={'dark'} 
    data-toggle={'tooltip'} 
    title={is_online ? 'online' : 'offline'}
    style={{ color: is_online ? "green" : "red" }}
  ></i>
)

export const FlagIcon = ({iso, lang_name = "flag"}: {iso: string, lang_name?: string}) => <img data-toggle="tooltip" data-placement="bottom" title={lang_name} src={`/admin/assets/flags/${iso.toLowerCase()}.png`} className={sheet.country_flag} alt={lang_name} />

export default TranslatorCard;
