import * as React from 'react';
import {Link} from "react-router-dom";
import {Icons} from "../../common/Icon";
import Lang from "../../common/lang/Lang";

interface CompanyTabsSchemas {
  active: string;
  company_id: number
}

export class CompanyTabs extends React.Component<CompanyTabsSchemas> {
  constructor(props:CompanyTabsSchemas){
    super(props)
  }

  render() {
    
    return (
      <div className="kt-grid__item kt-wizard-v2__aside">
        <div className="kt-wizard-v2__nav">
          <div className="kt-wizard-v2__nav-items">
            <Link className="kt-wizard-v2__nav-item" to={`/settings/company/edit/${this.props.company_id}`} data-ktwizard-type="step" data-ktwizard-state={this.props.active == "info" ? "current" : ''} >
              <div className="kt-wizard-v2__nav-body">
                <div className="kt-wizard-v2__nav-icon">
                  <i className={Icons.company_info}/>
                </div>
                <div className="kt-wizard-v2__nav-label">
                  <div className="kt-wizard-v2__nav-label-title">
                    {Lang.company_info}
                  </div>
                  <div className="kt-wizard-v2__nav-label-desc">
                    {Lang.company_detail}
                  </div>
                </div>
              </div>
            </Link>

            <Link className="kt-wizard-v2__nav-item" to={`/settings/company/edit/${this.props.company_id}/time-calculation`} data-ktwizard-type="step" data-ktwizard-state={this.props.active == "time" ? "current" : ''}>
              <div className="kt-wizard-v2__nav-body">
                <div className="kt-wizard-v2__nav-icon">
                  <i className={Icons.company_time}/>
                </div>
                <div className="kt-wizard-v2__nav-label">
                  <div className="kt-wizard-v2__nav-label-title">
                    {Lang.company_time}
                  </div>
                  <div className="kt-wizard-v2__nav-label-desc">
                    {Lang.company_time_cal}
                  </div>
                </div>
              </div>
            </Link>
            <Link className="kt-wizard-v2__nav-item" to={`/settings/company/edit/${this.props.company_id}/import-department`} data-ktwizard-type="step" data-ktwizard-state={this.props.active == "import" ? "current" : ''}>
              <div className="kt-wizard-v2__nav-body">
                <div className="kt-wizard-v2__nav-icon">
                  <i className={Icons.import}/>
                </div>
                <div className="kt-wizard-v2__nav-label">
                  <div className="kt-wizard-v2__nav-label-title">
                    {Lang.import_department_file}
                  </div>
                  <div className="kt-wizard-v2__nav-label-desc">
                    {Lang.import_excel_file}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}