import * as React from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {getOrderForCal} from "../../../services";
import Localization, {default as Lang} from "../../../common/lang/Lang";
import {DateInput, getQueryStringParams,SearchSelect, SearchInput} from "../../../common";
import {PriceConstant, TranslatorTypeConstant, FeeTypeConstant, BookingStatus} from '../Data';
const moment = require('moment');

interface IState {
  form: {
    like: {
      search: string,
      email: string
    },
    between: {
      completed_at: {
        from: string;
        to:string;
      }
    },
    equal: {
      translator: number,
      price_type: number,
      translator_type: number,
      fee_type: number,
      booking_type: number,
    }
  }
 }

export default class PendingFilter extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
        like: {
           search: old_data ? old_data.like.search : '',
           email: old_data ? old_data.like.email : ''
        }, 
        between: {
          completed_at: {
            from: old_data ? old_data.between.completed_at.from: '',
            to:old_data ? old_data.between.completed_at.to: '',
          }
        },
        equal: {
          translator: old_data ? old_data.equal.translator : '',
          price_type: old_data ? old_data.equal.price_type : '',
          translator_type: old_data ? old_data.equal.translator_type: '',
          fee_type: old_data ? old_data.equal.fee_type: '',
          booking_type: old_data ? old_data.equal.booking_type : ''
        }
      }})
  }

  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    let name = target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    let curr = newAccess;
    for (let depth = 1; depth < name.length -1; depth++){
      curr = curr[name[depth]];
    }
    curr[name[name.length - 1]] =  target.type === 'checkbox' ?  target.checked : target.value;
    this.setState({form: newAccess},() =>  this.props.getResults(this.state.form));
  };

    formDataReset = (e:any) => { 
        e.preventDefault();
        this.setState({
            form:  {
                like: {
                  search: '',
                  email: ''
                },
                between: {
                  completed_at: {
                    from: '',
                    to:'',
                  }
                },
                equal: {
                  translator: 0,
                  price_type: 0,
                  translator_type: 0,
                  fee_type: 0,
                  booking_type: 0,
                }
            }
        });     

        var formData = {
                       like: {
                          search: '',email: ''
                        },
                        between: {
                          completed_at: {
                            from: '',
                            to:'',
                          }
                        },
                        equal: {
                          translator: 0,
                          price_type: 0,
                          translator_type: 0,
                          fee_type: 0,
                          booking_type: 0,
                        },
                       };

         this.setState({form: formData},() =>  this.props.getResults(this.state.form));
    }



  render(){
    const price_type = PriceConstant.price_status;
    const translator_type = TranslatorTypeConstant.translator_type;
    const fee_type = FeeTypeConstant.fee_type;
    const booking_type = BookingStatus.booking_status;
    const translator = this.props.translator;
      
    return (
      <div className="row payout-filter-section">
        <DateInput name={'form.between.completed_at.from'} value={this.state.form.between.completed_at.from} onChange={this.handleInputForm} placeholder={Lang.from_date} />
        <DateInput name={'form.between.completed_at.to'} value={this.state.form.between.completed_at.to} onChange={this.handleInputForm} placeholder={Lang.to_date}/>
        <SearchInput name={'form.like.search'} value={this.state.form.like.search} onChange={this.handleInputForm} placeholder={Lang.order_no} />
        <SearchSelect name={'form.equal.translator'} value={this.state.form.equal.translator}  onChange={this.handleInputForm} options={[{label: Lang.select_translator_string, value: ''}, ...translator]}/>        
        <SearchSelect name={'form.equal.translator_type'} value={this.state.form.equal.translator_type}  onChange={this.handleInputForm} options={translator_type}/>
        <SearchSelect name={'form.equal.fee_type'} value={this.state.form.equal.fee_type}  onChange={this.handleInputForm} options={fee_type}/>
        <SearchSelect name={'form.equal.price_type'} value={this.state.form.equal.price_type}  onChange={this.handleInputForm} options={price_type}/>
        <SearchSelect name={'form.equal.booking_type'} value={this.state.form.equal.booking_type}  onChange={this.handleInputForm}  options={booking_type}/>
        <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn custom-reset-button'} data-skin={'dark'} title={Lang.reset} onClick={(e)=>this.formDataReset(e)}>{Lang.reset}</button>
      </div>
    )
  }
}