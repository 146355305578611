import React, {useEffect, useReducer, useState} from 'react';
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {
  InitialState, PopupState, ActionType,
  CAction, OrderPopupReducer
} from "./OrderPopupReducer";
import {SeeOrdertelmessageService} from "../../../services/OrderServices";


export function Telmessage () {

  const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(OrderPopupReducer , InitialState);

  useEffect( () => {
    SeeOrdertelmessageService.getPopupState().subscribe((message:any) => {
      dispatch({type: ActionType.SHOW_POPUP, payload: message}) ;
    });

  }, []);

  if(!state.show){
    return null;
  }

  
  return(
    
    <Modal
    
      show={state.show}
      onHide={() => { dispatch({type: ActionType.HIDE_POPUP}) } }
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form  id="fix_price_form">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {Lang.order_no} - #{state.orders.order_no}<br/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {state.orders.tel_message}
        </Modal.Body>
        <Modal.Footer>
        <button type={'button'} className={'btn btn-primary'} onClick={() => { dispatch({type: ActionType.HIDE_POPUP}) }}>{Lang.close}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}