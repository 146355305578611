import API, {base_url} from "../common/AxoisClient";
import {AxiosResponse} from "axios";

interface UserInfo {
  name: string;
  id: number;
  email: string;
  mobile_no?: string;
  country_code?: string;
  role_id: number;
  scopes: Array<string>
  token: string;
}

interface AuthSchema {
  isAuthenticated: boolean;
  userInfo: UserInfo|null;
  token: string;
  
  authenticate: (data: any) => void;
  signout: () => void;
  reAuthenticate: ($token: string) => any;
  systemlogout: ($token: string) => any;
  setToken: ($token: string) => any;
}
export const Auth : AuthSchema = {
  isAuthenticated: false,
  userInfo: null,
  token: '',
  
  authenticate( data: any) {
    this.isAuthenticated = true;
   // localStorage.setItem('userInfo', JSON.stringify(data));
    this.userInfo = data;
    this.token = data._token;
  },
  signout() {
    this.isAuthenticated = false;
     this.userInfo = null;
    this.token = null;
  },

  async reAuthenticate(token: string) {
    this.token = token;
    const res = await API.get(`${base_url}re-authenticate`);
    Auth.authenticate(res.data);
    this.token = token;
    return res.data;
  },

  async systemlogout(token: string) {
    this.isAuthenticated = false;
   // this.token = token;
    const res = await API.get(`${base_url}systemlogout`);
    this.token = null;
    this.userInfo = null;
     
    return res.data;
  },
  setToken(token: string) {
    this.token = token;
  }
};