import React, {useEffect, useState} from 'react';
import Chart from 'chart.js';
import {DashboardService} from "../services";
import {Icons, LoaderType, RequestWaiting, SetWaitingArea, SwitchLoader} from "../common";
import Lang from "../common/lang/Lang";
import { empty } from 'rxjs';
import _ from "lodash";


export function OrderChart(props) {

  const [data, setData] = useState();
  let chartdata:any = {}

  useEffect(() => {
      graphData();
      return()=>{
        if(!_.isEmpty(chartdata)) {
          chartdata.destroy();
        }

      }
     
   }, [props]);

  

  function graphData() {
    SwitchLoader(LoaderType.NONE);
    SetWaitingArea('order-chart');
    DashboardService.getOrderGraphData(props.startDate,props.endDate).then(res => {
     setData(res);
      chartdata = new Chart('kt_chart_daily_sales', {
       
        type: 'line',
        data: {
          labels: res.label,
          datasets: [
            {
              label: Lang.order_new_order,
              data: res.recent,
              borderWidth: 1,
              fill:false,
              borderColor:"blue",
              lineTension:0.1
            },
            {
              label: Lang.order_complete,
              data: res.completed,
              borderWidth: 1,
              fill:false,
              borderColor:"green",
              lineTension:0.1
            },
            {
              label:Lang.order_cancelled,
              data: res.cancelled,
              borderWidth: 1,
              fill:false,
              borderColor:"red",
              lineTension:0.1
            }
          ]
        },
        options: {
          tooltips: {
            mode: 'nearest',
            intersect: false,
            position: 'nearest',
            
          },
          responsive: true,
        }
      });
    })
  }

  return (
    <div className="kt-portlet kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            {Lang.recent_orders}
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar">
          <div className="dropdown dropdown-inline">
            {/* <button type="button" className="btn btn-clean btn-sm btn-icon-md btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="flaticon-more-1"/>
            </button> */}
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-fit dropdown-menu-md">

              <ul className="kt-nav">
                <li className="kt-nav__head">
                  Export Options
                  <span data-toggle="kt-tooltip" data-placement="right" title="Click to learn more...">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand kt-svg-icon--md1">
                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <rect id="bound" x="0" y="0" width="24" height="24" />
                        <circle id="Oval-5" fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                        <rect id="Rectangle-9" fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
                        <rect id="Rectangle-9-Copy" fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
                      </g>
                    </svg>
                  </span>
                </li>
                <li className="kt-nav__separator"/>
                <li className="kt-nav__item">
                  <a href="#" className="kt-nav__link">
                    <i className="kt-nav__link-icon flaticon2-drop"/>
                    <span className="kt-nav__link-text">Activity</span>
                  </a>
                </li>
                <li className="kt-nav__item">
                  <a href="#" className="kt-nav__link">
                    <i className="kt-nav__link-icon flaticon2-calendar-8"/>
                    <span className="kt-nav__link-text">FAQ</span>
                  </a>
                </li>
                <li className="kt-nav__item">
                  <a href="#" className="kt-nav__link">
                    <i className="kt-nav__link-icon flaticon2-link"/>
                    <span className="kt-nav__link-text">Settings</span>
                  </a>
                </li>
                <li className="kt-nav__item">
                  <a href="#" className="kt-nav__link">
                    <i className="kt-nav__link-icon flaticon2-new-email"/>
                    <span className="kt-nav__link-text">Support</span>
                    <span className="kt-nav__link-badge">
									 <span className="kt-badge kt-badge--success kt-badge--rounded">5</span>
									</span>
                  </a>
                </li>
                <li className="kt-nav__separator"/>
                <li className="kt-nav__foot">
                  <a className="btn btn-label-danger btn-bold btn-sm" href="#">Upgrade plan</a>
                  <a className="btn btn-clean btn-bold btn-sm" href="#" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more...">Learn more</a>
                </li>
              </ul>

            </div>
          </div>

          <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" onClick={graphData}>
            <i className={Icons.refresh} title={Lang.refresh}  data-toggle="kt-tooltip" data-placement="top" data-original-title={Lang.refresh} />
          </button>
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="kt-widget14__chart" >
          <RequestWaiting item={data} from={'order-chart'}>
            <canvas id="kt_chart_daily_sales"/>
          </RequestWaiting>
        </div>
      </div>
    </div>
  )
}