import * as React from 'react';
import {CreateLayout} from "../../../layout/CreateLayout";
import {CreateOrderBreadcrumb} from "../Data";
import Lang from "../../../common/lang/Lang";
import API from './../../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {AxiosResponse} from "axios";
import {CustomerWidget} from "../CustomerWidget";
import {
    ClientData,
    ClientSuggestion,
    DepartmentModel, DepartmentUnitModel, OrderContactPerson, OrderType, ProjectModel,
    TranslationTypeModel,
    UserModel
} from "../../../model";
import {CreateOrderInterface, OrderDateTimeInterface, OrderForm, OrderValidations} from "./OrderForm";
import {
  Icons,
  DatePickerOptions,
  showNotification,
  TimePickerOptions,
  FormElementGroup,
  CommonSubmitButton,
  Select,
  SwitchLoader,
  LoaderType,
  getMapContent,
  GooglePlace,
  Select2Wrapper,
  LanguageFlags,
  DatePickerOptionsForCreateOrUpdate
} from "../../../common";
import _ from 'lodash';
import {getCustomerInfo, getOrderInfo} from "../../../services";
import {Document} from "./Document";
import {SpecificTranslator} from "./SpecificTranslator";
import Downshift from 'downshift';
import moment from "moment";

// styles
import createStyle from './CreateOrder.module.scss'
import { NotSpecificTranslator } from './NotSpecificTranslator';

require('bootstrap-timepicker/js/bootstrap-timepicker.min.js');
require('bootstrap-timepicker/css/bootstrap-timepicker.min.css');


interface OrderState {
  form: CreateOrderInterface;
  customer_info: UserModel|null;
  translation_types: Array<TranslationTypeModel>;
  languages_from: Array<any>;
  languages_to: Array<any>;
  languages:Array<any>;
  to_time: string;
  is_special_company?: boolean;
  department_units?: Array<DepartmentUnitModel>
  projects?: Array<any>
  uploadDocument?: File;
  is_prefilled?: boolean; 
  is_department_show?: boolean,
  document_name?: string,
  is_specific_translator?:number,
  client_index_counter: number
  client_suggestions: Array<{
    client_index: number
    suggestions: ClientData[]
  }>
}
export class CreateOrder extends React.Component < RouteComponentProps < any > , OrderState > {
    state: OrderState = {
        form: {} as CreateOrderInterface,
        customer_info: null,
        translation_types: [] as TranslationTypeModel[],
        languages_from: [],
        languages_to: [],
        languages: [],
        to_time: moment().add(1, 'hours').format('hh:mm A'),
        uploadDocument: null,
        is_department_show: false,
        document_name: '',
        client_index_counter: 1,
        client_suggestions: []
    };

    JqueryFunction = () => {
        const ___this = this;
        $('.order_from').timepicker({
            ...TimePickerOptions,
            ...{
                defaultTime: ''
            }
        }).on('changeTime.timepicker', function(e: JQueryEventObject) {
            const target: any = e.target;
            let to_value = moment(e.time.value, 'HH:mm').add(1, 'hours').format('HH:mm');
            let orders: any = [...___this.state.form.order];

            var orderFromValueSplit = e.time.value.split(":");
            if (orderFromValueSplit[0] === '' || orderFromValueSplit[1] === '') {
                showNotification('danger', "Order from time is not valid.");
            }

            orders[target.dataset.id].order_from = e.time.value;
            orders[target.dataset.id].order_to = to_value;
            ___this.setState({
                form: {
                    ...___this.state.form,
                    order: orders
                }
            });

        });

        $('.order_to').timepicker({
                ...TimePickerOptions,
                ...{
                    defaultTime: ''
                }
            })
            .on('changeTime.timepicker', function(e: JQueryEventObject) {
                const target: any = e.target;
                let orders: any = [...___this.state.form.order];
                let diff = moment.duration(moment(e.time.value, "HH:mm").diff(moment(orders[target.dataset.id].order_from, "HH:mm")));

                var orderToValueSplit = e.time.value.split(":");
                if (orderToValueSplit[0] === '' || orderToValueSplit[1] === '') {
                    showNotification('danger', "Order to time is not valid.");
                }


                if (diff.asMinutes() <= 0 && (___this.state.form.translation_type_id !== OrderType.DOCUMENT && ___this.state.form.translation_type_id !== OrderType.PHONE_MESSAGE)) {
                    showNotification('danger', "Order to time can not be less then from time.");
                    ___this.setState({
                        form: {
                            ...___this.state.form,
                            order: orders
                        }
                    });
                } else {
                    orders[target.dataset.id].order_to = e.time.value;
                    ___this.setState({
                        form: {
                            ...___this.state.form,
                            order: orders
                        }
                    });
                }
            });

        $(".order_date").datepicker(DatePickerOptionsForCreateOrUpdate).on('changeDate', function(e) {
            const target: any = e.target;
            let orders: any = [...___this.state.form.order];
            orders[target.dataset.id].order_date = moment(e.date).format('YYYY-MM-DD');
            ___this.setState({
                form: {
                    ...___this.state.form,
                    order: orders
                }
            });
        });

    }

    componentDidMount() {

        getCustomerInfo(this.props.match.params.id).then((data) => {
            let is_department_show = false;
            if (!_.isEmpty(data.company.prefilled_fields)) {
                let department_position = data.company.prefilled_fields.search("department_id");
                if (department_position !== -1) {
                    is_department_show = true;
                }
            }
            let department_unit: Array < any > , projects: Array < any >;
            if (data.company.is_special_company === 1) {
                const department_units = data.company.departments.filter((dept: DepartmentModel) => {
                    return dept.id === data.customer.department_id
                });
                if (data.customer.department_id > 0 && department_units.length > 0) {
                    department_unit = department_units[0].units;
                    projects = department_units[0].projects;
                }
            }

            //set duplicate order data.
            if (!_.isEmpty(this.props.match.params.orderId) && Number(this.props.match.params.orderId) > 0) {
                getOrderInfo(this.props.match.params.orderId).then((dataContent) => {
                    let orderData: any = {
                        ...data,
                        ...dataContent
                    }
                    this.setState({
                        customer_info: data,
                        form: new OrderForm(orderData).getOrderCopyForm(),
                        is_special_company: data.company.is_special_company === 1,
                        is_department_show: is_department_show,
                        department_units: department_unit,
                        projects: projects,
                    }, () => {
                        this.JqueryFunction();
                    });
                });
            } else {
                this.setState({
                    customer_info: data,
                    form: new OrderForm(data).getOrderForm(),
                    is_special_company: data.company.is_special_company === 1,
                    is_department_show: is_department_show,
                    department_units: department_unit,
                    projects: projects,
                }, () => {
                    this.JqueryFunction();
                });
            }




        });

        API.get(`/orders/orders/create`).then((res: AxiosResponse) => {

            this.setState({
                translation_types: res.data.translation_types,
                languages: res.data.languages,
                languages_from: res.data.languages,
                languages_to: res.data.languages,
            });

        });

    }

    handleChange = (event: any) => {
        const target: any = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (event.target.className.includes("contacts")) {
            // control only contact persons inputs
            let contacts: any = [...this.state.form.contact_persons];
            contacts[target.dataset.id][target.dataset.name] = target.value;
            this.setState({
                form: {
                    ...this.state.form,
                    contact_persons: contacts
                }
            });
        } else if (event.target.className.includes("orders")) {
            // control only contact order inputs
            let orders: any = [...this.state.form.order];
            orders[target.dataset.id][target.dataset.name] = target.value;
            this.setState({
                form: {
                    ...this.state.form,
                    order: orders
                }
            });
        } else {

            // control other inputs
            if (target.name == "translation_type_id") {
                value = Number(value);
            }
            let new_state: any = {
                [name]: value
            };
            if (name === 'translation_type_id') {
                if (_.indexOf([OrderType.PHYSICAL, OrderType.VIDEO], value) < 0) {
                    new_state.is_dumb = 0;
                }
                this.setState(prevState => ({
                    form: {
                        ...prevState.form,
                        ...new_state
                    }
                }), () => {
                    this.JqueryFunction();
                });
            } else {

                if (name === "department_id") {
                    new_state.department_unit_id = null;
                    new_state.project_id = null;
                }

                if (name === 'specific_translator_id') {
                    new_state.specific_translator = event.params.data;
                    new_state.translator_id = value;
                    new_state.translator_gender = event.params.data.gender;
                    new_state.is_assign_other = 1;
                    new_state.is_talk_trans = 0;
                }

                if (name === 'not_specific_translator_id') {
                  new_state.not_specific_translator = event.params.data;
                }


                 if (name === 'isAddressSame') {
                    let loginUserData: any = this.state.customer_info;
                    if (Number(value) === 1) {
                        new_state.meeting_lat = loginUserData.lat;
                        new_state.meeting_long = loginUserData.lng;
                        new_state.meeting_address = loginUserData.address;
                        new_state.meeting_zip_code = loginUserData.zip_code;
                        new_state.meeting_city = loginUserData.city;
                        new_state.addition_info = loginUserData.additional_address_information;
                    } else {
                        new_state.meeting_lat = '';
                        new_state.meeting_long = '';
                        new_state.meeting_address = '';
                        new_state.meeting_zip_code = '';
                        new_state.meeting_city = '';
                        new_state.addition_info = '';
                    }
                }
                if (name === 'client_name' || name === 'client_cpr') {
                  const clients = _.cloneDeep(this.state.form.clients);
                  const index_of_changed = clients.findIndex(client => client.index === Number(event.target.dataset.clientid))
                  clients[index_of_changed][name] = value;
                  new_state = { clients };
                }

                this.setState(prevState => ({ form: { ...prevState.form, ...new_state }}));
            }
        }

        if (name === "from_language") {
            let found = this.state.languages.filter((la) => {
                return la.id !== value
            });

            this.setState(prevState => ({
                ...prevState,
                languages_to: found
            }));
            //this.setState({languages_to:found});
        }

        if (name === "to_language") {
            var found = this.state.languages.filter((la) => {
                return la.id != value
            });
            //this.setState({languages_from:found})
            this.setState(prevState => ({
                ...prevState,
                languages_from: found
            }));
        }

        if (name === "department_id") {
            //this.setState({ department_units: [], projects: []});
            this.setState(prevState => ({
                ...prevState,
                department_units: [],
                projects: []
            }));
            const department_units = this.state.customer_info.company.departments.filter((dept: DepartmentModel) => {
                return dept.id === Number(value)
            });
            if (value > 0 && department_units.length > 0) {
                //this.setState({ department_units: department_units[0].units, projects: department_units[0].projects});
                this.setState(prevState => ({
                    ...prevState,
                    department_units: department_units[0].units,
                    projects: department_units[0].projects
                }));
            }
        }

    }

    handleInputForm = (e: any) => {
        let department_unit_id = e.target.value;
        const name = e.target.name;
        API.get(`/orders/department-unit-ean-number/${department_unit_id}`).then((res: AxiosResponse) => {
            this.setState({
                form: {
                    ...this.state.form,
                    ean_number: res.data.ean_number
                }
            });
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: department_unit_id
                }
            });
        });
    }

    handleSubmit = (event: React.FormEvent < HTMLFormElement > ) => {
        event.preventDefault();
        SwitchLoader(LoaderType.BUTTON);
        if (OrderValidations(this.state.form, this.state.is_special_company)) {
            API.post('orders/orders', this.state.form).then((res) => {
                showNotification("success", res.data.message);
                this.props.history.push(`/orders/orders/`);
            })
            .catch(err => err.data.message && alert(err.data.message));
        }
    }

    removeOrders = (index: number) => {
        let orders: Array < OrderDateTimeInterface > = [...this.state.form.order];
        _.pullAt(orders, index);
        this.setState({
            form: {
                ...this.state.form,
                order: orders
            }
        });
    }

    googleGetAddressForMettingAddress = (place) => {
        const {
            address,
            city,
            lat,
            lng,
            zip_code
        } = getMapContent(place);
        this.setState({
            form: {
                ...this.state.form,
                meeting_lat: lat,
                meeting_long: lng,
                meeting_address: address,
                meeting_zip_code: zip_code,
                meeting_city: city
            }
        });
    }

    handleUpload = (file, index) => {
        let docs: any = [...this.state.form.order];
        let old_docs = [];
        if (!_.isUndefined(docs[index]['document'])) {
            old_docs = docs[index]['document'];
        }
        docs[index]['document'] = [...old_docs, ...file];
        this.setState({
            form: {
                ...this.state.form,
                order: docs
            }
        });
    }

    RemoveDocument = (index: number, name: string) => {
        const old_files = [...this.state.form.order];
        const files = old_files[index].document.filter(item => {
            return item.file_name !== name;
        })
        old_files[index].document = files;
        this.setState({
            form: {
                ...this.state.form,
                order: old_files
            }
        });
    }

    removeTranslator = (event: any, target: 'specific_translator' | 'not_specific_translator') => {
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                [`${target}_id`]: 0,
                [target]: {}
            }
        }));
    }

    formDataReset = (e: any) => {
        e.preventDefault();
        this.setState({
            form: {} as CreateOrderInterface
        });
    }
    
    handleClientRemove = (index: number): void => {
        this.setState({
            form: {
                ...this.state.form,
                clients: this.state.form.clients.filter((client: ClientData, indexOfClient: number) => indexOfClient !== index)
            }
        })
    }
    
    handleClientAdd = (): void => {
        let newClient: ClientData = {
            index: this.state.client_index_counter,
            client_name: '',
            client_cpr: '',
            client_lat: '',
            client_long: ''
        }
        this.setState({
            form: {
                ...this.state.form,
                clients: [...this.state.form.clients, newClient]
            },
            client_index_counter: this.state.client_index_counter + 1,
        })
    }

    createSuggestions = async (query: string, client_index: number) => {
      const clients: ClientData[] = await (await API.get(`/orders/orders/get-exisiting-clients/${this.state.customer_info.id}/${query}`)).data;
      const new_suggestions: ClientSuggestion = {
        client_index,
        suggestions: clients.map(client => ({ ...client, client_id: client.id })),
      }
      const old_suggestions_array = _.cloneDeep(this.state.client_suggestions);
      const suggestions_exist_index = old_suggestions_array.findIndex(suggestion => suggestion.client_index === client_index);
      if ( suggestions_exist_index !== -1 ) {
        old_suggestions_array[suggestions_exist_index] = new_suggestions;
      } else {
        old_suggestions_array.push(new_suggestions);
      }
      this.setState({ client_suggestions: old_suggestions_array });
    }

    debouncedCreateSuggestions = _.debounce(this.createSuggestions, 300);

    handleClientSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const client_index = Number(e.target.dataset.clientid);
      const index_of_old = this.state.client_suggestions.findIndex(suggestion => suggestion.client_index === client_index);
      if (!value && index_of_old !== -1 ) {
        const suggestions = _.cloneDeep(this.state.client_suggestions);
        suggestions.splice(index_of_old, 1);
      } else {
        this.debouncedCreateSuggestions(value, client_index);
      }
      this.handleChange(e);
    }

    setClient = (client_to_set: ClientData) => {
      const new_clients_state = _.cloneDeep(this.state.form.clients);
      const index_of_changed = new_clients_state.findIndex(client => client.index === client_to_set.index);
      new_clients_state[index_of_changed] = client_to_set;
      this.setState({
        form: {
          ...this.state.form,
          clients: new_clients_state,
        }
      })
    }

    handleCprChange = (e: React.ChangeEvent<HTMLInputElement>, client: ClientData) => {
      let new_client: Omit<ClientData, 'id' | 'client_id' | 'order_id'> | ClientData;
      const value = e.target.value;
      const index_of_suggestion = this.state.client_suggestions.findIndex(suggestion => suggestion.suggestions.findIndex(_client => _client.client_cpr === value) !== -1);
      if ( index_of_suggestion !== -1 ) {
        new_client = this.state.client_suggestions[index_of_suggestion].suggestions.find(_client => _client.client_cpr === value);
        new_client.index = client.index;
      } else if ( !value || index_of_suggestion === -1 ) {
        new_client =  _.omit(client, ['id', 'client_id', 'order_id']);
        new_client.client_cpr = value;
      }
      this.setClient(new_client);
    }

  render(){
    const client_ids = this.state.form.clients && this.state.form.clients.filter(client => !!client.client_id).map(client => client.client_id);
    console.log(this.state.form);
    return(
      <>
        {this.state.customer_info !== null && <CustomerWidget UserInfo={this.state.customer_info}/> }
        <CreateLayout breadcrumb={CreateOrderBreadcrumb} title={'create_order'}>
          <form className="kt-form" id={'order_form'} onSubmit={this.handleSubmit}>
            <div className="kt-portlet__body">
              <div className={'kt-section kt-section--first'}>
                <h3 className="kt-section__title">{Lang.order_information}</h3>
                <div className="kt-section__body">
                  <div className={'row'}>
                    <div className={'col-md-12'}>
                      <div className={'row'}>
                        <FormElementGroup>
                          <label>{Lang.translation_type} <span className={'required'}>*</span></label>
                          <Select className={'form-control'} value={this.state.form.translation_type_id || ''} id={'translation_type_id'} name={'translation_type_id'} onChange={this.handleChange}>
                            <option value={''}>{Lang.translation_type_placeholder}</option>
                            {this.state.translation_types.map((item: TranslationTypeModel, index: number) => {
                              return <option key={index} value={item.id}>{item.title}</option>
                            })}
                          </Select>
                        </FormElementGroup>

                        {(this.state.form.translation_type_id === OrderType.PHYSICAL) &&
                          <>
                          <React.Fragment>
                            {/*<FormElementGroup>
                                <label className="kt-checkbox">
                                  <input type="checkbox" className="custom-control-input" name={'isAddressSame'} id="isAddressSame" checked={Number(this.state.form.isAddressSame) === 1} onChange={this.handleChange} value={Number(this.state.form.isAddressSame) === 1 ? '0' : '1'} />
                                  {Lang.order_for_dumb}
                                  <span/>
                                </label>
                            </FormElementGroup>*/}
                            <FormElementGroup>
                              <label>{Lang.address} <span className={'required'}>* </span></label>
                              <GooglePlace onChooseAddress={this.googleGetAddressForMettingAddress} name={'meeting_address'}  defaultValue={this.state.form.meeting_address || ''} onChange={this.handleChange} value={this.state.form.meeting_address || ''} disabled={Number(this.state.form.isAddressSame) === 1} className="form-control" placeholder={Lang.getString(`address_placeholder`)} />
                              <p>
                                <>
                                  <label className="kt-checkbox">
                                    <input type="checkbox" className="custom-control-input" name={'isAddressSame'} id="isAddressSame" checked={Number(this.state.form.isAddressSame) === 1} onChange={this.handleChange} value={Number(this.state.form.isAddressSame) === 1 ? '0' : '1'} />
                                     <span/>
                                    {Lang.is_address_same}
                                  </label>
                                ({this.state.customer_info.address}, {this.state.customer_info.zip_code}, {this.state.customer_info.city})
                                </>
                              </p>
                            </FormElementGroup>
                            <FormElementGroup>
                              <label>{Lang.additional_address_info}</label>
                              <input type={'text'} onChange={this.handleChange} name={'addition_info'}  value={this.state.form.addition_info || ''} className="form-control" placeholder={'Type any additional address info eg: building no./block no'} />
                            </FormElementGroup>
                          </React.Fragment>
                          </>
                        }


                        { this.state.form.translation_type_id === OrderType.PHONE_MESSAGE &&
                          <React.Fragment>
                            <FormElementGroup>
                              <label>Mobile no of recipient<span className={'required'}>*</span></label>
                              <input type={'text'} onChange={this.handleChange} name={'tel_mobile'} value={this.state.form.tel_mobile || ''} className="form-control"/>
                            </FormElementGroup>
                            <FormElementGroup>
                              <label>{Lang.tel_message} <span className={'required'}>*</span></label>
                              <textarea rows={1} onChange={this.handleChange} name={'tel_message'} value={this.state.form.tel_message || ''} className="form-control"/>
                            </FormElementGroup>
                          </React.Fragment>
                        }

                      </div>
                    </div>


                    <div className={'col-md-12'}>
                      <div className={'row'}>

                        {(this.state.form.translation_type_id === OrderType.PHYSICAL || this.state.form.translation_type_id === OrderType.VIDEO) &&
                        <div className={'col-md-4'}>
                          <label className="kt-checkbox">
                            <input type="checkbox" className="custom-control-input" name={'is_dumb'} id="is_dumb" checked={Number(this.state.form.is_dumb) === 1} onChange={this.handleChange} value={Number(this.state.form.is_dumb) === 1 ? '0' : '1'} />
                            {Lang.order_for_dumb}
                            <span/>
                          </label>
                        </div>
                        }

                        <FormElementGroup>
                              <label>{Lang.from_language} <span className={'required'}>*</span> </label>
                              <Select2Wrapper className={'form-control'} value={this.state.form.from_language}
                                              name={'from_language'}  onChange={this.handleChange}
                                              data={{
                                                data: this.state.languages_from,
                                                templateResult: LanguageFlags,
                                                templateSelection: LanguageFlags,
                                                placeholder: Lang.from_language
                                              }}
                              />
                        </FormElementGroup>
                            {
                              (Number(this.state.form.is_dumb) === 0 || this.state.form.is_dumb === undefined )&&
                              <FormElementGroup>
                                  <label>{Lang.to_language} <span className={'required'}>*</span></label>
                                  <Select2Wrapper className={'form-control'} value={this.state.form.to_language || ''}
                                                  name={'to_language'}  onChange={this.handleChange}
                                                  data={{
                                                    data: this.state.languages_to,
                                                    templateResult: LanguageFlags,
                                                    templateSelection: LanguageFlags,
                                                    placeholder: Lang.to_language
                                                  }}
                                  />
                              </FormElementGroup>
                            }
                      </div>
                    </div>

                    {
                      this.state.form.order && this.state.form.order.map((item: OrderDateTimeInterface, index: number) => {
                        return (
                          <div className={'col-md-12'} key={index}>
                            <div className={'row'}>
                              <FormElementGroup>
                                <label>{Lang.date} <span className={'required'}>*</span></label>
                                <div className="input-group date">
                                  <input type={'text'} onChange={this.handleChange} data-id={index} data-name={'order_date'}  name={'order_date'}  value={this.state.form.order[index].order_date || ''} className="form-control orders order_date"  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="la la-calendar-check-o"/>
                                    </span>
                                  </div>
                                </div>
                              </FormElementGroup>

                              <FormElementGroup className={'col-md-8 col-lg-8'}>
                                <div className={'row'}>
                                  {this.state.form.translation_type_id !== OrderType.DOCUMENT && this.state.form.translation_type_id !== OrderType.PHONE_MESSAGE  &&
                                  <div className={'col-md-5'}>
                                    <label>{Lang.order_from} <span className={'required'}>*</span></label>
                                    <div className="input-group timepicker">
                                      <input className="form-control orders order_from"  data-id={index} readOnly={true}  onChange={this.handleChange}  data-name={'order_from'} name={'order_from'} value={this.state.form.order[index].order_from || ''} type="text" />
                                      <div className="input-group-append"><span className="input-group-text"><i className="la la-clock-o"/> </span></div>
                                    </div>
                                  </div>
                                  }

                                  <div className={'col-md-5'}>
                                    <label>{Lang.order_to} <span className={'required'}>*</span></label>
                                    <div className="input-group timepicker">
                                      <input className="form-control order_to orders" data-id={index}  readOnly={true}  onChange={this.handleChange}  data-name={'order_to'} name={'order_to'}  value={this.state.form.order[index].order_to || ''} type="text" />
                                      <div className="input-group-append"><span className="input-group-text"><i className="la la-clock-o"/></span></div>
                                    </div>
                                  </div>

                                  <div className={'col-md-2'}>
                                    <label/>
                                    {index === 0 &&
                                      <button type={'button'} style={{marginTop: 5}} className={'btn btn-success'}
                                              onClick={() => {
                                                let orders: Array<OrderDateTimeInterface> = [...this.state.form.order];
                                                orders.push({
                                                  order_date: '',
                                                  order_from: '',
                                                  order_to: '',
                                                  document: []
                                                });
                                                this.setState({form: { ...this.state.form, order: orders}}, () => { this.JqueryFunction();});
                                              }}
                                      ><i className={Icons.add}/></button>
                                    }
                                    {index > 0 &&
                                      <button type={'button'} style={{marginTop: 23}} className={'btn btn-danger'}
                                              onClick={() => this.removeOrders(index)}
                                      ><i className={Icons.delete}/></button>
                                    }
                                  </div>
                                </div>
                              </FormElementGroup>
                            </div>
                            {this.state.form.translation_type_id === 6 &&
                            <div className={'row'}>
                                <FormElementGroup className={"col-md-11 col-lg-11"}>

                                  <Document index={index} orders={item} addDoc={this.handleUpload} remove={this.RemoveDocument}/>
                                </FormElementGroup>
                            </div>
                            }
                          </div>
                        )
                      })
                    }

                    { this.state.form.contact_persons &&
                      this.state.form.contact_persons.map((form: OrderContactPerson, index: number) => {
                        return (
                          <div key={index} className={'col-md-12'}>
                            <div className={'row'}>
                            <FormElementGroup>
                              <label>{Lang.contact_person} <span className={'required'}>*</span></label>
                              <input type={'text'} onChange={this.handleChange} name={`con_name_${index}`} data-id={index} data-name={'name'}  value={this.state.form.contact_persons[index].name || ''} className="form-control contacts"/>
                            </FormElementGroup>

                            <FormElementGroup>
                              <label>{Lang.contact_phone} <span className={'required'}>*</span></label>
                              <input type={'text'} onChange={this.handleChange} name={`con_phone_${index}`} data-id={index} data-name={`phone_no`} value={this.state.form.contact_persons[index].phone_no || ''} className="form-control contacts"/>
                            </FormElementGroup>

                            <FormElementGroup>
                              <label>{Lang.contact_email} {(index === 0)?<span className={'required'}>*</span>:''}</label>
                              <input type={'text'} onChange={this.handleChange} name={`con_email_${index}`} data-id={index} data-name={`email`} value={this.state.form.contact_persons[index].email || ''} className="form-control contacts"/>
                            </FormElementGroup>
                            {index === 0 &&
                              <div className={'col-md-12'}>
                                  <label className="kt-checkbox">
                                    <input type="checkbox" onChange={(e) => {
                                      const contact_persons = [...this.state.form.contact_persons];
                                      if(e.target.checked){
                                        contact_persons.push( {name: '', email: '', phone_no: ''});
                                      } else {
                                        contact_persons.splice(-1, 1);
                                      }
                                      this.setState(prevState => (
                                        { form: {...this.state.form, is_second_person : !prevState.form.is_second_person, contact_persons: contact_persons} }
                                      ));
                                    }}/> {Lang.second_contact_person}
                                    <span/>
                                  </label>
                              </div>
                            }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                {
                  this.state.form.translation_type_id === OrderType.VIDEO && (
                    <>
                      <label className="kt-checkbox" htmlFor="schedule_team_meeting">
                        <input type="checkbox" id="schedule_team_meeting" name="schedule_team_meeting" checked={this.state.form.schedule_team_meeting} onChange={this.handleChange}/>
                        Ønsker du, at ViTolker tildeler dig et link til videomødet i Microsoft Teams?
                        <span/>
                      </label>
                    </>
                  )
                }
                {
                  (
                    this.state.form.translation_type_id !== OrderType.DOCUMENT &&
                    Number(this.state.form.translation_type_id) > 0 &&
                    !_.isEmpty(this.state.form.order) && this.state.form.order[0].order_date != '' &&
                    Number(this.state.form.from_language) > 0 &&
                    (Number(this.state.form.to_language)>0 || Number(this.state.form.is_dumb) === 1)
                  ) ? (
                    // [1] Preferred OR Unwanted Interpreter Contianer
                    <>
                      <h3 className="kt-section__title">{Lang.fill_the_details}</h3>
                      <section className={createStyle.details_section}>
                        <div className={createStyle.trans_select_holder}>
                          <div>
                            <label className="control-label d-block">{Lang.want_specific_translator_first + Lang.want_specific_translator_second}</label>
                            <div className={createStyle.radio_outer_contianer}>
                              <div className={createStyle.radio_inner_container}>
                                <span>
                                      <label className={createStyle.label_input_container}>
                                        <input type={'radio'} onChange={this.handleChange} id={'interpreterYes'} name={'is_specific_translator'} value={1} checked={this.state.form.is_specific_translator == 1}/>
                                        <span>{Lang.yes}</span>
                                      </label> &nbsp;
                                </span>
                                <span>
                                    <label className={createStyle.label_input_container}>
                                      <input type={'radio'} onChange={this.handleChange} id={'interpreterNo'} name={'is_specific_translator'} value={0} checked={this.state.form.is_specific_translator == 0}/>
                                      <span>{Lang.no}</span>
                                    </label> &nbsp;
                                </span>
                              </div>
                              {
                                this.state.form.is_specific_translator == 1 && (
                                  <div className={createStyle.trans_selector}>
                                    <SpecificTranslator removeTranslator={(e)=>this.removeTranslator(e, 'specific_translator')} onChange={this.handleChange} formData={this.state.form} />
                                  </div>
                                )
                              }
                            </div>
                          </div>
                          <div>
                            <label className="control-label d-block">{Lang.not_want_specific_translator}</label>
                            <div className={createStyle.radio_outer_contianer}>
                              <div className={createStyle.radio_inner_container}>
                                <span>
                                      <label className={createStyle.label_input_container}>
                                        <input type={'radio'} onChange={this.handleChange} id={'interpreterYes'} name={'is_not_specific_translator'} value={1} checked={this.state.form.is_not_specific_translator == 1}/>
                                        <span>{Lang.yes}</span>
                                      </label> &nbsp;
                                </span>
                                <span>
                                    <label className={createStyle.label_input_container}>
                                      <input type={'radio'} onChange={this.handleChange} id={'interpreterNo'} name={'is_not_specific_translator'} value={0} checked={this.state.form.is_not_specific_translator == 0}/>
                                      <span>{Lang.no}</span>
                                    </label> &nbsp;
                                </span>
                              </div>
                              {
                                this.state.form.is_not_specific_translator == 1 && (
                                  <div className={createStyle.trans_selector}>
                                    <NotSpecificTranslator removeTranslator={(e)=>this.removeTranslator(e, 'not_specific_translator')} onChange={this.handleChange} formData={this.state.form} />
                                  </div>
                                  )
                                }
                            </div>
                          </div>
                        </div>

                        <div className={createStyle.other_details_holder}>
                          {
                            // [2] Translator gender
                            Number(this.state.form.is_specific_translator) === 0 && (
                              <React.Fragment>
                                <FormElementGroup>
                                  <label className="control-label d-block">{Lang.trans_gender}
                                  </label>
                                  <span>
                                      <label>
                                        <input type={'radio'} onChange={this.handleChange} name={'translator_gender'} value={'0'}  checked={this.state.form.translator_gender === '0'}/> {Lang.no_sig}
                                      </label> &nbsp;
                                    </span>
                                  <span>
                                  <label>
                                      <input type={'radio'} onChange={this.handleChange}  name={'translator_gender'} value={'1'} checked={this.state.form.translator_gender === '1'}/>{Lang.man}
                                  </label>
                                  </span>
                                  <span>
                                  <label>
                                      <input type={'radio'} onChange={this.handleChange}  name={'translator_gender'} value={'2'}  checked={this.state.form.translator_gender === '2'}/> {Lang.woman}
                                  </label>
                                  </span>
                                </FormElementGroup>
                              </React.Fragment>
                            )
                          }
                          {
                            // [3] Assign other if busy radio
                            Number(this.state.form.is_talk_trans) === 0 && !_.isEmpty(this.state.form.specific_translator_id) && (
                              <div className={createStyle.trans_busy_container}>
                                <FormElementGroup>
                                  <label className="control-label d-block">{Lang.if_trans_busy}
                                  </label>
                                  <span>
                                      <label>
                                        <input type={'radio'} onChange={this.handleChange} name={'is_assign_other'} value={1} checked={Number(this.state.form.is_assign_other) === 1}/> {Lang.avail_for_another}
                                      </label>
                                    </span>
                                  <span>
                                  <label> &nbsp;
                                      <input type={'radio'} onChange={this.handleChange}  name={'is_assign_other'} value={2} checked={Number(this.state.form.is_assign_other) === 2}/>
                                      &nbsp; {Lang.contact_me_if_not_avail}
                                  </label>
                                  </span>
                                </FormElementGroup>
                              </div>
                            )
                          }
                          {
                            // [4] Assign a specific translator
                            Number(this.state.form.is_specific_translator) === 1 && !_.isEmpty(this.state.form.specific_translator_id) && (
                              <React.Fragment>
                                <FormElementGroup>
                                  <label className="control-label d-block">{Lang.already_talk}
                                  </label>
                                  <span>
                                    <label>
                                      <input type={'radio'} onChange={this.handleChange} name={'is_talk_trans'} value={1} checked={Number(this.state.form.is_talk_trans) === 1}/> &nbsp; {Lang.yes} &nbsp;
                                  </label>
                                </span>
                                <span>
                              <label>
                                  <input type={'radio'} onChange={this.handleChange}  name={'is_talk_trans'} value={0} checked={Number(this.state.form.is_talk_trans) === 0}/>
                                &nbsp; {Lang.no} </label>
                              </span>
                              </FormElementGroup>
                            </React.Fragment>
                          )
                        }
                      </div>
                    </section>
                    </>
                  ) : null
                }

                <h3 className="kt-section__title">{Lang.billing_information}</h3>
                <div className={'kt-section__body'}>
                  <div className={'row'}>

                      <FormElementGroup>
                        <label>{Lang.company} <span className={'required'}>*</span></label>
                        <input type={'text'} onChange={this.handleChange} name={'company_id'} readOnly={this.state.is_special_company} value={this.state.form.company_id || ''} className="form-control"/>
                      </FormElementGroup>

                      {this.state.is_special_company && this.state.is_department_show == true &&
                          <FormElementGroup>
                              <label>{Lang.department} <span className={'required'}>*</span></label>
                              <Select onChange={this.handleChange} value={this.state.form.department_id || ''} name={'department_id'} className="form-control" id={'department_id'}>
                                <option value={''}>{Lang.select_dept}</option>
                                {this.state.customer_info.company.departments.map((item: DepartmentModel, index) => {
                                  return <option key={index} value={item.id}>{item.name}</option>
                                })}
                              </Select>
                          </FormElementGroup>
                        }

                    {
                        !this.state.is_special_company &&
                            <FormElementGroup>
                                <label>{Lang.department} <span className={'required'}>*</span></label>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.department_id || ''} name={'department_id'} className="form-control" placeholder={Lang.getString(`department_id_placeholder`)}/>
                            </FormElementGroup>
                    }

                    {this.state.is_special_company === true && this.state.is_department_show == true &&
                      <FormElementGroup>
                          <label>{Lang.department_unit} <span className={'required'}>*</span></label>
                          <Select onChange={this.handleInputForm} value={this.state.form.department_unit_id || ''} name={'department_unit_id'} className="form-control" id={'department_unit_id'}>
                            <option value={''}>{Lang.select_dept_unit}</option>
                            {this.state.department_units && this.state.department_units.map((item: DepartmentUnitModel, index) => {
                              return <option key={index} value={item.id}>{item.name}</option>
                            })}
                          </Select>
                      </FormElementGroup>
                    }

                    {this.state.customer_info !== null &&
                      _.lowerCase(this.state.customer_info.company.email).search('redcross.dk') >= 0 &&
                      <FormElementGroup>
                          <label htmlFor="phoneContact">Select Project <span className="required">*</span></label>
                          <Select2Wrapper
                            data={{
                              placeholder: 'Select Project',
                              data: this.state.projects
                            }}
                            name={'project_id'}  value={this.state.form.project_id} onChange={this.handleChange} className="form-control">

                          </Select2Wrapper>
                      </FormElementGroup>
                    }
                    
                    { this.state.is_special_company &&
                      <FormElementGroup>
                        <label>{Lang.ean_number}</label>
                        <input type={'text'} onChange={this.handleChange} name={'ean_number'}  value={this.state.form.ean_number || ''} className="form-control" />
                      </FormElementGroup>
                    }
                  {
                    !_.isEmpty(this.state.form.clients) && this.state.form.clients.map((client: ClientData, index: number): JSX.Element => {
                      return (
                          <React.Fragment key={client.index}>
                            <Downshift
                              key={client.index}
                              onChange={(client, meta) => {
                                this.setClient(client);
                              }}
                            >
                              {
                                ({
                                  getInputProps,
                                  getLabelProps,
                                  getMenuProps,
                                  getItemProps,
                                  isOpen,
                                  highlightedIndex,
                                  openMenu
                                }) => (
                                  <div className={"col-md-6 col-lg-6"}>
                                    <div className={"form-group"}>
                                          <label {...getLabelProps({ htmlFor: `client_name${client.index}` })} >
                                            {Lang.client_name}
                                          </label>
                                          <input
                                            {...getInputProps({
                                              type: 'search',
                                              autoComplete: 'off',
                                              onChange: this.handleClientSearch,
                                              name: `client_name`,
                                              id: `client_name${client.index}`,
                                              value: client.client_name,
                                              className: 'form-control',
                                              onFocus: (e) => { openMenu() },
                                            })}
                                            data-clientid={client.index}
                                          />
                                          <ul {...getMenuProps({ className: 'client_suggestions', style: {display: isOpen ? 'block' : 'none'} })} >
                                            {
                                              isOpen
                                              &&
                                              this.state.client_suggestions
                                                .findIndex(suggestion => suggestion.client_index === client.index) !== -1
                                              &&
                                              this.state.client_suggestions
                                                .find(suggestion => suggestion.client_index === client.index).suggestions
                                                .filter(client => !client_ids
                                                .includes(client.client_id))
                                                .slice(0, 5)
                                                .map((_client, index) => (
                                                  <li
                                                    {...getItemProps({
                                                      key: _client.client_id,
                                                      index,
                                                      item: { ..._client, index: client.index },
                                                      className: 'client_suggestion',
                                                      style: {
                                                        backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                                                      }
                                                    })}
                                                  >
                                                    <span>{_client.client_name}</span><span>{_client.client_cpr}</span>
                                                  </li>
                                                ))
                                            }
                                          </ul>
                                    </div>
                                  </div>
                                )
                              }

                            </Downshift>
                            <div className={"col-md-5 col-lg-5"}>
                                <div className={"form-group"}>
                                    <label htmlFor={`client_cpr${client.index}`} >{Lang.client_cpr}</label>
                                    <input type={'text'} onChange={(e) => { this.handleCprChange(e, client) }} data-clientid={client.index} name={`client_cpr`} id={`client_cpr${client.index}`} value={client.client_cpr || ''} className="form-control" />
                                </div>
                            </div>
                            {
                                index === 0?
                                    (
                                        <div
                                            className={"col-md-1 col-lg-1"}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <button
                                                type={"button"}
                                                className={"btn btn-success"}
                                                onClick={this.handleClientAdd}>
                                                <i className={Icons.plusUser}/>
                                            </button>
                                        </div>
                                    )
                                    :
                                    (
                                        <div
                                            className={"col-md-1 col-lg-1"}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <button
                                                type={"button"}
                                                className={"btn btn-danger"}
                                                onClick={() => {
                                                    this.handleClientRemove(client.index)
                                                }}
                                            >
                                                <i className={Icons.minusUser}/>
                                            </button>
                                        </div>
                                    )
                            }
                          </React.Fragment>
                      )
                    })
                  }
                    <FormElementGroup>
                      <label>{Lang.comment_for_translator} (fx samtalens emne) </label>
                      <textarea rows={1}  onChange={this.handleChange} name={'comment_for_translator'}  value={this.state.form.comment_for_translator || ''} className="form-control" />
                    </FormElementGroup>

                    <FormElementGroup>
                      <label>{Lang.office_note} (fx ønske om en bestemt tolk eller et andet sprog) </label>
                      <textarea rows={1}  onChange={this.handleChange} name={'office_note'}  value={this.state.form.office_note || ''} className="form-control" />
                    </FormElementGroup>

                  </div>
                </div>
              </div>
                <div className='reset-btn-section'>
                    <CommonSubmitButton/>
                    <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn'} data-skin={'dark'} title={Lang.reset} onClick={(e)=>this.formDataReset(e)}>{Lang.reset}</button>
                </div>


            </div>
          </form>
        </CreateLayout>
      </>
    )
  }
}