import React from 'react'

// styles
import sheet from './NextJob.module.scss'

const NoNextJob = () => {
  return (
    <div className={sheet.empty_container}>Næste job ikke planlagt.</div>
  )
}

export default NoNextJob