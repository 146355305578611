import * as React from 'react';
import {DataSchema} from "./Model";
import {SearchInput, SearchSelect} from "../../common/form/SearchInput";
import {IState as CommonState} from "../../common/State";
import {ShowRecords} from "../../common/ShowRecords";
import {default as Lang} from "../../common/lang/Lang"
import {CONST, ExportButton, getQueryStringParams} from "../../common/Common";
import {Icons} from "../../common/Icon";
import {ListBreadcrumb} from "./Data";
import {actionButton} from "../../common/table/BlukActionButtons";
import {RouteComponentProps} from "react-router";
import { CreateLink, EditLink} from "../../common/page/CreateButton";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {DepartmentModel,CompanyModel} from "../../model";
import DepartmentFilter from './filter/DepartmentFilter';
import _ from 'lodash';
import {list_key} from "../../helpers/OrderHelper";


interface IState extends CommonState{
  UserData: Array<DataSchema>;
  form: {
    like: {
      name: string,
      email: string
    },
    equal: {
      status: number
    }
  }
}


export class DepartmentListing extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

 

  rowActionButtons = (data: DepartmentModel) => {
    return (
      <EditLink scope={'department.edit'} to={`${this.props.match.url}/edit/${data.id}`} />
    )
  }

  showRecords = (data:DepartmentModel, index:number) => {
     let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("id", index),
      this.records.current.createdTd("name",   data.name),
      this.records.current.createdTd("company_name", data.company !== null && data.company.name),
      this.records.current.createdTd("notify_email",   (data.notify_email !== null) ? data.notify_email :'--'),
       this.records.current.createdTd("status", () => {
        if(data.status === 1){
          return <span className={'badge badge-primary'}>{Lang.active}</span>
        }
        if(data.status === 2){
          return <span className={'badge badge-warning'}>{Lang.inactive}</span>
        }
      }),
      this.records.current.createdTd("created_at", data.created_at),
      this.records.current.createdTd("action", () => {
        return this.rowActionButtons(data);
      })
    ]);
    cols = _.compact(cols);
    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  render() {
    
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'department'} icon={Icons.users} >
            <ExportButton records={this.records} fileName={Lang.exportDepartments}/>
            &nbsp;
            <CreateLink to={`${this.props.match.url}/create`} scope={'department.create'} />
          </ListHeader>

          <ListForm
            form={(
              <DepartmentFilter   getResults={this.filterRecords} />
              
            )}
            table={(
              <ShowRecords
                url={"settings/department"}
                showRecords ={this.showRecords}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "settings/department/bulk-action", module: 'company'})}
              />
            )}
          />
        </ListingLayout>
    )
  }
}