import React, { PropsWithChildren } from 'react'

// styles
import sheet from './Table.module.scss'

const Table: React.FC<PropsWithChildren<{}>> = ({children}) => {
  return (
    <div className={sheet.table}>{ children }</div>
  )
}

export default Table