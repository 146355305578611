import React, {useState, useEffect} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

// components
import { Breadcrumb } from '../../../common';
import { CallBreadcrumb } from '../Data';
import TranslatorInfo from './TranslatorInfo';
import API from '../../../common/AxoisClient';

// styles
import callStyle from './CallStyle.module.scss';
import CallSection from './CallSection';
import { Countries_Data, Translator_Data } from './TypeDefs';
import { translator_data_initial_values } from './CallCommon';
import { isEmpty } from 'lodash';
import { Spinner } from 'react-bootstrap';

// interfaces
interface ParamsType {
    translatorId: string
}

const CallPage: React.FC<RouteComponentProps> = (props) => {
    const [Loading, setLoading] = useState<boolean>(true);
    const [TranslatorData, setTranslatorData] = useState<Translator_Data>(translator_data_initial_values);
    const country_initial_values: Countries_Data = {
        countries: [{
            id: 58,
            iso: 'DK',
            name: 'DENMARK'
        }],
        country_codes: [{
            id: 45,
            iso: 'dk',
            text: "45 - DENMARK",
        }]
    };
    const [Countries, setCountries] = useState<Countries_Data>(country_initial_values);
    // extracting user id of translator
    const { match } = props;
    const { translatorId } = match.params as ParamsType;

    // getting translator data and country lists
    useEffect(() => {
        API.get('users/translators/countries').then(res => {
            setCountries({
                countries: res.data.countryArr,
                country_codes: res.data.countryCodeArr,
            })
        });
        API.get(`users/translator/${translatorId}`).then(res => {
            setLoading(false);
            setTranslatorData(res.data);
        })
    }, [translatorId]);
    if (isEmpty(TranslatorData) && !Loading) {
        return (
            <>
                <Breadcrumb data={CallBreadcrumb} />
                <div className={callStyle.missing_page_container}>
                    <h1>Oversætter ikke fundet</h1>
                    <h2>
                        <Link to="/translators/all">Gå til oversætterliste</Link>
                    </h2>
                </div>
            </>
        )
    } else {
        if (Loading) {
            return (
                <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10rem 0'
                }}>
                    <Spinner
                        animation="border"
                        style={{
                            width: '200px',
                            height: '200px',
                        }}
                        role="status"
                        >
                        <span style={{opacity: '0'}}>Loading...</span>
                    </Spinner>
                </div>
            )
        } else {
        return (
            <div className={callStyle.container}>
                <Breadcrumb data={CallBreadcrumb} />
                <div className={callStyle.main_content_container}>
                    <TranslatorInfo
                        translatorInfo={ TranslatorData }
                        countries={Countries.countries}
                    />
                    <CallSection
                        translator_data={TranslatorData}
                        country_codes={Countries.country_codes}
                    />
                </div>
            </div>
        );}
    }
}

export default CallPage;
