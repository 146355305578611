import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { CountryFlags, Select2Wrapper } from '../../../common';

const LanguageSelector = (props) => {
    const validateField = value => {
        let error;
        if (!value) {
            error = 'Dette felt skal udfyldes.';
        }
        return error;
    }
    const {name, value, options, ...fieldProps} = props;
    return (
        <>
            <Field
                name={name}
                id={name}
                validate={validateField}
            >
                {
                    ({ field, form, meta }) => {
                        return <Select2Wrapper
                            id={name}
                            data={{
                                placeholder: 'Vælg sprog',
                                data: options,
                                templateResult: CountryFlags,
                                templateSelection: CountryFlags
                            }}
                            {...fieldProps}
                            {...field}
                        />
                    }
                }
            </Field>
            <ErrorMessage render={msg => <div className="text-danger">{msg}</div>} name={name}/>
        </>
    );
}

export default LanguageSelector;
