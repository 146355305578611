import React, {useState, useEffect} from 'react';
import { Modal, ModalProps} from "react-bootstrap";
import Lang from "../../common/lang/Lang";
import {
  base_url,
  showNotification,
  SubmitButton,
  SwitchLoader,
  LoaderType,
  CONST,
} from "../../common";
import { Editor } from "@tinymce/tinymce-react/lib/cjs/main/ts";
import { UserModel } from "../../model";
import API from "../../common/AxoisClient";
import { AxiosResponse } from "axios";
import { defaultVal } from "./State";
import { EmailTemplateDataSchema } from "../../settings/email-templates/Model";
import { UserContractModel } from "../../model/UserContract";

export interface ContractModel {
  subject?: string;
  content?: string;
  contractContent?: string;
  translator_id?: number;
  email_template?: EmailTemplateDataSchema;
  contract?: UserContractModel & ManualContract;
}

interface ManualContract {
  contract_pdf_file?: string;
}
interface InfoProps extends ModalProps{
  TransInfo: UserModel,
  transID?: number
  ContractInfo?: ContractModel
}

export const TranslatorContract = (props: InfoProps) => {
  const [emailData, setEmailData] = useState(defaultVal(props));
  const [ManualContract, setManualContract] = useState<string>('');
  const [popUpContent, setPopUpContent] = useState({send_contract:props.TransInfo.translator.send_contract});

  useEffect(() => {
    if (props.ContractInfo.contract.contract_pdf_file) {
      getManualContractURL(props.TransInfo.id)
        .then(url => {
          setManualContract( url );
        })
    }
  }, [props.ContractInfo.contract.contract_pdf_file, props.TransInfo.id]);

  if(props.TransInfo === undefined)
    return null;

  function handleSubmit(event) {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    API.post(`users/translator/contract-save`, emailData).then(
      (res: AxiosResponse) => {
        showNotification("success", Lang.contract_mail_send);
        props.onHide();
      }
    );
  }

  function handleMailContentChange(content: any) {
    setEmailData({ ...emailData, content: content });
  }

  function handleContractContentChange(content: any) {
    setEmailData({ ...emailData, contractContent: content });
  }

  const getManualContractURL = async (user_id: number | string) => {
    const res = await API.get(`users/translator/get-contract/${user_id}`, { responseType: 'arraybuffer' });
    const blob = new Blob([res.data], {type: 'application/pdf'});
    const url = URL.createObjectURL(blob);
    return url;
  }

  return(

      <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {Lang.send_contract}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
              <div className={'row'}>
                 {
                   (popUpContent.send_contract===null || popUpContent.send_contract==1) && (
                 <div className={'col-md-12'} style={{marginLeft: 10}}>
                  <div className={'form-group'}>
                  <label>{Lang.name}:</label>
                  <input type="text" className="form-control" id="name" name="name" placeholder={Lang.getString(`name`)}  value={props.TransInfo.name} disabled />
                  </div>

                  <div className={"form-group"}>
                    <label>{Lang.contract_subject}:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      name="subject"
                      placeholder={Lang.getString(`contract_subject`)}
                      onChange={(e) => {
                        setEmailData({ ...emailData, subject: e.target.value });
                      }}
                      value={emailData.subject}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>{Lang.send_contract_mail_content}:</label>
                    <Editor
                      id="mailcontent"
                      apiKey={CONST.TINY_MCE_KEY}
                      init={{
                        menubar: false,
                        mode: "textareas",
                        selector: "#mailcontent",
                        branding: false,
                        forced_root_block: false,
                      }}
                      value={emailData.content}
                      onEditorChange={handleMailContentChange}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>{Lang.contract_content}:</label>
                    <Editor
                      apiKey={CONST.TINY_MCE_KEY}
                      id="contractcontent"
                      init={{
                        menubar: false,
                        mode: "textareas",
                        selector: "#contractcontent",
                        branding: false,
                        forced_root_block: false,
                      }}
                      value={emailData.contractContent}
                      onEditorChange={handleContractContentChange}
                    />
                  </div>
                </div>
              )}

              {
                (popUpContent.send_contract===2) &&
                <div className={'col-md-12'} style={{marginLeft: 10}}>
                  <embed type="application/pdf" src= {props.ContractInfo.contract.contract_pdf_file ? ManualContract : (base_url+'storage/translator-contract/'+props.ContractInfo.contract.contract_filename)} style={{width:'100%',height:'50vw'}}/>
                </div>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {(popUpContent.send_contract === null ||
            popUpContent.send_contract == 1) && (
            <SubmitButton className={"btn btn-primary"} label={"Send"} />
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};
