import React from "react";
import {OrderFile} from "../../model";
import {base_url, Icons} from "../../common";
import _ from "lodash";
import {Link} from "react-router-dom";

interface FilesProps {
    files: Array<OrderFile>
}


export default class Files  extends React.Component<FilesProps, any> {

    groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    };


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        const files = this.groupBy(this.props.files, 'type');

        const FileList = (item: OrderFile) => {
            return (
                <li className="kt-nav__item" key={item.id}>
                    {/*<a className="kt-nav__link" href={`${base_url}api/admin/orders/order-files/${item.name}`} target={'_blank'}>*/}
                    {/* commented 19-Mar-2020
                    <a className="kt-nav__link" href={item.name} target={'_blank'}>
                        <i className={`kt-nav__link-icon ${Icons.file}`} />  <span className="kt-nav__link-text">{item.file_name}</span>
                    </a>*/}
                    <Link target={'_blank'} className={'kt-nav__link'} to={'/file-view/'+(item.id)+'/document/?file_name='+item.file_name}>
                        <i className={`kt-nav__link-icon ${Icons.file}`} />
                        <span className="kt-nav__link-text">{item.file_name}</span>
                    </Link>
                </li>
            )
        };

        const FileMenu = (files) => {
            return files.map(item => {
                return <FileList {...item}/>
            })
        };

        const FileDivider = (props: {files: Array<OrderFile>, title: string}) => {
            const {files, title} = props;
            return (
                <React.Fragment>
                    <h6 className="dropdown-header">{title}</h6>
                    { FileMenu(files) }
                </React.Fragment>
            )
        };

        const FileListing = (props: {files: any}) => {
            const {files} = props;
            return (
                <React.Fragment>
                    {!_.isEmpty(files.admin)    &&  <FileDivider files={files.admin}    title={"Verified"} />}
                    {!_.isEmpty(files.complete) &&  <FileDivider files={files.complete} title={"Translator Docs"} />}
                    {!_.isEmpty(files.create)   &&  <FileDivider files={files.create}   title={"Customer Docs"} />}
                </React.Fragment>
            )
        };

        return (
            <span style={{overflow: 'visible', position: 'relative', width: '80'}}>
              <div className="dropdown">
                <a data-toggle="dropdown" className="btn btn-sm btn-clean btn-icon btn-icon-md" aria-expanded="false" title={'Documents'}>
                    <i className={Icons.file}/>
                </a>
                <div className="dropdown-menu dropdown-menu-right"  x-placement="bottom-end">
                  <ul className="kt-nav">
                     <FileListing files={files} />
                  </ul>
                </div>
              </div>
            </span>
        )
    }
}