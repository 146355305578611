import * as React from 'react';
import {Breadcrumb} from "../../common/page/Breadcrumb";
import Lang from "../../common/lang/Lang";
import {EditBreadcrumb, IState} from "./Data";
import API from './../../common/AxoisClient';
import {showNotification} from "../../common/Common";
import {AdminUserTabs} from "./Tabs";
import {AdminUserValidations} from "./Validations";
import {RoleModel} from "../../model/Role";
import {AdminUserFormData} from "../../model/User";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import {CountryFlags, Select2Wrapper} from "../../common/Select2";
import {CommonSubmitButton, InlineLoader, Select,SwitchLoader} from "../../common";
import {LoaderType} from "../../common/Loader";

export class AdminUserEdit extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props: any){
    super(props);

    this.state = {
      form: AdminUserFormData(),
      id: props.match.params.id,
      roles: [],
      countries:[],
      countrycodes:[]
    }
    AdminUserValidations();
  }

  componentDidMount() {
    API.get('users/admin-users/create').then((res) => {
      this.setState({
        roles: res.data
      })
    })

    API.get(`users/admin-users/${this.state.id}`).then((res: any) => {
      this.setState({
        form: AdminUserFormData(res.data)
      })
    })

    API.get("users/admin-users/countries-list").then(res => {
      this.setState({
          countries: res.data.countryArr,
          countrycodes: res.data.countryCodeArr
      }, () => {
          ($(".selectpicker") as any).selectpicker('refresh');
      });
})
  }

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#admin_user_form").valid()) {
      API.put(`users/admin-users/${this.state.id}`, this.state.form, {params: {from: 'info'}}).then((res:any) => {
        showNotification("success", res.data.message);
        this.props.history.push(`/users/admin-users`);
      });
    }
  }


  render() {
    return (
    
<CreateLayout breadcrumb={EditBreadcrumb} title={'edit_customer'}>
          <div kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper></div>
            <form className="kt-form" id="admin_user_form"  onSubmit={this.handleSubmit}>
            
            <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
               <AdminUserTabs active={'info'} user_id={this.state.id}/>
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
              <div className="kt-heading kt-heading--md">{Lang.edit_user}</div>
                   <div className="kt-portlet__body">
                    <div className="{'row'}">


                              <div className="form-group">
                                <label>{Lang.name} <span className={'required'}>*</span></label>
                                <select onChange={this.handleChange} value={this.state.form.role_id} name={'role_id'} className="form-control" >
                                  <option value={''}>{Lang.select_role}</option>
                                  {this.state.roles.map((item:RoleModel, index: number) => {
                                       
                                    return <option value={item.id}>{item.name}</option>
                                  })}
                                </select>
                              </div>

                              <div className="form-group">
                                <label>{Lang.name} <span className={'required'}>*</span></label>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.name} name={'name'} className="form-control" placeholder={Lang.getString(`name_placeholder`)}/>
                              </div>

                              <div className="form-group">
                                <label>{Lang.email} <span className={'required'}>*</span></label>
                                <input type={'email'} onChange={this.handleChange} value={this.state.form.email} name={'email'} className="form-control" placeholder={Lang.getString(`email_placeholder`)} />
                              </div>
                              
                              <div className={"col-md-9 col-lg-9"}>
                        <div className="form-group ">
                            <label>{Lang.telephone_number} <span className={'required'}>*</span></label>
                            <div className={"row"}>
                                <div className={"col-md-5 col-lg-5"}>
                                    <Select2Wrapper   onChange={this.handleChange}
                                                    className={'form-control'} value={this.state.form.country_code || ''} id={'country_code'} name={'country_code'}
                                                    data={{
                                                        placeholder: "Select Options",
                                                        data: this.state.countrycodes,
                                                        templateResult: CountryFlags,
                                                        templateSelection: CountryFlags,
                                                    }}
                                     />
                          
                                </div>
                                <div className={"col-md-7 col-lg-7"}>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.mobile_no} name={'mobile_no'} className="form-control" placeholder={Lang.getString(`mobile_placeholder`)}/>
                                </div>
                            </div>
                        </div>
                       </div>
  
                        </div>
                        </div>
                        </div>
                </div>    
            <CommonSubmitButton/>
            </form>
            
        </CreateLayout>
   


       

        

    )
  }
}