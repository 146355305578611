import Lang from "../../common/lang/Lang";

declare var window: any;
export const LocationValidations = function () {

  $(document).ready(function () {

    $( "#create_location" ).validate({
      // define validation rules
      rules: {
        name: {
          required: true
        }
      },

      invalidHandler: function(event, validator) {
        var alert = $('#kt_form_1_msg');
        alert.removeClass('kt--hide').show();
      },
    });
  });
}