import * as React from 'react';
import { Breadcrumb } from "../../common/page/Breadcrumb";
import {CreateBreadcrumb} from "./Data";
import { ClaimModel } from "../../model/Claim";
import Lang from "../../common/lang/Lang";
import API, {SwitchLoader} from "../../common/AxoisClient";
import { ClaimValidations } from "./Validations";
import * as _ from 'lodash';
import {LoaderType, Select, showNotification, SubmitButton} from "../../common";
import {RouteComponentProps} from "react-router";

export interface IState{
    form : {
        order_id : string,
        subject : string,
        reason : string,
    },
    order_list : Array<any>
}

const DefaultValue = {
    order_id : '',
    subject : '',
    reason : '',
}

export class CreateClaims extends React.Component<RouteComponentProps<any>, IState> {
    state : IState;
    constructor(props: any) {
        super(props);
        this.state = {
            form: DefaultValue,
            order_list: [],
        }
        ClaimValidations();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        API.get("orders/claim/get-order-list").then(res => {
            this.setState({
                order_list: res.data
            });
        })
    }

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        SwitchLoader(LoaderType.BUTTON);
        if ($("#create_claim").valid()) {
            API.post('orders/claim', this.state.form).then((res) => {
                showNotification("success", Lang.claim_created);
                this.props.history.push(`/orders/claims/`);
            })
        }
    }

    handleChange = (event: React.ChangeEvent<any>) => {

        const target:  any = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ form: { ...this.state.form , [name]: value} });
    }

    formDataReset = (e:any) => {
        e.preventDefault();
        this.setState({
            form: DefaultValue,
        });

        var order_id = document.getElementById("order_id") as HTMLSelectElement;
        var subject = document.getElementById("subject") as HTMLInputElement;
        var reason = document.getElementById("reason") as HTMLTextAreaElement;
        order_id.value = '';
        subject.value = ''; 
        reason.value = '';
    }

    render() {
        return (
            <div>
                <Breadcrumb data={CreateBreadcrumb} />
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="kt-portlet">
                                    <div className="kt-portlet__head">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">
                                                {Lang.add_new_claim}
                                            </h3>
                                        </div>
                                    </div>
                                    <form className="kt-form" onSubmit={this.handleSubmit} id={'create_claim'}>
                                        <div className="kt-portlet__body">
                                            <div className={'row'}>
                                                <div className={"col-md-12 col-lg-12"}>
                                                    <div className="form-group">
                                                        <label>{Lang.order_name} <span className={'required'}>*</span></label>
                                                        <Select className="form-control" id="order_id" name="order_id" onChange={this.handleChange}>
                                                            <option value="">{Lang.select}</option>
                                                            {
                                                                !_.isEmpty(this.state.order_list) &&
                                                                this.state.order_list.map((orders, index) => {
                                                                    return <option value={orders.id}>{orders.order_no}</option>;
                                                                })
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className={"col-md-12 col-lg-12"}>
                                                    <div className="form-group">
                                                        <label>{Lang.claim_subject} <span className={'required'}>*</span></label>
                                                        <input onChange={this.handleChange} className="form-control" id="subject" placeholder={Lang.getString(`subject`)} value={this.state.form.subject || ''} name="subject" type="text" />
                                                    </div>
                                                </div>
                                                <div className={"col-md-12 col-lg-12"}>
                                                    <div className="form-group">
                                                        <label>{Lang.claim_reason} <span className={'required'}>*</span></label>
                                                        <textarea className="form-control" onChange={this.handleChange} placeholder={Lang.getString(`claim_reason`)} id="reason" name="reason">{this.state.form.reason || ''}</textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                    </div>
                                                    <div className="col-lg-6 kt-align-right">
                                                        <button onClick={(e)=>this.formDataReset(e)} className=" btn btn-default hvr-rectangle-out btnMove  mobiNone" type="button">{Lang.reset}</button> &nbsp;
                                                        <SubmitButton className={'btn btn-primary'} label={Lang.submit}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
