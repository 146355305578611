import { DepartmentUnitModel} from "./DepartmentUnit";
import {ProjectModel} from "./Project";
import {CompanyModel} from "./Company";

export interface DepartmentModel {
  id: number;
  name: string;
  notify_email:string;
  company_id: number;
  created_at: string;
  status: number;
  economics_ref_id: string;
  units: Array<DepartmentUnitModel>;
  company?: CompanyModel;
  projects?: Array<ProjectModel>
}

export const EmailValues = (data?: DepartmentModel) => {
  return {
   
     
    name: data !== undefined ? data.name : '',
    notify_email: data !== undefined ? data.notify_email : '',
    company_id: data !== undefined ? data.company_id : 0,
    status: data !== undefined ? data.status : 1,
    economics_ref_id: data !== undefined ? data.economics_ref_id: '',
  }

  

}