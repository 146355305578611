import React, {useState, useEffect} from 'react';
import { Modal, ModalProps} from "react-bootstrap";
import {ratesAddService, ratesUpdateService, ratelistService, LanguageAndCompanyList} from "../../../services";
import Lang from "../../../common/lang/Lang";
import API from "../../../common/AxoisClient";
import {LoaderType} from "../../../common/Loader";
import {TranslatorSpecialRatesModel,UserModel, CompanyModel} from "../../../model";
import {convert_comma_to_dot, convert_dot_to_comma, convert_to_decimal_format} from "../../../helpers/UserHelper";
import {TranslatorSpecialRateValidations} from "../Validations";
import * as _ from 'lodash';
import {showNotification, SubmitButton, Select2Wrapper,SwitchLoader,FormSearchSelect,Icons,Select} from "../../../common";

let moment = require("moment");
 
interface InfoProps extends ModalProps{
  transInfo: TranslatorSpecialRatesModel,
 
}

export const RatesUpdate = (props:any) => {
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({} as UserModel);
  const [special_physical_fix_rate, setSpecialPhysicalFixRate] = useState('');
  const [special_physical_fix_minute, setSpecialPhysicalFixMinute] = useState('');
  const [special_physical_sub_rate, setSpecialPhysicalSubRate] = useState('');
  const [special_physical_sub_minute, setSpecialPhysicalSubMinute] = useState('');
  const [special_telephone_fix_rate, setSpecialTelephoneFixRate] = useState('');
  const [special_telephone_fix_minute, setSpecialTelephoneFixMinute] = useState('');
  const [special_telephone_sub_rate, setSpecialTelephoneSubRate] = useState('');
  const [special_telephone_sub_minute, setSpecialTelephoneSubMinute] = useState('');
  const [special_video_fix_rate, setSpecialVideoFixRate] = useState('');
  const [special_video_fix_minute, setSpecialVideoFixMinute] = useState('');
  const [special_video_sub_rate, setSpecialVideoSubRate] = useState('');
  const [special_video_sub_minute, setSpecialVideoSubMinute] = useState('');
  const [special_tel_message_fix_rate, setSpecialTelMessageFixRate] = useState('');
  const [special_min_word, setSpecialMinWord] = useState('');
  const [special_per_word_rate, setSpecialPerWordRate] = useState('');
  const [special_compensation_agreed, setSpecialCompAgreed] = useState(0);
  const [special_min_km, setSpecialMinKm] = useState('');
  const [special_per_km_rate, setSpecialPerKmRate] = useState('');
  const [special_transport_fee_agreed, setSpecialTransportFeeAgreed] = useState(0);
  const [special_transport_fee_fix_rate, setSpecialTransportFeeFixRate] = useState('');
  const [special_transport_fee_minute, setSpecialTransportFeeMinute] = useState('');
  const [special_transport_fee_sub_rate, setSpecialTransportFeeSubRate] = useState('');
  const [special_transport_fee_sub_minute, setSpecialTransportFeeSubMinute] = useState('');
  const [special_holiday_charge_agreed, setSpecialHolidayChargeAgreed] = useState(0);
  const [special_extra_holiday_percentage, setSpecialExtraHolidayPer] = useState('');
  const [companies, setCompanies] = useState(Array as any);
  const [company, setCompany] = useState('');
  const [id, setId] = useState(0);
  const [rateId, setRateId] = useState('');
  const [special_domain, setSpecialDomain] = useState('');

  useEffect(() => {
    $(document).ready(function(){
      $('.modal').removeAttr("tabindex");
    });
    
    ratesAddService.getPopupState().subscribe((res:any) => {
      setRateId('');
      setCompany('');
      setSpecialDomain('');
      setSpecialPhysicalFixRate(res.user.physical_fix_rate);
      setSpecialPhysicalFixMinute(res.user.physical_fix_minute);
      setSpecialPhysicalSubRate(res.user.physical_sub_rate);
      setSpecialPhysicalSubMinute(res.user.physical_sub_minute);
      setSpecialTelephoneFixRate(res.user.telephone_fix_rate);
      setSpecialTelephoneFixMinute(res.user.telephone_fix_minute);
      setSpecialTelephoneSubRate(res.user.telephone_sub_rate);
      setSpecialTelephoneSubMinute(res.user.telephone_sub_minute);
      setSpecialVideoFixRate(res.user.video_fix_rate);
      setSpecialVideoFixMinute(res.user.video_fix_minute);
      setSpecialVideoSubRate(res.user.video_sub_rate);
      setSpecialVideoSubMinute(res.user.video_sub_minute);
      setSpecialTelMessageFixRate(res.user.tel_message_fix_rate);
      setSpecialMinWord(res.user.min_word);
      setSpecialPerWordRate(res.user.per_word_rate);
      setSpecialCompAgreed(res.user.compensation_agreed);
      setSpecialMinKm(res.user.min_km);
      setSpecialPerKmRate(res.user.per_km_rate);
      setSpecialTransportFeeAgreed(res.user.transport_fee_agreed);
      setSpecialTransportFeeFixRate(res.user.transport_fee_fix_rate);
      setSpecialTransportFeeMinute(res.user.transport_fee_minute);
      setSpecialTransportFeeSubRate(res.user.transport_fee_sub_rate);
      setSpecialTransportFeeSubMinute(res.user.transport_fee_sub_minute);
      setSpecialHolidayChargeAgreed(res.user.holiday_charge_agreed);
      setSpecialExtraHolidayPer(res.user.extra_holiday_percentage);
      setShow(res.show);
      setId(res.user.id);
    })
    ratesUpdateService.getPopupState().subscribe((res:any) => {
      setRateId(res.rate_id);
      setShow(res.show);
      setId(res.user.user_id);
      setCompany(res.user.company_id);
      setSpecialDomain(res.user.special_domain);
      setSpecialPhysicalFixRate(convert_dot_to_comma(res.user.special_physical_fix_rate));
      setSpecialPhysicalFixMinute(res.user.special_physical_fix_minute);
      setSpecialPhysicalSubRate(convert_dot_to_comma(res.user.special_physical_sub_rate));
      setSpecialPhysicalSubMinute(res.user.special_physical_sub_minute);
      setSpecialTelephoneFixRate(convert_dot_to_comma(res.user.special_telephone_fix_rate));
      setSpecialTelephoneFixMinute(res.user.special_telephone_fix_minute);
      setSpecialTelephoneSubRate(convert_dot_to_comma(res.user.special_telephone_sub_rate));
      setSpecialTelephoneSubMinute(res.user.special_telephone_sub_minute);
      setSpecialVideoFixRate(convert_dot_to_comma(res.user.special_video_fix_rate));
      setSpecialVideoFixMinute(res.user.special_video_fix_minute);
      setSpecialVideoSubRate(convert_dot_to_comma(res.user.special_video_sub_rate));
      setSpecialVideoSubMinute(res.user.special_video_sub_minute);
      setSpecialTelMessageFixRate(convert_dot_to_comma(res.user.special_tel_message_fix_rate));
      setSpecialMinWord(res.user.special_min_word);
      setSpecialPerWordRate(convert_dot_to_comma(res.user.special_per_word_rate));
      setSpecialCompAgreed(res.user.special_compensation_agreed);
      setSpecialMinKm(res.user.special_min_km);
      setSpecialPerKmRate(convert_dot_to_comma(res.user.special_per_km_rate));
      setSpecialTransportFeeAgreed(res.user.special_transport_fee_agreed);
      setSpecialTransportFeeFixRate(convert_dot_to_comma(res.user.special_transport_fee_fix_rate));
      setSpecialTransportFeeMinute(res.user.special_transport_fee_minute);
      setSpecialTransportFeeSubRate(convert_dot_to_comma(res.user.special_transport_fee_sub_rate));
      setSpecialTransportFeeSubMinute(res.user.special_transport_fee_sub_minute);
      setSpecialHolidayChargeAgreed(res.user.special_holiday_charge_agreed);
      setSpecialExtraHolidayPer(convert_dot_to_comma(res.user.special_extra_holiday_percentage));
    })
    if(show){
      LanguageAndCompanyList().then(res => {
        setCompanies(res.companies);
      });
      TranslatorSpecialRateValidations();
    }
  },[show]);

  if(show === false){
    return null;
  }

  function handleChange(event:any){
    if(event.target.name === 'company_id'){
      setCompany(event.target.value);
      setSpecialDomain('');
    }
    if(event.target.name === 'special_domain'){
      setCompany('');
      setSpecialDomain(event.target.value);
    }
    if(event.target.name === 'special_physical_fix_rate'){
      setSpecialPhysicalFixRate(event.target.value);
    }
    if(event.target.name === 'special_physical_fix_minute'){
      setSpecialPhysicalFixMinute(event.target.value);
    }
    if(event.target.name === 'special_physical_sub_rate'){
      setSpecialPhysicalSubRate(event.target.value);
    }
    if(event.target.name === 'special_physical_sub_minute'){
      setSpecialPhysicalSubMinute(event.target.value);
    }
    if(event.target.name === 'special_telephone_fix_rate'){
      setSpecialTelephoneFixRate(event.target.value);
    }
    if(event.target.name === 'special_telephone_fix_minute'){
      setSpecialTelephoneFixMinute(event.target.value);
    }
    if(event.target.name === 'special_telephone_sub_rate'){
      setSpecialTelephoneSubRate(event.target.value);
    }
    if(event.target.name === 'special_telephone_sub_minute'){
      setSpecialTelephoneSubMinute(event.target.value);
    }
    if(event.target.name === 'special_video_fix_rate'){
      setSpecialVideoFixRate(event.target.value);
    }
    if(event.target.name === 'special_video_fix_minute'){
      setSpecialVideoFixMinute(event.target.value);
    }
    if(event.target.name === 'special_video_sub_rate'){
      setSpecialVideoSubRate(event.target.value);
    }
    if(event.target.name === 'special_video_sub_minute'){
      setSpecialVideoSubMinute(event.target.value);
    }
    if(event.target.name === 'special_tel_message_fix_rate'){
      setSpecialTelMessageFixRate(event.target.value);
    }
    if(event.target.name === 'special_min_word'){
      setSpecialMinWord(event.target.value);
    }
    if(event.target.name === 'special_per_word_rate'){
      setSpecialPerWordRate(event.target.value);
    }
    if(event.target.name === 'special_compensation_agreed'){
      setSpecialCompAgreed(event.target.value);
    }
    if(event.target.name === 'special_min_km'){
      setSpecialMinKm(event.target.value);
    }
    if(event.target.name === 'special_per_km_rate'){
      setSpecialPerKmRate(event.target.value);
    }
    if(event.target.name === 'special_transport_fee_agreed'){
      setSpecialTransportFeeAgreed(event.target.value);
    }
    if(event.target.name === 'special_transport_fee_fix_rate'){
      setSpecialTransportFeeFixRate(event.target.value);
    }
    if(event.target.name === 'special_transport_fee_minute'){
      setSpecialTransportFeeMinute(event.target.value);
    }
    if(event.target.name === 'special_transport_fee_sub_rate'){
      setSpecialTransportFeeSubRate(event.target.value);
    }
    if(event.target.name === 'special_transport_fee_sub_minute'){
      setSpecialTransportFeeSubMinute(event.target.value);
    }
    if(event.target.name === 'special_holiday_charge_agreed'){
      setSpecialHolidayChargeAgreed(event.target.value);
    }
    if(event.target.name === 'special_extra_holiday_percentage'){
      setSpecialExtraHolidayPer(event.target.value);
    }
  }

  function handleBlur(event:any){
    if(event.target.name === 'company_id'){
      setCompany(event.target.value);
    }
    if(event.target.name === 'special_physical_fix_rate'){
      setSpecialPhysicalFixRate(convert_to_decimal_format(event.target.value));
    }
    if(event.target.name === 'special_physical_fix_minute'){
      setSpecialPhysicalFixMinute(event.target.value);
    }
    if(event.target.name === 'special_physical_sub_rate'){
      setSpecialPhysicalSubRate(convert_to_decimal_format(event.target.value));
    }
    if(event.target.name === 'special_physical_sub_minute'){
      setSpecialPhysicalSubMinute(event.target.value);
    }
    if(event.target.name === 'special_telephone_fix_rate'){
      setSpecialTelephoneFixRate(convert_to_decimal_format(event.target.value));
      setSpecialTelMessageFixRate(convert_to_decimal_format(event.target.value));
    }
    if(event.target.name === 'special_telephone_fix_minute'){
      setSpecialTelephoneFixMinute(event.target.value);
    }
    if(event.target.name === 'special_telephone_sub_rate'){
      setSpecialTelephoneSubRate(convert_to_decimal_format(event.target.value));
    }
    if(event.target.name === 'special_telephone_sub_minute'){
      setSpecialTelephoneSubMinute(event.target.value);
    }
    if(event.target.name === 'special_video_fix_rate'){
      setSpecialVideoFixRate(convert_to_decimal_format(event.target.value));
    }
    if(event.target.name === 'special_video_fix_minute'){
      setSpecialVideoFixMinute(event.target.value);
    }
    if(event.target.name === 'special_video_sub_rate'){
      setSpecialVideoSubRate(convert_to_decimal_format(event.target.value));
    }
    if(event.target.name === 'special_video_sub_minute'){
      setSpecialVideoSubMinute(event.target.value);
    }
    if(event.target.name === 'special_tel_message_fix_rate'){
      setSpecialTelMessageFixRate(convert_to_decimal_format(event.target.value));
    }
    if(event.target.name === 'special_min_word'){
      setSpecialMinWord(event.target.value);
    }
    if(event.target.name === 'special_per_word_rate'){
      setSpecialPerWordRate(convert_to_decimal_format(event.target.value));
    }
    if(event.target.name === 'special_compensation_agreed'){
      setSpecialCompAgreed(event.target.value);
    }
    if(event.target.name === 'special_min_km'){
      setSpecialMinKm(event.target.value);
    }
    if(event.target.name === 'special_per_km_rate'){
      setSpecialPerKmRate(convert_to_decimal_format(event.target.value));
    }
    if(event.target.name === 'special_transport_fee_agreed'){
      setSpecialTransportFeeAgreed(event.target.value);
    }
    if(event.target.name === 'special_transport_fee_fix_rate'){
      setSpecialTransportFeeFixRate(convert_to_decimal_format(event.target.value));
    }
    if(event.target.name === 'special_transport_fee_minute'){
      setSpecialTransportFeeMinute(event.target.value);
    }
    if(event.target.name === 'special_transport_fee_sub_rate'){
      setSpecialTransportFeeSubRate(convert_to_decimal_format(event.target.value));
    }
    if(event.target.name === 'special_transport_fee_sub_minute'){
      setSpecialTransportFeeSubMinute(event.target.value);
    }
    if(event.target.name === 'special_holiday_charge_agreed'){
      setSpecialHolidayChargeAgreed(event.target.value);
    }
    if(event.target.name === 'special_extra_holiday_percentage'){
      setSpecialExtraHolidayPer(convert_to_decimal_format(event.target.value));
    }      
  }
   
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#translator_special_rate").valid()) {
     API.post(`users/translator/translator-rates-update`,{
       id: rateId,
       user_id:id,
       company_id: company ? company: null,
       special_domain: special_domain ? special_domain: null,
       special_physical_fix_rate: convert_comma_to_dot(special_physical_fix_rate),
       special_physical_fix_minute: special_physical_fix_minute,
       special_physical_sub_rate: convert_comma_to_dot(special_physical_sub_rate),
       special_physical_sub_minute: special_physical_sub_minute,
       special_telephone_fix_rate: convert_comma_to_dot(special_telephone_fix_rate),
       special_telephone_fix_minute: special_telephone_fix_minute,
       special_telephone_sub_rate: convert_comma_to_dot(special_telephone_sub_rate),
       special_telephone_sub_minute: special_telephone_sub_minute,
       special_video_fix_rate: convert_comma_to_dot(special_video_fix_rate),
       special_video_fix_minute: special_video_fix_minute,
       special_video_sub_rate: convert_comma_to_dot(special_video_sub_rate),
       special_video_sub_minute: special_video_sub_minute,
       special_tel_message_fix_rate: convert_comma_to_dot(special_tel_message_fix_rate),
       special_min_word: special_min_word,
       special_per_word_rate: convert_comma_to_dot(special_per_word_rate),
       special_compensation_agreed: special_compensation_agreed,
       special_min_km: special_min_km,
       special_per_km_rate: convert_comma_to_dot(special_per_km_rate),
       special_transport_fee_agreed: special_transport_fee_agreed,
       special_transport_fee_fix_rate: convert_comma_to_dot(special_transport_fee_fix_rate),
       special_transport_fee_minute: special_transport_fee_minute,
       special_transport_fee_sub_rate: convert_comma_to_dot(special_transport_fee_sub_rate),
       special_transport_fee_sub_minute: special_transport_fee_sub_minute,
       special_holiday_charge_agreed: special_holiday_charge_agreed,
       special_extra_holiday_percentage: convert_comma_to_dot(special_extra_holiday_percentage)
     }).then(res => {
        ratelistService.ratedata();
        showNotification("success", Lang.translator_rates_update);
        setShow(false);
      })
    }
  }

  return(
    <Modal
      show={show}
      onHide={() => setShow(false) }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form className="kt-form" onSubmit={handleSubmit} id="translator_special_rate">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {Lang.rate}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
              <div className={'col-md-5'} >
                <div className="form-group">
                  <label>{Lang.company} </label>
                  <FormSearchSelect name={'company_id'} value={company || ''}  onChange={(e)=>handleChange(e)}  options={[{label: Lang.choose_company, value: ''}, ...companies]}/>
                </div>
              </div>
              <div className={'col-md-2'} >
                <span style={{textAlign: 'center'}}><strong>OR</strong></span>
              </div>
              <div className={'col-md-5'} >
                <div className="form-group">
                  <label>{Lang.special_domain} </label>
                  <input type="text" className={"form-control"} id="special_domain" value={special_domain} name="special_domain" onChange={(e)=>handleChange(e)} placeholder={Lang.getString(`special_domain`)} />
                </div>
              </div>
            </div>
            {(company || special_domain) &&
              <div>
                <div className={'row'}>
                    <div className={"col-md-12 col-lg-12"}>
                        <h4>A. Generelt</h4>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-12 col-lg-12"}>
                        <strong>Fremmøde-tolkning</strong>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.rate_minute} </label>
                                    <Select id="special_physical_fix_minute" value={special_physical_fix_minute} name="special_physical_fix_minute" onChange={(e)=>handleChange(e)} className="form-control">
                                        <option value="">{Lang.select}</option>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                        <option value={25}>25</option>
                                        <option value={30}>30</option>
                                        <option value={35}>35</option>
                                        <option value={40}>40</option>
                                        <option value={45}>45</option>
                                        <option value={50}>50</option>
                                        <option value={55}>55</option>
                                        <option value={60}>60</option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.fix_rate} </label>
                                    <input type="text" className={"form-control "+ (special_physical_fix_rate && props.normalRates.physical_fix_rate!==special_physical_fix_rate ? 'difference_rate' : '')} id="special_physical_fix_rate" value={special_physical_fix_rate} name="special_physical_fix_rate" onChange={(e)=>handleChange(e)} onBlur={(e)=>handleBlur(e)} placeholder={Lang.getString(`fix_rate`)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.rate_minute} </label>
                                    <Select id="special_physical_sub_minute" value={special_physical_sub_minute} name="special_physical_sub_minute" onChange={(e)=>handleChange(e)} className="form-control">
                                        <option value="">{Lang.select}</option>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                        <option value={25}>25</option>
                                        <option value={30}>30</option>
                                        <option value={35}>35</option>
                                        <option value={40}>40</option>
                                        <option value={45}>45</option>
                                        <option value={50}>50</option>
                                        <option value={55}>55</option>
                                        <option value={60}>60</option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.sub_rate} </label>
                                    <input type="text" className={"form-control "+ (special_physical_sub_rate && props.normalRates.physical_sub_rate!==special_physical_sub_rate ? 'difference_rate' : '')} id="special_physical_sub_rate" value={special_physical_sub_rate} name="special_physical_sub_rate" onChange={(e)=>handleChange(e)}  onBlur={(e)=>handleBlur(e)} placeholder={Lang.getString(`sub_rate`)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-12 col-lg-12"}>
                        <strong>Telefon-tolkning</strong>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.rate_minute} </label>
                                    <Select id="special_telephone_fix_minute" value={special_telephone_fix_minute} name="special_telephone_fix_minute" onChange={(e)=>handleChange(e)} className="form-control">
                                        <option value="">{Lang.select}</option>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                        <option value={25}>25</option>
                                        <option value={30}>30</option>
                                        <option value={35}>35</option>
                                        <option value={40}>40</option>
                                        <option value={45}>45</option>
                                        <option value={50}>50</option>
                                        <option value={55}>55</option>
                                        <option value={60}>60</option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.fix_rate} </label>
                                    <input type="text" className={"form-control "+ (special_telephone_fix_rate && props.normalRates.telephone_fix_rate!==special_telephone_fix_rate ? 'difference_rate' : '')} id="special_telephone_fix_rate" value={special_telephone_fix_rate} name="special_telephone_fix_rate" onChange={(e)=>handleChange(e)}  onBlur={(e)=>handleBlur(e)} placeholder={Lang.getString(`fix_rate`)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.rate_minute} </label>
                                    <Select id="special_telephone_sub_minute" value={special_telephone_sub_minute} name="special_telephone_sub_minute" onChange={(e)=>handleChange(e)} className="form-control">
                                        <option value="">{Lang.select}</option>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                        <option value={25}>25</option>
                                        <option value={30}>30</option>
                                        <option value={35}>35</option>
                                        <option value={40}>40</option>
                                        <option value={45}>45</option>
                                        <option value={50}>50</option>
                                        <option value={55}>55</option>
                                        <option value={60}>60</option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.sub_rate} </label>
                                    <input type="text" className={"form-control "+ (special_telephone_sub_rate && props.normalRates.telephone_sub_rate!==special_telephone_sub_rate ? 'difference_rate' : '')} id="special_telephone_sub_rate" value={special_telephone_sub_rate} name="special_telephone_sub_rate" onChange={(e)=>handleChange(e)}  onBlur={(e)=>handleBlur(e)} placeholder={Lang.getString(`sub_rate`)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-12 col-lg-12"}>
                        <strong>Video-tolkning</strong>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.rate_minute} </label>
                                    <Select id="special_video_fix_minute" value={special_video_fix_minute} name="special_video_fix_minute" onChange={(e)=>handleChange(e)} className="form-control">
                                        <option value="">{Lang.select}</option>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                        <option value={25}>25</option>
                                        <option value={30}>30</option>
                                        <option value={35}>35</option>
                                        <option value={40}>40</option>
                                        <option value={45}>45</option>
                                        <option value={50}>50</option>
                                        <option value={55}>55</option>
                                        <option value={60}>60</option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.fix_rate} </label>
                                    <input type="text" className={"form-control "+ (special_video_fix_rate && props.normalRates.video_fix_rate!==special_video_fix_rate ? 'difference_rate' : '')} id="special_video_fix_rate" value={special_video_fix_rate} name="special_video_fix_rate" onChange={(e)=>handleChange(e)}  onBlur={(e)=>handleBlur(e)} placeholder={Lang.getString(`fix_rate`)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.rate_minute} </label>
                                    <Select id="special_video_sub_minute" value={special_video_sub_minute} name="special_video_sub_minute" onChange={(e)=>handleChange(e)} className="form-control">
                                        <option value="">{Lang.select}</option>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                        <option value={25}>25</option>
                                        <option value={30}>30</option>
                                        <option value={35}>35</option>
                                        <option value={40}>40</option>
                                        <option value={45}>45</option>
                                        <option value={50}>50</option>
                                        <option value={55}>55</option>
                                        <option value={60}>60</option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.sub_rate} </label>
                                    <input type="text" className={"form-control "+ (special_video_sub_rate && props.normalRates.video_sub_rate!==special_video_sub_rate ? 'difference_rate' : '')} id="special_video_sub_rate" value={special_video_sub_rate} name="special_video_sub_rate" onChange={(e)=>handleChange(e)}  onBlur={(e)=>handleBlur(e)} placeholder={Lang.getString(`sub_rate`)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-12 col-lg-12"}>
                        <strong>Telefonbesked</strong>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.tel_message_fix_rate} </label>
                                    <input type="text" className={"form-control "+ (special_tel_message_fix_rate && props.normalRates.tel_message_fix_rate!==special_tel_message_fix_rate ? 'difference_rate' : '')} id="special_tel_message_fix_rate" value={special_tel_message_fix_rate} name="special_tel_message_fix_rate" onChange={(e)=>handleChange(e)}  onBlur={(e)=>handleBlur(e)} placeholder={Lang.getString(`tel_message_fix_rate`)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-12 col-lg-12"}>
                        <strong>Skriftlig Oversættelse</strong>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.min_word} </label>
                                    <input type="text" className="form-control" id="special_min_word" value={special_min_word} name="special_min_word" onChange={(e)=>handleChange(e)}  placeholder={Lang.getString(`min_word`)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.per_word_rate} </label>
                                    <input type="text" className={"form-control "+ (special_per_word_rate && props.normalRates.per_word_rate!==special_per_word_rate ? 'difference_rate' : '')} id="special_per_word_rate" value={special_per_word_rate} name="special_per_word_rate" onChange={(e)=>handleChange(e)}  onBlur={(e)=>handleBlur(e)} placeholder={Lang.getString(`per_word_rate`)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.compensation_agreed} </label>
                                    <Select id="special_compensation_agreed" value={(special_compensation_agreed !== 0)?special_compensation_agreed:'0' || ''} name="special_compensation_agreed" onChange={(e)=>handleChange(e)} className="form-control">
                                        <option value="">{Lang.select}</option>
                                        <option value={1}>{Lang.yes}</option>
                                        <option value={0}>{Lang.no}</option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { special_compensation_agreed==1 &&
                    <div className={'row'}>
                        <div className={"col-md-3 col-lg-3"}>
                            <div className="form-group">
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <label>{Lang.min_km} </label>
                                        <input type="text" className="form-control" id="special_min_km" value={special_min_km} name="special_min_km" onChange={(e)=>handleChange(e)}  placeholder={Lang.getString(`min_km`)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-3 col-lg-3"}>
                            <div className="form-group">
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <label>{Lang.per_km_rate} </label>
                                        <input type="text" className={"form-control "+ (special_per_km_rate && props.normalRates.per_km_rate!==special_per_km_rate ? 'difference_rate' : '')} id="special_per_km_rate" value={special_per_km_rate} name="special_per_km_rate" onChange={(e)=>handleChange(e)}  onBlur={(e)=>handleBlur(e)} placeholder={Lang.getString(`per_km_rate`)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className={'row'}>
                    <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.transport_fee_agreed} </label>
                                    <Select id="special_transport_fee_agreed" value={(special_transport_fee_agreed !== 0)?special_transport_fee_agreed:'0' || ''} name="special_transport_fee_agreed" onChange={(e)=>handleChange(e)} className="form-control">
                                        <option value="">{Lang.select}</option>
                                        <option value={1}>{Lang.yes}</option>
                                        <option value={0}>{Lang.no}</option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { special_transport_fee_agreed==1 &&
                    <div className={'row'}>
                        <div className={"col-md-3 col-lg-3"}>
                            <div className="form-group">
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <label>{Lang.rate_minute} </label>
                                        <Select id="special_transport_fee_minute" value={special_transport_fee_minute} name="special_transport_fee_minute" onChange={(e)=>handleChange(e)} className="form-control">
                                            <option value="">{Lang.select}</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                            <option value={20}>20</option>
                                            <option value={25}>25</option>
                                            <option value={30}>30</option>
                                            <option value={35}>35</option>
                                            <option value={40}>40</option>
                                            <option value={45}>45</option>
                                            <option value={50}>50</option>
                                            <option value={55}>55</option>
                                            <option value={60}>60</option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-3 col-lg-3"}>
                            <div className="form-group">
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <label>{Lang.fix_rate} </label>
                                        <input type="text" className={"form-control "+ (special_transport_fee_fix_rate && props.normalRates.transport_fee_fix_rate!==special_transport_fee_fix_rate ? 'difference_rate' : '')} id="special_transport_fee_fix_rate" value={special_transport_fee_fix_rate} name="special_transport_fee_fix_rate" onChange={(e)=>handleChange(e)}  onBlur={(e)=>handleBlur(e)} placeholder={Lang.getString(`fix_rate`)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-3 col-lg-3"}>
                            <div className="form-group">
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <label>{Lang.rate_minute} </label>
                                        <Select id="special_transport_fee_sub_minute" value={special_transport_fee_sub_minute} name="special_transport_fee_sub_minute" onChange={(e)=>handleChange(e)} className="form-control">
                                            <option value="">{Lang.select}</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                            <option value={20}>20</option>
                                            <option value={25}>25</option>
                                            <option value={30}>30</option>
                                            <option value={35}>35</option>
                                            <option value={40}>40</option>
                                            <option value={45}>45</option>
                                            <option value={50}>50</option>
                                            <option value={55}>55</option>
                                            <option value={60}>60</option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-3 col-lg-3"}>
                            <div className="form-group">
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <label>{Lang.sub_rate} </label>
                                        <input type="text" className={"form-control "+ (special_transport_fee_sub_rate && props.normalRates.transport_fee_sub_rate!==special_transport_fee_sub_rate ? 'difference_rate' : '')} id="special_transport_fee_sub_rate" value={special_transport_fee_sub_rate} name="special_transport_fee_sub_rate" onChange={(e)=>handleChange(e)}  onBlur={(e)=>handleBlur(e)} placeholder={Lang.getString(`sub_rate`)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-12 col-lg-12"}>
                            <p><i className={Icons.ban}></i> Obs. Maximum statens højeste takst 3,52</p>
                        </div>
                    </div>
                }
                <div className={'row'}>
                    <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <label>{Lang.holiday_charge_agreed} </label>
                                    <Select id="special_holiday_charge_agreed" value={(special_holiday_charge_agreed !== 0)?special_holiday_charge_agreed:'0' || ''} name="special_holiday_charge_agreed" onChange={(e)=>handleChange(e)} className="form-control">
                                        <option value="">{Lang.select}</option>
                                        <option value={1}>{Lang.yes}</option>
                                        <option value={0}>{Lang.no}</option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { special_holiday_charge_agreed==1 &&
                    <div className={'row'}>
                        <div className={"col-md-6 col-lg-6"}>
                            <div className="form-group">
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <label>{Lang.extra_holiday_percentage} </label>
                                        <input type="text" className={"form-control "+ (special_extra_holiday_percentage && props.normalRates.extra_holiday_percentage!==special_extra_holiday_percentage ? 'difference_rate' : '')} id="special_extra_holiday_percentage" value={special_extra_holiday_percentage} name="special_extra_holiday_percentage" onChange={(e)=>handleChange(e)} onBlur={(e)=>handleBlur(e)}  placeholder={Lang.getString(`extra_holiday_percentage`)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                }
              </div>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton className={'btn btn-success'} label={Lang.submit} />
          <button className={'btn btn-primary'} onClick={() =>  setShow(false) }>{Lang.close}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
