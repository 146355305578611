import React from "react";
import { useOnClickOutside } from "./useOnClickOutside";
import classnames, { Argument } from "classnames";
import { PropsWithChildren, useRef } from "react";
import ReactDOM from "react-dom";

// style
import style from "./Modal.module.scss";

interface Props {
  classNames?: { overlay?: Argument; modal?: Argument };
  isOpen: boolean;
  onClose: () => void;
}

export function Modal(props: PropsWithChildren<Props>) {
  const { children, classNames, isOpen, onClose } = props;
  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(modalRef, onClose);

  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <div className={classnames(style["overlay"], classNames?.overlay ?? "")}>
      <div ref={modalRef} className={classnames(style["modal"], classNames?.modal ?? "")}>
        {children}
      </div>
    </div>,
    document.body
  );
}

export default Modal;
