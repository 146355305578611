import React, { useState } from "react";

// components
import { Icons } from "../../../common";
import { Country_Code_Array, Translator_Data } from "./TypeDefs";
import { Qualification } from "../popup/Qualification";
import {
  onlineTestAnswerService,
  qualificationService,
} from "../../../services";
import { TranslatorOnlineTestAnswerModel, UserModel } from "../../../model";
import Lang from "../../../common/lang/Lang";
import { TranslatorOnlineTestAnswer } from "../popup/TranslatorOnlineTestAnswer";
import InterviewForm from "./InterviewForm";
import FinalizeInterviewForm from "./FinalizeInterviewForm";

// styles
import callStyle from "./CallStyle.module.scss";
import { Modal } from "react-bootstrap";

// interfaces
interface Props {
  translator_data: Translator_Data;
  country_codes: Country_Code_Array;
}

const CallSection: React.FC<Props> = (props) => {
  const { translator_data, country_codes } = props;
  const [ShowFinalizeInterview, setShowFinalizeInterview] =
    useState<boolean>(false);
  const initial_interview_taken =
    translator_data.translator.training &&
    translator_data.translator.highest_complete_edu &&
    translator_data.translator.current_employment &&
    translator_data.translator.motivation;
  return (
    <div className={callStyle.call_container}>
      <div className={callStyle.section_header_container}>
        <div className={callStyle.section_emoji}>
          <i className={Icons.mobile_no}></i>
        </div>
        <div className={callStyle.header_details}>
          <h2>Detaljer</h2>
          <ul className={callStyle.header_list}>
            <li>
              <span style={{ whiteSpace: "nowrap" }}>
                Sidste interviewdato:
              </span>
              <span>
                {translator_data.translator?.interview_scheduled_at || "---"}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className={callStyle.call_section}>
        <h2 className={callStyle.title}>Interview</h2>
        {/* green box translator call form */}
        <InterviewForm
          country_codes={country_codes}
          translator_data={translator_data}
        />
      </div>
      <div className={callStyle.interview_questions}>
        <h2>Interview med oversætter</h2>
        <button
          onClick={() => {
            qualificationService.showPopup(
              translator_data as unknown as UserModel
            );
          }}
          className={`${callStyle.interview_button} 
          ${!initial_interview_taken && callStyle.attention_button}`}
        >
          <i className={Icons.edit} />
          <span>{Lang.qualification}</span>
        </button>
        <button
          onClick={() => {
            onlineTestAnswerService.showPopup(
              translator_data as unknown as TranslatorOnlineTestAnswerModel
            );
          }}
          className={`${callStyle.interview_button} 
          ${
            initial_interview_taken &&
            !translator_data.translator?.interview_taken_at &&
            callStyle.attention_button
          }`}
        >
          <i className={Icons.edit} />
          <span>Svar på GTEP</span>
        </button>
        {initial_interview_taken && (
          <button
            onClick={() => {
              setShowFinalizeInterview(true);
            }}
            className={`${callStyle.interview_button} 
            ${
              initial_interview_taken &&
              !translator_data.translator?.interview_taken_at &&
              callStyle.attention_button
            }`}
            disabled={!!translator_data.translator.interview_taken_at}
          >
            <i className={Icons.edit} />
            <span>Afslut interview</span>
          </button>
        )}
      </div>
      {/* Finalize interview form modal popup */}
      <Modal
        show={ShowFinalizeInterview}
        onHide={() => {
          setShowFinalizeInterview(false);
        }}
        size="lg"
        centered
      >
        <div className={callStyle.complete_interview_container}>
          <button
            className={callStyle.exit_modal}
            onClick={(e) => {
              setShowFinalizeInterview(false);
            }}
          >
            <span className={`${Icons.cross}`}></span>
          </button>
          <h2>Finalize Interview</h2>
          {/* form at the bottom used to set translator final interview status and to upload files. */}
          <FinalizeInterviewForm
            setShowFinalizeInterview={setShowFinalizeInterview}
            user_id={translator_data.translator.user_id}
          />
        </div>
      </Modal>
      <Qualification />
      <TranslatorOnlineTestAnswer />
    </div>
  );
};

export default CallSection;
