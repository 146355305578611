import React from "react";
import style from "../SkanderborgFeedback.module.scss";

interface Props {
  orderNo: string;
  customerName: string;
  orderDate: string;
  orderFrom: string;
  orderTo: string;
  fromCountryName: string;
  fromLanguageName: string;
  toCountryName: string;
  toLanguageName: string;
  typeName: string;
}
const flagUrl = (name: string) =>
  `https://www.countryflags.com/wp-content/uploads/${name
    .split(" ")
    .join("-")
    .toLowerCase()}-flag-png-large.png`;
function OrderInfo({
  orderNo,
  customerName,
  orderDate,
  orderFrom,
  orderTo,
  fromCountryName,
  fromLanguageName,
  toCountryName,
  toLanguageName,
  typeName,
}: Props) {
  return (
    <>
      <h3 className={style["order-no"]}>#{orderNo}</h3>
      <h5>{customerName}</h5>
      <div className={style["info-lines"]}>
        <span>
          <span className={style["sub-text"]}>Date: </span>
          {orderDate}
        </span>
        <span>
          <span className={style["sub-text"]}>From: </span>
          {orderFrom}
          <span className={style["sub-text"]}> To: </span>
          {orderTo}
        </span>
        <div>
          <span className={style["sub-text"]}>Lang:&nbsp;</span>
          <img
            className={style["image-icon"]}
            src={flagUrl(fromCountryName)}
            alt={`${fromCountryName} flag`}
          />
          &nbsp;{fromLanguageName}
          &nbsp;&gt;&nbsp;
          <img
            className={style["image-icon"]}
            src={flagUrl(toCountryName)}
            alt={`${toCountryName} flag`}
          />
          &nbsp;{toLanguageName}
        </div>
        <div>
          <span className={style["sub-text"]}>Oversættelsestype:&nbsp;</span>
          {typeName}
        </div>
      </div>
    </>
  );
}

export default OrderInfo;
