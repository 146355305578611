import React from 'react'
import Lang from '../../../../common/lang/Lang'
import { LeavesState } from '../../Leaves'

// styles
import sheet from './LeaveTypeSelector.module.scss'

interface Props {
  state: LeavesState
  setParentState: any
}

const LeaveTypeSelector = ({ state, setParentState }: Props) => {
  const { leave_type, type_of_half_day } = state.leaveInfo

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let new_state: any = Number(e.target.value) !== 2 ? { type_of_half_day: '' } : {}
    new_state = Number(e.target.value) !== 3 ? { ...new_state, to_date: '' } : new_state
    setParentState({ leaveInfo: { ...state.leaveInfo, leave_type: Number(e.target.value), ...new_state } })
  }

  const handleDayHalfChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setParentState({ leaveInfo: { ...state.leaveInfo,  type_of_half_day: Number(e.target.value) } })

  return (
    <>
      <div className='col-md-12 px-0' >
        <div className={`form-group customRadioGroup ${sheet.container}`}>
          <label>{Lang.type_of_half_day}</label><br />
          <div className={`interpreterBox customRadioGroup mb-22 ${sheet.items_row}`}>
            <div className={`radioCustom ${sheet.checkbox}`}>
              <input type="radio" className="custom-control-radio" id="full_day" name="leave_type" value="1" onChange={handleTypeChange} checked={leave_type === 1}/>
              <label className={`custom-control-radio2 ${sheet.checkbox_label}`} htmlFor="full_day"><span className="fullDay"/><span>{Lang.full_day}</span><i className="iconCheck fas fa-check-circle"/></label>
            </div>
            <div className={`radioCustom ${sheet.checkbox}`}>
              <input type="radio" className="custom-control-radio" id="half_day" name="leave_type" value="2" onChange={handleTypeChange} checked={leave_type === 2} />
              <label className={`custom-control-radio2 ${sheet.checkbox_label}`} htmlFor="half_day"><span className="halfDay"/><span>{Lang.half_day}</span><i className="iconCheck fas fa-check-circle"/></label>
            </div>
            <div className={`radioCustom ${sheet.checkbox}`}>
              <input type="radio" className="custom-control-radio" id="multiple_days" name="leave_type" value="3" onChange={handleTypeChange} checked={leave_type === 3} />
              <label className={`custom-control-radio2 ${sheet.checkbox_label}`} htmlFor="multiple_days"><span className="multipleDays"/><span>{Lang.multiple}</span><i className="iconCheck fas fa-check-circle"/></label>
            </div>
          </div>
        </div>
      </div>
      { leave_type === 2 && (
          <div className=" col-md-12">
            <div>
              <label>{Lang.type_of_half_day}</label><br/>
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" className="custom-control-input" id={'customRadio9'} name={'type_of_half_day'} onChange={handleDayHalfChange} checked={type_of_half_day===0} value={0}/>
                <label className={`custom-control-label ${sheet.radio_label}`} htmlFor="customRadio9"><span className={sheet.inner_radio_label}>{Lang.first_half}</span></label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" className="custom-control-input" id={'customRadio5'} name={'type_of_half_day'} onChange={handleDayHalfChange} value={1} checked={type_of_half_day===1}/>
                  <label className={`custom-control-label ${sheet.radio_label}`} htmlFor="customRadio5"><span className={sheet.inner_radio_label}>{Lang.second_half}</span></label>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default LeaveTypeSelector