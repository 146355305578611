import {RoleModel} from "./Role";
import {TranslatorModel} from "./Translator";
import {CustomerModel} from "./Customer";
import {TranslateLanguageModel} from "./TranslateLanguage";
import {CompanyModel} from "./Company";
import {DepartmentModel} from "./Department";
import {OrderModel} from "./Order";
import {TranslationTypeModel} from "./TranslationType";

export interface UserModel {
  id?: number;
  role_id?: number|string;
  name: string;
  email: string;
  distance?: number | null; // This is generated&used for the translator table when filtering by distance, it doesn't exist on users nor translators
  mobile_no:string;
  contact_phone:string;
  password?: string;
  profile_image?: string;
  status?: number;
  address?:string;
  zip_code?:string;
  city?:string;
  lat?:string;
  lng?:string;
  created_at?: string;
  deleted_at?: string;
 registration_from?:string;
 created_by_user?: UserModel;
 is_online?:number;
 old_c_id?:number;
 old_t_id?:number;
 last_login?: string;
 last_login_platform:string;
 order_count?:number;
 ordercancel_count?:number;
 orderpending_count?:number;
 orderactive_count?:number;
 ordercomplete_count?:number;
 translator_totalearning_count?:number;
 translator_orderactive_count?:number;
 translator_ordercomplete_count?:number;
 translator_order_count?:number;
 user_code?:number;
  country_code?: string;
  gender?: string|number;
  dob?: string;
  is_company?: number;
  company_id?: boolean;
  //translator_languages?: TranslateLanguageModel;
  translator_languages?: any;
  block_reason?: string;
  blocked_from_login?: boolean
  country_iso?:string;
  about?:string;

  role?: RoleModel;
  translator?: TranslatorModel;
    translator_translation_type?: Array<string>;
  customer?: CustomerModel;
  company?: CompanyModel;
  icon?:string;

  is_busy?: number // only for assign translator
  rating?: number // only for assign translator
  totalreviews?: number // only for assign translator
  on_holiday?: number // only for assign translator

  email_verified_at: string;
  phone_verified_at: string;
  orderInfo?:OrderModel;
  ordercomplete?:OrderModel;

  fav?: number; // only show in assign trans popup to show fav trans at first,
  total_distance?: number;
  travel_time?: number;
  exp_scale?: number;


  last_order_invite?: number | null
}

export const AdminUserFormData = (data? : UserModel) => {
  return {
    role_id: data !== undefined ? data.role_id : '',
    name: data !== undefined ? data.name : '',
    email: data !== undefined ? data.email : '',
    password: '',
    cn_password: '',
    mobile_no: data !== undefined ? data.mobile_no : '',
    contact_phone: data !== undefined ? data.contact_phone : '',
    country_code: data !== undefined ? data.country_code : '45',
  }
};

export enum Gender {
  male = 1,
  female = 2
}