import React, {useState,useEffect} from 'react';
import {Button, Modal, ModalProps} from "react-bootstrap";
import API from "../../common/AxoisClient";
import Lang from "../../common/lang/Lang";
import {Link} from "react-router-dom";
import {Icons} from "../../common/Icon";
import {UserModel} from "../../model";
import {Sendemail} from "../../services";
import _ from "lodash";
import {UserHelper} from "../../helpers/UserHelper";
interface InfoProps extends ModalProps{
  userinfo: UserModel
}

export function CustomerInfo (props: InfoProps) {

  const [userInfo,setUserInfo] = useState(props.userinfo);

  useEffect(()=>{
    if((userInfo!=undefined && userInfo.id != props.userinfo.id) && props.show === true ) {
      API.get("users/customers/order-count/"+props.userinfo.id).then(res => {
        setUserInfo(res.data.userInfo)
      });
    }
 });

  if(props.userinfo === undefined || userInfo === undefined) {
    return null;
  }

  return(
   
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {Lang.customer_info}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-xl-12">
            <div className="kt-portlet kt-portlet--height-fluid">
              <div className="kt-portlet__body">
                <div className="kt-widget kt-widget--user-profile-3">
                  <div className="kt-widget__top">
                    <div className="kt-widget__media kt-hidden-">
                      <img src={userInfo.profile_image} alt="image"/>
                    </div>
                    <div className="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-boldest kt-font-light kt-hidden">
                      JM
                    </div>
                    <div className="kt-widget__content">
                      <div className="kt-widget__head">
                      <h3 className="kt-widget__title">
                      {!_.isEmpty(userInfo.name) &&
                     userInfo.name
                     } <span className="orerType">({( userInfo.user_code !== null ) ? userInfo.user_code : '--'})</span>
                      </h3>
                        <div className="kt-widget__action">
                          <button type="button" className="btn btn-label-success btn-sm btn-upper" onClick={() => Sendemail(userInfo.id)} >{Lang.send_login_d_btn}</button>
                        </div>
                      </div>
                      <div className="kt-widget__subhead">
                        <Link to={'#'}><i className={Icons.email}/>{userInfo.email}</Link>
                        <Link to={'#'}><i className={Icons.mobile_no}/>{userInfo.mobile_no}</Link>
                        <Link to={'#'}><i className={Icons.address}/>{userInfo.address}</Link>
                      </div>
                      
                      <div className="kt-widget__info">
                        <div className="kt-widget__desc">
                          {Lang.company} - { UserHelper.getCompany(userInfo) }<br/>
                          {Lang.department} -  { UserHelper.getDept(userInfo) }
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="kt-widget__bottom">

                    

                    <div className="kt-widget__item">
                      <div className="kt-widget__icon">
                        <i className="flaticon-file-2"/>
                      </div>
                      <div className="kt-widget__details">
                        <span className="kt-widget__title">{Lang.total_order}</span>
                           <span className="kt-widget__value">{userInfo.order_count}</span>
                      </div>
                    </div>

                    <div className="kt-widget__item">
                      <div className="kt-widget__icon">
                        <i className="flaticon-file-2"/>
                      </div>
                      <div className="kt-widget__details">
                        <span className="kt-widget__title">{Lang.active_order}</span>
                        <span className="kt-widget__value">{userInfo.orderactive_count}</span>
                      </div>
                    </div>

                    <div className="kt-widget__item">
                      <div className="kt-widget__icon">
                        <i className="flaticon-file-2"/>
                      </div>
                      <div className="kt-widget__details">
                        <span className="kt-widget__title">{Lang.pending_order}</span>
                        <span className="kt-widget__value">{userInfo.orderpending_count}</span>
                      </div>
                    </div>

                    <div className="kt-widget__item">
                      <div className="kt-widget__icon">
                        <i className="flaticon-file-2"/>
                      </div>
                      <div className="kt-widget__details">
                      <span className="kt-widget__title">{Lang.cancel_order}</span>
                        <span className="kt-widget__value">{userInfo.ordercancel_count}</span>
                      </div>
                    </div>

                    <div className="kt-widget__item">
                      <div className="kt-widget__icon">
                        <i className="flaticon-file-2"/>
                      </div>
                      <div className="kt-widget__details">
                        <span className="kt-widget__title">{Lang.complete_order}</span>
                        <span className="kt-widget__value">{userInfo.ordercomplete_count}</span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{Lang.close}</Button>
      </Modal.Footer>
    </Modal>
  )
};