import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Axios from "../../../../common/AxoisClient";
import style from "./SkanderborgFeedback.module.scss";
import Feedback from "./components/Feedback";
import OrderInfo from "./components/OrderInfo";

interface Props {
  orderId: number;
}

interface State {
  id: number;
  order_no: string;
  order_date: string;
  order_from: string;
  order_to: string;
  translate_by: {
    id: number;
    name: string;
  };
  language_from: {
    id: number;
    language_name: string;
    country: {
      id: number;
      name: string;
    };
  };
  language_to: {
    id: number;
    language_name: string;
    country: {
      id: number;
      name: string;
    };
  };
  translation_type: {
    id: number;
    title: string;
  };
}
const flagUrl = (name: string) =>
  `https://www.countryflags.com/wp-content/uploads/${name
    .split(" ")
    .join("-")
    .toLowerCase()}-flag-png-large.png`;
function SkanderborgFeedback({ orderId }: Props) {
  const [State, setState] = useState<State | undefined>(undefined);
  useEffect(() => {
    (async () => {
      const orderData = (await Axios.get(`orders/get-skanderborg-feedback-data/${orderId}`)).data;
      setState(orderData);
    })();
  }, []);

  return (
    <div className={style["container"]}>
      {!State ? (
        <Spinner animation="border" />
      ) : (
        <>
          <div className={style["top-content"]}>
            <OrderInfo
              orderNo={State.order_no}
              customerName={State.translate_by.name}
              orderDate={State.order_date}
              orderFrom={State.order_from}
              orderTo={State.order_to}
              fromCountryName={State.language_from.country.name}
              fromLanguageName={State.language_from.language_name}
              toCountryName={State.language_to.country.name}
              toLanguageName={State.language_to.language_name}
              typeName={State.translation_type.title}
            />
          </div>
          <div className={style["divider"]} />
          <div className={style["bottom-content"]}>
            <h3>Order Feedback</h3>
            <div className={style["action"]}>
              <Feedback orderId={orderId} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SkanderborgFeedback;
