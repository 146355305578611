import * as React from "react";
import { CreateBreadcrumb } from "./Data";
import { CTranslatorState } from "../../model";
import { TranslationTypeModel, TranslateLanguageModel } from "../../model";
import Lang from "../../common/lang/Lang";
import API from "../../common/AxoisClient";
import {
  CommonSubmitButton,
  showNotification,
  Select,
  Breadcrumb,
  SwitchLoader,
  InlineLoader,
} from "../../common";
import { TranslatorValidations } from "./Validations";
import { RouteComponentProps } from "react-router";
import { getMapContent, GooglePlace } from "../../common/GooglePlace";
import {
  CountryFlags,
  LanguageFlags,
  Select2Wrapper,
} from "../../common/Select2";
import { LoaderType } from "../../common/Loader";
import { AxiosResponse } from "axios";
import { getLanguageList } from "../../services";
import * as _ from "lodash";

declare var window: any;

export class CreateTranslators extends React.Component<
  RouteComponentProps,
  CTranslatorState
> {
  state: CTranslatorState;
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      name: "",
      title: "",
      email: "",
      address: "",
      city: "",
      zip_code: "",
      gender: "",
      origin_country: "",
      mobile_no: "",
      verification_number: 0,
      country_code: "45",
      select_location: "",
      location: [],
      countries: [],
      locationList: [],
      is_prefilled: false,
      countrycodes: [],
      isEmailExists: 2,
    };
    TranslatorValidations();
    //this.countryCodeChange = this.countryCodeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event: React.ChangeEvent<any>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (name !== "select_location") {
      if (name === "is_certified" && value == 0) {
        this.setState({
          children_certificate: "",
          children_certificate_filename: "",
          filePath: "",
        } as any);
      }
      this.setState({
        [name]: value,
      } as any);
    } else {
      var location_value = $("#select_location").val();
      this.setState({
        location: location_value,
      } as any);
    }
  };

  handleCheckboxChange = (event: React.ChangeEvent<any>) => {
    const targetCheck = event.target;
    const checkValue = targetCheck.value;
    const name = targetCheck.name;
    if (event.target.checked === true) {
      this.setState({
        [name]: checkValue,
      } as any);
    } else {
      this.setState({
        [name]: 0,
      } as any);
    }
  };

  async componentDidMount() {
    API.get("users/translators/countries").then((res) => {
      this.setState(
        {
          countries: res.data.countryArr,
          countrycodes: res.data.countryCodeArr,
          locationList: res.data.locationArr,
        },
        () => {
          ($(".selectpicker") as any).selectpicker("refresh");
        }
      );
    });
  }

  handleSelectChange = (event: React.ChangeEvent<HTMLElement>) => {
    const targetSelect: any = event.target;
    const value = targetSelect.value;
    this.setState({
      country_code: value,
    } as any);
  };

  checkEmail = () => {
    var email = this.state.email;
    let re =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (this.state.email !== "" && re.test(email)) {
      SwitchLoader(LoaderType.INLINE);
      API.get(`users/translator/check-email-exists`, {
        params: { email: email },
      }).then((res: AxiosResponse) => {
        if (res.data.status === 0) {
          this.setState({
            isEmailExists: 0,
          });
        } else {
          this.setState({
            isEmailExists: 1,
          });
        }
      });
    } else {
      this.setState({
        isEmailExists: 2,
      });
    }
  };

  handleSubmit(event: any) {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if ($("#create_translator").valid() && this.state.isEmailExists == 0) {
      console.log("post values", this.state);
      API.post("users/translators", this.state).then((response) => {
        showNotification("success", Lang.translator_created);
        this.props.history.push(
          `/users/translators/info/` + response.data.translator_id
        );
      });
    }
  }

  googleGetAddress = (place) => {
    const { address, city, lat, lng, zip_code } = getMapContent(place);
    this.setState({
      lat: lat,
      lng: lng,
      address: address,
      zip_code: zip_code,
      city: city,
    });
  };

  DocumentUpload(event: any) {
    const name = event.target.name;
    let formData = new FormData();
    const dFileName = event.target.files[0].name;
    if (name === "children_certificate") {
      formData.append("document_name", name);
      formData.append("orignal_filename", dFileName);
      formData.append("children_certificate", event.target.files[0]);
    }
    API.post(`users/translators/certificate-upload`, formData).then(
      (res: AxiosResponse) => {
        this.setState({
          children_certificate: res.data.filename,
          children_certificate_filename:
            res.data.children_certificate_org_filename,
          filePath: res.data.filePath,
        } as any);
      }
    );
  }

  resetForm = () => {
    this.setState({
      name: "",
      title: "",
      email: "",
      address: "",
      city: "",
      zip_code: "",
      gender: "",
      origin_country: "",
      mobile_no: "",
      verification_number: 0,
      country_code: "45",
      select_location: "",
      location: [],
      countries: [],
      locationList: [],
      is_prefilled: false,
      countrycodes: [],
      isEmailExists: 2,
      police_verified: 0,
      driving_license: 0,
      own_vehicle: 0,
    });
  };

  render() {
    const countryCodeList = this.state.countrycodes;
    
    return (
      <div>
        <Breadcrumb data={CreateBreadcrumb} />

        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet kt-portlet--mobile">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-portlet">
                  {/* Card header */}
                  <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                      {/* Title */}
                      <h3 className="kt-portlet__head-title">
                        {Lang.add_new_translator}
                      </h3>
                    </div>
                  </div>
                  <br />
                  <form
                    className="kt-form"
                    onSubmit={this.handleSubmit}
                    id={"create_translator"}
                  >
                    <div className="kt-portlet__body">
                      <div className={"row"}>
                        {/* Name field */}
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <label>
                              {Lang.name} <span className={"required"}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              onChange={this.handleChange}
                              value={this.state.name || ""}
                              placeholder={Lang.getString(`name_placeholder`)}
                            />
                          </div>
                        </div>

                        {/* Email field */}
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <label>
                              {Lang.email} <span className={"required"}>*</span>
                            </label>
                            <InlineLoader>
                              <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                onChange={this.handleChange}
                                value={this.state.email || ""}
                                placeholder={Lang.getString(
                                  `email_placeholder`
                                )}
                                onBlur={this.checkEmail}
                              />
                            </InlineLoader>

                            {this.state.isEmailExists === 1 && (
                              <>
                                <label
                                  id="email-exists-error"
                                  className={"error"}
                                  style={{ color: "red" }}
                                >
                                  {Lang.email_already_register}
                                </label>
                              </>
                            )}
                          </div>
                        </div>

                        {/* Address field */}
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <label>
                              {Lang.address}{" "}
                              <span className={"required"}>*</span>
                            </label>
                            {/*<input type="text" className="form-control" id="address" name="address"
                                                               onChange={this.handleChange} value={this.state.address} placeholder={Lang.getString(`address_placeholder`)}/>*/}
                            <GooglePlace
                              onChooseAddress={this.googleGetAddress}
                              name={"address"}
                              readOnly={this.state.is_prefilled}
                              className="form-control"
                              placeholder={Lang.getString(
                                `address_placeholder`
                              )}
                            />
                          </div>
                        </div>

                        {/* City field */}
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <label>
                              {Lang.city} <span className={"required"}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              name="city"
                              value={this.state.city}
                              readOnly={this.state.is_prefilled}
                              onChange={this.handleChange}
                              placeholder={Lang.getString(`city_placeholder`)}
                            />
                          </div>
                        </div>

                        {/* Zip code field */}
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <label>
                              {Lang.zip_code_translator}{" "}
                              <span className={"required"}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="zip_code"
                              value={this.state.zip_code}
                              name="zip_code"
                              onChange={this.handleChange}
                              readOnly={this.state.is_prefilled}
                              placeholder={Lang.getString(
                                `zip_code_trans_placeholder`
                              )}
                            />
                          </div>
                        </div>

                        {/* Location field */}
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group ">
                            <label>
                              {Lang.location}{" "}
                              <span className={"required"}>*</span>
                            </label>
                            <Select
                              onChange={this.handleChange}
                              className="form-control"
                              id="select_location"
                              name="select_location"
                              multiple
                            >
                              <option value="" disabled>
                                {Lang.select}
                              </option>
                              {this.state.locationList &&
                                this.state.locationList.length > 0 &&
                                this.state.locationList.map(
                                  (locations, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={locations.value}
                                      >
                                        {locations.text}
                                      </option>
                                    );
                                  }
                                )}
                            </Select>
                          </div>
                        </div>

                        {/* Phone number field */}
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group ">
                            <label>
                              {Lang.telephone_number}{" "}
                              <span className={"required"}>*</span>
                            </label>
                            <div className={"row"}>
                              <div className={"col-md-4 col-lg-4"}>
                                <Select2Wrapper
                                  value={this.state.country_code || ""}
                                  onChange={this.handleSelectChange}
                                  className={"form-control"}
                                  id={"country_code"}
                                  name={"country_code"}
                                  data={{
                                    placeholder: "Select Options",
                                    data: this.state.countrycodes,
                                    templateResult: CountryFlags,
                                    templateSelection: CountryFlags,
                                  }}
                                />
                                {/*<select className="form-control kt-select2" id="country_code" name="country_code">
                                                                    <option value="">Select</option>
                                                                    {
                                                                        this.state.countrycodes &&
                                                                        this.state.countrycodes.length > 0 &&
                                                                        this.state.countrycodes.map(countrycodes => {
                                                                            return <option key={countrycodes.id} title={countrycodes.iso}  value={countrycodes.value}>{countrycodes.label}</option>;
                                                                        })
                                                                    }
                                                                </select>*/}
                              </div>
                              <div className={"col-md-8 col-lg-8"}>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="mobile_no"
                                  value={this.state.mobile_no || ""}
                                  name="mobile_no"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(
                                    `telephone_placeholder`
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Field 1 */}
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>
                                  {Lang.driver_license}{" "}
                                  <span className={"required"}>*</span>
                                </label>
                                <select
                                  id="driving_license"
                                  value={this.state.driving_license || ""}
                                  name="driving_license"
                                  onChange={this.handleChange}
                                  className="form-control selectpicker"
                                >
                                  <option value="">{Lang.select}</option>
                                  <option value={1}>
                                    {Lang.own_transport}
                                  </option>
                                  <option value={0}>
                                    {Lang.other_transport}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Field 2 */}
                        {this.state.driving_license == 1 && (
                          <div className={"col-md-6 col-lg-6"}>
                            <div className="form-group">
                              <div className={"row"}>
                                <div className={"col-md-12"}>
                                  <label>
                                    {Lang.vehicle}{" "}
                                    <span className={"required"}>*</span>
                                  </label>

                                  <Select
                                    className="form-control"
                                    id="own_vehicle"
                                    value={this.state.own_vehicle || ""}
                                    name="own_vehicle"
                                    onChange={this.handleChange}
                                  >
                                    <option value="">{Lang.select}</option>
                                    <option value={1}>{Lang.yes}</option>
                                    <option value={0}>{Lang.no}</option>
                                  </Select>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Field 3 */}
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>
                                  {Lang.police_confirmed}{" "}
                                  <span className={"required"}>*</span>
                                </label>
                                <Select
                                  id="police_verified"
                                  value={this.state.police_verified || ""}
                                  name="police_verified"
                                  onChange={this.handleChange}
                                  className="form-control"
                                >
                                  <option value="">{Lang.select}</option>
                                  <option value={1}>
                                    {Lang.police_confirmed_yes}
                                  </option>
                                  <option value={0}>
                                    {Lang.police_confirmed_no}
                                  </option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Field 4 */}
                        {this.state.police_verified == 1 && (
                          <div className={"col-md-6 col-lg-6"}>
                            <div className="form-group">
                              <div className={"row"}>
                                <div className={"col-md-12"}>
                                  <label>
                                    {Lang.verification_number}{" "}
                                    <span className={"required"}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="verification_number"
                                    name="verification_number"
                                    value={this.state.verification_number || ""}
                                    onChange={this.handleChange}
                                    placeholder={
                                      Lang.verification_number_placeholder
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Field 5 */}
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>
                                  {Lang.sex}{" "}
                                  <span className={"required"}>*</span>
                                </label>
                                <select
                                  id="gender"
                                  value={this.state.gender || ""}
                                  name="gender"
                                  onChange={this.handleChange}
                                  className="form-control selectpicker"
                                >
                                  <option value="">{Lang.select}</option>
                                  <option value={1}>
                                    {Lang.translator_male}
                                  </option>
                                  <option value={2}>
                                    {Lang.translator_female}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Field 6 */}
                        <div className={"col-md-6 col-lg-6"}>
                          <div className={"form-group"}>
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>
                                  {Lang.country_of_origin}{" "}
                                  <span className={"required"}>*</span>
                                </label>
                                <select
                                  className={"form-control selectpicker"}
                                  onChange={this.handleChange}
                                  id="origin_country"
                                  name="origin_country"
                                >
                                  <option value={""}>{Lang.select}</option>
                                  {this.state.countries &&
                                    this.state.countries.length > 0 &&
                                    this.state.countries.map((countries) => {
                                      return (
                                        <option
                                          key={countries.id}
                                          value={countries.id}
                                        >
                                          {countries.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Buttons */}
                    <div className="reset-btn-section">
                      {/* Submit */}
                      <CommonSubmitButton />
                      {/* Reset */}
                      <button
                        className={
                          "btn btn-default hvr-rectangle-out btnMove reset-btn"
                        }
                        data-skin={"dark"}
                        title={Lang.reset}
                        onClick={this.resetForm}
                      >
                        {Lang.reset}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
