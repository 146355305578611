import API from "../common/AxoisClient";
import {OrderModel,OrderFeedbackModel, OrderFeedbackEditModel} from "../model";
import {Subject} from "rxjs/index";
import {CustomerMailForm} from "../orders/orders/order-reducers/SendMailToCustomerReducer";
import {showConfirmBox, SwitchLoader, showNotification, LoaderType} from "../common";
import {TranslatorMailForm, ActionType} from "../orders/orders/order-reducers/SendMailToTransReducer";
import {CancelAction} from "../orders/orders/order-reducers/CancelOrderReducer";
import {default as Lang} from "../common/lang/Lang";
import moment from 'moment';

import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
import _ from "lodash";
import { CreateOrderInterface } from "../users/customers/orders/OrderForm";
let fileDownload = require('js-file-download');

// let moment = require('moment');

// send mail to customer popup service
const subject = new Subject();
export const SendCustomerMailPopupService = {
  showPopup: (data: OrderModel) => subject.next( {show: true, data: data } ),
  hidePopup: () => subject.next({show: false, data: [] }),
  getPopupState: () => subject.asObservable()
};

const Ordertelmessage = new Subject();
export const SeeOrdertelmessageService = {
  showPopup: (data: OrderModel) =>  Ordertelmessage.next( {show: true, orders: data  } ),
  hidePopup: () => Ordertelmessage.next({show: false, data: [] }),
  getPopupState: () => Ordertelmessage.asObservable()
};

const CustomerEmailmessage = new Subject();
export const SeeCustomerEmailService = {
  showPopup: (data: OrderModel) =>  CustomerEmailmessage.next( {show: true, data: data  } ),
  
  hidePopup: () => CustomerEmailmessage.next({show: false, data: [] }),
  getPopupState: () => CustomerEmailmessage.asObservable()
};

const notificationnewlist = new Subject();
  export const NotificationListNewService = {
    notificationlistdata: () =>notificationnewlist.next(),
   getPopupState: () => notificationnewlist.asObservable()
    };

const ordersinfo = new Subject();
  export const OrdersinfoService = {
    ordersinfodata: (data: OrderModel) =>ordersinfo.next({show: true, data: data}),
   getPopupState: () => ordersinfo.asObservable()
    };

// send mail to translator popup service
const TransPopSubject = new Subject();
export const SendTransMailPopSubjectService = {
  showPopup: (data: OrderModel) => TransPopSubject.next( {show: true, data: data, action: ActionType.SHOW_POPUP } ),
  hidePopup: () => TransPopSubject.next({show: false, data: [], action: ActionType.HIDE_POPUP}),
  getPopupState: () => TransPopSubject.asObservable()
};

// cancel order popup service

const CancelOrderSubject = new Subject();
export const CancelOrderService = {
  showPopup: (data: OrderModel, is_charge: boolean) => {
    CancelOrderSubject.next( {show: true, data: data, action: CancelAction.SHOW_POPUP, is_charge: is_charge } )
  },
  hidePopup: () => CancelOrderSubject.next({show: false, data: [], action: ActionType.HIDE_POPUP, is_charge: false}),
  getPopupState: () => CancelOrderSubject.asObservable(),
};

// orders comments popup service
const OrderCommentSubject = new Subject();
export const OrderCommentService = {
  showPopup: (data: OrderModel) => {
    getComments(data.id).then(res => {
      OrderCommentSubject.next( {show: true, data: data, action: CancelAction.SHOW_POPUP, comments: res } )
    })
  },
  hidePopup: () => OrderCommentSubject.next({show: false, data: [], action: ActionType.HIDE_POPUP, comments: []}),
  getPopupState: () => OrderCommentSubject.asObservable(),
};


// orders fix price popup service
const FixPriceSubject = new Subject();
export const FixPriceSubjectService = {
    showPopup: (data: OrderModel, from: string) => getFixOrderRates(data.id).then(res => {
        FixPriceSubject.next({show:true, data:data, action:CancelAction.SHOW_POPUP, fixPrice:res, from:from})
        }),
    hidePopup: () => FixPriceSubject.next({show: false, data: [], action: ActionType.HIDE_POPUP, fixPrice: []}),
    getPopupState: () => FixPriceSubject.asObservable(),
  
};

// orders fix price popup service
const FixPriceCalculationSubject = new Subject();
export const FixPriceCalculationSubjectService = {
    showPopup: (data: OrderModel, from: string, translator_id:number, id:number) => getTranslatorFixOrderRates(data.id, translator_id, id).then(res => {
        FixPriceCalculationSubject.next({show:true, data:data, action:CancelAction.SHOW_POPUP, fixPrice:res, from:from})
        }),
    hidePopup: () => FixPriceCalculationSubject.next({show: false, data: [], action: ActionType.HIDE_POPUP, fixPrice: []}),
    getPopupState: () => FixPriceCalculationSubject.asObservable(),
  
};

// orders call log popup service
const CalllogSubject = new Subject();
export const CalllogSubjectService = {
  showPopup: (data: OrderModel) => CalllogSubject.next( {show: true, data: data, action: CancelAction.SHOW_POPUP }),
  hidePopup: () => CalllogSubject.next({show: false, data: [], action: ActionType.HIDE_POPUP, comments: []}),
  getPopupState: () => CalllogSubject.asObservable(),
};

// orders history popup service
const OrderHistorySubject = new Subject();
export const OrderHistoryService = {
  showPopup: (data: OrderModel) => {
    getHistory(data.id).then(res => {
      OrderHistorySubject.next( {show: true, data: data, action: CancelAction.SHOW_POPUP, history: res } )
    })
  },
  hidePopup: () => OrderHistorySubject.next({show: false, data: [], action: ActionType.HIDE_POPUP, comments: []}),
  getPopupState: () => OrderHistorySubject.asObservable(),
};

// orders feedback by customer
 const OrderfeedbackSubject = new Subject();
export const OrderfeedbackService = {
  showPopup: (data: OrderModel) => {
    getfeedback(data.id).then(res => {
      OrderfeedbackSubject.next( {show: true, data: data, action: CancelAction.SHOW_POPUP, history: res } )
    })
   },
  hidePopup: () => OrderfeedbackSubject.next({show: false, data: [], action: ActionType.HIDE_POPUP}),
  getPopupState: () => OrderfeedbackSubject.asObservable(),
};


// orders feedback by customer
const OrderfeedbackEditSubject = new Subject();
export const OrderfeedbackEditService = {
  showEditPopup: (data: OrderFeedbackEditModel) => {
    OrderfeedbackService.hidePopup();
    getfeedback(data.id).then(res => {
      OrderfeedbackEditSubject.next({show: true, data: data, action: CancelAction.SHOW_POPUP, history: res } )
     })
   },
  hidePopup: () => OrderfeedbackEditSubject.next({show: false, data: [], action: ActionType.HIDE_POPUP}),
  getPopupState: () => OrderfeedbackEditSubject.asObservable(),
};
 


// orders history popup service
const OrderCompleteSubject = new Subject();
export const OrderCompleteService = {
  showPopup: (data: OrderModel) => OrderCompleteSubject.next( {show: true, data: data, action: CancelAction.SHOW_POPUP }),
  hidePopup: () => OrderCompleteSubject.next({show: false, data: [], action: ActionType.HIDE_POPUP, comments: []}),
  getPopupState: () => OrderCompleteSubject.asObservable(),
};
// orders correction request popup service
const OrderCorrectionSubject = new Subject();
export const OrderCorrectionService = {
  showPopup: (data: OrderModel, type: string) =>{
    getEditRequest(data.id).then(res => {
      OrderCorrectionSubject.next( {show: true, data: data, action: CancelAction.SHOW_POPUP, history: type === 'edit' ? res.edit_request : res.cancel !== null ? [res.cancel]: [] , type: type})
    })
  } ,
  hidePopup: () => OrderCorrectionSubject.next({show: false, data: [], action: ActionType.HIDE_POPUP, comments: []}),
  getPopupState: () => OrderCorrectionSubject.asObservable()
};


// get order info
export const getOrderInfo = async (order_id) => {
  const res  = await  API.get(`orders/orders/${order_id}`);
  return res.data;
};

// send mail to customer
export const sendCustomerMail = async (order_id: number, mail: CustomerMailForm) => {
  SwitchLoader(LoaderType.BUTTON);
  const res  = await  API.post(`orders/orders/confirmation-mail-to-customer/${order_id}`, mail);
  return res.data;
};


// send mail or sms to translator
export const createTransContact = async (order_id: number, mail: TranslatorMailForm) => {
  SwitchLoader(LoaderType.BUTTON);
  const res  = await  API.post(`orders/orders/confirmation-mail-to-translator/${order_id}`, mail);
  return res.data;
};

// cancel order
export const submitCancelRequest = async (order_id: number, form) => {
  SwitchLoader(LoaderType.BUTTON);
  const res  = await  API.post(`orders/orders/cancel-order/${order_id}`, form);
  return res.data;
};

// submit comment
export const submitComment = async (order_id: number, form) => {
  SwitchLoader(LoaderType.BUTTON);
  const res  = await  API.post(`orders/orders/order-comments/${order_id}`, form);
  return res.data;
};

// get comments
export const getComments = async (order_id: number) => {
  const res  = await  API.get(`orders/orders/order-comments/${order_id}`);
  return res.data;
};

// get comments
export const getHistory = async (order_id: number) => {
  const res  = await  API.get(`orders/orders/history/${order_id}`);
  return res.data;
};

// get feedback
export const getfeedback = async (order_id: number) => {
  const res  = await  API.get(`orders/orders/orderfeedback/${order_id}`);
  return res.data;
};

// get feedback
export const setfeedback = async (order_id: number, formData) => {
  SwitchLoader(LoaderType.BUTTON);
  const res  = await  API.post(`orders/orders/updateOrderfeedback/${order_id}`, formData.form);
  return res.data;
};

// set price
export const setPrice = async (order_id: number, form) => {
  SwitchLoader(LoaderType.BUTTON);
  const res  = await  API.post(`orders/orders/set-price/${order_id}`, form);
  return res.data;
};

// set price
export const fixOrderRates = async (order_id: number, translator_id:number, form) => {
  SwitchLoader(LoaderType.BUTTON);
  const res  = await  API.post(`orders/orders/order-fix-rates/${order_id}/${translator_id}`, form);
  return res.data;
};

// set price
export const getFixOrderRates = async (order_id: number) => {
  SwitchLoader(LoaderType.BUTTON);
  const res  = await  API.get(`orders/orders/order-get-fix-rates/${order_id}`);
  return res.data;
};


// get price by translator
export const getTranslatorFixOrderRates = async (order_id: number, translator_id:number, id:number) => {
  SwitchLoader(LoaderType.BUTTON);
  const res  = await  API.get(`orders/orders/get-translator-fix-rates/${order_id}/${translator_id}/${id}`);
  return res.data;
};




// restore order
export  function restoreOrder(props) {
  showConfirmBox(Lang.restore_order, async () => {
    const res  = await  API.post(`orders/orders/restore-order`, {order_id: props.order.id});
    props.refresh();
    showNotification('success', res.data);
  })
}


//; complete order
export function _completeOrder(order_id, form) {
  SwitchLoader(LoaderType.BUTTON);
  return showConfirmBox(Lang.complete_order_conform, async () => {
    const res = await API.post(`orders/orders/complete-order/${order_id}`, form);
    return res.data;
  })
}

// upload order file
export async function uploadOrderFile(formData: any) {
  const res = await API.post(`orders/orders/uploadDocument`, formData);
  return res.data;
}
// restore order
export  function changeToActiveOrder(props) {
  showConfirmBox(Lang.restore_order, async () => {
    const res  = await  API.post(`orders/orders/change-to-active`, {order_id: props.order.id});
    props.refresh();
    showNotification('success', res.data);
  })
}

export async function getOrderForCal(info, successCallback, failureCallback) {
  const res = await API.get(`orders/orders/get-orders-cal`, {params: {start:  moment(info.start.valueOf()).format('Y-M-D'), end:  moment(info.end.valueOf()).format('Y-M-D')}});
  setTimeout(function () {
    $('.fc-day-number').css('color','#000');
  },100);
  return successCallback(res.data);
}


export async function getOrderForCancelAndRequestCal(info, successCallback, failureCallback) {
  const res = await API.get(`orders/orders/get-order-for-cancel-and-request-change-cal`, {params: {start:  moment(info.start.valueOf()).format('Y-M-D'), end:  moment(info.end.valueOf()).format('Y-M-D')}});
  setTimeout(function () {
    $('.fc-day-number').css('color','#000');
  },100);
  return successCallback(res.data);
}

/**
 * show order telephone message
 * @param {string} message
 */
export function showTelephoneMessage(message: string) {
  Swal.fire(
    Lang.the_message,
    message
  )
}

/**
 * show order note for office
 *
 * @param {string} message
 */
export function showOfficeNoteMessage(message: string, translator_note: string) {
  let string = '';
  if(!_.isEmpty(translator_note)){
    string += "<h4>Til tolken</h4>";
    string += translator_note;
  }

  if(!_.isEmpty(message)){
    string += "<h4>Til Bookingafdeling</h4>";
    string += message;
  }

  Swal.fire({
    html: string,  
  })
}

export function removeTranslator(order_id: number, translator:number, callback:(res?:any) => void) {

  return Swal.fire({
    title: Lang.reason_remove_translator,
    input: 'textarea',
    inputAttributes: {
      autocapitalize: 'off'
    },
    showCancelButton: true,
    confirmButtonText: 'Submit',
    showLoaderOnConfirm: true,
    preConfirm: async (message) => {
      return showConfirmBox(Lang.confirm_remove_translator, async () => {
        const res = await API.post(`orders/orders/remove-translator`, {order_id: order_id, message: message, translator_id: translator});
        showNotification('success', res.data);
        callback();
      })

    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {
   })
}

/**
 * get data of edit requests and cancel requests
 *
 * @param order_id
 * @returns {Promise<T>}
 */
export async function getEditRequest(order_id) {
  const res  = await  API.get(`orders/orders/correction-request/${order_id}`);
  return res.data;
}
/**
 * get data of edit requests and cancel requests
 *
 * @param order_id
 * @returns {Promise<T>}
 */
export async function completeEditRequest(order_id, formData) {
  SwitchLoader(LoaderType.BUTTON);
  const res  = await  API.post(`orders/orders/correction-request/${order_id}`, formData);
  return res.data;
}

/**
 * get list of already sent invitations to translators for any order
 *
 * @param order_id
 */
export async function getSentInvitations(order_id: number) {
  const res  = await  API.get(`orders/get-sent-invitations/${order_id}`);
  return res.data;
}


export async function translatorList(from: number, params: any, order?: CreateOrderInterface, user_id?: number, picker_type?: 'specific_translator' | 'not_specific_translator'){
  let param_data:any = _.pick(order, ['meeting_city','translation_type_id','is_dumb','order','from_language','to_language']);
  param_data.orders = param_data.order;
  param_data.user_id = user_id;
  SwitchLoader(LoaderType.NONE);
  const translator = await API.get('orders/orders/get-translator-list', {params: {...params, ...param_data, from: from}});
  const data = _.cloneDeep(translator.data)
  if ( picker_type !== undefined ) {
    data.results = data.results.filter((trans) => Number(trans.id) !== Number(order[picker_type === 'specific_translator' ? 'not_specific_translator_id' : 'specific_translator_id']))
  }
  return data;
}

/**
 * get list language and company for order filter.
 *
 */
export async function LanguageAndCompanyList() {
  const res  = await  API.get(`order-filter-data`);
  return res.data;
}


/**
 *
 * @param order_id
 * @returns {Promise<T>}
 */
export async function getDistanceTravelTime(order_id) {
  const res = await API.get('orders/orders/get-travel-distance', {params: {order_id: order_id}});
  return res.data.orderData;
}

export const updatePopupFlagForOrder = async (payload)  => {
  const {order_id} = payload;
  const res = await API.post(`/orders/orders/change-flag/${order_id}`, payload);
  return res.data;
}

export async function syncComdiaOrders() {
  const res = await API.post('orders/async-get-comdia-orders');
  return res.data.orderData; 
}

// submit comment
export const submitSpecialStatus = async (order_id: number, callback:(res?:any) => void) => {
  const res  = await  API.post(`orders/orders/change-special-status/${order_id}`, order_id);
  showNotification('success', 'Order special status changed successfully');
  callback();
};