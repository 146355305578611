import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { GooglePlace, getMapContent } from '../../../common/GooglePlace';
import Lang from '../../../common/lang/Lang';

const AddressInput = (props) => {
    const { name, label, ...fieldProps } = props;
    return (
        <div className="form-group col-lg-12">
            <label htmlFor={name}>
                {label}
                <span className={"required"}>*</span>
            </label>
            <Field
                name={name}
                id={name}
            >
                {
                    ({ field, form, meta }) => (
                        <GooglePlace
                            onChooseAddress={(place) => {
                                const { address, city, lat, lng, zip_code } = getMapContent(place);
                                form.setValues({
                                    ...form.values,
                                    address, city, lat, lng, zip_code
                                })
                                form.validateForm();
                            }}
                            className="form-control"
                            placeholder={Lang.getString('address_placeholder')}
                            {...fieldProps}
                            {...field}
                        />
                    )
                }
            </Field>
            <ErrorMessage render={msg => <div className="text-danger">{msg}</div>} name={name} />
        </div>
    );
}

export default AddressInput;
