import * as React from 'react';
import Lang from "../../common/lang/Lang";
import {EditBreadcrumb} from "./Data";
import API from './../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {DepartmentUnitModel,DepartmentModel,CompanyModel} from "../../model";
import {CreateLayout} from "../../layout/CreateLayout";
import {DepartmentValues} from "../../model/DepartmentUnit";
import {DepartmentValidations} from "./Validations";
import {LoaderType, showNotification, CommonSubmitButton,SwitchLoader,Select2Wrapper,CountryFlags, getMapContent, Select, GooglePlace} from "../../common";

interface IState{
  form: {
  
  name : string,
  notify_email : string,
  department_id ?: number,
  status? : number,
  ean_number?: number,
  address?: string,
  city?: string,
  country_code?: string,
  mobile_no?: number,
  zip_code?: number,
  location_code? : string,
  location_code_2?: number,
  latitude?: string,
  longitude?: string,
  
};
department : Array<any>;
company : Array<any>;
countries : Array<any>;
countrycodes : Array<any>;
id:number;
company_id:number;
uploadDocument?: File;
_address?: boolean;
  
}

export class DepartmentUnitEdit extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props: any){
    super(props);

    this.state = {
      form: DepartmentValues(),
      id: props.match.params.id,
      department :[],
      company:[],
      countries:[],
      countrycodes:[],
      company_id:0,
      uploadDocument : null,
      _address: false
    }
    DepartmentValidations();
  }
   
 
  componentDidMount() {
   
    API.get("settings/department-unit/companies-list").then(res => {
  
      this.setState({
        company: res.data
     })
    }); 
    

    API.get(`settings/department-unit/${this.state.id}`).then((res: any) => {
      
       this.setState({
        form: DepartmentValues(res.data),
        company_id:res.data.company.company_id,
        
      })
      this.department(res.data.company.company_id)
    })

    API.get("settings/department-unit/countries").then(res => {
      this.setState({
          countries: res.data.countryArr,
          countrycodes: res.data.countryCodeArr
      }, () => {
          ($(".selectpicker") as any).selectpicker('refresh');
      });
})
    
  }

  handleCompany = (e) => {
    this.setState({
     
      company_id:e.target.value,
      
    })
    this.department(e.target.value)
  }

  department($company_id){
    API.get(`settings/department-unit/companies-accordingto-department/${$company_id}`).then(res => {
      this.setState({
        department: res.data
     })
   });
   }

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

  googleGetAddress = (place) => {
    const {address, city, lat, lng, zip_code} =  getMapContent(place);
    this.setState(prevState => ({form: {...prevState.form, ...{ latitude:lat,
      longitude:lng,
      address: address,
      zip_code: zip_code, city: city}}}as any));
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#customer_form").valid()) {
      API.put(`settings/department-unit/${this.state.id}`, this.state.form, {params: {from: 'info'}}).then((res) => {
        showNotification("success", Lang.department_unit_update);
        this.props.history.push(`/settings/department-unit`);
      })
    }
  }
  
 

  render() {
    
    return (
    
      <CreateLayout breadcrumb={EditBreadcrumb} title={'department'}>
          
      <form className="kt-form" id={'customer_form'} onSubmit={this.handleSubmit}>
          <div className="kt-portlet__body">
              <div className={'row'}>
             <div className={"col-md-12 col-lg-12"}>
             <div className={'row'}>
                    <div className={"col-md-6 col-lg-6"}>
             <div className="form-group">
                             <label>{Lang.company} <span className={'required'}>*</span></label>
                             <Select className={'form-control'} onChange={(e)=>this.handleCompany(e)} value={this.state.company_id || ''} id={'company_id'} name={'company_id'}  >
                        <option value={''}>{'Select Comapny'}</option>
                       {this.state.company.map((item: CompanyModel, index: number) => {
                        return <option key={index} value={item.id}>{item.name}</option>
                      })}
                    </Select>
                </div>   
                </div> 
                <div className={"col-md-6 col-lg-6"}>
             <div className="form-group">
                             <label>{Lang.department} <span className={'required'}>*</span></label>
                             <Select className={'form-control'} onChange={this.handleChange} value={this.state.form.department_id || ''} id={'department_id'} name={'department_id'}  >
                        <option value={''}>{'Select department'}</option>
                       {this.state.department.map((item: DepartmentModel, index: number) => {
                        return <option key={index} value={item.id}>{item.name}</option>
                      })}
                    </Select>
                </div>   
                </div> 
                <div className={"col-md-6 col-lg-6"}>
                    <div className="form-group">
                                <label>{Lang.name}<span className={'required'}>*</span></label>
                                <input type={'text'} value={this.state.form.name} name="name" onChange={this.handleChange} className="form-control"  />
                     </div>
                     </div>
                     
                     <div className={"col-md-6 col-lg-6"}>
                    <div className="form-group">
                                <label>{Lang.location_code}</label>
                                <input type={'text'} value={this.state.form.location_code} name="location_code" onChange={this.handleChange} className="form-control"  />
                     </div>
                     </div>
                     <div className={"col-md-6 col-lg-6"}>
                    <div className="form-group">
                                <label>{Lang.location_code_2}</label>
                                <input type={'text'}  name="location_code_2"   value={this.state.form.location_code_2} onChange={this.handleChange} className="form-control"  />
                     </div>
                     </div>
                     <div className={"col-md-6 col-lg-6"}>
                    <div className="form-group">
                                <label>{Lang.ean_number}</label>
                                <input type={'text'}  name="ean_number" value={this.state.form.ean_number} onChange={this.handleChange} className="form-control"  />
                     </div>
                     </div>
                      
                     <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group ">
                            <label>{Lang.telephone_number} <span className={'required'}>*</span></label>
                            <div className={"row"}>
                                <div className={"col-md-4 col-lg-4"}>
                                    <Select2Wrapper   onChange={this.handleChange}
                                                    className={'form-control'} value={this.state.form.country_code || ''} id={'country_code'} name={'country_code'}
                                                    data={{
                                                        placeholder: "Select Options",
                                                        data: this.state.countrycodes,
                                                        templateResult: CountryFlags,
                                                        templateSelection: CountryFlags,
                                                    }}
                                    />
                          
                                </div>
                                <div className={"col-md-8 col-lg-8"}>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.mobile_no || ''} ref={input => {this[`mobile_no`] = input; }} name={'mobile_no'} className="form-control" placeholder={Lang.getString(`mobile_placeholder`)}/>
                                </div>
                            </div>
                        </div>
                       </div>
                     <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <label>{Lang.address} </label>
                          {!this.state._address &&
                            <GooglePlace onChooseAddress={this.googleGetAddress} name={'address'} defaultValue={this.state.form.address || ''} className="form-control" placeholder={Lang.getString(`address_placeholder`)} />
                          }
                          {this.state._address &&
                          <input name={'address'} ref={input => {this[`address`] = input; }} value={this.state.form.address || ''} onChange={() => { return;}}  className="form-control" placeholder={Lang.getString(`address_placeholder`)} />
                          }
                        </div>
                      </div>
                     
                     <div className={"col-md-6 col-lg-6"}>
                    <div className="form-group">
                                <label>{Lang.city}</label>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.city || ''} ref={input => {this[`city`] = input; }} name={'city'} className="form-control" placeholder={Lang.getString(`city_placeholder`)}/>
                     </div>
                     </div>
                     
                     <div className={"col-md-6 col-lg-6"}>
                    <div className="form-group">
                                <label>{Lang.zip_code}</label>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.zip_code || ''}  ref={input => {this[`zip_code`] = input; }} name={'zip_code'} className="form-control" placeholder={Lang.getString(`zip_code_placeholder`)}/>
                     </div>
                     </div>
                     </div>
              </div>
          </div>
          </div>
<CommonSubmitButton/>
      </form>
      
  </CreateLayout>

    )
  }
}