import React, {useState, useEffect} from 'react';
import { Modal, ModalProps} from "react-bootstrap";
import {languagelistService, FromTranslatorLanguage, ToTranslatorLanguage,languageAddService, languageUpdateService, getLanguageList} from "../../../services";
import Lang from "../../../common/lang/Lang";
 import API from "../../../common/AxoisClient";
import {LoaderType} from "../../../common/Loader";
import {TranslateLanguageModel,UserModel} from "../../../model";
 
import * as _ from 'lodash';
import {showNotification, SubmitButton, Select2Wrapper,LanguageFlags,SwitchLoader} from "../../../common";

 
interface InfoProps extends ModalProps{
  transInfo: TranslateLanguageModel,
 
}
 

 
export const LanguageUpdate = (props:any) => {
  const [show, setShow] = useState(false);
  const [Fromlanguage, setFromLanguage] = useState('');
  const [Tolanguage, setToLanguage] = useState('');
  const [laguagelist, setLaguagelist] = useState([]);
  const [checkfromlaguage, setCheckfromLaguage] = useState([]);
  const [checktolaguage, setChecktoLaguage] = useState([]);
  const [languageId, setLanguageId] = useState('');
  const [fromlaguagelist, setFromLaguagelist] = useState([]);
  const [tolaguagelist, setToLaguagelist] = useState([]);
  const [user, setUser] = useState({} as UserModel);
  const [id, setId] = useState(0);
  
  
   
  useEffect(() => {
    $(document).ready(function(){
      $('.modal').removeAttr("tabindex");
    });
    languageAddService.getPopupState().subscribe((res:any) => {
      
      //setLanguageId(res.language_id);
      //setFromLanguage(res.user.from_language_id);
      //setToLanguage(res.user.to_language_id);
      setLanguageId('');
      setFromLanguage('');
      setToLanguage('');
      setShow(res.show);
      setId(res.user);
    })
    languageUpdateService.getPopupState().subscribe((res:any) => {
      
      setLanguageId(res.language_id);
      setFromLanguage(res.user.from_language_id);
      setToLanguage(res.user.to_language_id);
      setShow(res.show);
       setId(res.user.user_id);
      
    })
    if(show){
       
      getLanguageList().then(res => {
        setLaguagelist(res);
        setToLaguagelist(res);
        setFromLaguagelist(res);
        var found = res.filter((la) => {return la.id != Fromlanguage});
        setToLaguagelist(found);
        var found = res.filter((la) => {return la.id != Tolanguage});
        setFromLaguagelist(found);
      })
     
     }
     
      
     FromTranslatorLanguage(props.transInfo.id).then(res => {
      setCheckfromLaguage(res)
    })

    ToTranslatorLanguage(props.transInfo.id).then(res => {
      setChecktoLaguage(res)
    })
    

  },[show]);

  if(show === false){
    return null;
  }

  function handleChange(event:any){
    if(event.target.name === 'from_language'){
    setFromLanguage(event.target.value);
     }
     if(event.target.name === 'to_language'){
       setToLanguage(event.target.value);
     }
    
    }
   
  function handleSubmit(event) {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
     API.post(`users/translator/translator-language-update`,{user_id:id,id:languageId,from_language:Fromlanguage,to_language:Tolanguage}).then(res => {
      languagelistService.languagedata();
      showNotification("success", Lang.translator_languages_update);
      setShow(false);
     })
  }

  return(
    
    <Modal
      show={show}
      onHide={() => setShow(false) }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {Lang.language}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
              <div className={'col-md-12'} >
              <div className={'form-group'}>
                <label>{Lang.from_language} <span className={'required'}>*</span></label>
                <Select2Wrapper className={'form-control'}  name={'from_language'}   required={true} value={Fromlanguage} onChange={(e)=>handleChange(e)}
                                        data={{
                                          placeholder: Lang.from_language,
                                          data: laguagelist,
                                          templateResult: LanguageFlags,
                                          templateSelection: LanguageFlags,
                                         
                                        }}
                        />

                </div>
                <div className={'form-group'}>
                   <label>{Lang.to_language} <span className={'required'}>*</span></label>
                <Select2Wrapper className={'form-control'} name={'to_language'}   required={true} value={Tolanguage || ''} onChange={(e)=>handleChange(e)}
                                        data={{
                                          data: tolaguagelist,
                                          templateResult: LanguageFlags,
                                          templateSelection: LanguageFlags,
                                          placeholder: Lang.to_language
                                        }}
                        />

                </div>
                </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
    
          <SubmitButton className={'btn btn-success'} label={Lang.send} />
          <button className={'btn btn-primary'} onClick={() =>  setShow(false) }>{Lang.close}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
