import Lang from "../../common/lang/Lang";
import {UserModel} from "../../model";

export const urls = {
  users: "/payouts/",
  list: "/payouts/pending",
}

export const ListBreadcrumb = [
  {
    label: Lang.payout,
    url: urls.users
  },
  {
    label: Lang.pending_payout,
    url: "#"
  }
];

export interface Payouts {
  id: number;
  total_order: number;
  total_work: number;
  total_amount: number;
  total_distance: number;
  total_travel_time: number;
  status: number;
  created_at: string;
  action: string;
  user: UserModel
}

export const PriceConstant = {
  price_status: [
    {value:"", label: Lang.select_price_type_string},
    {value:Lang.price_status_option_standard, label: Lang.standard},
    {value:Lang.price_status_option_special, label: Lang.special_status_yes},
    {value:Lang.price_status_option_fix_price, label: Lang.fix_price}
  ]
};

export const TranslatorTypeConstant = {
  translator_type: [
    {value:"", label: Lang.select},
    {value:2, label: Lang.private_translator},
    {value:1, label: Lang.public_translator}
  ]
}

export const FeeTypeConstant = {
  fee_type: [
    {value:"", label: Lang.select},
    {value:1, label:Lang.fee_type_1},
    {value:2, label:Lang.fee_type_2},
    {value:3, label:Lang.fee_type_3}
  ]
}

export const BookingStatus = {
  booking_status: [
    {value:"", label: Lang.select_booking_type_string},
    {value:1, label: Lang.completed},
    {value:2, label: Lang.cancelled_with_charge} 
  ]
}