import React, {useReducer} from 'react';
import {OrderModel} from "../../../model";

// all actions for assign translator
export enum CancelAction {
  SHOW_POPUP = 'SHOW_POPUP',
  HIDE_POPUP = 'HIDE_POPUP',
  HANDLE_CHANGE = 'HANDLE_CHANGE',
  RESET_POPUP_FORM = 'RESET_POPUP_FORM',
}

export interface CancelState  {
  show: boolean;
  order_data: OrderModel;
  order_id: number;
  form: {
    cancel_how: string;
    cancel_when: string;
    cancel_message: string;
    is_charge: boolean;
  }
}

export interface CancelProps  {
  refresh: () => void
}

export interface CAction {
  type: CancelAction;
  payload?: CancelState
}

export const cancelOrderInitialState: CancelState = {
  show: false,
  order_data: {} as OrderModel,
  order_id: 0,
  form: {
    cancel_how: '',
    cancel_when:'',
    cancel_message: '',
    is_charge: false
  }
};

export const orderInitialState: CancelState = {
  show: true,
  order_data: {} as OrderModel,
  order_id: 0,
  form: {
    cancel_how: '',
    cancel_when:'',
    cancel_message: '',
    is_charge: false
  }
};

// reducer for assign translator popup

export function CancelOrderReducer <CancelState, CAction > (state, action) {
  switch (action.type) {
    // show popup and set order data
    case CancelAction.SHOW_POPUP:
      return {...state, show: true, order_data:action.payload.data, order_id: action.payload.data.id, form: {...state.form, is_charge: action.payload.is_charge}};

    // hide popup and blank old state
    case CancelAction.HIDE_POPUP:
      return {...state, ...cancelOrderInitialState};

      // handle change of mail drop-down
    case CancelAction.HANDLE_CHANGE:
      const {name, value} = action.payload;
      return {...state, form: {...state.form, [name]: value}};


    case CancelAction.RESET_POPUP_FORM:
      return {...state, ...orderInitialState};

    default:
      throw new Error();
  }
}

export function CancelOrderValidations() {
  $(document).ready(function () {
    $( "#cancel_order" ).validate({

      rules: {
        cancel_how: "required",
        cancel_when: "required",
        cancel_message: "required",
      }
    });
  });
}