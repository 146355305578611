import {UserModel} from "./User";
import {OrderModel} from "./Order";
import Lang from "../common/lang/Lang";

export interface ClaimModel {
  id: number;
  order_no : string;
  creator_type : number;
  user_id : number;
  subject : string;
  reason : string;
  status : number;
  created_at : string;
  updated_at : string;
  order? : OrderModel;
  user? : UserModel;
}


export const ClaimStatus = {
  RESOLVED: 0,
  NEW_REQUEST: 1,
  REJECT: 2,
  ADMIN_REPLIED: 3,
  CUSTOMER_REPLIED: 4,
  TRANSLATOR_POINT_VIEW: 5,
};

export const ClaimStatusLabel = {
  [ClaimStatus.RESOLVED] : Lang.claim_status_resolved,
  [ClaimStatus.NEW_REQUEST] : Lang.claim_status_new,
  [ClaimStatus.REJECT] : Lang.claim_status_reject,
  [ClaimStatus.ADMIN_REPLIED] : Lang.claim_status_admin_replaed,
  [ClaimStatus.CUSTOMER_REPLIED] : Lang.claim_status_customer_replied,
};