import React, { PropsWithChildren, useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

// styles
import sheet from './FocusController.module.scss'


interface Props extends Partial<React.HTMLAttributes<HTMLDivElement>>{
  default?: 'expand' | 'contract'
  value: any

}
const FocusController = ({ default: defaultState, value, children, ...rest }: PropsWithChildren<Props>) => {
  const [Focus, setFocus] = useState<boolean>(defaultState === 'expand');
  const [Contractable, setContractable] = useState<boolean>(true)
  const [Hover, setHover] = useState<boolean>(false)

  // setters
  const focus = () => setFocus(true)
  const unfocus = () => setFocus(false)

  // listeners
  useEffect(() => {
    if (!isEmpty(value)) {
      if (Contractable) {
        setContractable(false)
      }
      focus()
    } else if (!Hover) {
      unfocus()
    }
  }, [value, Contractable, Hover])

  // event handlers
  const handleMouseLeave = () => {
    setHover(false)
    if (isEmpty(value) && !Contractable) {
      setContractable(true)
      unfocus()
    }
    if (Contractable) {
    }
  }

  const handleMouseEnter = () => {
    setHover(true)
    if (!Focus) {
      focus()
    }
    if (Contractable) {
      setContractable(false)
    }
  }

  const handleFocus = () => {
    if (!Focus && Contractable) {
      focus()
    }
  }

  const handleBlur = () => {
    if (Focus && Contractable) {
      unfocus()
    }
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...rest}
      className={`${rest.className || ''} ${sheet.container} ${Focus ? sheet.expand : sheet.contract}`}
    >
      { children }
    </div>
  )
}

export default FocusController
