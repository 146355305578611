import React from 'react'

// Styles
import sheet from './Table.module.scss'

const Heading = () => {
  return (
    <div className={sheet.heading_row}>
      <div></div>
      <div>Company</div>
      <div>Status</div>
      <div>Date</div>
      <div>Orders</div>
    </div>
  )
}

export default Heading