import Lang from "../../common/lang/Lang";

export const urls = {
  order: '/orders',
  orders: "/orders/orders",
};

export const ListBreadcrumb = [
  {
    label: Lang.orders,
    url: urls.order
  },
  {
    label: Lang.active_order_name,
    url: "#"
  }
];

export const RequestListBreadcrumb = [
  {
    label: Lang.orders,
    url: urls.order
  },
  {
    label: Lang.request_change,
    url: "#"
  }
];

export const UpdateOrderBreadcrumb = [
  {
    label: Lang.orders,
    url: urls.orders
  },
  {
    label: Lang.order_list,
    url: urls.orders
  },
  {
    label: Lang.update_order,
    url: "#"
  }
];

export const OrderInfoBreadcrumb = [
  {
    label: Lang.orders,
    url: urls.order
  },
  {
    label: Lang.order_list,
    url: urls.orders
  },
  {
    label: Lang.order_info,
    url: "#"
  }
];
export const CompleteListBreadcrumb = [
  {
    label: Lang.orders,
    url: urls.order
  },
  {
    label: Lang.complete_orders,
    url: "#"
  }
];
export const OrderInvoicingBreadcrumb = [
  { label: Lang.orders, url: urls.orders },
  { label: 'Fakturering', url: '#' }
]
export const CancelListBreadcrumb = [
  {
    label: Lang.orders,
    url: urls.order
  },
  {
    label: Lang.order_cancel,
    url: "#"
  }
];
export const OrderConst = {
  status: [
    {value:"", label: Lang.select},
    {value:1, label: Lang.order_new_order},
    {value:2, label: Lang.order_invitation_sent},
    {value:3, label: Lang.order_cancelled},
    {value:4, label: Lang.order_assigned},
    {value:5, label: Lang.order_complete},
  ],
  filter_status: [
    {value:"", label: Lang.VT_ADMIN_1},
    {value:1, label: Lang.order_new_order},
    {value:2, label: Lang.order_invitation_sent},
    {value:3, label: Lang.order_assigned},
  ],
  order_types: [
    { value: "", label: Lang.translation_type },
    { value: 1, label: "Telefon-tolkning" },
    { value: 2, label: "Fremmøde-tolkning" },
    { value: 3, label: "Video-tolkning" },
    { value: 5, label: "Telefonbesked Tolkning" },
    { value: 6, label: "Skriftlig Oversættelse" },
    { value: 7, label: "Sign Language" }
  ]
};
