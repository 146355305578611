import * as React from 'react';
import {Breadcrumb} from "../../common/page/Breadcrumb";
import { CreateBreadcrumb } from "./Data";
import Lang from "../../common/lang/Lang";
import API from './../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {LanguageValues} from "../../model/Language";
import {CountryModel} from "../../model/Country";
import {LanguageValidations} from "./Validation";
import {CreateLayout} from "../../layout/CreateLayout";
import {CommonSubmitButton} from "../../common/form/CommonSubmitButton";

declare var window: any;

interface IState{
  form: {
  country_id? : any,
  language_name? : string,
  };
 countries: Array<any>;
  id:number;

}

export class LanguageCreate extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props: any){
    super(props);

    this.state = {
      form: LanguageValues(),
      id: props.match.params.id,
      countries: []
    }
    LanguageValidations();
  }

  componentDidMount() {
    API.get('languages/language/getCountry').then((res: any) => {
      this.setState({
        countries: res.data
     })
    })
  }

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }


  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if($("#admin_user_form").valid()){
      API.post('languages/language', this.state.form).then((res) => {
         
      })
    }
  }

  render() {
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'add_admin_user'}>
          <form className="kt-form" id={'admin_user_form'} onSubmit={this.handleSubmit}>
            <div className="kt-portlet__body">
              <div className={'row'}>

                <div className={"col-md-12 col-lg-12"}>
                  <div className="form-group">
                    <label>{Lang.country} <span className={'required'}>*</span></label>
                    <select onChange={this.handleChange}  name={'country_id'} className="form-control" >
                      <option value={''}>{Lang.select_country}</option>
                      {this.state.countries && this.state.countries.map((item:CountryModel, index: number) => {
                        return <option value={item.id}>{item.name}</option>
                      })}
                    </select>
                  </div>
                </div>
                <div className={"col-md-12 col-lg-12"}>
                  <div className="form-group">
                    <label>{Lang.language} <span className={'required'}>*</span></label>
                    <input type={'text'} onChange={this.handleChange}  name={'language_name'} className="form-control" placeholder={Lang.getString(`name_placeholder`)}/>
                  </div>
                </div>

              </div>
            </div>
            <CommonSubmitButton/>
          </form>
        </CreateLayout>
    )
  }
}