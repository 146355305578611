import * as React from 'react';
import Lang from "../../../common/lang/Lang";
import {ActionLink,CONST} from "../../../common";
import {OrderProgress, OrderType,OrderStatus} from "../../../model";
import {order_to_language, order_time,get_order_gender_and_color,complete_order_time} from "../../../helpers/OrderHelper";
import {
    OrderCommentService, OrderCompleteService, SeeCustomerEmailService
} from "../../../services";
import {OrderActions,CompleteOrderActions,CancelOrderActions} from "../OrderActions";

import {
  AssignTranslator, SendMailToCustomer, SendMailToTranslator, CancelOrder, OrderComments, FixPrice,Calllog,
  HistoryPopup
} from "../popup";
import {CompleteOrderPopup} from "../popup/CompleteOrder";
import {CorrectionRequestPopup} from "../popup/CorrectionRequest";
import _ from "lodash";
const moment = require("moment");
declare let window:any;



export const OrderInfoHeader = (props?: any) => {



function OrdersStatus() {
    if(props.info.status === OrderStatus.NEW_ORDER ){
      return (<span className={'badge badge-primary'}>{Lang.order_new_order}</span>)
  } else if(props.info.status === OrderStatus.INVITATION_SENT){
    return  (<span className={'badge badge-primary'}>{Lang.order_invitation_sent}</span>)
  } else if(props.info.status === OrderStatus.TRANS_ASSIGNED) {
    return  (<span className={'badge badge-primary'}>{Lang.order_assigned}</span>)
  } else if(props.info.status === OrderStatus.CANCELLED){
    return (<span className={'badge badge-warning'}>{Lang.order_cancelled}</span>)
  } else if(props.info.status === OrderStatus.COMPLETED){
    return  (<span className={'badge badge-primary'}>{Lang.order_complete}</span>)
  } else if(props.info.status === OrderStatus.PAID){
    return  (<span className={'badge badge-primary'}>{Lang.order_paid}</span>)
  }  
}
  function Ordertype() {
    if(props.info.translation_type_id === OrderType.TELEPHONE ){
    return 'Telefon-tolkning'
  } else if(props.info.translation_type_id === OrderType.PHYSICAL){
    return 'Fremmøde-tolkning'
  } else if(props.info.translation_type_id === OrderType.VIDEO) {
    return 'Video-tolkning'
  } else if(props.info.translation_type_id === OrderType.SIGN_LANGUAGE){
    return 'Tegnsprog-tolkning'
  } else if(props.info.translation_type_id === OrderType.PHONE_MESSAGE){
    return 'Telefonbesked Tolkning'
  } else if(props.info.translation_type_id === OrderType.DOCUMENT){
    return 'Skriftlig Oversættelse'
  }
}

 
  return (
    
<div className="kt-portlet">

    <div className="kt-portlet__body">
      <div className="kt-widget kt-widget--user-profile-3">
        <div className="kt-widget__top">
          <div className="kt-widget__media kt-hidden-">
          <span className="kt-portlet__head-icon">
              <img src={!_.isEmpty(props.info.customer) && props.info.customer.profile_image_medium} alt={ !_.isEmpty(props.info.customer) && props.info.customer.name}/>
          </span>
          </div>                
          <div className="kt-widget__content">
            <div className="kt-widget__head">
            
              <h3 className="kt-widget__title">
              {!_.isEmpty(props.info.customer) &&
                props.info.customer.name
				
              }
             ({!_.isEmpty(props.info.customer) &&
              props.info.customer.email
              } )	<span className="orerType">({ Ordertype()})</span>
                </h3>
            
            
              <div className="kt-widget__action InfoOrdersRight">
			  { props.info.status !== 5 &&  props.info.status !== 4  && props.info.status !== 6 &&   
             <OrderActions order={props.info} refresh={'info'}/>
           } 
             { props.info.status === 5 &&  
             <CompleteOrderActions order={props.info} refresh={void('')} type={'info'}/>
             }
             { props.info.status === 6 &&  
             <CompleteOrderActions order={props.info} refresh={void('')} type={'info'}/>
             }
            { props.info.status === 4 &&
            <CancelOrderActions order={props.info} refresh={void('')} type={'info'}/>
             }
             &nbsp;
			
              <ActionLink className="btn btn-brand btn-sm btn-upper" scope={'orders.update'} to={`/orders/order/edit/${props.info.id}`}  title={Lang.edit_order}>
                      {Lang.edit_order}
                </ActionLink>
        <AssignTranslator refresh={void('')} />  
        <CancelOrder refresh={void('')} />   
         <CompleteOrderPopup refresh={void('')}/>
        <SendMailToCustomer />
        <SendMailToTranslator />
       <OrderComments/>
        <FixPrice/>
        <HistoryPopup /> 
        <CorrectionRequestPopup />
		 
        <Calllog/>
              </div>
            </div>                 
            <div className="kt-widget__info">
            <div className="kt-widget__desc">
            { ! _.isEmpty(props.info.contact_persons) && props.info.contact_persons.map((item, key) => {
            
             return <div key={item.id}>
              <span   title={Lang.contact_name} >
                  <i className="flaticon-avatar" />
                  {item.name}
              </span> 
              &nbsp;
              {item.email &&
              <span   title={Lang.email} >
                  <i className="flaticon-mail-1" />
                  {item.email}
              </span> 
              }
              &nbsp;
              {item.phone_no &&
               <span   title={Lang.contact_number} >
                  <i className="flaticon2-phone" />
                  {item.phone_no}
              </span> 
              } 
              </div>
             })
            
          }
          {/* <span  title={Lang.translator_name} >
                  <i className="flaticon-avatar" />
                  {props.orderinfo.translate_by.name}
              </span>  */}
          </div>
         
              
              <div className="kt-widget__stats d-flex align-items-center flex-fill">
                <div className="kt-widget__item">
                  <span className="kt-widget__date">
                    {Lang.order_date}
                  </span>
                  <div className="kt-widget__label">
                    <span className="btn btn-label-brand btn-sm btn-bold btn-upper btn-selectable">{moment(props.info.order_date).format(CONST.date_format)}</span>
                  </div>
				  
                </div>
                <div className="kt-widget__item">
                  <span className="kt-widget__date">
                  {Lang.order_time}
                  </span>
                  <div className="kt-widget__label">
                    <span className="btn btn-label-danger btn-sm btn-bold btn-upper btn-selectable">{order_time(props.info)}</span>
                  </div>
                </div>
				<div className="kt-widget__item">
                  <span className="kt-widget__date">
                  {Lang.order_day}
                  </span>
                  <div className="kt-widget__label">
                    <span className="btn btn-label-brand btn-sm btn-bold btn-upper btn-selectable">{props.info.order_day}</span>
                  </div>
                </div>
                { props.info.status  === 5 &&  
                <div className="kt-widget__item">
                  <span className="kt-widget__date">
                  {Lang.complete_order_time}
                  </span>
                 
                  <div className="kt-widget__label">
                    <span className="btn btn-label-danger btn-sm btn-bold btn-upper btn-selectable">{complete_order_time(props.info)}</span>
                  </div>
                  </div>
                  }
                <div className="kt-widget__item flex-fill">
            <span className="kt-widget__subtitel">{Lang.progress}&nbsp;{OrdersStatus()}</span>
                  <div className="kt-widget__progress d-flex  align-items-center">
                    <div className="progress" style={{height: '5px', width: '100%'}}>
                      <div className="progress-bar kt-bg-success" role="progressbar" style={{width: OrderProgress[props.info.status]}}/>
                    </div>
                    <span className="kt-widget__stat">
                      {OrderProgress[props.info.status]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-widget__bottom">
          <div className="kt-widget__item">
            <div className="kt-widget__icon">
              <i className="flaticon-piggy-bank"/>
            </div>
            <div className="kt-widget__details">
        <span className="kt-widget__title">{Lang.fix_price}</span>
              <span className="kt-widget__value"><span>$</span>{(props.info.fix_price)?props.info.fix_price:'0'}</span>
            </div>
          </div>
          {props.info.translation_type_id === OrderType.DOCUMENT &&
          <div className="kt-widget__item">
            <div className="kt-widget__icon">
              <i className="flaticon-confetti"/>
            </div>
           <div className="kt-widget__details">
              <span className="kt-widget__title">{Lang.doc_price}</span>
              <span className="kt-widget__value"><span>$</span>{(props.info.doc_price)?props.info.doc_price:'0'}</span>
            </div>
           </div>      
          }         
          <div className="kt-widget__item">
            <div className="kt-widget__icon">
              <i className="flaticon-file-2"/>
            </div>
            <div className="kt-widget__details">
                <span className="kt-widget__title">{Lang.language}</span>
                <img src={window.PUBLIC_URL +'/admin/assets/flags/'+_.lowerCase(props.info.language_from.country.iso) +'.png'} className="img-flag" />&nbsp;{props.info.language_from.language_name}
                <span className="exchangeIcon"><i className="fas fa-exchange-alt"/></span>
                {(props.info.is_dumb === 0 && props.info.language_to !== null) &&
                    <img src={window.PUBLIC_URL +'/admin/assets/flags/'+_.lowerCase(props.info.language_to.country.iso) +'.png'} className="img-flag" />
                }
                &nbsp;{order_to_language(props.info)}
            </div>
          </div>
          <div className="kt-widget__item">
            <div className="kt-widget__icon">
              <i className="flaticon-chat-1"/>
            </div>
            <div className="kt-widget__details">
              <span className="kt-widget__title">{props.info.comments_count} {Lang.comments}</span>
                { window.location.pathname.indexOf('info')!==-1 &&
                <ActionLink className="kt-widget__value kt-font-brand" scope={'orders.index'} to={'#'}  onClick={() =>OrderCommentService.showPopup(props.info)}   title={'Comments'}>
                    {Lang.view}
                </ActionLink>
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  )
}