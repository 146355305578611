import * as React from 'react';
import {DateInput, base_url, getQueryStringParams, SearchInput, SearchSelect, showNotification} from "../../../common";
import {default as Lang} from "../../../common/lang/Lang";
import {getDept, getDeptUnit} from "../../../helpers/OrderHelper";
import {LanguageAndCompanyList} from "../../../services";
import _, { cloneDeep, filter, isArray, isEmpty, update } from 'lodash';
import { OrderConst } from '../Data';
import { NewOrderStatus } from '../../../model';
import Axios from '../../../common/AxoisClient';
import moment from 'moment';

interface IState {
  form: {
    like: {
      q: string,
    },
    between: {
      order_date: {
        from: string;
        to:string;
      }
    }
    equal: {
      status: number,
      order_status: number,
      holiday_status: number,
      from_language: number,
      company_id: string,
      department_id: string;
      department_unit_id: string;
      is_dumb: number,
      show_late: number,
      show_weekend: number,
      order_type: number | string,
    }
    in?: {
      [key: string]: any[]
    }
  },
  dept: Array<any>,
  units: Array<any>,
  companies: Array<any>;
  languages: Array<any>;
  order_statuses: Array<any>;
  holiday_statuses: Array<any>;
  economic_information: {
    permissions: {
      drafting_allowed: boolean
      invoicing_allowed: boolean
    }
    policies: [{
      id: number
      name: string
      description: string
    }]
    orders_weeks?: [{
      week_start_date: string
      week_end_date: string
      order_count: number
    }]
  }
  invoice_batch_info?: {
    orders_count: number
    invoice_count: number
  }
}

export default class CompleteOrderFilter extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {

    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({
      dept: [],
      units:[],
      companies: [],
      languages: [],
      order_statuses: [
        {
          'label': Lang.order_status_completed,
          'value': String(NewOrderStatus.COMPLETED)
        },
        {
          'label': Lang.order_status_working,
          'value': String(NewOrderStatus.WORKING)
        },
        {
          'label': Lang.order_status_drafted,
          'value': String(NewOrderStatus.DRAFTED)
        },
        {
          'label': Lang.order_status_paid,
          'value': String(NewOrderStatus.PAID)
        },
      ],
      holiday_statuses: [
        {
          'label': 'Ferie',
          'value': '1'
        },
        {
          'label': 'Ikke ferie',
          'value': '2'
        }
      ],
      form:  {
        like: {
          q: old_data ? old_data.like.q : '',
        },
        between: {
          order_date: {
            from: old_data  && old_data.between ? old_data.between.order_date.from: '',
            to:old_data  && old_data.between ? old_data.between.order_date.to: '',
          }
        },
        equal: {
          status: old_data ? old_data.equal.status : '',
          order_status: old_data ? old_data.equal.order_status: '',
          holiday_status: old_data ? old_data.equal.holiday_status: '',
          from_language: old_data ? old_data.equal.from_language : '',
          company_id: old_data ? old_data.equal.company_id : '',
          department_id: old_data ? old_data.equal.department_id : '',
          department_unit_id: old_data ? old_data.equal.department_unit_id : '',
          is_dumb : old_data ? old_data.equal.is_dumb : '',
          show_late: old_data ? old_data.equal.show_late: '',
          show_weekend: old_data ? old_data.equal.show_weekend: '',
          order_type: old_data ? old_data.equal.order_type : ''
        },
        in: !isEmpty(old_data.in)? Object.entries(old_data.in).map(([key, val]) => ({[key]: val as any})).reduce((old, curr) => ({...old, curr})) : {}
      }})
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.state.form.equal.company_id !== '' && !_.isEmpty(nextProps.companies )){
      const dept: any = getDept(nextProps.companies, Number(this.state.form.equal.company_id));
      this.setState({
        dept: dept,
        units: getDeptUnit(dept, Number(this.state.form.equal.department_id))
      })
    }
  }

  calculateInvoiceBatchInfo = (state_clone: IState): IState => {
    const filter_from_date = state_clone.form.between.order_date.from || (state_clone.economic_information.orders_weeks?.[0].week_start_date ?? '')
    const filter_to_date = state_clone.form.between.order_date.to || (state_clone.economic_information.orders_weeks?.[state_clone.economic_information.orders_weeks.length - 1].week_end_date ?? '')
    const invoiced_weeks = state_clone.economic_information.orders_weeks?.filter?.(week => {
      const week_between_filter_dates = moment(week.week_start_date).isBetween(filter_from_date, filter_to_date, null, '[]') || moment(week.week_end_date).isBetween(filter_from_date, filter_to_date, null, '[]')
      const filter_dates_between_week = moment(filter_from_date).isBetween(week.week_start_date, week.week_end_date, null, '[]') || moment(filter_to_date).isBetween(week.week_start_date, week.week_end_date, null, '[]')
      return week_between_filter_dates || filter_dates_between_week
    })
    if (!invoiced_weeks) return state_clone
    state_clone.invoice_batch_info = {
      invoice_count: invoiced_weeks.length,
      orders_count: invoiced_weeks.reduce((acc, week) => acc + week.order_count, 0)
    }
    return state_clone
  }

  updateCompanyEconomicInfo() {
    return Axios({baseURL: base_url, url: `api/economics/company/get-company-economic-information/${this.state.form.equal.company_id}`})
    .then(({ data }, state_clone = cloneDeep(this.state)) => {
      state_clone.economic_information = data
      state_clone = this.calculateInvoiceBatchInfo(state_clone)
      this.setState(state_clone)
      return state_clone
    })
  }

  componentDidMount() {
    LanguageAndCompanyList().then(res => {
      this.setState({
        languages: res.languages,
        companies: res.companies,
      })
    })
    if (this.state.form.equal.company_id) 
    this.updateCompanyEconomicInfo()
  }

  componentDidUpdate(prevProps: Readonly<IState>, { form: {equal:{company_id: previous_company_id}, between: {order_date: {from, to}}} }: Readonly<IState>, snapshot?: any): void {
    const company_changed = Number(previous_company_id) !== Number(this.state.form.equal.company_id)
    const from_date_changed = from !== this.state.form.between.order_date.from
    const to_date_changed = to !== this.state.form.between.order_date.to
    if (!company_changed && !from_date_changed && !to_date_changed) return
    let state_clone = cloneDeep(this.state)
    if (!company_changed && (from_date_changed || to_date_changed) && isArray(this.state.economic_information?.orders_weeks)) this.setState(this.calculateInvoiceBatchInfo(state_clone))
    else if (company_changed) this.updateCompanyEconomicInfo()
      .then((state) => {
        const state_clone = cloneDeep(state)
        this.setState(this.calculateInvoiceBatchInfo(state_clone))
      })
  }

  handleResetClick = (e: any) => {
    e.preventDefault();
    let resetValues: any;
    resetValues = {
        like: {
          q: '',
        },
        between: {
          order_date: {
            from: '',
            to: '',
          }
        },
        equal: {
          status: "",
          order_status: "",
          holiday_status: "",
          from_language: "",
          company_id: '',
          department_id: '',
          department_unit_id: '',
          is_dumb: '',
          show_late: '',
          show_weekend: '',
          order_type: ''
        }
      };
      let newAccess: any = Object.assign({}, resetValues);
      let new_state = {form: newAccess};
      this.setState(new_state,() =>  this.props.getResults(this.state.form));

  }

  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;

    let name = target.name.split(".");
    let newAccess: IState['form'] = cloneDeep(this.state.form);
    let curr = newAccess;
    for (let depth = 1; depth < name.length -1; depth++){
      curr = curr[name[depth]];
    }
     
    if(target.type === 'checkbox'){
    if(target.checked === true){
      curr[name[name.length - 1]] =  target.value;
    } else{
       curr[name[name.length - 1]] =  0;
    }
  } else{
    curr[name[name.length - 1]] =  target.value;
  }
    let new_state = {form: newAccess};
    // show dept filter and set data for it
    if(name[2] === "company_id"){
      if (Number(target.value) === 66) {
        new_state['form']['in']['company_id'] = [66, 93, 94, 97, 101, 102, 104, 109, 111, 118, 119, 120, 121, 122, 123, 125, 127, 129, 130, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 150, 152, 153, 154, 155, 156, 157, 158, 160, 162, 163, 164, 165, 166, 168, 169, 170, 171, 173, 174, 175, 176, 178, 179, 180, 181, 182, 183, 185, 187, 188, 189, 190, 192, 194, 195, 196, 197, 199, 202, 203, 204, 205, 206, 207, 209, 210, 212, 213, 216, 218, 222, 223, 224, 225, 228, 229, 231, 232, 233, 237, 240, 241, 246, 247, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 260, 263, 274, 275, 287, 289, 293, 297, 299, 300, 307, 322, 324, 325, 329, 331, 342, 344, 348, 349, 350, 351, 353, 355, 356, 357, 358, 359, 360, 365, 369, 377, 379, 381, 384, 386, 391, 393, 399, 403, 414, 419, 425, 441, 449, 454, 455, 456, 463, 468, 469, 476, 482, 495, 501, 538, 557, 558, 561, 565, 587, 588, 590, 591, 617, 618, 619, 620, 630, 644, 648, 675, 679, 682, 693, 697, 707, 713, 722, 751, 753, 755, 758, 760, 788, 791, 806, 815, 816, 821, 827, 831, 834, 837, 841, 849, 862, 863, 864, 865, 866, 867, 876, 877, 880, 882, 883, 884, 885, 886, 910, 920, 929, 930, 933, 934, 936, 964, 969, 970, 972, 980, 981, 1003, 1004, 1012, 1013, 1014, 1016, 1020, 1025, 1027, 1028, 1042, 1046, 1061, 1062, 1063, 1065, 1073, 1078, 1096, 1104, 1119, 1120, 1122, 1124, 1128, 1132, 1142, 1153, 1154, 1155, 1165, 1170, 1171, 1211, 1214, 1216, 1219, 1220, 1234, 1235, 1239, 1248, 1250, 1256, 1257, 1259, 1268, 1269, 1274, 1281, 1282, 1285, 1288, 1292, 1302, 1312, 1314, 1317, 1318, 1323, 1329, 1331, 1333, 1334, 1335, 1343, 1391, 1395, 1399, 1418, 1430, 1437, 1439, 1440, 1445, 1471, 1485, 1497, 1503, 1506, 1508, 1521, 1527, 1538, 1541, 1552, 1573, 1592, 1594, 1622, 1623, 1672, 1686, 1689, 1709, 1732, 1784, 1803, 1807, 1834, 1837, 1845, 1859, 1884, 1900, 1909, 1934, 1988, 2164, 2167, 2206, 2207, 2208]
        new_state['form']['equal']['company_id'] = '66'
      }
      const {companies} = this.state;
      new_state['dept'] = getDept(companies, Number(e.target.value));
    }

    // show dept unit filter and set data for it.

    if(name[2] === "department_id"){
      new_state['units'] = getDeptUnit(this.state.dept, Number(e.target.value));
    }
    this.setState(new_state,() =>  this.props.getResults(cloneDeep(this.state.form)));
  }

  makeOrdersInvoice = async () => {
    const res = (await Axios({ baseURL: base_url, url: 'api/economics/draft-invoices', params: { form: this.state.form } })).data
    showNotification('success', res)
    this.setState(cloneDeep(this.state), () => this.props.getResults(this.state.form))
  }
  
  render(){
    const {languages, companies, order_statuses, holiday_statuses} = this.state;
    return (
      <div>
      <div className="row align-items-center">
        <SearchInput name={'form.like.q'} value={this.state.form.like.q} onChange={this.handleInputForm} placeholder={'Search'} />
        <DateInput name={'form.between.order_date.from'} value={this.state.form.between.order_date.from} onChange={this.handleInputForm} placeholder={'From date'} />
        <DateInput name={'form.between.order_date.to'} value={this.state.form.between.order_date.to} onChange={this.handleInputForm} placeholder={'To date'} />
        <SearchSelect name={'form.equal.from_language'} value={this.state.form.equal.from_language}  onChange={this.handleInputForm} options={[{label: Lang.from_language, value: ''}, ...languages]}/>
        <SearchSelect name={'form.equal.company_id'}  className="select-company" value={this.state.form.equal.company_id}  onChange={this.handleInputForm} options={[{label: Lang.choose_company, value: ''}, ...companies]}/>
        {
          this.state.form.equal.company_id &&
          <SearchSelect name={'form.equal.department_id'}  className="select-company" value={this.state.form.equal.department_id}  onChange={this.handleInputForm} options={this.state.dept}/>
        }

        {
          this.state.form.equal.company_id && this.state.form.equal.department_id &&
          <SearchSelect name={'form.equal.department_unit_id'}  className="select-company" value={this.state.form.equal.department_unit_id}  onChange={this.handleInputForm} options={this.state.units}/>
        }
        <SearchSelect name={'form.equal.order_status'} value={this.state.form.equal.order_status}  onChange={this.handleInputForm} options={[{label: Lang.order_status, value: ''}, ...order_statuses]}/>
        <SearchSelect name={'form.equal.holiday_status'} value={this.state.form.equal.holiday_status}  onChange={this.handleInputForm} options={[{label: Lang.holiday_status, value: ''}, ...holiday_statuses]}/>
        <SearchSelect name={'form.equal.order_type'} value={this.state.form.equal.order_type}  onChange={this.handleInputForm} options={OrderConst.order_types}/>
        <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
          <label className="kt-checkbox">
            <input type="checkbox" id="show_cancelled" value={'1'} name="form.like.show_cancelled" onChange={this.handleInputForm} />
            <label>{Lang.show_cancelled}</label>&nbsp;<span/>
          </label>
        </div>
        <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
          <label className="kt-checkbox">
            <input type="checkbox" id="show_cancelled" name="form.like.show_rating" value={'1'} onChange={this.handleInputForm}  />
            <label>{Lang.show_rating}</label>&nbsp;<span/>
          </label>
        </div>
        <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
          <label className="kt-checkbox">
            <input type="checkbox" id="show_sign" value={'1'} name="form.equal.is_dumb" onChange={this.handleInputForm} />
            <label>{Lang.sign_translation}</label>&nbsp;<span/>
          </label>
        </div>
        <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
          <label className="kt-checkbox">
            <input type="checkbox" id="show_late" value={'1'} name="form.equal.show_late" onChange={this.handleInputForm} />
            <label>{Lang.show_late}</label>&nbsp;<span/>
          </label>
        </div>
        <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
          <label className="kt-checkbox">
            <input type="checkbox" id="show_weekend" value={'1'} name="form.equal.show_weekend" onChange={this.handleInputForm} />
            <label>{Lang.show_weekend}</label>&nbsp;<span/>
          </label>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-md-6 kt-margin-b-20-tablet-and-mobile">
          {this.state.economic_information?.permissions.drafting_allowed && 
            <button
              type='button'
              className='btn btn-brand mr-3'
              onClick={this.makeOrdersInvoice}
              style={{
                position:'relative',
                display: 'inline-flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              Send som kladde
          </button>
          }
          <button className={'btn btn-default '}  onClick={this.handleResetClick} data-skin={'dark'} title={Lang.reset} >{Lang.reset}</button>
        </div>
      </div>
      </div>
    )
  }
}