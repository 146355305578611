export interface DataSchema {
  id?: number;
  name: string;
}

export interface FormFields  {
  name: any;
}


export const FormDefaultVal = {
  name: ''
}

export const Form: Array<FormFields> = [
  {name: "name"}
];

export const RequiredFields = {
  'name' : 'Name'
}