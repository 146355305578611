import React, {useEffect, useReducer} from 'react';
import {FixPriceSubjectService, FixPriceCalculationSubjectService, setPrice, fixOrderRates, getFixOrderRates} from "../../../services";
import API from "../../../common/AxoisClient";
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {showNotification, SubmitButton, Select, RequestWaiting} from "../../../common";
import {
  InitialState, PopupState, ActionType,
  CAction, FixPriceReducer, FixPriceValidations
} from "../order-reducers/FixPriceReducer";
import {convert_comma_to_dot, convert_dot_to_comma, convert_to_decimal_format} from "../../../helpers/UserHelper";
import {OrderFixPriceModel, TranslatorSpecialRatesModel, OrderType} from "../../../model";
import {FixPriceCalculation} from "./FixPriceCalculation";

export function FixPrice () {

    const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(FixPriceReducer , InitialState);
    useEffect( () => {
        FixPriceSubjectService.getPopupState().subscribe((message:any) => {
          FixPriceValidations();
          dispatch({type: message.action, payload: message});
          if(message && message.fixPrice && message.fixPrice.results){
            if(message.fixPrice.results.length > 0 && message.fixPrice.results[message.fixPrice.results.length-1].translation_type==message.fixPrice.results[message.fixPrice.results.length-1].translation_type_id){
              dispatch({type: ActionType.HANDLE_CHANGE, payload: { name: 'is_special_agreement', value: Number(message.fixPrice.results[message.fixPrice.results.length-1].is_special_agreement) } as any});
            }
            dispatch({type: ActionType.HANDLE_CHANGE, payload: { name: 'isAgreementSet', value: true } as any});
          }
        });
    }, []);

    useEffect( () => {
      if(state.form.is_special_agreement==1){
        state.fixPrice.map((item: OrderFixPriceModel, index: number) => {
          if(index==state.fixPrice.length-1){
            var specialCompanyRates: TranslatorSpecialRatesModel = chkSpecialCompany(item.special_company_rates);
            if(specialCompanyRates){
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'is_special_company', value: 1} as any});
            }
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'compensation_agreed', value:item.compensation_agreed} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'transport_fee_agreed', value:item.transport_fee_agreed} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'holiday_charge_agreed', value:item.holiday_charge_agreed} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'translation_type', value:item.translation_type} as any});

            var extra_per: any = item.extra_holiday_percentage;
            var extra_per_rate: any = item.extra_per_rate;

            if(item.holiday_charge_agreed=='1' && state.order_data.translation_type_id!==5 && state.order_data.translation_type_id!==6){
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_holiday_percentage', value: convert_to_decimal_format(extra_per)} as any});  
              if(extra_per){
                if(state.order_data.translation_type_id==1){
                  var translatorRateObj: any = {
                    extra_holiday_percentage: extra_per,
                    fix_rate: item.telephone_fix_rate,
                    fix_minute: item.telephone_fix_minute,
                    sub_rate: item.telephone_sub_rate,
                    sub_minute: item.telephone_sub_minute
                  }
                  var holiday_extra_rate: any = getHolidayCalc(state.order_data,translatorRateObj);
                } else if(state.order_data.translation_type_id==2){
                  var translatorRateObj: any = {
                    extra_holiday_percentage: extra_per,
                    fix_rate: item.physical_fix_rate,
                    fix_minute: item.physical_fix_minute,
                    sub_rate: item.physical_sub_rate,
                    sub_minute: item.physical_sub_minute
                  }
                  var holiday_extra_rate: any = getHolidayCalc(state.order_data,translatorRateObj);
                } else if(state.order_data.translation_type_id==3){
                  var translatorRateObj: any = {
                    extra_holiday_percentage: extra_per,
                    fix_rate: item.video_fix_rate,
                    fix_minute: item.video_fix_minute,
                    sub_rate: item.video_sub_rate,
                    sub_minute: item.video_sub_minute
                  }
                  var holiday_extra_rate: any = getHolidayCalc(state.order_data,translatorRateObj);
                }
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_per_rate', value: convert_to_decimal_format(extra_per_rate)} as any});  
              }
            }

            if(state.order_data.translation_type_id==1){
              var old_rate:any = 0;
              var telephone_fix_rate: any = 0;
              var telephone_fix_minute: any = 0;
              var old_sub_rate: any = 0;
              var telephone_sub_rate: any = 0;
              var telephone_sub_minute: any = 0;
              
              var difference = 0;
              difference = Number(convert_comma_to_dot(item.fix_price)) - Number(convert_comma_to_dot(item.old_total));
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'difference', value: convert_to_decimal_format(String(difference.toFixed(2)))} as any});
              
              old_rate = item.telephone_fix_rate;
              telephone_fix_rate = item.telephone_fix_rate;
              telephone_fix_minute = item.telephone_fix_minute;
              old_sub_rate = item.telephone_sub_rate;
              telephone_sub_rate = item.telephone_sub_rate;
              telephone_sub_minute = item.telephone_sub_minute;

              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_rate', value: convert_to_decimal_format(String(old_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'telephone_fix_rate', value: convert_to_decimal_format(String(telephone_fix_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'telephone_fix_minute', value: telephone_fix_minute} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_sub_rate', value: convert_to_decimal_format(String(old_sub_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'telephone_sub_rate', value: convert_to_decimal_format(String(telephone_sub_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'telephone_sub_minute', value: telephone_sub_minute} as any});

              var totalDiff = 0;
              var minuteDiff = 0;
              if(order_data.complete_order_to && order_data.status==5) {
                var timeEnd = new Date(order_data.order_date +' '+ order_data.complete_order_to).getTime();
              } else {
                var timeEnd = new Date(order_data.order_date +' '+ order_data.order_to).getTime();
              }
              var timeStart = new Date(order_data.order_date +' '+ order_data.order_from).getTime();
              totalDiff = timeEnd - timeStart;
              minuteDiff = Math.ceil(totalDiff / 60000);
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_time', value: minuteDiff} as any});

              if(minuteDiff > telephone_fix_minute){
                var calcExtraMinutes = minuteDiff - telephone_fix_minute;

                if(calcExtraMinutes >= telephone_sub_minute && Number(telephone_sub_minute) > 0){
                  var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes)/Number(telephone_sub_minute));
                  if(extraMinutesSlots==0){
                    extraMinutesSlots = 1;
                  }
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_extra_rates', value: convert_to_decimal_format(item.total_extra_rates)} as any});
                  if(item.translation_type==state.order_data.translation_type_id){
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(item.fix_price)} as any});
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(item.old_total)} as any});
                  }
                } else {
                  var extraMinutesSlots:number = 1;
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_extra_rates', value: convert_to_decimal_format(item.total_extra_rates)} as any});
                  if(item.translation_type==state.order_data.translation_type_id){
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(item.fix_price)} as any});
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(item.old_total)} as any});  
                  }
                }
              } else {
                if(item.translation_type==state.order_data.translation_type_id){
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(item.fix_price)} as any});
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(item.old_total)} as any});
                }
              }
            } else if(state.order_data.translation_type_id==2){
              var calcRemainKm = order_data.total_distance - (Number(item.min_km) * 2);
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'remain_km', value: calcRemainKm} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_km_rate', value: convert_to_decimal_format(item.total_km_rate)} as any});
              var old_rate:any = 0;
              var physical_fix_rate: any = 0;
              var physical_fix_minute: any = 0;
              var old_sub_rate: any = 0;
              var physical_sub_rate: any = 0;
              var physical_sub_minute: any = 0;
              var min_km: any = 0;
              var old_per_km_rate: any = 0;
              var per_km_rate: any = 0;
              var old_transport_fee_fix_rate: any = 0;
              var transport_fee_minute: any = 0;
              var old_transport_fee_sub_rate: any = 0;
              var transport_fee_sub_minute: any = 0;
              var transport_fee_fix_rate: any = 0;
              var transport_fee_sub_rate: any = 0;
              var transport_fee_agreed: any = 0;
              var difference = 0;
              difference = Number(convert_comma_to_dot(item.fix_price)) - Number(convert_comma_to_dot(item.old_total));
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'difference', value: convert_to_decimal_format(String(difference.toFixed(2)))} as any});

              old_rate = convert_dot_to_comma(item.physical_fix_rate);
              physical_fix_rate = convert_dot_to_comma(item.physical_fix_rate);
              physical_fix_minute = item.physical_fix_minute;
              old_sub_rate = convert_dot_to_comma(item.physical_sub_rate);
              physical_sub_rate = convert_dot_to_comma(item.physical_sub_rate);
              physical_sub_minute = item.physical_sub_minute;
              min_km = item.min_km;
              old_per_km_rate = convert_dot_to_comma(item.per_km_rate);
              per_km_rate = convert_dot_to_comma(item.per_km_rate);
              old_transport_fee_fix_rate = convert_dot_to_comma(item.transport_fee_fix_rate);
              transport_fee_minute = item.transport_fee_minute;
              old_transport_fee_sub_rate = convert_dot_to_comma(item.transport_fee_sub_rate);
              transport_fee_sub_minute = item.transport_fee_sub_minute;
              transport_fee_fix_rate = convert_dot_to_comma(item.transport_fee_fix_rate);
              transport_fee_sub_rate = convert_dot_to_comma(item.transport_fee_sub_rate);
              transport_fee_agreed = item.transport_fee_agreed;

              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_rate', value: convert_to_decimal_format(String(old_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'physical_fix_rate', value: convert_to_decimal_format(String(physical_fix_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'physical_fix_minute', value: physical_fix_minute} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_sub_rate', value: convert_to_decimal_format(String(old_sub_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'physical_sub_rate', value: convert_to_decimal_format(String(physical_sub_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'physical_sub_minute', value: physical_sub_minute} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'min_km', value: min_km} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_per_km_rate', value: convert_to_decimal_format(String(old_per_km_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'per_km_rate', value: convert_to_decimal_format(String(per_km_rate))} as any});
              
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_transport_fee_fix_rate', value: convert_to_decimal_format(String(old_transport_fee_fix_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'transport_fee_fix_rate', value: convert_to_decimal_format(String(transport_fee_fix_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'transport_fee_minute', value: transport_fee_minute} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_transport_fee_sub_rate', value: convert_to_decimal_format(String(old_transport_fee_sub_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'transport_fee_sub_rate', value: convert_to_decimal_format(String(transport_fee_sub_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'transport_fee_sub_minute', value: transport_fee_sub_minute} as any});

              var totalTravelRates:number = 0;
              var totalTravelExtraRates:number = 0;
              if(transport_fee_agreed==1){
                if(order_data.travel_time > transport_fee_minute) {
                  var calcTravelExtraMinutes = order_data.travel_time - (transport_fee_minute * 2);
                  if(calcTravelExtraMinutes >= transport_fee_sub_minute && Number(transport_fee_sub_minute) > 0){
                    var extraTransportMinutesSlots: number = Math.ceil(Number(calcTravelExtraMinutes)/Number(transport_fee_sub_minute));
                    if(extraTransportMinutesSlots==0){
                      extraTransportMinutesSlots = 1;
                    }
                    totalTravelExtraRates = getExtraCalc(extraTransportMinutesSlots, convert_comma_to_dot(transport_fee_sub_rate), Number(convert_comma_to_dot(transport_fee_sub_rate)));
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_transport_minutes_slots', value: extraTransportMinutesSlots} as any});
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_transport_rate', value: convert_to_decimal_format(item.extra_transport_rate)} as any});
                    totalTravelRates = (Number(convert_comma_to_dot(transport_fee_fix_rate)) * 2) + Number(totalTravelExtraRates); 
                  } else {
                    var extraTransportMinutesSlots: number = 1;
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_transport_minutes_slots', value: extraTransportMinutesSlots} as any});
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_transport_rate', value: convert_to_decimal_format(item.extra_transport_rate)} as any});
                    totalTravelRates = (Number(convert_comma_to_dot(transport_fee_fix_rate)) * 2) + Number(state.form.transport_fee_sub_rate);  
                  }
                } else {
                    totalTravelRates = (Number(convert_comma_to_dot(transport_fee_fix_rate)) * 2);
                }
              }

              var totalDiff = 0;
              var minuteDiff = 0;
              if(order_data.complete_order_to && order_data.status==5) {
                var timeEnd = new Date(order_data.order_date +' '+ order_data.complete_order_to).getTime();
              } else {
                var timeEnd = new Date(order_data.order_date +' '+ order_data.order_to).getTime();
              }
              var timeStart = new Date(order_data.order_date +' '+ order_data.order_from).getTime();
              totalDiff = timeEnd - timeStart;
              minuteDiff = Math.ceil(totalDiff / 60000);
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_time', value: minuteDiff} as any});

              if(minuteDiff > physical_fix_minute){
                var calcExtraMinutes = minuteDiff - physical_fix_minute;
                if(calcExtraMinutes >= physical_sub_minute && Number(physical_sub_minute) > 0){
                  var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes)/Number(physical_sub_minute));
                  if(extraMinutesSlots==0){
                    extraMinutesSlots = 1;
                  }
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_extra_rates', value: convert_to_decimal_format(item.total_extra_rates)} as any});
                  if(item.translation_type==state.order_data.translation_type_id){
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(item.fix_price)} as any});
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(item.old_total)} as any});
                  }
                } else {
                  var extraMinutesSlots:number = 1;
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_extra_rates', value: convert_to_decimal_format(item.total_extra_rates)} as any});
                  if(item.translation_type==state.order_data.translation_type_id){
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(item.fix_price)} as any});
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(item.old_total)} as any});  
                  }
                }
              } else {
                if(item.translation_type==state.order_data.translation_type_id){
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(item.fix_price)} as any});
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(item.old_total)} as any});
                }
              }
            } else if(state.order_data.translation_type_id==3){
              var old_rate:any = 0;
              var video_fix_rate: any = 0;
              var video_fix_minute: any = 0;
              var old_sub_rate: any = 0;
              var video_sub_rate: any = 0;
              var video_sub_minute: any = 0;
              var difference = 0;
              difference = Number(convert_comma_to_dot(item.fix_price)) - Number(convert_comma_to_dot(item.old_total));
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'difference', value: convert_to_decimal_format(String(difference.toFixed(2)))} as any});

              old_rate = convert_dot_to_comma(item.video_fix_rate);
              video_fix_rate = convert_dot_to_comma(item.video_fix_rate);
              video_fix_minute = item.video_fix_minute;
              old_sub_rate = convert_dot_to_comma(item.video_sub_rate);
              video_sub_rate = convert_dot_to_comma(item.video_sub_rate);
              video_sub_minute = item.video_sub_minute;

              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_rate', value: convert_to_decimal_format(String(old_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'video_fix_rate', value: convert_to_decimal_format(String(video_fix_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'video_fix_minute', value: video_fix_minute} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_sub_rate', value: convert_to_decimal_format(String(video_sub_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'video_sub_rate', value: convert_to_decimal_format(String(video_sub_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'video_sub_minute', value: video_sub_minute} as any});

              var totalDiff = 0;
              var minuteDiff = 0;
              if(order_data.complete_order_to && order_data.status==5) {
                var timeEnd = new Date(order_data.order_date +' '+ order_data.complete_order_to).getTime();
              } else {
                var timeEnd = new Date(order_data.order_date +' '+ order_data.order_to).getTime();
              }
              var timeStart = new Date(order_data.order_date +' '+ order_data.order_from).getTime();
              totalDiff = timeEnd - timeStart;
              minuteDiff = Math.ceil(totalDiff / 60000);
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_time', value: minuteDiff} as any});

              if(minuteDiff > video_fix_minute){
                var calcExtraMinutes = minuteDiff - video_fix_minute;
                if(calcExtraMinutes >= video_sub_minute && Number(video_sub_minute) > 0){
                  var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes)/Number(video_sub_minute));
                  if(extraMinutesSlots==0){
                    extraMinutesSlots = 1;
                  }
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_extra_rates', value: convert_to_decimal_format(item.total_extra_rates)} as any});
                  if(item.translation_type==state.order_data.translation_type_id){
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(item.fix_price)} as any});
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(item.old_total)} as any});
                  }
                } else {
                  var extraMinutesSlots: number = 1;
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_extra_rates', value: convert_to_decimal_format(item.total_extra_rates)} as any});
                  totalRates = Number(convert_comma_to_dot(video_fix_rate)) + Number(item.video_sub_rate);
                  if(item.translation_type==state.order_data.translation_type_id){
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(item.fix_price)} as any});
                    dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(item.old_total)} as any});  
                  }
                }
              } else {
                if(item.translation_type==state.order_data.translation_type_id){
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(video_fix_rate)} as any});
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(item.old_total)} as any});
                }
              }
            } else if(state.order_data.translation_type_id==5){
              var old_rate:any = 0;
              var tel_message_fix_rate: any = 0;
              var telephone_sub_rate: any = 0;
              var total_tel_message_fix_rate: any = 0;
              var difference = 0;
              difference = Number(convert_comma_to_dot(item.fix_price)) - Number(convert_comma_to_dot(item.old_total));
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'difference', value: convert_to_decimal_format(String(difference.toFixed(2)))} as any});

              old_rate = convert_dot_to_comma(item.tel_message_fix_rate);
              tel_message_fix_rate = convert_dot_to_comma(item.tel_message_fix_rate);
              //total_tel_message_fix_rate = Number(convert_comma_to_dot(item.tel_message_fix_rate)) * Number(item.total_strokes);
              total_tel_message_fix_rate = Number(convert_comma_to_dot(item.tel_message_fix_rate));

              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_rate', value: convert_to_decimal_format(String(tel_message_fix_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'tel_message_fix_rate', value: convert_to_decimal_format(String(tel_message_fix_rate))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_strokes', value: item.total_strokes} as any});

              if(item.translation_type==state.order_data.translation_type_id){
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(item.fix_price)} as any});
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(item.old_total)} as any});  
              }
            } else if(state.order_data.translation_type_id==6){
              var difference = 0;
              difference = Number(convert_comma_to_dot(item.fix_price)) - Number(convert_comma_to_dot(item.old_total));
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'difference', value: convert_to_decimal_format(String(difference.toFixed(2)))} as any});

              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_rate', value: convert_to_decimal_format(item.per_word_rate)} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'per_word_rate', value: convert_to_decimal_format(item.per_word_rate)} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'min_word', value: item.min_word ? item.min_word: 0} as any});

              if(item.total_words){
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_words', value: item.total_words} as any});
                var totalRates = Number(convert_comma_to_dot(item.fix_price));
                if(item.translation_type==state.order_data.translation_type_id){
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(item.fix_price)} as any});
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(item.old_total)} as any});
                }
              }
            }
          }
        });  
      } else {
        loadCurrentCalc();
      }
    }, [state.form.isAgreementSet]);

    if(state.show === false){
        return null;
    }
    const getExtraCalc = (totalExtraSlots, totalSubRate = 0, finalExtraRate = 0) => {
        var calcTotalExtraRates:number = Number(totalSubRate) * totalExtraSlots;
        var returnFinalRate:number = calcTotalExtraRates;
        return returnFinalRate;
    }


    const handleChange = (e: React.ChangeEvent<HTMLElement>) => {
        const target : any = e.target;
        const value = target.value;
        const name = target.name;
        dispatch({type: ActionType.HANDLE_CHANGE, payload: { value: value, name: name } as any});
        if(name=='is_special_agreement' && value==1){
          loadCurrentCalc();
        }
    }

    function loadCurrentCalc() {
      if(state.order_data.status==3 && state.order_data.translator_id) {
        API.get(`users/translators/${state.order_data.translator_id}`).then(res => {
          const translatorData = res.data.translatorArr.translator;
          var specialCompanyRates: TranslatorSpecialRatesModel = chkSpecialCompany(res.data.translatorArr.special_company_rates);
          var extra_per: any;
          if(specialCompanyRates){
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'is_special_company', value: 1} as any});
            extra_per = getHolidayData(state.order_data, specialCompanyRates, 1);
            if(specialCompanyRates.special_holiday_charge_agreed==1 && extra_per && extra_per!='0,00'){
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'holiday_charge_agreed', value:specialCompanyRates.special_holiday_charge_agreed} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_holiday_percentage', value: extra_per} as any});
            } else {
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'holiday_charge_agreed', value:0} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_holiday_percentage', value: '0,00'} as any});
            }
          } else {
            extra_per = getHolidayData(state.order_data, translatorData, 0);
            if(translatorData.holiday_charge_agreed==1 && extra_per && extra_per!='0,00'){
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'holiday_charge_agreed', value:translatorData.holiday_charge_agreed} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_holiday_percentage', value: extra_per} as any});
            } else {
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'holiday_charge_agreed', value:0} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_holiday_percentage', value: '0,00'} as any});
            }
          }
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'translation_type', value:state.order_data.translation_type_id} as any});

          if(state.order_data.translation_type_id==1){
            var old_rate:any = 0;
            var telephone_fix_rate: any = 0;
            var telephone_fix_minute: any = 0;
            var old_sub_rate: any = 0;
            var telephone_sub_rate: any = 0;
            var telephone_sub_minute: any = 0;

            if(specialCompanyRates){
              old_rate = convert_dot_to_comma(specialCompanyRates.special_telephone_fix_rate);
              telephone_fix_rate = convert_dot_to_comma(specialCompanyRates.special_telephone_fix_rate);
              telephone_fix_minute = specialCompanyRates.special_telephone_fix_minute;
              old_sub_rate = convert_dot_to_comma(specialCompanyRates.special_telephone_sub_rate);
              telephone_sub_rate = convert_dot_to_comma(specialCompanyRates.special_telephone_sub_rate);
              telephone_sub_minute = specialCompanyRates.special_telephone_sub_minute;
            } else {
              old_rate = convert_dot_to_comma(translatorData.telephone_fix_rate);
              telephone_fix_rate = convert_dot_to_comma(translatorData.telephone_fix_rate);
              telephone_fix_minute = translatorData.telephone_fix_minute;
              old_sub_rate = convert_dot_to_comma(translatorData.telephone_sub_rate);
              telephone_sub_rate = convert_dot_to_comma(translatorData.telephone_sub_rate);
              telephone_sub_minute = translatorData.telephone_sub_minute;
            }
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_rate', value: old_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'telephone_fix_rate', value: telephone_fix_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'telephone_fix_minute', value: telephone_fix_minute} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_sub_rate', value: old_sub_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'telephone_sub_rate', value: telephone_sub_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'telephone_sub_minute', value: telephone_sub_minute} as any});

            var totalDiff = 0;
            var minuteDiff = 0;
            if(order_data.complete_order_to && order_data.status==5) {
              var timeEnd = new Date(order_data.order_date +' '+ order_data.complete_order_to).getTime();
            } else {
              var timeEnd = new Date(order_data.order_date +' '+ order_data.order_to).getTime();
            }
            var timeStart = new Date(order_data.order_date +' '+ order_data.order_from).getTime();
            totalDiff = timeEnd - timeStart;
            minuteDiff = Math.ceil(totalDiff / 60000);
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_time', value: minuteDiff} as any});

            var totalRates:number = 0;
            var totalExtraRates:number = 0;
            if(minuteDiff > telephone_fix_minute){
              var calcExtraMinutes = minuteDiff - telephone_fix_minute;
              if(calcExtraMinutes >= telephone_sub_minute && Number(telephone_sub_minute) > 0){
                var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes)/Number(telephone_sub_minute));
                if(extraMinutesSlots==0){
                  extraMinutesSlots = 1;
                }
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
                totalExtraRates = getExtraCalc(extraMinutesSlots, convert_comma_to_dot(telephone_sub_rate), Number(convert_comma_to_dot(telephone_sub_rate)));
                totalRates = Number(convert_comma_to_dot(telephone_fix_rate)) + Number(totalExtraRates);
              } else {
                var extraMinutesSlots: number = 1;
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
                totalExtraRates = Number(convert_comma_to_dot(telephone_sub_rate));
                totalRates = Number(convert_comma_to_dot(telephone_fix_rate)) + Number(convert_comma_to_dot(telephone_sub_rate));
              }
            } else {
              totalRates = Number(convert_comma_to_dot(telephone_fix_rate));
            }
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_extra_rates', value: convert_to_decimal_format(String(totalExtraRates.toFixed(2)))} as any});
            if(extra_per){
              var translatorRateObj: any = {
                extra_holiday_percentage: extra_per,
                fix_rate: telephone_fix_rate,
                fix_minute: telephone_fix_minute,
                sub_rate: telephone_sub_rate,
                sub_minute: telephone_sub_minute
              }
              var holiday_extra_rate: any = getHolidayCalc(state.order_data,translatorRateObj);
              if(holiday_extra_rate){
                //var before_extra_rate = totalRates;
                //totalRates = totalRates * ((100+Number(convert_comma_to_dot(extra_per)))/100);
                //var extraPerRate = totalRates - before_extra_rate;
                totalRates += holiday_extra_rate;
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_per_rate', value: convert_to_decimal_format(String(holiday_extra_rate.toFixed(2)))} as any});
              }  
            }
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
          } else if(state.order_data.translation_type_id==2){
            var calcPerKmRate = 0;
            var old_rate:any = 0;
            var physical_fix_rate: any = 0;
            var physical_fix_minute: any = 0;
            var old_sub_rate: any = 0;
            var physical_sub_rate: any = 0;
            var physical_sub_minute: any = 0;
            var min_km: any = 0;
            var old_per_km_rate: any = 0;
            var per_km_rate: any = 0;
            var old_transport_fee_fix_rate: any = 0;
            var transport_fee_minute: any = 0;
            var old_transport_fee_sub_rate: any = 0;
            var transport_fee_sub_minute: any = 0;
            var transport_fee_fix_rate: any = 0;
            var transport_fee_sub_rate: any = 0;
            var transport_fee_agreed: any = 0;

            if(specialCompanyRates){
              var calcRemainKm = order_data.total_distance - (Number(specialCompanyRates.special_min_km) * 2);
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'remain_km', value: calcRemainKm} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'compensation_agreed', value:specialCompanyRates.special_compensation_agreed} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'transport_fee_agreed', value:specialCompanyRates.special_transport_fee_agreed} as any});
              if(calcRemainKm > 0 && specialCompanyRates.special_compensation_agreed==1){
                calcPerKmRate = calcRemainKm * Number(specialCompanyRates.special_per_km_rate);
              }
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_km_rate', value: convert_to_decimal_format(String(calcPerKmRate))} as any});
              old_rate = convert_dot_to_comma(specialCompanyRates.special_physical_fix_rate);
              physical_fix_rate = convert_dot_to_comma(specialCompanyRates.special_physical_fix_rate);
              physical_fix_minute = specialCompanyRates.special_physical_fix_minute;
              old_sub_rate = convert_dot_to_comma(specialCompanyRates.special_physical_sub_rate);
              physical_sub_rate = convert_dot_to_comma(specialCompanyRates.special_physical_sub_rate);
              physical_sub_minute = specialCompanyRates.special_physical_sub_minute;
              min_km = specialCompanyRates.special_min_km;
              old_per_km_rate = convert_dot_to_comma(specialCompanyRates.special_per_km_rate);
              per_km_rate = convert_dot_to_comma(specialCompanyRates.special_per_km_rate);
              old_transport_fee_fix_rate = convert_dot_to_comma(specialCompanyRates.special_transport_fee_fix_rate);
              transport_fee_minute = specialCompanyRates.special_transport_fee_minute;
              old_transport_fee_sub_rate = convert_dot_to_comma(specialCompanyRates.special_transport_fee_sub_rate);
              transport_fee_sub_minute = specialCompanyRates.special_transport_fee_sub_minute;
              transport_fee_fix_rate = convert_dot_to_comma(specialCompanyRates.special_transport_fee_fix_rate);
              transport_fee_sub_rate = convert_dot_to_comma(specialCompanyRates.special_transport_fee_sub_rate);
              transport_fee_agreed = specialCompanyRates.special_transport_fee_agreed;
            } else {
              var calcRemainKm = order_data.total_distance - (Number(translatorData.min_km) * 2);
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'remain_km', value: calcRemainKm} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'compensation_agreed', value:translatorData.compensation_agreed} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'transport_fee_agreed', value:translatorData.transport_fee_agreed} as any});
              if(calcRemainKm > 0 && translatorData.compensation_agreed==1){
                calcPerKmRate = calcRemainKm * Number(translatorData.per_km_rate);
              }
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_km_rate', value: convert_to_decimal_format(String(calcPerKmRate))} as any});
              old_rate = convert_dot_to_comma(translatorData.physical_fix_rate);
              physical_fix_rate = convert_dot_to_comma(translatorData.physical_fix_rate);
              physical_fix_minute = translatorData.physical_fix_minute;
              old_sub_rate = convert_dot_to_comma(translatorData.physical_sub_rate);
              physical_sub_rate = convert_dot_to_comma(translatorData.physical_sub_rate);
              physical_sub_minute = translatorData.physical_sub_minute;
              min_km = translatorData.min_km;
              old_per_km_rate = convert_dot_to_comma(translatorData.per_km_rate);
              per_km_rate = convert_dot_to_comma(translatorData.per_km_rate);
              old_transport_fee_fix_rate = convert_dot_to_comma(translatorData.transport_fee_fix_rate);
              transport_fee_minute = translatorData.transport_fee_minute;
              old_transport_fee_sub_rate = convert_dot_to_comma(translatorData.transport_fee_sub_rate);
              transport_fee_sub_minute = translatorData.transport_fee_sub_minute;
              transport_fee_fix_rate = convert_dot_to_comma(translatorData.transport_fee_fix_rate);
              transport_fee_sub_rate = convert_dot_to_comma(translatorData.transport_fee_sub_rate);
              transport_fee_agreed = translatorData.transport_fee_agreed;
            }
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_rate', value: old_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'physical_fix_rate', value: physical_fix_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'physical_fix_minute', value: physical_fix_minute} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_sub_rate', value: old_sub_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'physical_sub_rate', value: physical_sub_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'physical_sub_minute', value: physical_sub_minute} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'min_km', value: min_km} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_per_km_rate', value: old_per_km_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'per_km_rate', value: per_km_rate} as any});
            
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_transport_fee_fix_rate', value: old_transport_fee_fix_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'transport_fee_fix_rate', value: transport_fee_fix_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'transport_fee_minute', value: transport_fee_minute} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_transport_fee_sub_rate', value: old_transport_fee_sub_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'transport_fee_sub_rate', value: transport_fee_sub_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'transport_fee_sub_minute', value: transport_fee_sub_minute} as any});

            var totalTravelRates:number = 0;
            var totalTravelExtraRates:number = 0;
            if(transport_fee_agreed==1){
              if(order_data.travel_time > transport_fee_minute) {
                var calcTravelExtraMinutes = order_data.travel_time - (transport_fee_minute * 2);
                if(calcTravelExtraMinutes >= transport_fee_sub_minute && Number(transport_fee_sub_minute) > 0){
                  var extraTransportMinutesSlots: number = Math.ceil(Number(calcTravelExtraMinutes)/Number(transport_fee_sub_minute));
                  if(extraTransportMinutesSlots==0){
                    extraTransportMinutesSlots = 1;
                  }
                  totalTravelExtraRates = getExtraCalc(extraTransportMinutesSlots, convert_comma_to_dot(transport_fee_sub_rate), Number(convert_comma_to_dot(transport_fee_sub_rate)));
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_transport_minutes_slots', value: extraTransportMinutesSlots} as any});
                  totalTravelRates = (Number(convert_comma_to_dot(transport_fee_fix_rate)) * 2) + Number(totalTravelExtraRates); 
                } else {
                  var extraTransportMinutesSlots: number = 1;
                  totalTravelExtraRates = Number(convert_comma_to_dot(transport_fee_sub_rate));
                  dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_transport_minutes_slots', value: extraTransportMinutesSlots} as any});
                  totalTravelRates = (Number(convert_comma_to_dot(transport_fee_fix_rate)) * 2) + Number(convert_comma_to_dot(transport_fee_sub_rate));  
                }
              } else {
                  totalTravelRates = (Number(convert_comma_to_dot(transport_fee_fix_rate)) * 2);
              }
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_transport_rate', value: convert_to_decimal_format(String(totalTravelExtraRates.toFixed(2)))} as any});
            }
            

            var totalDiff = 0;
            var minuteDiff = 0;
            if(order_data.complete_order_to && order_data.status==5) {
              var timeEnd = new Date(order_data.order_date +' '+ order_data.complete_order_to).getTime();
            } else {
              var timeEnd = new Date(order_data.order_date +' '+ order_data.order_to).getTime();
            }
            var timeStart = new Date(order_data.order_date +' '+ order_data.order_from).getTime();
            
            totalDiff = timeEnd - timeStart;
            minuteDiff = Math.ceil(totalDiff / 60000);
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_time', value: minuteDiff} as any});

            var totalRates:number = 0;
            var totalExtraRates:number = 0;
            if(minuteDiff > physical_fix_minute){
              var calcExtraMinutes = minuteDiff - physical_fix_minute;
              if(calcExtraMinutes >= physical_sub_minute && Number(physical_sub_minute) > 0){
                var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes)/Number(physical_sub_minute));
                if(extraMinutesSlots==0){
                  extraMinutesSlots = 1;
                }
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
                totalExtraRates = getExtraCalc(extraMinutesSlots, convert_comma_to_dot(physical_sub_rate), Number(convert_comma_to_dot(physical_sub_rate)));
                totalRates = Number(convert_comma_to_dot(physical_fix_rate)) + Number(totalExtraRates) + (calcPerKmRate) + (totalTravelRates);
              } else {
                var extraMinutesSlots: number = 1;
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
                totalExtraRates = Number(convert_comma_to_dot(physical_sub_rate));
                totalRates = Number(convert_comma_to_dot(physical_fix_rate)) + Number(convert_comma_to_dot(physical_sub_rate)) + (calcPerKmRate) + (totalTravelRates);
              }
            } else {
              totalRates = Number(convert_comma_to_dot(physical_fix_rate))+ (calcPerKmRate) + (totalTravelRates);  
            }
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_extra_rates', value: convert_to_decimal_format(String(totalExtraRates.toFixed(2)))} as any});
            if(extra_per){
              var translatorRateObj: any = {
                extra_holiday_percentage: extra_per,
                fix_rate: physical_fix_rate,
                fix_minute: physical_fix_minute,
                sub_rate: physical_sub_rate,
                sub_minute: physical_sub_minute
              }
              var holiday_extra_rate: any = getHolidayCalc(state.order_data,translatorRateObj);
              if(holiday_extra_rate){
                //var before_extra_rate = totalRates;
                //totalRates = totalRates * ((100+Number(convert_comma_to_dot(extra_per)))/100);
                //var extraPerRate = totalRates - before_extra_rate;
                totalRates += holiday_extra_rate;
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_per_rate', value: convert_to_decimal_format(String(holiday_extra_rate.toFixed(2)))} as any});
              }  
            }
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
          } else if(state.order_data.translation_type_id==3){
            var old_rate:any = 0;
            var video_fix_rate: any = 0;
            var video_fix_minute: any = 0;
            var old_sub_rate: any = 0;
            var video_sub_rate: any = 0;
            var video_sub_minute: any = 0;

            if(specialCompanyRates){
              old_rate = convert_dot_to_comma(specialCompanyRates.special_video_fix_rate);
              video_fix_rate = convert_dot_to_comma(specialCompanyRates.special_video_fix_rate);
              video_fix_minute = specialCompanyRates.special_video_fix_minute;
              old_sub_rate = convert_dot_to_comma(specialCompanyRates.special_video_sub_rate);
              video_sub_rate = convert_dot_to_comma(specialCompanyRates.special_video_sub_rate);
              video_sub_minute = specialCompanyRates.special_video_sub_minute;
            } else {
              old_rate = convert_dot_to_comma(translatorData.video_fix_rate);
              video_fix_rate = convert_dot_to_comma(translatorData.video_fix_rate);
              video_fix_minute = translatorData.video_fix_minute;
              old_sub_rate = convert_dot_to_comma(translatorData.video_sub_rate);
              video_sub_rate = convert_dot_to_comma(translatorData.video_sub_rate);
              video_sub_minute = translatorData.video_sub_minute;
            }

            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_rate', value: old_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'video_fix_rate', value: video_fix_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'video_fix_minute', value: video_fix_minute} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_sub_rate', value: video_sub_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'video_sub_rate', value: video_sub_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'video_sub_minute', value: video_sub_minute} as any});

            var totalDiff = 0;
            var minuteDiff = 0;
            if(order_data.complete_order_to && order_data.status==5) {
              var timeEnd = new Date(order_data.order_date +' '+ order_data.complete_order_to).getTime();
            } else {
              var timeEnd = new Date(order_data.order_date +' '+ order_data.order_to).getTime();
            }
            var timeStart = new Date(order_data.order_date +' '+ order_data.order_from).getTime();
            
            totalDiff = timeEnd - timeStart;
            minuteDiff = Math.ceil(totalDiff / 60000);
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_time', value: minuteDiff} as any});

            var totalRates:number = 0;
            var totalExtraRates:number = 0;
            if(minuteDiff > video_fix_minute){
              var calcExtraMinutes = minuteDiff - video_fix_minute;
              if(calcExtraMinutes >= video_sub_minute && Number(video_sub_minute) > 0){
                var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes)/Number(video_sub_minute));
                if(extraMinutesSlots==0){
                  extraMinutesSlots = 1;
                }
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
                totalExtraRates = getExtraCalc(extraMinutesSlots, convert_comma_to_dot(video_sub_rate), Number(convert_comma_to_dot(video_sub_rate)));
                totalRates = Number(convert_comma_to_dot(video_fix_rate)) + Number(totalExtraRates); 
              } else {
                var extraMinutesSlots: number = 1;
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
                totalExtraRates = Number(convert_comma_to_dot(video_sub_rate));
                totalRates = Number(convert_comma_to_dot(video_fix_rate)) + Number(convert_comma_to_dot(video_sub_rate));
              }
            } else {
              totalRates = Number(convert_comma_to_dot(video_fix_rate));
            }
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_extra_rates', value: convert_to_decimal_format(String(totalExtraRates.toFixed(2)))} as any});
            if(extra_per){
              var translatorRateObj: any = {
                extra_holiday_percentage: extra_per,
                fix_rate: video_fix_rate,
                fix_minute: video_fix_minute,
                sub_rate: video_sub_rate,
                sub_minute: video_sub_minute
              }
              var holiday_extra_rate: any = getHolidayCalc(state.order_data,translatorRateObj);
              if(holiday_extra_rate){
                //var before_extra_rate = totalRates;
                //totalRates = totalRates * ((100+Number(convert_comma_to_dot(extra_per)))/100);
                //var extraPerRate = totalRates - before_extra_rate;
                totalRates += holiday_extra_rate;
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_per_rate', value: convert_to_decimal_format(String(holiday_extra_rate.toFixed(2)))} as any});
              }  
            }
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
          } else if(state.order_data.translation_type_id==5){
            var old_rate:any = 0;
            var tel_message_fix_rate: any = 0;
            var telephone_sub_rate: any = 0;
            var total_tel_message_fix_rate: any = 0;
            if(specialCompanyRates){
              old_rate = convert_dot_to_comma(specialCompanyRates.special_tel_message_fix_rate);
              tel_message_fix_rate = convert_dot_to_comma(specialCompanyRates.special_tel_message_fix_rate);
              //total_tel_message_fix_rate = Number(convert_comma_to_dot(specialCompanyRates.special_tel_message_fix_rate)) * state.form.total_strokes;
              total_tel_message_fix_rate = Number(convert_comma_to_dot(specialCompanyRates.special_tel_message_fix_rate));
            } else {
              old_rate = convert_dot_to_comma(translatorData.tel_message_fix_rate);
              tel_message_fix_rate = convert_dot_to_comma(translatorData.tel_message_fix_rate);
              //total_tel_message_fix_rate = Number(convert_comma_to_dot(translatorData.tel_message_fix_rate)) * state.form.total_strokes;
              total_tel_message_fix_rate = Number(convert_comma_to_dot(translatorData.tel_message_fix_rate));
            }
            var totalRates: number = Number(convert_comma_to_dot(total_tel_message_fix_rate));
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_rate', value: tel_message_fix_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'tel_message_fix_rate', value: tel_message_fix_rate} as any});

            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
          } else if(state.order_data.translation_type_id==6){
            var old_rate:any = 0;
            var per_word_rate: any = 0;
            var min_word: any = 0;
            var totalRates: number
            if(specialCompanyRates){
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'is_special_company', value: 1} as any});
              old_rate = convert_dot_to_comma(specialCompanyRates.special_per_word_rate);
              per_word_rate = convert_dot_to_comma(specialCompanyRates.special_per_word_rate);
              min_word = specialCompanyRates.special_min_word ? specialCompanyRates.special_min_word: 0;
            } else {
              old_rate = convert_dot_to_comma(translatorData.per_word_rate);
              per_word_rate = convert_dot_to_comma(translatorData.per_word_rate);
              min_word = translatorData.min_word ? translatorData.min_word: 0;
            }
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_rate', value: per_word_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'per_word_rate', value: per_word_rate} as any});
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'min_word', value: min_word} as any});

            if(state.form.total_words){
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_words', value: state.form.total_words} as any});
              if(Number(state.form.total_words) >= min_word){
                totalRates = Number(state.form.total_words) * Number(convert_comma_to_dot(per_word_rate));
              } else {
                totalRates = Number(state.form.min_word) * Number(convert_comma_to_dot(per_word_rate));
              }
              
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
            }
          }
        });
      }
    }

    function handleBlur(e) {
        var extra_per: any;
        if(state.form.holiday_charge_agreed==1 && state.form.extra_holiday_percentage){
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_holiday_percentage', value: convert_to_decimal_format(String(state.form.extra_holiday_percentage))} as any});
          extra_per = convert_comma_to_dot(state.form.extra_holiday_percentage);
        }

        if(state.order_data.translation_type_id==1){
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'telephone_fix_rate', value: convert_to_decimal_format(String(state.form.telephone_fix_rate))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'telephone_sub_rate', value: convert_to_decimal_format(String(state.form.telephone_sub_rate))} as any});
          var totalDiff = 0;
          var minuteDiff = 0;
          if(order_data.complete_order_to && order_data.status==5) {
            var timeEnd = new Date(order_data.order_date +' '+ order_data.complete_order_to).getTime();
          } else {
            var timeEnd = new Date(order_data.order_date +' '+ order_data.order_to).getTime();
          }
          var timeStart = new Date(order_data.order_date +' '+ order_data.order_from).getTime();
          
          totalDiff = timeEnd - timeStart;
          minuteDiff = Math.ceil(totalDiff / 60000);

          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_time', value: minuteDiff} as any});
          var difference:number = 0;
          var totalRates:number = 0;
          var totalExtraRates:number = 0;

          if(minuteDiff > state.form.telephone_fix_minute){
            var calcExtraMinutes = minuteDiff - state.form.telephone_fix_minute;
            if(calcExtraMinutes >= state.form.telephone_sub_minute && Number(state.form.telephone_sub_minute) > 0){
              var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes)/Number(state.form.telephone_sub_minute));
              if(extraMinutesSlots==0){
                extraMinutesSlots = 1;
              }
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
              totalExtraRates = getExtraCalc(extraMinutesSlots,  convert_comma_to_dot(state.form.telephone_sub_rate), Number(convert_comma_to_dot(state.form.telephone_sub_rate)));
              totalRates = Number(convert_comma_to_dot(state.form.telephone_fix_rate)) + Number(totalExtraRates);
            } else {
              var extraMinutesSlots: number = 1;
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
              totalExtraRates = Number(convert_comma_to_dot(state.form.telephone_sub_rate));
              totalRates = Number(convert_comma_to_dot(state.form.telephone_fix_rate)) + Number(state.form.telephone_sub_rate);
            }
            difference = totalRates - Number(convert_comma_to_dot(state.form.old_total));
          } else {
            totalRates = Number(convert_comma_to_dot(state.form.telephone_fix_rate));
            difference = Number(convert_comma_to_dot(state.form.telephone_fix_rate)) - Number(convert_comma_to_dot(state.form.old_total));
          }
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_extra_rates', value: convert_to_decimal_format(String(totalExtraRates.toFixed(2)))} as any});
          if(extra_per){
            var translatorRateObj: any = {
                extra_holiday_percentage: state.form.extra_holiday_percentage,
                fix_rate: state.form.telephone_fix_rate,
                fix_minute: state.form.telephone_fix_minute,
                sub_rate: state.form.telephone_sub_rate,
                sub_minute: state.form.telephone_sub_minute
              }
            var holiday_extra_rate: any = getHolidayCalc(state.order_data,translatorRateObj);
            if(holiday_extra_rate){
              //var before_extra_rate = totalRates;
              //totalRates = totalRates * ((100+Number(extra_per))/100);
              //var extraPerRate = totalRates - before_extra_rate;
              totalRates += holiday_extra_rate;
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_per_rate', value: convert_to_decimal_format(String(holiday_extra_rate.toFixed(2)))} as any});
              difference = totalRates - Number(convert_comma_to_dot(state.form.old_total));
            }
          }
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'difference', value: convert_to_decimal_format(String(difference.toFixed(2)))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(String(state.form.old_total))} as any});
        } else if(state.order_data.translation_type_id==2){
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'physical_fix_rate', value: convert_to_decimal_format(String(state.form.physical_fix_rate))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'physical_sub_rate', value: convert_to_decimal_format(String(state.form.physical_sub_rate))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'per_km_rate', value: convert_to_decimal_format(String(state.form.per_km_rate))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'transport_fee_fix_rate', value: convert_to_decimal_format(String(state.form.transport_fee_fix_rate))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'transport_fee_sub_rate', value: convert_to_decimal_format(String(state.form.transport_fee_sub_rate))} as any});
          var calcRemainKm:number = order_data.total_distance - (Number(state.form.min_km) * 2);
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'remain_km', value: calcRemainKm} as any});
          var calcPerKmRate:number = 0;
          if(calcRemainKm > 0 && state.form.compensation_agreed==1){
            calcPerKmRate = calcRemainKm * Number(convert_comma_to_dot(state.form.per_km_rate));
          }
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_km_rate', value: convert_to_decimal_format(String(calcPerKmRate))} as any});
          var totalTravelRates:number = 0;
          var totalTravelExtraRates:number = 0;
          if(state.form.transport_fee_agreed==1){
            if(order_data.travel_time > state.form.transport_fee_minute) {
              var calcTravelExtraMinutes = order_data.travel_time - (state.form.transport_fee_minute * 2);
              if(calcTravelExtraMinutes >= state.form.transport_fee_sub_minute && Number(state.form.transport_fee_sub_minute) > 0){
                var extraTransportMinutesSlots: number = Math.ceil(Number(calcTravelExtraMinutes)/Number(state.form.transport_fee_sub_minute));
                if(extraTransportMinutesSlots==0){
                  extraTransportMinutesSlots = 1;
                }
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_transport_minutes_slots', value: extraTransportMinutesSlots} as any});
                totalTravelExtraRates = getExtraCalc(extraTransportMinutesSlots, convert_comma_to_dot(state.form.transport_fee_sub_rate), Number(convert_comma_to_dot(state.form.transport_fee_sub_rate)));
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_transport_rate', value: convert_to_decimal_format(String(totalTravelExtraRates.toFixed(2)))} as any});
                totalTravelRates = (Number(convert_comma_to_dot(state.form.transport_fee_fix_rate)) * 2) + Number(totalTravelExtraRates); 
              } else {
                var extraTransportMinutesSlots: number = 1;
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_transport_minutes_slots', value: extraTransportMinutesSlots} as any});
                totalTravelExtraRates = Number(convert_comma_to_dot(state.form.transport_fee_sub_rate));
                dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_transport_rate', value: convert_to_decimal_format(String(totalTravelExtraRates.toFixed(2)))} as any});
                totalTravelRates = (Number(convert_comma_to_dot(state.form.transport_fee_fix_rate)) * 2) + Number(convert_comma_to_dot(state.form.transport_fee_sub_rate));  
              }
            } else {
                totalTravelRates = (Number(convert_comma_to_dot(state.form.transport_fee_fix_rate)) * 2);
            }
          }

          var totalDiff = 0;
          var minuteDiff = 0;
          if(order_data.complete_order_to && order_data.status==5) {
            var timeEnd = new Date(order_data.order_date +' '+ order_data.complete_order_to).getTime();
          } else {
            var timeEnd = new Date(order_data.order_date +' '+ order_data.order_to).getTime();
          }
          var timeStart = new Date(order_data.order_date +' '+ order_data.order_from).getTime();
          
          totalDiff = timeEnd - timeStart;
          minuteDiff = Math.ceil(totalDiff / 60000);

          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_time', value: minuteDiff} as any});
          var difference:number = 0;
          var totalRates:number = 0;
          var totalExtraRates:number = 0;
          if(minuteDiff > state.form.physical_fix_minute){
            var calcExtraMinutes = minuteDiff - state.form.physical_fix_minute;
            var extraPerRate:number = 0;
            if(calcExtraMinutes >= state.form.physical_sub_minute && Number(state.form.physical_sub_minute) > 0){
              var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes)/Number(state.form.physical_sub_minute));
              if(extraMinutesSlots==0){
                extraMinutesSlots = 1;
              }
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
              totalExtraRates = getExtraCalc(extraMinutesSlots,  convert_comma_to_dot(state.form.physical_sub_rate), Number(convert_comma_to_dot(state.form.physical_sub_rate)));
              totalRates = Number(convert_comma_to_dot(state.form.physical_fix_rate)) + Number(totalExtraRates) + (calcPerKmRate) + (totalTravelRates);
            } else {
              var extraMinutesSlots: number = 1;
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
              totalExtraRates = Number(convert_comma_to_dot(state.form.physical_sub_rate));
              totalRates = Number(convert_comma_to_dot(state.form.physical_fix_rate)) + Number(convert_comma_to_dot(state.form.physical_sub_rate)) + (calcPerKmRate) + (totalTravelRates);
            }
          } else {
            totalRates = Number(convert_comma_to_dot(state.form.physical_fix_rate)) + (calcPerKmRate) + (totalTravelRates);
          }
          difference = totalRates - Number(convert_comma_to_dot(state.form.old_total));
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_extra_rates', value: convert_to_decimal_format(String(totalExtraRates.toFixed(2)))} as any});
          if(extra_per){
            var translatorRateObj: any = {
                extra_holiday_percentage: state.form.extra_holiday_percentage,
                fix_rate: state.form.physical_fix_rate,
                fix_minute: state.form.physical_fix_minute,
                sub_rate: state.form.physical_sub_rate,
                sub_minute: state.form.physical_sub_minute
              }
            var holiday_extra_rate: any = getHolidayCalc(state.order_data,translatorRateObj);
            if(holiday_extra_rate){
              //var before_extra_rate = totalRates;
              //totalRates = totalRates * ((100+Number(extra_per))/100);
              //var extraPerRate = totalRates - before_extra_rate;
              totalRates += holiday_extra_rate;
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_per_rate', value: convert_to_decimal_format(String(holiday_extra_rate.toFixed(2)))} as any});
              difference = totalRates - Number(convert_comma_to_dot(state.form.old_total));
            }
          }
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'difference', value: convert_to_decimal_format(String(difference.toFixed(2)))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});  
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(String(state.form.old_total))} as any});
        } else if(state.order_data.translation_type_id==3){
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'video_fix_rate', value: convert_to_decimal_format(String(state.form.video_fix_rate))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'video_sub_rate', value: convert_to_decimal_format(String(state.form.video_sub_rate))} as any});
          var totalDiff = 0;
          var minuteDiff = 0;

          if(order_data.complete_order_to && order_data.status==5) {
            var timeEnd = new Date(order_data.order_date +' '+ order_data.complete_order_to).getTime();
          } else {
            var timeEnd = new Date(order_data.order_date +' '+ order_data.order_to).getTime();
          }
          var timeStart = new Date(order_data.order_date +' '+ order_data.order_from).getTime();
          
          totalDiff = timeEnd - timeStart;
          minuteDiff = Math.ceil(totalDiff / 60000);

          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_time', value: minuteDiff} as any});
          var difference:number = 0;

          var totalRates:number = 0;
          var totalExtraRates:number = 0;
          if(minuteDiff > state.form.video_fix_minute){
            var calcExtraMinutes = minuteDiff - state.form.video_fix_minute;
            if(calcExtraMinutes >= state.form.video_sub_minute && Number(state.form.video_sub_minute) > 0){
              var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes)/Number(state.form.video_sub_minute));
              if(extraMinutesSlots==0){
                extraMinutesSlots = 1;
              }
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
              totalExtraRates = getExtraCalc(extraMinutesSlots,  convert_comma_to_dot(state.form.video_sub_rate), Number(convert_comma_to_dot(state.form.video_sub_rate)));
              totalRates = Number(convert_comma_to_dot(state.form.video_fix_rate)) + Number(totalExtraRates); 
            } else {
              var extraMinutesSlots: number = 1;
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_minutes_slots', value: extraMinutesSlots} as any});
              totalExtraRates = Number(convert_comma_to_dot(state.form.video_sub_rate));
              totalRates = Number(convert_comma_to_dot(state.form.video_fix_rate)) + Number(convert_comma_to_dot(state.form.video_sub_rate));
            }
            difference = totalRates - Number(convert_comma_to_dot(state.form.old_total));
          } else {
            totalRates = Number(convert_comma_to_dot(state.form.video_fix_rate));
            difference = Number(convert_comma_to_dot(state.form.video_fix_rate)) - Number(convert_comma_to_dot(state.form.old_total));  
          }
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_extra_rates', value: convert_to_decimal_format(String(totalExtraRates.toFixed(2)))} as any});
          if(extra_per){
            var translatorRateObj: any = {
                extra_holiday_percentage: state.form.extra_holiday_percentage,
                fix_rate: state.form.video_fix_rate,
                fix_minute: state.form.video_fix_minute,
                sub_rate: state.form.video_sub_rate,
                sub_minute: state.form.video_sub_minute
              }
            var holiday_extra_rate: any = getHolidayCalc(state.order_data,translatorRateObj);
            if(holiday_extra_rate){
              //var before_extra_rate = totalRates;
              //totalRates = totalRates * ((100+Number(extra_per))/100);
              //var extraPerRate = totalRates - before_extra_rate;
              totalRates += holiday_extra_rate;
              dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_per_rate', value: convert_to_decimal_format(String(holiday_extra_rate.toFixed(2)))} as any});
              difference = totalRates - Number(convert_comma_to_dot(state.form.old_total));
            }
          }
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'difference', value: convert_to_decimal_format(String(difference.toFixed(2)))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(String(state.form.old_total))} as any});
        } else if(state.order_data.translation_type_id==5) {
          var total_tel_message_fix_rate: any = 0;
          //total_tel_message_fix_rate = Number(convert_comma_to_dot(state.form.tel_message_fix_rate)) * state.form.total_strokes;
          total_tel_message_fix_rate = Number(convert_comma_to_dot(state.form.tel_message_fix_rate));
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'tel_message_fix_rate', value: convert_to_decimal_format(String(state.form.tel_message_fix_rate))} as any});

          var difference:number = 0;
          var totalRates:number = 0;
          if(total_tel_message_fix_rate > 0){
            totalRates = Number(convert_comma_to_dot(String(total_tel_message_fix_rate)));
          }
          
          difference = totalRates - Number(convert_comma_to_dot(state.form.old_total));
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'difference', value: convert_to_decimal_format(String(difference.toFixed(2)))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(String(state.form.old_total))} as any});
        } else if(state.order_data.translation_type_id==6) {
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'per_word_rate', value: convert_to_decimal_format(String(state.form.per_word_rate))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'min_word', value: state.form.min_word} as any});
          var difference: number = 0;
          var totalRates: number = 0;
          if(state.form.total_words){
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'total_words', value: state.form.total_words} as any});
            if(Number(state.form.total_words) >= state.form.min_word){
              totalRates = Number(state.form.total_words) * Number(convert_comma_to_dot(state.form.per_word_rate));
            } else {
              totalRates = Number(state.form.min_word) * Number(convert_comma_to_dot(state.form.per_word_rate));
            }
          }
          
          difference = totalRates - Number(convert_comma_to_dot(state.form.old_total));
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'difference', value: convert_to_decimal_format(String(difference.toFixed(2)))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'fix_price', value: convert_to_decimal_format(String(totalRates.toFixed(2)))} as any});
          dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'old_total', value: convert_to_decimal_format(String(state.form.old_total))} as any});
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        if($("#fix_price_form").valid()){
          fixOrderRates(state.order_id, state.order_data.translator_id, state.form).then(res => {
            showNotification('success', res.message);
            FixPriceSubjectService.hidePopup();
          });
        }
    }

    function chkSpecialCompany(specialData) {
      var specialRatesData: any = null;
      if(state.order_data.company.is_special_company==1){
        let getCompanyId = state.order_data.company.id;
        let getEmail = state.order_data.company.email.split('@');
        if(specialData){
          specialData.filter(function(item){
            if((getEmail.length > 0 && item.special_domain && item.special_domain.toLowerCase() == getEmail[1].toLowerCase()) || item.company_id == getCompanyId){
              specialRatesData = item;
            }
          });
        }
      }
      return specialRatesData;
    }

    function getHolidayData(orderData, translatorDetails, isSpecial) {
      if(state.order_data.translation_type_id==5 || state.order_data.translation_type_id==6) {
        return null;
      }
      var previousDate: string;
      var nextDate: string;
      var orderTime: string;
      if(orderData.order_from){
        orderTime = orderData.order_from;
      }
      if(orderData.complete_order_to && orderData.status==5){
        orderTime = orderData.complete_order_to;
      }
      else if(orderData.order_to){
        orderTime = orderData.order_to;
      }

      var d = new Date(orderData.order_date+' '+orderTime);
      if(parseInt(orderTime)>=0 && parseInt(orderTime)<=8){
        d.setDate(d.getDate() - 1);
      }
      var setYear = d.getFullYear();
      var setMonth: any;
      if(d.getMonth() + 1 <= 9){
        setMonth = '0' + (d.getMonth() + 1);
      } else {
        setMonth = d.getMonth() + 1;
      }
      var setDate: any;
      if(d.getDate() <= 9){
        setDate = '0' + d.getDate();
      } else {
        setDate = d.getDate();  
      }
      previousDate = setYear + '-' + setMonth +'-'+ setDate;
      var d2 = new Date(d.getTime() + 86400000);
      var setYear2 = d2.getFullYear();
      var setMonth2: any;
      if(d2.getMonth() + 1 <= 9){
        setMonth2 = '0' + (d2.getMonth() + 1);
      } else {
        setMonth2 = d2.getMonth() + 1;
      }
      var setDate2: any;
      if(d2.getDate() <= 9){
        setDate2 = '0' + d2.getDate();
      } else {
        setDate2 = d2.getDate();  
      }
      nextDate = setYear2 + '-' + setMonth2 +'-'+ setDate2;
      if(isSpecial==1 && translatorDetails.special_holiday_charge_agreed==1 && Date.parse(state.order_data.order_date +' '+ orderTime) >= Date.parse(previousDate +' '+ '17:00') && Date.parse(state.order_data.order_date +' '+ orderTime) <= Date.parse(nextDate +' '+ '08:00')){
        return convert_dot_to_comma(translatorDetails.special_extra_holiday_percentage) ? convert_dot_to_comma(translatorDetails.special_extra_holiday_percentage): null;
      }
      else if(isSpecial==0 && translatorDetails.holiday_charge_agreed==1 && Date.parse(state.order_data.order_date +' '+ orderTime) >= Date.parse(previousDate +' '+ '17:00') && Date.parse(state.order_data.order_date +' '+ orderTime) <= Date.parse(nextDate +' '+ '08:00')) {
        return convert_dot_to_comma(translatorDetails.extra_holiday_percentage) ? convert_dot_to_comma(translatorDetails.extra_holiday_percentage): null;  
      }
      return null;
    }

    function getHolidayCalc(orderData, translatorDetails) {
      if(state.order_data.translation_type_id==5 || state.order_data.translation_type_id==6) {
        return null;
      }
      var previousDate: string;
      var nextDate: string;
      var orderTime1: string;
      var orderTime2: string;
      if(orderData.order_from){
        orderTime1 = orderData.order_from;
      }
      if(orderData.complete_order_to && orderData.status==5){
        orderTime2 = orderData.complete_order_to;
      }
      else if(orderData.order_to){
        orderTime2 = orderData.order_to;
      }

      var d = new Date(orderData.order_date+' '+orderTime1);
      if(parseInt(orderTime1)>=0 && parseInt(orderTime1)<=8){
        d.setDate(d.getDate() - 1);
      }
      var setYear = d.getFullYear();
      var setMonth: any;
      if(d.getMonth() + 1 <= 9){
        setMonth = '0' + (d.getMonth() + 1);
      } else {
        setMonth = d.getMonth() + 1;
      }
      var setDate: any;
      if(d.getDate() <= 9){
        setDate = '0' + d.getDate();
      } else {
        setDate = d.getDate();  
      }
      previousDate = setYear + '-' + setMonth +'-'+ setDate;
      var d2 = new Date(d.getTime() + 86400000);
      var setYear2 = d2.getFullYear();
      var setMonth2: any;
      if(d2.getMonth() + 1 <= 9){
        setMonth2 = '0' + (d2.getMonth() + 1);
      } else {
        setMonth2 = d2.getMonth() + 1;
      }
      var setDate2: any;
      if(d2.getDate() <= 9){
        setDate2 = '0' + d2.getDate();
      } else {
        setDate2 = d2.getDate();  
      }
      nextDate = setYear2 + '-' + setMonth2 +'-'+ setDate2;
      var calcRate:any = null;
      if(Date.parse(state.order_data.order_date +' '+ orderTime1) >= Date.parse(previousDate +' '+ '17:00') && Date.parse(state.order_data.order_date +' '+ orderTime1) <= Date.parse(nextDate +' '+ '08:00')){
        var totalPer = translatorDetails.extra_holiday_percentage ? convert_dot_to_comma(translatorDetails.extra_holiday_percentage): '0,00';
        if(totalPer!='0,00'){
          var calcTime = Date.parse(state.order_data.order_date +' '+ orderTime2) - Date.parse(state.order_data.order_date +' '+ orderTime1);
          var calcMinutes = Math.ceil(calcTime/60000);
          if(calcMinutes > translatorDetails.fix_minute){
            var remainMin = calcMinutes - translatorDetails.fix_minute;
            if(Number(translatorDetails.sub_minute) > 0){
              var remainMinSlot = Math.ceil(Number(remainMin)/Number(translatorDetails.sub_minute));  
            } else {
              var remainMinSlot = 0;
            }
            var remainRateCalc = Number(convert_comma_to_dot(translatorDetails.sub_rate)) * remainMinSlot;
            calcRate = (Number(convert_comma_to_dot(translatorDetails.fix_rate)) * ((100+Number(convert_comma_to_dot(totalPer)))/100)) - Number(convert_comma_to_dot(translatorDetails.fix_rate));
            var calcExtraRate = (remainRateCalc * ((100+Number(convert_comma_to_dot(totalPer)))/100)) - remainRateCalc;
            calcRate += calcExtraRate;
            var extra_consider_amt: number = Number(convert_comma_to_dot(translatorDetails.fix_rate)) + remainRateCalc;
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_consider_amt', value: convert_to_decimal_format(String(extra_consider_amt.toFixed(2)))} as any});
          } else {
            calcRate = (Number(convert_comma_to_dot(translatorDetails.fix_rate)) * ((100+Number(convert_comma_to_dot(totalPer)))/100)) - Number(convert_comma_to_dot(translatorDetails.fix_rate));
            var extra_consider_amt: number = Number(convert_comma_to_dot(translatorDetails.fix_rate));
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_consider_amt', value: convert_to_decimal_format(String(extra_consider_amt.toFixed(2)))} as any});
          }
        }
      } else if(Date.parse(state.order_data.order_date +' '+ orderTime2) >= Date.parse(previousDate +' '+ '17:00') && Date.parse(state.order_data.order_date +' '+ orderTime2) <= Date.parse(nextDate +' '+ '08:00')){
        var totalPer = translatorDetails.extra_holiday_percentage ? convert_dot_to_comma(translatorDetails.extra_holiday_percentage): '0,00';
        if(totalPer!='0,00'){
          var calcTime = Date.parse(state.order_data.order_date +' '+ orderTime2) - Date.parse(state.order_data.order_date +' '+ '17:00');
          var calcMinutes = Math.ceil(calcTime/60000);
          if(calcMinutes > translatorDetails.fix_minute){
            var remainMin = calcMinutes - translatorDetails.fix_minute;
            if(Number(translatorDetails.sub_minute) > 0){
              var remainMinSlot = Math.ceil(Number(remainMin)/Number(translatorDetails.sub_minute));  
            } else {
              var remainMinSlot = 0;
            }
            var remainRateCalc = Number(convert_comma_to_dot(translatorDetails.sub_rate)) * remainMinSlot;
            calcRate = (Number(convert_comma_to_dot(translatorDetails.fix_rate)) * ((100+Number(convert_comma_to_dot(totalPer)))/100)) - Number(convert_comma_to_dot(translatorDetails.fix_rate));
            var calcExtraRate = (remainRateCalc * ((100+Number(convert_comma_to_dot(totalPer)))/100)) - remainRateCalc;
            calcRate += calcExtraRate;
            var extra_consider_amt: number = Number(convert_comma_to_dot(translatorDetails.fix_rate)) + remainRateCalc;
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_consider_amt', value: convert_to_decimal_format(String(extra_consider_amt.toFixed(2)))} as any});
          } else {
            calcRate = (Number(convert_comma_to_dot(translatorDetails.fix_rate)) * ((100+Number(convert_comma_to_dot(totalPer)))/100)) - Number(convert_comma_to_dot(translatorDetails.fix_rate));
            var extra_consider_amt: number = Number(convert_comma_to_dot(translatorDetails.fix_rate));
            dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'extra_consider_amt', value: convert_to_decimal_format(String(extra_consider_amt.toFixed(2)))} as any});
          }
        }
      }
      return calcRate;
    }

    const {order_data} = state;

    function formDataReset(e:any) {
        e.preventDefault();
        var fix_price = document.getElementById("fix_price") as HTMLInputElement;
        fix_price.value = ''; 
    }

    function fixPriceCalculation(e:any, translator_id:number, id:number) {
        e.preventDefault();
        FixPriceCalculationSubjectService.showPopup(order_data, 'active', translator_id, id);
    }

    function timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      if(rminutes > 0){
        return rhours + " hour(s) and " + rminutes + " minute(s)";
      }
      return rhours + " hour(s)";
    }

  return(
    <Modal
      show={state.show}
      onHide={() => { dispatch({type: ActionType.HIDE_POPUP}) } }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form onSubmit={handleSubmit} id="fix_price_form">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {Lang.set_price} - #{order_data.order_no}
            {order_data.translation_type_id==1 &&
              <p style={{fontSize:12,margin:0}}>Type: Telefon-tolkning</p>
            }
            {order_data.translation_type_id==2 &&
              <p style={{fontSize:12,margin:0}}>Type: Fremmøde-tolkning</p>
            }
            {order_data.translation_type_id==3 &&
              <p style={{fontSize:12,margin:0}}>Type: Video-tolkning</p>
            }
            {order_data.translation_type_id==5 &&
              <p style={{fontSize:12,margin:0}}>Type: Telefonbesked Tolkning</p>
            }
            {order_data.translation_type_id==6 &&
              <p style={{fontSize:12,margin:0}}>Type: Skriftlig Oversættelse</p>
            }
            <p style={{fontSize:12,margin:0}}>Date: {order_data.order_date}</p>
            <p style={{fontSize:12,margin:0}}>From: {order_data.order_from}</p>
            <p style={{fontSize:12,margin:0}}>To: {order_data.order_to}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
                {order_data.company.is_special_company == 1 && state.form.is_special_company == 1 &&
                  <div className={'col-md-12'} >
                    <div className={'form-group'}>
                      <strong>Special Company: {order_data.company.name}</strong>
                    </div>
                  </div>
                }
                <div className={'col-md-12'} >
                  <div className={'form-group'}>
                    <input type={'text'} id={'fix_price'} name={'fix_price'} className={'form-control'} value={state.form.fix_price || "" } onChange={handleChange} placeholder={Lang.enter_price} readOnly={true} required/>
                  </div>
                </div>
                <div className={"col-md-12 col-lg-12"}>
                    <div className="form-group">
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <label>{Lang.is_special_agreement} <span className={'required'}>*</span></label>
                                <Select id="is_special_agreement" value={(state.form.is_special_agreement !== 0)?state.form.is_special_agreement:'0' || ''} name="is_special_agreement" onChange={handleChange} className="form-control">
                                    <option value="">{Lang.select}</option>
                                    <option value={1}>{Lang.yes}</option>
                                    <option value={0}>{Lang.no}</option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          {state.form.is_special_agreement == 1 &&
            <table className="table">
              <thead>
                <tr>
                  <th style={{width:"35%"}}>Description</th>
                  <th style={{width:"25%"}}>Specification</th>
                  <th style={{width:"5%"}}></th>
                  <th style={{width:"20%"}}>Rate</th>
                  <th style={{width:"5%"}}></th>
                  <th style={{width:"10%"}}>Total</th>
                </tr>
              </thead>
              <tbody>
                {order_data.translation_type_id==1 &&
                  <React.Fragment>
                    <tr>
                      <td>{state.form.telephone_fix_minute} minutter, Påbegyndt: {state.form.old_rate}</td>
                      <td><span className="input-label">{state.form.telephone_fix_rate} X 1</span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="input-box"><input type="text" className="form-control" id="telephone_fix_rate" value={state.form.telephone_fix_rate} name="telephone_fix_rate" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`fix_rate`)} style={{width:100}} autoComplete="off"/></span><span className="input-label">kr.</span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="underline-text">{state.form.telephone_fix_rate}</span></td>
                    </tr>
                    {state.form.extra_minutes_slots > 0 &&
                      <tr>
                        <td>{state.form.telephone_sub_minute} minutter, Efterfølgende {state.form.old_sub_rate}</td>
                        <td><span className="input-label">{state.form.telephone_sub_rate} X {state.form.extra_minutes_slots}</span></td>
                        <td><span className="input-label">=</span></td>
                        <td><span className="input-box"><input type="text" className="form-control" id="telephone_sub_rate" value={state.form.telephone_sub_rate} name="telephone_sub_rate" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`sub_rate`)} style={{width:100}} autoComplete="off" readOnly={state.form.extra_minutes_slots > 0  ? false : true}/></span><span className="input-label">kr.</span></td>
                        <td><span className="input-label">=</span></td>
                        <td><span className="underline-text">{state.form.total_extra_rates}</span></td>
                      </tr>
                    }
                  </React.Fragment>
                }
                {order_data.translation_type_id==2 &&
                  <React.Fragment>
                    <tr>
                      <td>{state.form.physical_fix_minute} minutter, Påbegyndt: {state.form.old_rate}</td>
                      <td><span className="input-label">{state.form.physical_fix_rate} X 1</span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="input-box"><input type="text" className="form-control" id="physical_fix_rate" value={state.form.physical_fix_rate} name="physical_fix_rate" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`fix_rate`)} style={{width:100}} autoComplete="off"/></span><span className="input-label">kr.</span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="underline-text">{state.form.physical_fix_rate}</span></td>
                    </tr>
                    {state.form.extra_minutes_slots > 0 &&
                      <tr>
                        <td>{state.form.physical_sub_minute} minutter, Efterfølgende {state.form.old_sub_rate}</td>
                        <td><span className="input-label">{state.form.physical_sub_rate} X {state.form.extra_minutes_slots}</span></td>
                        <td><span className="input-label">=</span></td>
                        <td><span className="input-box"><input type="text" className="form-control" id="physical_sub_rate" value={state.form.physical_sub_rate} name="physical_sub_rate" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`sub_rate`)} style={{width:100}} autoComplete="off" readOnly={state.form.extra_minutes_slots > 0  ? false : true}/></span><span className="input-label">kr.</span></td>
                        <td><span className="input-label">=</span></td>
                        <td><span className="underline-text">{state.form.total_extra_rates}</span></td>
                      </tr>
                    }
                    {state.form.compensation_agreed == 1 && state.order_data.total_distance > 0 &&
                      <tr>
                        <td>KM (Both way)</td>
                        <td><span className="input-label">[{convert_dot_to_comma(state.order_data.total_distance)} - {convert_to_decimal_format(String(Number(state.form.min_km * 2)))}] = {convert_to_decimal_format(String(state.form.remain_km))}</span></td>
                        <td><span className="input-label">X</span></td>
                        <td><span className="input-box"><input type="text" className="form-control" id="per_km_rate" value={state.form.per_km_rate} name="per_km_rate" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`per_km_rate`)} style={{width:100}} autoComplete="off"/></span><span className="input-label">kr.</span></td>
                        <td><span className="input-label">=</span></td>
                        <td><span className="underline-text">{state.form.total_km_rate}</span></td>
                      </tr>
                    }
                    {state.form.transport_fee_agreed == 1 && state.order_data.travel_time > 0 &&
                      <React.Fragment>
                        <tr>
                          <td>{state.form.transport_fee_minute} minutter, Påbegyndt: {state.form.old_transport_fee_fix_rate} (Both way)</td>
                          <td><span className="input-label">{state.form.transport_fee_fix_rate} X 2</span></td>
                          <td><span className="input-label">=</span></td>
                          <td><span className="input-box"><input type="text" className="form-control" id="transport_fee_fix_rate" value={state.form.transport_fee_fix_rate} name="transport_fee_fix_rate" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`fix_rate`)} style={{width:100}} autoComplete="off"/></span><span className="input-label">kr.</span></td>
                          <td><span className="input-label">=</span></td>
                          <td><span className="underline-text">{convert_to_decimal_format(String(Number(convert_comma_to_dot(state.form.transport_fee_fix_rate) * 2)))}</span></td>
                        </tr>
                        <tr>
                          <td>{state.form.transport_fee_sub_minute} minutter, Efterfølgende {state.form.old_transport_fee_sub_rate} (Both way)</td>
                          <td><span className="input-label">{state.form.transport_fee_sub_rate} X {state.form.extra_transport_minutes_slots}</span></td>
                          <td><span className="input-label">=</span></td>
                          <td><span className="input-box"><input type="text" className="form-control" id="transport_fee_sub_rate" value={state.form.transport_fee_sub_rate} name="transport_fee_sub_rate" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`sub_rate`)} style={{width:100}} autoComplete="off"/></span><span className="input-label">kr.</span></td>
                          <td><span className="input-label">=</span></td>
                          <td><span className="underline-text">{state.form.extra_transport_rate}</span></td>
                        </tr>
                      </React.Fragment>
                    }
                  </React.Fragment>
                }
                {order_data.translation_type_id==3 &&
                  <React.Fragment>
                    <tr>
                      <td>{state.form.video_fix_minute} minutter, Påbegyndt: {state.form.old_rate}</td>
                      <td><span className="input-label">{state.form.video_fix_rate} X 1</span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="input-box"><input type="text" className="form-control" id="video_fix_rate" value={state.form.video_fix_rate} name="video_fix_rate" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`fix_rate`)} style={{width:100}} autoComplete="off"/></span><span className="input-label">kr.</span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="underline-text">{state.form.video_fix_rate}</span></td>
                    </tr>
                    {state.form.extra_minutes_slots > 0 &&
                      <tr>
                        <td>{state.form.video_sub_minute} minutter, Efterfølgende {state.form.old_sub_rate}</td>
                        <td><span className="input-label">{state.form.video_sub_rate} X {state.form.extra_minutes_slots}</span></td>
                        <td><span className="input-label">=</span></td>
                        <td><span className="input-box"><input type="text" className="form-control" id="video_sub_rate" value={state.form.video_sub_rate} name="video_sub_rate" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`sub_rate`)} style={{width:100}} autoComplete="off" readOnly={state.form.extra_minutes_slots > 0  ? false : true}/></span><span className="input-label">kr.</span></td>
                        <td><span className="input-label">=</span></td>
                        <td><span className="underline-text">{state.form.total_extra_rates}</span></td>
                      </tr>
                    }
                  </React.Fragment>
                }
                {order_data.translation_type_id==5 &&
                  <React.Fragment>
                    {/*<tr>
                      <td>{Lang.total_strokes}:</td>
                      <td><span className="input-label">-</span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="input-box"><input type="text" className="form-control" id="total_strokes" value={state.form.total_strokes} name="total_strokes" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`total_strokes`)} style={{width:100}} autoComplete="off"/></span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="underline-text">{state.form.total_strokes}</span></td>
                    </tr>*/}
                    <tr>
                      <td>{Lang.tel_message_fix_rate}: {state.form.old_rate} for 30 minutter</td>
                      <td><span className="input-label">{state.form.tel_message_fix_rate} X 1</span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="input-box"><input type="text" className="form-control" id="tel_message_fix_rate" value={state.form.tel_message_fix_rate} name="tel_message_fix_rate" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`fix_rate`)} style={{width:100}} autoComplete="off"/></span><span className="input-label">kr.</span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="underline-text">{state.form.tel_message_fix_rate}</span></td>
                    </tr>
                  </React.Fragment>
                }
                {order_data.translation_type_id==6 &&
                  <React.Fragment>
                    <tr>
                      <td>{Lang.min_word}:</td>
                      <td><span className="input-label">-</span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="input-label">{state.form.min_word}</span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="underline-text">{state.form.min_word}</span></td>
                    </tr>
                    <tr>
                      <td>{Lang.total_words}:</td>
                      <td><span className="input-label">-</span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="input-box"><input type="text" className="form-control" id="total_words" value={state.form.total_words} name="total_words" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`total_words`)} style={{width:100}} autoComplete="off"/></span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="underline-text">{state.form.total_words}</span></td>
                    </tr>
                    <tr>
                      <td>{Lang.per_word_rate}:</td>
                      <td><span className="input-label">{state.form.per_word_rate} X {state.form.total_words}</span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="input-box"><input type="text" className="form-control" id="per_word_rate" value={state.form.per_word_rate} name="per_word_rate" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`total_words`)} style={{width:100}} autoComplete="off"/></span></td>
                      <td><span className="input-label">=</span></td>
                      <td><span className="underline-text">{state.form.fix_price}</span></td>
                    </tr>
                  </React.Fragment>
                }
                {state.form.holiday_charge_agreed==1 &&
                  <tr>
                    <td>{Lang.extra_holiday_percentage}</td>
                    <td><span className="input-label">[{state.form.extra_consider_amt} X {state.form.extra_holiday_percentage}]/100</span></td>
                    <td><span className="input-label">=</span></td>
                    <td><span className="input-box"><input type="text" className="form-control" id="extra_holiday_percentage" value={state.form.extra_holiday_percentage} name="extra_holiday_percentage" onChange={handleChange}  onBlur={handleBlur} placeholder={Lang.getString(`extra_holiday_percentage`)} style={{width:100}} autoComplete="off"/></span><span className="input-label">kr.</span></td>
                    <td><span className="input-label">=</span></td>
                    <td><span className="underline-text">{state.form.extra_per_rate}</span></td>
                  </tr>
                }
                {order_data.translation_type_id!==5 && order_data.translation_type_id!==6 &&
                  <React.Fragment>
                    <tr>
                      <td colSpan={3}>Total Time</td>
                      <td colSpan={3} align="right"><span className="underline-text">{timeConvert(state.form.total_time)}</span></td>
                    </tr>
                  </React.Fragment>
                }
                { state.form.old_total == state.form.fix_price &&
                    <React.Fragment>
                      <tr>
                        <td colSpan={5}>Den oprindelig pris</td>
                        <td><span className="underline-text">{state.form.fix_price}</span></td>
                      </tr>
                      <tr>
                        <td colSpan={5}>Ekstra</td>
                        <td><span className="underline-text">0,00</span></td>
                      </tr>
                    </React.Fragment>
                }
                { state.form.old_total !== state.form.fix_price &&
                  <React.Fragment>
                    <tr>
                      <td colSpan={5}>Den oprindelig pris</td>
                      <td><span className="underline-text">{state.form.old_total}</span></td>
                    </tr>
                    <tr>
                      <td colSpan={5}>Ekstra</td>
                      <td><span className="underline-text">{state.form.difference}</span></td>
                    </tr>
                  </React.Fragment>
                }
                <tr>
                  <td colSpan={5}>Den ny endelig pris</td>
                  <td><span className="underline-text">{state.form.fix_price}</span></td>
                </tr>
              </tbody>
            </table>
          }
          { state.fixPrice.length > 0 &&
            <div className={'col-md-12'}>
              <table className={'table table-bordered table-hover'}>
                <tbody>
                <tr>
                  <td>{Lang.translators}</td>
                  <td>{Lang.view_calculation}</td>
                </tr>
                {state.fixPrice.map((item: OrderFixPriceModel, index: number) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.translator_name}</td>
                      <td> <button className={'btn btn-info'} data-skin={'dark'} title={Lang.view_calculation} onClick={(e) => fixPriceCalculation(e, item.translator_id, item.id)}>{Lang.view_calculation}</button></td>
                    </tr>
                  )
                })}
                </tbody>

              </table>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button onClick={(e)=>formDataReset(e)} className=" btn btn-default hvr-rectangle-out btnMove  mobiNone" type="button">{Lang.reset}</button>&nbsp;
          <SubmitButton className={'btn btn-primary'} label={Lang.submit}/>
        </Modal.Footer>
      </form>
      <FixPriceCalculation/>
    </Modal>
  )


}