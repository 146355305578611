import { Field, ErrorMessage } from 'formik';
import React from 'react';
import { 
    CountryFlags,
    Select2Wrapper
 } from '../../../common/Select2';

const PhoneInput = (props) => {
    const { countryCode, mobileNum, required, label, containerProps } = props;
    const { countryCode_name, countryCode_value, countryCode_handleChange, countryCode_options, ...countryCode_props } = countryCode;
    const { mobileNum_name, ...mobileNum_props } = mobileNum;

    return (
        <div
            className="form-group col-lg-6"
            {...containerProps}
        >
            <label>
                {label}
                {
                    required && (<span className={"required"}>*</span>)
                }
            </label>
            <div className="row">
                <div className="col-lg-4">
                    <Field
                        name={countryCode_name}
                        id={countryCode_name}
                        >
                        {
                            ({field, form, meta}) => (
                                <Select2Wrapper
                                    className="form-control boxed-input"
                                    id={countryCode_name}
                                    data={{
                                        placeholder: 'Select Options',
                                        data: countryCode_options,
                                        templateResult: CountryFlags,
                                        templateSelection: CountryFlags,
                                    }}
                                    {...field}
                                    {...countryCode_props}
                                />
                            )
                        }
                    </Field>
                    <ErrorMessage render={msg => <div className="text-danger">{msg}</div>} name={countryCode_name} />
                </div>
                <div className="col-lg-8">
                    <Field
                        type="text"
                        className="form-control"
                        id={mobileNum_name}
                        name={mobileNum_name}
                        {...mobileNum_props}
                    />
                    <ErrorMessage render={msg => <div className="text-danger">{msg}</div>} name={mobileNum_name} />
                </div>
            </div>
        </div>
    );
}

export default PhoneInput;
