import React from "react";
import { Formik, FormikHelpers } from "formik";
import Axios from "../../../common/AxoisClient";
import moment from "moment";
import Lang from "../../../common/lang/Lang";
import { RouteComponentProps, RouteProps, withRouter } from 'react-router-dom';

// components
import { showNotification, SubmitButton } from "../../../common";
import FormController from "../formik/FormController";

// styles
import callStyle from "./CallStyle.module.scss";

// interfaces
interface Props extends RouteComponentProps {
  user_id: number
  setShowFinalizeInterview: React.Dispatch<React.SetStateAction<boolean>>
}

interface FormValues {
  interview_taken_at: Date | string // calculated on submit
  audio_call_file: File; // not required so no yup validation.
}

const FinalizeInterviewForm: React.FC<Props> = (props) => {
  const { user_id, setShowFinalizeInterview, match, location, history } = props;

  const initial_values: FormValues = {
    audio_call_file: null,
    interview_taken_at: null
  };
  
  const handleSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
    ) => void | Promise<any> = (values) => {
      let form_data = new FormData();
      form_data.append('interview_taken_at', moment().format('YYYY-MM-DD HH:mm:ss'));
      values.audio_call_file !== null && form_data.append('audio_call_file', values.audio_call_file);
      Axios.post(`users/translator/interview-taken/${user_id}`, form_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.status === 200) {
          const response_message = 'Interviewdato og interviewopkald er blevet opdateret';
          showNotification('success', response_message);
          setShowFinalizeInterview(false);
          history.push(`/users/translators/info/${user_id}`);
        }
      });
  };

  return (
    <Formik initialValues={initial_values} onSubmit={handleSubmit}>
      {(formik) => {
        const { handleReset, handleSubmit, values } = formik;
        return (
          <form onReset={handleReset} onSubmit={handleSubmit}>
            <FormController
              control="file"
              name="audio_call_file"
              label="Upload audio recording (optional)"
            />
            <span>
              Note: Audio recording is optional. By clicking the button below
              you set final interview status to completed.
            </span>
            <div className={callStyle.submit_button_container}>
              <SubmitButton
                className='btn btn-primary'
                label={Lang.submit}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default withRouter(FinalizeInterviewForm);
