import React, {useRef, useEffect} from 'react';
import _ from 'lodash';
import {DatePickerOptions} from "../Common";
import moment from "moment"

// styles
import sheet from './LeaveDatePicker.module.scss'
import { Icons } from '../Icon';

export interface LeaveDatePickerProps  extends React.InputHTMLAttributes<HTMLInputElement> {
  options?: DatepickerOptions
}


export function LeaveDatePickerWrapper (props: LeaveDatePickerProps) {

  const datetime_picker = useRef(null);

  useEffect(() => {
    const $el = datetime_picker.current;
    if($el !== null ){
      $el.readOnly = true;
      $($el).datepicker({...DatePickerOptions, ...props.options}).on('changeDate', function(ev: any) {
        props.onChange(ev)
      });
    }
  }, []);

  return(
    <label className={sheet.date_container}>
      <i className={`${Icons.calendar} ${sheet.date_icon}`} />
      <input className={`${props.className} ${sheet.date_input}`} type={'text'} ref={datetime_picker} {... _.omit(props, ['options', 'ref', 'className'])} />
    </label>
  )
}