import { ErrorMessage, Field } from 'formik';
import React from 'react';

const SelectInput = (props) => {
    const { label,
        name,
        options,
        placeholder,
        required,
        ...fieldProps } = props;
    ( placeholder && options[0].value !== '' ) && options.unshift({
        label: placeholder,
        value: "",
        className: "Select Options",
    })
    return (
        <div className="form-group col-lg-6">
            <label htmlFor={name}>
                {label}
                { required && (<span className={"required"}>*</span>) }
            </label>
            <Field
                name={name}
                id={name}
                as="select"
                {...fieldProps}
            >
                {
                    options.map(({ value, label, ...selectProps }) => (
                        <option {...selectProps} key={ value } value={ value }>{ label }</option>
                    ))
                }
            </Field>
            <ErrorMessage render={msg => <div className="text-danger">{msg}</div>} name={name} />
        </div>
    );
}

export default SelectInput;
