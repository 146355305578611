import Downshift from 'downshift'
import { cloneDeep, debounce, isEmpty, join, set, split } from 'lodash'
import React, { PropsWithChildren, useState } from 'react'
import { InlineLoader } from '../../../../common'
import Lang from '../../../../common/lang/Lang'
import { CompanyModel } from '../../../../model'
import { getCompanyList } from '../../../../services'
import { companySetup, Form } from '../../Data'

// styles
import sheet from './CustomerEmailInput.module.scss'
import '../../../../../DownshiftCommon.scss'
import SpecialCompanyItem from './SpecialCompanyItem'

interface Props {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  checkSpecialCompany: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  makeFormSpecialCompany: (item: ReturnType<typeof companySetup.specialCompany>) => void
  form: Form
}

/** This function appends the special company domain to the customer email */
export const appendCompanyDomain = (email: string, company_email: string): string => join([split(email, '@', 1)[0], split(split(company_email, ',')[0], '@')[1]], '@')

const CustomerEmailInput = ({ handleChange, value, checkSpecialCompany, makeFormSpecialCompany, form, children }: PropsWithChildren<Props>) => {
  const [Suggestions, setSuggestions] = useState<CompanyModel[]>([])
  /** This function updates the form state and creates the speical company suggestions */
  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e); setSuggestions(await getCompanyList(e.target.value)); }
  /** This function handles setting up the form when a special company is selected */
  const handleSelectSpecialCompany = (company: CompanyModel, stateAndHelpers, new_form: Form = set(cloneDeep(form), 'email', appendCompanyDomain(form.email, company.email))) => makeFormSpecialCompany(companySetup.specialCompany(company, new_form, true))
  
  return (
    <Downshift<CompanyModel> onChange={handleSelectSpecialCompany}>
      {({ getLabelProps, getInputProps, getMenuProps, getItemProps, isOpen, highlightedIndex }) => (
          <div className={"col-md-6 col-lg-6 input-container"}>
            <div className="form-group">
              <label {...getLabelProps()}>{Lang.email} <span className={'required'}>*</span></label>
              <InlineLoader>
                <input {...getInputProps({
                    name: 'email',
                    type: 'email',
                    autoComplete: 'none',
                    onChange: handleEmailChange,
                    value,
                    className: `form-control input ${isOpen && !isEmpty(Suggestions) && 'input_menu_open'}`,
                    placeholder: Lang.getString('email_placeholder'),
                    onBlur: checkSpecialCompany
                  })}
                />
              </InlineLoader>
              { children ?? null }
              {
                isOpen && !isEmpty(Suggestions) && (
                  <ul {...getMenuProps({ className: 'list' })}>
                    { Suggestions.map((item, index) => (
                      <SpecialCompanyItem item={item} index={index} getItemProps={getItemProps} highlightedIndex={highlightedIndex} />
                    )) }
                  </ul>
                )
              }
            </div>
          </div>
      )}
    </Downshift>
  )
}

export default CustomerEmailInput
