import React, {useState, useEffect} from 'react';
import {InlineLoaderService} from "../../services";

export function InlineLoader(props:any) {
  const [spinnerClass, setSpinnerClass] = useState('');

  useEffect(() => {
    InlineLoaderService.getLoading().subscribe((message: string) => {
      setSpinnerClass(message)
    });
  }, [spinnerClass])


  return(
    <div className={spinnerClass}>
      {props.children}
    </div>
  );
}