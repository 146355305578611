import React from "react";
import { ErrorMessage, Field } from "formik";

interface RadioInput {
  value: string;
  label: string;
  container_props?: React.HTMLAttributes<any>;
  field_props?: React.InputHTMLAttributes<any>;
  label_props?: React.LabelHTMLAttributes<any>;
}

export interface RadioProps {
  name: string;
  label: string;
  values: Array<RadioInput>;
  required?: boolean
  container_props?: React.HTMLAttributes<any>;
}
const RadioInput = (props: RadioProps) => {
  const {
    name,
    label,
    values,
    required = false,
    container_props = { className: "form-group" },
  } = props;
  return (
    <div {...container_props}>
      <label>
            {label}
            { required && (<span className={"required"}>*</span>) }
      </label>
      {values.map((item, index) => {
        const {
            value,
            label,
            container_props = {className: 'form-check'},
            field_props = {className: 'form-check-input'},
            label_props = {className: 'form-check-label'}
        } = item;
        return (
          <div key={index} {...container_props}>
            <Field type="radio" name={name} id={value} value={value} {...field_props} />
            <label htmlFor={value} {...label_props}>{label}</label>
          </div>
        );
      })}
      <ErrorMessage render={msg => <div className="text-danger">{msg}</div>} name={name} />
    </div>
  );
};

export default RadioInput;
