import Lang from "../../common/lang/Lang";

export const urls = {
  home: "/settings/",
  list: "/settings/customer-calculation/create",
}

export const ListBreadcrumb = [
  {
    label: Lang.settings,
    url: urls.home
  },
  {
    label: Lang.customer_calculation,
    url: "#"
  }
];

export const CreateBreadcrumb = [
  {
    label: Lang.settings,
    url: urls.home
  },
  {
    label: Lang.customer_calculation,
    url: urls.list
  },
   
];

