import React, { useEffect, useReducer, useRef } from "react";
import { OrderfeedbackService } from "../../../services";
import Lang from "../../../common/lang/Lang";
import { Modal } from "react-bootstrap";
import { Stars } from "../../../common/Stars";
import { RequestWaiting, SubmitButton, EditLink } from "../../../common";
import {
  InitialState,
  PopupState,
  ActionType,
  CAction,
  OrderFeedbackReducer,
} from "../order-reducers/OrderFeedbackReducer";
import { OrderfeedbackEditService } from "../../../services";
import { OrderFeedbackEditModel, OrderFeedbackModel } from "../../../model";

export function OrderFeedbackPopup() {
  const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(
    OrderFeedbackReducer,
    InitialState
  );

  useEffect(() => {
    OrderfeedbackService.getPopupState().subscribe((message: any) => {
      dispatch({ type: message.action, payload: message });
    });
  }, []);
  console.log("this is order data", state.history);

  if (state.show === false) {
    return null;
  }

  const { order_data, history } = state;

  return (
    <Modal
      show={state.show}
      onHide={() => {
        dispatch({ type: ActionType.HIDE_POPUP });
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form id="fix_price_form">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {Lang.order_feedback} - #{order_data.order_no}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={"row"}>
              <div className={"col-md-12"}>
                <div className={"col-md-12"}>
                  <table className={"table table-bordered table-hover"}>
                    <tbody>
                      <tr>
                        <td>{Lang.customer_name}</td>
                        <td>{Lang.message}</td>
                        <td>{Lang.rating}</td>
                        <td>{Lang.created_at}</td>
                        <td>{Lang.action}</td>
                      </tr>
                      {history.map((item: OrderFeedbackModel) => (
                        <FeedbackRow item={item} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </form>
    </Modal>
  );
}

interface RowProps {
  item: OrderFeedbackModel;
}

const FeedbackRow = (props: RowProps) => {
  const { user, message, rating, created_at } = props.item;
  return (
    <tr>
      <td>{(user as any)?.name ?? "Customer"}</td>
      <td>{message}</td>
      <td>
        <Stars value={rating} />
        <br />
      </td>
      <td>{created_at}</td>
      <td>
        <button
          className={"btn btn-success"}
          onClick={() =>
            OrderfeedbackEditService.showEditPopup(props.item as OrderFeedbackEditModel)
          }
          type={"button"}
        >
          Behandle{" "}
        </button>
      </td>
    </tr>
  );
};
