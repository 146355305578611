import _ from "lodash"
import moment from "moment"
import Axios from "../common/AxoisClient"
import { UserModel } from "../model"

interface UserSpecialActionsDefault {
  emails : {
    confirmation_email: boolean,
    reset_password: boolean
  },
  values: {
    email_verified_at: Date,
    blocked_from_login: 0 | 1
  }
}

type AdminActions = "emails.confirmation_email" | "emails.reset_password" | "values.email_verified_at" | "values.blocked_from_login"
export default class UserServices {
  /* PROPERTIES */
  public user: UserModel;

  public UserSpecialActionsDefault = {
    emails : { confirmation_email: false, reset_password: false },
    values: { email_verified_at: null, blocked_from_login: null }
  }

  /* CONSTRUCTOR */
  public constructor (user: UserModel) {
    this.user = user
  }

  private makeLoad = (item: AdminActions, value: boolean | string) => _.set(_.cloneDeep(this.UserSpecialActionsDefault), item, value)

  /* METHODS */
  /** This function sends the user an email verification email */
  public sendConfirmationEmail = () => Axios.post(`users/verify-send-email/${this.user.id}`, this.makeLoad('emails.confirmation_email', true))
  /** This function sends the user a password reset email */
  public sendPasswordReset = () => Axios.post(`users/verify-send-email/${this.user.id}`, this.makeLoad('emails.reset_password', true))
  /** This function verifies the user's email */
  public verifyEmail = () => Axios.post(`users/verify-send-email/${this.user.id}`, this.makeLoad('values.email_verified_at', moment().format('YYYY-MM-DD HH:mm:ss')))
  /** This function unblocks the user from logging in */
  public unblockLogin = () => Axios.post(`users/verify-send-email/${this.user.id}`, this.makeLoad('values.blocked_from_login', false))
}