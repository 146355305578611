import * as React from 'react';
import {default as Lang} from "../../common/lang/Lang"
import {
    Icons, ActionLink, EditLink, ShowRecords,
    IState as CommonState, SetWaitingArea,
} from "../../common";
import {RouteComponentProps} from "react-router";
import {actionButton} from "../../common/table/BlukActionOrderButtons";
import {RequestListBreadcrumb} from "./Data";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {OrderModel} from "../../model";
import {OrderInfo} from "./OrderInfo";
import _ from "lodash";
import {OrderActions} from "./OrderActions";
import {
  AssignTranslator, SendMailToCustomer, SendMailToTranslator, CancelOrder, OrderComments, FixPrice,
  HistoryPopup
} from "./popup";
import {CompleteOrderPopup} from "./popup/CompleteOrder";
import {list_key, orderRequestContentShow} from "../../helpers/OrderHelper";
import {CorrectionRequestPopup} from "./popup/CorrectionRequest";
import {OrdersinfoService,OrderCommentService} from "../../services";
import ActiveOrderFilter from './filter/ActiveOrderFilter';
import RequestOrderFilter from "./filter/RequestOrderFilter";



declare var window:any;

interface IState extends CommonState {
    ClaimData: Array<OrderModel>;
    ModalData?: OrderModel;
    show?: boolean;
    languages: Array<{value: string, label:string}>;
    companies: Array<{value: string, label:string}>;
}


export class RequestOrderslist extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();
  interval:any;

  componentDidMount() {
      OrdersinfoService.getPopupState().subscribe((res:any) => {
         this.setState({
          ModalData : res.data,
          show: true
        })
      });

      let __this = this;
      $('body').on('click','.completeCorrectionRequest',function () {           
          setTimeout(function () {
              SetWaitingArea('listing');
              __this.filterRecords();
          },1000);
      });
  }

  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
    return  this.state !== nextState;
 }

  showContent = (data: OrderModel, index: number) => {
    let cols = orderRequestContentShow(this.records, data, index, this.filterRecords);
    cols.push(this.records.current.createdTd("action", () => {
      return (
        <div>
          <ActionLink scope={'orders.index'} to={`/orders/order/info/${data.id}`} data-skin={'dark'} title={Lang.view_order} >
            <i className={`kt-nav__link-icon ${Icons.view}`} />
          </ActionLink>
          <EditLink scope={'orders.update'} to={`/orders/order/edit/${data.id}`} data-skin={'dark'}  title={Lang.edit_order} />
          <OrderActions order={data} refresh={'order'}/>
          <ActionLink scope={'orders.index'} to={'#'}    onClick={() =>OrderCommentService.showPopup(data)}  >
           <i className="la la-comments"/> <span className="commentCountBox">{data.comments_count}</span>
          </ActionLink>
        </div>
      )
    }));
    cols = _.compact(cols);
    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

  render() {

    return (
      <ListingLayout breadcrumb={RequestListBreadcrumb}>
          <ListHeader title={'order'} icon={Icons.order} >
            &nbsp;
          </ListHeader>
          <ListForm
            form={(
              <RequestOrderFilter getResults={this.filterRecords} languages={this.state !== null ? this.state.languages: []} companies={this.state !== null ? this.state.companies: []}/>
            )}
            table={(
              <ShowRecords
                url={"orders/request-changes-cancel"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={false}
                showCheckbox={false}
                actionButtons={actionButton({records: this.records, 'url': "orders/orders/bulk-action"})}
              />
            )}
          />
        {
          this.state !== null &&
          <OrderInfo
              show={this.state.show}
              onHide={() => this.setState({show: false})}
              orderinfo={this.state.ModalData}/>
        }


        <AssignTranslator refresh={this.filterRecords} />
        <SendMailToCustomer />
        <SendMailToTranslator />
        <CancelOrder refresh={this.filterRecords} />
        <OrderComments/>
        <FixPrice/>
        <HistoryPopup />
        <CompleteOrderPopup refresh={this.filterRecords}/>
        <CorrectionRequestPopup />
      </ListingLayout>
     
    )
  }
}