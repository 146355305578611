import React, { PropsWithChildren } from 'react';
import AddressInput from './AddressInput';
import CheckboxInput from './CheckboxInput';
import EmailInput from './EmailInput';
import FileUpload, { FileProps } from './FileUpload';
import PhoneInput from './PhoneInput';
import RadioInput, { RadioProps } from './RadioInput';
import SelectInput from './SelectInput';
import TextAreaInput from './TextAreaInput';
import TextInput from './TextInput';
import TranslationLangInput from './TranslationLangInput';

interface Props {
    control: 'text' | 'email' | 'phoneNum' | 'address' | 'translatorLanguageSelector' | 'textarea' | 'checkbox' | 'select' | 'radio' | 'file'
    [key: string]: any
}

const FormController: React.FC<PropsWithChildren<Props>> = (props) => {
    const { control, ...inputProps } = props;
    
    switch (control) {
        case 'text': return <TextInput {...inputProps} />;
        case 'email': return <EmailInput {...inputProps} />;
        case 'phoneNum': return <PhoneInput {...inputProps} />;
        case 'address': return <AddressInput {...inputProps} />;
        case 'translatorLanguageSelector': return <TranslationLangInput {...inputProps} />;
        case 'textarea': return <TextAreaInput {...inputProps} />;
        case 'checkbox': return <CheckboxInput {...inputProps} />;
        case 'select': return <SelectInput {...inputProps} />;
        case 'radio': return <RadioInput {...inputProps as RadioProps}/>
        case 'file': return <FileUpload {...inputProps as FileProps} />
    }
}

export default FormController;
