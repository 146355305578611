import React from 'react'
import { COLOR_PALETTE, InvoicingData } from '../Invoicing'
import { allJobs, INVOICE_JOB_STATUS } from '../InvoicingType'
import Heading from './Table/Heading'
import Row from './Table/Row'
import Table from './Table/Table'

//? styles
import sheet from './JobsTable.module.scss'
import { Icons } from '../../../common'

enum JOB_TYPES {
  orders = 'orders',
  batch = 'batch'
}

interface Props {
  allJobs: InvoicingData['allJobs']
  handlePageChange: (page: number) => void
}

const JobsTable = ({ handlePageChange, allJobs: { data: jobs, current_page, last_page, per_page } }:  Props) => {
  return (
    <>
      <div className={sheet.table_navigation}>
        <button className={sheet.table_nav_buttons} onClick={() => handlePageChange(current_page === 1 ? 1 : current_page - 1)}><i className={Icons.prev_page}/> Previous</button>
        <span className={sheet.page_number}>{current_page}</span>
        <button className={sheet.table_nav_buttons} onClick={() => handlePageChange(current_page === last_page ? current_page : current_page + 1)}>Next <i className={Icons.next_page}/></button>
      </div>
      <Table>
        <Heading />
        {
          jobs.map((job, index) => (
            <Row
            Type={Type(index + (per_page * (current_page - 1)) + 1)}
            Company={Company(job.company)}
            Status={Status(job.status)}
            Date={DateComp(job.created_at)}
            Orders={Orders(job.order_count)}
            />
            ))
          }
      </Table>
    </>
  )
}

//? Table Column definintions
const Type = (type: number) => <div style={{color: COLOR_PALETTE.grey}}>{type}</div>
const Company = ({ name, logo }: allJobs[number]['company']) => (
  <div className={sheet.company_column}>
    <div className={sheet.column_image}>
      <img src={logo} alt={name} />
    </div>
    <div>{name}</div>
  </div>
)
const determineStatusColor = (status: allJobs[number]['status']) => {
  switch (status) {
    case INVOICE_JOB_STATUS.WORKING:
      return COLOR_PALETTE.green
    case INVOICE_JOB_STATUS.SUCCESS:
      return '#2A9798'
    case INVOICE_JOB_STATUS.PLANNED:
      return COLOR_PALETTE.grey
    case INVOICE_JOB_STATUS.NEXT:
      return COLOR_PALETTE.blue
    case INVOICE_JOB_STATUS.FAIL:
      return COLOR_PALETTE.red
    case INVOICE_JOB_STATUS.RETRY:
      return COLOR_PALETTE.orange
    default:
      return COLOR_PALETTE.grey
  }
}
const Status = (status: allJobs[number]['status']) => ( 
  <div style={{ color: determineStatusColor(status)}}>
    {status}
  </div>)
const DateComp = (date: allJobs[number]['created_at'] ) => <div>{date}</div>
const Orders = (orders_count: allJobs[number]['order_count']) => <div>{orders_count}</div>
const Actions = () => <a href="#">Manage</a>

export default JobsTable