import * as React from 'react';
import {CreateBreadcrumb, IState} from "./Data";
import Lang from "../../common/lang/Lang";
import {RouteComponentProps} from "react-router";
import { WeLearnModel} from "../../model";
import {WeLearnValidations} from "./Validations";
import {CreateLayout} from "../../layout/CreateLayout";
import {CommonSubmitButton} from "../../common";
import {LearnService} from "../../services";

export class WeLearnCreate extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      form: {} as WeLearnModel,
    };
    WeLearnValidations();
  }

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.type === 'file' ? target.files[0] :  target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }


  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if($("#create_we_learn_form").valid()){
      LearnService.store(this.state.form).then(res => {
        this.props.history.push(LearnService.URLS.WE_LEARN.LIST);
      })
    }
  }

    resetForm = (event:any) => { 
        event.preventDefault();      
        this.setState({ form: { ...this.state.form, title: '', description:'', video: '', video_thumbnail:'', hidden_video: '', hidden_video_thumbnail:''}});
    }

  render() {
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'add_we_learn'}>
          <form className="kt-form kt-form--label-right" id={'create_we_learn_form'} onSubmit={this.handleSubmit}>
            <div className="kt-portlet__body text-center">
              <div className={'col-md-8'} style={{paddingTop:20}}>
                <div className="form-group row">
                  <label className={'col-2 col-form-label'}>{Lang.title} <span className={'required'}>*</span></label>
                  <div className="col-10">
                    <input type={'text'} onChange={this.handleChange} value={this.state.form.title || ''} name={'title'} className="form-control" placeholder={Lang.enter_title}/>
                  </div>
                </div>

                <div className="form-group row">
                  <label className={'col-2 col-form-label'}>{Lang.description} <span className={'required'}>*</span></label>
                  <div className="col-10">
                    <textarea onChange={this.handleChange} value={this.state.form.description} name={'description'} className="form-control" placeholder={Lang.enter_description} />
                  </div>
                </div>
                <div className="form-group row">
                  <label className={'col-2 col-form-label'}>{Lang.video} <span className={'required'}>*</span></label>
                  <div className="col-10">
                    <div className="custom-file">
                      <input type={'file'} onChange={this.handleChange}  name={'video'} className="custom-file-input" required={true}/>
                      <label className="custom-file-label" htmlFor="customFile"/>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className={'col-2 col-form-label'}>{Lang.video_thumbnail} <span className={'required'}>*</span></label>
                  <div className="col-10">
                    <div className="custom-file">
                      <input type={'file'} onChange={this.handleChange}  name={'video_thumbnail'} className="custom-file-input" required={true}/>
                      <label className="custom-file-label" htmlFor="customFile"/>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className='reset-btn-section'>
                <CommonSubmitButton/>
                <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn'} data-skin={'dark'} title={Lang.reset} onClick={this.resetForm}>{Lang.reset}</button>
            </div>


          </form>
        </CreateLayout>
    )
  }
}