
declare var window: any;
export const SmsTemplateValidations = function () {

  $(document).ready(function () {

    $( "#create_smsTemplate" ).validate({
      rules: {
        name: {
          required: true
        },
        type: {
          required: true
        },
      },

      invalidHandler: function(event, validator) {
        var alert = $('#kt_form_1_msg');
        alert.removeClass('kt--hide').show();
      },
    });
  });
}