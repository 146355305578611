import * as React from 'react';
import {OrderModel, OrderType} from "../../../model";
import _ from 'lodash';
import {getDistanceTravelTime} from "../../../services";
import Lang from "../../../common/lang/Lang";
import { Icons } from '../../../common';

// style
import sheet from './TravelDistance.module.scss'

interface DistanceProps {
  order: OrderModel;
  from: string;
}

interface DistanceState {
  total_distance: number;
  travel_time: number
  translator_address: string
  meeting_address: string
}

export default class TravelDistance extends React.Component<DistanceProps, DistanceState> {

  constructor(props){
    super(props)
    const {order} = this.props;
    this.state = {
      total_distance : order.total_distance,
      travel_time : order.travel_time,
      translator_address: order.translate_by?.address ?? "N/A",
      meeting_address: order.meeting_address
    }
  }

  show_distance = (order_id: number) => {
    getDistanceTravelTime(order_id).then(distance => {
      this.setState({
        ...distance
      })
    });

  };
  render() {

    const {order, from} = this.props;

    if(order.translation_type_id === OrderType.PHYSICAL){

      if(this.state.total_distance === undefined || this.state.total_distance === null || this.state.total_distance <= 0.00){
        return (
          <p style={{marginTop:10}}>
            <button className={'btn btn-default'} id={`show_hide_distance`}  onClick={(e) => this.show_distance(order.id)} title={'Show Distance'}
                    type={'button'}>
              Show Distance
            </button>
          </p>
        )
      } else {

        if(from === "listing"){
          return (
            <div className={sheet.container}>
              <div className={sheet.horizontal_container}>
                <i className={`${sheet.icon} ${Icons.order_physical}`} />
                <span>Fra: <span className={sheet.bold}>{this.state.translator_address}</span></span>
              </div>
              <div className={sheet.horizontal_container}>
                <div className={sheet.line}>&nbsp;</div>
                <div className={sheet.distance_info}> <i className={`${sheet.icon} ${Icons.handshake}`} /> {(this.state.total_distance / 2).toFixed(0)} KM <i className={`${sheet.icon} ${Icons.clock}`} /> {(this.state.travel_time)} Mins </div>
              </div>
              <div className={sheet.horizontal_container}>
                <i className={`${sheet.icon} ${Icons.order_physical}`} />
                <span>Til : <span className={sheet.bold}>{this.state.meeting_address}</span></span>
              </div>
            </div>
          )
        } else {
          return (
            <React.Fragment>
              <div className="kt-widget4__item">
                <span className="kt-widget4__title kt-widget4__title--light">
                  {Lang.total_distance}
                </span>
                <span className="kt-widget4__number kt-font-info">{(this.state.total_distance/2).toFixed(2)} KM</span>
              </div>

              <div className="kt-widget4__item">
                <span className="kt-widget4__title kt-widget4__title--light">
                {Lang.travel_time}
                </span>
                <span className="kt-widget4__number kt-font-info">{(this.state.travel_time/2).toFixed(2)} mins</span>
              </div>
            </React.Fragment>
          )
        }
      }
    } else {
      return null;
    }
  }
}