import * as React from 'react';
import {ClaimModel, ClaimStatus} from "../../model/Claim";
import {
    IState as CommonState,
    ShowRecords,
    Icons,
    ActionLink,
    ExportButton,
    getQueryStringParams,
    actionButton,
    SearchInput,
    SearchSelect,
    Select, showNotification, CreateLink
} from "../../common";
import Localization, {default as Lang} from "../../common/lang/Lang";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb} from "./Data";
import {OrderInfo} from "../../orders/orders/OrderInfo";
import {OrderModel, OrderStatus} from "../../model/Order";
import _ from "lodash";
import {ListHeader, ListingLayout, ListForm} from "../../layout/Listing";
import {Link} from "react-router-dom";
import API from "../../common/AxoisClient";
import {NotificationListNewService} from "../../services";
import {list_key} from "../../helpers/OrderHelper";

interface IState extends CommonState {
    ClaimData: Array<ClaimModel>;
    ModalData?: OrderModel;
    show?: boolean
    form: {
        like: {
            subject: string,
            reason: string,
        },
        equal: {
            status: number,
            order_no: number
        }
    }
}

export class ClaimsListing extends React.Component<RouteComponentProps, IState> {
state: IState;
records:React.RefObject<ShowRecords> = React.createRef();

UNSAFE_componentWillMount() {
        let old_data: any = getQueryStringParams("form");
        old_data = JSON.parse(old_data);
        this.setState({form:  {
            like: {
                subject: old_data ? old_data.like.subject : '',
                reason: old_data ? old_data.like.reason : ''
            },
            equal: {
                status: old_data ? old_data.equal.status : '',
                order_no: old_data ? old_data.equal.order_no : '',
            }
        }})
    }

    changeStatus = (event, claim_id) => {
        API.post(`orders/claim/change-status`,{id: claim_id, status: event.target.value}).then(response => {
            showNotification("success", Lang.claim_status_changed);
        })
    }

    shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      return  this.state !== nextState;}
    showContent = (data: ClaimModel, index: number) => {
      let cols = [];
      cols.push(...[
        // <td key={index}>{this.records.current.checkbox(data.id)}</td>,
        this.records.current.createdTd("id", index),
        this.records.current.createdTd("order_no",() => {
            if(data.order !== null){
                return (
                    <div>
                        <ActionLink scope={'orders.index'} onClick={() => this.setState({ModalData: data.order, show: true})} to={'#'}  data-skin={'dark'} title={Lang.view_order} >
                            {data.order.order_no}
                        </ActionLink>
                    </div>
                )
            }
           }),
        this.records.current.createdTd("creator_type", () => {
        if(data.creator_type === 1){
          return <span className={'badge'}>{Lang.claim_creator_type_admin}</span>
        }
        if(data.creator_type === 2){
          return <span className={'badge'}>{Lang.claim_creator_type_customer}</span>
        }
        if(data.creator_type === 3){
          return <span className={'badge'}>{Lang.claim_creator_type_translator}</span>
        }
      }),
      this.records.current.createdTd("name",() => {
          if(data.user !== null){
              return (
                  <div>
                      {data.user.name}
                  </div>
              )
          }
      }),
      //this.records.current.createdTd("name", data.user.name),
      this.records.current.createdTd("subject", data.subject),
      this.records.current.createdTd("reason", (data.reason.length>100)?data.reason.substr(0, 100)+' . . .':data.reason),
      /*this.records.current.createdTd("status", () => {
        if(data.status === 0){
          return <span className={'badge badge-primary'}>{Lang.claim_status_resolved}</span>
        }
        if(data.status === 1){
          return <span className={'badge badge-warning'}>{Lang.claim_status_new}</span>
        }
        if(data.status === 2){
          return <span className={'badge badge-danger'}>{Lang.claim_status_reject}</span>
        }
        if(data.status === 3){
          return <span className={'badge badge-warning'}>{Lang.claim_status_admin_replaed}</span>
        }
      }),*/

      this.records.current.createdTd("status", () => {
          return (
              <Select value={(data.status !== 0)?data.status:'0' || ''} onChange={(e) => this.changeStatus(e, data.id)}>
                  <option value={ClaimStatus.RESOLVED}>{Lang.claim_status_resolved}</option>
                  <option value={ClaimStatus.NEW_REQUEST}>{Lang.claim_status_new}</option>
                  <option value={ClaimStatus.REJECT}>{Lang.claim_status_reject}</option>
                  <option value={ClaimStatus.ADMIN_REPLIED}>{Lang.claim_status_admin_replaed}</option>
                  {/*<option value={ClaimStatus.TRANSLATOR_POINT_VIEW}>{Lang.claim_status_translator_point}</option>*/}
              </Select>
          )
      }),

      this.records.current.createdTd("created_at", data.created_at),

      this.records.current.createdTd("action", () => {
        return <ActionLink scope={'claim.view'} to={`/orders/claims/detail/${data.id}`} >
            <i className="flaticon-eye"/>
          </ActionLink>
        })
      ]);
      cols = _.compact(cols);

        return (
            <tr key={list_key(data.id)}>{cols}</tr>
        );
    }

     formDataReset = (e:any) => { 
        e.preventDefault();
        this.setState({
            form:  {
                like: {
                    subject: '',
                    reason: '',
                },
                equal: {
                    status: 0,
                    order_no: 0
                }
            }
        });     

        var formData = {
                       like: {
                            subject: '',
                            reason: '',
                        },
                        equal: {
                            status: 0,
                            order_no: 0
                        },
                       };

        this.setState({form: formData}, this.filterRecords);
    }


  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState({form: newAccess}, this.filterRecords);
  }
    
  rowActionButtons = (data: ClaimModel) => {
    return (
        <td>
            <ActionLink scope={'claim.view'} to={`/orders/claims/detail/${data.id}`} >
                <i className="flaticon-eye"/>
            </ActionLink>
        </td>
    )
  }

    filterRecords = () => {
        if( this.records && this.records.current !== null){
            this.records.current.setFormFields(this.state.form);
        }
    }

    render() {
        return (
            <ListingLayout breadcrumb={ListBreadcrumb}>
                <ListHeader title={'claims'} icon={Icons.claim} >
                    <ExportButton records={this.records} fileName={Lang.exportClaims}/>
                    &nbsp;
                    {/*<Link to={`/orders/claims/create`}  className="btn btn-brand btn-elevate btn-icon-sm"> <i className={Icons.add}/> {Localization.add_new}</Link>*/}
                    <CreateLink to={`/orders/claims/create`} scope={'claim.create'} />
                </ListHeader>
                <ListForm
                    form={( 
                        <div className="row align-items-center">
                            <SearchInput name={'form.like.subject'} value={this.state.form.like.subject} onChange={this.handleInputForm} placeholder={'Enter Subject'} />
                            <SearchInput name={'form.like.reason'} value={this.state.form.like.reason} onChange={this.handleInputForm} placeholder={'Enter Reason'} />
                            <SearchSelect name={'form.equal.status'} value={this.state.form.equal.status}  onChange={this.handleInputForm} options={[
                                {value:"", label: Localization.select},
                                {value:0, label: Lang.claim_status_resolved},
                                {value:1, label: Lang.claim_status_new},
                                {value:2, label: Lang.claim_status_reject},
                                {value:3, label: Lang.claim_status_admin_replaed},
                            ]}/>
                            <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn custom-reset-button'} data-skin={'dark'} title={Lang.reset} onClick={(e)=>this.formDataReset(e)}>{Lang.reset}</button>
                        </div>
                    )}
                    table={(
                        <ShowRecords
                            url={"orders/claim"}
                            showRecords={this.showContent}
                            ref={this.records}
                            isNeedAction={false}
                            showCheckbox={false}
                            rowActions={this.rowActionButtons}
                            actionButtons={actionButton({records: this.records, 'url': "orders/claims/bulk-action"})}
                        />
                    )}
                />
                  { this.state !== null &&
             <OrderInfo
              show={this.state.show}
              onHide={() => this.setState({show: false})}
              orderinfo={this.state.ModalData}/>
         }
            </ListingLayout>
            
        )
    }
}
