import Lang from "../../common/lang/Lang";

declare var window: any;
export const CompanyValidations = function () {

  $(document).ready(function () {

    $.validator.addMethod("multiple_emails",  (value: any, element: HTMLElement, params: any) =>{
      let all_emails = value.split(",");
      let flag = true;
      all_emails.map((item: string) => {
          if(!jQuery.validator.methods.email.call(this, item.trim(), element)){
            flag = false;
          }
        })
        return flag;
      },
      Lang.company_mul_email);



    $( "#create_company" ).validate({
      // define validation rules
      rules: {
        name: {
          required: true
        },
        email: {
          required: true,
          multiple_emails: true
        },
      },

      invalidHandler: function(event, validator) {
        var alert = $('#kt_form_1_msg');
        alert.removeClass('kt--hide').show();
      },
    });
  });
}