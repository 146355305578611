import Lang from "../../common/lang/Lang";

export const urls = {
    users: "/users",
    list: "/users/translators",
}

export const ListBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.translators,
        url: "#"
    }
];

export const CallBreadcrumb = [
    {
        label: Lang.translators,
        url: '/translators/all',
    },
    {
        label: Lang.interview,
        url: '#'
    }
]

export const CreateBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.translators,
        url: urls.list
    },
    {
        label: Lang.create,
        url: '#'
    }
];

export const EditBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.translators,
        url: urls.list
    },
    {
        label: Lang.edit,
        url: '#'
    }
];

export const TaxBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.translators,
        url: urls.list
    },
    {
        label: Lang.tax_payment,
        url: '#'
    }
];

export const RatesBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.translators,
        url: urls.list
    },
    {
        label: Lang.rates,
        url: '#'
    }
];

export const LanguageBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.translators,
        url: urls.list
    },
    {
        label: Lang.languages,
        url: '#'
    }
];
export const TranslatortypeBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.translators,
        url: urls.list
    },
    {
        label: Lang.translation_type,
        url: '#'
    }
];

export const ServicesBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.translators,
        url: urls.list
    },
    {
        label: Lang.services,
        url: '#'
    }
];
export const LeavesBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.translators,
        url: urls.list
    },
    {
        label: Lang.leaves,
        url: '#'
    }
];

export const ChangePwdBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.translators,
        url: urls.list
    },
    {
        label: Lang.change_password,
        url: '#'
    }
];

export const OrdersBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.translators,
        url: urls.list
    },
    {
        label: Lang.order,
        url: '#'
    }
];
export const NotificationBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.notification,
        url: '#'
    }
];
export const ActivityLogBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.translators,
        url: urls.list
    },
    {
        label: Lang.activity_logs,
        url: '#'
    }
];
