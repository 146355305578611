import * as React from "react";
import Lang from "../../common/lang/Lang";
import { Link } from "react-router-dom";
import API from "../../common/AxoisClient";
import { UserModel } from "../../model";
import Swal from "sweetalert2";
import { showNotification, Icons } from "../../common";
import { get_translator_gender_with_color } from "../../helpers/UserHelper";
import _ from "lodash";
import { CropProfileImage } from "./CropProfileImage";
import Cropper from "cropperjs";
var cropper: any;

interface TranslatorDSchemas {
  translator_id: number;
  active: string;
}

export class BasicTranslatorDetail extends React.Component<TranslatorDSchemas> {
  state: {
    user: UserModel;
    active: string;
  };

  constructor(props: TranslatorDSchemas) {
    super(props);
    this.state = {
      user: {} as UserModel,
      active: props.active,
    };
  }

  getData = () => {
    API.get(`users/translators/${this.props.translator_id}`).then((res) => {
      this.setState({
        user: res.data.translatorArr,
      });
    });
  };

  componentDidMount() {
    this.getData();
  }

  sendmail = () => {
    let confirmText = Lang.mail_confirm;
    Swal.fire({
      title: Lang.is_sure,
      text: confirmText,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.value) {
        let translator_id: any = this.state.user.id;
        API.post("users/translator/send-mail", { translator_id }).then(
          (response) => {
            showNotification("success", Lang.mail_send);
          }
        );
      }
    });
  };

  render() {
    const fontsize = { fontSize: "15px" };
    const TabsItems = [
      {
        title: Lang.translator_info,
        to: `/users/translators/info/${this.props.translator_id}`,
        active: "info",
        fontIcon: Icons.user,
        show: true,
      },
      {
        title: Lang.tax_payment,
        to: `/users/translators/info/${this.props.translator_id}/tax`,
        active: "tax",
        fontIcon: Icons.bank,
        show: true,
      },
      {
        title: Lang.services,
        to: `/users/translators/info/${this.props.translator_id}/services`,
        active: "services",
        fontIcon: Icons.comment,
        //count: this.state.user.translator !== undefined ? this.state.user.translator.translator_language_count : 0,
        show: true,
      },
      {
        title: Lang.rates,
        to: `/users/translators/info/${this.props.translator_id}/rates`,
        active: "rates",
        fontIcon: Icons.dollor,
        //count: this.state.user.translator !== undefined ? this.state.user.translator.translator_language_count : 0,
        show: true,
      },
      {
        title: Lang.translation_type,
        to: `/users/translators/info/${this.props.translator_id}/translatortype`,
        active: "translatortype",
        fontIcon: Icons.comment,
        show: false,
      },
      {
        title: Lang.order,
        to: `/users/translators/info/${this.props.translator_id}/order?form={"like":{"q":""},"between":{"start":""},"equal":{"translator_id":"${this.props.translator_id}"}}`,
        active: "order",
        fontIcon: Icons.order2,
        show: true,
      },
      {
        title: Lang.leaves,
        to: `/users/translators/info/${this.props.translator_id}/leaves`,
        active: "leaves",
        fontIcon: Icons.leaves,
        count:
          this.state.user.translator !== undefined
            ? this.state.user.translator.translator_leave_count
            : 0,
        show: true,
      },
      {
        title: Lang.recruitment_results,
        to: `/users/translators/info/${this.props.translator_id}/online-test`,
        active: "online-test",
        fontIcon: Icons.notes,
        show:
          this.state.user.translator !== undefined
            ? this.state.user.translator.send_online_test === 2 ||
              this.state.user.translator.interview_taken_at !== null
            : false,
      },
      {
        title: Lang.online_quiz,
        to: `/users/translators/info/${this.props.translator_id}/online-quiz`,
        active: "online-quiz",
        fontIcon: Icons.question,
        show: true,
      },
      {
        title: Lang.change_password,
        to: `/users/translators/info/${this.props.translator_id}/change-password`,
        active: "password",
        fontIcon: Icons.password2,
        show: true,
      },
      {
        title: Lang.notification,
        to: `/users/translators/info/${this.props.translator_id}/notification`,
        active: "notification",
        fontIcon: Icons.notification,
        show: true,
      },
      {
        title: Lang.activity_logs,
        to: `/users/translators/info/${this.props.translator_id}/activitylog`,
        active: "activitylog",
        fontIcon: Icons.users,
        show: true,
      },
    ];

    const { gender } = get_translator_gender_with_color(this.state.user);

    const uploadProfilePic = async (
      e: React.ChangeEvent<HTMLElement>,
      callback?: (doc: string) => void
    ) => {
      $("#updateProfilePictureOfUser").show();
      $("#ProfilePicturePleaseWait").hide();
      const target: any = e.target;

      var fileData: any = target.files[0];
      var reader: any = new FileReader();
      if (fileData.type.startsWith("image/")) {
        ($("#editTProfileImagePopup") as any).modal("show");
        reader.addEventListener(
          "load",
          function () {
            $("#uploadedImage1").attr("src", reader.result);
            setTimeout(function () {
              $("#uploadedImage1").css({ opacity: "1" });
              const image: any = document.getElementById("uploadedImage1");
              if (!_.isEmpty(cropper)) {
                cropper.destroy();
              }
              image.src = reader.result;
              cropper = new Cropper(image, {
                aspectRatio: 50 / 50,
                crop(event) {},
              });
            }, 500);
          },
          false
        );
        if (fileData) {
          reader.readAsDataURL(fileData);
        }
      }
      let this_popup: any = this;
      $("body").on("click", "#updateProfilePictureOfUser", function () {
        $("#updateProfilePictureOfUser").hide();
        $("#ProfilePicturePleaseWait").show();
        let imgData = cropper.getCroppedCanvas().toDataURL("image/png");
        let formData = new FormData();
        formData.append("profile_image", imgData);
        formData.append("id", this_popup.state.user.id);
        API.post("users/translators/update-profile-img", formData).then(
          (response) => {
            ($("#editTProfileImagePopup") as any).modal("hide");
            this_popup.getData();
            showNotification(
              "success",
              "Profile image has been updated successfully."
            );
          }
        );
      });
    };

    return (
      <div className="kt-widget kt-widget--user-profile-1">
        <div className="kt-widget__head">
          <div className="kt-widget__media boxShadow ">
            <div className={"profile_box"} style={{textAlign: "center"}}>
              <div className="imgbox">
                <img src={this.state.user.profile_image} alt="image" />
                <div className="overlay_img">
                  <div className="input-file-container">
                    <input
                      accept="image/*"
                      className="input-file-trigger"
                      type="file"
                      name="profile_image"
                      id="profile_pic"
                      onChange={(e) => uploadProfilePic(e)}
                    />
                    <label
                      id="change_avatar"
                      htmlFor="profile_pic"
                      className="input-file-trigger"
                    >
                      <span>
                        {!_.isUndefined(this.state.user.profile_image) &&
                          this.state.user.profile_image.indexOf(
                            "user-placeholder"
                          ) === -1 && (
                            <i
                              className="fas fa-pencil-alt"
                              aria-hidden="true"
                            />
                          )}
                        {!_.isUndefined(this.state.user.profile_image) &&
                          this.state.user.profile_image.indexOf(
                            "user-placeholder"
                          ) > -1 && (
                            <i className="fa fa-camera" aria-hidden="true" />
                          )}
                      </span>
                    </label>
                  </div>
                  <p className="file-return" />
                </div>
              </div>
              <a href={this.state.user.profile_image} download={this.state.user.name + "- profile-picture"} target="_blank">Download</a>
            </div>
            {this.state.user.translator !== undefined && (
              <>
                {this.state.user.translator.rating !== null && (
                  <span
                    style={{
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    {this.state.user.translator.rating + ".0 "}{" "}
                    <i
                      className="fa fa-star"
                      style={{
                        color: "green",
                        fontSize: "20px",
                      }}
                    />
                  </span>
                )}
                {this.state.user.translator.review > 0 && (
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginTop: "5px",
                    }}
                  >
                    {this.state.user.translator.review}&nbsp;{Lang.review_title}{" "}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="kt-widget__content">
            <div className="kt-widget__section">
              <a href="#" className="kt-widget__username">
                {this.state.user.name}&nbsp;&nbsp;&nbsp; (
                {this.state.user.user_code !== null
                  ? this.state.user.user_code
                  : "--"}
                )
                {this.state.user.status == 1 ? (
                  <i
                    className="flaticon2-correct kt-font-success"
                    title={Lang.active}
                  />
                ) : (
                  <i
                    className="flaticon2-close-cross"
                    title={Lang.inactive}
                    style={{ color: "#299798" }}
                  />
                )}
              </a>
              <span className="kt-widget__subtitle">
                {this.state.user.gender == "1" ? (
                  <i className={Icons.male} style={fontsize} />
                ) : (
                  <i className={Icons.female} style={fontsize} />
                )}{" "}
                {gender}
              </span>
            </div>

            {/* <div className="kt-widget__action">
                            <button type="button" className="btn btn-info btn-sm" onClick={() => this.sendmail()}>{Lang.send_mail}</button>
                        </div> */}
          </div>
        </div>
        <div className="kt-widget__body profileInfo">
          <div className="kt-widget__content">
            <div className="kt-widget__info">
              <span className="kt-widget__label">{Lang.email}:</span>
              <br />
              <span className="kt-widget__data">{this.state.user.email}</span>
            </div>
            <div className="kt-widget__info">
              <span className="kt-widget__label">{Lang.telephone_number}:</span>
              <span className="kt-widget__data">
                + {this.state.user.country_code} - {this.state.user.mobile_no}
              </span>
            </div>
            <div className="kt-widget__info">
              <span className="kt-widget__label">{Lang.address}:</span>
              <span className="kt-widget__data">{this.state.user.address}</span>
            </div>
          </div>

          <div className="kt-widget__items">
            {TabsItems.map((item: any, index: number) => {
              if (item.show) {
                return (
                  <Link
                    key={index}
                    className={
                      this.state.active === item.active
                        ? "kt-widget__item kt-widget__item--active kt-widget__desc"
                        : "kt-widget__item"
                    }
                    to={item.to}
                    data-ktwizard-type="step"
                    onClick={() => {
                      this.setState({ active: item.active });
                    }}
                  >
                    <span className="kt-widget__section">
                      <span className="kt-widget__icon" />
                      <span className="kt-widget__desc">
                        <i className={item.fontIcon} /> {item.title}
                      </span>
                    </span>
                    {item.count !== undefined && item.count > 0 && (
                      <span className="kt-badge kt-badge--unified-danger kt-badge--sm kt-badge--rounded kt-badge--bolder">
                        {item.count}
                      </span>
                    )}
                  </Link>
                );
              }
            })}
          </div>
          <CropProfileImage />
        </div>
      </div>
    );
  }
}
