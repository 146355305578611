import * as React from 'react';
import {companySetup, CreateBreadcrumb, IState} from "./Data";
import Lang from "../../common/lang/Lang";
import {CustomerFormDefaultVal} from "./Model";
import API from './../../common/AxoisClient';
import * as _ from 'lodash';
import {RouteComponentProps} from "react-router";
import {CustomerValidations} from "./Validations";
import {CreateLayout} from "../../layout/CreateLayout";
import {DepartmentModel, DepartmentUnitModel} from "../../model";
import {getDept, getDeptUnit} from "../../helpers/UserHelper";
import {CommonSubmitButton, SubmitButton, showNotification, InlineLoader, Select,SwitchLoader, FormSearchSelect} from "../../common";
import {isSpecialCompany, LanguageAndCompanyList} from "../../services";
import {CountryFlags, Select2Wrapper} from "../../common/Select2";
import {LoaderType, getMapContent, GooglePlace} from "../../common";
import {AxiosResponse} from "axios";
import { cloneDeep, isEmpty, set } from 'lodash';
import CustomerEmailInput, { appendCompanyDomain } from './components/CustomerEmailInput/CustomerEmailInput';

export class CustomerCreate extends React.Component<RouteComponentProps, IState> {
  state: IState;
  constructor(props:any){
    super(props);
    this.state={
      form: CustomerFormDefaultVal,
      is_special_company: false,
      is_prefilled: false,
      is_department_show: false,
      _address: false,
      countries:[],
      countrycodes:[],
      is_ean_found: false,
      is_cvr_found: false,
      is_contact_found: false,
      companies: [],
      dept: [],
      units:[],
      isEmailExists:2
    }
    CustomerValidations();
  }

    handleChange = (event: React.ChangeEvent<HTMLElement>) => {
        const target : any = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "customer_type") {
            let resetObj: any = {
                is_prefilled: false,
                is_special_company: false,
                is_ean_found: false,
                is_cvr_found: false,
                is_contact_found: false,
                form: {
                    ...this.state.form,
                    customer_type: value,
                    email: '',
                    company_id: '',
                    department_id: '',
                    department_unit_id: '',
                    economics_department_unit_id: '',
                    ean_number: '',
                    //cpr_number: ''
                },
                departments: [],
                department_units: []
            };
            this.setState(resetObj);
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value
                }
            });
        }

        if (name === "department_id") {
            this.setState({
                department_units: []
            });
            if (value > 0) {
                let units = this.state.departments.filter(dept => {
                    return String(dept.id) === value;
                })
                this.setState({
                    department_units: units[0].units
                });
            }
        }

        if (name === "department_unit_id") {
            if (value > 0) {
                this.setState(companySetup.autoFill(this.state, value));
                SwitchLoader(LoaderType.INLINE);
                API.get(`/orders/department-unit-ean-number/${value}`).then(res => {
                    this.setState({
                        is_ean_found: true
                    });
                    this.setState({
                        form: { ...this.state.form,
                            ean_number: res.data.ean_number,
                            mobile_no: res.data.mobile_no,
                            order_contact_phone: res.data.mobile_no
                        }
                    });
                    if (res.data.address && res.data.zip_code && res.data.city) {
                        let makeAddress = res.data.address + '+' + res.data.zip_code + '+' + res.data.city;
                        SwitchLoader(LoaderType.INLINE);
                        API.get(`users/customers/convert-address-to-geocode/${makeAddress}`).then(res3 => {
                            if (res3.data.geometry) {
                                let postal_code_content = _.filter(res3.data.address_components, (item) => {
                                    return _.indexOf(item.types, 'postal_code') > -1
                                });

                                let city_content = _.filter(res3.data.address_components, (item) => {
                                    return _.indexOf(item.types, 'locality') > -1 && _.indexOf(item.types, 'political') > -1
                                });

                                let address = res3.data.formatted_address;
                                let zip_code = postal_code_content[0] !== undefined ? postal_code_content[0]['long_name'] : '';
                                let city = city_content[0] !== undefined ? city_content[0]['long_name'] : '';
                                this.setState(prevState => ({form: {...prevState.form, ...{ lat:res3.data.geometry.location.lat,lng:res3.data.geometry.location.lat,address: address,zip_code: zip_code, city: city}}}as any
                                ));
                            } else {
                                this.setState(prevState => ({form: {...prevState.form, ...{ lat:'',lng:'',address: '',zip_code: '', city: ''}}}as any
                                ));
                            }
                        });
                    }

                    SwitchLoader(LoaderType.INLINE);
                    API.get(`users/customers/get-details-of-ean/${res.data.ean_number}`).then(res2 => {
                        this.setState({
                            is_cvr_found: true
                        });
                        this.setState({
                            form: { ...this.state.form,
                                cpr_number: res2.data.UnitCVR,
                                economics_department_unit_id: res2.data.UnitName
                            }
                        });
                    });
                });
            }
        }
        if (name === "converted_to_company_id") {
            const {
                companies
            } = this.state;
            if (Number(value) !== 66) {
                this.setState({
                    dept: getDept(companies, Number(value))
                });
            }
        }

        // show dept unit filter and set data for it.

        if (name === "converted_to_department_id") {
            this.setState({
                units: getDeptUnit(this.state.dept, Number(value))
            });
        }

        if (name === "is_convert_to_public") {
            if (target.checked === true) {
                this.setState({
                    form: {
                        ...this.state.form,
                        'is_convert_to_public': 1
                    }
                });
            } else {
                this.setState({
                    form: {
                        ...this.state.form,
                        'is_convert_to_public': 0
                    }
                });
            }
        }
    }
    componentDidMount() {
        API.get("users/customers/countries").then(res => {
            this.setState({
                countries: res.data.countryArr,
                countrycodes: res.data.countryCodeArr
            }, () => {
                ($(".selectpicker") as any).selectpicker('refresh');
                this.setState({
                    form: {
                        ...this.state.form,
                        country_code: '45'
                    }
                });
            });
        })

        LanguageAndCompanyList().then(res => {
            let makeCompanyArr: any = [];
            makeCompanyArr = res.companies;
            makeCompanyArr.push({
                label: 'Silkeborg Kommune',
                value: '66',
                id: 66
            });
            this.setState({
                companies: makeCompanyArr,
            }, function() {
                if (Number(this.state.form.converted_to_company_id) !== 0 && Number(this.state.form.converted_to_company_id) !== 66 && res.companies) {
                    const dept: any = getDept(res.companies, Number(this.state.form.converted_to_company_id));
                    this.setState({
                        dept: dept,
                        units: getDeptUnit(dept, Number(this.state.form.converted_to_department_id))
                    })
                }
            });
        });
    }
    handleSelectChange = (event: React.ChangeEvent < HTMLElement > ) => {
        const targetSelect: any = event.target;
        const value = targetSelect.value;
        this.setState({country_code: value}as any);
    }

    makeFormNonSpecialCompany = () => {
        companySetup.readOnly(this, this.state.old_fields, false)
        this.setState(companySetup.blank(this.state.old_fields, this.state.form))
    }

    makeFormSpecialCompany = ({ state, required_fields }: ReturnType<typeof companySetup.specialCompany>) => {
        this.setState(state)
        companySetup.readOnly(this, required_fields, true)
    }

    checkSpecialCompany = async (e?: React.FocusEvent<HTMLInputElement>, email: string = e ? e.target.value : this.state.form.email) => {
        const special_company = await isSpecialCompany(email)
        if (isEmpty(special_company)) this.makeFormNonSpecialCompany()
        else this.makeFormSpecialCompany(companySetup.specialCompany(
                special_company,
                set(cloneDeep(this.state.form),'email', appendCompanyDomain(this.state.form.email, special_company.email)),
                true
            ))
        this.checkEmail()
    }
    checkEmail = () => {
        var email = this.state.form.email;
        let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (email !== '' && re.test(email)) {
            SwitchLoader(LoaderType.INLINE);
            API.get(`users/translator/check-email-exists`, {
                params: {
                    email: email
                }
            }).then((res: AxiosResponse) => {
                if (res.data.status === 0) {
                    this.setState({
                        'isEmailExists': 0
                    });
                } else {
                    this.setState({
                        'isEmailExists': 1
                    });
                }
            })
        } else {
            this.setState({
                'isEmailExists': 2
            });
        }
    }
    checkEanDetails = () => {
        if (this.state.form.ean_number !== '') {
            SwitchLoader(LoaderType.INLINE);
            API.get(`users/customers/get-details-of-ean/${this.state.form.ean_number}`).then(res2 => {
                this.setState({
                    is_cvr_found: true
                });
                this.setState({
                    form: {
                        ...this.state.form,
                        cpr_number: res2.data.UnitCVR,
                        economics_department_unit_id: res2.data.UnitName
                    }
                });
            });
        } else {
            this.setState({
                is_cvr_found: false
            });
        }
    }
    checkCvrDetails = () => {
        if (this.state.form.customer_type !== '1' && this.state.form.customer_type !== 1 && this.state.form.cpr_number !== '') {
            this.setState(prevState => ({
                form: {...prevState.form,...{lat: '',lng: '',address: '',zip_code: '',city: ''}}}as any
            ));
            SwitchLoader(LoaderType.INLINE);
            API.get(`users/customers/get-details-of-cvr/${this.state.form.cpr_number}`).then(res2 => {
                this.setState({
                    is_contact_found: true
                });
                this.setState({
                    form: {
                        ...this.state.form,
                        company_id: res2.data.name ?? '',
                        contact_phone_2: res2.data.phone ?? ''
                    }
                });
                let makeAddress = res2.data.address + '+' + res2.data.zipcode + '+' + res2.data.city;
                SwitchLoader(LoaderType.INLINE);
                API.get(`users/customers/convert-address-to-geocode/${makeAddress}`).then(res3 => {
                    if (res3.data.geometry) {
                        let postal_code_content = _.filter(res3.data.address_components, (item) => {
                            return _.indexOf(item.types, 'postal_code') > -1
                        });

                        let city_content = _.filter(res3.data.address_components, (item) => {
                            return _.indexOf(item.types, 'locality') > -1 && _.indexOf(item.types, 'political') > -1
                        });

                        let address = res3.data.formatted_address;
                        let zip_code = postal_code_content[0] !== undefined ? postal_code_content[0]['long_name'] : '';
                        let city = city_content[0] !== undefined ? city_content[0]['long_name'] : '';
                        this.setState(prevState => ({
                            form: {...prevState.form,...{lat: res3.data.geometry.location.lat,lng: res3.data.geometry.location.lat,address: address,zip_code: zip_code,city: city}}}as any
                        ));
                    } else {
                        this.setState(prevState => ({
                            form: {...prevState.form,...{lat: '',lng: '',address: '',zip_code: '',city: ''}}}as any));
                    }
                });
            });
        } else {
            this.setState({
                is_contact_found: false
            });
        }
    }
    handleSubmit = (event: React.FormEvent <HTMLFormElement> ) => {
        event.preventDefault();
        SwitchLoader(LoaderType.BUTTON);
        if ($("#customer_form").valid() && this.state.isEmailExists == 0) {
            API.post('users/customers', this.state.form).then((res) => {
                showNotification("success", res.data.message);
                this.props.history.push(`/users/customers`)
            })
        }
    }
    googleGetAddress = (place) => {
        const {
            address,
            city,
            lat,
            lng,
            zip_code
        } = getMapContent(place);
        this.setState(prevState => ({
            form: {...prevState.form,...{lat: lat,lng: lng,address: address,zip_code: zip_code,city: city}}}as any
        ));
    }

    formDataReset = (e:any) => { 
         e.preventDefault();
        this.setState({
            form: CustomerFormDefaultVal
        });       
    }

    render() {
        return (
            <CreateLayout compact breadcrumb={CreateBreadcrumb} title={'add_customer'}>
                <form className="kt-form" id={'customer_form'} onSubmit={this.handleSubmit}>
                    <div className="kt-portlet__body">
                        <div className={'row'}>
                            <div className={"col-md-6 col-lg-6"}>
                                <div className="form-group">
                                    <label>{Lang.customer_type} <span className={'required'}>*</span></label>
                                    <Select onChange={this.handleChange} value={this.state.form.customer_type || ''} id={'customer_type'} name={'customer_type'} className="form-control" >
                                    <option value={''}>{Lang.select_customer_type}</option>
                                    <option value="1">Public</option>
                                    <option value="2">Private</option>
                                    </Select>
                                </div>
                            </div>
                            {this.state.form.customer_type==1 &&
                                <div className={"col-md-6 col-lg-6"}>
                                    <div className="form-group">
                                        <InlineLoader>
                                            <label>{Lang.ean_number}</label>
                                        </InlineLoader>
                                        <input type={'text'} onChange={this.handleChange} onBlur={this.checkEanDetails} readOnly={this.state.is_ean_found} value={this.state.form.ean_number || ''} ref={input => {this[`ean_number`] = input; }} name={'ean_number'} className="form-control" placeholder={Lang.getString(`ean_number_placeholder`)}/>
                                    </div>
                                </div>
                            }
                            {(this.state.form.customer_type==1 || this.state.form.customer_type==2) &&
                                <div className={"col-md-6 col-lg-6"}>
                                    <div className="form-group">
                                        <InlineLoader>
                                            <label>{Lang.cvr_number}</label>
                                        </InlineLoader>
                                        <input type={'text'} onChange={this.handleChange} onBlur={this.checkCvrDetails} readOnly={this.state.is_cvr_found} value={this.state.form.cpr_number || ''} ref={input => {this[`cpr_number`] = input; }} name={'cpr_number'} className="form-control" placeholder={Lang.getString(`cvr_number_placeholder`)}/>
                                    </div>
                                </div>
                            }
                            {(this.state.form.customer_type==1 || this.state.form.customer_type==2) && this.state.is_cvr_found && this.state.form.ean_number!=='' &&
                                <div className={"col-md-6 col-lg-6"}>
                                    <div className="form-group">
                                        <label>{Lang.other_department_unit}</label>
                                        <input type={'text'} readOnly={true} value={this.state.form.economics_department_unit_id || ''} ref={input => {this[`economics_department_unit_id`] = input; }} name={'economics_department_unit_id'} className="form-control"/>
                                    </div>
                                </div>
                            }
                            <div className={"col-md-6 col-lg-6"}>
                                <div className="form-group">
                                    <label>{Lang.name} <span className={'required'}>*</span></label>
                                    <input type={'text'} onChange={this.handleChange} value={this.state.form.name || ''} name={'name'} className="form-control" placeholder={Lang.getString(`name_placeholder`)}/>
                                </div>
                            </div>
                            <CustomerEmailInput
                                handleChange={this.handleChange}
                                value={this.state.form.email || ''}
                                checkSpecialCompany={this.checkSpecialCompany}
                                makeFormSpecialCompany={this.makeFormSpecialCompany}
                                form={this.state.form}
                            >
                                { this.state.isEmailExists === 1 && <>
                                    <label id="email-exists-error" className={'error'} style={{color:'red'}}>{Lang.email_already_register}</label></>
                                }
                            </CustomerEmailInput>
                            <div className={"col-md-6 col-lg-6"}>
                                <div className="form-group ">
                                    <label>{Lang.order_contact_number} </label>
                                    <input type={'text'} onChange={this.handleChange} value={this.state.form.order_contact_phone || ''}  ref={input => {this[`order_contact_phone`] = input; }} name={'order_contact_phone'}  className="form-control" placeholder={Lang.getString(`order_contact_placeholder`)}/>
                                </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                                <div className="form-group">
                                    <label>{Lang.company} <span className={'required'}>*</span></label>
                                    <input type={'text'} onChange={this.handleChange} readOnly={this.state.is_special_company} value={this.state.form.company_id} name={'company_id'} className="form-control" placeholder={Lang.getString(`company_id_placeholder`)}/>
                                </div>
                            </div>
                            {this.state.is_special_company && this.state.is_department_show == true &&
                                <div className={"col-md-6 col-lg-6"}>
                                    <div className="form-group">
                                        <label>{Lang.department} <span className={'required'}>*</span></label>                              
                                        <Select onChange={this.handleChange} value={this.state.form.department_id || ''} id={'department_id'} name={'department_id'} className="form-control" >
                                        <option value={''}>{Lang.select_dept}</option>
                                        {this.state.departments.map((item: DepartmentModel, index) => {
                                        return <option key={index} value={item.id}>{item.name}</option>})}
                                        </Select>
                                    </div>
                                </div>
                            }
                            { !this.state.is_special_company && 
                                <div className={"col-md-6 col-lg-6"}>
                                    <div className="form-group">
                                        <label>{Lang.department} <span className={'required'}>*</span></label>                              
                                        <input type={'text'} id={'department_id'} onChange={this.handleChange} value={this.state.form.department_id || ''} name={'department_id'} className="form-control" placeholder={Lang.getString(`department_id_placeholder`)}/>
                                    </div>
                                </div>
                            }
                            {this.state.is_special_company === true &&
                                <div className={"col-md-6 col-lg-6"}>
                                    <div className="form-group">
                                        <InlineLoader>
                                            <label>{Lang.department_unit} <span className={'required'}>*</span></label>
                                        </InlineLoader>
                                        <Select onChange={this.handleChange} value={this.state.form.department_unit_id || ''} name={'department_unit_id'} className="form-control" >
                                        <option value={''}>{Lang.select_dept_unit}</option>
                                        {this.state.department_units.map((item: DepartmentUnitModel, index) => {return <option key={index} value={item.id}>{item.name}</option>})}
                                        </Select>
                                    </div>
                                </div>
                            }
                            <div className={"col-md-6 col-lg-6"}>
                                <div className="form-group">
                                    <label>{Lang.address} <span className={'required'}>*</span></label>
                                    {!this.state._address &&
                                    <GooglePlace
                                        onChooseAddress={this.googleGetAddress}
                                        onChange={(e) => {this.setState({...this.state, form:{ ...this.state.form, city: '', zip_code: '', lat: '', lng: ''}})}}
                                        name={'address'}
                                        defaultValue={this.state.form.address || ''}
                                        className="form-control"
                                        placeholder={Lang.getString(`address_placeholder`)}
                                    />
                                    }
                                    { this.state.form.address &&
                                        <span>
                                            City: <strong>{this.state.form.city || <span style={{color:'red'}}>&lt;NONE&gt;</span>}</strong> |&nbsp;
                                            ZIP Code (Postal Code): <strong>{this.state.form.zip_code || <span style={{color:'red'}}>&lt;NONE&gt;</span>}</strong>
                                        </span>
                                    }
                                    {this.state._address &&
                                    <input name={'address'} ref={input => {this[`address`] = input; }} defaultValue={this.state.form.address}  className="form-control" placeholder={Lang.getString(`address_placeholder`)} id="address"/>
                                    }
                                    <input type={'hidden'} id="zip_code" onChange={this.handleChange} value={this.state.form.zip_code || ''}  ref={input => {this[`zip_code`] = input; }} name={'zip_code'} className="form-control" placeholder={Lang.getString(`zip_code_placeholder`)}/>
                                    <input type={'hidden'} id="city" onChange={this.handleChange} value={this.state.form.city || ''} ref={input => {this[`city`] = input; }} name={'city'} className="form-control" placeholder={Lang.getString(`city_placeholder`)}/>
                                </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                                <div className="form-group ">
                                    <label>{Lang.telephone_number} <span className={'required'}>*</span></label>
                                    <div className={"row"}>
                                        <div className={"col-md-4 col-lg-4"}>
                                            <Select2Wrapper   onChange={this.handleChange}
                                            className={'form-control'} id={'country_code'} name={'country_code'} value={this.state.form.country_code || ''}
                                            data={{
                                            placeholder: "Select Options",
                                            data: this.state.countrycodes,
                                            templateResult: CountryFlags,
                                            templateSelection: CountryFlags,
                                            }}
                                            />
                                        </div>
                                        <div className={"col-md-8 col-lg-8"}>
                                            <input type={'text'} onChange={this.handleChange} value={this.state.form.mobile_no || ''}  ref={input => {this[`mobile_no`] = input; }} name={'mobile_no'}  className="form-control" placeholder={Lang.getString(`mobile_placeholder`)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { this.state.is_contact_found &&
                                <div className={"col-md-6 col-lg-6"}>
                                    <div className="form-group ">
                                        <label>{Lang.telephone_number2}</label>
                                        <div className={"row"}>
                                            <div className={"col-md-8 col-lg-8"}>
                                                <input type={'text'} value={this.state.form.contact_phone_2 || ''}  readOnly={true} ref={input => {this[`contact_phone_2`] = input; }} name={'contact_phone_2'}  className="form-control" placeholder={Lang.getString(`telephone_number2`)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/*{!this.state.is_special_company &&
                            <div className={"col-md-6 col-lg-6"}>
                                <div className="form-group">
                                    <label>{Lang.cpr_number}</label>
                                    <input type={'text'} onChange={this.handleChange} value={this.state.form.cpr_number || ''} name={'cpr_number'} className="form-control" placeholder={Lang.getString(`cpr_number_placeholder`)}/>
                                </div>
                            </div>
                            }
                            <div className={"col-md-6 col-lg-6"}>
                                <div className="form-group">
                                    <label>{Lang.identification_code} <span className={'required'}>*</span></label>
                                    <input type={'text'} onChange={this.handleChange} value={this.state.form.identification_code || ''} name={'identification_code'} className="form-control" placeholder={Lang.getString(`identification_code_placeholder`)}/>
                                </div>
                            </div>
                            */}
                        </div>
                        <div className={'row'}>
                            <div className={"col-md-12 col-lg-12"}>
                                <div className="form-group ">
                                    <label className="kt-checkbox">
                                    <input type="checkbox" name={'is_convert_to_public'} checked={this.state.form.is_convert_to_public===1 ? true : false} onChange={(e)=>this.handleChange(e)}  value={this.state.form.is_convert_to_public} 
                                      />Are you sure you want to add this customer into another special company?
                                    <span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        { this.state.form.is_convert_to_public === 1 &&
                            <div className={'row'}>
                                <div className={"col-md-6 col-lg-6"}>
                                    <div className="form-group ">
                                        <label>{Lang.company}</label>
                                        <FormSearchSelect name={'converted_to_company_id'} value={this.state.form.converted_to_company_id}  onChange={this.handleChange}  options={[{label: Lang.choose_company, value: ''}, ...this.state.companies]}/>
                                    </div>
                                </div>
                            { this.state.form.converted_to_company_id!==0 && Number(this.state.form.converted_to_company_id)!==66 &&
                                <div className={"col-md-6 col-lg-6"}>
                                    <div className="form-group ">
                                        <label>{Lang.department}</label>
                                        <FormSearchSelect name={'converted_to_department_id'}  value={this.state.form.converted_to_department_id || ''}  onChange={this.handleChange} options={this.state.dept}/>
                                    </div>
                                </div>
                            }
                            {this.state.form.converted_to_company_id!==0 && this.state.form.converted_to_department_id!==0 &&
                                <div className={"col-md-6 col-lg-6"}>
                                    <div className="form-group ">
                                        <label>{Lang.department_unit}</label>
                                        <FormSearchSelect name={'converted_to_department_unit_id'}  value={this.state.form.converted_to_department_unit_id || ''}  onChange={this.handleChange} options={this.state.units}/>
                                    </div>
                                </div>
                            }
                        </div>
                        }
                    </div>
                    <input type={'hidden'} id={'is_special_company'} value={this.state.is_special_company ? 1 : 0}/>
                    <div className='reset-btn-section'>
                        {/* <CommonSubmitButton/> */}
                        <div className="kt-portlet__foot kt-align-right">
                                <SubmitButton className='btn btn-primary mr-2' label={Lang.submit} />
                                <button onClick={(e)=>this.formDataReset(e)} className="btn btn-default hvr-rectangle-out btnMove reset-btn" type="button">{Lang.reset}</button>
                        </div>
                    </div>

                </form>
            </CreateLayout>
        )
    }
}