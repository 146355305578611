import React, {useEffect, useReducer, useState} from 'react';
import Lang from "../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {Icons, showNotification, SubmitButton} from "../../common";
import {
  InitialState, PopupState, ActionType,
  CAction, OrderPopupReducer
} from "./OrderPopupReducer";
import { PayoutServices, SeePayoutOrderService} from "../../services/PayoutServices";
import {OrderType, PayoutModel} from "../../model";
import {order_time} from "../../helpers/OrderHelper";
import _ from "lodash";

export function SeeOrders () {

  const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(OrderPopupReducer , InitialState);

  useEffect( () => {
    SeePayoutOrderService.getPopupState().subscribe((message:any) => {
      dispatch({type: ActionType.SHOW_POPUP, payload: message}) ;
    });

  }, []);

  if(state.show === false){
    return null;
  }

  function getOrders() {
    PayoutServices.getPendingPayOrders(state.translator_id).then((res) => {
      dispatch({type: ActionType.GET_ORDERS, payload: res}) ;
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
  }

  function approveOrders() {
    if(state.to_approve.length > 0){
      PayoutServices.approveOrders(state.to_approve).then(res=> {
        getOrders();
      })
    } else {
      showNotification('danger', "Select at least one order.");
    }
  }

  function movePending() {
    if(state.to_approve.length > 0){
      PayoutServices.movePending(state.to_approve).then(res=> {
        PayoutServices.getCompletePayOrders(state.translator_id).then(res => {
          dispatch({type: ActionType.GET_ORDERS, payload: res}) ;
        })
      })
    } else {
      showNotification('danger', "Select at least one order.");
    }

  }

  function getPaid(){
    if(state.to_approve.length > 0){
      PayoutServices.setComplete(state.to_approve).then(res=> {
        PayoutServices.getCompletePayOrders(state.translator_id, true).then(res => {
          dispatch({type: ActionType.GET_ORDERS, payload: res}) ;
        })
      })
    } else {
      showNotification('danger', "Select at least one order.");
    }
  }
  return(
    <Modal
      show={state.show}
      onHide={() => { dispatch({type: ActionType.HIDE_POPUP}) } }
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form onSubmit={handleSubmit} id="fix_price_form">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Oversætterens ordreoplysninger
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
              {
                state.orders !== undefined && state.orders.length > 0 &&
                <div className={'col-md-12'}>
                  <table className={'table table-bordered table-hover'}>
                    <tbody>
                    <tr>
                      <td>#</td>
                      <td>Order No</td>
                      <td>Translation Type</td>
                      <td>Date</td>
                      <td>Time</td>
                      <td>Time Usages</td>
                      <td>Rate</td>
                      <td>Total</td>
                      <td>KM</td>
                      {!_.isEmpty(state.orders[0].order.translate_by.translator) &&
                      state.orders[0].order.translate_by.translator.is_km_pay === 1 && <td>Payment KM</td>}
                      <td>Transport Time</td>
                      {!_.isEmpty(state.orders[0].order.translate_by.translator) &&
                      state.orders[0].order.translate_by.translator.is_transport_pay === 1 && <td>Payment for transport time</td>}
                      <td>Total</td>
                      {!_.isEmpty(state.orders[0].order.translate_by.translator) &&
                      state.orders[0].order.translate_by.translator.is_transport_pay === 1 && <td>A-indkomst</td>}
                    </tr>
                    {state.orders.map((item: PayoutModel, index: number) => {
                      return (
                        <tr key={item.id}>
                          <td><input type={'checkbox'} value={item.id} onChange={(e) => { dispatch({type: ActionType.HANDLE_CHANGE, payload: e.target} as any)}}/></td>
                          <td>{item.order.order_no}</td>
                          <td>{item.order.translation_type.title}</td>
                          <td>{item.order.order_date}</td>
                          <td>{order_time(item.order)}</td>
                          <td>{item.work_time}</td>
                          <td>
                            <EditableFields value={item.rate}  fields={'rate'} data={item} refresh={getOrders} />
                          </td>
                          <td>{item.total_rate}</td>
                          <td>{item.order.translation_type_id === OrderType.PHYSICAL  ?
                              <EditableFields value={item.order.total_distance}  fields={'total_distance'} data={item} refresh={getOrders} />
                             : 'N/A'}</td>
                          {!_.isEmpty(item.order.translate_by.translator) && item.order.translate_by.translator.is_km_pay === 1 && <td>
                            <EditableFields value={item.km_pay}  fields={'km_pay'} data={item} refresh={getOrders} /></td>}
                          <td>{item.order.translation_type_id === OrderType.PHYSICAL  ?
                                <EditableFields value={item.order.travel_time}  fields={'travel_time'} data={item} refresh={getOrders} />
                             : 'N/A'}</td>
                          {!_.isEmpty(item.order.translate_by.translator) && item.order.translate_by.translator.is_transport_pay === 1 && <td>
                              <EditableFields value={item.transport_pay}  fields={'transport_pay'} data={item} refresh={getOrders} />
                            </td>}
                          <td>{
                              item.order.fix_price > 0  ?
                                (<>{item.order.fix_price } <br/> <span className={'label label-success'}>Fix Price</span>) </>) :
                                ( <>{item.net_total}</>)
                              }
                            </td>
                          { !_.isEmpty(item.order.translate_by.translator) && item.order.translate_by.translator.is_transport_pay === 1 && <td>{item.agreed_wage}</td>}
                        </tr>
                      )
                    })}
                    </tbody>

                  </table>
                </div>
              }

              {state.orders !== undefined && state.orders.length <= 0 && <div className={'alert alert-danger'}>No orders found.</div>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {state.type === 'complete' &&
            <div>
              <SubmitButton className={'btn btn-warning'} label={'Move Pending'} onClick={movePending}/> &nbsp;
              <SubmitButton className={'btn btn-success'} label={'Get paid'} onClick={getPaid}/>
            </div>
          }
          {state.type === 'pending' && <SubmitButton className={'btn btn-warning'} onClick={approveOrders} label={Lang.send}/>}

          <button type={'button'} className={'btn btn-primary'} onClick={() => { dispatch({type: ActionType.HIDE_POPUP}) }}>{Lang.close}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

function EditableFields(props: {value: any, fields: string, data: PayoutModel, refresh: () => void}) {
  const [edit, setEdit] = useState(false);
  const [val, setVal] = useState(props.value);
  const [oval, setOVal] = useState(props.value);


  function saveField(){
    if(props.value !== val){
      PayoutServices.updateFields({field: props.fields, value: val, order_id: props.data.order_id}).then(res => {
        setOVal(val);
        props.refresh();
        setEdit(false);
      });
    } else {
      setEdit(false);
    }
  }

  if(edit === true){
    return(
      <div>
        <input type={'text'} value={val} onChange={(e) => setVal(e.target.value)} name={props.fields} onBlur={saveField} /> &nbsp;
        {/*<button onClick={saveField} className={'btn btn-success'}><i className={Icons.complete}/></button>*/}
      </div>
    )
  } else {
    return(
      <div >
        { oval } &nbsp; <button onClick={() => setEdit(true)} className={'btn btn-warning'}><i  className={Icons.edit}/></button>
      </div>
    )
  }
}