import React, {useEffect,useReducer, useRef} from 'react';
import {_completeOrder, OrderCompleteService, uploadOrderFile} from "../../../services";
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {
  InitialState, PopupState, ActionType,
  CAction, CompleteOrderReducer
} from "../order-reducers/CompleteOrderReducer";
import {CancelProps} from "../order-reducers/CancelOrderReducer";
import {showNotification, SubmitButton, WarningDiv, TimePickerWrapper} from "../../../common";
import {OrderModel, OrderType} from "../../../model";
import {order_to_language} from "../../../helpers/OrderHelper";
import _ from 'lodash';
const moment = require("moment");
export function CompleteOrderPopup (props: CancelProps) {

  const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(CompleteOrderReducer , InitialState);
  

  useEffect( () => {
    OrderCompleteService.getPopupState().subscribe((message:any) => {
      dispatch({type: message.action, payload: message}) ;
    });
  }, []);

  if(state.show === false){
    return null;
  }

  const {order_data} = state;

  function completeOrder(e){
    e.preventDefault();
    _completeOrder(state.order_data.id, state.form).then((res:any) => {
      if(res !== undefined){
        showNotification('success', res);
        props.refresh();
        OrderCompleteService.hidePopup();
      }
    })
  }

  function handleChange(e) {
    dispatch({type: ActionType.HANDLE_CHANGE, payload: e.target})
  }

  function RemoveDocument(name){
    const files = state.form.document.filter(file => {
      return file.name !== name;
    })
     dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'document', value: files} as any })
  }


  function handleFile(e){
    let formData = new FormData();
    if(e.target.files){
      for(let i=0; i<e.target.files.length; i++){
        formData.append('document[]', e.target.files[i]);
      }
    }

   uploadOrderFile(formData).then(res => {
     let old_file = [];
     if(!_.isEmpty(state.form.document)){
       old_file = state.form.document;
     }
      dispatch({type: ActionType.HANDLE_CHANGE, payload: {name: 'document', value: [...old_file, ...res.document]} as any})
      showNotification("success", res.message);
    })
  }

    function formDataReset(e:any) {
        e.preventDefault();
        var comment = document.getElementById("comment") as HTMLTextAreaElement;
        comment.value = '';
    }
  
  return(
   <div style={{
     zIndex: 1000
   }}>
      <Modal
        show={state.show}
        onHide={() => { dispatch({type: ActionType.HIDE_POPUP}) } }
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {Lang.complete_order} - #{order_data.order_no}
            </Modal.Title>
          </Modal.Header>
          <form onSubmit={completeOrder} id="order_comments">
            <Modal.Body>
              <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className={'row'}>
                  <table className={'table'}>
                    <tbody>
                    <tr><td>Order No</td> <td>#{order_data.order_no}</td></tr>
                    <tr><td>Client</td><td>{order_data.customer.name}</td></tr>
                      <tr><td>Contact Person</td><td>{order_data.contact_persons[0].name}</td></tr>
                      <tr><td>Language</td><td>{order_data.language_from.language_name} - { order_to_language(order_data) }</td></tr>
                      <tr><td>Date</td><td>{moment(order_data.order_date).format('DD-MM-YYYY')}</td></tr>
                    {order_data.translation_type_id !== OrderType.DOCUMENT && 
                      <tr><td>From Time</td><td> {state.form.order_from} </td></tr>
                      }
                      <tr><td>To Time</td><td>
                        <div className="input-group timepicker">
                          <TimePickerWrapper value={state.form.complete_order_to} onChange={(e:any) => { dispatch({type: ActionType.HANDLE_TIME, payload:  {"time": e.time, name: "complete_order_to"} as any})}}  className="form-control" type="text" />
                          <div className="input-group-append"><span className="input-group-text"><i className="la la-clock-o"/></span></div>
                        </div>
                      </td></tr>
                      <tr><td>Translation Type</td><td>{order_data.translation_type.title}</td></tr>
                      {order_data.translation_type_id === OrderType.PHONE_MESSAGE &&
                          <React.Fragment>
                        <tr><td colSpan={2}>
                            <label>Translation of message<span className={'required'}>*</span></label>
                            <textarea name={'document'} value={state.form.document}  onChange={handleChange} className={'form-control'} placeholder={'Enter message translation'}  required={true} />
                        </td></tr>

                        {/*<tr><td colSpan={2}>
                            <label>Total Strokes<span className={'required'}> *</span></label>
                            <input type="text" name={'total_stroke'} value={state.form.total_stroke}  onChange={handleChange} className={'form-control'} required={true} />
                        </td></tr>*/}
                        </React.Fragment>
                      }
                      
                      <tr><td colSpan={2}>
                        <label>{Lang.comments}</label>
                        <textarea name={'comment'} id={'comment'} value={state.form.comment}  onChange={handleChange} className={'form-control'} placeholder={'Enter comment'}
                        />
                      </td></tr>
                      {
                        order_data.translation_type_id === OrderType.DOCUMENT &&
                          <React.Fragment>
                            <tr><td colSpan={2}>
                            <label>Total Word<span className={'required'}> *</span></label>
                            <input type="text" name={'total_word'} value={state.form.total_word}  onChange={handleChange} className={'form-control'} required={true} />
                        </td></tr>
                            <tr><td colSpan={2}>
                              <label>{Lang.attach_document}</label>
                              <div className="custom-file">
                                <input type={'file'} onChange={handleFile} multiple={true} name={'document'} className="custom-file-input"/>
                                <label className="custom-file-label" htmlFor="customFile">{Lang.choose_file}</label>
                              </div>
                              { !_.isEmpty(state.form.document) && state.form.document.map(item => {
                                return (
                                  <div className="form-group">
                                    {item.file_name}
                                    <button type="button" onClick={() => {RemoveDocument(item.name)}}  className="btn btn-sm btn-danger"><i className="flaticon-cancel"/></button>
                                  </div>
                                )
                              })
                              }
                              {order_data.document !== null && !_.isEmpty(order_data.document) &&
                              <a href={order_data.document} target='_blank' className="kt-widget4__number kt-font-info">{Lang.download_document}</a>
                              }
                            </td></tr>

                            <tr><td colSpan={2}>
                              <div className={'kt-checkbox-inline'}>
                                <label className="kt-checkbox">
                                  <input type="checkbox" name={'send_email'} value={1} checked={state.form.send_email}  onChange={handleChange}/> Send Email <span/>
                                </label>
                                {/*<label className="kt-checkbox">
                            <input type="checkbox" name={'send_sms'} value={1} checked={state.form.send_sms}  onChange={handleChange}/> Send SMS <span/>
                          </label>*/}
                              </div>

                            </td></tr>

                          </React.Fragment>
                      }

                    </tbody>
                  </table>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button onClick={(e)=>formDataReset(e)} className=" btn btn-default hvr-rectangle-out btnMove  mobiNone" type="button">{Lang.reset}</button>
              {CompleteSubmitButton(order_data)}
            </Modal.Footer>
          </form>
      </Modal>
    </div>
  )
}

/**
 *
 * @param {OrderModel} props
 * @returns {any}
 * @constructor
 */
function  CompleteSubmitButton (props: OrderModel){
  
  if((!_.isEmpty(props.translator_id)|| props.translator_id <= 0)){
    return <WarningDiv message={Lang.translator_not_assigned} />;
  }

  switch (props.translation_type_id){
   
    /*case OrderType.PHYSICAL:
       if(props.translate_by === null || _.isEmpty(props.translate_by.translator.physical_rate)|| props.translate_by.translator.physical_rate <= 0){
        return <WarningDiv message={Lang.specify_trans_rate} />;
      }
      break;

    case OrderType.TELEPHONE:
    case OrderType.PHONE_MESSAGE:
      if(props.translate_by === null || _.isEmpty(props.translate_by.translator.telephone_rate) || props.translate_by.translator.telephone_rate <= 0){
        return <WarningDiv message={Lang.specify_trans_rate} />;
      }
      break;
    case OrderType.VIDEO:
      if(props.translate_by === null || _.isEmpty(props.translate_by.translator.video_rate) || props.translate_by.translator.video_rate <= 0){
        return <WarningDiv message={Lang.specify_trans_rate} />;
      }
      break;*/

    default:
      return <SubmitButton className={'btn btn-primary'} label={Lang.submit}/>
  }

  return <SubmitButton className={'btn btn-primary'} label={Lang.submit}/>
}