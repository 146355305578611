import React from 'react';
import { Field, ErrorMessage } from 'formik';

const TextInput = (props) => {
    const {
        label,
        name,
        required = false,
        ...fieldProps
    } = props;

    return (
        <div className="form-group col-lg-6">
            <label htmlFor={name}>
                {label}
                { required && (<span className={"required"}>*</span>) }
            </label>
            <Field
                type="text"
                id={name}
                name={name}
                {...fieldProps}
            />
            <ErrorMessage render={msg => <div className="text-danger">{msg}</div>} name={name} />
        </div>
    );
}

export default TextInput;
