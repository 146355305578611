import { Field, ErrorMessage } from 'formik';
import React from 'react';

const EmailInput = (props) => {
    const {name, label, ...field_props} = props;
    return (
        <div className="form-group col-lg-6">
            <label htmlFor={name}>
                {label}
                <span className={"required"}>*</span>
            </label>
            <Field
                type="email"
                id={name}
                name={name}
                {...field_props}
            />
            <ErrorMessage render={msg => <div className="text-danger">{msg}</div>} name={name} />
        </div>
    );
}

export default EmailInput;
