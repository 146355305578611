import * as React from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {getOrderForCal} from "../../../services";
import {DateInput, getQueryStringParams,SearchSelect, SearchInput} from "../../../common";
const moment = require('moment');

interface IState {
  form: {
    like: {
      search: string,
    },
  
   
  },
 }

export default class CalllogFilter extends React.Component<any, IState> {
  state: IState;


  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
        like: {
          search: old_data ? old_data.like.search : '',
        },
       
      }})
  }

  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState({form: newAccess},() =>  this.props.getResults(this.state.form));
  }

   

  render(){
    
    return (
      <div className="row align-items-center">
      <SearchInput name={'form.like.search'} value={this.state.form.like.search} onChange={this.handleInputForm} placeholder={'Search for...'} />
     </div>
    )
  }
}