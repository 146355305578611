import {CompanyModel, CustomerModel} from "../../model";
import { Form } from "./Data";

export interface UserDataSchema {
  id: number;
  name: string;
  email: string;
  mobile_no: string;
  country_code: string;
  address: string;
  company_id: string;
  created_at: string;
  company: CompanyModel;
  customer: CustomerModel;
  status: number;
}

export  interface CustomerProps {
  add: string;
  changePage?: (page: number) => void;
}

export interface FormFields  {
  name: any;
  type?: string;
}

export const CustomerFormDefaultVal: Form = {
  customer_type : '',
  name: '',
  email: '',
  password:'',
  cn_password:'',
  address:'',
  zip_code:'',
  city:'',
  mobile_no:'',
  order_contact_phone:'',
  country_code: '',
  contact_person:'',
  contact_phone:'',
  contact_phone_2:'',
  ean_number:'',
  cpr_number:'',
  department_id: '',
  department_unit_id:0,
  economics_department_unit_id:'',
  is_super_customer:0,
  identification_code:'',
  company_id: '',
  company_id_number: null,
  is_convert_to_public:0,
  converted_to_company_id: 0,
  converted_to_department_id: 0,
  converted_to_department_unit_id: 0,
  converted_to_ean_number: ''
}
