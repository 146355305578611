import React, {useReducer} from 'react';
import {OrderModel} from "../../../model";

// all actions for assign translator
export enum ActionType {
  SHOW_POPUP = 'SHOW_POPUP',
  HIDE_POPUP = 'HIDE_POPUP',
  SET_MAIL = 'SET_MAIL',
  MAIL_CONTENT = 'MAIL_CONTENT',
  COPY_CONTENT = 'COPY_CONTENT',
}

export interface CustomerMailForm {
  mail: string;
  content: string;
}

export interface PopupState  {
  show: boolean;
  order_data: OrderModel;
  order_id: number;
  form: CustomerMailForm,
  copy_data: string;
}

export interface IAction {
  type: ActionType;
  payload?: PopupState
}
 
export const initialPopupState: PopupState = {
   show: false,
  order_data: {} as OrderModel,
  order_id: 0,
  copy_data: '',
  form: {
    mail: '',
    content: ''
  }
};
 

// reducer for assign send mail for order confirmation to customer popup

export function SendMailToCustomerReducer <PopupState, IAction> (state, action) {
  switch (action.type) {
    // show popup and set order data
   
    case ActionType.SHOW_POPUP:
      return {...state, show: true, order_data:action.payload, order_id: action.payload.id, form: {content:'Hello '+action.payload.customer.name+',\n',mail:''}};

    // hide popup and blank old state
    case ActionType.HIDE_POPUP:
      return {...state, ...initialPopupState};

    // handle change of mail drop-down
    case ActionType.MAIL_CONTENT:
      return {...state, form: {...state.form, content: action.payload}};

    // handle change of mail drop-down
    case ActionType.SET_MAIL:
      return {...state, form: {...state.form, mail: action.payload.value}};


    default:
      throw new Error();
  }
}
