import * as React from 'react';
import Lang from "../../../common/lang/Lang";
import { OrderType, OrderModel, ClientData } from "../../../model";
import * as _ from 'lodash';
 
export const OrderContactInfo = (props?: {info: OrderModel}) => {
  let clientsArray: Array<JSX.Element> = [];
  if (!_.isEmpty(props.info.clients)) {
    let endOfClientsIndex: number = props.info.clients.length - 1;
    props.info.clients.forEach((client: ClientData, index: number) => {
      if (index !== endOfClientsIndex) {
        clientsArray.push(<React.Fragment key={index}>{index + 1}) {client.client_name} </React.Fragment>);
      } else {
        clientsArray.push(<React.Fragment key={index}>{index + 1}) {client.client_name}</React.Fragment>);
      }
    })
  }
  return (
    <div className="col-xl-4">
    <div className="kt-portlet kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title kt-font-success">
              {Lang.contact_info}
          </h3>
        </div>               
      </div>
     
      <div className="kt-portlet__body">
        <div className="kt-widget4">   
        <div className="kt-widget4__item">                   
            <span className="kt-widget2__title kt-widget2__title--light">
              {Lang.client_name}
            </span>
            <span className="kt-widget6__number kt-font-info">{clientsArray}</span>
          </div>
          { props.info.company.is_special_company === 1 &&
            <div className="kt-widget4__item">                   
              <span className="kt-widget4__title kt-widget4__title--light">
              {Lang.ean_number}
              </span>
              <span className="kt-widget4__number kt-font-info">{props.info.ean_number}</span>
            </div>
          }
          {
            (props.info.company.is_special_company === 1 && !_.isEmpty(props.info.customer) && !_.isEmpty(props.info.customer.customer)) &&
              <div className="kt-widget4__item">
                <span className="kt-widget4__title kt-widget4__title--light">
                Customer EAN no
                </span>
                <span className="kt-widget4__number kt-font-info">{props.info.customer.customer.ean_number}</span>
              </div>
          }

          {props.info.translation_type_id === OrderType.PHYSICAL &&
          <>
          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
              {Lang.address}
            </span>
            <span className="kt-widget4__number kt-font-info">{props.info.meeting_address}</span>
          </div> 
          { ! _.isEmpty(props.info.addition_info) &&
            <div className="kt-widget4__item">
              <span className="kt-widget4__title kt-widget4__title--light">
              {Lang.additional_info}
              </span>
              <span className="kt-widget4__number kt-font-info">{(props.info.addition_info)}</span>
            </div>
          }
            { ! _.isEmpty(props.info.meeting_city) &&
            <div className="kt-widget4__item">
              <span className="kt-widget4__title kt-widget4__title--light">
              {Lang.city}
              </span>
              <span className="kt-widget4__number kt-font-info">{(props.info.meeting_city)}</span>
            </div>
            }
            { ! _.isEmpty(props.info.meeting_zip_code) &&
            <div className="kt-widget4__item">
              <span className="kt-widget4__title kt-widget4__title--light">
              {Lang.zip_code}
              </span>
              <span className="kt-widget4__number kt-font-info">{(props.info.meeting_zip_code)}</span>
            </div>
            }
          </>
           }
        </div>
      </div>
      
    </div>
  </div>

  )
}