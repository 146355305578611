import * as React from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {getOrderForCal} from "../../../services";
import Localization, {default as Lang} from "../../../common/lang/Lang";
import {DateInput, getQueryStringParams,SearchSelect, SearchInput} from "../../../common";
const moment = require('moment');

interface IState {
  form: {
    like: {
      name: string,
      email: string
    },
    equal: {
      status: number
    }
  },
 }

export default class CompanyFilter extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
        like: {
          name: old_data ? old_data.like.name : '',
          email: old_data ? old_data.like.email : ''
        },
        equal: {
          status: old_data ? old_data.equal.status : ''
        }
      }})
  }
  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState({form: newAccess},() =>  this.props.getResults(this.state.form));
  }

    formDataReset = (e:any) => { 
        e.preventDefault();
        this.setState({
            form:  {
                like: {
                  name: '',
                  email: ''
                },
                equal: {
                  status: 0
                }
            }
        });     

        var formData = {
                       like: {
                          name: '',
                          email: ''
                        },
                        equal: {
                          status: 0
                        }
                      };

         this.setState({form: formData},() =>  this.props.getResults(this.state.form));
    }

 

  render(){
    const {status} = this.props;
    return (
      <div className="row align-items-center">
        <SearchInput name={'form.like.name'} value={this.state.form.like.name} onChange={this.handleInputForm} placeholder={'Enter user name'} />
        <SearchInput name={'form.like.email'} value={this.state.form.like.email} onChange={this.handleInputForm} placeholder={'Enter email'} />
       <SearchSelect name={'form.equal.status'} value={this.state.form.equal.status}  onChange={this.handleInputForm} options={status}/>
       <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn custom-reset-button'} data-skin={'dark'} title={Lang.reset} onClick={(e)=>this.formDataReset(e)}>{Lang.reset}</button>
      </div>
    )
  }
}