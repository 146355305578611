import React, {useState, useEffect} from 'react';
import {onlineTestService, sendOnlineTestMail} from "../../../services";
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {Editor} from "@tinymce/tinymce-react/lib/cjs/main/ts";
import {Auth} from "../../../Auth/Auth";
import {showNotification, SubmitButton} from "../../../common";

export function SendOnlineTest() {

    const [show, setShow] = useState(false);
    const [content, setContent] = useState({} as {name: string, subject: string, content: string, attachment: any, trans_id: number});
    useEffect(() => {
        onlineTestService.getPopupState().subscribe((res:any) => {
            if(res.show){
                setContent({name: Auth.userInfo.name, subject: 'Vedr. personlig telefonisk samtale med ViTolker', content: res.content, attachment: '', trans_id: res.user.id});
            }
            setShow(res.show);
        })
    });

    if(show === false){
        return null;
    }

    async function sendMail(e) {
        e.preventDefault();
        sendOnlineTestMail(content).then(res => {
            showNotification('success', res);
            onlineTestService.hidePopup();
        })
    }

    return(
        <Modal
            show={show}
            onHide={() => setShow(false) }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
        <form onSubmit={sendMail}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {Lang.send_mail}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className={'row'}>
                        <div className={'col-md-12'} >
                            <div className={'form-group'}>
                                <label>{Lang.name}</label>
                                <input type={'text'} name={'name'} defaultValue={content.name} readOnly={true} className={'form-control'}/>
                            </div>
                            <div className={'form-group'}>
                                <label>{Lang.subject}</label>
                                <input type={'text'} name={'subject'} value={content.subject} onChange={(e) => setContent({...content, subject:e.target.value})}
                                 className={'form-control'} required={true}/>
                            </div>
                            <div className={'form-group'}>
                                <label>{Lang.send_contract_mail_content}</label>
                                <Editor id='content' init={{ menubar:false,mode: "textareas", selector: 'textarea', branding: false, forced_root_block : false, height: 300 }} value={content.content} onEditorChange={(con) => setContent({...content, content:con})} />
                            </div>
                            <div className={'form-group'}>
                                <label>{Lang.attach_document}</label>
                                <input type={'file'} name={'attachment'} onChange={(e) => setContent({...content, attachment:  e.target.files[0]})}  className={'form-control'}/>
                            </div>
                            <a className={'btn btn-label-primary'} href="/admin/assets/files/ViTolker-fagtermer.pdf" download>ViTolker-fagtermer.pdf</a> &nbsp;&nbsp;
                            <a className={'btn btn-label-primary'} href="/admin/assets/files/ViTolkers-uddrag-af-Tolkeguiden.pdf" download>ViTolker-Tolkeguide.pdf</a>

                            
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <SubmitButton className={'btn btn-success'} label={Lang.send} />
                <button className={'btn btn-primary'} onClick={() =>  setShow(false) }>{Lang.close}</button>
            </Modal.Footer>
        </form>
    </Modal>
  )
}
