import React, { useState, useEffect } from 'react';
import { Breadcrumb, CommonSubmitButton, showNotification } from '../../common';
import { CreateBreadcrumb } from './Data';
import { Formik, Form, FieldArray, setNestedObjectValues } from 'formik';
import * as yup from 'yup';
import FormController from './formik/FormController';
import Lang from '../../common/lang/Lang';
import API from '../../common/AxoisClient';
import { isEmpty } from 'lodash';
import { getLanguageList, SubmitButtonService } from '../../services';
import * as _ from 'lodash';
import { Spinner } from 'react-bootstrap';

interface translatorLangPair {
    from_language_id: number | string | null // type is int in db, are being coerced into int from string by php
    to_language_id: number | string | null // type is int in db, are being coerced into int from string by php
}
interface FormValues {
	first_name: string // required
	last_name: string // required
	email: string // required
    country_code: string // required
	mobile_no: string // required
	address: string // required
	city: string
	lat: string
	lng: string
	zip_code: string
	translator_languages: Array<translatorLangPair> // required to have at least one pair
	hr_comment: string | null
	location: Array<string>
	driving_license: '0' | '1' | ''
	police_verified: '0' | '1' | ''
	gender: '1' | '2' | ''
	origin_country: string | null
	own_vehicle: '0' | '1' | '' // required only if driving_license is 1
	verification_number: string | null // required only if police_verified is 1
    worked_with_ministry: '0' | '1'
}

interface countryList {
    countryCodes: Array<countryCode>
    countries: Array<country>
}
interface countryCode {
    text: string
    id: number
    iso: string
}

interface country {
    name: string
    iso: string
    id: number
}

interface langCountry {
    id: number
    iso: string
    iso3: string
    name: string
    nick_name: string
    phone_code: string
    priority: number
    status: number
}

interface language {
    country: langCountry
    country_id: number
    id: number
    language_name: string
    text: string
}

interface selectOption {
    label: string
    value: string | number | boolean | Array<string>
}

type YUP_TEST_FUNCTION = (
    value: string,
    context: Omit<yup.TestContext<Record<string, any>>, 'parent'> & {parent: FormValues}
    ) => boolean | yup.ValidationError | Promise<boolean | yup.ValidationError>

const CreateNew = (props) => {
    const [CountryList, setCountryList] = useState<countryList>({
        countryCodes: [],
        countries: []
    });
    const [LanguageList, setLanguageList] = useState<Array<language>>([]);
    const [ShowAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(false);

    type SELECT_OPTIONS = Array<selectOption>;

    const [locationOptions, setLocationOptions] = useState<SELECT_OPTIONS>();

    const policeOptions: SELECT_OPTIONS = [
        { label: Lang.yes, value: '1' },
        { label: Lang.no, value: '0' },
    ]

    const drivingLicenseOptions: SELECT_OPTIONS = [
        { label: Lang.own_transport, value: '1' },
        { label: Lang.other_transport, value: '0' }
    ]

    const genderOptions: SELECT_OPTIONS = [
        { label: Lang.male, value: '1' },
        { label: Lang.female, value: '2' }
    ]

    const countryOptions: SELECT_OPTIONS = !isEmpty(CountryList.countries)? CountryList.countries.map(country => {
        return { label: country.name, value: country.id };
    }) : [{ label: '', value: '' }];

    const vehicleOptions: SELECT_OPTIONS = [
        { label: Lang.yes, value: '1' },
        { label: Lang.no, value: '0' }
    ]



    useEffect(() => {
        API.get('users/translators/countries').then(res => {
            setCountryList({
                countries: res.data.countryArr,
                countryCodes: res.data.countryCodeArr,
            });
            const newLocationOptions = res.data.locationArr.map(l => ({ label: l.text, value: l.value}));
            setLocationOptions(newLocationOptions)
        })
        getLanguageList().then(data => {
            // This sets the iso on the "surface" of each object because it's needed for getting country flag
            let newData = [...data].map((country, index) => {
                return {
                    ...country,
                    iso: country.country.iso,
                }
            });
            setLanguageList(newData);
        })
    }, []);

    const checkEmailExists = async (value, ) => {
        if (value !== '') {
            let res = await API.get(`users/translator/check-email-exists`, {
                params: {
                    email: value
                }
            });
            if (res.data.status === 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    // Proper addresses filled by Google will have city and zip_code set. This is validation for that.
    const validateGoogleAddress : YUP_TEST_FUNCTION = ( value, context ) => {
        const { parent } = context;
        let valid = false;
        if (parent.city && parent.zip_code) {
            valid = true;
        }
        return valid;
    }

    // Validation for police verification numbers which are only required if policed_verified is '1'.
    const validateVerNum : YUP_TEST_FUNCTION = (value, context) => {
        const { parent } = context;
        let valid = false;

        if( (parent.police_verified === '1' && value) || !parent.police_verified ) {
            valid = true;
        }
        return valid;
    }

    // Vehicle Required if Driving License is '1'
    const validateVehicle : YUP_TEST_FUNCTION = (value, context) => {
        const { parent } = context;
        let valid = false;

        if ( (parent.driving_license === '1' && value) || !parent.driving_license) {
            valid = true;
        }
        return valid;
    }

    const handleShowAdditionalInfo = (
        e: React.ChangeEvent<HTMLInputElement>,
        setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean) => void,
        values: FormValues
    ) => {
        if (e.target.checked !== ShowAdditionalInfo) {
            setShowAdditionalInfo(e.target.checked);
            // reset the optional field values
            if (e.target.checked === false) {
                setValues({
                    ...values,
                    location: [],
                    driving_license: '', // tinyint (1)
                    police_verified: '', // tinyint (1)
                    origin_country: '',
                    own_vehicle: '', // tinyint (1)
                    verification_number: ''
                })
            }
        }
    }

    const validationSchema = yup.object({
        // First Name
        first_name: yup.string()
                    .required(Lang.required_field),

        // Last Name
        last_name: yup.string()
                    .required(Lang.required_field),

        // Email
        email: yup.string().min(5, Lang.value_too_short)
                    .required(Lang.required_field)
                    .email(Lang.required_valid_email)
                    .test('email-exists', Lang.email_already_register, checkEmailExists),

        // Mobile Number Country Code
        country_code: yup.string()
                    .required(Lang.required_field),

        // Mobile Number
        mobile_no: yup.string()
                    .min(8, 'Telefonnummeret er for kort.')
                    .required(Lang.required_valid_mobile),

        // gender
        gender: yup.string().required(Lang.required_translator_gender),

        // Address
        address: yup.string()
                    .required(Lang.required_address)
                    .test('google-address', Lang.address_is_invalid, validateGoogleAddress),

        // Verification Number
        verification_number: yup.string()
                            .test('ver-num-req-validation', Lang.required_field, validateVerNum),

        // Vehicle
        own_vehicle: yup.string()
                        .test('vehicle-required', Lang.required_field, validateVehicle),
    })

    const initialValues: FormValues = {
        first_name: '', // required
        last_name: '', // required
        email: '', // required
        country_code: '45', // required
        mobile_no: '', // required
        address: '', // required
        city: '',
        lat: '',
        lng: '',
        zip_code: '12345678', 
        translator_languages: [ // required to have at least one item
            {
                from_language_id: "96",
                to_language_id: null
            },
        ],
        hr_comment: '',
        gender: '',
        location: [''],
        driving_license: '', // tinyint (1)
        police_verified: '', // tinyint (1)
        origin_country: '',
        own_vehicle: '', // tinyint (1)
        verification_number: '',
        worked_with_ministry: '0'
    }

    function onSubmit(values: FormValues, {setSubmitting, setErrors, setStatus, resetForm}) {
        /* TODO
            - remove duplicate language pairs
        */
        SubmitButtonService.loadStart();
        console.log("post values", values);
        API.post("users/translators", values)
            .then((response) => {
                showNotification("success", Lang.translator_created);
                if(response.status === 200) {
                    SubmitButtonService.clearLoading();
                    props.history.push(
                        `/users/translators/info/` + response.data.translator_id
                      );
                }
            })
    }

    return (
        <div className="container-fluid">
            <Breadcrumb data={CreateBreadcrumb} />
            <div className="kt-portlet kt-portlet--mobile" 
            style={{
                margin: "auto",
                width: "35%",
            }}>
                {/* Card header */}
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        {/* Title */}
                        <h3 className="kt-portlet__head-title">
                            {Lang.add_new_translator}
                        </h3>
                    </div>
                </div>
                <br />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnChange={false}
                >
                    {formik => {
                        return (
                            <Form
                                translate
                                className="kt-form"
                            >
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '90%',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <div className="row">
                                        {/* First Name */}
                                        <FormController
                                            control="text"
                                            label="Fornavn"
                                            name="first_name"
                                            required
                                            placeholder="Indtast Fornavn"
                                            className="form-control light-placeholder"
                                        />
                                        {/* Last Name */}
                                        <FormController
                                            control="text"
                                            label="Efternavn"
                                            name="last_name"
                                            required
                                            placeholder="Indtast Efternavn"
                                            className="form-control light-placeholder"
                                        />
                                    </div>
                                    <div className="row">
                                        {/* Email */}
                                        <FormController
                                            control="email"
                                            label="E-mail"
                                            name="email"
                                            placeholder="Indtast E-mail"
                                            className="form-control light-placeholder"
                                        />
                                        {/* Phone Number */}
                                        <FormController
                                            control="phoneNum"
                                            label="Telefonnummer"
                                            required
                                            countryCode={{
                                                countryCode_name: 'country_code',
                                                countryCode_options: CountryList.countryCodes,
                                                className: 'form-control boxed-input light-placeholder'
                                            }}
                                            mobileNum={{
                                                mobileNum_name: 'mobile_no',
                                                className: 'form-control light-placeholder',
                                                placeholder: "Indtaste Telefonnummer"
                                            }}
                                        />
                                    </div>
                                    {/* Gender */}
                                    <FormController
                                        control='radio'
                                        name='gender'
                                        label={Lang.select_gender}
                                        values={ genderOptions }
                                    />
                                    <div className="row">
                                        {/* Address */}
                                        <FormController
                                            control="address"
                                            label="Adresse"
                                            name="address"
                                            className="form-control light-placeholder"
                                            autoComplete="off"
                                        />
                                    </div>
                                    {/* Translator Language Pair Select */}
                                    <FieldArray name="translator_languages">
                                        {
                                            (props) => {

                                                const { push, remove, form } = props;
                                                const { values } = form;
                                                return (<React.Fragment>
                                                    {
                                                        values.translator_languages.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <FormController
                                                                        control="translatorLanguageSelector"
                                                                        options={LanguageList}
                                                                        remove={remove}
                                                                        index={index}
                                                                        fromInput={{
                                                                            fromName: `translator_languages[${index}].from_language_id`,
                                                                            fromLabel: 'Sprog, der tolkes fra',
                                                                            className: "form-control boxed-input light-placeholder",
                                                                            required: true
                                                                        }}
                                                                        toInput={{
                                                                            toName: `translator_languages[${index}].to_language_id`,
                                                                            toLabel: 'Sprog, der tolkes til',
                                                                            className: "form-control boxed-input light-placeholder",
                                                                            required: true
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div
                                                        className="d-flex flex-row-reverse row"
                                                        style={{
                                                            marginBottom: '2rem',
                                                        }}
                                                    >
                                                        <div>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    push({
                                                                        from_language_id: '96',
                                                                        to_language_id: ''
                                                                    })
                                                                }}
                                                                className="btn addLangBtn"
                                                                style={{
                                                                    border: 'none',
                                                                    fontFamily: 'Poppins',

                                                                }}
                                                            >
                                                                {Lang.add_new_language}&emsp;
                                                                <i className="fas fa-plus hvr-push" />
                                                            </button>
                                                        </div>
                                                    </div>

                                                </React.Fragment>)
                                            }
                                        }
                                    </FieldArray>
                                    <FormController
                                        control='radio'
                                        label={<><span style={{ fontWeight: 'bolder' }}>Har du erfaring med at tolke for Udlændinge- og Integrationsministeriet </span><i>(herunder, Flygtningenævnet, Hjemrejsestyrelsen, Udlændingestyrelsen, SIRI og Udlændingenævnet)</i> ?</>}
                                        name='worked_with_ministry'
                                        className="form-control boxed-input"
                                        values={[{ label: Lang.yes, value: '1' }, { label: Lang.no, value: '0' }]}
                                    />
                                    {/* HR comment */}
                                    <FormController
                                        control="textarea"
                                        label="Bemærkninger til HR"
                                        name="hr_comment"
                                        placeholder="Beskrivelse af dig selv"
                                        className="form-control boxed-input light-placeholder"
                                    />
                                    {/* Enable extra information */}
                                    <FormController
                                        control="checkbox"
                                        label="Tilføj yderligere oplysninger"
                                        name="additional_info_included"
                                        className="form-check-input"
                                        divProps={{
                                            style: {
                                                marginLeft: '10px'
                                            }
                                        }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleShowAdditionalInfo(e, formik.setValues, formik.values)
                                        }}
                                        checked={ShowAdditionalInfo}
                                    />
                                    {
                                        ShowAdditionalInfo && (
                                            <>
                                                <div className="row">
                                                    {/* Location */}
                                                    <FormController
                                                        control="select"
                                                        label="Lokation"
                                                        name="location[0]"
                                                        className="form-control light-placeholder boxed-input"
                                                        placeholder="Vælg muligheder"
                                                        options={ locationOptions }
                                                    />
                                                    {/* Driving License */}
                                                    <FormController
                                                        control="select"
                                                        label="Kørekort"
                                                        name="driving_license"
                                                        className="form-control light-placeholder"
                                                        placeholder="Vælg muligheder"
                                                        options={ drivingLicenseOptions }
                                                    />
                                                </div>
                                                <div className="row">
                                                    {/* Police Approved */}
                                                    <FormController
                                                        control="select"
                                                        label="Politigodkendt"
                                                        name="police_verified"
                                                        className="form-control light-placeholder"
                                                        placeholder="Vælg muligheder"
                                                        options={ policeOptions }
                                                    />
                                                    {/* Country of Origin */}
                                                    <FormController
                                                        control="select"
                                                        label="Oprindelsesland"
                                                        name="origin_country"
                                                        className="form-control light-placeholder"
                                                        placeholder="Vælg muligheder"
                                                        options={ countryOptions }
                                                    />
                                                </div>
                                                {
                                                    (formik.values.driving_license === '1' || formik.values.police_verified === '1') && (
                                                        <div className="row">
                                                            {
                                                                formik.values.police_verified === '1' && (
                                                                    <FormController
                                                                        control="text"
                                                                        label="Police Verification Number"
                                                                        name="verification_number"
                                                                        placeholder="Enter Police Verification Number"
                                                                        className="form-control light-placeholder"
                                                                        required
                                                                    />
                                                                )
                                                            }
                                                            {
                                                                formik.values.driving_license === '1' && (
                                                                    <FormController
                                                                        control="select"
                                                                        label="Vehicle"
                                                                        name="own_vehicle"
                                                                        className="form-control light-placeholder"
                                                                        placeholder="Vælg muligheder"
                                                                        options={ vehicleOptions }
                                                                        required
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                                
                                            </>
                                        )
                                    }
                                </div>
                                <CommonSubmitButton />
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    );
}

export default CreateNew;
