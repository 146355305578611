import React, {useEffect, useReducer, useRef} from 'react';
import { OrderHistoryService} from "../../../services";
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {
  InitialState, PopupState, ActionType,
  CAction, HistoryReducer
} from "../order-reducers/HistoryReducer";
import _ from "lodash";
import {ActivityLogModel, ClientData, OrderType} from "../../../model";
import {RequestWaiting, SetWaitingArea} from "../../../common";

export function HistoryPopup () {
  const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(HistoryReducer , InitialState);
  useEffect( () => {
    SetWaitingArea('history');
    OrderHistoryService.getPopupState().subscribe((message:any) => {
      dispatch({type: message.action, payload: message}) ;
    });
  }, []);

  if(state.show === false){
    return null;
  }

  const {order_data, history} = state;
  var item_count = 0;
  return(
    <Modal
      show={state.show}
      onHide={() => { dispatch({type: ActionType.HIDE_POPUP}) } }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >

        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {Lang.history} - #{order_data.order_no}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
              <div className={'col-md-12'} >
                <RequestWaiting item={history} from={"history"}>
                  {
                    history.length > 0 &&
                      <table className={'table table-bordered table-hover'}>
                        <tbody>
                        <tr>
                          <td>#</td>
                          <td>{Lang.users}</td>
                          <td>{Lang.activity}</td>
                          <td>{Lang.platform}</td>
                          <td>{Lang.created_at}</td>
                        </tr>
                        {state.history.map((item: ActivityLogModel, index: number) => {
                          if(item.new_data || item.old_data){
                            let dataObj: any = JSON.parse(item.new_data);
                            let oldDataObj: any = JSON.parse(item.old_data);
                            
                            if(!("flag_popup" in dataObj) || item.type=='assign_translator'){
                              return (
                                <tr key={item.id}>
                                  <td>{++item_count}</td>
                                  <td>{item.user !== undefined ? (item.user !== null ? item.user.name : '-') : item.admin_id}</td>
                                  <td>
                                  <p dangerouslySetInnerHTML={{ __html: item.message }}/>
                                  { item.type=='update' &&
                                    <p>
                                      <strong>Følgende er ændret i opgaven:</strong>
                                      { ('special_status' in dataObj) &&
                                        <span><br/><br/><strong>særlig status, fra: </strong>{dataObj.special_status ? 'Unmarked' : 'Marked'}  er <strong>rettet</strong> til {dataObj.special_status ? 'Makred' : 'Unmarked'}</span>
                                      }
                                      { dataObj.translation_type_id && oldDataObj.translation_type_id &&
                                        <span><br/><br/><strong>Bestillingstype, fra: </strong>{Object.keys(OrderType).find(key => OrderType[key] === oldDataObj.translation_type_id)}  er <strong>rettet</strong> til {Object.keys(OrderType).find(key => OrderType[key] === dataObj.translation_type_id)}</span>
                                      }
                                      { dataObj.meeting_address &&
                                        <span><br/><br/><strong>Adresse: </strong>{oldDataObj.meeting_address}  er <strong>rettet</strong> til {dataObj.meeting_address}</span>
                                      }
                                    
                                      { dataObj.from_language && oldDataObj.from_language &&
                                        <span><br/><br/><strong>Sprog, fra: </strong>{item.languages.find(lang => lang.id == oldDataObj.from_language).language_name}  er <strong>rettet</strong> til {item.languages.find(lang => lang.id == dataObj.from_language).language_name}</span>
                                      }
                                    
                                      { dataObj.to_language && oldDataObj.to_language &&
                                        <span><br/><br/><strong>Sprog, til: </strong>{item.languages.find(lang => lang.id == oldDataObj.to_language).language_name}  er <strong>rettet</strong> til {item.languages.find(lang => lang.id == dataObj.to_language).language_name}</span>
                                      }
                                    
                                      { dataObj.order_date &&
                                        <span><br/><br/><strong>Dato: </strong>{oldDataObj.order_date}  er <strong>rettet</strong> til {dataObj.order_date}</span>
                                      }
                                    
                                      { dataObj.order_from &&
                                        <span><br/><br/><strong>Tid, fra: </strong>{oldDataObj.order_from}  er <strong>rettet</strong> til {dataObj.order_from}</span>
                                      }
                                    
                                      { dataObj.order_to &&
                                        <span><br/><br/><strong>Tid, til: </strong>{oldDataObj.order_to}  er <strong>rettet</strong> til {dataObj.order_to}</span>
                                      }
                                      <span><br/>
                                      {
                                        (oldDataObj.hasOwnProperty('clients') && oldDataObj.hasOwnProperty('clients')) && (
                                          <span><br/>
                                            ==========
                                            {
                                              !_.isEmpty(oldDataObj.clients) ? oldDataObj.clients.map((client, index) => {
                                                return (
                                                  <React.Fragment key={index}>
                                                    <br/><strong>Borgeren {index + 1} navn:&nbsp;</strong>{client.client_name} - <strong>CPR nr.</strong> {client.client_cpr}
                                                  </React.Fragment>
                                                )
                                              }) : (
                                                <>
                                                  <br/>
                                                  <strong>Ingen Klienter</strong>
                                                </>
                                              )
                                            }<br/>
                                            ==========
                                            <br/><br/>
                                            er <strong>rettet</strong> 
                                            <br/><br/>
                                            ==========
                                            {
                                              !_.isEmpty(dataObj.clients) ? dataObj.clients.map((client, index) => {
                                                return (
                                                  <React.Fragment key={index}>
                                                    <br/><strong>Borgeren {index + 1} navn:&nbsp;</strong>{client.client_name} - <strong>CPR nr.</strong> {client.client_cpr}
                                                  </React.Fragment>
                                                )
                                              }) : (
                                                <>
                                                  <br/>
                                                  <strong>Ingen Klienter</strong>
                                                </>
                                            )
                                            }<br/>
                                            ==========
                                          </span>
                                        )
                                      }
                                      </span>

                                      { dataObj.ean_number &&
                                        <span><br/><br/><strong>EAN nr.: </strong>{oldDataObj.ean_number}  er <strong>rettet</strong> til {dataObj.ean_number}</span>
                                      }

                                      { dataObj.comment_for_translator &&
                                        <span><br/><br/><strong>Bemærkning til tolken: </strong>{oldDataObj.comment_for_translator}  er <strong>rettet</strong> til {dataObj.comment_for_translator}</span>
                                      }

                                      { dataObj.office_note &&
                                        <span><br/><br/><strong>Bemærkninger til kontoret: </strong>{oldDataObj.office_note}  er <strong>rettet</strong> til {dataObj.office_note}</span>
                                      }
                                    </p>
                                  }
                                  </td>
                                  <td>{item.platform } - {item.browser }</td>
                                  <td>{item.action_at}</td>
                                </tr>
                              )
                            }
                          } else {
                            return (
                                <tr key={item.id}>
                                  <td>{++item_count}</td>
                                  <td>{item.user !== undefined ? (item.user !== null ? item.user.name : '-') : item.admin_id}</td>
                                  <td>
                                    <p dangerouslySetInnerHTML={{ __html: item.message }}/>
                                  </td>
                                  <td>{item.platform } - {item.browser }</td>
                                  <td>{item.action_at}</td>
                                </tr>                                      
                            )
                          }
                        })}
                        </tbody>

                      </table>
                  }
                </RequestWaiting>

              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={'btn btn-primary'} onClick={() => { dispatch({type: ActionType.HIDE_POPUP}) } }>{Lang.close}</button>
        </Modal.Footer>
    </Modal>
  )


}