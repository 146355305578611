import React from 'react';
import Index from "./components/Index";
import { CookiesProvider, withCookies } from 'react-cookie';

class App extends React.PureComponent {
  render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return (
      <CookiesProvider>
        <Index/>
      </CookiesProvider>
    );
  }
}
export default withCookies(App);
