import React, { PropsWithChildren } from 'react';
import { Icons } from '../../../common';
import { Country_Array, Translator_Data } from './TypeDefs';

// styles
import callStyle from './CallStyle.module.scss';
import _, { isEmpty, isUndefined } from 'lodash';
import { TranslatorStatuses } from '../../../helpers/UserHelper';
import { UserModel } from '../../../model';
import Lang from '../../../common/lang/Lang';
import LanguagePair from './LanguagePair';

// interfaces

interface Props {
    translatorInfo: Translator_Data
    countries: Country_Array
}

const TranslatorInfo: React.FC<PropsWithChildren<Props>> = (props) => {
    const { translatorInfo, countries } = props;
    // nulls (value is sent empty) are coerced to undefined to avoid fatal errors in case for whatever reason one of the branches leading to the value is undefined (checked for using optional chaining)
    // sometimes the coercion is unnecessary but it's done nonetheless for consistency
    // I'm casting values I need to check to strings, best workaround as I don't know if back-end strictly sends ints
    const display_info = {
        email: translatorInfo.email,
        mobile_no: translatorInfo.mobile_no,
        country_code: translatorInfo.country_code,
        address: translatorInfo.address,
        gender: (translatorInfo.gender ?? undefined) !== undefined? String(translatorInfo.gender) === '1' ? Lang.man : Lang.woman : '---',
        driving_license: (translatorInfo.translator?.driving_license ?? undefined) !== undefined? String(translatorInfo.translator?.driving_license) === '1'? Lang.other_transport : Lang.own_transport : '---',
        own_vehicle: (translatorInfo.translator?.own_vehicle ?? undefined) !== undefined? String(translatorInfo.translator?.own_vehicle) === '0'? Lang.no : Lang.yes : '---',
        country: translatorInfo.translator?.country?.name || '---',
        police_verified: (translatorInfo.translator?.police_verified ?? undefined) !== undefined? String(translatorInfo.translator?.police_verified) === '0'? Lang.no : Lang.yes : '---',
        cpr_number: translatorInfo.translator?.cvr_number || '---',
        bank_reg_no: translatorInfo.translator?.bank_registration_no || '---',
        account_no: translatorInfo.translator?.account_no || '---'
    };
    const {
        email, mobile_no, country_code, address, gender, driving_license, own_vehicle, country, police_verified, cpr_number, bank_reg_no, account_no
    } = display_info;
    
    return (
        <div className={callStyle.info_container}>
            <div className={callStyle.profile_picture}>
                <img
                    src={translatorInfo.profile_image || 'https://api.vitolker.com/media/user-placeholder.jpg'}
                    alt="Translator"
                />
            </div>
            <div className={callStyle.translator_information}>
                <h2 className={callStyle.translator_name}>
                    {
                        !translatorInfo.name?
                            "Loading..." : translatorInfo.name + '  ' + translatorInfo.user_code
                    }
                </h2>
                {/* First information list */}
                <ul className={callStyle.info_list}>
                    {/* email */}
                    <li className={callStyle.info_item}><i className={Icons.email}></i>{ email }</li>   
                    {/* mobile number */}
                    <li className={callStyle.info_item}><i className={Icons.mobile_no}></i>+{country_code} - {mobile_no}</li>
                    {/* address */}
                    <li className={callStyle.info_item}><i className={Icons.address}></i>{ address }</li>
                    {/* gender */}
                    <li className={callStyle.info_item}><i className={Icons.male}></i>{ gender }</li>
                </ul>
                {/* Second Information List */}
                <ul className={callStyle.second_info_list}>
                    {/* Driving License */}
                    <li><span> Kørekort: </span><span>{ driving_license }</span></li>
                    {/* Has own vehicle */}
                    { (driving_license === Lang.own_transport) && (<li><span> Vehicle: </span><span>{ own_vehicle }</span></li>) }
                    {/* Country of origin */}
                    <li><span> Oprindelsesland: </span><span>{ country }</span></li>
                    {/* Police verified */}
                    <li><span> Politigodkendt: </span><span>{ police_verified }</span></li>
                    {/* CVR Number */}
                    <li><span> CPR Number: </span><span>{ cpr_number }</span></li>
                    {/* ?? */}
                    <li><span> Reg.nr: </span><span>{bank_reg_no}</span></li>
                    {/* Account number */}
                    <li><span> Kontonr.: </span><span>{ account_no }</span></li>
                </ul>
            </div>
            <div className={callStyle.lang_info_list}>
                <h2>{Lang.languages}</h2>
                <ul>
                    {
                        translatorInfo.translator_languages.map(lang => {

                            return (
                                <li>
                                    <LanguagePair
                                        from={{
                                            name: lang.from_language.language_name,
                                            iso: !_.isEmpty(countries)?  countries[lang.from_language_id - 1]?.iso.toLowerCase() : null,
                                        }}
                                        to={{
                                            name: lang.to_language.language_name,
                                            iso: !_.isEmpty(countries) ? countries[lang.to_language_id - 1]?.iso.toLowerCase() : null,
                                        }}
                                    />
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className={callStyle.status_display}>
                <h2>Status</h2>
                <div className={callStyle.status_list}>
                    {
                        <TranslatorStatuses data={translatorInfo as unknown as UserModel} />
                    }
                </div>
            </div>
        </div>
    );
}

export default TranslatorInfo;
