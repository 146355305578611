import * as React from 'react';
import {
  SearchInput, SearchSelect, IState as CommonState, ShowRecords,
  CONST, ExportButton, getQueryStringParams, actionButton, CreateLink, EditLink, Icons, base_url
} from "../../common";
import {RouteComponentProps} from "react-router";
import {PayoutModel, UserModel} from "../../model";
import {ListBreadcrumb} from "./Data";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import {SeeOrders} from "../orders/OrdersPopup";
import {PayoutServices} from "../../services/PayoutServices";
import Lang from "../../common/lang/Lang";
import {order_to_language} from "../../helpers/OrderHelper";

interface IState extends CommonState{
  data: Array<any>;
  form: {
    like: {
      name: string,
      email: string
    },
    equal: {
      status: number
    }
  }
}


export class PaymentOrdersPayout extends React.Component<RouteComponentProps<any>, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
        like: {
          name: old_data ? old_data.like.name : '',
          email: old_data ? old_data.like.email : ''
        },
        equal: {
          status: old_data ? old_data.equal.status : ''
        }
      }})
  }

  showContent = (data: PayoutModel, index: number) => {
    let cols = [];
    cols.push(...[
      this.records.current.createdTd("id", index),
      this.records.current.createdTd("language", `${data.order.language_from.language_name} - ${order_to_language(data.order)}`),
      this.records.current.createdTd("translation_type", data.order.translation_type.title),
      this.records.current.createdTd("work_time",  data.work_time),
      this.records.current.createdTd("rate", data.rate),
      this.records.current.createdTd("total_amount", data.amount),
      this.records.current.createdTd("completed_at", data.completed_at),
      this.records.current.createdTd("approve_at", data.approve_at),
      this.records.current.createdTd("paid_at", data.paid_at),
      this.records.current.createdTd("action", () => {
        return (
          <div>
            <a href={`${base_url}api/admin/orders/orders/generate-pdf/${data.order.id}`} type={'button'} className="btn btn-success" target={'_blank'}> {Lang.pdf} </a>
            &nbsp;
            <button className="btn btn-warning" onClick={() => PayoutServices.movePaidToComplete(data.id).then(res => this.filterRecords())} type={'button'}>{Lang.move_back}</button>
          </div>

        )
      })
    ]);
    cols = _.compact(cols);

    return (
      <tr key={index}>{cols}</tr>
    );
  };

  filterRecords = () => {
    if( this.records && this.records.current !== null){
      this.records.current.setFormFields(this.state.form);
    }
  };


  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState({form: newAccess}, this.filterRecords);
  };


  rowActionButtons = (data: UserModel) => {
    return (
      <EditLink scope={'admin-users.update'} to={`${this.props.match.url}/edit/${data.id}`} />
    )
  }

  render() {
    const status = CONST.status;
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'paid_payout_title'} icon={Icons.dollor} >
            <ExportButton records={this.records} fileName={Lang.exportPaymentOrders}/>
            &nbsp;
          </ListHeader>

          <ListForm
            form={(
              <div className="row align-items-center">
                <SearchInput name={'form.like.name'} value={this.state.form.like.name} onChange={this.handleInputForm} placeholder={'Enter user name'} />
                <SearchInput name={'form.like.email'} value={this.state.form.like.email} onChange={this.handleInputForm} placeholder={'Enter email'} />
                <SearchSelect name={'form.equal.status'} value={this.state.form.equal.status}  onChange={this.handleInputForm} options={status}/>
              </div>
            )}
            table={(
              <ShowRecords
                url={`/payouts/paid-orders/${this.props.match.params.id}`}
                showRecords={this.showContent}
                ref={this.records}
                showCheckbox={false}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "users/admin-users/bulk-action"})}
                rowActions={this.rowActionButtons}
              />
            )}
          />
          <SeeOrders />
        </ListingLayout>
    )
  }
}
