import * as React from 'react';
import {Breadcrumb} from "../../common/page/Breadcrumb";
import {CreateBreadcrumb, Form, IState} from "./Data";
import Lang from "../../common/lang/Lang";
import API from './../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {AdminUserFormData} from "../../model/User";
import {RoleModel} from "../../model/Role";
import {AdminUserValidations} from "./Validations";
import {CreateLayout} from "../../layout/CreateLayout";
import {CommonSubmitButton} from "../../common/form/CommonSubmitButton";
import {SwitchLoader, InlineLoader} from "../../common";
import {CountryFlags, Select2Wrapper} from "../../common/Select2";
import {LoaderType} from "../../common/Loader";
import {AxiosResponse} from "axios";

export class AdminUserCreate extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      form: AdminUserFormData(),
      roles: [],
      countries:[],
      countrycodes:[],
      isEmailExists:2
    }
    AdminUserValidations();
  }

  componentDidMount() {
    API.get('users/admin-users/create').then((res) => {
      this.setState({
        roles: res.data
      })
    })

    API.get("users/admin-users/countries-list").then(res => {
      this.setState({
          countries: res.data.countryArr,
          countrycodes: res.data.countryCodeArr
      }, () => {
          ($(".selectpicker") as any).selectpicker('refresh');
      });
})
  }

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }


  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#admin_user_form").valid() && this.state.isEmailExists==0){
      API.post('users/admin-users', this.state.form).then((res) => {
        this.props.history.push(`/users/admin-users/edit/${res.data.id}/permissions`)
      })
    }
  }
  checkEmail = ()=>{
        var email = this.state.form.email;
        let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(email !== ''  && re.test(email)){
            SwitchLoader(LoaderType.INLINE);
            API.get(`users/translator/check-email-exists`, {params: {email: email}}).then((res: AxiosResponse) => {
                if(res.data.status===0){
                    this.setState({
                        'isEmailExists':0
                    });
                } else {
                    this.setState({
                        'isEmailExists':1
                    });
                }
            })
        } else {
            this.setState({
                'isEmailExists':2
            });
        }
    }

    resetForm = () => { 
        this.setState({
            form: AdminUserFormData()
        });
    }

  render() {
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'add_admin_user'}>
          <form className="kt-form" id={'admin_user_form'} onSubmit={this.handleSubmit}>
            <div className="kt-portlet__body">
              <div className={'row'}>

                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label>{Lang.role} <span className={'required'}>*</span></label>
                    <select onChange={this.handleChange} value={this.state.form.role_id} name={'role_id'} className="form-control" >
                      <option value={''}>{Lang.select_role}</option>
                      {this.state.roles.map((item:RoleModel, index: number) => {
                        return <option value={item.id}>{item.name}</option>
                      })}
                    </select>
                  </div>
                </div>
                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label>{Lang.name} <span className={'required'}>*</span></label>
                    <input type={'text'} onChange={this.handleChange} value={this.state.form.name} name={'name'} className="form-control" placeholder={Lang.getString(`name_placeholder`)}/>
                  </div>
                </div>

                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label>{Lang.email} <span className={'required'}>*</span></label>
                    <InlineLoader>
                    <input type={'email'} onChange={this.handleChange} value={this.state.form.email} name={'email'} className="form-control" placeholder={Lang.getString(`email_placeholder`)} onBlur={this.checkEmail}/>
                    </InlineLoader>

                      { this.state.isEmailExists === 1 && <>
                          <label id="email-exists-error" className={'error'} style={{color:'red'}}>{Lang.email_already_register}</label></>
                      }
                  </div>
                </div>

                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label>{Lang.password} <span className={'required'}>*</span></label>
                    <input type={'password'} onChange={this.handleChange} value={this.state.form.password} name={'password'} className="form-control" placeholder={Lang.getString(`password_placeholder`)} id={'password'}/>
                  </div>
                </div>

                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label>{Lang.cn_password} <span className={'required'}>*</span></label>
                    <input type={'password'} onChange={this.handleChange} value={this.state.form.cn_password} name={'cn_password'} className="form-control" placeholder={Lang.getString(`cn_password_placeholder`)}/>
                  </div>
                </div>
               
                <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group ">
                            <label>{Lang.telephone_number} <span className={'required'}>*</span></label>
                            <div className={"row"}>
                                <div className={"col-md-4 col-lg-4"}>
                                    <Select2Wrapper   onChange={this.handleChange}
                                                    className={'form-control'} id={'country_code'} name={'country_code'}
                                                    value={this.state.form.country_code}
                                                    data={{
                                                        placeholder: "Select Options",
                                                        data: this.state.countrycodes,
                                                        templateResult: CountryFlags,
                                                        templateSelection: CountryFlags,
                                                    }}
                                    />
                          
                                </div>
                                <div className={"col-md-8 col-lg-8"}>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.mobile_no} name={'mobile_no'} className="form-control" placeholder={Lang.getString(`mobile_placeholder`)}/>
                                </div>
                            </div>
                        </div>
                       </div>
 
              </div>
            </div>
            <div className='reset-btn-section'>
            
              <CommonSubmitButton/>
              <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn'} data-skin={'dark'} title={Lang.reset}
                     onClick={this.resetForm}>{Lang.reset}</button>
             </div>                

          </form>
        </CreateLayout>
    )
  }
}