import React, {useReducer} from 'react';
import {OrderModel, UserModel} from "../../../model";

// all actions for assign translator
export enum ActionType {
  SHOW_POPUP = 'SHOW_POPUP',
  HIDE_POPUP = 'HIDE_POPUP',
  GET_LIST = 'GET_LIST',
  ADD_TRANS = 'ADD_TRANS',
  REMOVE_TRANS = 'REMOVE_TRANS',
  PUSH_TRANS = 'PUSH_TRANS',
  SEND_TRANS = 'SEND_TRANS',
  CHANGE_FORM = 'CHANGE_FORM',
}


export interface PopupState  {
  translatorsList: Array<UserModel>;
  show: boolean;
  order_data: OrderModel;
  translators: Array<number>;
  fav_translators: Array<number>;
  un_fav_translators: Array<number>;
  order_id: number;
  form: {
    order_id: number;
    distance: number|string;
    is_certified: boolean;
    push_agreement: boolean;
  }
}

export interface IAction {
  type: ActionType;
  payload?: PopupState
}

export const initialTranslators: PopupState = {
  translatorsList: [],
  show: false,
  order_data: {} as OrderModel,
  translators: [],
  fav_translators: [],
  un_fav_translators: [],
  order_id: 0,
  form: {
    order_id : 0,
    distance: '',
    is_certified: false,
    push_agreement: false,
  }
};



// reducer for assign translator popup

export function TranslatorReducer <PopupState, IAction> (state, action) {

  switch (action.type) {
    // show popup and set order data
    case ActionType.SHOW_POPUP:

      const {fav_trans, order, un_fav_trans } = action.payload.records;

      return {...state, show: true, order_data:order, fav_translators: fav_trans, un_fav_translators: un_fav_trans, order_id: order.id, form: {...state.form, order_id: order.id}};

    // hide popup and blank old state
    case ActionType.HIDE_POPUP:
      return {...state, ...initialTranslators};

    // handle change of distance dropdown
    case ActionType.CHANGE_FORM:
      const name = action.payload.name;
      const value = action.payload.type == 'checkbox' ? action.payload.checked : action.payload.value;
      return {...state, form: {...state.form, [name]: value}};

    // set translator list
    case ActionType.GET_LIST:
      return {...state, translatorsList: action.payload};

    // add translator in state which is selected
    case ActionType.ADD_TRANS:
      return {...state, translators : [...state.translators, action.payload]};

    // remove translator in state which is selected
    case ActionType.REMOVE_TRANS:
      return {...state, translators: state.translators.filter(function(item: number) {
          return item !== action.payload
        })};

    case ActionType.PUSH_TRANS:
      return {};

    case ActionType.SEND_TRANS:
      return {};

    default:
      throw new Error();
  }
}
