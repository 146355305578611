import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Auth } from "../Auth/Auth";
import { HandleServerError } from "./HandleError";
import { LoaderType, ShowLoader, StopLoader } from "./Loader";
import { RequestService } from "../services/RequestService";
import _ from "lodash";
//export const base_url = "http://we-translate.local/";
//export const base_url = "http://we-translate.demoserver4semidot.com/";
// export const base_url = "http://api.beta-vitolker.com/";
//export const base_url = "http://vitolker-api.local/";
//export const base_url = "http://api.beta-vitolker.com/";
 export const base_url = "https://api.vitolker.com/";
// export const base_url = "http://127.0.0.1:8000/";
//export const base_url = "http://192.168.43.64/we-translate/public/";
//export const base_url = "http://172.20.10.11/we-translate/public/";
//export const base_url = "http://localhost/we-translate/public/";

let loader = LoaderType.COMMON;
let waitingFrom = "none";

export function SwitchLoader(state: LoaderType) {
  loader = state;
}

export function SetWaitingArea(state: string) {
  waitingFrom = state;
}

const Axios = axios.create({
  baseURL: `${base_url}api/admin/`,
});

// Add a request interceptor
Axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    ShowLoader(loader);
    config.headers["Authorization"] = `Bearer ${Auth.token}`;
    if (_.isUndefined(config.headers["From"])) {
      config.headers["From"] = `web`;
    }
    if (loader !== LoaderType.BUTTON) {
      RequestService.start(waitingFrom);
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
Axios.interceptors.response.use(
  (response: AxiosResponse) => {
    RequestService.end();
    StopLoader(loader);
    waitingFrom = "none";
    return response;
  },
  (error: AxiosError) => {
    waitingFrom = "none";
    RequestService.end();
    StopLoader(loader);
    HandleServerError(error);

    return Promise.reject(error.response);
  }
);

export default Axios;
