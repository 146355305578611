import React, { useState, useEffect } from 'react';
import { number } from 'yargs';
import Lang from '../lang/Lang';
import { FormElementGroup } from './Elements';
import { gapi, loadAuth2 } from 'gapi-script';
import {DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {TextField} from '@material-ui/core';
import MomentUtils from '@date-io/moment';

// import {moment} from "moment";
const moment = require("moment");

interface CalendarEventProps {
    eventDuration?: number,
    attendees?: any,
    setcalendarEvent: (any) => void,
    disabled?: boolean,
    required?: boolean,
    eventTitle: string,
    startDate?: any,
    interview_scheduled_at?: string,
    setEventToUpdate? : (any) => void,
}

interface CalendarEvent {
    summary: string,
    location?: string,
    description?: string,
    start: {
        date?: string,
        dateTime: Date,
        timeZone?: string
    },
    end: {
        date?: string,
        dateTime: Date,
        timeZone?: string
    },
    attendees?: [
        {
            id: string,
            displayName: string,
            email: string,
            comment?: string
        }
    ]
}

const CreateCalendarEvent = (props: CalendarEventProps) => {

    const [event, setEvent] = useState<CalendarEvent>();
    // const [startDate, setStartDate] = useState<any>(new moment().toDate());
    const [startDate, setStartDate] = useState<any>(props.startDate ? props.startDate : null);
    // const [endDate, setEndDate] = useState<any>(new moment().toDate());
    const [endDate, setEndDate] = useState<any>(null);
    const GOOGLE_CALENDAR_INFO = {
        client_id : '380208470563-b1sr8vrm11ricr99jcqjh48r4luvcvjj', //'380208470563-up02b60npm0krq8gfklno0qd1dl1lnd5',
        apiKey: 'AIzaSyDcmUKeutuJuZ43sm5ZiSi1hCpX0hp9keM', //C1PftMTk9UEsjgnMTrJQ5ZY6
        discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
        scope: "https://www.googleapis.com/auth/calendar",
    }

    
    function initClient() {
        gapi.client.init(GOOGLE_CALENDAR_INFO).then(function () {
        // Listen for sign-in state changes.
        gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

        let isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
        if (!isSignedIn) gapi.auth2.getAuthInstance().signIn();
        // Handle the initial sign-in state.
        updateSigninStatus(isSignedIn);
        }, function(error) {
        console.log(JSON.stringify(error, null, 2));
        });
    }

      /**
       *  Called when the signed in status changes, to update the UI
       *  appropriately. After a sign-in, the API is called.
       */
       function updateSigninStatus(isSignedIn) {
        if (isSignedIn) {
            findEventToUpdate();
        } else {
        }
      }

      function findEventToUpdate() {
        let minDate = new Date();
        minDate.setMonth(minDate.getMonth() - 3);
        gapi.client.calendar.events.list({
          'calendarId': 'primary',
          'timeMin': minDate.toISOString(),
          'showDeleted': false,
          'singleEvents': true,
        //   'maxResults': 10,
          'orderBy': 'startTime'
        }).then(function(response) {
          var events = response.result.items;
          console.log('User events: ', events);
          console.log("Existing event date: ", props.interview_scheduled_at)
          if (events.length > 0) {
            for (let i = 0; i < events.length; i++) {
              var event = events[i];
              var when = event.start.dateTime ? event.start.dateTime : event.start.date;
            // console.log(event.summary + ' (' + when + ')')
              // compare interview date with event date, set event to update if they have the same date
              let interviewDate = new Date(props.interview_scheduled_at);
              let eventDate = new Date(when);
              if (interviewDate.getTime() === eventDate.getTime()) {
                console.log("The existing event is found in the user's schedule !")
                props.setEventToUpdate(event.id);
            }
              else console.log("The existing event is not in the user's schedule.") 
            }
          } else {
            console.log('No upcoming events found.');
          }
        });
      }


    useEffect(() => {
        gapi.load('client:auth2', initClient);
    }, []);

    const handleSetDate = (values) => {
        let isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
        if (isSignedIn) {
            let moment_startDate = values.toDate();
            setStartDate(moment_startDate);
            let moment_endDate = moment(values).add(props.eventDuration ? props.eventDuration : 15, 'm').toDate();
            setEndDate(moment_endDate);
            const {attendees} = props;
            props.setcalendarEvent({
                event: {
                summary: props.eventTitle,
                start: {
                    dateTime: moment_startDate,
                    timeZone: "GMT+2",
                },
                end: {
                    dateTime: moment_endDate,
                    timeZone: "GMT+2",
                },
                attendees: [
                    {
                        id: attendees.id,
                        displayName: attendees.name,
                        email: attendees.email,
                        comment: attendees.mobile_no,
                    }
                ],
                format24HourTime: "true"
            }, 
            gapi: gapi});
        }
        else {
            gapi.client.init(GOOGLE_CALENDAR_INFO).then(function () {
                // Listen for sign-in state changes.
                gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
        
                let isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
                if (!isSignedIn) gapi.auth2.getAuthInstance().signIn();
                // Handle the initial sign-in state.
                updateSigninStatus(isSignedIn);
                }, function(error) {
                console.log(JSON.stringify(error, null, 2));
                });
            alert("Log ind på din Vitolker Google-konto");
        }
        
    }

    // Useless function for now
    const handleSubmit = () => {
        const {attendees} = props;
        setEvent({
            summary: `Preliminary Interview with new applicant`,
            start: {
                dateTime: startDate,
                timeZone: "GMT+2",
            },
            end: {
                dateTime: endDate,
                timeZone: "GMT+2",
            },
            attendees: [
                {
                    id: attendees.id,
                    displayName: attendees.name,
                    email: attendees.email,
                    comment: attendees.mobile_no,
                }
            ]
        });
        let request = gapi.client.calendar.events.insert({
            'calendarId': 'primary',
            'sendUpdates': 'all',
            'resource': event
          });
    }

    return(
        <>
            <div className="input-group date form_datetime text text-dark">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker 
                    disabled={props.disabled}
                    id="datetime-local"
                    label={Lang.pick_datetime}
                    value={startDate}
                    onChange={handleSetDate}
                    className={"form-control orders order_date"}
                    ampm={false}
                    // InputLabelProps={{
                    // shrink: true,
                    // }}
                />
                </MuiPickersUtilsProvider>
            </div>
        </>
    )    
}

export default CreateCalendarEvent;