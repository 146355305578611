import * as React from 'react';
import {Breadcrumb} from "../../common/page/Breadcrumb";
import Lang from "../../common/lang/Lang";
import {OrdersBreadcrumb} from "./Data";
import {RouteComponentProps} from "react-router";
import {ShowRecords} from "../../common/ShowRecords";
import {OrderModel} from "../../model";
import {IState as CommonState} from "../../common/State";
import {OrdersListing} from "../../orders/orders/OrdersListing";

interface IState extends CommonState {
  id: any;
  ModalData?: OrderModel;
  show?: boolean
}

export class TranslatorOrders extends React.Component<RouteComponentProps,IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  constructor(props: any) {
    super(props);
    this.state = {
      id: props.match.params.id,
    } as any;
  }

  render() {
  
    return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
     <Breadcrumb data={OrdersBreadcrumb}/>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="row">
          <div className="col-xl-12">
            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">{Lang.order}</h3>
                </div>
              </div><br/>
              <OrdersListing type={'translator'}/> {/* Show orders */}
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}