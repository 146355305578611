import * as React from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {getOrderForCal, LanguageAndCompanyList} from "../../../services";
import {CONST, getQueryStringParams, SearchInput, SearchSelect} from "../../../common";
import {default as Lang} from "../../../common/lang/Lang";
import {getDept, getDeptUnit} from "../../../helpers/OrderHelper";
import _ from "lodash";
import {OrderConst} from "../Data";
const moment = require('moment');

export interface Form {
  like: {
    q: string,
  },
  equal: {
    filter_status: number,
    order_date: string;
    from_language: string;
    to_language: string;
    company_id: string;
    department_id: string;
    department_unit_id: string;
    customer_id: string;
    translator_id: string;
    order_type: string | number;
  },
  between?: {
    from: string;
    to:string;
  },
  in?: {
    id: (number | string)[]
  }
  request_changes: number;
  request_cancel : number;
};

interface IState {
  form ?: Form,
  dept: Array<any>,
  units: Array<any>,
  companies: Array<any>;
  languages: Array<any>;
}

export default class ActiveOrderFilter extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {

    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
     
    this.setState({
      dept: [],
      units:[],
      companies: [],
      languages: [],
      form:  {
        like: {
          q: old_data ? old_data.like.q : '',
        },
        equal: {
          filter_status: old_data ? old_data.equal.filter_status : '',
          order_date: old_data ? old_data.equal.order_date : '',
          from_language: old_data ? old_data.equal.from_language : '',
          to_language: old_data ? old_data.equal.to_language : '',
          company_id: old_data ? old_data.equal.company_id : '',
          department_id: old_data ? old_data.equal.department_id : '',
          department_unit_id: old_data ? old_data.equal.department_unit_id : '',
          customer_id: old_data ? old_data.equal.customer_id : '',
          translator_id: old_data ? old_data.equal.translator_id : '',
          order_type: old_data ? old_data.equal.order_type : '',
        },
        between: {
            from: old_data && old_data.between ? old_data.between.start : '',
            to:old_data && old_data.between ? old_data.between.end : '',
        },
        in: {
          id: []
        },
        request_changes: old_data ? old_data.request_changes : 0,
        request_cancel: old_data ? old_data.request_cancel : 0,
      }})
  }



  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {

    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    let new_state = {form: newAccess};

    // show dept filter and set data for it
    if(name[2] === "company_id"){
      const {companies} = this.state;
      new_state['dept'] = getDept(companies, Number(e.target.value));
    }

    // show dept unit filter and set data for it.

    if(name[2] === "department_id"){
      new_state['units'] = getDeptUnit(this.state.dept, Number(e.target.value));
    }

    this.setState(new_state,() =>  this.props.getResults(this.state.form));
  }

  handleCalClicks = (date:string) => {
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess['equal']['order_date'] = date;
    newAccess['equal']['filter_status'] = "";
    this.setState({form: newAccess}, () =>  this.props.getResults(this.state.form));
    setTimeout(function () {
      $('.fc-day-number').css('color','#000');
    },10);
  }

  handledaterange = (start:string,end:string) => {
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess['between']['from'] = start;
    newAccess['between']['to'] = end;
    this.setState({form: newAccess}, () =>  this.props.getResults(this.state.form));
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.state.form.equal.company_id !== '' && !_.isEmpty(nextProps.companies )){
      const dept: any = getDept(nextProps.companies, Number(this.state.form.equal.company_id));
      this.setState({
        dept: dept,
        units: getDeptUnit(dept, Number(this.state.form.equal.department_id))
      })
    }
  }

  componentDidMount() {
    var datadate = this;
   
    (jQuery('#kt_daterangepicker_2_modal') as any).daterangepicker({
      buttonClasses: ' btn',
      applyClass: 'btn-primary',
      cancelClass: 'btn-secondary'
  }, function(start, end, label) {
      $('#kt_daterangepicker_2_modal .form-control').val( start.format('YYYY-MM-DD') + ' / ' + end.format('YYYY-MM-DD'));
       datadate.handledaterange(start.format('YYYY-MM-DD'),end.format('YYYY-MM-DD'))
  });

    LanguageAndCompanyList().then(res => {
      this.setState({
        languages: res.languages,
        companies: res.companies,
      })
    });

  }

  formDataReset = (e:any) => { 
        e.preventDefault();
        this.setState({
              form:  {
                like: {
                  q: '',
                },
                equal: {
                  filter_status: 0,
                  order_date: '',
                  from_language: '',
                  to_language: '',
                  company_id: '',
                  department_id: '',
                  department_unit_id: '',
                  customer_id: '',
                  translator_id: '',
                  order_type: '',
                },
                between: {
                    from: '',
                    to: '',
                },
                request_changes:  0,
                request_cancel: 0,
            }
        });     

        var formData = {
                        like: {
                          q: '',
                        },
                        equal: {
                          filter_status: 0,
                          order_date: '',
                          from_language: '',
                          to_language: '',
                          company_id: '',
                          department_id: '',
                          department_unit_id: '',
                          customer_id: '',
                          translator_id: '',
                          order_type: '',
                        },
                        between: {
                            from: '',
                            to: '',
                        },
                        request_changes:  0,
                        request_cancel: 0,
                    };



        this.setState({form: formData}, () => this.props.getResults(this.state.form));
    }

  render(){
    const status = OrderConst.filter_status;
    const {languages, companies} = this.state;
    return (
      <div className="row align-items-center">
        {this.state.form.request_changes == 0 && this.state.form.request_cancel == 0 &&
          <div className="col-md-12 kt-margin-b-20-tablet-and-mobile" style={{marginBottom:10}}>
          <FullCalendar defaultView="dayGridMonth"
                        eventColor={'white'}
                        plugins={[ dayGridPlugin, interactionPlugin  ]}
                        eventTextColor={'rgb(255,255,255)'}
                        eventSources={[{
                          events: getOrderForCal,
                          backgroundColor: '#DB1010',
                          textColor:'white',
                          eventColor:'#378006',
                          className:'bg_colour_set_with_text'
                        }
                        ]}
                        locale={'da'}
                        firstDay={1}
                        dateClick={(info) => this.handleCalClicks(info.dateStr)}
                        eventClick={(info) => this.handleCalClicks(moment(info.event.start).format("Y-M-D"))}

          />
        </div>
      }
      
       <SearchInput name={'form.like.q'} value={this.state.form.like.q || ''} onChange={this.handleInputForm} placeholder={Lang.search_orders} />

        <div className="col-lg-6 col-md-6 col-sm-6">
             <div className='input-group' id='kt_daterangepicker_2_modal'>
                <input type='text' className="form-control" readOnly={true}  placeholder={Lang.select_date_range}/>
                   <div className="input-group-append">
                       <span className="input-group-text"><i className="la la-calendar-check-o"/></span>
                     </div>
                </div>
         </div>
        <SearchSelect name={'form.equal.from_language'} value={this.state.form.equal.from_language || ''}  onChange={this.handleInputForm} options={[{label: Lang.from_language, value: ''}, ...languages]}/>
        <SearchSelect name={'form.equal.to_language'} value={this.state.form.equal.to_language || ''}  onChange={this.handleInputForm} options={[{label: Lang.to_language, value: ''}, ...languages]}/>
        <SearchSelect name={'form.equal.company_id'}  className="select-company" value={this.state.form.equal.company_id || ''}  onChange={this.handleInputForm} options={[{label: Lang.choose_company, value: ''}, ...companies]}/>
        {
          this.state.form.equal.company_id &&
          <SearchSelect name={'form.equal.department_id'}  className="select-company" value={this.state.form.equal.department_id || ''}  onChange={this.handleInputForm} options={this.state.dept}/>
        }

        {
          this.state.form.equal.company_id && this.state.form.equal.department_id &&
          <SearchSelect name={'form.equal.department_unit_id'}  className="select-company" value={this.state.form.equal.department_unit_id || ''}  onChange={this.handleInputForm} options={this.state.units}/>
        }

        <SearchSelect name={'form.equal.filter_status'} value={this.state.form.equal.filter_status}  onChange={this.handleInputForm} options={status}/>
        <SearchSelect name={'form.equal.order_type'} value={this.state.form.equal.order_type}  onChange={this.handleInputForm} options={OrderConst.order_types}/>
        <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn custom-reset-button'} data-skin={'dark'} title={Lang.reset} onClick={(e)=>this.formDataReset(e)}>{Lang.reset}</button>

      </div>
    )
  }
}