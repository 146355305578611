import React, { useState } from "react";
import Axios from "../../../../../common/AxoisClient";
import style from "../SkanderborgFeedback.module.scss";
import StarRating from "./StarRating";

interface FeedbackState {
  rating: number;
  rejection_message: string | undefined;
  feedback_message: string | undefined;
  acceptance_status: "accepted" | "rejected" | "submitted" | undefined;
}

interface Props {
  orderId: number;
}

function Feedback({ orderId }: Props) {
  const [feedback, setFeedback] = useState<FeedbackState>({
    rating: 5,
    rejection_message: undefined,
    feedback_message: undefined,
    acceptance_status: undefined,
  });
  const [emptyMessage, setEmptyMessage] = useState<boolean>(false);

  const handleSubmit = () => {
    const submittedFeedback: Partial<typeof feedback> = { ...feedback };
    // remove uneeded properties
    if (submittedFeedback.acceptance_status === "rejected") {
      delete submittedFeedback.rating;
      delete submittedFeedback.feedback_message;
    } else delete submittedFeedback.rejection_message;
    if (
      feedback.acceptance_status === "rejected" &&
      (feedback.rejection_message || "").length < 3
    ) {
      setEmptyMessage(true);
      return;
    }
    // send feedback to server
    Axios.post(`orders/set-skanderborg-feedback-data/${orderId}`, { ...feedback, orderId });
    setFeedback({ ...feedback, acceptance_status: "submitted" });
    // refresh page
    window.location.reload();
  };

  if (feedback.acceptance_status === undefined)
    return (
      <>
        <button
          onClick={() => {
            setFeedback({ ...feedback, acceptance_status: "accepted" });
          }}
          className={style["accepted-button"]}
        >
          Rate
        </button>
        <button
          onClick={() => {
            setFeedback({ ...feedback, acceptance_status: "rejected" });
          }}
          className={style["rejected-button"]}
        >
          Reject
        </button>
      </>
    );
  else if (feedback.acceptance_status !== "submitted") {
    return (
      <div className={style["form-container"]}>
        <div className={style["form-title"]}>
          <span>{feedback.acceptance_status === "rejected" ? "Rejection Reason" : "Rating"}</span>
        </div>
        {feedback.acceptance_status === "rejected" ? (
          <>
            <textarea
              onChange={({ target: { value } }) =>
                setFeedback({ ...feedback, rejection_message: value })
              }
              className={`${style["rejection-message"]} ${
                emptyMessage ? style["message-empty"] : ""
              }`}
              onFocus={() => setEmptyMessage(false)}
            />
            {emptyMessage && (
              <div className={style["error-message"]}>Rejection message must not be empty.</div>
            )}
          </>
        ) : (
          <>
            <textarea
              onChange={({ target: { value } }) =>
                setFeedback({ ...feedback, feedback_message: value })
              }
              className={style["rejection-message"]}
            />
            <StarRating
              value={feedback.rating}
              parentSetRating={(newRating: number) => {
                setFeedback({ ...feedback, rating: newRating });
              }}
              className={style["star-rating"]}
            />
          </>
        )}
        <div className={style["button-container"]}>
          <button onClick={handleSubmit} className={style[`${feedback.acceptance_status}-button`]}>
            Submit
          </button>
          <button
            onClick={() => setFeedback({ ...feedback, acceptance_status: undefined })}
            className={style["back-button"]}
          >
            Back
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h3>Thank you</h3>
      </div>
    );
  }
}

export default Feedback;
