import API from "../common/AxoisClient";
import React from "react";




class StaticContent {

  static instance = new StaticContent();

  languages = [];
  countries = [];
  translation_types = [];
  total_users = [];
  total_orders = [];
  total_setting = [];
  total_payout = [];
  total_learn = [];
  total_newletter = [];
  total_sms = [];
  total_system_info = [];

  /**
   * get language list
   *
   * @returns {Promise<any[]>}
   */
  public async language_list() {
    if(this.languages.length <= 0){
      const languages = await API.get(`language-list`);
      this.languages = languages.data;
    }
    return this.languages;
  }

  /**
   *  get countries list
   *
   * @returns {Promise<any[]>}
   */
  public async country_list() {
    if(this.countries.length <= 0){
      const countries = await API.get(`country-list`);
      this.countries = countries.data.countryCodeArr;
    }
    return this.countries;
  }

  /**
   *  get translation types list
   *
   * @returns {Promise<any[]>}
   */
  public async translation_type_list() {
    if(this.translation_types.length <= 0){
      const data = await API.get(`translation-type-list`);
      this.translation_types = data.data;
    }
    return this.translation_types;
  }
  /**
   *  get total users count with diff type
   *
   * @returns {Promise<any[]>}
   */
  public async get_total_users() {
    if(this.total_users.length <= 0){
      const res = await API.get(`users`);
      this.total_users = res.data;
    }
    return this.total_users;
  }
  /**
   *  get total setting count with diff type
   *
   * @returns {Promise<any[]>}
   */
  public async get_total_setting() {
    if(this.total_setting.length <= 0){
      const res = await API.get(`settings`);
      this.total_setting = res.data;
    }
    return this.total_setting;
  }
  /**
   *  get total payout count with diff type
   *
   * @returns {Promise<any[]>}
   */
  public async get_total_payout() {
    if(this.total_payout.length <= 0){
      const res = await API.get(`payouts`);
      this.total_payout = res.data;
    }
    return this.total_payout;
  }

  /**
   *  get total newletter count with diff type
   *
   * @returns {Promise<any[]>}
   */
  public async get_total_newletter() {
    if(this.total_newletter.length <= 0){
      const res = await API.get(`email`);
      this.total_newletter = res.data;
    }
    return this.total_newletter;
  }
  /**
   *  get total payout count with diff type
   *
   * @returns {Promise<any[]>}
   */
  public async get_total_learn() {
    if(this.total_learn.length <= 0){
      const res = await API.get(`learn`);
      this.total_learn = res.data;
    }
    return this.total_learn;
  }
  /**
   *  get total sms count with diff type
   *
   * @returns {Promise<any[]>}
   */
  public async get_total_sms() {
    if(this.total_sms.length <= 0){
      const res = await API.get(`sms`);
      this.total_sms = res.data;
    }
    return this.total_sms;
  }


  /**
   *  get total system info count with diff type
   *
   * @returns {Promise<any[]>}
   */
  public async get_total_system_info() {
    if(this.total_system_info.length <= 0){
      const res = await API.get(`systemInfo`);
      this.total_system_info = res.data;
    }
    return this.total_system_info;
  }
  /**
   *  get total orders count with diff type
   *
   * @returns {Promise<any[]>}
   */
  public async get_total_orders() {
    if(this.total_orders.length <= 0){
      const res = await API.get(`orders`);
      this.total_orders = res.data;
    }
    return this.total_orders;
  }

}


/**
 * get language list
 *
 * @returns {Promise<any[]>}
 */
export const getLanguageList = async() => {
  return StaticContent.instance.language_list();
};

/**
 * get country list
 *
 * @returns {Promise<any[]>}
 */

export const getCountryList = async() => {
  return StaticContent.instance.country_list();
}
/**
 * get sms list
 *
 * @returns {Promise<any[]>}
 */

export const get_total_sms = async() => {
  return StaticContent.instance.get_total_sms();
}
/**
 * get system info list
 *
 * @returns {Promise<any[]>}
 */

export const get_total_system_info = async() => {
  return StaticContent.instance.get_total_system_info();
}
/**
 * get transaltion type list
 *
 * @returns {Promise<any[]>}
 */
export const getTranslationType = async() => {
  return StaticContent.instance.translation_type_list();
}
/**
 * get total users
 *
 * @returns {Promise<any[]>}
 */
export const get_total_users = async() => {
  return StaticContent.instance.get_total_users();
}

/**
 * get total setting
 *
 * @returns {Promise<any[]>}
 */
export const get_total_setting = async() => {
  return StaticContent.instance.get_total_setting();
}

/**
 * get total payout
 *
 * @returns {Promise<any[]>}
 */
export const get_total_payout = async() => {
  return StaticContent.instance.get_total_payout();
}

/**
 * get total learn
 *
 * @returns {Promise<any[]>}
 */
export const get_total_learn = async() => {
  return StaticContent.instance.get_total_learn();
}


export const get_total_orders = async() => {
  return StaticContent.instance.get_total_orders();
}


export const get_total_newletter = async() => {
  return StaticContent.instance.get_total_newletter();
}

/**
 * upload any file. return file name or callback if used.
 *
 *
 * @param {React.ChangeEvent<HTMLElement>} e
 * @param {(doc: string) => void} callback
 * @returns {Promise<any>}
 */
export const uploadFile = async (e:React.ChangeEvent<HTMLElement>, callback?: (doc:string)=> void ) => {
  const target:any = e.target;
  let formData = new FormData();
  formData.append('document', target.files[0]);
  const doc = await API.post('profile/upload-file', formData);
  if(callback !== undefined){
    return callback(doc.data);
  }
  return doc.data;
};