import * as React from 'react';
import Lang from "../common/lang/Lang";
import {Breadcrumb} from "../common/page/Breadcrumb";

interface LayoutProps {
  breadcrumb: any;
  title: string;
  children: any
  compact?: boolean
}

export const CreateLayout = (props: LayoutProps) => {
  return (
    <div>
      <Breadcrumb data={props.breadcrumb}/>

      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div
          className="kt-portlet kt-portlet--mobile"
          style={ props.compact ? { margin: 'auto', width: '36%' } : undefined }
        >
          <div className="row">
            <div className="col-md-12">

              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label pl-2 pr-2">
                    <h3 className="kt-portlet__head-title">
                      {Lang.getString(props.title)}
                    </h3>
                  </div>
                </div>
                <div className="pl-2 pr-2">
                  {props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}