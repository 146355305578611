import React, {useState,useEffect} from 'react';
import { Modal, ModalProps} from "react-bootstrap";
import Lang from "../../../common/lang/Lang";
import {UserModel} from "../../../model";
import {base_url, showNotification, SubmitButton} from "../../../common";
import API from "../../../common/AxoisClient";
import {AxiosResponse} from "axios";
import {EmailTemplateDataSchema} from "../../../settings/email-templates/Model";
import {UserContractModel} from "../../../model/UserContract";
import * as _ from 'lodash';



export interface ContractModel {
  subject?: string;
  content?: string;
  contractContent?: string;
  translator_id?: number;
  email_template?: EmailTemplateDataSchema;
  contract?: UserContractModel;
}

interface InfoProps extends ModalProps{
  transInfo: UserModel,
  transID?: number
  ContractInfo?: ContractModel
}

export const SuperCustomer = (props: InfoProps) => {
  
    // if(props.TransInfo === undefined)
    //  return null;
  
    const [allUsers, setAllUsers] = useState([]);
    const [CustomerChecked, setCustomerChecked] = useState(false);
    const [PartialChecked, setPartialChecked] = useState(false);
    const [UserMessage, setUserMessage] = useState(false);
    const [selectallIds, setSelectallIds] = useState();
    const [userIds, setUserIds] = useState();
    const [searchuserslist, setSearchusers] = useState([]);
    const [searchVal, setSearchVal] = useState();
    const [SuperCustomerCheck, setSuperCustomerCheck] = useState(0);

    useEffect(() => {
      API.get(`users/customers/SuperCustomer-Userlist/${props.transInfo.id}`).then(response => {
        setAllUsers(response.data); 
      })
     
      API.get(`users/customers/SuperCustomer-check/${props.transInfo.id}`).then(response => {
        setSuperCustomerCheck(response.data[0].is_super_customer); 
        
      })

    },[props.transInfo.id]);

    
   
   

    function searchusers(event:any){
    if(event.target.value == ''){
      setUserMessage(false)
        setSearchusers(event.target.value)
     } else {
      setUserMessage(true) 
        setSearchVal(event.target.value)
      }
      SearchUserlists(event.target.value)
   }
    
    function CustomerUserChecked(event:any){
      if(event.target.checked === true) {
       setSuperCustomerCheck(1)
      CustomerStatusUpdate(event.target.value)
      } else{
        setSuperCustomerCheck(0)
        CustomerStatusUpdate(3)
      } 
     
      
       
      }

    function PartialUserChecked(event:any){
      
      if(event.target.checked === true ) {
        setSuperCustomerCheck(2)
        CustomerStatusUpdate(event.target.value)
       } else{
        setSuperCustomerCheck(0)
        CustomerStatusUpdate(4)
       }  

      
     }

    function SearchUserlists(searchVal){
        let searchChar = $.trim(searchVal);
        let customerLength = searchChar.length;
        if (customerLength >= 3) {
            API.post(`users/customers/SuperCustomer-Searchlist`,{search:searchVal,id:props.transInfo.id,company_id:props.transInfo.company_id}).then(response => {
              setSearchusers(response.data);
            })
        } else {
            setSearchusers([]);
        }
    }

    function handleChange(event:any){
      let value =  event.target.value;
        const name = event.target.name;
      if(name === 'userIds'){
       if(event.target.checked === true) {
      setSelectallIds(event.target.value)
      } else{
      setSelectallIds(event.target.value)
       }
       PartialCustomeradd(event.target.value)
            
      }
    }

    function deleteuser(id:any){
      setUserIds(id)
      UnfavoriteUserdelete(id)
    }

    function PartialCustomeradd(selectallIds){
                
      API.post(`users/customers/PartialCustomer-Useradd`,{id:props.transInfo.id,userIds:selectallIds}).then(response => {
        setAllUsers(response.data); 
        SearchUserlists(searchVal)
      })

      }

      function UnfavoriteUserdelete(userIds){
        API.post(`users/customers/PartialCustomer-delete`,{id:props.transInfo.id,userIds:userIds}).then(response => {
          setAllUsers(response.data);
        })

        }

        function CustomerStatusUpdate(SuperCustomerCheck){
          API.post(`users/customers/SuperCustomer-add`,{id:props.transInfo.id,is_super_customer:SuperCustomerCheck}).then(response => {
            setAllUsers(response.data);
          })

        }

   

  
  return(

    
   
      <Modal
          {...props}
          //size=""
          aria-labelledby="contained-modal-title-vcenter"
      >
        <form >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {Lang.super_customer}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
              <div className={'row'}>
              <div className={"col-md-12 col-lg-12"}>
              
              <div className="form-group">
              
              <div className="input-group">
              
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <label className="kt-checkbox kt-checkbox--single kt-checkbox--success">
                          <input type="checkbox" checked={SuperCustomerCheck === 1} value={1} onChange={(e)=>CustomerUserChecked(e)}/>
                          <span></span>
                        </label>
                      </span>
                      <span className="input-group-text">Super Customer</span>
                    </div>
                    </div>
                    </div>
                  
                 
                    <div className="form-group">
                    <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <label className="kt-checkbox kt-checkbox--single kt-checkbox--success">
                    <input type="checkbox"checked={SuperCustomerCheck === 2} value={2} onChange={(e)=>PartialUserChecked(e)}/>
                    <span></span>
                  </label>
                </span>
                <span className="input-group-text">Partial Customer</span>
              </div>
             
           
              </div>
             </div> 
             
              { SuperCustomerCheck === 2 &&
              <div className="form-group">
              
              <input type="text" placeholder="Please type minimum 3 characters for search"  className="form-control" onChange={(e)=>searchusers(e)} />
              </div>
               }
              <div className="form-group">
              { SuperCustomerCheck === 2 &&
              <div className="kt-checkbox-inline">
               
              {searchuserslist !== null && searchuserslist !== undefined  && searchuserslist.length>0 && 
              searchuserslist.map((item,i) => {
             return <label key={item.id} className="kt-checkbox">
              <input type ="Checkbox"  name={'userIds'} onChange={(e)=>handleChange(e)}  value={item.id} 
                />{item.name}
              <span></span>
              </label>
              })
              }
              </div>
             }
              {UserMessage === true &&  searchuserslist.length === 0  && SuperCustomerCheck === 2 &&
                       <div className="alert alert-outline-warning fade show" >Customer not found</div> 
                        }
               
                </div> 
                
              
              
                </div>
               
                 </div>
            </div> 
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            { allUsers.length > 0  && SuperCustomerCheck === 2 &&
                <table className="table table-bordered table-hover">
                            
                <thead className="kt-datatable__head"> 
                <tr className="kt-datatable__row">
                  <th className="kt-datatable__cell kt-datatable__cell--sort kt-datatable__cell--sorted">Name</th>
                  <th className="kt-datatable__cell kt-datatable__cell--sort kt-datatable__cell--sorted">Email</th>
                 <th className="kt-datatable__cell kt-datatable__cell--sort kt-datatable__cell--sorted">Action</th>
                </tr>
                </thead>
                <tbody>
                {allUsers !== null  && allUsers !== undefined && allUsers.length>0 &&
                allUsers.map((item,i) => {
                  return (
                    <tr>
                      <td>{item.super_customer.name}</td>
                      <td>{item.super_customer.email}</td>
                      <button type="button" onClick={(e)=>deleteuser(item.super_customer.id)}  className="btn btn-sm btn-danger"><i className="la la-trash"></i></button>
                    </tr>
                  )
                })
              }
               </tbody>
                </table>
             }
            
             </div>
            
          </Modal.Body>
         
        </form>
      </Modal>
  )
};