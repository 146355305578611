
export const TranslatorValidations = function () {

  $(document).ready(function () {
    $.validator.addMethod(
        "regex",
        function(value, element, regexp) {
            var check = false;
            return this.optional(element) || regexp.test(value);
        },
        "Invalid format."
    );

    $( "#create_translator" ).validate({
      rules: {
        name: {
          required: true
        },
        title: {
          maxlength: 30
        },
        email: {
          required: true,
          email: true
        },
        address: {
          required: true
        },
        city: {
          required: true
        },
        zip_code: {
          required: true
        },
        select_location: {
          required: true
        },
        country_code: {
          required: true
        },
        mobile_no: {
          required: true,
          number: true
        },
        driving_license: {
          required: true
        },
        is_certified: {
          required: true
        },
        children_certificate: {
          required: function () {
            return ($("#is_certified").val() == 1 && $('#checkCertificate').val() == '')
          }
        },
        gender: {
          required: true
        },
        origin_country: {
          required: true
        },
        exp_scale: {
          required: true
        },
        account_holder_name: {
          required: true
        },
        bank_name: {
          required: true
        },
        income: {
          required:'#agreed_income:checked'
        },
        how_many_km: {
          required:'#is_km_pay:checked'
        },
        how_much_pay: {
          required:'#is_km_pay:checked',
          pattern: /^\d+(\.\d{1,2})?$/
        },
        hourly_pay: {
          required:'#is_transport_pay:checked'
        },
        physical_rate: {
          required: true,
          pattern: /^\d+(\.\d{1,2})?$/
        },
        telephone_rate: {
          required: true,
          pattern: /^\d+(\.\d{1,2})?$/
        },
        video_rate: {
          required: true,
          pattern: /^\d+(\.\d{1,2})?$/
        },
        own_vehicle: {
          required: function () { 
           return $("#driving_license").val() == 1
          }
        },
        police_verified: {
          required: function () {
            return $("#driving_license").val() == 1 && $("#own_vehicle").val() == 1
          }
        },
        verification_number: {
          required: function () {
            return $("#police_verified").val() == 1
          }
        },
        translator_type: {
          required: true
        },
        is_vat_registered: {
          required: function () {
            return $("#translator_type").val() == 1
          }
        },
        cvr_number: {
          required: function () {
            return $("#is_vat_registered").val() == 1
          },
          minlength: 8,
          maxlength: 8,
          number: true
        },
        company_name: {
          required: function () {
            return $("#is_vat_registered").val() == 1
          },
        },
        company_address: {
          required: function () {
            return $("#is_vat_registered").val() == 1
          },
        },
        social_no: {
          required: function () {
            return $("#is_vat_registered").val() == 1 || ($("#translator_type").val() == 2 && ($('#fee_type').val()==1 || $('#fee_type').val()==2))
          }, 
          minlength: 10,
          maxlength: 10,
          number: true
        },
        bank_registration_no: {
          required: function () {
            return $("#is_vat_registered").val() == 1 || ($("#translator_type").val() == 2 && ($('#fee_type').val()==1 || $('#fee_type').val()==2))
          }, 
          minlength: 4,
          maxlength: 4,
          number: true
        },
        account_no: {
           required: function () {
            return $("#is_vat_registered").val() == 1 || ($("#translator_type").val() == 2 && ($('#fee_type').val()==1 || $('#fee_type').val()==2))
          },
        },
        vat_number: {
          required: function () {
            return $("#is_vat_registered").val() == 0
          }
        },
        iban: {
          required: function () {
            return $("#is_vat_registered").val() == 0 || ($("#translator_type").val() == 2 && ($('#fee_type').val()==3))
          }
        },
        swift: {
          required: function () {
            return $("#is_vat_registered").val() == 0 || ($("#translator_type").val() == 2 && ($('#fee_type').val()==3))
          }
        },
        tax_country: {
          required: function () {
            return $("#is_vat_registered").val() == 0
          }
        },
        fee_type: {
          required: function () {
            return $("#translator_type").val() == 2
          }
        },
        primary_income: {
          required: function () {
            return $("#fee_type").val() == 2
          }
        },
        date_of_birth: {
          required: function () {
            return $("#fee_type").val() == 3
          }
        },
        physical_fix_rate: {
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        physical_fix_minute: {
          number: true
        },
        physical_sub_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        physical_sub_minute:{
          number: true
        },
        telephone_fix_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        telephone_fix_minute:{
          number: true
        },
        telephone_sub_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        telephone_sub_minute:{
          number: true
        },
        video_fix_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        video_fix_minute:{
          number: true
        },
        video_sub_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        video_sub_minute:{
          number: true
        },
        tel_message_fix_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        min_word:{
          number: true
        },
        per_word_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        compensation_agreed: {
          required: true
        },
        min_km:{
          number: true
        },
        per_km_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        transport_fee_agreed: {
          required: true
        },
        transport_fee_fix_rate: {
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        transport_fee_minute:{
          number: true
        },
        transport_fee_sub_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        transport_fee_sub_minute:{
          number: true
        },
        holiday_charge_agreed: {
          required: true
        },
        extra_holiday_percentage: {
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        }
      },

      invalidHandler: function(event, validator) {
        var alert = $('#kt_form_1_msg');
        alert.removeClass('kt--hide').show();
      },
    });
  });
}

export const LeaveFromValidations = function () {
  $(document).ready(function () {
    $( "#leave_info_form" ).validate({
      rules: {
        leave_type: {
          required: true
        },
        from_date: {
          required: true
        },
        to_date: {
          required: {
            depends: function(element:any){
              if($('#multiple_days').val()==3){
                return true;
              } else {
                return false;
              }
            }
          },
        },
        type_of_half_day: {
          required: {
            depends: function(element:any){
              if($('#half_day').val()==2){
                return true;
              } else {
                return false;
              }
            }
          }
        },
        reason: {
          required: true
        }
      },
    });

  });
}

export const TranslatorSpecialRateValidations = function() {
   $(document).ready(function () {

    $.validator.addMethod(
        "regex",
        function(value, element, regexp) {
            var check = false;
            return this.optional(element) || regexp.test(value);
        },
        "Invalid format."
    );
     
    $( "#translator_special_rate" ).validate({
      rules: {
        company_id: {
          required: function(element) {
            return $("#special_domain").val()=='';
          }
        },
        special_domain: {
          required: function(element) {
            return $("#company_id").val()=='';
          }
        },
        special_physical_fix_rate: {
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        special_physical_fix_minute: {
          number: true
        },
        special_physical_sub_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        special_physical_sub_minute:{
          number: true
        },
        special_telephone_fix_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        special_telephone_fix_minute:{
          number: true
        },
        special_telephone_sub_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        special_telephone_sub_minute:{
          number: true
        },
        special_video_fix_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        special_video_fix_minute:{
          number: true
        },
        special_video_sub_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        special_video_sub_minute:{
          number: true
        },
        special_tel_message_fix_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        special_min_word:{
          number: true
        },
        special_per_word_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        special_compensation_agreed: {
          required: true
        },
        special_min_km:{
          number: true
        },
        special_per_km_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        special_transport_fee_agreed: {
          required: true
        },
        special_transport_fee_fix_rate: {
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        special_transport_fee_minute:{
          number: true
        },
        special_transport_fee_sub_rate:{
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
        special_transport_fee_sub_minute:{
          number: true
        },
        special_holiday_charge_agreed: {
          required: true
        },
        special_extra_holiday_percentage: {
          regex: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        }
      },
      invalidHandler: function(event, validator) {
        var alert = $('#kt_form_1_msg');
        alert.removeClass('kt--hide').show();
      }
    });
  }); 
}
