import * as React from 'react';
import {SearchInput} from "../../common/form/SearchInput";
import {IState as CommonState,} from "../../common/State";
import {ShowRecords} from "../../common/ShowRecords";
import { GeneratePermitLink
} from "../../common";
import {CONST, ExportButton, getQueryStringParams} from "../../common/Common";
import {Icons} from "../../common/Icon";
import {actionButton} from "../../common/table/BlukActionButtonsEmailSms";
import {RouteComponentProps} from "react-router";
import {SystemEmailModel} from "../../model/SystemEmail";
import {ListBreadcrumb} from './Data';
import {UserModel} from "../../model";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {default as Lang} from "../../common/lang/Lang";
 import {CustomerInfo} from "../../users/customers/CustomerInfo";
import {TranslatorInfo} from "../../users/translators/TranslatorInfo";
import {OrderInfo} from "../../orders/orders/OrderInfo";
import {OrderModel} from "../../model";
import SMSSystemFilter from './filter/SMSSystemFilter';
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";



interface IState extends CommonState{
  UserData: Array<SystemEmailModel>;
  CustomerModalData?: UserModel;
  ModalData?: UserModel;
  Customershow?: boolean;
  Translatorshow?: boolean;
  Ordershow?: boolean;
  OrderData?: OrderModel;
 
}


export class SystemSMSListing extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }


  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }


  UserByRole = (data) => {
    if(data.users !== null && data.users.role_id === 2 ) {
       return <GeneratePermitLink scope={'customers.index'} to={'#'} onClick={() => this.setState({CustomerModalData: data.users, Customershow: true})}>
          {( data.users !== null ) ? data.users.name : '--'}
        </GeneratePermitLink>
    } else {
        return  <GeneratePermitLink scope={'translator.index'} to={'#'} onClick={() => this.setState({ModalData: data.users, Translatorshow: true})} title={Lang.translator_info}>
          {( data.users !== null ) ? data.users.name : '--'}
           </GeneratePermitLink>
    }
  }

   

    showContent = (data: SystemEmailModel, index: number) => {
       
        let cols = [];
        cols.push(...[
            /*<td key={index}>{this.records.current.checkbox(data.id)}</td>,*/
            this.records.current.createdTd("id", index),
           
           
            this.records.current.createdTd("order_id", () =>{
              return<GeneratePermitLink scope={'customers.index'}  to={'#'}  data-skin={'dark'} onClick={() => this.setState({OrderData: data.order, Ordershow: true})}>
                       { (data.order !== null ) ? data.order.order_no : '--'}
                  </GeneratePermitLink>
                
              }),
              this.records.current.createdTd("name", () =>{
                return (
                  this.UserByRole(data)
                 )  
                
                }),
              
            this.records.current.createdTd("content", data.content),
           this.records.current.createdTd("created_at", data.created_at),
         ]);
        cols = _.compact(cols);

        return (
            <tr key={list_key(data.id)}>{cols}</tr>
        );
    
  }





  render() {
   
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'system_sms'} icon={Icons.sms} >
            <ExportButton records={this.records} fileName={Lang.exportSystemSms}/>
          </ListHeader>

          <ListForm
            form={(
              <SMSSystemFilter getResults={this.filterRecords} />
          )}

            table={(

              <ShowRecords
                  url={"sms/system"}
                  showRecords={this.showContent}
                  ref={this.records}
                  showCheckbox={false}
                  isNeedAction={true}
                  actionButtons={actionButton({records: this.records, 'url': "email/system/bulk-action"})}
              />
          )}
          />
           {
          this.state !== null &&
          <CustomerInfo
          show={this.state.Customershow}
          onHide={() => this.setState({Customershow: false})}
          userinfo={this.state.CustomerModalData}
        />
           }
            {
          this.state !== null &&
        <TranslatorInfo
          show={this.state.Translatorshow}
          onHide={() => this.setState({Translatorshow: false})}
          UserInfo={this.state.ModalData}
        />
            }
             {
          this.state !== null &&
         <OrderInfo
              show={this.state.Ordershow}
              onHide={() => this.setState({Ordershow: false})}
              orderinfo={this.state.OrderData}/>
             }
        </ListingLayout>
    )
  }
}