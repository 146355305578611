import React, {useState, useEffect} from 'react';
import {OrderModel, OrderType} from "../model";
import {Icons, LoaderType, RequestWaiting, SetWaitingArea, SwitchLoader} from "../common";
import {order_to_language} from "../helpers/OrderHelper";
import {DashboardService} from "../services";
import {OrderInfo} from "../orders/orders/OrderInfo";
import Lang from "../common/lang/Lang";
import _ from "lodash";
const moment = require("moment");
declare let window:any;

export function RecentOrders(){

  const [orders, setOrders] = useState([]);
  const [completeOrders, setCompleteOrders] = useState([]);
  const [cancelOrders, setCancelOrders] = useState([]);
  const [orderPopup, setOrderPopup] = useState(false);
  const [orderInfo, setOrderInfo] = useState({} as OrderModel);

  useEffect(() => {
    getRecentOrders();
  }, []);

  function getRecentOrders() {
    SwitchLoader(LoaderType.NONE);
    SetWaitingArea('orders');
    DashboardService.getRecentOrders().then(res => {
      setOrders(res.recent);
      setCompleteOrders(res.completed);
      setCancelOrders(res.cancelled);
    })
  }

  function showOrderModel(info: OrderModel) {
    setOrderPopup(true);    
    setOrderInfo(info);
  }
  

  return (
    <div className="kt-portlet kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            {Lang.recent_orders}
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar">
          <ul className="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#kt_widget5_tab1_content" role="tab">
                {Lang.order_new_order}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#kt_widget5_tab2_content" role="tab">
                {Lang.order_complete}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#kt_widget5_tab3_content" role="tab">
                {Lang.order_cancelled}
              </a>
            </li>
            <li className="nav-item">
              <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" onClick={getRecentOrders}>
                <i className={Icons.refresh} title={Lang.refresh}  data-toggle="kt-tooltip" data-placement="top" data-original-title={Lang.refresh} />
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="tab-content">
          <div className="tab-pane active" id="kt_widget5_tab1_content" aria-expanded="true">
            <div className="kt-widget5">
              <RequestWaiting item={orders} from={'orders'} >
                {orders.length > 0 && orders.map((item: OrderModel) => {
                  return <OrderList item={item} showOrder={(info) => showOrderModel(info)} key={item.id} />
                })}
              </RequestWaiting>
            </div>
          </div>
          <div className="tab-pane" id="kt_widget5_tab2_content">
            <div className="kt-widget5">
              <RequestWaiting item={completeOrders} from={'orders'}>
                {completeOrders.length > 0 && completeOrders.map((item: OrderModel) => {
                  return <OrderList item={item} showOrder={(info) => showOrderModel(info)} key={item.id}/>
                })}
              </RequestWaiting>
            </div>
          </div>
          <div className="tab-pane" id="kt_widget5_tab3_content">
            <div className="kt-widget5">
              <RequestWaiting item={cancelOrders} from={'orders'}>
                {cancelOrders.length > 0 && cancelOrders.map((item: OrderModel) => {
                  return <OrderList item={item} showOrder={(info) => showOrderModel(info)} key={item.id}/>
                })}
              </RequestWaiting>
            </div>
          </div>
        </div>
      </div>

      {
        orderPopup &&
        <OrderInfo
          show={orderPopup}
          onHide={() => setOrderPopup(false)}
          orderinfo={orderInfo}
        />
      }
</div>
  );
}


const OrderList = (props:{item: OrderModel, showOrder(info): void}) => {
  return (
    <div className="kt-widget5__item">
      <div className="kt-widget5__content">
        <div className="kt-widget5__pic">
          {
            props.item.translation_type !== null?
              <img className="kt-widget7__img" src={props.item.translation_type.small_icon} alt={props.item.translation_type.title}/> : <img className="kt-widget7__img" src='' alt=''/>

          }
        </div>
        <div className="kt-widget5__section">
          <span style={{cursor: 'pointer'}} onClick={() => {props.showOrder(props.item)}} className="kt-widget5__title">
            #{props.item.order_no}
          </span>
          <p className="kt-widget5__desc">
          <img src={window.PUBLIC_URL +'/admin/assets/flags/'+_.lowerCase(props.item.language_from.country.iso) +'.png'} className="img-flag" />&nbsp;{props.item.language_from.language_name}<span className="exchangeIcon"><i className="fas fa-exchange-alt"></i></span>
          {(props.item.is_dumb === 0 && props.item.language_to !== null) &&
           <img src={window.PUBLIC_URL +'/admin/assets/flags/'+_.lowerCase(props.item.language_to.country.iso) +'.png'} className="img-flag" />
          }
          &nbsp;{order_to_language(props.item)}
           </p>
          <div className="kt-widget5__info">
            <span>{Lang.customer}: </span>
            <span className="kt-font-info"> {!_.isEmpty(props.item.customer) &&  props.item.customer.name}</span>
            <span>{Lang.date}: </span>
            <span className="kt-font-info"> {moment(moment(props.item.order_date)).format('D-M-YYYY')}</span>
          </div>
        </div>
      </div>
      <div className="kt-widget5__content">
        {
          props.item.translation_type_id !== OrderType.DOCUMENT &&
          <div className="kt-widget5__stats">
            <span className="kt-widget5__number">{props.item.order_from}</span>
            <span className="kt-widget5__sales">{Lang.from_time}</span>
          </div>
        }

        <div className="kt-widget5__stats">
          <span className="kt-widget5__number">{props.item.order_to}</span>
          <span className="kt-widget5__votes">{Lang.to_time}</span>
        </div>
      </div>
    </div>
  )
}