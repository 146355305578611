export interface DataSchema {
  id?: number;
  name: string;
  on_date: string;
}

export interface FormFields  {
  name: any;
  on_date: any;
}


export const FormDefaultVal = {
  name: '',
  on_date: '',
}

export const Form: Array<FormFields> = [
  {name: "name", on_date: "on_date"},
];

export const RequiredFields = {
  'name' : 'Name',
  'on_date': 'On Date'
}