import React, {useEffect, useRef} from "react";
import 'dropzone/dist/dropzone.css'
import _ from "lodash";
import {base_url, Error422Custom, showNotification} from "../../../common";
import {Auth} from "../../../Auth/Auth";
import Dropzone from 'dropzone';

declare let window: any;


export const Document = (props: any) => {
  const ele = useRef(null);

  useEffect(() => {
    let $el = ele.current;
    if($el !== null){
      let $els = $($el);
      $els.empty();
      let myDropzone = new Dropzone($el, {

        thumbnailHeight: 120,
        thumbnailWidth: 120,
        addRemoveLinks: true,
        url: base_url + "api/customer/v1/profile/upload-file",
        autoProcessQueue: true,
        uploadMultiple: true,
        paramName: 'document',
        acceptedFiles:"image/*,application/pdf,.doc,.docx,.excel",
        //previewTemplate: document.querySelector('#template-container').innerHTML,
        params: function() {
          return {"document_name" : "document"}
        },
        headers: {
          Authorization : `Bearer ${Auth.token}`,
          From: 'web'
        },
        successmultiple: function (file:any, res: any) {
          props.addDoc(res.filename, props.index);
        },
        error: function (file, res) {
          myDropzone.removeFile(file);
          if(!_.isEmpty(res.errors)) {
            Error422Custom(res);
          } else {
            showNotification('danger', "Invalid file");
          }
        }
      });
      myDropzone.on("removedfile", function(file : any) {
        props.remove(props.index, file.name)

      });
    }
  }, []);

  return(
      <div className={'row'}>
        <div id={'drop_zone'} className={'dropzone'} ref={ele} style={{width:"100%"}}/>
      </div>
  )
}