import React from 'react';
import { floor } from 'lodash';
import { Icons } from '../../../../../../../common';

// style
import sheet from './Stars.module.scss';

interface Props {
  count: number
  cap: number
  reviews: number
}

const Stars = ({ count, cap, reviews }: Props) => {
  const half_star = count % 1 !== 0
  return (
    <div className={sheet.container}>
      {
        reviews === 0 ? <span>No reviews</span> :
        (
          <div className={sheet.stars_container}>
            <div>
              {
                Array(cap).fill(null).map((item, index) => (
                  index + 1 <= floor(count) ? <Star key={index}/> :
                  (index + 1 === floor(count) + 1 && half_star) ?
                  <HalfStar key={index}/> : <EmptyStar key={index}/> ))
                }
              <span>{count}</span>
            </div>
            <div className={sheet.ratings_number}>
              <span>{reviews} ratings</span>
            </div>
          </div>
        )
      }
    </div>
  )
};

const Star = ({key}: {key: any}) => <i key={key} className={Icons.star}></i>
const HalfStar = ({key}: {key: any}) => <i key={key} className={Icons.star_half}></i>
const EmptyStar = ({key}: {key: any}) => <i key={key} className={Icons.star_empty}></i>

export default Stars;
