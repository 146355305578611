import * as React from "react";
import API from "../../common/AxoisClient";
import { showNotification } from "../../common/Common";
import Lang from "../../common/lang/Lang";
import { RouteComponentProps } from "react-router";
import { Breadcrumb } from "../../common/page/Breadcrumb";
import { RatesBreadcrumb } from "./Data";
import { CompanyModel, TranslatorSpecialRatesModel } from "../../model";
import {
  ratesAddService,
  ratesUpdateService,
  getTranslatorRate,
  ratelistService,
  rateActions,
} from "../../services";
import { RatesUpdate } from "./popup/RatesUpdate";
import { TranslatorValidations } from "./Validations";
import { RatesTranslatorState } from "../../model/Translator";
import { getMapContent, GooglePlace } from "../../common/GooglePlace";
import { CountryFlags, Select2Wrapper } from "../../common/Select2";
import {
  CommonSubmitButton,
  Icons,
  Select,
  SwitchLoader,
  GeneratePermitLink,
  PleaseWait,
  NoRecords,
  showConfirmBox,
} from "../../common";
import {
  convert_comma_to_dot,
  convert_dot_to_comma,
  convert_to_decimal_format,
} from "../../helpers/UserHelper";
import { DateofBirthInput } from "../../common";
import { LoaderType } from "../../common/Loader";
import * as _ from "lodash";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";

declare var window: any;

let moment = require("moment");

export class Rates extends React.Component<
  RouteComponentProps,
  RatesTranslatorState
> {
  state: RatesTranslatorState;
  constructor(props: any) {
    super(props);
    this.state = {
      id: props.match.params.id,
      is_loading: true,
      rates: [],
      physical_fix_minute: 30,
      physical_sub_minute: 15,
      telephone_fix_minute: 15,
      telephone_sub_minute: 15,
      video_fix_minute: 15,
      video_sub_minute: 15,
    };
    TranslatorValidations();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event: React.ChangeEvent<any>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    } as any);
  };

  handleBlur = (event: React.ChangeEvent<any>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (
      name == "physical_fix_rate" ||
      name == "physical_sub_rate" ||
      name == "telephone_fix_rate" ||
      name == "telephone_sub_rate" ||
      name == "video_fix_rate" ||
      name == "video_sub_rate" ||
      name == "tel_message_fix_rate" ||
      name == "per_word_rate" ||
      name == "per_km_rate" ||
      name == "transport_fee_fix_rate" ||
      name == "transport_fee_sub_rate" ||
      name == "extra_holiday_percentage"
    ) {
      if (name == "telephone_fix_rate") {
        this.setState({
          tel_message_fix_rate: convert_to_decimal_format(value),
          telephone_fix_rate: convert_to_decimal_format(value),
        });
      } else {
        this.setState({
          [name]: convert_to_decimal_format(value),
        } as any);
      }
    } else {
      this.setState({
        [name]: value,
      } as any);
    }
  };

  getTranslatorData = () => {
    API.get(`users/translators/${this.state.id}`).then((res) => {
      const translatorData = res.data.translatorArr.translator;
      const userData = res.data.translatorArr;
      // Formulas gotten from (2da48e0) TranslatorController lines 1037

      let {
        /* PHYSCIAL */
        physical_fix_minute: PFM, // PFM
        physical_sub_minute: PSM, // PSM
        physical_fix_rate: PFR, // PFR
        physical_sub_rate: PSR, // PSR
        /* TELEPHONE */
        telephone_fix_minute: TFM, // TFM
        telephone_sub_minute: TSM, // TSM
        telephone_fix_rate: TFR, // TFR
        telephone_sub_rate: TSR, // TSR
        /* VIDEO */
        video_fix_minute: VFM, // VFM
        video_sub_minute: VSM, // VSM
        video_fix_rate: VFR, // VFR
        video_sub_rate: VSR, // VSR
      } = translatorData;

      /* PHYSICAL */
      PFM = Number(PFM ?? 30);
      PSM = Number(PSM ?? 15);
      PFR = Number(PFR ?? 0);
      PSR = Number(PSR ?? 0);
      /* TELEPHONE */
      TFM = Number(TFM ?? 15);
      TSM = Number(TSM ?? 15);
      TFR = Number(TFR ?? 0);
      TSR = Number(TSR ?? 0);
      /* VIDEO */
      VFM = Number(VFM ?? 15);
      VSM = Number(VSM ?? 15);
      VFR = Number(VFR ?? 0);
      VSR = Number(VSR ?? 0);

      const X1 = Math.floor(PFM / PSM);
      const X2 = Math.floor(TFM / TSM);
      const X3 = Math.floor(VFM / VSM);

      this.setState({
        physical_fix_rate: convert_dot_to_comma(String(PFR)),
        physical_fix_minute: PFM ? PFM : 30,
        physical_sub_rate: convert_dot_to_comma(String(PSR)),
        physical_sub_minute: PSM ? PSM : 15,
        telephone_fix_rate: convert_dot_to_comma(String(TFR)),
        telephone_fix_minute: TFM ? TFM : 15,
        telephone_sub_rate: convert_dot_to_comma(String(TSR)),
        telephone_sub_minute: TSM ? TSM : 15,
        video_fix_rate: convert_dot_to_comma(String(VFR)),
        video_fix_minute: VFM ? VFM : 15,
        video_sub_rate: convert_dot_to_comma(String(VSR)),
        video_sub_minute: VSM ? VSM : 15,
        trans_rates: {
          physical: PFR + X1 * PSR,
          telephone: TFR + X2 * TSR,
          video: VFR + X3 * VSR,
        },
        tel_message_fix_rate: convert_dot_to_comma(
          translatorData.tel_message_fix_rate
        ),
        min_word: translatorData.min_word,
        per_word_rate: convert_dot_to_comma(translatorData.per_word_rate),
        compensation_agreed: translatorData.compensation_agreed,
        min_km: translatorData.min_km,
        per_km_rate: convert_dot_to_comma(translatorData.per_km_rate),
        transport_fee_agreed: translatorData.transport_fee_agreed,
        transport_fee_fix_rate: convert_dot_to_comma(
          translatorData.transport_fee_fix_rate
        ),
        transport_fee_minute: translatorData.transport_fee_minute,
        transport_fee_sub_rate: convert_dot_to_comma(
          translatorData.transport_fee_sub_rate
        ),
        transport_fee_sub_minute: translatorData.transport_fee_sub_minute,
        holiday_charge_agreed: translatorData.holiday_charge_agreed,
        extra_holiday_percentage: convert_dot_to_comma(
          translatorData.extra_holiday_percentage
        ),
      });
    });
  };

  componentDidMount() {
    const { id }: any = this.props.match.params;
    getTranslatorRate(id).then((res) => {
      this.setState({
        rates: res.translator.translator_rate,
        is_loading: false,
      });
    });

    this.getTranslatorData();

    ratelistService.getPopupState().subscribe((res: any) => {
      getTranslatorRate(id).then((res) => {
        this.setState({
          rates: res.translator.translator_rate,
          is_loading: false,
        });
      });
    });
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if ($("#create_translator").valid()) {
      let paramArr = {
        id: this.state.id,
        physical_fix_rate: convert_comma_to_dot(this.state.physical_fix_rate),
        physical_fix_minute: this.state.physical_fix_minute,
        physical_sub_rate: convert_comma_to_dot(this.state.physical_sub_rate),
        physical_sub_minute: this.state.physical_sub_minute,
        telephone_fix_rate: convert_comma_to_dot(this.state.telephone_fix_rate),
        telephone_fix_minute: this.state.telephone_fix_minute,
        telephone_sub_rate: convert_comma_to_dot(this.state.telephone_sub_rate),
        telephone_sub_minute: this.state.telephone_sub_minute,
        video_fix_rate: convert_comma_to_dot(this.state.video_fix_rate),
        video_fix_minute: this.state.video_fix_minute,
        video_sub_rate: convert_comma_to_dot(this.state.video_sub_rate),
        video_sub_minute: this.state.video_sub_minute,
        tel_message_fix_rate: convert_comma_to_dot(
          this.state.tel_message_fix_rate
        ),
        min_word: this.state.min_word,
        per_word_rate: convert_comma_to_dot(this.state.per_word_rate),
        compensation_agreed: this.state.compensation_agreed,
        min_km: this.state.min_km,
        per_km_rate: convert_comma_to_dot(this.state.per_km_rate),
        transport_fee_agreed: this.state.transport_fee_agreed,
        transport_fee_fix_rate: convert_comma_to_dot(
          this.state.transport_fee_fix_rate
        ),
        transport_fee_minute: this.state.transport_fee_minute,
        transport_fee_sub_rate: convert_comma_to_dot(
          this.state.transport_fee_sub_rate
        ),
        transport_fee_sub_minute: this.state.transport_fee_sub_minute,
        holiday_charge_agreed: this.state.holiday_charge_agreed,
        extra_holiday_percentage: convert_comma_to_dot(
          this.state.extra_holiday_percentage
        ),
      };
      API.put(`users/translators/${this.state.id}`, paramArr).then((res) => {
        this.getTranslatorData();
        showNotification("success", Lang.rate_updated);
      });
    }
  };

  handleSelectChange = (event: React.ChangeEvent<HTMLElement>) => {
    const targetSelect: any = event.target;
    const value = targetSelect.value;
  };

  formDataReset = (e: any) => {
    e.preventDefault();
    this.setState({
      physical_fix_rate: 0,
      physical_fix_minute: 0,
      physical_sub_rate: 0,
      physical_sub_minute: 0,
      telephone_fix_rate: 0,
      telephone_fix_minute: 0,
      telephone_sub_rate: 0,
      telephone_sub_minute: 0,
      video_fix_rate: 0,
      video_fix_minute: 0,
      video_sub_rate: 0,
      video_sub_minute: 0,
      tel_message_fix_rate: 0,
      min_word: 0,
      per_word_rate: 0,
      compensation_agreed: 0,
      min_km: 0,
      per_km_rate: 0,
      transport_fee_agreed: 0,
      transport_fee_fix_rate: 0,
      transport_fee_minute: 0,
      transport_fee_sub_rate: 0,
      transport_fee_sub_minute: 0,
      holiday_charge_agreed: 0,
      extra_holiday_percentage: 0,
    });
  };

  updateStatus = (status, id) => {
    const ___this = this;
    const msg = "Are you sure that you want to delete this rate?";
    showConfirmBox(msg, function () {
      rateActions({ status: status, id: id }).then((res) => {
        showNotification("success", res);
        getTranslatorRate(___this.state.id).then((res) => {
          ___this.setState({
            rates: res.translator.translator_rate,
            is_loading: false,
          });
        });
      });
    });
  };

  render() {
    const updated_X1 = Math.ceil(
        (60 - this.state.physical_fix_minute) / this.state.physical_sub_minute
      ),
      updated_X2 = Math.ceil(
        (60 - this.state.telephone_fix_minute) / this.state.telephone_sub_minute
      ),
      updated_X3 = Math.ceil(
        (60 - this.state.video_fix_minute) / this.state.video_sub_minute
      ),
      updated_physical_rate =
        Number(String(this.state.physical_fix_rate).replace(",", ".")) +
        updated_X1 *
          Number(String(this.state.physical_sub_rate).replace(",", ".")),
      updated_telephone_rate =
        Number(String(this.state.telephone_fix_rate).replace(",", ".")) +
        updated_X2 *
          Number(String(this.state.telephone_sub_rate).replace(",", ".")),
      updated_video_rate =
        Number(String(this.state.video_fix_rate).replace(",", ".")) +
        updated_X3 *
          Number(String(this.state.video_sub_rate).replace(",", ".")),
      physical_rate_was_updated =
        this.state.trans_rates?.physical !== updated_physical_rate,
      telephone_rate_was_updated =
        this.state.trans_rates?.telephone !== updated_telephone_rate,
      video_rate_was_updated =
        this.state.trans_rates?.video !== updated_video_rate;

    const { id }: any = this.props.match.params;
    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <Breadcrumb data={RatesBreadcrumb} />
        <div className="kt-portlet kt-portlet--mobile">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">{Lang.rates}</h3>
                  </div>
                </div>
                <br />
                <form
                  className="kt-form"
                  onSubmit={this.handleSubmit}
                  id={"create_translator"}
                >
                  <div className="kt-portlet__body">
                    <div className={"row"}>
                      <div className={"col-md-12 col-lg-12"}>
                        <h4>A. Generelt</h4>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className={"col-md-12 col-lg-12"}>
                        <strong>
                          Fremmøde-tolkning&emsp;
                          <span className="trans_rate">
                            <span
                              className={
                                physical_rate_was_updated &&
                                "trans_rate_changed"
                              }
                            >
                              {this.state.trans_rates?.physical
                                ? `${this.state.trans_rates.physical} Kr.`
                                : "--Kr."}
                            </span>
                            {physical_rate_was_updated &&
                              ` → ${updated_physical_rate}Kr.`}
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.rate_minute} </label>
                              <Select
                                id="physical_fix_minute"
                                value={this.state.physical_fix_minute}
                                name="physical_fix_minute"
                                onChange={this.handleChange}
                                className="form-control"
                              >
                                <option value="">{Lang.select}</option>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                                <option value={30}>30</option>
                                <option value={35}>35</option>
                                <option value={40}>40</option>
                                <option value={45}>45</option>
                                <option value={50}>50</option>
                                <option value={55}>55</option>
                                <option value={60}>60</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.fix_rate} </label>
                              <input
                                type="text"
                                className="form-control"
                                id="physical_fix_rate"
                                value={this.state.physical_fix_rate}
                                name="physical_fix_rate"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                placeholder={Lang.getString(`fix_rate`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.rate_minute} </label>
                              <Select
                                id="physical_sub_minute"
                                value={this.state.physical_sub_minute}
                                name="physical_sub_minute"
                                onChange={this.handleChange}
                                className="form-control"
                              >
                                <option value="">{Lang.select}</option>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                                <option value={30}>30</option>
                                <option value={35}>35</option>
                                <option value={40}>40</option>
                                <option value={45}>45</option>
                                <option value={50}>50</option>
                                <option value={55}>55</option>
                                <option value={60}>60</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.sub_rate} </label>
                              <input
                                type="text"
                                className="form-control"
                                id="physical_sub_rate"
                                value={this.state.physical_sub_rate}
                                name="physical_sub_rate"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                placeholder={Lang.getString(`sub_rate`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className={"col-md-12 col-lg-12"}>
                        <strong>
                          Telefon-tolkning&emsp;
                          <span className="trans_rate">
                            <span
                              className={
                                telephone_rate_was_updated &&
                                "trans_rate_changed"
                              }
                            >
                              {this.state.trans_rates?.telephone
                                ? `${this.state.trans_rates.telephone} Kr.`
                                : "--Kr."}
                            </span>
                            {telephone_rate_was_updated &&
                              ` → ${updated_telephone_rate}Kr.`}
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.rate_minute} </label>
                              <Select
                                id="telephone_fix_minute"
                                value={this.state.telephone_fix_minute}
                                name="telephone_fix_minute"
                                onChange={this.handleChange}
                                className="form-control"
                              >
                                <option value="">{Lang.select}</option>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                                <option value={30}>30</option>
                                <option value={35}>35</option>
                                <option value={40}>40</option>
                                <option value={45}>45</option>
                                <option value={50}>50</option>
                                <option value={55}>55</option>
                                <option value={60}>60</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.fix_rate} </label>
                              <input
                                type="text"
                                className="form-control"
                                id="telephone_fix_rate"
                                value={this.state.telephone_fix_rate}
                                name="telephone_fix_rate"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                placeholder={Lang.getString(`fix_rate`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.rate_minute} </label>
                              <Select
                                id="telephone_sub_minute"
                                value={this.state.telephone_sub_minute}
                                name="telephone_sub_minute"
                                onChange={this.handleChange}
                                className="form-control"
                              >
                                <option value="">{Lang.select}</option>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                                <option value={30}>30</option>
                                <option value={35}>35</option>
                                <option value={40}>40</option>
                                <option value={45}>45</option>
                                <option value={50}>50</option>
                                <option value={55}>55</option>
                                <option value={60}>60</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.sub_rate} </label>
                              <input
                                type="text"
                                className="form-control"
                                id="telephone_sub_rate"
                                value={this.state.telephone_sub_rate}
                                name="telephone_sub_rate"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                placeholder={Lang.getString(`sub_rate`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className={"col-md-12 col-lg-12"}>
                        <strong>
                          Video-tolkning&emsp;
                          <span className="trans_rate">
                            <span
                              className={
                                video_rate_was_updated && "trans_rate_changed"
                              }
                            >
                              {this.state.trans_rates?.video
                                ? `${this.state.trans_rates.video} Kr.`
                                : "--Kr."}
                            </span>
                            {video_rate_was_updated &&
                              ` → ${updated_video_rate}Kr.`}
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.rate_minute} </label>
                              <Select
                                id="video_fix_minute"
                                value={this.state.video_fix_minute}
                                name="video_fix_minute"
                                onChange={this.handleChange}
                                className="form-control"
                              >
                                <option value="">{Lang.select}</option>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                                <option value={30}>30</option>
                                <option value={35}>35</option>
                                <option value={40}>40</option>
                                <option value={45}>45</option>
                                <option value={50}>50</option>
                                <option value={55}>55</option>
                                <option value={60}>60</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.fix_rate} </label>
                              <input
                                type="text"
                                className="form-control"
                                id="video_fix_rate"
                                value={this.state.video_fix_rate}
                                name="video_fix_rate"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                placeholder={Lang.getString(`fix_rate`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.rate_minute} </label>
                              <Select
                                id="video_sub_minute"
                                value={this.state.video_sub_minute}
                                name="video_sub_minute"
                                onChange={this.handleChange}
                                className="form-control"
                              >
                                <option value="">{Lang.select}</option>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                                <option value={30}>30</option>
                                <option value={35}>35</option>
                                <option value={40}>40</option>
                                <option value={45}>45</option>
                                <option value={50}>50</option>
                                <option value={55}>55</option>
                                <option value={60}>60</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.sub_rate} </label>
                              <input
                                type="text"
                                className="form-control"
                                id="video_sub_rate"
                                value={this.state.video_sub_rate}
                                name="video_sub_rate"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                placeholder={Lang.getString(`sub_rate`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className={"col-md-12 col-lg-12"}>
                        <strong>Telefonbesked</strong>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.tel_message_fix_rate} </label>
                              <input
                                type="text"
                                className="form-control"
                                id="tel_message_fix_rate"
                                value={this.state.tel_message_fix_rate}
                                name="tel_message_fix_rate"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                placeholder={Lang.getString(
                                  `tel_message_fix_rate`
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className={"col-md-12 col-lg-12"}>
                        <strong>Skriftlig Oversættelse</strong>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.min_word} </label>
                              <input
                                type="text"
                                className="form-control"
                                id="min_word"
                                value={this.state.min_word}
                                name="min_word"
                                onChange={this.handleChange}
                                placeholder={Lang.getString(`min_word`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-3 col-lg-3"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.per_word_rate} </label>
                              <input
                                type="text"
                                className="form-control"
                                id="per_word_rate"
                                value={this.state.per_word_rate}
                                name="per_word_rate"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                placeholder={Lang.getString(`per_word_rate`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.compensation_agreed} </label>
                              <Select
                                id="compensation_agreed"
                                value={
                                  this.state.compensation_agreed !== 0
                                    ? this.state.compensation_agreed
                                    : "0" || ""
                                }
                                name="compensation_agreed"
                                onChange={this.handleChange}
                                className="form-control"
                              >
                                <option value="">{Lang.select}</option>
                                <option value={1}>{Lang.yes}</option>
                                <option value={0}>{Lang.no}</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.compensation_agreed == 1 && (
                      <div className={"row"}>
                        <div className={"col-md-3 col-lg-3"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>{Lang.min_km} </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="min_km"
                                  value={this.state.min_km}
                                  name="min_km"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`min_km`)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={"col-md-3 col-lg-3"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>{Lang.per_km_rate} </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="per_km_rate"
                                  value={this.state.per_km_rate}
                                  name="per_km_rate"
                                  onChange={this.handleChange}
                                  onBlur={this.handleBlur}
                                  placeholder={Lang.getString(`per_km_rate`)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={"row"}>
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.transport_fee_agreed} </label>
                              <Select
                                id="transport_fee_agreed"
                                value={
                                  this.state.transport_fee_agreed !== 0
                                    ? this.state.transport_fee_agreed
                                    : "0" || ""
                                }
                                name="transport_fee_agreed"
                                onChange={this.handleChange}
                                className="form-control"
                              >
                                <option value="">{Lang.select}</option>
                                <option value={1}>{Lang.yes}</option>
                                <option value={0}>{Lang.no}</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.transport_fee_agreed == 1 && (
                      <div className={"row"}>
                        <div className={"col-md-3 col-lg-3"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>{Lang.rate_minute} </label>
                                <Select
                                  id="transport_fee_minute"
                                  value={this.state.transport_fee_minute}
                                  name="transport_fee_minute"
                                  onChange={this.handleChange}
                                  className="form-control"
                                >
                                  <option value="">{Lang.select}</option>
                                  <option value={5}>5</option>
                                  <option value={10}>10</option>
                                  <option value={15}>15</option>
                                  <option value={20}>20</option>
                                  <option value={25}>25</option>
                                  <option value={30}>30</option>
                                  <option value={35}>35</option>
                                  <option value={40}>40</option>
                                  <option value={45}>45</option>
                                  <option value={50}>50</option>
                                  <option value={55}>55</option>
                                  <option value={60}>60</option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={"col-md-3 col-lg-3"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>{Lang.fix_rate} </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="transport_fee_fix_rate"
                                  value={this.state.transport_fee_fix_rate}
                                  name="transport_fee_fix_rate"
                                  onChange={this.handleChange}
                                  onBlur={this.handleBlur}
                                  placeholder={Lang.getString(`fix_rate`)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={"col-md-3 col-lg-3"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>{Lang.rate_minute} </label>
                                <Select
                                  id="transport_fee_sub_minute"
                                  value={this.state.transport_fee_sub_minute}
                                  name="transport_fee_sub_minute"
                                  onChange={this.handleChange}
                                  className="form-control"
                                >
                                  <option value="">{Lang.select}</option>
                                  <option value={5}>5</option>
                                  <option value={10}>10</option>
                                  <option value={15}>15</option>
                                  <option value={20}>20</option>
                                  <option value={25}>25</option>
                                  <option value={30}>30</option>
                                  <option value={35}>35</option>
                                  <option value={40}>40</option>
                                  <option value={45}>45</option>
                                  <option value={50}>50</option>
                                  <option value={55}>55</option>
                                  <option value={60}>60</option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={"col-md-3 col-lg-3"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>{Lang.sub_rate} </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="transport_fee_sub_rate"
                                  value={this.state.transport_fee_sub_rate}
                                  name="transport_fee_sub_rate"
                                  onChange={this.handleChange}
                                  onBlur={this.handleBlur}
                                  placeholder={Lang.getString(`sub_rate`)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={"col-md-12 col-lg-12"}>
                          <p>
                            <i className={Icons.ban}></i> Obs. Maximum statens
                            højeste takst 3,44
                          </p>
                        </div>
                      </div>
                    )}
                    <div className={"row"}>
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>{Lang.holiday_charge_agreed} </label>
                              <Select
                                id="holiday_charge_agreed"
                                value={
                                  this.state.holiday_charge_agreed !== 0
                                    ? this.state.holiday_charge_agreed
                                    : "0" || ""
                                }
                                name="holiday_charge_agreed"
                                onChange={this.handleChange}
                                className="form-control"
                              >
                                <option value="">{Lang.select}</option>
                                <option value={1}>{Lang.yes}</option>
                                <option value={0}>{Lang.no}</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.holiday_charge_agreed == 1 && (
                      <div className={"row"}>
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>{Lang.extra_holiday_percentage} </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="extra_holiday_percentage"
                                  value={this.state.extra_holiday_percentage}
                                  name="extra_holiday_percentage"
                                  onChange={this.handleChange}
                                  onBlur={this.handleBlur}
                                  placeholder={Lang.getString(
                                    `extra_holiday_percentage`
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="reset-btn-section">
                      <CommonSubmitButton />
                      <button
                        className={
                          "btn btn-default hvr-rectangle-out btnMove reset-btn"
                        }
                        data-skin={"dark"}
                        title={Lang.reset}
                        onClick={(e) => this.formDataReset(e)}
                      >
                        {Lang.reset}
                      </button>
                    </div>
                    <div className={"row"}>
                      <div className={"col-md-9 col-lg-9"}>
                        <h4>B. Særlige aftaler for specifikke kunder</h4>
                      </div>
                      <div className={"col-md-3 col-lg-3"}>
                        <input
                          type="button"
                          className="btn btn-primary"
                          value={Lang.add_more}
                          onClick={() => {
                            ratesAddService.showPopup(this.state);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <br />
                <div className="kt-portlet__body">
                  <div
                    className={
                      "kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
                    }
                  >
                    {!this.state.is_loading && this.state.rates.length == 0 && (
                      <NoRecords />
                    )}
                    {this.state.is_loading && <PleaseWait />}
                    {!this.state.is_loading && this.state.rates.length > 0 && (
                      <table className={"table"}>
                        <tbody>
                          <tr>
                            <th>#</th>
                            <th>{Lang.company}</th>
                            <th>{Lang.special_domain}</th>
                            <th>{Lang.action}</th>
                          </tr>
                          {this.state.rates.map(
                            (
                              item: TranslatorSpecialRatesModel,
                              index: number
                            ) => {
                              return (
                                <tr key={item.id}>
                                  <td>{++index}</td>
                                  {item.company && (
                                    <React.Fragment>
                                      <td>{item.company.name}</td>
                                      <td></td>
                                    </React.Fragment>
                                  )}
                                  {item.special_domain && (
                                    <React.Fragment>
                                      <td></td>
                                      <td>{item.special_domain}</td>
                                    </React.Fragment>
                                  )}
                                  <td>
                                    <GeneratePermitLink
                                      className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                      scope={""}
                                      to={"#"}
                                      onClick={() => {
                                        ratesUpdateService.showPopup(item);
                                      }}
                                    >
                                      <i className={Icons.edit} />
                                    </GeneratePermitLink>
                                    <GeneratePermitLink
                                      className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                      scope={""}
                                      to={"#"}
                                      onClick={() => {
                                        this.updateStatus(1, item.id);
                                      }}
                                    >
                                      <i className={Icons.delete} />
                                    </GeneratePermitLink>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                <RatesUpdate
                  transInfo={this.props.match.params}
                  normalRates={this.state}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
