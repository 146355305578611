import React, {useEffect, useReducer, useRef} from 'react';
import { OrderCommentService, submitComment} from "../../../services";
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {RequestWaiting, showNotification, SubmitButton} from "../../../common";
import {
  OrderCommentReducer, InitialState, PopupState, ActionType,
  CAction
} from "../order-reducers/OrderCommentReducer";
import {OrderCommentModel} from "../../../model";

export function OrderComments () {

  const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(OrderCommentReducer , InitialState);

  useEffect( () => {
    OrderCommentService.getPopupState().subscribe((message:any) => {
      dispatch({type: message.action, payload: message}) ;
    });

  }, []);

  if(state.show === false){
    return null;
  }


  function handleChange(e: React.ChangeEvent<HTMLElement>) {
    const target : any = e.target;
    const value = target.value;
    const name = target.name;
    dispatch({type: ActionType.HANDLE_CHANGE, payload: { value: value, name: name } as any});
  }


  function handleSubmit(event) {
    event.preventDefault();
    if($("#order_comments").valid()){
      submitComment(state.order_id, state.form).then(res => {
        dispatch({type: ActionType.ADD_COMMENT, payload: res});
      });
    }
  }
  const {order_data} = state;

    function resetForm(event:any){ 
        event.preventDefault();
        var comment = document.getElementById("comment") as HTMLInputElement;
        comment.value = '';
    }

  return(
    <Modal
      show={state.show}
      onHide={() => { dispatch({type: ActionType.HIDE_POPUP}) } }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form onSubmit={handleSubmit} id="order_comments">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {Lang.comment_title} - #{order_data.order_no}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
              <RequestWaiting item={state.comments}>
                {
                  state.comments.length > 0 &&
                  <div className={'col-md-12'}>
                    <table className={'table table-bordered table-hover'}>
                      <tbody>
                      <tr>
                        <td>#</td>
                        <td>{Lang.users}</td>
                        <td>{Lang.comments}</td>
                        <td>{Lang.created_at}</td>
                      </tr>
                      {state.comments.map((item: OrderCommentModel, index: number) => {
                        return (
                          <tr key={item.id}>
                            <td>{++index}</td>
                            <td>{item.user.name}</td>
                            <td>{item.comment}</td>
                            <td>{item.created_at}</td>
                          </tr>
                        )
                      })}
                      </tbody>

                    </table>
                  </div>
                }
              </RequestWaiting>

              <div className={'col-md-12'} >
                  <div className={'form-group'}>
                    <textarea name={'comment'} className={'form-control'} value={state.form.comment} rows={4} onChange={handleChange} id="comment" placeholder={Lang.enter_comment} required/>
                  </div>

              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={'btn btn-info'} data-skin={'dark'} title={Lang.reset} 
                onClick={(e) => resetForm(e)}>{Lang.reset}</button>&nbsp;
          <SubmitButton className={'btn btn-primary order_comment_submit_button'} label={Lang.submit}/>

        </Modal.Footer>
      </form>
    </Modal>
  )


}