import React, {useReducer, useState, useEffect} from  'react';
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {createTransContact, sendCustomerMail, SendTransMailPopSubjectService} from "../../../services";
import {Select, showNotification, SubmitButton} from "../../../common";
import {
  ActionType,
  IAction, initialPopupState, PopupService, PopupState, SendMailToTransReducer
} from "../order-reducers/SendMailToTransReducer";
import {Editor} from "@tinymce/tinymce-react/lib/cjs/main/ts";
import { OrderType} from "../../../model";
import _ from 'lodash';

export function SendMailToTranslator() {

  const [state, dispatch] = useReducer<React.Reducer<PopupState, IAction>>(SendMailToTransReducer , initialPopupState);

  useEffect( () => {
    SendTransMailPopSubjectService.getPopupState().subscribe((message:PopupService) => {
      dispatch({type: message.action, payload: message.data} as any) ;
    });
  }, []);

  if(state.show === false){
    return null;
  }


  function handleChange(e: React.ChangeEvent<HTMLElement>) {
    const target : any = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name
    dispatch({type: ActionType.HANDLE_CHANGE, payload: { value: value, name: name } as any});
  }

  function handleSubmit(event) {
    event.preventDefault();
    createTransContact(state.order_id, state.form).then(res => {
      showNotification('success', res)
      SendTransMailPopSubjectService.hidePopup();
    })

  }

  function handleEditorChange(content : any) {
    dispatch({type: ActionType.HANDLE_CHANGE, payload: { value: content, name: 'mail_content' } as any});
  }

  const {order_data} = state;
  return(
    <Modal
      show={state.show}
      onHide={() => { dispatch({type: ActionType.HIDE_POPUP}) } }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {Lang.send_mail_translator} - #{order_data.order_no}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
              <div className={'col-md-12'}>
                <div className={'form-group'}>
                  <label className="kt-checkbox kt-checkbox--brand">
                    <input type={'checkbox'} name={'is_send_mail'} checked={state.form.is_send_mail} onChange={handleChange}/>
                    Send Email
                    <span/>
                  </label>
                  &nbsp; &nbsp;
                  <label className="kt-checkbox kt-checkbox--brand">
                    <input type={'checkbox'} name={'is_send_sms'} checked={state.form.is_send_sms} onChange={handleChange}/>
                    Send SMS
                    <span/>
                  </label>
                </div>
              </div>

              <div className={'col-md-12'} style={{ marginLeft: 10}}>

                <div className={'form-group'}>
                  <label>SMS Content</label>
                  <textarea id={'msg_content'} className={'form-control'} rows={5} name={'sms_content'} value={state.form.sms_content} onChange={handleChange}/>
                </div>

                <div className={'form-group'}>
                  <label>Email Content</label>
                  <Editor id='content'
                      init={{
                        menubar:false,mode: "textareas", selector: '#content', branding: false, forced_root_block : false
                      }} value={state.form.mail_content} onEditorChange={handleEditorChange} />
                </div>
              </div>
            </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {(state.form.is_send_sms || state.form.is_send_mail) &&
          <SubmitButton className={'btn btn-primary'} label={Lang.send}/>
        }

      </Modal.Footer>
      </form>
    </Modal>
  )
}