import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import App from "./App";

Sentry.init({ 
    dsn: "https://1bdf95878a104c94a6b82a9c9cb216f2@o465582.ingest.sentry.io/5478632",
    environment: process.env.NODE_ENV
 });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
