import Lang from "../../common/lang/Lang";

declare var window: any;
export const DepartmentValidations = function () {

  $(document).ready(function () {

    $.validator.addMethod("multiple_emails",  (value: any, element: HTMLElement, params: any) =>{
      let all_emails = value.split(",");
      let flag = true;
      all_emails.map((item: string) => {
          if(!jQuery.validator.methods.email.call(this, item.trim(), element)){
            flag = false;
          }
        })
        return flag;
      },
      Lang.company_mul_email);



      $( "#customer_form" ).validate({
        // define validation rules
        rules: {
          department_id: {
            required: true
          },
          company_id: {
            required: true,
            
          },
          name: {
            required: true,
            
          },
        },

      invalidHandler: function(event, validator) {
        var alert = $('#kt_form_1_msg');
        alert.removeClass('kt--hide').show();
      },
    });
  });
}