import * as React from 'react';
import {Breadcrumb} from "../../common/page/Breadcrumb";
import Lang from "../../common/lang/Lang";
import {EditBreadcrumb} from "./Data";
import API from './../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {SubmitButton,showNotification,SwitchLoader, CONST} from "../../common";
 import {CommonSettingValues} from "../../model/CommonSetting";
import {CommonValidations} from "./Validation";
import { Editor } from '@tinymce/tinymce-react';
import {LoaderType} from "../../common/Loader";

declare var window: any;

interface IState{
  form: {
   
  setting_name : string,
  setting_value: string,
    field_type: number,
  };
 
  id:number;

}
export class CommonEdit extends React.Component<RouteComponentProps<any>, IState> {
  state: IState;

  constructor(props: any){
    super(props);

    this.state = {
      form: CommonSettingValues(),
      id: props.match.params.id,
      
    }
    
    CommonValidations();
  }
   
 
  componentDidMount() {
    API.get(`settings/common-setting/${this.state.id}`).then((res: any) => {
      this.setState({
        form: CommonSettingValues(res.data[0])
      })
    })

  }

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

  

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#admin_user_form").valid()) {
      API.put(`settings/common-setting/${this.state.id}`, this.state.form, {params: {from: 'info'}}).then((res) => {
        showNotification("success", Lang.setting_updated);
        this.props.history.push(`/settings/common-setting`) 
      })
    }
  }
  

  render() {
    
    return (
    
      <div>
        
         <Breadcrumb data={EditBreadcrumb}/>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
               
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                  <form className="kt-form" id="admin_user_form"  onSubmit={this.handleSubmit}>
                    <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                      <div className="kt-heading kt-heading--md">{Lang.edit_setting}</div>
                      <div className="kt-form__section kt-form__section--first">
                        <div className="kt-wizard-v2__form">


                              <div className="form-group">
                                <label>{Lang.setting_name} <span className={'required'}>*</span></label>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.setting_name} name={'setting_name'} className="form-control"/>
                              </div>

                              <div className="form-group">
                                <label>{Lang.setting_value} <span className={'required'}>*</span></label>
                                {
                                  this.state.form.field_type === 2 &&

                                  <Editor  apiKey={CONST.TINY_MCE_KEY} id='insertText' init={{
                                    menubar:false, selector: 'textarea', branding: false, forced_root_block : false
                                  }} value={this.state.form.setting_value} onEditorChange={(value) => {this.setState({ form: { ...this.state.form, setting_value: value} })}} />
                                }

                                 {
                                  this.state.form.field_type !== 2 &&
                                    <textarea  onChange={this.handleChange} value={this.state.form.setting_value} name={'setting_value'} className="form-control"/>
                                }


                              </div>

                              <div className="kt-form__actions">
                          <div className="kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                          <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                          </div>
                        </div>  
                        </div>

                       
                      </div>
                    </div>
                  </form>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}