import React, {useState, useEffect} from 'react';
import {Breadcrumb, Icons} from "../../common";
import Lang from "../../common/lang/Lang";
import {ChangePwdBreadcrumb} from "./Data";
import {RouteComponentProps} from "react-router";
import { QuizModel} from "../../model";
import {getOnlineTestAnswers} from "../../services";
import _ from 'lodash';
import {QuizResponseList} from "./QuizResponseList";
import Axios from '../../common/AxoisClient';

export function  TranslatorOnlineTest (props: RouteComponentProps) {
  const [DownloadLink, setDownloadLink] = useState<HTMLAnchorElement | null>(null);
  const [ShowDownloadButton, setShowDownloadButton] = useState<boolean>(false);
  const {id}: any = props.match.params;

  const [quiz, setQuiz] = useState({} as QuizModel);

  useEffect(() => {
    // seeing if interview recording exists, if it does create download link out of it
    Axios.get(`/users/translator/get-interview-call-file/${id}`, {responseType: 'arraybuffer'})
      .then(res => {
        if (res.data !== "Interview Call Doesnt Exist") {
          setShowDownloadButton(true);
          const blob = new Blob([res.data], {type: "audio/mpeg"});
          const url = URL.createObjectURL(blob);
          const tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = url;
          tempLink.setAttribute('download', `interview_recording_${id}.mp3`);
          if (tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
          }
          setDownloadLink(tempLink);
        }
      })
    // online answers
    getOnlineTestAnswers(id).then(res => {
      setQuiz(res);
    });
  }, []);

  const handleDownload = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();    
    document.body.appendChild(DownloadLink);
    DownloadLink.click();
    document.body.removeChild(DownloadLink);
  }

  return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
      <Breadcrumb data={ChangePwdBreadcrumb}/>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="row">
          <div className="col-xl-12">
            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">{Lang.recruitment_results}</h3>
                </div>
              </div>
              <br/>
              {
                ShowDownloadButton && (
                  <>
                    <form onSubmit={handleDownload} style={{ margin: '2rem 2rem 3rem' }}>
                      <label><h2>Interviewoptagelse</h2></label><br/>
                      <button type="submit" className="btn btn-primary">{Lang.download_document}<i className={Icons.download}/></button>
                    </form>
                    <h2 style={{ marginLeft: '2rem' }}>GTEP -svar</h2>
                  </>
                )
              }
              <div className="kt-notification-v2">
                {
                  _.isEmpty(quiz) &&
                    <div className={'col-md-12'}>
                      <div className={'kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded'}>
                        <div className="alert alert-outline-warning fade show" role="alert">
                          <div className="alert-icon"><i className="flaticon-warning"/></div>
                          <div className="alert-text">{Lang.test_not_taken}</div>
                        </div>
                      </div>
                    </div>
                }
                <QuizResponseList quiz={quiz}/>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}