import React, {useRef, useEffect} from 'react';
import _ from 'lodash';
import {TimePickerOptions} from "../../index";

// styles
import sheet from './TimePicker.module.scss'
import { Icons } from '../../Icon';

export interface TimePickerProps  extends React.InputHTMLAttributes<HTMLInputElement> {
  options?: TimepickerOptions
  onTimeChange?: (e: React.ChangeEvent<HTMLInputElement>, elem?: JQuery<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}


export function TimePickerWrapper (props: TimePickerProps) {
  let onTimeChange = props.onTimeChange;
  if (props.onChange) onTimeChange = props.onChange
  const datetime_picker = useRef(null);

  useEffect(() => {
    const $el = datetime_picker.current;
    if($el !== null ){
      $el.readOnly = true;
      $($el).timepicker({...TimePickerOptions, ...props.options}).on('changeTime.timepicker', function(ev: any) {
        onTimeChange(ev, $($el))
      });
    }
  }, []);

  return(
    <label className={sheet.time_picker_container}>
      <i className={`${Icons.clock} ${sheet.time_picker_icon}`} />
      <input className={`${props.className} ${sheet.time_picker_input}`} type={'text'} ref={datetime_picker} {... _.omit(props, ['options', 'ref', 'className'])} />
    </label>
  )
}