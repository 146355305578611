import {
    UserModel
} from "./User";

export interface SMSModel {
    id: number;
    subject: string;
    message: string;
    status: number;
    created_at: string;
    users ? : UserModel;
    translator ? : number;
    customer ? : number;
    language_from: number;
    language_to: number;
    query: string;
    customertypeUsers ? : number;
    translatortypeUsers ? : number;
    userlanguagefrom ? : number;
    userlanguageto ? : number;
    userStatus ? : string;
    user_type ? : number
}

export const SMSValues = (data ? : SMSModel) => {
    return {
        subject: data !== undefined ? data.subject : '',
        message: data !== undefined ? data.message : '',
        status: data !== undefined ? data.status : '',
        user_type: data !== undefined ? data.user_type : 0,
        userIds: [],
        customer: data !== undefined ? data.customer : 0,
        translator: data !== undefined ? data.translator : 0,
        language_from: data !== undefined ? data.language_from : 0,
        language_to: data !== undefined ? data.language_to : 0,

    }
}

export const SearchValues = {
    query: '',
    customertypeUsers: 0,
    translatortypeUsers: 0,
    userlanguagefrom: 0,
    userlanguageto: 0,
    userStatus: '',
    user_type: 0,
}