import _ from 'lodash'
import React, { useState } from 'react'

// styles
import style from './MultiSelector.module.scss'

interface Props<T>{
  label?: string
  name: string
  form: T
  updateState: (newState: T) => void
  width?: number
  options: {
    label: string
    value: string | number
    checked: boolean
  }[]
  style?: React.CSSProperties
}

const MultiSelector = <T,>({label, name, form, updateState, width = 0, options, style: styling}: Props<T>) => {
  const [DropdownOpened, setDropdownOpened] = useState<boolean>(false)
  const [Closeable, setCloseable] = useState<boolean>(true)
  const [PlayClosingAnimation, setPlayClosingAnimation] = useState<boolean>(false)

  // DropDown Functions
  
  const disableCloseDropdown = () => setCloseable(false)
  const enableCloseDropdown = () => setCloseable(true)
  const closeDropdown = () => {
    if (Closeable) {
      setPlayClosingAnimation(true)
      setTimeout(() => {
        setDropdownOpened(false)
        setPlayClosingAnimation(false)
      }, 200);
    }
  }
  const handleLabelClick = () => {
    if (DropdownOpened) {
      setPlayClosingAnimation(true)
      setTimeout(() => {
        setDropdownOpened(false)
        setPlayClosingAnimation(false)
      }, 200);
    } else {
      setDropdownOpened(true)
    }
  }

  // Form Functions
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target
    let newFormState = _.cloneDeep(form);
    let pointer = _.get(newFormState, name)
    if (checked) {
      pointer.push(value)
    } else {
      pointer = pointer.filter(item => item !== value)
    }
    _.set(newFormState as unknown as object, name, pointer)
    updateState(newFormState)
  }

  const checkedItemsToTop = (options: Props<T>['options']): Props<T>['options'] => {
    // couldn't get the sort function to work properly without bugs so used filter
    const checked = options.filter(item => item.checked)
    const rest = options.filter(item => !item.checked)
    return [...checked, ...rest]
  }

  return (
    <div
      className={style.container}
      tabIndex={0}
      onMouseEnter={disableCloseDropdown}
      onMouseLeave={enableCloseDropdown}
      onBlur={closeDropdown}
      style={{ ...styling, width: width ? `${width}rem` : (styling?.width || undefined) }}
    >
      <div className={style.label} onClick={handleLabelClick}>
        <span>{ label || 'Select' }</span>
        <span>&gt;</span>
      </div>
      {
        DropdownOpened && (
          <ul className={`${style.option_list} ${PlayClosingAnimation && style.option_list_closing_animation}`}>
            {
              checkedItemsToTop(options).map(option => (
                <li>
                  <input type="checkbox" id={String(option.value)} name={name} value={option.value} checked={option.checked} onChange={handleChange} />
                  <label htmlFor={String(option.value)}>{option.label}</label>
                </li>
              ))
            }
          </ul>
        )
      }
    </div>
  )
}

export default MultiSelector
