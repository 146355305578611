import * as React from 'react';
import {default as Lang} from "../../common/lang/Lang"
import API from '../../common/AxoisClient';
import {
  ActionLink, 
  IState as CommonState, PleaseWait, Icons,CONST ,Breadcrumb
} from "../../common";
import {OrderInfoBreadcrumb } from "./Data";
import {OrderModel, OrderProgress, OrderType,OrderStatus} from "../../model";
import _ from "lodash";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
 import {CallInfo} from "./CallInfo";
import {OrderInfoHeader} from "./order-compnent/orderinfo-header";
import {OrderDetails} from "./order-compnent/order-detail";
import {OrderContactInfo} from "./order-compnent/order-contactInfo";
import {OrderTranslatorInfo} from "./order-compnent/order-translatorInfo";
import {OrderCancelInfo} from "./order-compnent/order-cancelInfo";
import {OrderFeedbackInfo} from "./order-compnent/order-feedbackInfo";
import {OrderPreferenceInfo} from "./order-compnent/order-preferenceInfo";
let moment = require("moment");


declare var window:any;


export class Orderdetails extends React.Component <any, any>{
  
  constructor(props: any){
    super(props);
    
    this.state = {
       
      order_detail: {} as OrderModel,
    }
    
  }

  updateOrderDetails() {
      API.get(`orders/orders/${this.props.match.params.id}`).then((res: any) => {
          this.setState({
              order_detail: res.data.orderInfo,
          });
      });
  }

  componentDidMount() {
       this.updateOrderDetails();
       let __this = this;
       $('body').on('click','.order_comment_submit_button',function () {
          let textFieldVal = $(this).parents('.modal-content').find('textarea[name="comment"]').val();
          if(!_.isEmpty(textFieldVal)) {
              setTimeout(function () {
                  __this.updateOrderDetails();
              },1000);
          }
       });
  }
  
 

  render() {
 
   if(_.isEmpty(this.state.order_detail)){
      return <PleaseWait/>
   } else {
      
    return (
      <ListingLayout breadcrumb={OrderInfoBreadcrumb}>
      <ListHeader title={'order_info'} icon={Icons.order} order_no={this.state.order_detail.order_no} >
      </ListHeader>
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
       
      <OrderInfoHeader info={this.state.order_detail} />
     <div className="row">
     <OrderDetails info={this.state.order_detail} />     
     <OrderContactInfo info={this.state.order_detail} />
        <OrderTranslatorInfo info={this.state.order_detail} /> 
        {this.state.order_detail.status === 4 &&
        <OrderCancelInfo info={this.state.order_detail} />
         }
        <OrderFeedbackInfo info={this.state.order_detail} />
       <OrderPreferenceInfo info={this.state.order_detail} />
          {this.state.order_detail.translation_type_id === OrderType.VIDEO && 
          <div className="col-xl-4">
          <CallInfo info={this.state.order_detail} />
          </div>
           }
      </div>
      </div>
     
      </ListingLayout>
      )
   }
   }
}