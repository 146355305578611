import {is_permit, showNotification} from "../Common";
import {default as Lang} from "../lang/Lang";
import API from "../AxoisClient";
import Swal from 'sweetalert2'
import {ShowRecords} from "../ShowRecords";
import * as React from "react";

export interface ActionButtonSchema {
  records: React.RefObject<ShowRecords>
  url: string,
  module?: string
}


export const actionButton = (props: ActionButtonSchema) => {
       
  function actions(type: string) {

    Swal.fire({
      title: Lang.is_sure,
      text: Lang.getString(`${type}_confirm`),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        let ids: any = props.records.current.state.selected;
        API.post(props.url, {ids, action: type}).then((response : any) => {
          if(response.data.status === true){
            props.records.current.fetchData();
            showNotification('success', response.data.message)
          } else {
            showNotification('error', response.data.message)
          }
        });
      }
    })
  }

  return [
    {label: 'Activate',  "class": "btn btn-sm btn-primary", "onClick" : () => actions('active'), show: is_permit(`${props.module}.update`)},
    {label: 'Delete', "class": "btn btn-sm btn-danger", "onClick" : () => actions('delete'), show: is_permit(`${props.module}.delete`)},
  ];
}