import React from 'react'

// types
import { GetItemPropsOptions } from 'downshift'
import { CompanyModel } from '../../../../model'

// styles
import sheet from './CustomerEmailInput.module.scss'
import '../../../../../DownshiftCommon.scss'
import Lang from '../../../../common/lang/Lang'

interface Props {
  getItemProps: (options: GetItemPropsOptions<CompanyModel>) => any
  item: CompanyModel
  index: number
  highlightedIndex: number
}

const SpecialCompanyItem = ({ getItemProps, item, index, highlightedIndex }: Props) => {
  return (
    <li {...getItemProps({
        item,
        index,
        className: `item ${sheet.item} ${highlightedIndex === index && 'highlighted_item'}`,
        key: item.id
      })}
    >
      <div className={sheet.company_info}>
        <span>{item.name}</span>
        <span>{item.email}</span>
      </div>
      <span className={sheet.special_company_indicator}>{ Lang.special }</span>
    </li>
  )
}

export default SpecialCompanyItem
