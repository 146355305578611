import * as React from 'react';
import {PermissionModel} from "../../model/Permission";
import {PleaseWait} from "../../common/page/PleaseWait";
import {RouteComponentProps} from "react-router";
import {RoleValues} from "../../model";
import {RoleValidations} from "./Validation";
import _ from "lodash";

interface PropsPermission {
  scopes: Array<any>;
  permissions: Array<string>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void
  CheckAll?: (event: React.ChangeEvent<HTMLInputElement>, roles: Array<PermissionModel>) => void
    CheckedAllValues?: (event: React.ChangeEvent<HTMLInputElement>, rolesPermission) => void

}

export class ChoosePermission extends React.Component<PropsPermission> {


    checkAllVal = (module)=> {
        let scopes = [];
        module.map((item: PermissionModel) => {
            scopes.push(item.scope);
        })
        for(var i = 0; i < scopes.length; i++){
            if(this.props.permissions.indexOf(scopes[i]) === -1)
                return false;
        }
        return true;
    }

    checkedAllVal = (module)=> {
        let scopes = [];
        Object.entries(module).map((key : any, value: number) =>  {
            key[1].map((item: PermissionModel) => {
                scopes.push(item.scope);

            })
        })
        for(var i = 0; i < scopes.length; i++){
            if(this.props.permissions.indexOf(scopes[i]) === -1)
                return false;
        }
        return true;
    }
  render() {

    if( this.props.scopes.length <= 0){

      return   PleaseWait();
    } else {

      return(

          <div>
              <div className={"col-md-12 col-lg-12"}>
                   <span className="kt-switch">
                      <label>
                       <input type={'checkbox'}  checked={this.checkedAllVal(this.props.scopes)} onChange={(e) => this.props.CheckedAllValues(e, this.props.scopes)} />
                        <span/>
                      </label>
                    </span>

              </div>
              { this.props.scopes &&
                  Object.keys(this.props.scopes).map((module: any, index:number) => {
                      return (
                        <div className={"seprateBox"}>
                          <div className={"row"} key={index}>

                            <div className={"col-md-4"}>
                              <h4 >{(module === 'Calllogs')?'Call Logs':(module === 'Email')?'NewsLetter':module}</h4>
                                  </div>
                                  <div className={"col-md-8"}>
                              <span className="kt-switch">
                                  <label>
                                    <input type={'checkbox'}  checked={this.checkAllVal(this.props.scopes[module])} onChange={(e) => this.props.CheckAll(e, this.props.scopes[module])} />
                                    <span/>
                                  </label>
                                </span>
                                  </div>

                              {this.props.scopes[module].map((content: PermissionModel, num: number) => {

                                  return (
                                      <div className={"col-md-6 col-lg-6"} key={num}>
                                          <div className="form-group form-group-sm row">
                                              <div className="col-lg-12">
                                <span className="kt-switch leftSwitchbtn">
                                  <label>
                                    <input type={'checkbox'}  checked={this.props.permissions.indexOf(content.scope) > -1}  value={content.id} onChange={(e) => this.props.onChange(e, content.scope)} name={'scopes'}/>
                                    <span/>
                                  </label>
                                </span>
                                <label className="col-form-label VrtTop rightSwitchText" style={{fontSize: 15}}> {(content.action === 'Calllogs')?'Call Logs':content.action} <i className={'flaticon2-information'} data-skin={'dark'} data-toggle={'kt-tooltip'} title={content.title}/></label>
                                              </div>
                                              
                                          </div>
                                      </div>
                                  )
                              })}
                          </div>
                          </div>
                      )
              })
          }
          </div>


      )
    }
  }
}