import {AddLeaveFormSchema,GetLeavesSchema} from "./Schema";
import {CountryModel} from "../../model/Country";

export interface TContractSendSchema {
  subject?: string;
  content?: string;
  contractContent?: string;
  translator_id?: number;
}

const TContractSendState: TContractSendSchema = {
  subject: '',
  content: '',
  contractContent: '',

}

export const defaultVal  = (props) => {
  if(props.ContractInfo!==undefined){
      return {subject: props.ContractInfo.email_template.subject,
          content: props.ContractInfo.email_template.content,
          contractContent: props.ContractInfo.setting, translator_id: props.TransInfo.id}
  } else {
    return TContractSendState;
  }
}

export const AddLeaveFormState: AddLeaveFormSchema = {
  leave_type:1,
  from_date:'',
  to_date:'',
  reason:''
}

export const GetLeavesState: GetLeavesSchema = {
  transLeavesData: [],
}