import * as React from 'react';
import Lang from "../../common/lang/Lang";
import {EditBreadcrumb} from "./Data";
import {DataSchema, Form, FormDefaultVal, FormFields, RequiredFields} from "./Model";
import API from './../../common/AxoisClient';
import {SubmitButton,showNotification, Breadcrumb,SwitchLoader} from "../../common";
import {CompanyTabs} from "./Tabs";
import {CompanyValidations} from "./Validations";
import {EditProfileImgPopup} from "./EditProfileImgPopup";
import {LoaderType} from "../../common/Loader";
import Cropper from 'cropperjs';
import _ from "lodash";

interface IState{
  form: DataSchema;
  id: number;
  uploadDocument?: File;
}
var cropper:any;
export class CompanyEdit extends React.Component<{}, IState> {
  state: IState;

  constructor(props: any){
    super(props);

    this.state = {
      form: FormDefaultVal,
      id: props.match.params.id
    }
    CompanyValidations();
  }

   
  componentDidMount() {
    API.get(`settings/company/${this.state.id}`).then((res) => {
      this.setState({
        form: res.data
      })
    })
  
    let logoupload = this;
    $(document).ready(function () {
      $("#updateProfilePictureOfUser").unbind().click(function(event) {
          event.preventDefault();
          let imgData = cropper.getCroppedCanvas().toDataURL("image/png");
          let formData = new FormData();
          formData.append('logo', imgData);
          $("#updateProfilePictureOfUser").hide();
          API.post('settings/uploadcompanylogo', formData).then((res) => {
             if (res.data[0]) {
               logoupload.setState({
                 form: { ...logoupload.state.form, logo: res.data[0]}
                });
                 $("#updateProfilePictureOfUser").show();
                  ($('#editProfileImagePopup') as any).modal('hide');
                  $('#profile_pic').val('');
              } else {
                  $("#updateProfilePictureOfUser").html(Lang.submit);
               }
          })
      });
  });
  }

   uploadProfilePic = async (e:React.ChangeEvent<HTMLElement>, callback?: (doc:string)=> void ) => {
    const target:any = e.target;

    let fileData:any = target.files[0];
    let reader:any = new FileReader();

    if (fileData.type.startsWith('image/')) {
        ($('#editProfileImagePopup') as any).modal('show');
        $('#uploadedImage').css({opacity:'0'});
        reader.addEventListener("load", function () {
            $('#uploadedImage').attr('src',reader.result);
            setTimeout(function () {
                $('#uploadedImage').css({opacity:'1'});
                const image:any = document.getElementById('uploadedImage');
                if(!_.isEmpty(cropper)) {
                    cropper.destroy();
                }
                image.src = reader.result;
                    cropper = new Cropper(image, {
                    aspectRatio: 5 / 5,
                    crop(event) {

                    },
                });

            },500);
        }, false);

        if (fileData) {
            reader.readAsDataURL(fileData);
        }
    }
};


  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target : any = event.target;
    let value = target.value;
    const name = target.name;
    if(name === 'prefilled_fields'){
      let prefilled_fields: Array<string> = this.state.form.prefilled_fields !== null ?  this.state.form.prefilled_fields.split(',') : [];
      if(_.indexOf(prefilled_fields, value) > -1 ){
        prefilled_fields = _.pull(prefilled_fields, value);
      } else {
        prefilled_fields = _.concat(prefilled_fields, value);
      }
      prefilled_fields = _.compact(prefilled_fields);
      if(_.indexOf(prefilled_fields, "department_unit_id") <= -1 &&  prefilled_fields.length > 0){
        prefilled_fields.push('department_unit_id');
      }
      value = _.join(prefilled_fields, ',');
    }
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#create_company").valid()) {
      API.put(`settings/company/${this.state.id}`, this.state.form).then((res) => {
        showNotification("success", Lang.company_update);
      })
    }
  }


  render() {
    const form: Array<FormFields> = Form;
    type K1 = keyof DataSchema;
    return (
      <div>
        <Breadcrumb data={EditBreadcrumb}/>

        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
               <CompanyTabs active={'info'} company_id={this.state.id}/>
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                  <form className="kt-form" id="create_company"  onSubmit={this.handleSubmit}>
                    <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                      <div className="kt-heading kt-heading--md">{Lang.edit_company}</div>
                      <div className="kt-form__section kt-form__section--first">
                        <div className="kt-wizard-v2__form">
                          {form.map((item : FormFields, index) => {
                            return (
                                <div className="form-group" key={index}>
                                  <label>{Lang.getString(item.name)} <span className={'required'}>*</span></label>
                                  <input type={item.type !== undefined ? item.type : 'text'} onChange={this.handleChange} value={this.state.form[item.name as K1]} name={item.name} className="form-control" placeholder={Lang.getString(`${item.name}_placeholder`)}/>
                                  {item.name === 'email' &&
                                  <span className="form-text text-muted">{Lang.other_emails_hint}</span>
                                  }
                              </div>
                            );
                          })}
                        <div className={"col-md-12 col-lg-12"}>
                        <div className="form-group">
                        <label>{Lang.logo_upload}:</label>
                     <div></div>
                     <div className="custom-file">
                     <input accept="image/*" onChange={(e)=>this.uploadProfilePic(e)} className="input-file-trigger" type="file" name="logo" id="profile_pic"/>
                       <label className="custom-file-label" htmlFor="profile_pic"/> 
                      </div>
                    </div>
                    { this.state.form.logo !== null &&
                     <div className="form-group">
                     <img src={this.state.form.logo} width="50%" height="50%"/>
                      </div>
                     }
                     </div>
                          {this.state.form.is_special_company === 1&&
                            <div className={"col-md-12 col-lg-12"}>
                              <div className="form-group">
                                <label>Required Fields For Customers<span className={'required'}>*</span></label><br/>
                                {Object.keys(RequiredFields).map((item, index) => {
                                  return (
                                    <label className={"kt-checkbox kt-checkbox--brand" } key={index} style={{marginLeft: 20}} >
                                      <input type={'checkbox'} name={'prefilled_fields'} checked={this.state.form.prefilled_fields !== null && this.state.form.prefilled_fields.search(item) !== -1} value={item} onChange={this.handleChange}  />
                                      { RequiredFields[item] }
                                      <span/>
                                      &nbsp;
                                    </label>
                                  )
                                })}
                              </div>
                            </div>
                          }

                        </div>
                        <div className="kt-form__actions">
                          <div className="kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                            <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <EditProfileImgPopup />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}