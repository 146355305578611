import React, {useState,useEffect} from 'react';
import { Modal, ModalProps} from "react-bootstrap";
import Lang from "../../../common/lang/Lang";
import {UserModel} from "../../../model";
import {base_url, showNotification, SubmitButton} from "../../../common";
import API from "../../../common/AxoisClient";
import {AxiosResponse} from "axios";
import {EmailTemplateDataSchema} from "../../../settings/email-templates/Model";
import {UserContractModel} from "../../../model/UserContract";
import * as _ from 'lodash';
import { ChangeEvent } from 'react';




export interface ContractModel {
  subject?: string;
  content?: string;
  contractContent?: string;
  translator_id?: number;
  email_template?: EmailTemplateDataSchema;
  contract?: UserContractModel;
}

interface InfoProps extends ModalProps{
  transInfo: UserModel,
  transID?: number
  ContractInfo?: ContractModel
   
}

export const Documents = (props: InfoProps) => {
  
    // if(props.TransInfo === undefined)
    //  return null;
  
    const [Resume, setResume] = useState();
    const [Childern, setChildern] = useState();
    const [Criminal, setCriminal] = useState();
    const [ContractURL, setContractURL] = useState<string>('');
    const [Loader, setLoader] = useState(0);

    useEffect(() => {
      API.get(`users/translator/upload-ddocumentlist/${props.transInfo.id}`).then(response => {
        setResume(response.data[0].resume); 
        setChildern(response.data[0].children_certificate);
        setCriminal(response.data[0].criminal_record);
      })
      API.get(`users/translator/get-contract/${props.transInfo.id}`, {responseType: 'arraybuffer'})
        .then((res: AxiosResponse) => {
          const response_string = String.fromCharCode.apply(null, new Uint8Array(res.data));
          if (response_string !== "\"Translator's Contract Doesnt Exist\"") {
            const blob = new Blob([res.data], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            setContractURL(url);
          }
        })
    },[props.transInfo.id]);

   
    function DocumentUpload(event: React.ChangeEvent<HTMLInputElement>){
        setLoader(1);
        const name = event.target.name;
        let formData = new FormData();
        if(name === 'resume'){
          formData.append('resume', event.target.files[0]);
        }
        if(name === 'children_certificate'){
          formData.append('children_certificate', event.target.files[0]);
        }
        if(name === 'criminal_record'){
          formData.append('criminal_record', event.target.files[0]);
        }
        formData.append('id', `${props.transInfo.id}`);
        API.post(`users/translator/documetsupload`,formData).then((res: AxiosResponse) => {
            setResume(res.data[0].resume);
            setChildern(res.data[0].children_certificate);
            setCriminal(res.data[0].criminal_record);
            setLoader(0);
            showNotification("success", Lang.document_upload);
        })
    }

    //  Contract upload handler
    const contractUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        setLoader(1);
        let formData = new FormData();
        formData.append('contract_pdf_file', event.target.files[0]);
        const url = URL.createObjectURL(event.target.files[0]);
        await API.post(`users/translator/upload-contract/${props.transInfo.id}`, formData);
        setContractURL(url);
        setLoader(0);
      } catch (err) {
        showNotification('danger', err.message ?? 'Something went wrong.');
      }
    }

  return(
     <Modal
          {...props}
          //size=""
          aria-labelledby="contained-modal-title-vcenter"
      >
        <form >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {Lang.documents}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                {Loader === 1 &&
                    <div className="alert alert-solid-brand" role="alert">
                        <div className="alert-icon"><i className="flaticon-warning"/></div>
                        <div className="alert-text">Please wait...</div>
                    </div>
                }
              <div className={'row'}>
              <div className={"col-md-12 col-lg-12"}>
                {/* C.V */}
                 <div className="form-group">
                     <label>{Lang.CV} :</label>
                     <div></div>
                     <div className="custom-file">
                            <input type={'file'} name={'resume'} accept={"pdf,doc,docx"} className="custom-file-input"  onChange={(e)=>DocumentUpload(e)} />
                         <label className="custom-file-label" htmlFor="customFile"/>
                     </div>
                     {Resume !== null && ! _.isEmpty(Resume) &&
                        <a href={Resume} target="_blank">View {Lang.CV}</a>
                     }
                 </div>
                 {/* Criminial Record */}
                 <div className="form-group">
                     <label>{Lang.criminal_record} :</label>
                     <div></div>
                     <div className="custom-file">
                         <input type={'file'} name={'criminal_record'} accept={"pdf,doc,docx"}   className="custom-file-input"  onChange={(e)=>DocumentUpload(e)}/>
                         <label className="custom-file-label" htmlFor="customFile"/>
                      </div>
                     {Criminal !== null && ! _.isEmpty(Criminal) &&
                        <a href={Criminal} target="_blank">View {Lang.criminal_record}</a>
                     }
                 </div>
                 {/* Child  certificate*/}
                 <div className="form-group">
                     <label>{Lang.børneattest}:</label>
                     <div></div>
                     <div className="custom-file">
                         <input type={'file'}  name={'children_certificate'} accept={"pdf,doc,docx"}  className="custom-file-input"  onChange={(e)=>DocumentUpload(e)}/>
                         <label className="custom-file-label" htmlFor="customFile"/>
                     </div>
                     {Childern !== null  && ! _.isEmpty(Childern) &&
                        <a href={Childern} target="_blank" rel="noopener noreferrer">View {Lang.børneattest}</a>
                    }
                 </div>
                 {/*Contract Upload*/}
                 <div className="form-group">
                     <label>Kontrakt:</label>
                     <div></div>
                     <div className="custom-file">
                         <input type={'file'}  name={'children_certificate'} accept={"pdf,doc,docx"}  className="custom-file-input"  onChange={contractUpload}/>
                         <label className="custom-file-label" htmlFor="customFile"/>
                     </div>
                     {ContractURL !== '' &&
                        <a href={ContractURL} target="_blank"  rel="noopener noreferrer">View Kontrakt</a>
                    }
                 </div>
             </div>
               </div>
            </div> 
            
          </Modal.Body>
          
        </form>
      </Modal>
  )
};