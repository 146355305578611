import * as React from 'react';


export const FormElementGroup = (props: any) => {
  return (
    <div className={props.className !== undefined ? props.className : "col-md-6 col-lg-6"}>
      <div className="form-group">
        {props.children}
      </div>
    </div>
  )
}