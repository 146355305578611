import * as React from 'react';
import {SearchInput, SearchSelect} from "../../common/form/SearchInput";
import {IState as CommonState,} from "../../common/State";
import {ShowRecords} from "../../common/ShowRecords";
import {CONST, ExportButton, getQueryStringParams} from "../../common/Common";
import {Icons} from "../../common/Icon";
import {actionButton} from "../../common/table/BlukActionButtonsEmailSms";
import {RouteComponentProps} from "react-router";
import {EmailModel} from "../../model/Email";
import {ListBreadcrumb} from './Data';
import {ActionLink, CreateLink, EditLink,GeneratePermitLink} from "../../common/page/CreateButton";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {default as Lang} from "../../common/lang/Lang";
import {SeeNewletterService} from "../../services/NewletterServices";
import {SeeEmail} from "./popup/EmailPopup";
import {CustomerInfo} from "../../users/customers/CustomerInfo";
import {TranslatorInfo} from "../../users/translators/TranslatorInfo";
import {UserModel} from "../../model";
import EmailFilter from './filter/EmailFilter';
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";



interface IState extends CommonState{
  UserData: Array<EmailModel>;
  CustomerModalData?: UserModel;
  ModalData?: UserModel;
  Customershow?: boolean;
  Translatorshow?: boolean;
 
}


export class EmailListing extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }


  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

  UserByRole = (data) => {
 
    if(data.users !== null){
      if(data.users.role_id === 2 ) {
      return <GeneratePermitLink scope={'customers.index'} to={'#'} onClick={() => this.setState({CustomerModalData: data.users, Customershow: true})}>
     {( data.users !== null ) ? data.users.name : '--'}
      </GeneratePermitLink>
      } else{
         
    return  <GeneratePermitLink scope={'translator.index'} to={'#'} onClick={() => this.setState({ModalData: data.users, Translatorshow: true})} title={Lang.translator_info}>
      {( data.users !== null ) ? data.users.name : '--'}
       </GeneratePermitLink>
      }

    } else{
     return data.user_email;
    }
   
  }

   

    showContent = (data: EmailModel, index: number) => {
         let cols = [];
       cols.push(...[
            <td key={index}>{this.records.current.checkbox(data.id)}</td>,
            this.records.current.createdTd("id", index),
           
            this.records.current.createdTd("created_at", data.created_at),
            this.records.current.createdTd("subject", data.subject),
            this.records.current.createdTd("recipient", () =>{
              return (
                this.UserByRole(data)
               )  
              }),
              this.records.current.createdTd("mail_template", () => {
                return (<button type={'button'} className="btn btn-warning" onClick={() => SeeNewletterService.showPopup(data)}> {Lang.see_mail} </button>)
              }),
         ]);
        cols = _.compact(cols);

        return (
            <tr key={list_key(data.id)}>{cols}</tr>
        );
    
  }

  



  render() {
   
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'email'} icon={Icons.email} >
            <ExportButton records={this.records} fileName={Lang.exportEmail}/>
            &nbsp;
            
            <GeneratePermitLink scope={'email.store'} to={`${this.props.match.url}/create`}  className={'btn btn-brand btn-elevate btn-icon-sm'} title={Lang.send_email} >
            <i className={Icons.add} />{Lang.send_email}
                            </GeneratePermitLink>
  

          </ListHeader>

          <ListForm
            form={(
              <EmailFilter getResults={this.filterRecords} />
          )}

            table={(

              <ShowRecords
                  url={"email/email"}
                  showRecords={this.showContent}
                  ref={this.records}
                isNeedAction={true}
                  actionButtons={actionButton({records: this.records, 'url': "email/bulk-action"})}
              />
          )}
          />
          {
          this.state !== null &&
           <CustomerInfo
          show={this.state.Customershow}
          onHide={() => this.setState({Customershow: false})}
          userinfo={this.state.CustomerModalData}
        />
  }
  {
          this.state !== null &&
        <TranslatorInfo
          show={this.state.Translatorshow}
          onHide={() => this.setState({Translatorshow: false})}
          UserInfo={this.state.ModalData}
        />
          }
          <SeeEmail />
        </ListingLayout>
    )
  }
}