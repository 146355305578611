import React from 'react';
import {LangStatus, TranslationTypeModel, TranslateLanguageModel} from "../../model";
import {TranslatortypeBreadcrumb, LanguageBreadcrumb, ServicesBreadcrumb} from "./Data";
import Lang from "../../common/lang/Lang";
import {Breadcrumb, Select2Wrapper, LoaderType, SwitchLoader, showConfirmBox, SubmitButton,showNotification, GeneratePermitLink, Icons, PleaseWait, NoRecords} from "../../common";
import {languageAddService, getTranslatorLanguage, languageActions,languageUpdateService,languagelistService} from "../../services";
import {RouteComponentProps} from "react-router";
import {get_trans_lang_status} from "../../helpers/UserHelper";
import {LanguageUpdate} from "./popup/LanguageUpdate";
import API from './../../common/AxoisClient';
import * as _ from 'lodash';

declare var window: any;

export class Translatortype extends React.Component<RouteComponentProps> {
  state: {
     
    is_loading: boolean;
    types:Array<any>;
    translator_type:Array<any>;
    selectallIds : Array<any>;
    translators : Array<any>;
    userIds:Array<any>;
    id:number;
    languages: Array<TranslateLanguageModel>;
    lang_status : number;
    exp_scale?: number;
    push_agreement?: number;
  };
  constructor(props){
    super(props);
    this.state = {
      id: props.match.params.id,
      is_loading: true,
      types:[],
      translator_type : [],
      selectallIds:[],
      translators: [],
      userIds:[],
      languages: [],
      lang_status : 0,
      exp_scale: 0,
      push_agreement: 0
    }
  }

  componentDidMount() {
    const {id}: any = this.props.match.params;
    getTranslatorLanguage(id).then(res => {
      this.setState({
        languages: res.translator.translator_language,
        is_loading: false
      })
    })
    API.get(`users/translator/translator-language-status-get/${id}`).then(response => {
       this.setState({
      lang_status: response.data[0].is_sign_lang_translation,
     })
    })

    languagelistService.getPopupState().subscribe((res:any) => {
      getTranslatorLanguage(id).then(res => {
        this.setState({
          languages: res.translator.translator_language,
          is_loading: false
        })
      })
    })
    API.get(`users/translator/translator-types`).then((res: any) => {
      this.setState({
        types: res.data,
       })
     });

     API.get(`users/translator/translator-get-type/${this.state.id}`).then((res: any) => {
       this.setState({
         translator_type: res.data,
       })
     });

     API.get(`users/translators/${this.state.id}`).then(res => {
        const translatorData = res.data.translatorArr.translator;
        this.setState({
          exp_scale : translatorData.exp_scale,
          push_agreement : translatorData.push_agreement
        });
     });
  }

  handleCheckboxChange =( event : React.ChangeEvent<any>) => {
    if(event.target.checked === true) {
      if(event.target.name=='push_agreement'){
          this.setState({
            push_agreement :1
          })
        } else {
          this.Updatelanguage(1)
        }
      } else {
        if(event.target.name=='push_agreement'){
          this.setState({
            push_agreement :0
          })
        } else {
          this.Updatelanguage(0)
        }
      }
  }

  Updatelanguage(is_sign_lang_translation){
    const {id}: any = this.props.match.params;
    API.post(`users/translator/translator-language-status`,{is_sign_lang_translation:is_sign_lang_translation,id:id}).then(response => {
      
      this.setState({
        lang_status :is_sign_lang_translation
      })
    })
  }

  updateStatus = (status, id) =>{
    const ___this = this;
    const msg = status === 1 ? "Are you sure that you want to approve this language?" :  "Are you sure that you want to reject this language?";
    showConfirmBox(msg, function () {
      languageActions({status: status, id: id}).then(res => {
        showNotification('success', res);
        ___this.setState( {
          languages: ___this.state.languages.filter(item => {
            if(item.id === id){
              item.status = status;
            }
            return item
          })
        });
      })
    })
  };

  deleteLanguage = (status, id) => {
    const ___this = this;
    const msg = "Are you sure that you want to delete this language?";
    showConfirmBox(msg, function () {
      languageActions({status: status, id: id}).then(res => {
        showNotification('success', res);
        ___this.setState( {
          languages: ___this.state.languages
        });
      })
    })
  }

  
  handleChange = async (event: React.ChangeEvent<HTMLElement>) => {
      const target : any = event.target;
      let value =  target.value;
      const name = target.name;

      if(name === 'userIds'){
          let userIds = this.state.translator_type;
          if(_.indexOf(userIds, Number(value)) !== -1 ){
            userIds = _.pull(userIds, Number(value));
          } else {
            userIds = _.concat(userIds, Number(value));
          }

          this.setState({
            translator_type :_.compact(userIds)
          });
        }

        if(name !== 'select_location'){
            if(name === 'is_certified' && value == 0){
                this.setState({
                    'children_certificate': '',
                    'children_certificate_filename': '',
                    'filePath': ''
                } as any);
            }
            this.setState({
                [name]: value
            } as any);
        } else {
            var location_value = $('#select_location').val();
            this.setState({
                'location': location_value
            } as any);
        }
   
  }


    formDataReset = (e: any) => {
        e.preventDefault();
        this.setState({
            types:[],
            translator_type : [],
            selectallIds:[],
            translators: [],
            userIds:[],
            languages: [],
            lang_status : 0,
            exp_scale: 0,
            push_agreement: 0
        });
    }
 
  

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
      API.post(`/users/translator/translator-type-update`,{translator_id:this.state.id,translation_type:this.state.translator_type,exp_scale: this.state.exp_scale,push_agreement: this.state.push_agreement}).then((res) => {
        showNotification("success", Lang.translation_type_updated);
      })
  }

  render() {
    const {id}: any = this.props.match.params;
    return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
     <Breadcrumb data={ServicesBreadcrumb}/>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="row">
          <div className="col-xl-12">
            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">{Lang.translation_type}</h3>
                </div>
              </div><br/>
              <form className="kt-form"  onSubmit={this.handleSubmit}  id={'update_notification'}>
                <div className="kt-portlet__body">
                  <div className={'row'}>
                    <div className={"col-md-12 col-lg-12"}>
                      <div className="form-group">
                          <label>{Lang.exp_scale_title} <span className={'required'}>*</span></label>&nbsp;&nbsp;
                          <div className="kt-radio-inline">
                              <label className="kt-radio">
                                  <input type="radio" id="exp_scale"
                                         name="exp_scale"
                                         onChange={this.handleChange} checked={this.state.exp_scale==1} value={1}
                                  /> {Lang.average}
                                  <span></span>
                              </label>&nbsp;&nbsp;

                              <label className="kt-radio">
                                  <input type="radio" id="exp_scale"
                                         name="exp_scale"
                                         onChange={this.handleChange} checked={this.state.exp_scale==2} value={2}
                                  /> {Lang.expensive}
                                  <span></span>
                              </label>&nbsp;&nbsp;

                              <label className="kt-radio kt-radio-edit">
                                  <input type="radio" id="exp_scale"
                                         name="exp_scale"
                                         onChange={this.handleChange} checked={this.state.exp_scale==3} value={3}
                                  /> {Lang.very_expensive}
                                  <span></span>
                              </label>
                          </div>
                      </div>
                    </div>
                    <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                            <label className="kt-checkbox">
                                <input type="checkbox" id="push_agreement" value={1} name="push_agreement" onChange={this.handleCheckboxChange}  checked={this.state.push_agreement>0} /><label>
                                {Lang.push_agreement} </label> &nbsp;<img alt="Logo" src={`${window.PUBLIC_URL}/admin/assets/media/icons/push_agreement.png`} />
                                <span></span>
                            </label>
                        </div>
                    </div>
                    <div className={"col-md-12 col-lg-12"}>
                      <div className="form-group">
                      {this.state.types.map((item: TranslationTypeModel, index: number) => {
                         
                         return (
                          <label key={item.id} className="kt-checkbox mr-3">
                            <input type ="Checkbox" onChange={this.handleChange} name={'userIds'} value={item.id} 
                            checked={_.indexOf(this.state.translator_type, item.id) > -1}  />{item.title}
                            <span></span>
                          </label>
                         )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="kt-portlet__foot">
                  <div className="kt-form__actions">
                    <div className="row">
                      <div className="col-lg-6">
                      </div>
                      <div className="col-lg-6 kt-align-right">
                      {/*<button className={'btn btn-default hvr-rectangle-out btnMove reset-btn'} data-skin={'dark'} title={Lang.reset} onClick={(e)=>this.formDataReset(e)}>{Lang.reset}</button>&nbsp;*/}
                      <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="kt-portlet kt-portlet--mobile">
        <div className="row">
          <div className="col-md-12">
            <div className="kt-portlet">
              <div className="row">
                <div className="col-md-12">
                  <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">
                        {Lang.languages}
                      </h3>&nbsp;&nbsp;
                      <input type="button" className="btn btn-primary" value={Lang.add_more} onClick={() => {languageAddService.showPopup(id)}}/>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <span className="kt-switch">
                          <label>
                            <input type={'checkbox'}  name="is_sign_lang_translation" checked={this.state.lang_status === 1} onChange={this.handleCheckboxChange} />
                            <span/>
                             {Lang.is_sign_translation}
                          </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              
              <br/>
              <div className="kt-portlet__body">
                <div className={'kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded'}>
                  {!this.state.is_loading && this.state.languages.length == 0 && <NoRecords/>}
                  {this.state.is_loading && <PleaseWait/>}
                  { !this.state.is_loading &&  this.state.languages.length > 0 &&
                    <table className={'table'}>
                      <tbody>
                      <tr>
                        <th>#</th>
                        <th>{Lang.from_language}</th>
                        <th>{Lang.to_language}</th>
                          <th>{Lang.action}</th>
                      </tr>
                      {
                        this.state.languages.map((item: TranslateLanguageModel, index: number) => {
                         
                          return (
                            <tr key={item.id}>
                              <td>{++index}</td>
                              <td>
                                { !_.isEmpty(item.from_language) &&  item.from_language.language_name }
                                <p>{item.is_certificate === 1 && <a href={item.certificate} target={'_blank'}> View Certificate</a> }</p>
                                <span className="badge badge-info">{item.mother_tongue === 1 ? Lang.mother_tongue :''}</span>
                              </td>
                              <td>{!_.isEmpty(item.to_language) &&  item.to_language.language_name}
                                <p>{item.is_certificate === 1 && <a href={item.certificate} target={'_blank'}> View Certificate</a> }</p>
                                <p>{item.mother_tongue === 2 ? Lang.mother_tongue :''}</p>
                              </td>
                               <td>
                                {get_trans_lang_status(item)}
                                { item.status === LangStatus.PENDING &&
                                  <div style={{marginTop: 5}}>
                                    <GeneratePermitLink className="btn btn-sm btn-success btn-icon btn-icon-md" scope={''} to={'#'} onClick={() => this.updateStatus(1, item.id)}>
                                      <i className={Icons.tick_mark}/>
                                    </GeneratePermitLink>
                                    <GeneratePermitLink className="btn btn-sm btn-danger btn-icon btn-icon-md" scope={''} to={'#'} onClick={() => this.updateStatus(3, item.id)}>
                                      <i className={Icons.cross}/>
                                    </GeneratePermitLink>
                                  </div>
                                }

                                { item.status === LangStatus.ACTIVE &&

                                  <div style={{marginTop: 5}}>
                                    <GeneratePermitLink className="btn btn-sm btn-danger btn-icon btn-icon-md" scope={''} to={'#'} onClick={() => this.updateStatus(3, item.id)}>
                                      <i className={Icons.cross}/>
                                    </GeneratePermitLink>
                                  </div> 
                                }

                                { item.status === LangStatus.REJECT &&

                                  <div style={{marginTop: 5}}>
                                    <GeneratePermitLink className="btn btn-sm btn-success btn-icon btn-icon-md" scope={''} to={'#'} onClick={() => this.updateStatus(1, item.id)}>
                                      <i className={Icons.tick_mark}/>
                                    </GeneratePermitLink>
                                  </div> 
                                }
                                <GeneratePermitLink className="btn btn-sm btn-clean btn-icon btn-icon-md" scope={''} to={'#'} onClick={() => {this.deleteLanguage(2, item.id)}}>
                                  <i className={Icons.delete}/>
                                </GeneratePermitLink>
                                <GeneratePermitLink className="btn btn-sm btn-clean btn-icon btn-icon-md" scope={''} to={'#'} onClick={() => {languageUpdateService.showPopup(item)}}>
                                  <i className={Icons.edit}/>
                                </GeneratePermitLink>
                     </td>

                            </tr>
                          )

                        })
                      }
                      </tbody>

                    </table>
                  }
                </div>
              </div>
            </div>
            <LanguageUpdate transInfo={this.props.match.params}/>
          </div>

        </div>
      </div>
    </div>
    )
  }
}