import * as React from 'react';
import Lang from "../../common/lang/Lang";
import {EditBreadcrumb} from "./Data";
import API from './../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {LanguageValues} from "../../model";
import {LanguageValidations} from "./Validation";
import {LoaderType, showNotification, SubmitButton,SwitchLoader, CountryFlags, Select2Wrapper, Breadcrumb} from "../../common";

declare var window: any;

interface IState{
  form: {
  country_id? : any,
  language_name? : string,
  country_code?: any,
  };
countrycodes: Array<any>;
  id:number;

}
export class LanguageEdit extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props: any){
    super(props);

    this.state = {
      form: LanguageValues(),
      id: props.match.params.id,
      countrycodes: [],
    }
    LanguageValidations();
  }
   
 
  componentDidMount() {
      API.get("settings/get-countries").then(res => {
      this.setState({
        countrycodes: res.data.countryCodeArr
      }, () => {
          ($(".selectpicker") as any).selectpicker('refresh');
      });
      
    });
    
   
    API.get(`settings/language/${this.state.id}`).then((res: any) => {
      this.setState({
        form: LanguageValues(res.data[0])
      })
    })

  }

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

  handleSelectChange = (event : React.ChangeEvent<HTMLElement>) => {
    const targetSelect : any = event.target;
    const value = targetSelect.value;
    this.setState({ form: { ...this.state.form, country_id: value} });
   
}

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#admin_user_form").valid()) {
      API.put(`settings/language/${this.state.id}`, this.state.form, {params: {from: 'info'}}).then((res) => {
        showNotification("success", Lang.language_updated);
        this.props.history.push(`/languages/language`);
      })
    }
  }
  

  render() {
    
    return (
    
      <div>
        
         <Breadcrumb data={EditBreadcrumb}/>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
               
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                  <form className="kt-form" id="admin_user_form"  onSubmit={this.handleSubmit}>
                    <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                      <div className="kt-heading kt-heading--md">{Lang.edit_language}</div>
                      <div className="kt-form__section kt-form__section--first">
                        <div className="kt-wizard-v2__form">


                              <div className="form-group">
                                <label>{Lang.country} <span className={'required'}>*</span></label>
                                
                              
                                <Select2Wrapper value={this.state.form.country_id} onChange={this.handleSelectChange}
                                        className={'form-control'} id={'country_id'} name={'country_id'}
                                        data={{
                                            placeholder: "Select Options",
                                            data: this.state.countrycodes,
                                            templateResult: CountryFlags,
                                            templateSelection: CountryFlags,
                                        }}
                                   />
                              </div>

                              <div className="form-group">
                                <label>{Lang.language} <span className={'required'}>*</span></label>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.language_name} name={'language_name'} className="form-control" placeholder={Lang.getString(`name_placeholder`)}/>
                              </div>

                             
                        </div>

                        <div className="kt-form__actions">
                          <div className="kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                          <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}