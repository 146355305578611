import * as React from 'react';
import Lang from "../../common/lang/Lang";



export const CallInfo = (props?: any) => {
 
  if(props.info.call_log.length > 0){
  return (
      
          <div className="kt-portlet kt-portlet--height-fluid">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title kt-font-success">
                   {Lang.call_log}
                </h3>
              </div>               
            </div>
            
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
              <div className={'col-md-12'} >
               
                    <div className={'col-md-12'}>
                      <table className={'table table-bordered table-hover'}>
                        <tbody>
                        <tr>
                          <th>#</th>
                          <th>{Lang.call_start_time}</th>
                          <th>{Lang.call_end_time}</th>
                          <th>{Lang.call_status}</th>
                          <th>{Lang.total_call_time}</th>
                        </tr>
                        { props.info.call_log.map((item, key) => {
                  
                        return  <tr key={item.id}>
                             <td>{++key}</td>
                            <td>{item.start_time}</td>
                            <td>{item.end_time}</td>
                            <td>{item.reason}</td>
                            
                            <td>{item.status === 2 ? item.total_call_time : '--'}</td>
                          </tr>
                        })
                      } 
                      
                        </tbody>

                      </table>
                    </div>
                  
               

              </div>
            </div>
          </div>
          </div>
          
       
  )
  } else{
 //  return  <div className="alert alert-outline-warning fade show" >Call logs not found </div> 
     return null
  }

}