import React, {useEffect, useRef} from 'react';
import {DataFormat, GroupedDataFormat, OptionData, Options} from "select2";
import _ from "lodash";
declare let window:any;

export interface Select2Props  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  data: Options
}

export function  Select2Wrapper (props: Select2Props) {
  const ele = useRef(null);
  useEffect(() => {
    let $el = ele.current;
    if($el !== null){
      let $els = $($el);
      $els.empty();
      $els.select2(props.data !== undefined ? props.data : {})
        .off( 'select2:select')
        .on("select2:select", (event: any) => {
        if(props.onChange !== undefined )
          props.onChange(event)
      });
      if(props.value !== undefined)
        $els.val(props.value).trigger('change.select2');
    }
  }, [props, props.data.data, props.value]);

  const {data, onChange, value, ...rest} = props;
    return(
      <div>
        <select ref={ele} {...rest}>
          {props.children}
        </select>
      </div>
    )
}

export function LanguageFlags(state: any) {
   
  if (!state.id || state.country === null || state.country === undefined) {
    return state.text;
  }
  return $(
    '<span><img src="'+ window.PUBLIC_URL +'/admin/assets/flags/'+_.lowerCase(state.country.iso) +'.png" class="img-flag" /> ' + state.text + '</span>'
  );
}

export function CountryFlags(state: any) {
  if (!state.id) {
    return state.text;
  }
  return $(
    '<span><img src="'+ window.PUBLIC_URL +'/admin/assets/flags/'+(state.iso as string).toLowerCase() +'.png" class="img-flag" /> ' + state.text + '</span>'
  );
}