import * as React from 'react';
import Lang from "../../../common/lang/Lang";
import * as _ from 'lodash';
import {Stars} from "../../../common/Stars";
 
export const OrderFeedbackInfo = (props?: any) => {
  if(!_.isEmpty(props.info.feedback)) {
    return (
      <div className="col-xl-4">
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-success">
               {Lang.order_feedback}
            </h3>
          </div>               
        </div>
        <div className="kt-portlet__body">
          <div className="kt-widget4">
            <div className="kt-widget4__item">                   
              <span className="kt-widget4__title kt-widget4__title--light">
                 {Lang.message}
              </span>
              <span className="kt-widget4__number kt-font-info">{props.info.feedback.message}</span>
            </div>
            {
              props.info.feedback.rating !== null && props.info.feedback.rating > 0 &&
              <div className="kt-widget4__item">
                <span className="kt-widget4__title kt-widget4__title--light">
                   {Lang.rating}
                </span>
                <Stars   value={props.info.feedback.rating} />
              </div>
            }

        </div>
        </div>
      </div>
    </div>     
  
    )
  }
  return null
}