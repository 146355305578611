import React, {useEffect, useReducer, useRef} from 'react';
import { completeEditRequest, OrderCorrectionService} from "../../../services";
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {
  InitialState, PopupState, ActionType,
  CAction, CorrectionReducer
} from "../order-reducers/CorrectionReducer";
import {RequestWaiting, showNotification, SubmitButton, WarningDiv} from "../../../common";

export function CorrectionRequestPopup () {

  const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(CorrectionReducer , InitialState);

  useEffect( () => {
    OrderCorrectionService.getPopupState().subscribe((message:any) => {
      dispatch({type: message.action, payload: message}) ;
    });
  }, []);

  if(state.show === false){
    return null;
  }

  const {order_data} = state;

  function completeOrder(e){
    e.preventDefault();
    completeEditRequest(state.order_data.id, {type: state.type}).then((res:any) => {
      showNotification('success', res);
      OrderCorrectionService.hidePopup();
    })
  }
  let i:number = Number(($(state.history).length)-1);

  return(
    <Modal
      show={state.show}
      onHide={() => { dispatch({type: ActionType.HIDE_POPUP}) } }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >

        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {state.type === 'edit' ? Lang.correction_request : Lang.cancel_request}{} - #{order_data.order_no}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={completeOrder} id="order_comments">
          <Modal.Body>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
              <div className={'row'}>
                <RequestWaiting item={state.history}>
                  {
                    state.history.length > 0  &&
                    <div className={'col-md-12'}>
                      <table className={'table table-bordered table-hover'}>
                        <tbody>
                        <tr>
                          <td>#</td>
                          <td>{Lang.message}</td>
                          <td>{Lang.requestStatus}</td>
                          <td>{Lang.created_at}</td>
                        </tr>
                        {state.history.map((item: any, index: number) => {
                           
                          return (
                            <tr key={item.id}>
                              <td>{++index}</td>
                              <td>{state.type === 'edit' ? item.edit_order_message : item.reason}</td>
                              {state.type === 'edit' &&
                              <td>
                                {Number(item.status) === 0 &&
                                <span className={'badge badge-danger'}>
                                    {Lang.status_new_edit_request}
                                </span>
                                }
                                {Number(item.status) === 1 &&
                                <span className={'badge badge-success'}>
                                    {Lang.status_completed_edit_request}
                                </span>
                                }
                                {Number(item.status) === 2 &&
                                <span className={'badge badge-warning'}>
                                  {Lang.status_rejected_edit_request}
                                </span>
                                }
                              </td>
                              }
                              {state.type !== 'edit' &&
                              <td>
                                {Number(item.status)===0&&
                                    <span className={'badge badge-danger'}>
                                        {Lang.status_new_cancel_request}
                                    </span>
                                }
                                {Number(item.status)===1&&
                                <span className={'badge badge-success'}>
                                  {Lang.status_completed_cancel_request}
                                </span>
                                }
                                {Number(item.status)===2&&
                                  <span className={'badge badge-warning'}>
                                  {Lang.status_rejected_cancel_request}
                                  </span>
                                }
                              </td>
                              }
                              <td>{item.created_at}</td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </table>
                    </div>
                  }
                </RequestWaiting>

              </div>
            </div>
          </Modal.Body>
          {state.history.length > 0 && Number(state.history[i].status) === 0 &&
            <Modal.Footer>
               <SubmitButton className={'btn btn-primary completeCorrectionRequest'} label={Lang.completeRequest}/>
            </Modal.Footer>
          }
        </form>
    </Modal>
  )
}