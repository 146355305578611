import * as React from 'react';
import {SearchInput} from "../../common/form/SearchInput";
import {IState as CommonState} from "../../common/State";
import {ShowRecords} from "../../common/ShowRecords";
import {getQueryStringParams} from "../../common/Common";
import {Icons} from "../../common/Icon";
import {actionButton} from "../../common/table/BlukActionButtonsEmailSms";
import {RouteComponentProps} from "react-router";
import {CallLogModel} from "../../model/CallLog";
import {ListBreadcrumb} from './Data';
import {OrderInfo} from "../../orders/orders/OrderInfo";
import {CustomerInfo} from "../../users/customers/CustomerInfo";
import {TranslatorInfo} from "../../users/translators/TranslatorInfo";
import { OrderModel,UserModel } from "../../model";
import {ActionLink, CreateLink, EditLink, GeneratePermitLink} from "../../common/page/CreateButton";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {default as Lang} from "../../common/lang/Lang";
import CalllogFilter from './filter/CalllogFilter';
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";


interface IState extends CommonState{
  UserData: Array<CallLogModel>;
   
  ModalData?: OrderModel;
  CustomerModalData?: UserModel;
  TranslatorModalData?: UserModel;
  show?: boolean
  Customershow?: boolean;
  Translatorshow?: boolean;
}


export class CallLogListing extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

 
  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

  UserSender = (data) => {
   if(data.sender !== null && data.sender.role_id === 2 ) {
    
       return <GeneratePermitLink scope={'customers.index'} to={'#'} onClick={() => this.setState({CustomerModalData: data.sender, Customershow: true})}>
          {( data.sender !== null ) ? data.sender.name : '--'}
        </GeneratePermitLink>
    } else {
      
        return  <GeneratePermitLink scope={'translator.index'} to={'#'} onClick={() => this.setState({TranslatorModalData: data.sender, Translatorshow: true})} >
          {( data.sender !== null ) ? data.sender.name : '--'}
           </GeneratePermitLink>
          }
  }

  UserRecevier = (data) => {
   
    if(data.receiver.role_id === 2 ) {
    
       return <GeneratePermitLink scope={'customers.index'} to={'#'} onClick={() => this.setState({CustomerModalData: data.receiver, Customershow: true})}>
          {( data.receiver !== null ) ? data.receiver.name : '--'}
        </GeneratePermitLink>
    } else{
      
        return  <GeneratePermitLink scope={'translator.index'} to={'#'} onClick={() => this.setState({TranslatorModalData: data.receiver, Translatorshow: true})} >
          {( data.receiver !== null ) ? data.receiver.name : '--'}
           </GeneratePermitLink>
          }
  }

    showContent = (data: CallLogModel, index: number) => {
   
        let cols = [];
        cols.push(...[
             /*<td key={index}>{this.records.current.checkbox(data.id)}</td>,*/
            this.records.current.createdTd("id", index),
            this.records.current.createdTd("order_id", () => {
            return (
              <div>
                 <ActionLink scope={'orders.index'} onClick={() => this.setState({ModalData: data.order, show: true})} to={'#'}  data-skin={'dark'} title={Lang.view_order} >
                {data.order.order_no} 
                </ActionLink>
                </div>
             )
             }),
             this.records.current.createdTd("sender_id", () =>{
              return (
                this.UserSender(data)
               )  
              
              }),
              this.records.current.createdTd("receiver_id", () =>{
                return (
                  this.UserRecevier(data)
                 )  
                
                }),
          
           this.records.current.createdTd("start_time", data.start_time),
           this.records.current.createdTd("end_time", data.end_time),
           this.records.current.createdTd("total_call_time",data.status === 2 ? data.call_log[0].total_call_time : '--'),
           this.records.current.createdTd("message", data.reason),
          
        ]);
        cols = _.compact(cols);

        return (
            <tr key={list_key(data.id)}>{cols}</tr>
        );
    
  }

 



  render() {

    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'call_log'} icon={Icons.activity_log} >
           
          </ListHeader>

          <ListForm
            form={(
              <CalllogFilter getResults={this.filterRecords} />
          )}

            table={(

              <ShowRecords
                  url={"systemInfo/calllog"}
                  showRecords={this.showContent}
                  showCheckbox={false}
                  ref={this.records}
                isNeedAction={true}
                  actionButtons={actionButton({records: this.records, 'url': "systemInfo/calllog/bulk-action"})}
              />
              
          )}
          />
           { this.state !== null &&
          <OrderInfo
              show={this.state.show}
              onHide={() => this.setState({show: false})}
              orderinfo={this.state.ModalData}/>
         }
         { this.state !== null &&
          <CustomerInfo
          show={this.state.Customershow}
          onHide={() => this.setState({Customershow: false})}
          userinfo={this.state.CustomerModalData}
        />
         }
         { this.state !== null &&
        <TranslatorInfo
          show={this.state.Translatorshow}
          onHide={() => this.setState({Translatorshow: false})}
          UserInfo={this.state.TranslatorModalData}
        />
         }
        </ListingLayout>
    )
  }
}