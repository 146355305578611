import React from 'react';
import _ from 'lodash';


export function Stars(props) {
  return (
    <div id="rating">
      <div className="stars">
        {
          _.range(1, 6).map((item, index) => {
            return (
              <React.Fragment key={index}>
                <input type={'radio'}
                      defaultChecked={item === parseInt(props.value)} className={`star-${item}`} id={`star-${item}`} {... _.omit(props, ['value', 'className', 'id'])} />
                <label  className={`star-${item}`} htmlFor={`star-${item}`} >{item}</label>
              </React.Fragment>
            )
          })
        }
        <span/>
      </div>
    </div>
  )
}