import React from 'react';

interface Lang {
  iso?: string
  name: string
}

interface Props {
  from: Lang
  to: Lang
}

const LanguagePair: React.FC<Props> = (props) => {
  const {from, to} = props;
  const resolveImageSrc = (iso) => (window as any).PUBLIC_URL + '/admin/assets/flags/' + iso + '.png';
  return (
    <>
      {
        from.iso && <img src={resolveImageSrc(from.iso)} alt={from.name} className="img-flag"/> /* country flag */
      }
      &nbsp; {from.name} {/* country name */}
      <span className="exchangeIcon"><i className="fas fa-exchange-alt"/></span> {/* from to arrow */}
      {
        to.iso && <img src={resolveImageSrc(to.iso)} alt={to.name} className="img-flag"/> /* country flag */
      }
      &nbsp; {to.name} {/* country name */}
    </>
  );
}

export default LanguagePair;
