import React, { useEffect, useRef, useState } from 'react'
import { base_url, Icons } from '../../common'
import CurrentJob from './CurrentJob/CurrentJob'
import NextJob from './NextJob/NextJob'

//? Layout
import { ListHeader, ListingLayout } from '../../layout/Listing'
import { OrderInvoicingBreadcrumb } from '../orders/Data'

//? Styling
import sheet from './Invoicing.module.scss'
import JobsTable from './JobsTable/JobsTable'
import { allJobs, currentJob, nextJob, Statistics as SType } from './InvoicingType'
import Axios from 'axios'
import Statistics from './Statistics/Statistics'
import NoNextJob from './NextJob/NoNextJob'
import NoCurrentJob from './CurrentJob/NoCurrentJob'

export enum COLOR_PALETTE {
  green = '#03C3AB',
  blue = '#245EE7',
  red = '#FF4343',
  grey = '#9895B9',
  orange = '#FFAB43'
}

export interface InvoicingData {
  currentJob?: currentJob
  nextJob?: nextJob
  statistics: SType
  allJobs: {
    current_page: number
    last_page: number
    data: allJobs
    per_page: number
    total: number
  }
}

const updateInvoicingData = (setInvoicingData: React.Dispatch<React.SetStateAction<InvoicingData>>, page: number = 1) => async () => {
  const data = (await Axios({ baseURL: base_url, url: 'api/economics/invoicing', params: { page } })).data as InvoicingData
  setInvoicingData(data)
}

const Invoicing = () => {
  const [InvoicingData, setInvoicingData] = useState<InvoicingData>(null)
  useEffect(() => {
    updateInvoicingData(setInvoicingData)()
    const interval = setInterval(updateInvoicingData(setInvoicingData), 10 * 1000)
    return () => clearInterval(interval)
  }, [])
  const handlePageChange = (page: number) => updateInvoicingData(setInvoicingData, page)()
  return (
    <ListingLayout breadcrumb={OrderInvoicingBreadcrumb}>
      <ListHeader title={'order_invoicing_title'} icon={Icons.receipt} />
      <div className={sheet.container}>
        <div className={sheet.running_job}>
          <SectionTitle title='Nuværende job' color={COLOR_PALETTE.green} icon={Icons.hardhat} />
          {InvoicingData?.currentJob ? <CurrentJob currentJob={InvoicingData.currentJob} /> : <NoCurrentJob />}
        </div>
        <div>
          <SectionTitle title='Næste job' color={COLOR_PALETTE.blue} icon={Icons.next} />
          {InvoicingData?.nextJob ? <NextJob nextJob={InvoicingData.nextJob} /> : <NoNextJob />}
        </div>
        <div>
          <SectionTitle title='Statistik' color='black' icon={Icons.chart} />
          {InvoicingData?.statistics && <Statistics statistics={InvoicingData.statistics} />}
        </div>
        <div className={sheet.table}>
        {InvoicingData?.allJobs && <JobsTable handlePageChange={handlePageChange} allJobs={InvoicingData.allJobs} />}
        </div>
      </div>
    </ListingLayout>
  )
}

interface SectionTitleProps {
  title: string
  color: string
  icon: string
}

const SectionTitle = ({title, color, icon}: SectionTitleProps) => (
  <div className={sheet.section_title}>
    <h1>{title}</h1>
    <div style={{ backgroundColor: color }} className={sheet.title_icon}>
      <i className={icon}/>
    </div>
  </div>
)

export default Invoicing