import React, {useEffect, useReducer, useRef} from 'react';
import {CancelOrderService, submitCancelRequest} from "../../../services";
import {
  CAction, CancelAction, cancelOrderInitialState, CancelOrderReducer, CancelOrderValidations, CancelProps,
  CancelState
} from "../order-reducers/CancelOrderReducer";
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {showConfirmBox, showNotification, SubmitButton} from "../../../common";
import { DatetimePickerWrapper} from "../../../common/form/DatetimePicker";

export function CancelOrder (props: CancelProps) {

  const [state, dispatch] = useReducer<React.Reducer<CancelState, CAction>>(CancelOrderReducer , cancelOrderInitialState);

  useEffect( () => {
    CancelOrderService.getPopupState().subscribe((message:any) => {
      CancelOrderValidations();
      dispatch({type: message.action, payload: message}) ;
    });

  }, []);

  if(state.show === false){
    return null;
  }


  function handleChange(e: React.ChangeEvent<HTMLElement>) {
    console.log("Click cancel")
    const target : any = e.target;
    const value = target.value;
    const name = target.name;
    dispatch({type: CancelAction.HANDLE_CHANGE, payload: { value: value, name: name } as any});
  }


  function handleSubmit(event) {
    event.preventDefault();
    if($("#cancel_order").valid()){
      showConfirmBox(Lang.cancel_order_confirm, () => {
        submitCancelRequest(state.order_id, state.form).then(res => {
          showNotification('success', res);
          props.refresh();
          CancelOrderService.hidePopup();
        });
      })

    }
  }
  const {order_data} = state;

    function formDataReset(e:any) {
        e.preventDefault();
        dispatch({type: CancelAction.RESET_POPUP_FORM});
        var cancel_how = document.getElementById("cancel_how") as HTMLInputElement;
        var cancel_when = document.getElementById("cancel_when") as HTMLInputElement;
        var cancel_message = document.getElementById("cancel_message") as HTMLInputElement;
        cancel_how.checked = false;
        cancel_when.value = ''; 
        cancel_message.value = '';
    }

  return(
    <Modal
      show={state.show}
      onHide={() => { dispatch({type: CancelAction.HIDE_POPUP}) } }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form onSubmit={handleSubmit} id="cancel_order">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {Lang.cancel_order} - #{order_data.order_no}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
              <div className={'col-md-12'}>
                <div className={'form-group'}>

                </div>
              </div>

              <div className={'col-md-12'} style={{ marginLeft: 10}}>

                <div className={'form-group'}>
                  <label>{Lang.how} </label>
                  <div className="kt-radio-inline">
                    <label className="kt-radio kt-radio--brand" >
                      <input type={'radio'} id={'cancel_how'} name={'cancel_how'} value={'telephone'} onChange={handleChange}/>
                      {Lang.telephone}
                      <span />
                    </label>

                    <label className="kt-radio kt-radio--brand" >
                      <input type={'radio'} id={'cancel_how'} name={'cancel_how'} value={'mail'} onChange={handleChange}/>
                      {Lang.mail}
                      <span />
                    </label>

                    <label className="kt-radio kt-radio--brand" >
                      <input type={'radio'} id={'cancel_how'} name={'cancel_how'} value={'chat'} onChange={handleChange}/>
                      {Lang.chat}
                      <span />
                    </label>

                    <label className="kt-radio kt-radio--brand" >
                      <input type={'radio'} id={'cancel_how'} name={'cancel_how'} value={'translator'} onChange={handleChange}/>
                      {Lang.via_trans}
                      <span />
                    </label>
                  </div>
                  <div className={'form-group'}>
                    <label>{Lang.when}</label>
                    <DatetimePickerWrapper id={'cancel_when'} name={'cancel_when'} className={'form-control'} onChange={handleChange} placeholder={Lang.select_time} required />
                  </div>
                  <div className={'form-group'}>
                    <label>{Lang.message}</label>
                    <textarea name={'cancel_message'} id={'cancel_message'} className={'form-control'} rows={4} onChange={handleChange} placeholder={'Enter cancellation message'} required/>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={(e)=>formDataReset(e)} className=" btn btn-default hvr-rectangle-out btnMove  mobiNone" type="button">{Lang.reset}</button>&nbsp;
          <SubmitButton className={'btn btn-primary'} label={Lang.submit}/>

        </Modal.Footer>
      </form>
    </Modal>
  )


}