import React, {useEffect, useReducer} from 'react';
import {FixPriceCalculationSubjectService, setPrice, fixOrderRates, getFixOrderRates} from "../../../services";
import API from "../../../common/AxoisClient";
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {showNotification, SubmitButton, Select, RequestWaiting} from "../../../common";
import {
  InitialState, PopupState, ActionType,
  CAction, FixPriceReducer, FixPriceValidations
} from "../order-reducers/FixPriceReducer";
import {convert_comma_to_dot, convert_dot_to_comma, convert_to_decimal_format} from "../../../helpers/UserHelper";
import {OrderFixPriceModel} from "../../../model";

export function FixPriceCalculation () {

    const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(FixPriceReducer , InitialState);
    useEffect( () => {
        FixPriceCalculationSubjectService.getPopupState().subscribe((message:any) => {
          dispatch({type: message.action, payload: message}) ;
        });
    }, []);

    if(state.show === false){
        return null;
    }

    function timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      if(rminutes > 0){
        return rhours + " hour(s) and " + rminutes + " minute(s)";
      }
      return rhours + " hour(s)";
    }

  return(
    <Modal
      show={state.show}
      onHide={() => { dispatch({type: ActionType.HIDE_POPUP}) } }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="FixPriceCalculationPopUp"
    >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
                { state.fixPrice.length > 0 &&
                    <div className={'col-md-12'}>
                        {state.fixPrice.map((item: OrderFixPriceModel, index: number) => {
                            var fix_price:any = item.fix_price;
                            var old_total:any = item.old_total;
                            var diff = Number(convert_comma_to_dot(fix_price))-Number(convert_comma_to_dot(old_total));
                            return (
                                <div>
                                {item.order.company.is_special_company == 1 &&
                                  <div>
                                    <p><strong>Special Company: {item.order.company.name}</strong></p>
                                  </div>
                                }
                                {item.translation_type==1 &&
                                    <div>
                                      <p>{item.telephone_fix_minute} minutter, Påbegyndt: {item.old_rate}: {item.telephone_fix_rate}</p>
                                      <p>{item.telephone_sub_minute} minutter, Efterfølgende {item.old_sub_rate} - {item.extra_minutes_slots} times: {item.telephone_sub_rate}</p>
                                      {item.holiday_charge_agreed == '1' &&
                                        <p>Hvor mange procent ekstra?: [{item.extra_consider_amt} X {item.extra_holiday_percentage}]/100: {item.extra_per_rate}</p>
                                      }
                                      {item.old_total == item.fix_price &&
                                        <p>Total Rate: {item.fix_price}</p>
                                      }
                                      {item.old_total !== item.fix_price &&
                                        <React.Fragment>
                                        <p>Old Rate: {item.old_total}</p>
                                        <p>Difference Rate : {convert_dot_to_comma(String(diff.toFixed(2)))}</p>
                                        </React.Fragment>
                                      }
                                      <p>Final Rate : {item.fix_price}</p>
                                      <p>Total Time : {timeConvert(item.total_time)}</p>
                                    </div>
                                }
                                {item.translation_type==2 &&
                                    <div>
                                      <p>{item.physical_fix_minute} minutter, Påbegyndt: {item.old_rate}: {item.physical_fix_rate}</p>
                                      <p>{item.physical_sub_minute} minutter, Efterfølgende {item.old_sub_rate} - {item.extra_minutes_slots} times: {item.total_extra_rates}</p>
                                      {item.compensation_agreed=='1' && item.total_km_rate &&
                                        <p>Kilometergodtgørelse pris: {item.per_km_rate} Afregning sker efter {item.min_km}: {item.total_km_rate} (Both way)</p>
                                      }
                                      {item.transport_fee_agreed=='1' && item.extra_transport_rate &&
                                        <React.Fragment>
                                          <p>Er der aftalt honorar for transporttid?</p>
                                          <p>{item.transport_fee_minute} minutter, Påbegyndt: {item.transport_fee_fix_rate} - 2 times: {convert_to_decimal_format(String(convert_comma_to_dot(item.transport_fee_fix_rate) * 2))} (Both way)</p>
                                          <p>{item.transport_fee_sub_minute} minutter, Efterfølgende {item.transport_fee_sub_rate} - {item.extra_transport_minutes_slots} times: {item.extra_transport_rate} (Both way)</p>
                                        </React.Fragment>
                                      }
                                      {item.holiday_charge_agreed == '1' &&
                                        <p>Hvor mange procent ekstra?: [{item.extra_consider_amt} X {item.extra_holiday_percentage}]/100: {item.extra_per_rate}</p>
                                      }
                                      {item.old_total == item.fix_price &&
                                        <p>Total Rate: {item.fix_price}</p>
                                      }
                                      {item.old_total !== item.fix_price &&
                                        <React.Fragment>
                                        <p>Old Rate: {item.old_total}</p>
                                        <p>Difference Rate : {convert_dot_to_comma(String(diff.toFixed(2)))}</p>
                                        </React.Fragment>
                                      }
                                      <p>Final Rate : {item.fix_price}</p>
                                      <p>Total Time : {timeConvert(item.total_time)}</p>
                                    </div>
                                }
                                {item.translation_type==3 &&
                                    <div>
                                      <p>{item.video_fix_minute} minutter, Påbegyndt: {item.old_rate}: {item.video_fix_rate}</p>
                                      <p>{item.video_sub_minute} minutter, Efterfølgende {item.old_sub_rate} - {item.extra_minutes_slots}: {item.video_sub_rate}</p>
                                      {item.holiday_charge_agreed == '1' &&
                                        <p>Hvor mange procent ekstra?: [{item.extra_consider_amt} X {item.extra_holiday_percentage}]/100: {item.extra_per_rate}</p>
                                      }
                                      {item.old_total == item.fix_price &&
                                        <p>Total Rate: {item.fix_price}</p>
                                      }
                                      {item.old_total !== item.fix_price &&
                                        <React.Fragment>
                                        <p>Old Rate: {item.old_total}</p>
                                        <p>Difference Rate : {convert_dot_to_comma(String(diff.toFixed(2)))}</p>
                                        </React.Fragment>
                                      }
                                      <p>Final Rate : {item.fix_price}</p>
                                      <p>Total Time : {timeConvert(item.total_time)}</p>
                                    </div>
                                }
                                {item.translation_type==5 &&
                                    <div>
                                      <p>{Lang.tel_message_fix_rate}: {item.old_rate} for 30 minutter: {item.tel_message_fix_rate}</p>
                                      {item.old_total == item.fix_price &&
                                        <p>Total Rate: {item.fix_price}</p>
                                      }
                                      {item.old_total !== item.fix_price &&
                                        <React.Fragment>
                                        <p>Old Rate: {item.old_total}</p>
                                        <p>Difference Rate : {convert_dot_to_comma(String(diff.toFixed(2)))}</p>
                                        </React.Fragment>
                                      }
                                      <p>Final Rate : {item.fix_price}</p>
                                    </div>
                                }
                                {item.translation_type==6 &&
                                    <div>
                                      <p>{Lang.min_word}: {item.min_word}</p>
                                      <p>{Lang.total_words} : {item.total_words}</p>
                                      {item.old_total == item.fix_price &&
                                        <p>Total Rate: {item.fix_price}</p>
                                      }
                                      {item.old_total !== item.fix_price &&
                                        <React.Fragment>
                                        <p>Old Rate: {item.old_total}</p>
                                        <p>Difference Rate : {convert_dot_to_comma(String(diff.toFixed(2)))}</p>
                                        </React.Fragment>
                                      }
                                      <p>Final Rate : {item.fix_price}</p>
                                    </div>
                                }
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
          </div>
          
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
    </Modal>
  )


}