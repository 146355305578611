import React, {useEffect, useRef} from 'react';
import * as _ from "lodash";

interface GooglePlaceInterface extends React.InputHTMLAttributes<HTMLInputElement>{
  onChooseAddress: (place: any) => void;
}

export function GooglePlace(props: GooglePlaceInterface) {

  let place_input = useRef(null);
  useEffect(() => {
    let ele = place_input.current;
    let searchBox = new google.maps.places.SearchBox(ele);

    searchBox.addListener('places_changed', function() {
      let places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      places.forEach(function(place) {
        if (!place.geometry) {
          return;
        }
        props.onChooseAddress(place);
      });
    });

  });

  const {onChooseAddress, ...rest} = props;
  return(
    <div>
      <input type={'search'} {...rest} ref={place_input} />
    </div>
  )
}



export function getMapContent(place) {

  let postal_code_content = _.filter(place.address_components, (item) => {
    return _.indexOf(item.types, 'postal_code') > -1
  });

  let city_content = _.filter(place.address_components, (item) => {
    const typeIsLocality = _.indexOf(item.types, 'locality') > -1;
    const typeIsSubLocality = _.indexOf(item.types, 'sublocality') > -1;
    const typeIsPolitical = _.indexOf(item.types, 'political') > -1;
    return (typeIsLocality || typeIsSubLocality) && typeIsPolitical;
  });

  let address = place.formatted_address;
  let zip_code =  postal_code_content[0] !== undefined ? postal_code_content[0]['long_name'] : '';
  let city = city_content[0] !== undefined ? city_content[0]['long_name'] : '';

  let lat = place.geometry.location.lat();
  let lng = place.geometry.location.lng();

  return {address, zip_code, city, lat, lng};

}