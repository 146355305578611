import * as React from 'react';
import {OrderModel} from "../../model";
import {actionButton} from "../../common/table/BlukActionButtons";
import Lang from "../../common/lang/Lang";
import {
  IState as CommonState, EditLink, ShowRecords, Icons, ActionLink,
} from "../../common";
import {OrderInfo} from "./OrderInfo";
import {ListForm} from "../../layout/Listing";
import { orderContentShow} from "../../helpers/OrderHelper";
import _ from "lodash";
import {OrdersinfoService} from "../../services";
import {
  AssignTranslator, SendMailToCustomer, SendMailToTranslator, CancelOrder, OrderComments, FixPrice,
  HistoryPopup
} from "./popup";
import {CompleteOrderPopup} from "./popup/CompleteOrder";
import {CorrectionRequestPopup} from "./popup/CorrectionRequest";
import ActiveOrderFilter from './filter/ActiveOrderFilter';


declare var window:any;

interface IState extends CommonState {
    id: number;
    ModalData?: OrderModel;
    show?: boolean;
    languages: Array<{value: string, label:string}>;
    companies: Array<{value: string, label:string}>;
}

interface PropsType  {
    type: string;
}

export class OrdersListing extends React.Component<PropsType,IState> {
  
    props: PropsType;
    state: IState;
    records:React.RefObject<ShowRecords> = React.createRef();
    constructor(props: any) {
        super(props);
        this.state = {
            show: false
        } as any;
    }

    componentDidMount() {
      OrdersinfoService.getPopupState().subscribe((res:any) => {
    
         this.setState({
          ModalData : res.data,
          show: true
        })
      })
      
      }

      filterRecords = (data?: any) => {
        if( this.records && this.records.current !== null){
          if(data !== undefined){
            this.records.current.setFormFields(data);
          }else {
            this.records.current.setFormFields();
          }
        }
      }

    showContent = (data: OrderModel, index: number) => {
      let cols = orderContentShow(this.records, data, index, this.filterRecords);
      cols.push(this.records.current.createdTd("action", () => {
        return (
          <div>
            <ActionLink scope={'orders.index'} onClick={() => this.setState({ModalData: data, show: true})} to={'#'}  data-skin={'dark'} title={Lang.view_order} >
              <i className={`kt-nav__link-icon ${Icons.view}`} />
            </ActionLink>
            <EditLink scope={'orders.update'} to={`/orders/order/edit/${data.id}`} data-skin={'dark'}  title={Lang.edit_order} />
          </div>
        )
      }));
      cols = _.compact(cols);
      return (
        <tr key={data.id}>{cols}</tr>
      );
    };

    render() {
       
        return (
            <div>
              <ListForm
            form={(
              <ActiveOrderFilter getResults={this.filterRecords}  languages={this.state.languages !== undefined ? this.state.languages: []} companies={this.state !== null ? this.state.companies: []}  />
            )}
            table={(
                <ShowRecords
                    url={`orders/orders?type=`+this.props.type}
                    showRecords={this.showContent}
                    ref={this.records}
                    isNeedAction={false}
                    showCheckbox={false}
                    actionButtons={actionButton({records: this.records, 'url': "orders/orders/bulk-action"})}
                />
                )}
                />
               {
          this.state.show &&
          <OrderInfo
              show={this.state.show}
              onHide={() => this.setState({show: false})}
              orderinfo={this.state.ModalData}
              />
         }
         <AssignTranslator refresh={this.filterRecords} />
         <SendMailToCustomer />
        <SendMailToTranslator />
        <CancelOrder refresh={this.filterRecords} />
        <OrderComments/>
        <FixPrice/>
        <HistoryPopup />
        <CompleteOrderPopup refresh={this.filterRecords}/>
        <CorrectionRequestPopup />
            </div>
        );
    }
}