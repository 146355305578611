import * as React from 'react';
import {ActivityLogModel} from "../../model/ActivityLog";
import {IState as CommonState} from "../../common/State";
import {ShowRecords} from "../../common/ShowRecords";
import {ExportButton, getQueryStringParams} from "../../common/Common";
import {Breadcrumb} from "../../common/page/Breadcrumb";
import {RouteComponentProps} from "react-router";
import {ActivityLogBreadcrumb} from "./Data";

interface IState extends CommonState {
    UserData: Array<ActivityLogModel>;
    id:number;
    form: {
        like: {
            name: string,
            type: string,
        },
        equal: {
            status: number
        }
    }
    
}

export class ActivityLog extends React.Component<RouteComponentProps,IState> {
    state: IState;
    records:React.RefObject<ShowRecords> = React.createRef();

    

    UNSAFE_componentWillMount() {
        let old_data: any = getQueryStringParams("form");
        old_data = JSON.parse(old_data);
        this.setState({form:  {
                like: {
                    name: old_data ? old_data.like.name : '',
                    type: old_data ? old_data.like.type : ''
                },
                equal: {
                    status: old_data ? old_data.equal.status : ''
                }
            }})
    }

    filterRecords = () => {
        if( this.records && this.records.current !== null){
            this.records.current.setFormFields(this.state.form);
        }
    }
    createMarkup =(content: string) => {
        return { __html: content };
    }
    
    showContent = (data: ActivityLogModel, index: number) => {
     
        return (
            <tr key={index}>
                
                {this.records.current.createdTd("id", index)}
                {this.records.current.createdTd("message", data.message)}
                {this.records.current.createdTd("action_at", data.action_at)}
               
            </tr>
        );
       
    }

    handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name.split(".");
        let newAccess: any = Object.assign({}, this.state.form);
        newAccess[name[1]][name[2]] = e.target.value;
        this.setState({form: newAccess}, this.filterRecords);
    }

    actions = (actionType: string) => {
    }

    render() {
      const {id}: any = this.props.match.params;
        const actionButton = [
            {label: 'Activate', "class": "btn btn-sm btn-success", "onClick" : () => this.actions('active'), show: true},
        ];
        return (
               
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <Breadcrumb data={ActivityLogBreadcrumb}/>
                   <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg">
                            <div className="kt-portlet__head-label">
                            <span className="kt-portlet__head-icon">
                                <i className="kt-font-brand fa fa-history"/>
                            </span>
                                <h3 className="kt-portlet__head-title">
                                    Activity Logs
                                </h3>
                            </div>
                          
                        </div>
                        <form method={'get'} id={'filter_form'}>
                            <ShowRecords
                                url={`users/customers/customer-activitylogs/${id}`}
                                showRecords={this.showContent}
                                ref={this.records}
                                isNeedAction={false}
                                showCheckbox={false}
                               
                               
                            />
                        </form>
                    </div>
               
                </div>
             
        )
    }
}
