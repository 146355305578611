import React, {useState, useEffect} from 'react';
import {
  QuestionType,
  QuizModel,
  QuizQuestionModel,
  TranslatorQuizAnswersModel,
  QuizAnswerModel,
  QuizQuestionOptionModel
} from "../../model";

import _ from 'lodash';
import { empty } from 'rxjs';
import {Icons} from "../../common";

export function  QuizResponseList (props: {quiz: QuizModel}) {

  function getAnswers(ans:QuizAnswerModel) {
    let isCorrect = true;
    let answers;
    if(ans !== null){
      answers = ans.translator_answer.map((opt:TranslatorQuizAnswersModel) => {
        if(opt.get_answer !== null ){
          if(opt.get_answer.is_correct === 0){
            isCorrect = false;
          }
          return (<span key={opt.id} style={{marginLeft: 5}} className={`badge ${isCorrect ? 'badge-success' : 'badge-danger'}`}>Ans - {opt.get_answer.options}</span>)
        } else {
          return (opt.answer_id)
        }
      });
  }
  return {isCorrect, answers};
  }

  return (
    <div className="kt-notification-v2">
       {
        !_.isEmpty(props.quiz) && props.quiz.questions.length > 0 && props.quiz.questions.map((item: QuizQuestionModel) => {
          const {isCorrect, answers} = getAnswers(item.quiz_answers);
          return (
            <div className="kt-notification-v2__item" key={item.id}>
              <div className="kt-notification-v2__item-icon">
                <i className={item.question_type === QuestionType.SUBJECTIVE ? 'flaticon2-pen kt-font-warning' : (`${isCorrect ? 'flaticon2-check-mark kt-font-success' : 'flaticon2-cancel-music kt-font-danger'}`)}/>
              </div>
              <div className="kt-notification-v2__itek-wrapper">
                <div className="kt-notification-v2__item-title" dangerouslySetInnerHTML={{__html: item.question}}/>
                <div className="kt-notification-v2__item-desc">
                  {
                    (item.question_type === QuestionType.OBJECTIVE || item.question_type === QuestionType.MULTI_CHOICE) &&
                      item.options.map((options:QuizQuestionOptionModel, index: number) => {
                        return (
                          <div key={options.id} style={{marginLeft: 5, marginBottom:5}}>
                            <span  className={'kt-font-info'}>{++index} - {options.options} &nbsp;
                              {options.is_correct && <i className={Icons.complete} title={'This is the right answer'} />}
                            </span>
                          </div>
                        )
                      })
                  }
                  {answers}
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}