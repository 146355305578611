import React, {useState, useEffect} from 'react';
import {commentsService, getTranslatorComments, postComments} from "../../../services";
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {NoRecords, PleaseWait, SubmitButton} from "../../../common";
import { UserModel} from "../../../model";
import {TranslatorCommentModel} from "../../../model/TranslatorComment";

export function Comments(props:any) {
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [form, setForm] = useState({} as { translator_id: string | number, comment: string});
    const [user, setUser] = useState({} as UserModel);
    const [content, setContent] = useState([] as Array <TranslatorCommentModel> );

    useEffect(() => {
        commentsService.getPopupState().subscribe((res: any) => {
            const {show, user} = res;
            setShow(show);
            if (show) {
                setUser(user);
                setLoader(true);
                setForm({
                    ...form,
                    translator_id: user.id
                });
                getTranslatorComments(user.id).then(res => {
                    setContent(res);
                    setLoader(false);
                    if(typeof res !== 'undefined' && res.length > 0){
                        setForm({...form,comment: res[0].comment, translator_id: user.id});
                    }
                })
            }
        })
    }, []);

    if (show === false) {
        return null;
    }
    async function handleSubmit(e) {
        e.preventDefault();
        postComments(user.id, form).then((res: TranslatorCommentModel) => {
            setContent([...content, res]);
            setForm({
                ...form,
                comment: ''
            });
            
        });

    }

    function handleChange(e) {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    function resetForm(event: any) {
        event.preventDefault();
        setForm({
            ...form,
            comment: ''
        });
    }

    return(
        <Modal
            show={show}
            onHide={() =>
            setShow(false) }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            >
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {Lang.comments}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                        <div className={'row'}>
                            <div className={'col-md-12'} >
                                <div className={'form-group'}>
                                    <label>{Lang.comments}</label>
                                    <textarea name={'comment'} onChange={handleChange} value={form.comment}
                                        className={'form-control'} required={true} />
                                </div>
                                <button className={'btn btn-info'} data-skin={'dark'} title={Lang.reset} onClick={resetForm}>{Lang.reset}</button>&nbsp;
                                <SubmitButton className={'btn btn-success'} label={Lang.submit} />
                            </div>
                        </div>
                        <br/>
                        <div  className={'row'}>
                            { loader && 
                            <PleaseWait/>
                            }
                            {!loader && content.length == 0 && 
                            <NoRecords/>
                            }
                            {!loader &&
                            content.length > 0 &&
                            <div className={'col-md-12'}>
                                <table className={'table table-bordered table-hover'}>
                                    <tbody>
                                        <tr>
                                            <td>#</td>
                                            <td>{Lang.users}</td>
                                            <td>{Lang.translator}</td>
                                            <td>{Lang.comments}</td>
                                            <td>{Lang.created_at}</td>
                                        </tr>
                                        {content.map((item: TranslatorCommentModel, index: number) => {
                                        return (
                                        <tr key={item.id}>
                                            <td>{++index}</td>
                                            <td>{item.user.name}</td>
                                            <td>{item.translator.name}</td>
                                            <td>{item.comment}</td>
                                            <td>{item.created_at}</td>
                                        </tr>
                                        )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className={'btn btn-primary'} onClick={() =>  setShow(false) }>{Lang.close}</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
