import {DepartmentModel} from "./Department";
export interface DepartmentUnitModel {
  id: number;
  name: string;
  department_id: number;
  ean_number: number;
  address: string;
  city: string;
  country_code : string;
  mobile_no: number;
  zip_code: number;
  location_code: string;
  location_code_2: number;
  department?: DepartmentModel;
  status?: number;
  notify_email?: string;
  created_at?: string;
  latitude?: string;
  longitude?: string;
  economics_ref_id?: string;
}

export const DepartmentValues = (data?: DepartmentUnitModel) => {
  return {
   
     
    name: data !== undefined ? data.name : '',
    notify_email: data !== undefined ? data.notify_email : '',
    department_id: data !== undefined ? data.department_id : 0,
    ean_number: data !== undefined ? data.ean_number : 0,
    address: data !== undefined ? data.address : '',
    city: data !== undefined ? data.city : '',
    country_code: data !== undefined ? data.country_code : '',
    mobile_no: data !== undefined ? data.mobile_no : 0,
    zip_code: data !== undefined ? data.zip_code : 0,
    location_code: data !== undefined ? data.location_code : '',
    location_code_2: data !== undefined ? data.location_code_2 : 0,
     status: data !== undefined ? data.status : 1,
     latitude: data !== undefined ? data.latitude : '',
     longitude: data !== undefined ? data.longitude : '',
     economics_ref_id: data !== undefined ? data.economics_ref_id : '',
  }
}