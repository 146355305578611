import React, {useState,useEffect} from 'react';
import { Modal, ModalProps} from "react-bootstrap";
import Lang from "../../common/lang/Lang";
import {Icons,base_url, showNotification, SubmitButton} from "../../common";
import {Editor} from "@tinymce/tinymce-react/lib/cjs/main/ts";
import {UserModel} from "../../model";
import API from "../../common/AxoisClient";
import {AxiosResponse} from "axios";
 import {EmailTemplateDataSchema} from "../../settings/email-templates/Model";
import {UserContractModel} from "../../model/UserContract";
import * as _ from 'lodash';

import { array } from 'prop-types';


export interface ContractModel {
  subject?: string;
  content?: string;
  contractContent?: string;
  translator_id?: number;
  email_template?: EmailTemplateDataSchema;
  contract?: UserContractModel;
}

interface InfoProps extends ModalProps{
  transInfo: UserModel,
  transID?: number
  ContractInfo?: ContractModel
}

export function CustomerUnFavorite  (props: any)  {
  
   
    const [favorite, setfavorite] = useState([]);
    const [unfavorite, setunfavorite] = useState([]);
    const [favoriteusers, setfavoriteUsers] = useState();
    const [searchVal, setSearchVal] = useState('');
    const [searchuserslist, setSearchusers] = useState([]);
    const [usermessage, setUsermessage] = useState(0);
    const [selectallIds, setSelectallIds] = useState();
    const [userIds, setUserIds] = useState();
    const [usertype, setUsertype] = useState(2);
    const [customerId,setCustomerId] = useState(props.match.params.id);

    
    useEffect(() => {
    
    Unfavorties()
    
  },[customerId]);



   function Unfavorties(){
    API.get(`users/customers/Unfavorite-Userlist/${customerId}`).then(response => {
      setfavorite(response.data.favorite); 
      setunfavorite(response.data.unfavorite); 
    })
   }

    
function SearchUserlists(searchVal){
    let searchChar = $.trim(searchVal);
    let customerLength = searchChar.length;
    if (customerLength < 3) {
        setSearchusers([]);
    } else {
        API.post(`users/customers/Unfavorite-Searchlist`,{search:searchVal,id:customerId}).then(response => {
            setSearchusers(response.data);
        })
    }
 }

 function UnfavoriteUseradd(selectallIds){
    
  API.post(`users/customers/UnfavoriteUseradd`,{id:customerId,userIds:selectallIds,type:usertype}).then(response => {
    
      Unfavorties()
     
    SearchUserlists(searchVal)
 })

}

function UnfavoriteUserdelete(userIds){
  API.post(`users/customers/UnfavoriteUserdelete`,{id:customerId,userIds:userIds,type:usertype}).then(response => {
    
      Unfavorties()
      SearchUserlists(searchVal)  
   
})

}
   

     function searchusers(event:any){
       if(event.target.value == ''){
        setUsermessage(0)
        setSearchusers(event.target.value)
        SearchUserlists(event.target.value)
        } else {
        setUsermessage(1) 
        setSearchVal(event.target.value)
        SearchUserlists(event.target.value)
        }
       
      }

    
   function handleChange(event:any){
         let value =  event.target.value;
          const name = event.target.name;
      if(name === 'userIds'){
        if(event.target.checked === true) {
        setSelectallIds(event.target.value)
         } else{
        setSelectallIds(event.target.value)
        }
        UnfavoriteUseradd(event.target.value)
         }
     }
    

     function changeusertype(event:any){
      
        Unfavorties()
        setUsermessage(0)
        setSearchVal('');
		setSearchusers([])
        SearchUserlists('')
       setUsertype(event)
     }

     
     function deleteuser(event:any){
       setUserIds(event.target.value)
       UnfavoriteUserdelete(event.target.value)
     }

    

  return(
   
    <div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
           
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar">
          <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-brand" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" onClick={(e)=>changeusertype(2)} data-toggle="tab" href="#kt_widget4_tab1_content"  role="tab">
                {Lang.unfavorite_users}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={(e)=>changeusertype(1)} data-toggle="tab" href="#kt_widget4_tab2_content" role="tab">
                {Lang.favorite_users}
              </a>
            </li>
           
          </ul>
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="tab-content">
          <div className="tab-pane active" id="kt_widget4_tab1_content">
            <div className="kt-widget4">
              
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className="form-group">
            <div className={'row'}>
       <div className={"col-md-12 col-lg-12"}>
      <input type="text" placeholder="Please type minimum 3 characters for search"  className="form-control"  onChange={(e)=>searchusers(e)}/>
      
      {searchuserslist !== null && searchuserslist !== undefined  && searchuserslist.length >0 &&
      (searchuserslist).map((item,i) => { 
      return <div className="favorite-checkbox"><label key={item['id']} className="kt-checkbox">
      <input type ="Checkbox" name={'userIds'} onChange={(e)=>handleChange(e)}  value={item['id']} 
        />{item['name']}
      <span></span>
      </label></div>
      })
      }
      
         {searchuserslist.length === 0 && usermessage === 1 && 
        <div className="alert alert-outline-warning fade show mt-3" >Users not found</div> 
        }
 
        </div>  
          </div>
          </div>
          {unfavorite.length > 0 &&
          <table className="table table-bordered table-hover">
                            
          <thead className="kt-datatable__head"> 
          <tr className="kt-datatable__row">
            <th className="kt-datatable__cell kt-datatable__cell--sort kt-datatable__cell--sorted">Name</th>
            <th className="kt-datatable__cell kt-datatable__cell--sort kt-datatable__cell--sorted">Action</th>
          </tr>
          </thead>
          <tbody>
      

        {unfavorite !== null  && unfavorite !== undefined && 
         (unfavorite).map((item,i) => {
         return<tr>
          <td>{item['translator_userslist']['name']}</td>
          <button type="button" value={ item['translator_userslist']['id']} onClick={(e)=>deleteuser(e)} className="btn btn-sm btn-danger">Delete</button>
          </tr>
        })
        }
        
          </tbody>
          </table>
          }
          <div className="form-group">
          {unfavorite.length === 0 && 
         <div className="alert alert-outline-warning fade show " >Translator not found</div> 
         }
         </div>
      </div>
         </div>
         
          </div>
          <div className="tab-pane" id="kt_widget4_tab2_content">
          <div className="kt-widget4">
              
              <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
              <div className="form-group">
              <div className={'row'}>
         <div className={"col-md-12 col-lg-12"}>
        <input type="text" placeholder="Please type minimum 3 characters for search"  className="form-control"  onChange={(e)=>searchusers(e)}/>
        
        {searchuserslist !== null && searchuserslist !== undefined  && searchuserslist.length >0 &&
        (searchuserslist).map((item,i) => { 
        return <div className="favorite-checkbox"><label key={item['id']} className="kt-checkbox">
        <input type ="Checkbox" name={'userIds'} onChange={(e)=>handleChange(e)}  value={item['id']} 
          />{item['name']}
        <span></span>
        </label></div>
        })
        }
        
           {searchuserslist.length === 0 && usermessage === 1 && 
          <div className="alert alert-outline-warning fade show mt-3" >Users not found</div> 
          }
   
          </div>  
            </div>
            </div>
            {favorite.length > 0 &&
            <table className="table table-bordered table-hover">
                              
            <thead className="kt-datatable__head"> 
            <tr className="kt-datatable__row">
              <th className="kt-datatable__cell kt-datatable__cell--sort kt-datatable__cell--sorted">Name</th>
              <th className="kt-datatable__cell kt-datatable__cell--sort kt-datatable__cell--sorted">Action</th>
            </tr>
            </thead>
            <tbody>
        
  
          {favorite !== null  && favorite !== undefined && 
           (favorite).map((item,i) => {
           return<tr>
            <td>{item['translator_userslist']['name']}</td>
            <button type="button" value={ item['translator_userslist']['id']} onClick={(e)=>deleteuser(e)} className="btn btn-sm btn-danger">Delete</button>
            </tr>
          })
          }
          
            </tbody>
            </table>
            }
            <div className="form-group">
            {favorite.length === 0 && 
           <div className="alert alert-outline-warning fade show " >Translator not found</div> 
           }
           </div>
        </div>
           </div>
           
          </div>
        </div>
      </div>

  </div>
 
  )
};