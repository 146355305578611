import * as React from "react";
import API from "../../common/AxoisClient";
import { showNotification } from "../../common/Common";
import Lang from "../../common/lang/Lang";
import { RouteComponentProps } from "react-router";
import { Breadcrumb } from "../../common/page/Breadcrumb";
import { EditBreadcrumb } from "./Data";
import { TranslatorValidations } from "./Validations";
import { ETranslatorState } from "../../model/Translator";
import { getMapContent, GooglePlace } from "../../common/GooglePlace";
import { CountryFlags, Select2Wrapper } from "../../common/Select2";
import { CommonSubmitButton, Icons, Select, SwitchLoader } from "../../common";
import { LoaderType } from "../../common/Loader";
import * as _ from "lodash";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";

declare var window: any;

export class EditTranslators extends React.Component<
  RouteComponentProps,
  ETranslatorState
> {
  state: ETranslatorState;
  constructor(props: any) {
    super(props);
    this.state = {
      id: props.match.params.id,
      name: "",
      title: "",
      email: "",
      address: "",
      city: "",
      zip_code: "",
      account_no: "",
      bank_registration_no: "",
      social_no: "",
      gender: "",
      bank_name: "",
      account_holder_name: "",
      origin_country: "",
      is_km_pay: 0,
      is_transport_pay: 0,
      agreed_income: 0,
      mobile_no: "",
      country_code: "",
      select_location: "",
      location: [],
      locationList: [],
      countries: [],
      countrycodes: [],
      physical_rate: "",
      telephone_rate: "",
      video_rate: "",
      worked_with_ministry: false
    };
    TranslatorValidations();
    this.countryCodeChange = this.countryCodeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event: React.ChangeEvent<any>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (name !== "select_location") {
      if (name === "is_certified" && value == 0) {
        this.setState({
          children_certificate: "",
          children_certificate_filename: "",
          filePath: "",
        } as any);
      }
      this.setState({
        [name]: value,
      } as any);
    } else {
      var location_value = $("#select_location").val();
      this.setState({
        location: location_value,
      } as any);
    }
  };

  DocumentUpload(event: any) {
    const name = event.target.name;
    let formData = new FormData();
    const dFileName = event.target.files[0].name;
    if (name === "children_certificate") {
      formData.append("document_name", name);
      formData.append("orignal_filename", dFileName);
      formData.append("children_certificate", event.target.files[0]);
    }
    API.post(`users/translators/certificate-upload`, formData).then(
      (res: AxiosResponse) => {
        this.setState({
          children_certificate: res.data.filename,
          children_certificate_filename:
            res.data.children_certificate_org_filename,
          filePath: res.data.filePath,
        } as any);
      }
    );
  }

  handleCheckboxChange = (event: React.ChangeEvent<any>) => {
    const targetCheck = event.target;
    const checkValue = targetCheck.value;
    const name = targetCheck.name;
    if (event.target.checked === true) {
      this.setState({
        [name]: checkValue,
      } as any);
    } else {
      this.setState({
        [name]: 0,
      } as any);
    }
  };

  componentDidMount() {
    API.get(`users/translators/${this.state.id}`).then((res) => {
      const translatorData = res.data.translatorArr.translator;
      const userData = res.data.translatorArr;
      this.setState({
        countries: res.data.countryArr,
        countrycodes: res.data.countryCodeArr,
        locationList: res.data.locationArr,
        location: translatorData.location,
        name: userData.name,
        title: translatorData.title,
        email: userData.email,
        country_code: userData.country_code,
        address: userData.address,
        city: userData.city,
        zip_code: userData.zip_code,
        status: userData.status,
        account_no: translatorData.account_no,
        bank_registration_no: translatorData.bank_registration_no,
        bank_name: translatorData.bank_name,
        account_holder_name: translatorData.account_holder_name,
        verification_number: translatorData.verification_number,
        social_no: translatorData.social_no,
        gender: userData.gender,
        origin_country: translatorData.origin_country,
        is_km_pay: translatorData.is_km_pay,
        is_transport_pay: translatorData.is_transport_pay,
        agreed_income: translatorData.agreed_income,
        mobile_no: userData.mobile_no,
        exp_scale: translatorData.exp_scale,
        driving_license: translatorData.driving_license,
        own_vehicle: translatorData.own_vehicle,
        is_certified: translatorData.is_certified,
        children_certificate:
          translatorData.children_certificate !== null &&
          translatorData.children_certificate !== ""
            ? translatorData.children_certificate.split(
                "public/storage/translator-document/",
                2
              )[1]
            : translatorData.children_certificate,
        children_certificate_filename:
          translatorData.children_certificate_filename,
        filePath: translatorData.children_certificate,
        police_verified: translatorData.police_verified,
        income: translatorData.income,
        how_many_km: translatorData.how_many_km,
        how_much_pay: translatorData.how_much_pay,
        hourly_pay: translatorData.hourly_pay,
        push_agreement: translatorData.push_agreement,
        physical_rate: translatorData.physical_rate,
        telephone_rate: translatorData.telephone_rate,
        video_rate: translatorData.video_rate,
        worked_with_ministry: translatorData.worked_with_ministry
      });
    });
  }

  countryCodeChange(countryCodeSelect: any) {
    this.setState({
      country_code: countryCodeSelect,
    });
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if ($("#create_translator").valid()) {
      API.put(`users/translators/${this.state.id}`, this.state).then((res) => {
        showNotification(
          "success",
          "Oplysningerne om tolken " + this.state.name + " er opdateret."
        );
        {
          /*this.props.history.push(`/users/translators`);*/
        }
      });
    }
  };

  handleSelectChange = (event: React.ChangeEvent<HTMLElement>) => {
    const targetSelect: any = event.target;
    const value = targetSelect.value;
    this.setState({
      country_code: value,
    } as any);
  };

  googleGetAddress = (place) => {
    const { address, city, lat, lng, zip_code } = getMapContent(place);
    this.setState({
      lat: lat,
      lng: lng,
      address: address,
      zip_code: zip_code,
      city: city,
    });
  };

  render() {
    const countryCodeList = this.state.countrycodes;
    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <Breadcrumb data={EditBreadcrumb} />

        <div className="kt-portlet kt-portlet--mobile">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">
                      {Lang.edit_translator}
                    </h3>
                  </div>
                </div>
                <br />
                <form
                  className="kt-form"
                  onSubmit={this.handleSubmit}
                  id={"create_translator"}
                >
                  <div className="kt-portlet__body">
                    <div className={"row"}>
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <label>
                            {Lang.name} <span className={"required"}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            onChange={this.handleChange}
                            value={this.state.name || ""}
                            placeholder={Lang.getString(`name_placeholder`)}
                          />
                        </div>
                      </div>
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <label>
                            {Lang.email} <span className={"required"}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={this.handleChange}
                            value={this.state.email || ""}
                            placeholder={Lang.getString(`email_placeholder`)}
                          />
                        </div>
                      </div>
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <label>
                            {Lang.address} <span className={"required"}>*</span>
                          </label>
                          {/*<input type="text" className="form-control" id="address" name="address"
                                                               onChange={this.handleChange} value={this.state.address} placeholder={Lang.getString(`address_placeholder`)}/>*/}
                          <GooglePlace
                            onChooseAddress={this.googleGetAddress}
                            name={"address"}
                            readOnly={this.state.is_prefilled}
                            defaultValue={this.state.address}
                            className="form-control"
                            placeholder={Lang.getString(`address_placeholder`)}
                          />
                        </div>
                      </div>
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <label>
                            {Lang.city} <span className={"required"}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            value={this.state.city}
                            onChange={this.handleChange}
                            placeholder={Lang.getString(`city_placeholder`)}
                          />
                        </div>
                      </div>
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <label>
                            {Lang.zip_code_translator}{" "}
                            <span className={"required"}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="zip_code"
                            value={this.state.zip_code || ""}
                            name="zip_code"
                            onChange={this.handleChange}
                            placeholder={Lang.bank_name_placeholder}
                          />
                        </div>
                      </div>

                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group ">
                          <label>
                            {Lang.telephone_number}{" "}
                            <span className={"required"}>*</span>
                          </label>
                          <div className={"row"}>
                            <div className={"col-md-4 col-lg-4"}>
                              <Select2Wrapper
                                value={this.state.country_code}
                                onChange={this.handleSelectChange}
                                className={"form-control"}
                                id={"country_code"}
                                data={{
                                  placeholder: "Select Options",
                                  data: this.state.countrycodes,
                                  templateResult: CountryFlags,
                                  templateSelection: CountryFlags,
                                }}
                              />
                              {/*<select className="form-control kt-select2" id="country_code" name="country_code">
                                                                    {
                                                                        this.state.countrycodes &&
                                                                        this.state.countrycodes.length > 0 &&
                                                                        this.state.countrycodes.map(countrycodes => {
                                                                            return <option title={countrycodes.iso}  value={countrycodes.value}>{countrycodes.label}</option>;
                                                                        })
                                                                    }
                                                                </select>*/}
                            </div>
                            <div className={"col-md-8 col-lg-8"}>
                              <input
                                type="text"
                                className="form-control"
                                id="mobile_no"
                                value={this.state.mobile_no || ""}
                                name="mobile_no"
                                onChange={this.handleChange}
                                placeholder={Lang.getString(
                                  `telephone_placeholder`
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group ">
                          <label>{Lang.location}</label>
                          <Select
                            onChange={this.handleChange}
                            className="form-control"
                            id="select_location"
                            name="select_location"
                            value={this.state?.location}
                            multiple
                          >
                            <option value="" disabled>
                              {Lang.select}
                            </option>
                            {this.state.locationList &&
                              this.state.locationList.length > 0 &&
                              this.state.locationList.map((locations) => {
                                return (
                                  <option value={locations.value}>
                                    {locations.text}
                                  </option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>

                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>
                                {Lang.driver_license}{" "}
                                <span className={"required"}>*</span>
                              </label>
                              <Select
                                id="driving_license"
                                value={
                                  this.state.driving_license !== 0
                                    ? this.state.driving_license
                                    : "0" || ""
                                }
                                name="driving_license"
                                onChange={this.handleChange}
                                className="form-control"
                              >
                                <option value="">{Lang.select}</option>
                                <option value={1}>{Lang.yes}</option>
                                <option value={0}>{Lang.no}</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.driving_license == 1 && (
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>
                                  {Lang.vehicle}{" "}
                                  <span className={"required"}>*</span>
                                </label>
                                <Select
                                  id="own_vehicle"
                                  value={this.state.own_vehicle || ""}
                                  name="own_vehicle"
                                  onChange={this.handleChange}
                                  className="form-control "
                                >
                                  <option value="">{Lang.select}</option>
                                  <option value={1}>{Lang.yes}</option>
                                  <option value={0}>{Lang.no}</option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>
                                {Lang.police_confirmed}{" "}
                                <span className={"required"}>*</span>
                              </label>
                              <Select
                                id="police_verified"
                                value={this.state.police_verified}
                                name="police_verified"
                                onChange={this.handleChange}
                                className="form-control"
                              >
                                <option value="">{Lang.select}</option>
                                <option value={1}>{Lang.yes}</option>
                                <option value={0}>{Lang.no}</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {this.state.police_verified == 1 && (
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>
                                  {Lang.verification_number}{" "}
                                  <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="verification_number"
                                  name="verification_number"
                                  value={this.state.verification_number || ""}
                                  onChange={this.handleChange}
                                  placeholder={
                                    Lang.verification_number_placeholder
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>
                                {Lang.sex} <span className={"required"}>*</span>
                              </label>
                              <Select
                                id="gender"
                                value={this.state.gender || ""}
                                name="gender"
                                onChange={this.handleChange}
                                className="form-control"
                              >
                                <option value="">{Lang.select}</option>
                                <option value={1}>{Lang.male}</option>
                                <option value={2}>{Lang.female}</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className={"row"}>
                          <div className={"col-md-6"}>
                            <label>
                              {Lang.country_of_origin}{" "}
                              <span className={"required"}>*</span>
                            </label>
                            <Select
                              className="form-control"
                              value={this.state.origin_country || ""}
                              onChange={this.handleChange}
                              id="origin_country"
                              name="origin_country"
                            >
                              <option value="">{Lang.select}</option>
                              {this.state.countries &&
                                this.state.countries.length > 0 &&
                                this.state.countries.map((countries) => {
                                  return (
                                    <option
                                      key={countries.id}
                                      value={countries.id}
                                    >
                                      {countries.name}
                                    </option>
                                  );
                                })}
                            </Select>
                          </div>
                          {/* Worked with ministry in the past field */}
                          <div className="col-md-6">
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '1rem 0' }}>
                                <label style={{marginBottom: 0, textAlign: 'start', fontSize: '.87rem'}}><span style={{ fontWeight: 'bolder' }}>Har du erfaring med at tolke for Udlændinge- og Integrationsministeriet </span><i>(herunder, Flygtningenævnet, Hjemrejsestyrelsen, Udlændingestyrelsen, SIRI og Udlændingenævnet)</i> ?</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="worked_with_ministry" id="worked_with_ministry_true" value="true" style={{cursor: 'pointer'}} checked={this.state.worked_with_ministry} onChange={() => this.setState({ worked_with_ministry: true })}/>
                                        <label className="form-check-label" htmlFor="worked_with_ministry_true" style={{cursor: 'pointer'}}>{Lang.yes}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="worked_with_ministry" id="worked_with_ministry_false" value="false" style={{cursor: 'pointer'}} checked={!this.state.worked_with_ministry} onChange={() => this.setState({ worked_with_ministry: false })} />
                                        <label className="form-check-label" htmlFor="worked_with_ministry_false" style={{cursor: 'pointer'}}>{Lang.no}</label>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CommonSubmitButton />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
