import React from 'react'

// Styles
import sheet from './Table.module.scss'

interface Props {
  Type: string | JSX.Element
  Company: string | JSX.Element
  Status: string | JSX.Element
  Date: string | JSX.Element
  Orders: string | JSX.Element
}

const Row = ({Type, Company, Status, Date: DateElem, Orders}: Props) => (
  <div className={sheet.row}>
    { typeof Type === 'string' ? <div>{Type}</div> : Type }
    { typeof Company === 'string' ? <div>{Company}</div> : Company }
    { typeof Status === 'string' ? <div>{Status}</div> : Status }
    { typeof DateElem === 'string' ? <div>{DateElem}</div> : DateElem }
    { typeof Orders === 'string' ? <div>{Orders}</div> : Orders }
  </div>
)

export default Row