import React from "react";
import Lang from "../../common/lang/Lang";

export const CropProfileImage = (props: any) => {
  return (
    <div className="modal customModal fade" id="editTProfileImagePopup">
      <div className="modal-dialog modal-dialog-centered modal-lg modal-ml">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="title mb-0">
              <i className="far fa-edit" />
              {Lang.profile_pic}
            </h1>
            <button type="button" className="close" data-dismiss="modal" />
          </div>
          <div className="modal-body">
            <div className="row">
              <div className={"col-md-12"}>
                <img
                  className={"img-fluid"}
                  alt={""}
                  id={"uploadedImage1"}
                  src="#"
                />
                {/* <img className={'img-fluid'} alt={''} id={"uploadedImage1"} src={'http://localhost/HD%20Backgrounds/Praful%20Editx%20Backgrounds%20(1).jpg'}/> */}
              </div>
            </div>
            <div className="mt-4 btnSet text-center">
              <button
                className={"btn btn-primary"}
                id={"updateProfilePictureOfUser"}
              >
                {Lang.upload}
              </button>
              <button
                className={"btn btn-primary"}
                id={"ProfilePicturePleaseWait"}
              >
                {Lang.please_wait}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
