import * as React from "react";
import API from "../../common/AxoisClient";
import { showNotification } from "../../common/Common";
import Lang from "../../common/lang/Lang";
import { RouteComponentProps } from "react-router";
import { Breadcrumb } from "../../common/page/Breadcrumb";
import { TaxBreadcrumb } from "./Data";
import { TranslatorValidations } from "./Validations";
import { TaxTranslatorState } from "../../model/Translator";
import { getMapContent, GooglePlace } from "../../common/GooglePlace";
import { CountryFlags, Select2Wrapper } from "../../common/Select2";
import { CommonSubmitButton, Icons, Select, SwitchLoader } from "../../common";
import { DateofBirthInput } from "../../common";
import { LoaderType } from "../../common/Loader";
import * as _ from "lodash";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";

declare var window: any;

let moment = require("moment");

export class TaxAndPayment extends React.Component<RouteComponentProps, TaxTranslatorState> {
  state: TaxTranslatorState;
  constructor(props: any) {
    super(props);
    this.state = {
      id: props.match.params.id,
      translator_type: 2,
      countries: [],
    };
    TranslatorValidations();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  formDataReset = (e: any) => {
    e.preventDefault();
    this.setState({
      translator_type: 0,
      is_vat_registered: 0,
      cvr_number: 0,
      company_name: "",
      company_address: "",
      account_no: "",
      vat_number: "",
      iban: "",
      swift: "",
      fee_type: 0,
      primary_income: 0,
      date_of_birth: "",
    });
  };

  handleChange = (event: React.ChangeEvent<any>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    } as any);
  };

  googleGetAddress = (place) => {
    const { address, city, lat, lng, zip_code } = getMapContent(place);
    this.setState({ company_address: address });
  };

  checkCvrDetails = () => {
    SwitchLoader(LoaderType.INLINE);
    API.get(`users/customers/get-details-of-cvr/${this.state.cvr_number}`).then((res2) => {
      this.setState({
        company_name: res2.data.name,
        company_address: res2.data.address + " " + res2.data.zipcode + " " + res2.data.city,
      });
    });
  };

  componentDidMount() {
    API.get(`users/translators/${this.state.id}`).then((res) => {
      const translatorData = res.data.translatorArr.translator;
      const userData = res.data.translatorArr;

      this.setState({
        countries: res.data.countryArr,
        translator_type: translatorData.translator_type,
        is_vat_registered: translatorData.is_vat_registered,
        cvr_number: translatorData.cvr_number,
        company_name: translatorData.company_name,
        company_address: translatorData.company_address,
        social_no: translatorData.social_no,
        bank_registration_no: translatorData.bank_registration_no,
        account_no: translatorData.account_no,
        vat_number: translatorData.vat_number,
        iban: translatorData.iban,
        swift: translatorData.swift,
        tax_country: translatorData.tax_country,
        fee_type: translatorData.fee_type,
        primary_income: translatorData.primary_income,
        date_of_birth: translatorData.date_of_birth,
      });
    });
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if ($("#create_translator").valid()) {
      API.put(`users/translators/${this.state.id}`, this.state).then((res) => {
        showNotification("success", Lang.translator_updated);
        {
          /*this.props.history.push(`/users/translators`);*/
        }
      });
    }
  };

  handleSelectChange = (event: React.ChangeEvent<HTMLElement>) => {
    const targetSelect: any = event.target;
    const value = targetSelect.value;
  };

  render() {
    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <Breadcrumb data={TaxBreadcrumb} />

        <div className="kt-portlet kt-portlet--mobile">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">{Lang.tax_payment}</h3>
                  </div>
                </div>
                <br />
                <form className="kt-form" onSubmit={this.handleSubmit} id={"create_translator"}>
                  <div className="kt-portlet__body">
                    <div className={"row"}>
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <div className={"row"}>
                            <div className={"col-md-12"}>
                              <label>
                                {Lang.translator_type} <span className={"required"}>*</span>
                              </label>
                              <Select
                                id="translator_type"
                                value={this.state.translator_type ? this.state.translator_type : 2}
                                name="translator_type"
                                onChange={this.handleChange}
                                className="form-control"
                              >
                                <option value="">{Lang.select}</option>
                                <option value={2}>{Lang.private_translator}</option>
                                <option value={1}>{Lang.public_translator}</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.translator_type == 1 && (
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>
                                  {Lang.is_vat_registered} <span className={"required"}>*</span>
                                </label>
                                <Select
                                  id="is_vat_registered"
                                  value={this.state.is_vat_registered}
                                  name="is_vat_registered"
                                  onChange={this.handleChange}
                                  className="form-control"
                                >
                                  <option value="">{Lang.select}</option>
                                  <option value={1}>{Lang.yes}</option>
                                  <option value={0}>{Lang.no}</option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.translator_type == 1 && this.state.is_vat_registered == 1 && (
                        <div className={"col-md-12 col-lg-12"}>
                          <div className={"row"}>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.cvr_number} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="cvr_number"
                                  value={this.state.cvr_number}
                                  name="cvr_number"
                                  onBlur={this.checkCvrDetails}
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`cvr_number`)}
                                />
                              </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.company_name} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="company_name"
                                  value={this.state.company_name}
                                  name="company_name"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`company_name`)}
                                />
                              </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.company_address} <span className={"required"}>*</span>
                                </label>
                                <GooglePlace
                                  onChooseAddress={this.googleGetAddress}
                                  name={"company_address"}
                                  defaultValue={this.state.company_address || ""}
                                  className="form-control"
                                  placeholder={Lang.getString(`company_address`)}
                                />
                              </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.social_security_no} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="social_no"
                                  value={this.state.social_no}
                                  name="social_no"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`social_security_no`)}
                                />
                              </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.bank_reg_no} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="bank_registration_no"
                                  value={this.state.bank_registration_no}
                                  name="bank_registration_no"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`bank_reg_no`)}
                                />
                              </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.bank_account_no} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="account_no"
                                  value={this.state.account_no}
                                  name="account_no"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`bank_account_no`)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.translator_type == 1 && this.state.is_vat_registered == 0 && (
                        <div className={"col-md-12 col-lg-12"}>
                          <div className={"row"}>
                            <div className={"col-md-6"}>
                              <label>
                                {Lang.country_of_origin} <span className={"required"}>*</span>
                              </label>
                              <Select
                                className="form-control"
                                value={this.state.tax_country || ""}
                                onChange={this.handleChange}
                                id="tax_country"
                                name="tax_country"
                              >
                                <option value="">{Lang.select}</option>
                                {this.state.countries &&
                                  this.state.countries.length > 0 &&
                                  this.state.countries.map((countries) => {
                                    return (
                                      <option key={countries.id} value={countries.id}>
                                        {countries.name}
                                      </option>
                                    );
                                  })}
                              </Select>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.vat_number} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="vat_number"
                                  value={this.state.vat_number}
                                  name="vat_number"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`vat_number`)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.translator_type == 2 && (
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <div className={"row"}>
                              <div className={"col-md-12"}>
                                <label>
                                  {Lang.fee_type} <span className={"required"}>*</span>
                                </label>
                                <Select
                                  id="fee_type"
                                  value={this.state.fee_type ? this.state.fee_type : ""}
                                  name="fee_type"
                                  onChange={this.handleChange}
                                  className="form-control"
                                >
                                  <option value="">{Lang.select}</option>
                                  <option value={1}>{Lang.fee_type_1}</option>
                                  <option value={2}>{Lang.fee_type_2}</option>
                                  <option value={3}>{Lang.fee_type_3}</option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.translator_type == 2 && this.state.fee_type == 1 && (
                        <div className={"col-md-12 col-lg-12"}>
                          <div className={"row"}>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.bank_reg_no} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="bank_registration_no"
                                  value={this.state.bank_registration_no}
                                  name="bank_registration_no"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`bank_reg_no`)}
                                />
                              </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.bank_account_no} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="account_no"
                                  value={this.state.account_no}
                                  name="account_no"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`bank_account_no`)}
                                />
                              </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.social_security_no} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="social_no"
                                  value={this.state.social_no}
                                  name="social_no"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`social_security_no`)}
                                />
                              </div>
                            </div>
                            <div className={"col-md-12 col-lg-12"}>
                              <p>
                                <i className={Icons.ban}></i> Obs. Der gøres opmærksom på, at man
                                selv skal betale skat af sin indkomst.
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.translator_type == 2 && this.state.fee_type == 2 && (
                        <div className={"col-md-12 col-lg-12"}>
                          <div className={"row"}>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <div className={"row"}>
                                  <div className={"col-md-12"}>
                                    <label>
                                      {Lang.primary_income} <span className={"required"}>*</span>
                                    </label>
                                    <Select
                                      id="primary_income"
                                      value={
                                        this.state.primary_income ? this.state.primary_income : ""
                                      }
                                      name="primary_income"
                                      onChange={this.handleChange}
                                      className="form-control"
                                    >
                                      <option value="">{Lang.select}</option>
                                      <option value={1}>{Lang.primary_income_1}</option>
                                      <option value={2}>{Lang.primary_income_2}</option>
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.bank_reg_no} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="bank_registration_no"
                                  value={this.state.bank_registration_no}
                                  name="bank_registration_no"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`bank_reg_no`)}
                                />
                              </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.bank_account_no} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="account_no"
                                  value={this.state.account_no}
                                  name="account_no"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`bank_account_no`)}
                                />
                              </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.social_security_no} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="social_no"
                                  value={this.state.social_no}
                                  name="social_no"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`social_security_no`)}
                                />
                              </div>
                            </div>
                            <div className={"col-md-12 col-lg-12"}>
                              <p>
                                <i className={Icons.ban}></i> Obs. Der gøres opmærksom på, at ens
                                takst vil blive fratrukket 12,5 % overført til ens personlige
                                feriekonto.
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {(Boolean(this.state.translator_type) && this.state.translator_type === 2
                        ? this.state.fee_type != null
                        : true) && (
                        <div className={"col-md-12 col-lg-12"}>
                          <div className={"row"}>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.date_of_birth} <span className={"required"}>*</span>
                                </label>
                                <DateofBirthInput
                                  name="date_of_birth"
                                  value={this.state.date_of_birth}
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`date_of_birth`)}
                                />
                              </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.iban} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="iban"
                                  value={this.state.iban}
                                  name="iban"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`iban`)}
                                />
                              </div>
                            </div>
                            <div className={"col-md-6 col-lg-6"}>
                              <div className="form-group">
                                <label>
                                  {Lang.swift} <span className={"required"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="swift"
                                  value={this.state.swift}
                                  name="swift"
                                  onChange={this.handleChange}
                                  placeholder={Lang.getString(`swift`)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="reset-btn-section">
                    <CommonSubmitButton />
                    <button
                      className={"btn btn-default hvr-rectangle-out btnMove reset-btn"}
                      data-skin={"dark"}
                      title={Lang.reset}
                      onClick={(e) => this.formDataReset(e)}
                    >
                      {Lang.reset}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
