import React, {useState, useEffect} from 'react';
import {commentsService, getTranslatorComments, postComments, aboutTranslatorService,onlineTestAnswerService} from "../../../services";
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {NoRecords, PleaseWait, SubmitButton} from "../../../common";
import { UserModel} from "../../../model";
import {AboutTranslatorModel} from "../../../model/AboutTranslator";

export function AboutTranslator() {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState({} as {translator_id: string|number, about: string});
  const [user, setUser] = useState({} as UserModel);
  const [content, setContent] = useState([] as Array<AboutTranslatorModel>);

  useEffect(() => {
    aboutTranslatorService.getPopupState().subscribe((res:any) => {
      const {show, user : {translator}, user} = res;
      setShow(show);
      if(show){
        setUser(user);
        setContent(user.translator.about);
        setLoader(true);
      }
    })
  }, []);

  if(show === false){
    return null;
  }
  return(
    <Modal
      show={show}
      onHide={() => setShow(false) }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {Lang.about_translator}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
              <div className={'col-md-12'} >
                <div className={'form-group'}>
                    {!content && <NoRecords/>}
                    {content && content.length > 0 && <p>{content}</p>}
                </div>
              </div>
            </div>
            <br/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={'btn btn-primary'} onClick={() =>  setShow(false) }>{Lang.close}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
