import React, { useEffect, useRef, useState } from 'react'
import { Icons } from '../../../common'

//? styles
import sheet from './CurrentJob.module.scss'
import { currentJob } from '../InvoicingType'
import moment from 'moment'

interface Props {
  currentJob: currentJob
}

const CurrentJob = ({ currentJob: { company: {name, logo}, created_at, updated_at, order_count, handled_orders_count } }: Props) => {
  const [ProgressText, setProgressText] = useState<string>('Progress')
  const minutesSinceStart = moment((new Date().toDateString())).diff(updated_at, 'minutes');
  useEffect(() => {
    const textUpdate = setInterval(() => {
      setProgressText((label) => label === 'Progress...' ? 'Progress.' : label.concat('.'))
    }, 1000)
    return () => clearInterval(textUpdate)
  }, [])
  return (
    <div className={sheet.container}>
      <div className={sheet.company_image}>
        <img src={logo} alt="quick care" />
      </div>
      <div className={sheet.company_details}>
        <h2 className={sheet.title}>{name}</h2>
        <div> <i className={`${Icons.calendar_plus} ${sheet.icon}`} /><span>{created_at}</span> </div>
        <div> <i className={`${Icons.clock_regular} ${sheet.icon}`} /><span>{minutesSinceStart} minutter siden</span> </div>
      </div>
      <div className={sheet.info}>
        <div>
          <h5>Total orders</h5>
          <span className={sheet.info_highlight}>{order_count}</span>
        </div>
        <div>
          <h5>Remaining</h5>
          <span className={sheet.info_highlight}>{order_count - handled_orders_count}</span>
        </div>
        <div>
          <h5>{ProgressText}</h5>
          <span className={sheet.info_highlight}>{Math.floor((handled_orders_count / order_count) * 100)}%</span>
        </div>
      </div>
    </div>
  )
}

export default CurrentJob