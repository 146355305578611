import {LanguageModel} from "./Language";
import {TranslationTypeModel} from "./TranslationType";
import {LanguageCertificateLevelsModel} from "./LanguageCertificateLevels";
import Lang from "../common/lang/Lang";

export interface TranslateLanguageModel {
  id : number;
  from_language_id : string;
  to_language_id : string;
  accent : string;
  mother_tongue : string|number;
  is_certificate : number|string;
  certificate : string;
  how_much_hours : number|string;
  level : number|string;
  user_id : number;
  status : number;
  from_language : LanguageModel;
  to_language : LanguageModel;
  language_level? : LanguageCertificateLevelsModel;
  translation_type?: TranslationTypeModel;
}

export const LangStatus = {
  ACTIVE: 1,
  PENDING: 2,
  REJECT:3
};