import * as React from 'react';
import {getLanguageList} from "../../../services";
import {
  getQueryStringParams,
  SearchSelect,
  SearchInput,
  LanguageFlags,
  Select2Wrapper, showNotification, Select, CONST
} from "../../../common";
import Lang from "../../../common/lang/Lang";
import API from "../../../common/AxoisClient";

interface IState {
  form: {
    like: {
        search: string,
        email: string,
        vdeleted_translator: string,
        police_confirmed: string,
        contract_signed: string,
        new_applicant: string,
    },
    equal: {
        status: number,
        gender: number,
        rating: number,
        from_language: number,
        to_language: number,
        location: number,
        extra_status: number,
    },
},
    languages_from?: Array<any>;
    languages_to?: Array<any>;
    locationList?: Array<any>;
 }

export default class TranslatorFilterInactive extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {
      getLanguageList().then(res => {
          API.get("users/translators/countries").then(res => {
              this.setState({
                  locationList: res.data.locationArr,
              } as any);
          });
          this.setState({
              languages_from: res,
              languages_to: res
          } as any);
      })

    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
        like: {
            search: old_data ? old_data.like.search : '',
            email: old_data ? old_data.like.email : '',
            vdeleted_translator: old_data ? old_data.like.vdeleted_translator : '',
            police_confirmed: old_data ? old_data.like.police_confirmed : '',
            contract_signed: old_data ? old_data.like.contract_signed : '',
            new_applicant: old_data ? old_data.like.new_applicant : '',
        },
        equal: {
            status: old_data ? old_data.equal.status : '',
            gender: old_data ? old_data.equal.gender : '',
            rating: old_data ? old_data.equal.rating : '',
            from_language: old_data ? old_data.equal.from_language : '',
            to_language: old_data ? old_data.equal.to_language : '',
            location: old_data ? old_data.equal.location : '',
            extra_status: old_data ? old_data.equal.extra_status : '',
        },
    }})
}

  handleInputForm = (e: React.ChangeEvent<any>) => {
      let name = e.target.name.split(".");
      let newAccess: any = Object.assign({}, this.state.form);
      let from_language = $('#from_lang').val();
      let to_language = $('#to_lang').val();
      if(from_language !== null && to_language !== null && from_language === to_language) {
          showNotification('danger', Lang.from_to_not_same);
      } else {
          if (e.target.type === 'checkbox') {
              if (e.target.checked === true) {
                  newAccess[name[1]][name[2]] = e.target.value;
              } else {
                  newAccess[name[1]][name[2]] = 0;
              }
          } else {
              newAccess[name[1]][name[2]] = e.target.value;
          }
          this.setState({form: newAccess}, () => this.props.getResults(this.state.form));
      }
  }


    formDataReset = (e:any) => { 
        e.preventDefault();
        this.setState({
            form:  {
                like: {
                    search: '',
                    email: '',
                    vdeleted_translator: '0',
                    police_confirmed:'0',
                    contract_signed: '0',
                    new_applicant: '0',
                },
                equal: {
                    from_language: 96,
                    to_language: 0,
                    extra_status: 0,
                    status: 0,
                    gender: 0,
                    rating: 0,
                    location: 0,
                }
            }
        });     

        var formData = {
                       like: {
                            search: '',
                            email: '',
                            vdeleted_translator: '0',
                            police_confirmed:'0',
                            contract_signed: '0',
                            new_applicant: '0',
                        },
                        equal: {
                            to_language: 0,
                            
                            extra_status: 0,
                            status: 0,
                            from_language: 96,
                            gender: 0,
                            location: 0,
                            rating: 0,
                        },
                       };

        this.setState({form: formData}, () => this.props.getResults(this.state.form));
    }



  render(){
    const ExtraFilter = [
      {'label': Lang.select_profile_status, "value": ""},
      {'label': Lang.contract_send, "value": 1},
      {'label': Lang.contract_signed, "value": 2},
      {'label': Lang.online_test_sent, "value": 3},
      {'label': Lang.online_test_taken, "value": 4},
    ];
    return (
      <div className="row align-items-center">
                                           
      <SearchInput name={'form.like.search'} value={this.state.form.like.search} onChange={this.handleInputForm} placeholder={Lang.search_user} />
      <SearchSelect name={'form.equal.status'} value={this.state.form.equal.status} onChange={this.handleInputForm} options={[{'label': Lang.select_status, "value": ""}, {'label': Lang.passive, "value": 2}, {'label': Lang.rejected, "value": 4}]}/>
        <SearchSelect name={'form.equal.extra_status'} value={this.state.form.equal.extra_status} onChange={this.handleInputForm} options={ExtraFilter}/>
      <SearchSelect name={'form.equal.gender'} value={this.state.form.equal.gender} onChange={this.handleInputForm} options={[{'label': Lang.select_gender, "value": ""}, {'label': Lang.male, "value": 1}, {'label': Lang.female, "value": 2}]}/>
      <SearchSelect name={'form.equal.rating'} value={this.state.form.equal.rating} onChange={this.handleInputForm} options={[{'label': Lang.select_rating, "value": ""}, {'label': " < = 1.0", "value": 1}, {'label': "< = 2.0", "value": 2}, {'label': "< = 3.0", "value": 3}, {'label': "< = 4.0", "value": 4}, {'label': "< = 5.0", "value": 5}]}/>
      <div className={'col-md-3 mb-3 kt-margin-b-20-tablet-and-mobile'}>
      <Select2Wrapper className={'form-control'}
          name={'form.equal.from_language'} id={'from_lang'} value={this.state.form.equal.from_language || ''} onChange={this.handleInputForm}
          data={{
              placeholder: Lang.select_source_lang,
              data: this.state.languages_from,
              templateResult: LanguageFlags,
              templateSelection: LanguageFlags,
          }}
      />
      </div>
          <div className={'col-md-3 mb-3 kt-margin-b-20-tablet-and-mobile'}>
            <Select2Wrapper className={'form-control'}
                            name={'form.equal.to_language'} id={'to_lang'}  value={this.state.form.equal.to_language || ''} onChange={this.handleInputForm}
                            data={{
                                placeholder: Lang.select_to_language,
                                data: this.state.languages_to,
                                templateResult: LanguageFlags,
                                templateSelection: LanguageFlags,
                            }}
            />
          </div>
          <div className={'col-md-3 mb-3 kt-margin-b-20-tablet-and-mobile'}>
              <Select onChange={this.handleInputForm} id={'form.equal.location'} name={'form.equal.location'}>
                  <option value="">{Lang.select}</option>
                  {
                      this.state.locationList &&
                      this.state.locationList.length > 0 &&
                      this.state.locationList.map((locations,index) => {
                          return <option value={locations.value}>{locations.text}</option>;
                      })
                  }
              </Select>

      </div>
    <div className={'col-12 mt-4'}>
      <label className="kt-checkbox"><input type="checkbox" id="police_confirmed" name="form.like.police_confirmed" value={'1'} onChange={this.handleInputForm} checked={(this.state.form.like.police_confirmed=='1')}/>  <label>{Lang.police_confirmed} </label>&nbsp;<span/>
      </label>
      <label className="kt-checkbox"><input type="checkbox" id="contract_signed" name="form.like.contract_signed" value={'2'} onChange={this.handleInputForm} checked={(this.state.form.like.contract_signed=='2')}/>  <label>{Lang.contract_signed} </label>&nbsp;<span/>
      </label>
      <label className="kt-checkbox"><input type="checkbox" id="new_applicant" name="form.like.new_applicant" value={'1'} onChange={this.handleInputForm} checked={(this.state.form.like.new_applicant=='1')}/>  <label>{Lang.new_applicant} </label>&nbsp;<span/>
      </label>
      <label className="kt-checkbox"><input type="checkbox" id="vdeleted_translator" name="form.like.vdeleted_translator"  onChange={this.handleInputForm} value={'1'} checked={(this.state.form.like.vdeleted_translator=='1')}/>  <label>{Lang.delete_translator_title} </label>&nbsp;<span/>
      </label>

      <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn custom-reset-button'} data-skin={'dark'} title={Lang.reset} onClick={(e)=>this.formDataReset(e)}>{Lang.reset}</button>
    </div>
  </div>

  
    )
  }
}