import { Subject } from 'rxjs';
import {OrderInvitation, OrderModel, UserModel, TranslateLanguageModel, TranslatorSpecialRatesModel, AboutTranslatorModel, TranslatorOnlineTestAnswerModel} from "../model";
import API from "../common/AxoisClient";
import {default as Lang} from "../common/lang/Lang";
import {showConfirmBox, showNotification, SwitchLoader} from "../common";
import {LoaderType} from "../common/Loader";

const subject = new Subject();

export const TranslatorService = {
  showPopup: (data: OrderModel) => {
    getOrderSentInvitations(data.id).then(res => {
      subject.next( {show: true, data: data, records:res } )
    });
  },
  hidePopup: () => subject.next({show: false, data: [] }),
  getPopupState: () => subject.asObservable()
};

const userprofile = new Subject();
  export const UserProfileService = {
   userprofiledata: () =>userprofile.next(),
   getPopupState: () => userprofile.asObservable()
    };



const onlineTest = new Subject();

export const onlineTestService = {
  showPopup: (data: UserModel) => {
    getMailData(data.id).then(res => {
      if(res == 0){
        showNotification('danger', Lang.online_test_not_found);
      } else {
        onlineTest.next( {show: true, content: res, user: data} )
      }
    })
  },
  hidePopup: () => onlineTest.next({show: false,  user: {} }),
  getPopupState: () => onlineTest.asObservable()
};


const languagelist = new Subject();
  export const languagelistService = {
   languagedata: () =>languagelist.next(),
   getPopupState: () => languagelist.asObservable()
    };


const LanguageAdd = new Subject();

export const languageAddService = {
   showPopup: (data: any) => {
    LanguageAdd.next( {show: true, content: {}, user: data} )
  },
  hidePopup: () => LanguageAdd.next({show: false,  user: {} }),
  getPopupState: () => LanguageAdd.asObservable()
};

const LanguageUpdate = new Subject();

export const languageUpdateService = {
   showPopup: (data: TranslateLanguageModel) => {
    getTranslatorLanguagelist(data.user_id).then(res => {
      LanguageUpdate.next( {show: true, content: res, user: data,language_id:data.id} )
    })

  },
  hidePopup: () => LanguageUpdate.next({show: false,  user: {} }),
  getPopupState: () => LanguageUpdate.asObservable()
};

const ratelist = new Subject();
  export const ratelistService = {
   ratedata: () =>ratelist.next(),
   getPopupState: () => ratelist.asObservable()
    };

const RatesAdd = new Subject();

export const ratesAddService = {
   showPopup: (data: any) => {
    RatesAdd.next( {show: true, content: {}, user: data} )
  },
  hidePopup: () => RatesAdd.next({show: false,  user: {} }),
  getPopupState: () => RatesAdd.asObservable()
};

const RatesUpdate = new Subject();

export const ratesUpdateService = {
  showPopup: (data: TranslatorSpecialRatesModel) => {
    RatesUpdate.next( {show: true, content: {}, user: data, rate_id:data.id} )
  },
  hidePopup: () => RatesUpdate.next({show: false,  user: {} }),
  getPopupState: () => RatesUpdate.asObservable()
};


const qualification = new Subject();

export const qualificationService = {
  showPopup: (data: UserModel) => qualification.next( {show: true, user: data} ),
  hidePopup: () => qualification.next({show: false,  user: {} }),
  getPopupState: () => qualification.asObservable()
};


const comments = new Subject();

export const commentsService = {
  showPopup: (data) => comments.next({show: true, user: data} ),
  hidePopup: () => comments.next({show: false,  user: {} }),
  getPopupState: () => comments.asObservable(),
};

const info = new Subject();
export const infoModalService = {
  showPopup: (data) => info.next({show: true, comment: data}),
  hidePopup: () => info.next({show: false, comment: ''}),
  getPopupState: () => info.asObservable(),
}


const about = new Subject();
export const aboutTranslatorService = {
  showPopup: (data: AboutTranslatorModel) => about.next( {show: true, user: data} ),
  hidePopup: () => about.next({show: false,  user: {} }),
  getPopupState: () => about.asObservable()
};



const onlineTestAnswer = new Subject();
export const onlineTestAnswerService = {
  showPopup: (data: TranslatorOnlineTestAnswerModel) => onlineTestAnswer.next({show:true,user:data}),
  hidePopup: () => onlineTestAnswer.next({show: false,  user: {} }),
  getPopupState: () => onlineTestAnswer.asObservable()
};


/**
 *
 * @param payload
 * @returns {Promise<{translatorsList: any}>}
 */
export const getTranslators = async (payload)  => {
  const {order_id} = payload;
  const res = await API.get(`/orders/orders/for-assign/${order_id}`, {params: payload});
  return res.data;
}


/**
 *  get today orders for translator
 *
 * @param payload
 * @returns {Promise<T>}
 */
export const getTodayOrdersForTranslator = async (payload)  => {
  const {translator_id, order_id} = payload;
  const res = await API.post(`/orders/order/translator/${translator_id}/${order_id}`, payload);
  return res.data;
}

/**
 *  assign translator for order
 *
 * @param payload
 * @returns {Promise<T>}
 */
export const assignTranslators = async (payload)  => {
  const {order_id} = payload;
  const res = await API.post(`/orders/orders/for-assign/${order_id}`, payload);
  return res.data;
}

export const getInvitedTranslators = (invitations: Array<OrderInvitation>) => {
  let ids = [];
  ids = invitations.map(item => {
    return item.user_id;
  })
  return ids;
}

export const getRejectedTranslators = (invitations: Array<OrderInvitation>) => {
  let ids = [];
  ids = invitations.map(item => {
    if(item.status==2){
      return item.user_id;
    }
  })
  return ids;
}

/**
 * login details on sms
 *
 * @param trans_id
 * @returns {Promise<T>}
 */
export async function smsLogin(trans_id) {
  showConfirmBox(Lang.sms_login_confirm, async () => {
    const res = await API.post(`/users/translator/sms-login/${trans_id}`);
    showNotification('success', res.data);
  })
}

export async function getMailData(trans_id) {
  const res = await API.get(`/users/translator/send-online-test/${trans_id}`);
  return res.data;
}

/**
 * sent online test to translator
 *
 * @param content
 * @returns {Promise<T>}
 */
export async function sendOnlineTestMail(content) {
  let formData = new FormData();
  Object.keys(content).map((item) => {
    formData.append(item, content[item]);
  });
  SwitchLoader(LoaderType.BUTTON);
  const res = await API.post(`/users/translator/send-online-test/${content.trans_id}`, formData);
  return res.data;
}

/**
 *  save qualifications
 *
 * @param user_id
 * @param form
 * @returns {Promise<T>}
 */
export async function saveQualificationData(user_id, form){
  SwitchLoader(LoaderType.BUTTON);
  const res = await API.post(`/users/translator/save-qualifications/${user_id}`, form);
  return res.data;
}

// get comments of translators
export async function getTranslatorComments(user_id) {
  const res = await API.get(`/users/translator/comments/${user_id}`,);
  return res.data;
}

// post comments
export async function postComments(user_id, form) {
  SwitchLoader(LoaderType.BUTTON);
  const res = await API.post(`/users/translator/comments/${user_id}`, form);
  return res.data;
}

// get translator language list
export async function getTranslatorLanguage(user_id) {
  SwitchLoader(LoaderType.NONE);
  const res = await API.get(`/users/translator/language-list/${user_id}`);
  return res.data;
}

export async function getTranslatorRate(user_id) {
  SwitchLoader(LoaderType.NONE);
  const res = await API.get(`/users/translator/rate-list/${user_id}`);
  return res.data;
}

// get translator language list
export async function getTranslatorLanguagelist(user_id) {
  SwitchLoader(LoaderType.NONE);
  const res = await API.get(`/users/translator/translator-language-list/${user_id}`);
  return res.data;
}

export async function getTranslatorRatelist(user_id) {
  SwitchLoader(LoaderType.NONE);
  const res = await API.get(`/users/translator/translator-rate-list/${user_id}`);
  return res.data;
}

// get translator language list
export async function FromTranslatorLanguage(user_id) {
  SwitchLoader(LoaderType.NONE);
  const res = await API.get(`/users/translator/from-translator-language/${user_id}`);
  return res.data;
}

export async function ToTranslatorLanguage(user_id) {
  SwitchLoader(LoaderType.NONE);
  const res = await API.get(`/users/translator/to-translator-language/${user_id}`);
  return res.data;
}

// get leaves list of translator
export async function getTranslatorLeaves(user_id) {
  const res = await API.get(`/users/translator/leaves-list/${user_id}`);
  return res.data;
}

// get leaves list of translator
export async function languageActions(data) {
  const res = await API.post(`/users/translator/language-actions`, data);
  return res.data;
}

export async function rateActions(data) {
  const res = await API.post(`/users/translator/rate-actions`, data);
  return res.data;
}


export async function getOnlineTestAnswers($trans_id){
  const res = await API.get(`/users/translator/online-test-answers/${$trans_id}`);
  return res.data;
}

export async function getOnlineQuizzes($trans_id){
  const res = await API.get(`/users/translator/online-quiz-answers/${$trans_id}`);
  return res.data;
}

export async function getOrderSentInvitations(order_id: number) {
  const res  = await  API.get(`orders/get-sent-invitations/${order_id}`);
  return res.data;
}
