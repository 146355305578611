import * as React from 'react';
import {CreateBreadcrumb} from "./Data";
import Lang from "../../common/lang/Lang";
import API from './../../common/AxoisClient';
import {SMSValues,SearchValues} from "../../model/SMS";
import * as _ from 'lodash';
import {RouteComponentProps} from "react-router";
import {EmailValidations} from "./Validation";
import {CreateLayout} from "../../layout/CreateLayout";
import {UserModel} from "../../model/User";
import {CommonSubmitButton, InlineLoader, Select} from "../../common";
import {showNotification,SwitchLoader} from "../../common";
import {LoaderType} from "../../common/Loader";
import {CountryFlags, Select2Wrapper} from "../../common/Select2";
import { CustomerBreadcrumb } from '../../users/customers/Data';
import { Editor } from '@tinymce/tinymce-react';


declare var window: any;

interface IState{
  	form: {  
	  	subject : string,
	  	message : string,
	  	userIds : Array<any>,
	  	translator: number,
	 	customer: number,
	 	language_from? : number,
	 	language_to? : number,
	};

	searchVal:{
	  	query : string,
	  	customertypeUsers : number,
	  	translatortypeUsers : number,
	  	userlanguagefrom : number,
	  	userlanguageto : number,
	  	userStatus : string,
	  	user_type : number,
	};

 	allUsers: Array<any>;
  	id:number;
  	user_type :number;
  	userIds : Array<any>;
  	uploadDocument?: File;
  	languagecodes: Array<any>;
  	typeUsers? : number;
  	userStatus? : string;
  	is_allt: boolean;
  	is_allc: boolean;
  	all_selected : boolean;
 	selectallIds : Array<any>;
  	usermessage : boolean;  
}

export class SMSCreate extends React.Component<RouteComponentProps, IState> {
  	state: IState;
  
  	constructor(props: any){
    	super(props);
	    this.state = {
	      	form: SMSValues(),
	      	searchVal : SearchValues,
	      	id: props.match.params.id,
	      	allUsers: [],
	      	user_type:0,
		    typeUsers : 0,
		    userIds :[],
		    uploadDocument : null,
		    languagecodes:[],
		    userStatus: '',
		    selectallIds : [],
		    is_allt:false,
		    is_allc:false,
		    all_selected: false,
		    usermessage : false
	    } 
 	}

  
  	getData = () => {
	    if (this.state.searchVal.query !== '') {
	        API.get(`sms/get-allusers`, {
	            params: this.state.searchVal
	        }).then(res => {

	            if (res.data) {
	                this.setState({
	                    allUsers: res.data,

	                });
	            }
	        });

	    }
	}

	componentDidMount() {
	    API.get("sms/get-alllanguage").then(res => {
	        this.setState({
	            languagecodes: res.data.languageCodeArr
	        }, () => {
	            ($(".selectpicker") as any).selectpicker('refresh');
	        });

	    });
	}

	onChangesearch = (event: React.ChangeEvent <any> ) => {
	    if (event.target.value == '') {
	        this.setState({
	            usermessage: false,
	            searchVal: {
	                ...this.state.searchVal,
	                query: event.target.value
	            },
	            allUsers: event.target.value
	        });
	    } else {
	        this.setState({
	            usermessage: true,
	            searchVal: {
	                ...this.state.searchVal,
	                query: event.target.value
	            },
	        }, this.getData);
	    }
	}
	onChangeallselect = (event: React.ChangeEvent <any> ) => {
	    const targetCheck = event.target;
	    const value = targetCheck.value;
	    const name = targetCheck.name;

	    let selectIds = [];
	    this.state.allUsers.map((item: UserModel, index: number) => {
	        selectIds.push(item.id);
	    })

	    if (event.target.checked === true) {
	        this.setState({
	            all_selected: true,
	            form: {
	                ...this.state.form,
	                userIds: selectIds
	            },
	            selectallIds: selectIds
	        });

	    } else {

	        this.setState({
	            all_selected: false,
	            form: {
	                ...this.state.form,
	                userIds: []
	            },
	            selectallIds: []
	        });
	    }
	}

	handleChange = async (event: React.ChangeEvent <HTMLElement> ) => {
	    const target: any = event.target;
	    let value = target.value;
	    const name = target.name;
	    if (name === 'userIds') {

	        if (target.checked === true) {

	            await this.setState(prevState => ({
	                selectallIds: [...prevState.selectallIds, Number(value)]
	            }));
	        } else {

	            await this.setState({
	                selectallIds: this.state.selectallIds.filter(function(item: number) {
	                    return item !== Number(value)
	                })
	            });
	        }


	        let userIds = this.state.form.userIds;

	        if (_.indexOf(userIds, value) > -1) {
	            userIds = _.pull(userIds, value);
	        } else {
	            userIds = _.concat(userIds, value);
	        }

	        value = _.compact(userIds);
	    }
	    if (name === 'customer') {
	        if (target.checked === true) {

	            this.setState({
	                is_allc: true,
	                searchVal: {
	                    ...this.state.searchVal,
	                    customertypeUsers: value
	                },
	                form: {
	                    ...this.state.form,
	                    customer: 1
	                }
	            }, this.getData);

	        } else {

	            this.setState({
	                is_allc: false,
	                searchVal: {
	                    ...this.state.searchVal,
	                    customertypeUsers: 0
	                },
	            }, this.getData);
	        }
	    }
	    if (name === 'translator') {
	        if (target.checked === true) {
	            this.setState({
	                is_allt: true,
	                searchVal: {
	                    ...this.state.searchVal,
	                    translatortypeUsers: value
	                },

	                form: {
	                    ...this.state.form,
	                    translator: 2
	                }
	            }, this.getData);

	        } else {

	            this.setState({
	                is_allt: false,
	                form: {
	                    ...this.state.form,
	                    translator: 1
	                },
	                searchVal: {
	                    ...this.state.searchVal,
	                    translatortypeUsers: 0
	                }
	            }, this.getData);

	        }
	    }
	    if (name === 'status') {
	        this.setState({
	            searchVal: {
	                ...this.state.searchVal,
	                userStatus: value
	            },
	        }, this.getData);
	    }
	    if (name === 'language_from') {
	        this.setState({
	            searchVal: {
	                ...this.state.searchVal,
	                userlanguagefrom: value
	            },
	        });
	    }
	    if (name === 'language_to') {

	        this.setState({
	            searchVal: {
	                ...this.state.searchVal,
	                userlanguageto: value
	            },
	        });
	    }
	    if (name !== 'translator')
	        this.setState({
	            form: {
	                ...this.state.form,
	                [name]: value
	            }
	        });
	}

	handleCheckboxChange = (event: React.ChangeEvent < any > ) => {

	    const targetCheck = event.target;
	    const checkValue = targetCheck.value;
	    const name = targetCheck.name;


	    if (event.target.checked === true) {
	        if (checkValue == 1) {

	            this.setState({[name]: checkValue,is_allt: true,is_allc: true,form: {...this.state.form,translator: 2,customer: 1},} as any);
	        } else {
	            this.setState({[name]: checkValue,is_allt: false,is_allc: false,form: {...this.state.form,translator: 1},searchVal: {...this.state.searchVal,user_type: 2},}as any);
	        }
	    } else {

	        this.setState({[name]: 0,}as any);
	    }
	}

	handleSubmit = (event: React.FormEvent < HTMLFormElement > ) => {
	    event.preventDefault();
	    SwitchLoader(LoaderType.BUTTON);
	    if ($("#customer_form").valid()) {
	        API.post('/sms/sms', this.state.form).then((res) => {
	            showNotification("success", Lang.sms_msg);
	            this.props.history.push(`/sms/sms`)
	        })
	    }
	}

	resetForm = (event:any) => {
        event.preventDefault(); 
        var message = document.getElementById("message") as HTMLTextAreaElement;
        message.value = '';

        this.setState({
        	form: {
                ...this.state.form,
			  	message : '',
			  	userIds : [],
			  	translator: 0,
			 	customer: 0,
			 	language_from : 0,
			 	language_to : 0,
			},
			searchVal:{
			  	query : '',
			  	customertypeUsers : 0,
			  	translatortypeUsers : 0,
			  	userlanguagefrom : 0,
			  	userlanguageto : 0,
			  	userStatus : '',
			  	user_type : 0,
			},
			allUsers: [],
		  	user_type :0,
		  	userIds : [],
		  	typeUsers:0,
		  	userStatus : '',
		  	is_allt: false,
		  	is_allc: false,
		  	all_selected : false,
		 	selectallIds : [],
		  	usermessage : false,

	    });
    }
  
  render() {
     
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'sms'}>
           
            <form className="kt-form" id={'customer_form'} onSubmit={this.handleSubmit}>
                <div className="kt-portlet__body">
                    <div className={'row'}>
                   <div className={"col-md-12 col-lg-12"}>
                     <div className={'row'} >
                    <div className={"col-md-6 col-lg-6"}>

                    <div className="form-group">
                    
                    <label className="d-block" >{Lang.select_users} <span className={'required'}>*</span></label>
                    <label className="kt-checkbox">
                    <input type="radio"  value={1}  onChange={this.handleCheckboxChange}   name="user_type" /><label>{Lang.all_users} </label>&nbsp;
                    <span></span>
                   </label>
                   <label className="kt-checkbox">
                    <input type="radio"  value={2}  id="is_user_type" onChange={this.handleCheckboxChange} checked={this.state.user_type>1} name="user_type" /><label>{Lang.specific_users} </label>&nbsp;
                    <span></span>
                   </label>
                       
                    </div>
                


                    <div className="form-group">
                    <label className="kt-checkbox">
                    <input type="checkbox"  value={2}   onChange={this.handleChange} checked={this.state.is_allt}  name="translator"/><label>{Lang.translators} </label>&nbsp;
                    <span></span>
                   </label>
                   <label className="kt-checkbox">
                    <input type="checkbox"  value={1}   onChange={this.handleChange} checked={this.state.is_allc} name="customer" /><label>{Lang.customers} </label>&nbsp;
                    <span></span>
                   </label>
                       
                    </div>
                    {this.state.form.translator>1 &&  
                    <div className="form-group">
                    <div className={"col-md-12 col-lg-12"}>
                    <div className={'row'} >
                    <div className={"col-md-6 col-lg-6"}>
                                <label>{Lang.language_from} <span className={'required'}>*</span></label>
                               <Select2Wrapper value={this.state.form.language_from} onChange={this.handleChange}
                                        className={'form-control'} id={'language'} name={'language_from'}
                                        data={{
                                            placeholder: "Select Options",
                                            data: this.state.languagecodes,
                                            templateResult: CountryFlags,
                                            templateSelection: CountryFlags,
                                        }}
                                   />
                                   </div>
                                   <div className={"col-md-6 col-lg-6"}>
                                   <label>{Lang.language_to} <span className={'required'}>*</span></label>
                                     <Select2Wrapper value={this.state.form.language_to} onChange={this.handleChange}
                                        className={'form-control'} id={'language_to'} name={'language_to'}
                                        data={{
                                            placeholder: "Select Options",
                                            data: this.state.languagecodes,
                                            templateResult: CountryFlags,
                                            templateSelection: CountryFlags,
                                        }}
                                   />    
                                   </div>   
                              </div> 
                              </div>
                              </div>
                              }
                              <div className="form-group">
                                <label>{Lang.user_status} <span className={'required'}></span></label>
                                <select className={'form-control '} onChange={this.handleChange}   name="status">
                                <option defaultValue=''>Select option</option>
                                      <option value='1'>Active</option>
                                        <option value='2'>Inactive</option>                           
                                </select>
                            </div>
                        
                            {/* <div className="form-group">
                                <label>{Lang.subject} <span className={'required'}>*</span></label>
                                <input type={'text'} onChange={this.handleChange} name="subject" className="form-control"  />
                            </div> */}
                        
                        <div className="form-group">
                                <label>{Lang.message_content} <span className={'required'}>*</span></label>
                               <textarea  onChange={this.handleChange} name="message" id="message" className="form-control"  />
                                
                            </div>
                       

                    </div>
                    <div className={"col-md-6 col-lg-6"}>
                    
                    {this.state.user_type>1 &&

                    <div className="form-group">
                      <input type="text" placeholder="Search for..." value={this.state.searchVal.query} onChange={this.onChangesearch} className="form-control" />
                      <div className="kt-checkbox-inline">
                      { this.state.allUsers.length>0 &&
                    <label className="kt-checkbox"><input type ="Checkbox" onChange={this.onChangeallselect} name={'select_all'} value={'all'}   />Select all<span></span></label>}

                      {this.state.allUsers.length === 0 && this.state.usermessage === true &&
                        <div className="alert alert-outline-warning fade show" >Users not found</div> 
                      }         
                     {this.state.allUsers.length>0 && this.state.allUsers.map((item:UserModel, index: number) => {
                       return <label key={item.id} className="kt-checkbox">
                          <input type ="Checkbox" onChange={this.handleChange}name={'userIds'} value={item.id} 
                          checked={_.indexOf(this.state.selectallIds, item.id) > -1}  />{item.name}
                    <span></span>
                    </label>
                 })
                
                 }
                 
                    </div>
                  </div>
                     }

                    </div>
                    </div>
                    </div>
                </div>
                </div>
     			<div className='reset-btn-section'>
                    <CommonSubmitButton/>
                    <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn'} data-skin={'dark'} title={Lang.reset} onClick={(e) => {this.resetForm(e)}}>{Lang.reset}</button>
                </div>

            </form>
        </CreateLayout>
   
   )
  }
}