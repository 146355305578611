import React from 'react'

// styles
import sheet from './InfoPill.module.scss'

interface InfoPillInterface {
  label: string
  color?: AvailableColors
  text_color?: "light" | "dark"
}

export enum AvailableColors {
  green = "green",
  red = "red",
  blue = "blue",
  grey = "grey"
}

const InfoPill = ({ label, color = AvailableColors.blue, text_color = "light" }: InfoPillInterface) => {
  const { blue, green, red, grey } = AvailableColors
  const palette = {
    [blue]: 'steelblue',
    [red]: '#FF0000',
    [green]: '#2A9798',
    [grey]: "#EBEBEB"
  }
  return (
    <span
      className={sheet.info_pill}
      style={{
        background: palette[color],
        color: text_color === 'light' ? "white" : "#4D5373"
      }}
    >
        {label}
    </span>
  )
}

export default InfoPill