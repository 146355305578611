
import {UserModel} from "./User";

export interface TranslatorLeaveModel {
  id : number;
  leave_type : number;
  from_date : string;
  to_date : string;
  from_time: string;
  to_time: string;
  reason : string;
  type_of_half_day : number;
  user_id : any;
  status : number;
  user? : UserModel;
}

export const leaveTypes = {
  FULL_DAY: 1,
  HALF_DAY: 2,
  MULTIPLE: 3,
};

export const LeaveTitle = {
  [leaveTypes.FULL_DAY]: "Full Day",
  [leaveTypes.HALF_DAY]: "Half Day",
  [leaveTypes.MULTIPLE]: "Time Span",
};

export const HalfDayTypes = {
  FIRST_HALF: 0,
  SECOND_HALF: 1,
};

export const HalfDayTitle = {
  [HalfDayTypes.FIRST_HALF]: "First Half",
  [HalfDayTypes.SECOND_HALF]: "Second Half",
};

export const LeaveStatus = {
  APPROVE: 1,
  PENDING: 0,
  REJECT:3
};