import React, {useReducer} from 'react';
import {OrderModel} from "../../../model";
import {OrderFixPriceModel} from "../../../model";

// all actions for assign translator
export enum ActionType {
  SHOW_POPUP = 'SHOW_POPUP',
  HIDE_POPUP = 'HIDE_POPUP',
  HANDLE_CHANGE = 'HANDLE_CHANGE',
  ADD_FIX_PRICE = 'ADD_FIX_PRICE',
}

export interface PopupState  {
  show: boolean;
  order_data: OrderModel;
  order_id: number;
  fixPrice: Array<OrderFixPriceModel>;
  form: {
    isAgreementSet: boolean;
    is_special_company?: number;
    old_rate?: number;
    old_sub_rate?: number;
    old_total?: any;
    difference?: any;
    fix_price: any;
    is_special_agreement: number;
    physical_fix_rate?: number;
    physical_fix_minute?: number;
    physical_sub_rate?: number;
    physical_sub_minute?: number;
    telephone_fix_rate?: number;
    telephone_fix_minute?: number;
    telephone_sub_rate?: number;
    telephone_sub_minute?: number;
    video_fix_rate?: number;
    video_fix_minute?: number;
    video_sub_rate?: number;
    video_sub_minute?: number;
    extra_minutes_slots?: number;
    total_strokes?: number;
    tel_message_fix_rate?: number;
    min_word?: number;
    per_word_rate?: number;
    total_words?: number;
    compensation_agreed?: number;
    min_km?: number;
    per_km_rate?: number;
    old_per_km_rate?: number;
    remain_km?: number;
    total_km_rate?:number;
    transport_fee_agreed?: number;
    transport_fee_fix_rate?: number;
    old_transport_fee_fix_rate?: number;
    extra_transport_minutes_slots?: number;
    transport_fee_minute?: number;
    transport_fee_sub_rate?: number;
    old_transport_fee_sub_rate?: number;
    transport_fee_sub_minute?: number;
    holiday_charge_agreed?: number;
    extra_holiday_percentage?: number;
    extra_per_rate?: number;
    total_extra_rates?: number;
    extra_transport_rate?: number;
    total_per_word_rate?: number;
    total_rate?: number;
    total_time?: number;
    from: string;
    extra_consider_amt?: number;
  }
}

export interface CAction {
  type: ActionType;
  payload?: PopupState
}

export const InitialState: PopupState = {
  show: false,
  order_data: {} as OrderModel,
  fixPrice: [],
  order_id: 0,
  form: {
    isAgreementSet: false,
    is_special_company: 0,
    old_rate: 0,
    old_sub_rate: 0,
    old_total: '0,00',
    difference: '0,00',
    fix_price: '0,00',
    is_special_agreement: 0,
    physical_fix_rate: 0,
    physical_fix_minute: 0,
    physical_sub_rate: 0,
    physical_sub_minute: 0,
    telephone_fix_rate: 0,
    telephone_fix_minute: 0,
    telephone_sub_rate: 0,
    telephone_sub_minute: 0,
    video_fix_rate: 0,
    video_fix_minute: 0,
    video_sub_rate: 0,
    video_sub_minute: 0,
    total_strokes: 0,
    tel_message_fix_rate: 0,
    min_word: 0,
    per_word_rate: 0,
    total_words:0,
    compensation_agreed: 0,
    min_km: 0,
    per_km_rate: 0,
    old_per_km_rate: 0,
    remain_km: 0,
    total_km_rate:0,
    transport_fee_agreed: 0,
    transport_fee_fix_rate: 0,
    old_transport_fee_fix_rate:0,
    transport_fee_minute: 0,
    transport_fee_sub_rate: 0,
    old_transport_fee_sub_rate:0,
    transport_fee_sub_minute: 0,
    holiday_charge_agreed: 0,
    extra_holiday_percentage: 0,
    extra_per_rate: 0,
    total_extra_rates: 0,
    extra_transport_rate: 0,
    total_per_word_rate: 0,
    total_rate: 0,
    total_time: 0,
    extra_minutes_slots: 0,
    extra_transport_minutes_slots: 0,    
    from: '',
    extra_consider_amt: 0
  }
};

// reducer for fix price popup

export function FixPriceReducer <PopupState, CAction> (state, action) {
  switch (action.type) {
    // show popup and set order data
    case ActionType.SHOW_POPUP:
      return {...state, show: true, order_data:action.payload.data, fixPrice: action.payload.fixPrice.results, order_id: action.payload.data.id, form: {...state.form, from: action.payload.from}};

    // hide popup and blank old state
    case ActionType.HIDE_POPUP:
      return {...state, ...InitialState};

      // handle change of mail drop-down
    case ActionType.HANDLE_CHANGE:
      const {name, value} = action.payload;
      return {...state, form: {...state.form, [name]: value}};

    case ActionType.ADD_FIX_PRICE:
      return {...state, show: true, order_data:action.payload.data, fixPrice: action.payload.fixPrice.results, order_id: action.payload.data.id, form: {...state.form, from: action.payload.from}};


    default:
      throw new Error();
  }
}

export function FixPriceValidations() {
  $(document).ready(function () {
    $( "#fix_price_form" ).validate({
      rules: {
        fix_price: {
          required: true,
          pattern: /^([-+] ?)?[0-9]+(,[0-9]+)?$/
        },
      },
    });
  });
}