import API from "../common/AxoisClient";
import {LoaderType} from "../common/Loader";
import {showConfirmBox, showNotification,SwitchLoader} from "../common";
import {default as Lang} from "../common/lang/Lang";
import { CompanyModel } from "../model";


/**
 * check special company
 * @param email
 * @returns {Promise<T>}
 */
export async function isSpecialCompany(email) {
  SwitchLoader(LoaderType.INLINE);
  const res  = await  API.get(`settings/company/check-special-company-email/${email}`);
  return res.data;
}

export async function getCustomerInfo(id) {
  const res  = await API.get(`users/customers/${id}`);
  return res.data;
}

export const getCompanyList = async (email: string): Promise<CompanyModel[]> => (await API.get(`settings/company/special-company-list/${email}`)).data

export async function Sendemail(id) {
  showConfirmBox(Lang.sms_login_confirm, async () => {
    const res = await API.get(`/users/customers/sendemail-logindetail/${id}`);
    showNotification('success', Lang.send_login_email);
  })
}
