import React, { useRef, useState } from "react";
import { default as Lang } from "../../common/lang/Lang";
import { GeneratePermitLink, Icons, showNotification } from "../../common";
import Swal from "sweetalert2";
import API from "../../common/AxoisClient";
import {
  commentsService,
  aboutTranslatorService,
  onlineTestService,
  qualificationService,
  smsLogin,
  onlineTestAnswerService,
} from "../../services";
// import {TranslatorOnlineTest} from "./OnlineTest";

export function TranslatorActions(props) {
  function approve(event: any, translator_id: number, status: number) {
    Swal.fire({
      title: Lang.is_sure,
      text: Lang.sure_approve,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.value) {
        API.post("users/translator/approve", {
          translator_id: translator_id,
          status: status,
        }).then((response) => {
          status === 1
            ? showNotification("success", Lang.success_approve)
            : showNotification("success", Lang.success_unapprove);
        });
      }
    });
  }
  return (
    <span style={{ overflow: "visible", position: "relative", width: "80" }}>
      <div className="dropdown">
        <a
          data-toggle="dropdown"
          className="btn btn-sm btn-clean btn-icon btn-icon-md"
          aria-expanded="false"
        >
          <i className="flaticon-more-1" />
        </a>
        <div
          className="dropdown-menu dropdown-menu-right"
          x-placement="bottom-end"
        >
          <ul className="kt-nav">
            <li className="kt-nav__item">
              <GeneratePermitLink
                className="kt-nav__link"
                scope={"translator.update"}
                to={`/users/translators/info/${props.translator_data.id}/order?form={"like":{"q":""},"between":{"start":""},"equal":{"translator_id":"${props.translator_data.id}"}}`}
              >
                <i className={`${Icons.view}`} />
                &nbsp;&nbsp;
                <span className="kt-nav__link-text">{Lang.see_orders}</span>
              </GeneratePermitLink>
            </li>

            <li className="kt-nav__item">
              <GeneratePermitLink
                className="kt-nav__link"
                scope={"translator.update"}
                to={"#"}
                onClick={() => smsLogin(props.translator_data.id)}
              >
                <i className={`${Icons.sms}`} />
                &nbsp;&nbsp;
                <span className="kt-nav__link-text">{Lang.sms_login}</span>
              </GeneratePermitLink>
            </li>

            {props.translator_data.status === 2 && (
              <li className="kt-nav__item">
                <GeneratePermitLink
                  className="kt-nav__link"
                  scope={"translator.update"}
                  to={"#"}
                >
                  <i className={`${Icons.user_tick}`} />
                  &nbsp;&nbsp;
                  <span
                    className="kt-nav__link-text"
                    onClick={(e) => approve(e, props.translator_data.id, 1)}
                  >
                    {Lang.approve}
                  </span>
                </GeneratePermitLink>
              </li>
            )}
            {props.translator_data.status === 1 && (
              <li className="kt-nav__item">
                <GeneratePermitLink
                  className="kt-nav__link"
                  scope={"translator.update"}
                  to={"#"}
                >
                  <i className={`${Icons.user_tick}`} />
                  &nbsp;&nbsp;
                  <span className="kt-nav__link-text">{Lang.approved}</span>
                </GeneratePermitLink>
              </li>
            )}
            {(props.translator_data.translator.send_contract === 2 ||
              props.translator_data.translator.send_contract === null) && (
              <li className="kt-nav__item">
                <GeneratePermitLink
                  className="kt-nav__link"
                  scope={"translator.update"}
                  to={"#"}
                >
                  <i className={`${Icons.contract}`} />
                  &nbsp;&nbsp;
                  <span className="kt-nav__link-text" onClick={props.showTPop}>
                    {props.translator_data.translator.send_contract === 2
                      ? Lang.show_contract
                      : Lang.send_contract}
                  </span>
                </GeneratePermitLink>
              </li>
            )}

            {props.translator_data.translator.send_contract === 1 && (
              <li className="kt-nav__item">
                <GeneratePermitLink
                  className="kt-nav__link"
                  scope={"translator.update"}
                  to={"#"}
                >
                  <i className={`${Icons.contract}`} />
                  &nbsp;&nbsp;
                  <span className="kt-nav__link-text" onClick={props.showTPop}>
                    {Lang.resend_contract}
                  </span>
                </GeneratePermitLink>
              </li>
            )}
            {props.translator_data.translator.send_online_test !== 2 && (
              <li className="kt-nav__item">
                <GeneratePermitLink
                  className="kt-nav__link"
                  scope={"translator.update"}
                  to={"#"}
                  onClick={() => {
                    onlineTestService.showPopup(props.translator_data);
                  }}
                >
                  <i className={`${Icons.notes}`} />
                  &nbsp;&nbsp;
                  <span className="kt-nav__link-text">{Lang.online_test}</span>
                </GeneratePermitLink>
              </li>
            )}

            {!props.translator_data.translator.interview_taken_at &&
              props.translator_data &&
              props.translator_data.translator &&
              props.translator_data.translator.send_online_test &&
              props.translator_data.translator.send_online_test == 2 && (
                <li className="kt-nav__item">
                  <GeneratePermitLink
                    className="kt-nav__link"
                    scope={"translator.update"}
                    to={"#"}
                    onClick={() => {
                      onlineTestAnswerService.showPopup(props.translator_data);
                    }}
                  >
                    <i className={`${Icons.notes}`} />
                    &nbsp;&nbsp;
                    <span className="kt-nav__link-text">Svar på GTEP</span>
                  </GeneratePermitLink>
                </li>
              )}

            {/* <li className="kt-nav__item">
                  <GeneratePermitLink className="kt-nav__link" scope={'translator.update'} to={'#'} onClick={() => {qualificationService.showPopup(props.translator_data)}}>
                    <i className={`${Icons.notes}`} />&nbsp;&nbsp;<span className="kt-nav__link-text">{Lang.qualification}</span>
                  </GeneratePermitLink>
                </li> */}
            <li className="kt-nav__item">
              <GeneratePermitLink
                className="kt-nav__link"
                scope={"translator.update"}
                to={"#"}
                onClick={() => {
                  commentsService.showPopup(props.translator_data);
                }}
              >
                <i className={`${Icons.comment}`} />
                &nbsp;&nbsp;
                <span className="kt-nav__link-text">{Lang.comments}</span>
              </GeneratePermitLink>
            </li>
            <li className="kt-nav__item">
              <GeneratePermitLink
                className="kt-nav__link"
                scope={"translator.update"}
                to={"#"}
                onClick={() => {
                  aboutTranslatorService.showPopup(props.translator_data);
                }}
              >
                <i className={`${Icons.comment}`} />
                &nbsp;&nbsp;
                <span className="kt-nav__link-text">
                  {Lang.about_translator}
                </span>
              </GeneratePermitLink>
            </li>

            <li className="kt-nav__item">
              <GeneratePermitLink
                className="kt-nav__link"
                scope={"translator.update"}
                to={"#"}
              >
                <i className={`${Icons.users}`} />
                &nbsp;&nbsp;
                <span className="kt-nav__link-text" onClick={props.showSPop}>
                  {Lang.translator_calling}
                </span>
              </GeneratePermitLink>
            </li>
            <li className="kt-nav__item">
              <GeneratePermitLink
                className="kt-nav__link"
                scope={"translator.update"}
                to={"#"}
              >
                <i className={`${Icons.notes}`} />
                &nbsp;&nbsp;
                <span className="kt-nav__link-text" onClick={props.showDPop}>
                  {Lang.documents}
                </span>
              </GeneratePermitLink>
            </li>
            {/* INTERVIEW */}
            <li className="kt-nav__item">
              <GeneratePermitLink
                className="kt-nav__link"
                scope={"translator.update"}
                to={{
                  pathname: `/translators/interview/${props.translator_data.id}`,
                }}
              >
                <i className={Icons.mobile_no} />
                &emsp;
                <span className="kt-nav__link-text">{Lang.interview}</span>
              </GeneratePermitLink>
            </li>
            {/* ADMIN ACTIONS - Send confirmation email, send password reset, verify email, unblock user. */}
            <hr />
            <Action icon={Icons.email} onClick={props.sendConfirmationEmail} label="Send Confirmation Email" />
            <Action icon={Icons.password} onClick={props.sendPasswordReset} label="Send Password Reset" />
            <Action icon={Icons.tick_mark} onClick={props.verifyEmail} label="Verify Email" />
            <Action icon={Icons.password2} onClick={props.unblockLogin} label="Unblock User" />
          </ul>
        </div>
      </div>
    </span>
  );
}

interface ActionProps {
  icon: string
  onClick: () => void
  label: string
  scope?: string
  to?: string
}

const Action = ({ icon, onClick, label, scope = "translator.update", to = "#" }: ActionProps) => {
  const [Loading, setLoading] = useState(false)
  const ref = useRef(null)
  return (
    <li className="kt-nav__item">
      <GeneratePermitLink className="kt-nav__link" scope={scope} to={to}>
        <i className={icon} /> &nbsp;&nbsp; 
        <span className="kt-nav__link-text" onClick={onClick} >
          { label }
        </span>
      </GeneratePermitLink>
    </li>
  )
}