import React, {useReducer} from 'react';
import {ActivityLogModel, OrderModel, OrderType} from "../../../model";

// all actions for assign translator
export enum ActionType {
  SHOW_POPUP = 'SHOW_POPUP',
  HIDE_POPUP = 'HIDE_POPUP',
  HANDLE_TIME = 'HANDLE_TIME',
  HANDLE_DOCUMENT = 'HANDLE_DOCUMENT',
  HANDLE_CHANGE = 'HANDLE_CHANGE',
}

interface completeForm {
  order_to: string,
  order_from: string,
  complete_order_to:string,
  comment: string,
  phone_message: string,
  document: Array<any>,
  send_email: boolean,
  send_sms: boolean,
  total_stroke:string,
  total_word:string
}

export interface PopupState  {
  show: boolean;
  order_data: OrderModel;
  history: Array<ActivityLogModel>,
  form:completeForm
}

export interface CAction {
  type: ActionType;
  payload?: PopupState
}

export const InitialState: PopupState = {
  show: false,
  order_data: {} as OrderModel,
  history: [],
  form: {} as completeForm
};

// reducer for fix price popup

export function CompleteOrderReducer <PopupState, CAction > (state, action) {
  switch (action.type) {
    // show popup and set order data
    case ActionType.SHOW_POPUP:
      return {...state,
        show: true, order_data:action.payload.data, history: action.payload.history,
        form: {...state.form,
          complete_order_to: action.payload.data.order_to,
          order_from: action.payload.data.order_from,
          send_email:  action.payload.data.translation_type_id === OrderType.DOCUMENT
      }};

    // hide popup and blank old state
    case ActionType.HIDE_POPUP:
      return {...state, ...InitialState};

    case ActionType.HANDLE_CHANGE:
      const name = action.payload.name;
      const value = action.payload.type === 'checkbox' ? action.payload.checked : action.payload.value;
      return {...state, form: {...state.form, [name]: value}};

    case ActionType.HANDLE_TIME:
      return {...state, form: {...state.form, [action.payload.name]: action.payload.time.value}};


    default:
      throw new Error();
  }
}