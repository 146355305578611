import Lang from "../../common/lang/Lang";
import {RoleModel} from "../../model/Role";
import {UserModel} from "../../model";

export const urls = {
  users: "/payouts",
  list: "/payouts/complete",
}

export const ListBreadcrumb = [
  {
    label: Lang.payout,
    url: urls.users
  },
  {
    label: Lang.complete_payout,
    url: "#"
  }
];


export interface Payouts {
  id: number;
  total_order: number;
  total_work: number;
  total_amount: number;
  total_distance: number;
  total_travel_time: number;
  status: number;
  created_at: string;
  action: string;
  user: UserModel
}