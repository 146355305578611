import * as React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import {Dashboard} from "./dashbaord/Dashboard";
import {Listing} from "./users/customers/Listing";
import {AdminUsers} from "./users/admin-users/Index";
import {CustomerCreate} from "./users/customers/Create";
import {LocationListing} from "./settings/location/Listing";
import {LocationCreate} from "./settings/location/Create";
import {LocationEdit} from "./settings/location/Edit";
import {HolidayListing} from "./settings/holiday/Listing";
import {HolidayCreate} from "./settings/holiday/Create";
import {HolidayEdit} from "./settings/holiday/Edit";
import {CompanyListing} from "./settings/company/Listing";
import {CompanyCreate} from "./settings/company/Create";
import {CompanyEdit} from "./settings/company/Edit";
import {DepartmentListing} from "./settings/department/Listing";
import {DepartmentCreate} from "./settings/department/Create";
import {DepartmentEdit} from "./settings/department/Edit";
import {DepartmentUnitListing} from "./settings/department-unit/Listing";
import {DepartmentUnitCreate} from "./settings/department-unit/Create";
import {DepartmentUnitEdit} from "./settings/department-unit/Edit";
import {TimeCalculation} from "./settings/company/TimeCalculation";
import {EmailTemplates} from "./settings/email-templates/Index";
import {CreateEmailTemplates} from "./settings/email-templates/create";
import {EditEmailTemplates} from "./settings/email-templates/edit";
import {SmsTemplates} from "./settings/sms-templates/Index";
import {CreateSmsTemplates} from "./settings/sms-templates/create";
import {EditSmsTemplates} from "./settings/sms-templates/edit";
import {ActivityLogs} from "./logs/activity-logs/Index";
import {Auth} from "./Auth/Auth";
import {CreateTranslators} from "./users/translators/create";
import CreateTranslatorsNew from "./users/translators/createNew";
// import {CreateTranslatorsNew} from "./users/translators/create-new";
import {TranslatorsListing} from "./users/translators/Listing";
import {RolesListing} from "./permissions/role/Listing";
import {RoleCreate} from "./permissions/role/Create";
import {RoleEdit} from "./permissions/role/Edit";
import {AdminUserCreate} from "./users/admin-users/Create";
import {AdminUserEdit} from "./users/admin-users/Edit";
import {UserPermission} from "./users/admin-users/UserPermission";
import {AdminUserChangePassword} from "./users/admin-users/ChangePassword";
import {is_permit} from "./common/Common";
import {ErrorPage} from "./error/ErrorPage";
import {Web} from "./layout/Web";
import {CreateClaims} from "./orders/claims/Create";
import {ClaimsListing} from "./orders/claims/Listing";
import {ClaimsDetail} from "./orders/claims/Detail";
import {EditTranslators} from "./users/translators/edit";
import {CreateOrder} from "./users/customers/orders/CreateOrder";
import {TChangePassword} from "./users/translators/ChangePassword";
import {TranslatorOrders} from "./users/translators/Orders";
import {OrderListing} from "./orders/orders/Listing";
import {UpdateOrder} from "./orders/orders/UpdateOrder";
import {LanguageListing} from "./languages/language/Listing";
import {CustomerChangePassword} from "./users/customers/ChangePassword";
import {CustomerTabs} from "./users/customers/CustomerTabs";
import {TranslatorTabs} from "./users/translators/TranslatorTabs";
import {LanguageEdit} from "./languages/language/Edit";
import {LanguageCreate} from "./languages/language/Create";
import {EditProfile} from "./users/profile/edit";

import {EmailListing} from "./email/email/Listing";
import {SystemEmailListing} from "./email/system/Listing"; 
import {EmailCreate} from "./email/email/Create";
import {CancelOrders} from "./orders/orders/CancelOrders";
import {CompleteOrders} from "./orders/orders/CompleteOrders";
import {Orderdetails} from "./orders/orders/info";

import {SMSListing} from "./sms/sms/Listing";
import {SMSCreate} from "./sms/sms/Create";
import {SystemSMSListing} from "./sms/system/Listing"; 

import {CommonListing} from "./settings/common-setting/Listing";
import {CommonEdit} from "./settings/common-setting/Edit";
import {CommonCreate} from "./settings/common-setting/Create";
import {PendingPayouts} from "./payouts/pending";
import {NotificationListing} from "./systemInfo/notification/Listing";
import {EnquireListing} from "./systemInfo/enquire/Listing";
import {CallLogListing} from "./systemInfo/calllog/Listing";

import {CustomerCalculationCreate} from "./settings/customer-calculation/Create";
import {CompletePayouts} from "./payouts/complete";
import {PaidPayouts} from "./payouts/paid";
import {PaymentOrdersPayout} from "./payouts/paid/PaymentOrders";
import {UserGrid} from "./users";
import {OrdersGrid} from "./orders";
import {WeLearn} from "./learn/we-learn";
import {WeLearnCreate} from "./learn/we-learn/Create";
import {WeLearnEdit} from "./learn/we-learn/Edit";
import {Quiz} from "./learn/quiz";
import {QuizCreate} from "./learn/quiz/Create";
import {OnlineTest} from "./learn/online-test";
import {OnlineTestCreate} from "./learn/online-test/Create";
import {NotFound} from "./NotFound";
import {Switch} from "react-router";
import {EditQuiz} from "./learn/quiz/Edit";
import {EditOnlineTest} from "./learn/online-test/Edit";
import {SettingGrid} from "./settings/settings";
import {PayoutGrid} from "./payouts";
import {LearnGrid} from "./learn";
import {NewletterGrid} from "./email";
import {SMSGrid} from "./sms";
import {SystemInfoGrid} from "./systemInfo";
import {Importdepartment} from "./settings/company/Importdepartment";
import { CookiesProvider, withCookies, Cookies } from 'react-cookie';
import moment from "moment-timezone";
import {RequestOrderslist} from "./orders/orders/RequestOrders";
import Login from "./Auth/Login";
import { WeFileViewer } from './we-file-viewer/WeFileViewer';
import CallPage from './users/translators/CallPage/CallPage';
import Invoicing from './orders/invoicing/Invoicing';
declare const window: any;



interface IProps {
  exact?: boolean;
  path: string;
  scope: string|Array<string>;
  component: React.ComponentType<any>;
}

class Index extends React.Component<any, any> {


  constructor(props){
    super(props);
    moment.tz.setDefault();
    this.state = {
      isAuthenticated: false,
      is_loading: true
    };
  }
  componentDidMount() {
    ($(".selectpicker") as any).selectpicker();
    const {cookies}: any = this.props;
    Auth.reAuthenticate(cookies.get('remember_token')).then(res => {
      if(res){
        if(res.role_id == 1 || res.role_id > 3){
          this.setState({
            isAuthenticated: true,
            is_loading: false
          }, () => {window.KTLayout.init()});
        } else {
          this.setState({
            is_loading: false
          })
        }

      } else {
        this.setState({
          is_loading: false
        })
      }
    });
  }

  render() {

    if(this.state.is_loading){
      return null;
    }

    if(!this.state.isAuthenticated){
      return <Login />
    }


    const PermitRoute = ({ component: Component, ...rest }: IProps) => (
      <Route {...rest} render={(props) => (
        is_permit(rest.scope)
          ? <Component {...props}/>
          : <ErrorPage  />
      )} />
    );

    return(
      <CookiesProvider>
        <Router>
        <Web>
          <Switch>
          <Route path="/" exact component={Dashboard} />
          <PermitRoute exact path="/users" component={UserGrid} scope={['customers.index', 'admin-users.index', 'translator.index']} />
          <PermitRoute exact path="/users/admin-users" component={AdminUsers} scope={'admin-users.index'} />
          <PermitRoute path="/users/admin-users/create" component={AdminUserCreate} scope={'admin-users.store'} />
          <PermitRoute exact path="/users/admin-users/edit/:id" component={AdminUserEdit} scope={'admin-users.update'} />
          <PermitRoute path="/users/admin-users/edit/:id/permissions" component={UserPermission} scope={'admin-users.update'} />
          <PermitRoute path="/users/admin-users/edit/:id/change-password" component={AdminUserChangePassword} scope={'admin-users.update'} />

          {/* Customer routes*/}
          <PermitRoute exact path="/users/customers/"  component={Listing} scope={'customers.index'} />
          <PermitRoute exact path="/users/customers/create" component={CustomerCreate} scope={'customers.store'} />
          <PermitRoute path="/users/customers/:id/orders/create" component={CreateOrder} scope={'orders.store'} />
          <PermitRoute path="/users/customers/:id/orders/:orderId/create" component={CreateOrder} scope={'orders.store'} />
          <PermitRoute path="/users/customers/info/:id" component={CustomerTabs} scope={'customers.update'} />


          {/*<PermitRoute exact path="/users/customers/:id/orders" component={CustomerOrders} scope={'order.index'} />*/}

          <PermitRoute path="/users/customers/change-password/:id" component={CustomerChangePassword} scope={''}/>
          {/* Customer routes end*/}

          <PermitRoute exact path="/users/translators/" component={TranslatorsListing} scope={'translator.index'} />
          <PermitRoute exact path="/translators/all" component={TranslatorsListing} scope={'translator.index'} />
          <PermitRoute exact path="/translators/active" component={TranslatorsListing} scope={'translator.index'} />
          <PermitRoute exact path="/translators/applicants" component={TranslatorsListing} scope={'translator.index'} />
          <PermitRoute exact path="/translators/inactive" component={TranslatorsListing} scope={'translator.index'} />
          <PermitRoute path="/users/translators/create-old" component={CreateTranslators} scope={'translator.store'}/>
          <PermitRoute path="/users/translators/create" component={CreateTranslatorsNew} scope={'translator.store'}/>
          {/*<PermitRoute path="/users/translators/edit/:id" component={EditTranslators} scope={'translator.update'}/>*/}
          <PermitRoute path="/users/translators/info/:id" component={TranslatorTabs} scope={'translator.update'}/>
          <PermitRoute path="/translators/all/info/:id" component={TranslatorTabs} scope={'translator.update'}/>
          <PermitRoute path="/translators/active/info/:id" component={TranslatorTabs} scope={'translator.update'}/>
          <PermitRoute path="/translators/applicants/info/:id" component={TranslatorTabs} scope={'translator.update'}/>
          <PermitRoute path="/translators/inactive/info/:id" component={TranslatorTabs} scope={'translator.update'}/>
          <PermitRoute path="/profile/edit/" component={EditProfile} scope={''}/>
          <PermitRoute path="/translators/interview/:translatorId" component={CallPage} scope={"translator.update"} />

          <PermitRoute exact path="/settings" component={SettingGrid} scope={['location.index', 'holiday.index', 'company.index', 'department.index', 'department-unit.index','email-template.index','sms-template.index','role.index','language.index','common-setting.index']} />
          
          <PermitRoute exact path="/settings/location/"  component={LocationListing} scope={'location.index'} />
          <PermitRoute path="/settings/location/create" component={LocationCreate } scope={'location.store'} />
          <PermitRoute path="/settings/location/edit/:id" component={LocationEdit} exact scope={'location.update'} />

          <PermitRoute exact path="/settings/holiday/"  component={HolidayListing} scope={'holiday.index'} />
          <PermitRoute path="/settings/holiday/create" component={HolidayCreate } scope={'holiday.store'} />
          <PermitRoute path="/settings/holiday/edit/:id" component={HolidayEdit} exact scope={'holiday.update'} />

          <PermitRoute exact path="/settings/company/"  component={CompanyListing} scope={'company.index'} />
          <PermitRoute path="/settings/company/create" component={CompanyCreate } scope={'company.store'} />
          <PermitRoute path="/settings/company/edit/:id" component={CompanyEdit} exact scope={'company.update'} />

          <PermitRoute exact path="/settings/department/"  component={DepartmentListing} scope={'department.index'} />
          <PermitRoute path="/settings/department/create" component={DepartmentCreate } scope={'department.create'} />
          <PermitRoute path="/settings/department/edit/:id" component={DepartmentEdit} exact scope={'department.edit'} />

          <PermitRoute exact path="/settings/department-unit/"  component={DepartmentUnitListing} scope={'department-unit.index'} />
          <PermitRoute path="/settings/department-unit/create" component={DepartmentUnitCreate } scope={'department-unit.create'} />
          <PermitRoute path="/settings/department-unit/edit/:id" component={DepartmentUnitEdit} exact scope={'department-unit.edit'} />
          
          <PermitRoute path="/settings/company/edit/:id/time-calculation" component={TimeCalculation}  scope={'company.update'} />
          <PermitRoute path="/settings/company/edit/:id/import-department" component={Importdepartment}  scope={'company.update'} />

          <PermitRoute exact path="/settings/email-templates/" component={EmailTemplates} scope={'email-template.index'} />
          <PermitRoute path="/settings/email-templates/create" component={CreateEmailTemplates} scope={'email-template.store'} />
          <PermitRoute path="/settings/email-templates/edit/:id" component={EditEmailTemplates} scope={'email-template.update'} />

          <PermitRoute exact path="/settings/sms-templates/" component={SmsTemplates} scope={'sms-template.index'} />
          <PermitRoute path="/settings/sms-templates/create" component={CreateSmsTemplates} scope={'sms-template.store'} />
          <PermitRoute path="/settings/sms-templates/edit/:id" component={EditSmsTemplates} scope={'sms-template.update'} />

          <PermitRoute exact path="/permission/role" component={RolesListing} scope={'role.index'} />
          <PermitRoute path="/permission/role/create" component={RoleCreate} scope={'role.store'} />
          <PermitRoute path="/permission/role/edit/:id" component={RoleEdit} scope={'role.update'} />

          


          <PermitRoute exact path="/orders" component={OrdersGrid} scope={['claim.index','orders.index','orders.cancel-orders-list','orders.complete-orders-list','orders.request-changes-cancel','customer-calculation.store']} />
          <PermitRoute exact path="/orders/claims/" component={ClaimsListing} scope={'claim.index'} />
          <PermitRoute path="/orders/claims/create" component={CreateClaims} scope={'claim.create'} />
          <PermitRoute path="/orders/claims/detail/:id" component={ClaimsDetail} scope={'claim.view'} />

          <PermitRoute path="/orders/orders" component={OrderListing} scope={'orders.index'} />
          <PermitRoute path="/orders/order/edit/:id" component={UpdateOrder} scope={'orders.index'} />
          <PermitRoute exact path="/orders/cancel-orders" component={CancelOrders} scope={'orders.cancel-orders-list'} />
          <PermitRoute exact path="/orders/complete-orders" component={CompleteOrders} scope={'orders.complete-orders-list'} />
          <PermitRoute exact path="/orders/invoicing" component={Invoicing} scope={"orders.complete-order"} />
          <PermitRoute exact path="/orders/order/info/:id" component={Orderdetails} scope={'orders.complete-orders-list'} />
          <PermitRoute exact path="/orders/request-change-cancel" component={RequestOrderslist} scope={'orders.request-changes-cancel'} />

          <PermitRoute exact path="/languages/language" component={LanguageListing} scope={'language.index'} />
          <PermitRoute path="/languages/language/edit/:id" component={LanguageEdit} scope={'language.edit'} />
          <PermitRoute path="/languages/language/create" component={LanguageCreate} scope={'language.create'} />

          <PermitRoute exact path="/email" component={NewletterGrid} scope={['email.index','system-email.index']} />
          <PermitRoute exact path="/email/email" component={EmailListing} scope={'email.index'} />
          <PermitRoute path="/email/email/create" component={EmailCreate} scope={'email.store'} />
            <PermitRoute exact path="/email/system" component={SystemEmailListing} scope={'system-email.index'} />
            {/*<PermitRoute exact path="/email/system" component={SystemEmailListing} scope={'system-email.index'} />*/}
           <PermitRoute exact path="/sms" component={SMSGrid} scope={['sms.index','system.index']} />
          <PermitRoute exact path="/sms/sms" component={SMSListing} scope={'sms.index'} />
          <PermitRoute path="/sms/sms/create" component={SMSCreate} scope={'sms.store'} />
          <PermitRoute exact path="/sms/system" component={SystemSMSListing} scope={'system.index'} />
          {/* commented 01-04-2020 for permission issue
          <PermitRoute exact path="/sms/system" component={SystemSMSListing} scope={'system-sms.index'} />*/}

          <PermitRoute exact path="/settings/common-setting" component={CommonListing} scope={'common-setting.index'} />
          <PermitRoute path="/settings/common-setting/edit/:id" component={CommonEdit} scope={'common-setting.update'} />
          <PermitRoute path="/settings/common-setting/create" component={CommonCreate} scope={'common-setting.store'} />
          <PermitRoute path="/settings/customer-calculation/create" component={CustomerCalculationCreate} scope={'customer-calculation.store'} />
          <PermitRoute exact path="/systemInfo/notification" component={NotificationListing} scope={'notification.index'} />
          <PermitRoute exact path="/systemInfo" component={SystemInfoGrid} scope={['notification.index','enquiries.index','calllog.index','activity-log.index']} />
          <PermitRoute exact path="/systemInfo/enquire" component={EnquireListing} scope={'enquiries.index'} />
          <PermitRoute exact path="/systemInfo/calllog" component={CallLogListing} scope={'calllog.index'} />
		  <PermitRoute path="/logs/activity-logs/" component={ActivityLogs} exact scope={'activity-log.index'} />
          {/*payouts*/}
          <PermitRoute exact path="/payouts" component={PayoutGrid} scope={['payouts-pending.index','payouts-complete.index','payouts-paid.index']} />
          <PermitRoute path="/payouts/pending" component={PendingPayouts} scope={'payouts-pending.index'} />
          <PermitRoute path="/payouts/completed" component={CompletePayouts} scope={'payouts-complete.index'} />
          <PermitRoute exact={true} path="/payouts/paid" component={PaidPayouts} scope={'payouts-paid.index'} />
          <PermitRoute path="/payouts/paid/orders/:id" component={PaymentOrdersPayout} scope={'payouts-paid.index'} />

          {/*Learn*/}
          <PermitRoute exact path="/learn" component={LearnGrid} scope={['we-learn.index','quiz.index','online-test.index']} />
          <PermitRoute exact path="/learn/we-learn" component={WeLearn} scope={'we-learn.index'} />
          <PermitRoute path="/learn/we-learn/create" component={WeLearnCreate} scope={'we-learn.store'} />
          <PermitRoute path="/learn/we-learn/edit/:id" component={WeLearnEdit} scope={'we-learn.store'} />

          <PermitRoute exact path="/learn/quiz" component={Quiz} scope={'quiz.index'} />
          <PermitRoute exact path="/learn/quiz/create" component={QuizCreate} scope={'quiz.store'} />
          <PermitRoute path="/learn/quiz/edit/:id" component={EditQuiz} scope={'quiz.update'} />

          <PermitRoute exact path="/learn/online-test" component={OnlineTest} scope={'online-test.index'} />
          <PermitRoute exact path="/learn/online-test/create" component={OnlineTestCreate} scope={'online-test.store'} />
          <PermitRoute path="/learn/online-test/edit/:id" component={EditOnlineTest} scope={'online-test.update'} />
          <PermitRoute path="/learn/online-test/edit/:id" component={EditOnlineTest} scope={'online-test.update'} />
          <PermitRoute exact path='/file-view/:id/:documentType/' scope={'orders.index'} component={WeFileViewer} />

          <Route path="*" component={NotFound} />
          </Switch>
       </Web>
      </Router>
      </CookiesProvider>
    )
  }
}

export default withCookies(Index);