import {ClientData, OrderModel, OrderType, UserModel} from "../../../model";
import {showNotification} from "../../../common";
import Lang from "../../../common/lang/Lang";
import _ from "lodash";

let moment = require("moment");

export interface OrderDateTimeInterface {
  order_date: string;
  order_from: string;
  order_to: string;
  document: Array<any>;
}

export interface CreateOrderInterface extends OrderModel {
  order: Array<OrderDateTimeInterface>,
  is_specific_translator:number,
  is_not_specific_translator:number,
  specific_translator_id:number,
  specific_translator:any,
  translator_id:number,
  avail_for_another:number,
  isAddressSame:number,
}



export class OrderForm {
  private data: UserModel;
  constructor(data?: UserModel){
    this.data = data;
  }


  public getOrderForm(): CreateOrderInterface {
    return {
      customer_id: this.data.id,
      translation_type_id: this.getValue('translation_type_id', 0),
      meeting_address: this.getValue('meeting_address'),
      document: this.getValue('document'),
      tel_message: this.getValue('tel_message'),
      from_language: this.getValue('from_language', 96),
      to_language: this.getValue('to_language'),
      order : [
        {
          order_date: '',
          order_from:  '',
          order_to:  '',
          document: [],
        }
      ],
      clients: [{
        index: 0,
        client_name: '',
        client_cpr: this.data.customer.cpr_number,
        client_lat: '',
        client_long: '',
      }],
      comment_for_translator: this.getValue('comment_for_translator'),
      company_id: this.data !== undefined ? this.data.company.name : '',
      company_id_number: this.data.company.id,
      department_id: this.data.company.is_special_company ? this.data.customer.department_id : this.data.customer.department !== null ? this.data.customer.department.name :'--',
      department_unit_id: this.data.customer.department_unit_id,
      email: this.getValue('email'),
      mobile_no: this.getValue('mobile_no'),
      address: this.getValue('address'),
      //ean_number: this.getValue('ean_number'),
      ean_number: this.data.customer.ean_number,
      is_second_person: false,
      office_note: this.getValue('office_note'),
      contact_persons: [
        {name: this.data.name, email: this.data.email, phone_no: this.data.mobile_no},
      ],
      is_specific_translator:0,
      is_not_specific_translator:0,
      specific_translator_id:0,
      not_specific_translator_id:0,
      specific_translator:{},
      translator_gender:'0',
      translator_id:0,
      avail_for_another:0,
      isAddressSame:0,
    }
  }

  public getOrderCopyForm(): CreateOrderInterface {
    return {
      customer_id: this.data.id,
      is_dumb: this.data.orderInfo.is_dumb,
      translation_type_id: this.data.orderInfo.translation_type_id,
      meeting_address: this.data.orderInfo.meeting_address,
      addition_info: this.data.orderInfo.addition_info,
      document: this.getValue('document'),
      tel_message: this.data.orderInfo.tel_message,
      from_language: this.data.orderInfo.from_language,
      to_language: this.data.orderInfo.to_language,
      order : [
        {
          order_date: '',
          order_from:  this.data.orderInfo.order_from,
          order_to:  this.data.orderInfo.order_to,
          document: [],
        }
      ],
      clients: this.data.orderInfo.clients.map(client => {
        return {
          index: client.index,
          client_name: client.client_name,
          client_cpr: client.client_cpr,
          client_lat: '',
          client_long: '',
        }
      }),
      comment_for_translator: this.data.orderInfo.comment_for_translator,
      company_id: this.data.company.name,
      company_id_number: this.data.company.id,
      department_id: this.data.company.is_special_company ? this.data.customer.department_id : this.data.customer.department !== null ? this.data.customer.department.name :'--',
      department_unit_id: this.data.customer.department_unit_id,
      email: this.getValue('email'),
      mobile_no: this.getValue('mobile_no'),
      address: this.getValue('address'),
      ean_number: this.data.orderInfo.ean_number,
      is_second_person: false,
      office_note:  this.data.orderInfo.office_note,
      contact_persons: [
        {name: this.data.name, email: this.data.email, phone_no: this.data.mobile_no},
      ],
      is_specific_translator:0,
      is_not_specific_translator:0,
      specific_translator_id:0,
      not_specific_translator_id:0,
      specific_translator:{},
      translator_gender:'0',
      translator_id:0,
      avail_for_another:0,
      isAddressSame:0,
    }
  }

  private getValue (field: string, _default?: any){
    type orderFields = keyof OrderModel;
    return (this.data !== undefined  && this.data[field as orderFields] !== undefined )? this.data[field as orderFields] : (_default !== undefined ? _default : '')
  }

}

export const isValidateEmail = (email:string) => {
  let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}


export const isValidatePhone = (mobile_no:string) => {
  let re = /^\d{8,14}$/;
  return re.test(mobile_no);
}



export const OrderValidations = function (form, is_special_company, update:boolean = false) {
  const validations = {
    is_valid: true,

    showMessage: function (msg:string) {
      this.is_valid = false;
      showNotification('danger', msg);
    },
    valid: function (form: CreateOrderInterface, is_special_company) {
      if(_.isNil(form.translation_type_id) || Number(form.translation_type_id)===0){
        this.showMessage(Lang.required_trans_type);
      }

      if((Number(form.translation_type_id) === OrderType.PHYSICAL)){
        if(_.isEmpty(form.meeting_address)){
          this.showMessage(Lang.required_meet_address);
        }
      }

      if(( Number(form.translation_type_id) === OrderType.PHONE_MESSAGE)) {
        if(_.isEmpty(form.tel_message)){
          this.showMessage(Lang.required_tel_message);
        }
        if(_.isEmpty(form.tel_mobile)){
          this.showMessage(Lang.required_tel_mobile);
        }
        if( !_.isEmpty(form.tel_mobile) && !isValidatePhone(form.tel_mobile)){
          this.showMessage(Lang.required_valid_mobile);
        }
      }

      if(form.from_language === undefined || form.from_language <= 0){
        this.showMessage(Lang.required_from_lang);
      }

      if((Number(form.is_dumb) === 0  || form.is_dumb === undefined )&& (_.isNil(form.to_language)|| String(form.to_language)==='')){
        this.showMessage(Lang.required_to_lang);
      }

      if(!update){
        form.order.map((item: OrderDateTimeInterface, index: number) => {

          if(_.isEmpty(item.order_date)){
            this.showMessage(Lang.required_order_date);
          }

          if(_.isEmpty(item.order_from) && (Number(form.translation_type_id) !== OrderType.DOCUMENT && Number(form.translation_type_id) !== OrderType.PHONE_MESSAGE)){
            this.showMessage(Lang.required_order_from);
          }

          if(_.isEmpty(item.order_to)){
            this.showMessage(Lang.required_order_to);
          }

          if(( Number(form.translation_type_id) === OrderType.DOCUMENT)) {
            if(_.isEmpty(item.document)){
              this.showMessage(Lang.required_document);
            }
          }

        });
      } else{
        if(_.isEmpty(form.order_date)){
          this.showMessage(Lang.required_order_date);
        }

        if(_.isEmpty(form.order_from) && (Number(form.translation_type_id) !== OrderType.DOCUMENT && Number(form.translation_type_id) !== OrderType.PHONE_MESSAGE)){
          this.showMessage(Lang.required_order_from);
        }

        if(_.isEmpty(form.order_to)){
          this.showMessage(Lang.required_order_to);
        }

        if(( Number(form.translation_type_id) === OrderType.DOCUMENT)) {
          if(_.isEmpty(form.order_files)){
            this.showMessage(Lang.required_document);
          }
        }
      }


      if(_.isEmpty(form.company_id)){
        this.showMessage(Lang.required_company);
      }

      if(_.isNil(form.department_id)){
        this.showMessage(Lang.required_department);
      }

      if(is_special_company &&  _.isNil(form.department_unit_id)){
        this.showMessage(Lang.required_department_unit);
      }
      if(is_special_company &&  _.lowerCase(form.company_id).search('redcross') >= 0 && _.isNil(form.project_id)){
        this.showMessage(Lang.required_project);
      }

      if(form.contact_persons){
            form.contact_persons.map((item: any, index: number) => {
                if(item.name === ''|| item.name === undefined){
                    this.showMessage(Lang.required_contact_name);
                }

                if(_.isEmpty(item.email) && _.isEmpty(item.phone_no)){
                    this.showMessage(Lang.required_email_or_phone);
                }

                if( !_.isEmpty(item.email) && !isValidateEmail(item.email)){
                    this.showMessage(Lang.required_valid_email);
                }

                if( !_.isEmpty(item.phone_no) && !isValidatePhone(item.phone_no)){
                    this.showMessage(Lang.required_valid_mobile);
                }

            });
      }

      if(form.addition_info){
            if(form.addition_info.length >= 255){
                this.showMessage(Lang.addition_info_max_length);
            }
        }


      // if(_.isEmpty(form.client_name)){
      //   this.showMessage(Lang.required_client_name);
      // }

      // if(_.isEmpty(form.client_cpr )){
      //   this.showMessage(Lang.required_client_cpr);
      // }

      return this.is_valid;
    },
  };


  return validations.valid(form, is_special_company);
}