import * as React from 'react';
import {DateInput, getQueryStringParams,SearchSelect, SearchInput} from "../../../common";
import {getDept, getDeptUnit} from "../../../helpers/UserHelper";
import {LanguageAndCompanyList} from "../../../services";
import {default as Lang} from "../../../common/lang/Lang";
import {EconomicsConstant, SpecialStatus} from '../Data';
import _ from "lodash";
const moment = require('moment');

interface IState {
  form: {
    like: {
      search: string,
      email: string
    },
    equal: {
      status: number,
      customer_type: number,
      company_id :string,
      user_department_id: number,
      user_department_unit_id: number,
      economics_sync_status: number,
      special_status: number,
    }
  },
  dept: Array<any>,
  units: Array<any>,
  companies: Array<any>
 }

export default class CustomerFilter extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({
      dept: [],
      units:[],
      companies: [],
      form:  {
        like: {
          search: old_data ? old_data.like.search : '',
          email: old_data ? old_data.like.email : ''
        },
        equal: {
          status: old_data ? old_data.equal.status : '',
          customer_type: old_data ? old_data.equal.customer_type : '',
          user_department_id: old_data ? old_data.equal.user_department_id : '',
          user_department_unit_id: old_data ? old_data.equal.user_department_unit_id : '',
          company_id: old_data ? old_data.equal.company_id : '',
          economics_sync_status: old_data ? old_data.equal.economics_sync_status : '',
          special_status: old_data ? old_data.equal.special_status : ''
        }
      }})
  }

  componentDidMount() {
     LanguageAndCompanyList().then(res => {
      this.setState({
        companies: res.companies,
      })
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.state.form.equal.company_id !== '' && !_.isEmpty(nextProps.companies )){
      const dept: any = getDept(nextProps.companies, Number(this.state.form.equal.company_id));
      this.setState({
        dept: dept,
        units: getDeptUnit(dept, Number(this.state.form.equal.user_department_id))
      })
    }
  }

  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    let new_state = {form: newAccess};

    // show dept filter and set data for it
    if(name[2] === "company_id"){
      const {companies} = this.state;
      new_state['dept'] = getDept(companies, Number(e.target.value));
    }

    // show dept unit filter and set data for it.

    if(name[2] === "user_department_id"){
      new_state['units'] = getDeptUnit(this.state.dept, Number(e.target.value));
    }


    this.setState(new_state,() =>  this.props.getResults(this.state.form));
  }

    formDataReset = (e:any) => { 
        e.preventDefault();
        this.setState({
            form:  {
                like: {
                  search:  '',
                  email: ''
                },
                equal: {
                  status: 0,
                  customer_type: 0,
                  company_id :'',
                  user_department_id: 0,
                  user_department_unit_id: 0,
                  economics_sync_status: 0,
                  special_status: 0,
                }
            }
        });     

        var formData = {
                       like: {search:'',email: ''},
                       equal: {status: 0,
                               customer_type: 0,
                               company_id :'',
                               user_department_id: 0,
                               user_department_unit_id: 0,
                               special_status: 0,
                               economics_sync_status: 0,
                           },
                       };

        this.setState({form: formData},() =>  this.props.getResults(this.state.form));
    }
 

  render(){
    const {status, customer_type, Companylist, } = this.props;
    const {companies} = this.state;
    const economics_sync_status = EconomicsConstant.economics_sync_status;
    const special_status = SpecialStatus.special_status;
    return (
      <div className="row align-items-center">
        <SearchInput name={'form.like.search'} value={this.state.form.like.search} onChange={this.handleInputForm} placeholder={'Search User..'} />
        <SearchSelect name={'form.equal.status'} value={this.state.form.equal.status}  onChange={this.handleInputForm} options={status}/>
        <SearchSelect name={'form.equal.customer_type'} value={this.state.form.equal.customer_type}  onChange={this.handleInputForm} options={customer_type}/>
        <SearchSelect name={'form.equal.company_id'} value={this.state.form.equal.company_id}  onChange={this.handleInputForm}  options={[{label: Lang.choose_company, value: ''}, ...companies]}/>
        {
          this.state.form.equal.company_id &&
          <SearchSelect name={'form.equal.user_department_id'}  value={this.state.form.equal.user_department_id || ''}  onChange={this.handleInputForm} options={this.state.dept}/>
        }

        {
          this.state.form.equal.company_id && this.state.form.equal.user_department_id &&
          <SearchSelect name={'form.equal.user_department_unit_id'}  value={this.state.form.equal.user_department_unit_id || ''}  onChange={this.handleInputForm} options={this.state.units}/>
        }
        <SearchSelect name={'form.equal.economics_sync_status'} value={this.state.form.equal.economics_sync_status}  onChange={this.handleInputForm} options={economics_sync_status}/>
        <SearchSelect name={'form.equal.special_status'} value={this.state.form.equal.special_status}  onChange={this.handleInputForm} options={special_status}/>

        <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn custom-reset-button'} data-skin={'dark'} title={Lang.reset} onClick={(e)=>this.formDataReset(e)}>{Lang.reset}</button>
      </div>
    )
  }
}