import * as React from 'react';
import {Breadcrumb} from "../../common/page/Breadcrumb";
import Lang from "../../common/lang/Lang";
import {EditBreadcrumb} from "./Data";
import {DataSchema, FormDefaultVal} from "./Model";
import API from './../../common/AxoisClient';
import {showNotification} from "../../common/Common";
import {SubmitButton, InlineLoader, Select,SwitchLoader} from "../../common";
import {CompanyTabs} from "./Tabs";
import {LoaderType} from "../../common/Loader";

interface IState{
  form: DataSchema;
  id: number;
  trans_types?: Array<any>,
  calculation?: Array<any>
}

export class TimeCalculation extends React.Component<{}, IState> {
  state: IState;

  constructor(props:any){
    super(props);

    this.state = {
      form: FormDefaultVal,
      id: props.match.params.id,
      calculation: []
    }
  }

  componentDidMount() {
    API.get(`settings/time-calculation/${this.state.id}`, {params: {type: '1'}}).then((res) => {
      this.setState({
        trans_types: res.data.trans_types,
        calculation: res.data.calculation
      })
    })
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    const form_data = $("#kt_form").serialize();
    API.put(`settings/time-calculation/${this.state.id}`, form_data, {params: {type: '1'}}).then((res) => {
      if(res.data.status === true){
        showNotification("success", res.data.message);
      } else{
        showNotification("danger", res.data.message);
      }
    })
  }


  render() {
    const calculationData = (type_id : any, col: string) => {
      let value = '';
      if(this.state.calculation !== undefined){
        let cal_data: any = this.state.calculation.filter((item) => {
          return (item.translation_type_id === parseInt(type_id))
        })
        if(cal_data.length > 0){
          value = cal_data[0][col];
        }
      }
      return value;
    };

    return (
      <div>
        <Breadcrumb data={EditBreadcrumb}/>

        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
               <CompanyTabs active={'time'} company_id={this.state.id}/>
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                  <form className="kt-form" id="kt_form"  onSubmit={this.handleSubmit} style={{width: "100%"}}>
                    <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                      <div className="kt-heading kt-heading--md">{Lang.company_time_calculation}</div>
                      {this.state.trans_types !== undefined &&
                        <div className="alert alert-outline-brand  fade show" role="alert">
                          <div className="alert-icon"><i className="flaticon-warning"/></div>
                          <div className="alert-text">All fields are required and all value should be less then 60.</div>
                        </div>
                      }

                      <div className="kt-form__section kt-form__section--first">
                        <div className="kt-wizard-v2__form">
                          {this.state.trans_types === undefined &&
                            <div className="alert alert-solid-brand alert-bold" role="alert">
                              <div className="alert-text">{Lang.please_wait}</div>
                            </div>
                          }

                          {
                            this.state.trans_types !== undefined && (Object.keys(this.state.trans_types)).map((item: any, index: number) => {
                              return (
                                  <div key={index}>
                                    <div className="row"><div className="col-xl-12"><h6>{this.state.trans_types[item]}</h6></div></div>
                                    <div className="row">
                                      <div className="col-xl-4">
                                        <div className="form-group">
                                          <input type="text" required={true} className="form-control" name={`time[${item}][minutes]`} defaultValue={calculationData(item, 'minutes')} placeholder="Enter Minutes" aria-invalid="false"/>
                                          <span className="form-text text-muted">{Lang.company_time_cal_first_min}</span>
                                        </div>
                                      </div>
                                      <div className="col-xl-4">
                                        <div className="form-group">
                                          <input type="text" required={true} className="form-control" name={`time[${item}][calculate_as]`} defaultValue={calculationData(item, 'calculate_as')} placeholder="Enter Minutes" aria-invalid="false"/>
                                          <span className="form-text text-muted">{Lang.company_time_cal_cal_as}</span>
                                        </div>
                                      </div>
                                      <div className="col-xl-4">
                                        <div className="form-group">
                                          <input type="text" required={true} className="form-control" name={`time[${item}][following]`} defaultValue={calculationData(item, 'following')} placeholder="Enter Minutes" aria-invalid="false"/>
                                          <span className="form-text text-muted">{Lang.company_time_cal_following}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              );
                            })
                          }
                        </div>
                        <div className="kt-form__actions">
                          <div className="kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                          <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                    
                  </form>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}