import React, {useEffect, useState} from 'react';
import {CONST, Icons, LoaderType, RequestWaiting, SetWaitingArea, SwitchLoader} from "../common";
import Lang from "../common/lang/Lang";
import {DashboardService} from "../services";
import {ClaimModel, ClaimStatusLabel} from "../model/Claim";
import _ from "lodash";
const moment = require("moment");

export function Claims() {

  const [claims, setClaims] = useState([]);

  useEffect(() => {
    getClaimData();
  }, []);

  function getClaimData() {
    SwitchLoader(LoaderType.NONE);
    SetWaitingArea('claims');
    DashboardService.getClaimsData().then(res => {
      setClaims(res);
    })
  }

  return(
    <div className="kt-portlet kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            {Lang.claims}
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar">
          <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" onClick={getClaimData}>
            <i className={Icons.refresh} title={Lang.refresh}  data-toggle="kt-tooltip" data-placement="top" data-original-title={Lang.refresh} />
          </button>
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="kt-widget3">
          <RequestWaiting item={claims} from={'claims'}>
            {claims.length > 0 && claims.map((item: ClaimModel) => {
              return (
                <div className="kt-widget3__item" key={item.id}>
                  <div className="kt-widget3__header">
                    <div className="kt-widget3__user-img">
                      {/*<img className="kt-widget3__img" src={item.user.profile_image} alt=""/>*/}
                    </div>
                    <div className="kt-widget3__info">
                      {!_.isUndefined(item.order) && !_.isEmpty(item.order) && item.order.order_no !=null &&
                          <>
                            <span className="kt-widget3__username">{(!_.isEmpty(item.user))?item.user.name:''} (#{item.order.order_no})</span><br/>
                          </>
                      }
                      <span className="kt-widget3__time">{moment(moment(item.created_at)).format(CONST.date_format)}</span>
                    </div>
                    <span className="kt-widget3__status kt-font-success">{ClaimStatusLabel[item.status]} </span>
                  </div>
                  <div className="kt-widget3__body">
                    <p className="kt-widget3__text">
                      {(item.reason.length>100)?item.reason.substr(0, 100)+' . . .':item.reason}
                    </p>
                  </div>
                </div>
              )
            })}
          </RequestWaiting>
        </div>
      </div>
    </div>
  )
}