import * as React from "react";

export const Da = {
  info: "Info",
  success: "Success",
  warning: "Warning",
  error: "Error",
  submit: "Gem",
  active_confirm: "Are you sure that you want to active records?",
  mail_confirm: "Are you sure that you want to send mail ?",
  inactive_confirm: "Are you sure that you want to inactive records?",
  reject_confirm: "Are you sure that you want to reject records?",
  delete_confirm: "Are you sure that you want to delete records?",
  approve_confirm_leave: "Are you sure that you want to approve leave?",
  reject_confirm_leave: "Are you sure that you want to reject leave?",
  edit: "Edit",
  error_response: "Ooops... Something went wrong. Please try again.",
  please_wait: "Please wait...",
  is_sure: "Are you sure?",
  yes: "Yes",
  no: "No",
  back: "Back",
  showing: "Viser",
  of: "ud af",
  reset: "Reset",
  ok: "Okay",
  cancel: "Afbestille",
  special: "Special",
  excel: "Excel",
  csv: "CSV",
  export: "Eksport",
  active: "Aktiv",
  translator_active: "Aktiv",
  deactivate: "Deactivate",
  select: "Vælg",
  inactive: "Inactive",
  inactive_passive: "Passiv",
  inactive_rejected: "Afvist",
  choose_fields: "Choose Felter",
  Fields: "Felter",
  admin_users: "Admin users",
  add_new: "New Record",
  customers: "Customers",
  create: "Create",
  users: "Users",
  add_customer: "Opret en ny kunde",
  name: "Navn",
  translator_title: "Title",
  delete_translator_title: "Slettet oversætter",
  translator_title_placeholder: "Enter Title",

  customer_type: "Customer Type",

  email_placeholder: "Enter Email",
  name_placeholder: "Enter Name",
  password_placeholder: "Enter Password",
  cn_password: "Confirm Password",
  cn_password_placeholder: "Enter Password Again",
  address: "Adresse",
  address_placeholder: "Indtast din adresse",
  additional_address_info: "Yderligere adresseinfo",
  zip_code_placeholder: "Enter Post Number",
  city: "By",
  city_placeholder: "Enter By",
  mobile: "Mobilnummer",
  mobile_placeholder: "Enter Mobile No.",
  order_contact_placeholder: "Enter Contact No.",
  contact_person: "Contact Person",
  contact_person_placeholder: "Enter Contact Person Name",
  contact_phone: "Contact Person Mobile No.",
  contact_phone_placeholder: "Enter Contact Person Mobile No.",
  ean_number: "EAN No.",
  ean_number_placeholder: "Enter EAN No.",
  cpr_number: "CVR Number",
  cpr_number_placeholder: "Enter CVR Number",
  cvr_number: "CVR No.",
  fix_rate: "Påbegyndt",
  rate_minute: "minutter",
  sub_rate: "Efterfølgende",
  total_strokes: "Total Strokes",
  tel_message_fix_rate: "Pr. stk",
  min_word: "Min. betaling",
  per_word_rate: "ordprisen",
  compensation_agreed: "Er der aftalt KM godtgørelse?",
  min_km: "Afregning sker efter",
  per_km_rate: "Kilometergodtgørelse pris",
  transport_fee_agreed: "Er der aftalt honorar for transporttid?",
  holiday_charge_agreed:
    "Er der aftalt aftens tillæg, weeekend (lørdag & søndag) og helligdage (Kl: 17:01-Kl: 07:59 )?",
  extra_holiday_percentage: "Hvor mange procent ekstra?",
  company_name: "Virksomhedens navn",
  company_address: "Virksomhedens adresse",
  cvr_number_placeholder: "Enter CVR Number",
  identification_code: "Employee ID",
  identification_code_placeholder: "Enter Employee ID",
  economics_ref_id: "Economics Ref Id",
  // company module
  settings: "Settings",
  company: "Company",
  add_company: "Add New Company",
  edit_company: "Edit Company",
  other_emails: "Other Emails",
  other_emails_placeholder: "Enter Other Emails",
  other_emails_hint: "Enter multiple email (,) separated",
  company_update: "Company information successfully updated.",
  company_created: "New Company has been successfully created.",
  email_temp_created: "New email template has been successfully created.",
  email_temp_updated: "email template successfully updated.",
  email_temp_deleted: "email template successfully deleted.",
  sms_temp_created: "Sms email template has been successfully created.",
  sms_temp_updated: "Sms template successfully updated.",
  sms_temp_deleted: "Sms template successfully deleted.",
  company_time_calculation: "Company Time Calculation",
  company_info: "Company Info",
  company_detail: "Setup Your Company Details",
  company_time: "Company Time Calculation",
  company_time_cal: "Set your comapny time calculation",
  company_time_cal_first_min: "The first minimum minutes",
  company_time_cal_cal_as: "Calculated as",
  company_time_cal_following: "and the following time",
  company_mul_email: "Please enter valid email.",
  //customer
  department: "Department",
  department_name: "Department name",
  department_unit: "Department Unit",
  other_department_unit: "Other Department Unit",
  department_id_placeholder: "Enter Department",
  department_unit_id_placeholder: "Enter Department Unit",
  company_id_placeholder: "Enter Company Name",
  select_dept: "Select Department",
  select_dept_unit: "Select Department Unit",
  select_customer_type: "Select Customer Type",
  customer_info: "Customer Info",

  tax_payment: "Tax and Payment",
  services: "Services",
  rates: "Rates",
  review_title: "Anmeldelser",
  send_login_d_btn: "Send Login Details",
  total_order: "Total Orders",
  total_earning: "Total Earning",
  active_order: "Active Orders",
  pending_order: "Pending Orders",
  location_code: "Location Code",
  location_code_2: "Location Code 2",
  latitude: "Latitude",
  longitude: "Longitude",
  import_file: "Import file",
  create_order: "Create New Order",
  edit_customer: "Edit Customer Info",
  applicant: "Ansøger",
  department_update: "Department Update",
  department_create: "Department Create",
  department_unit_update: "Department-unit Update",
  department_unit_create: "Department-unit Create",
  customer_status_confirm:
    "Are you sure that you want to change customer status?",
  payout_status_confirm: "Are you sure that you want to mark as completed?",
  special_status_select: "Special Status",
  special_status_yes: "Special",
  special_status_no: "Not Special",
  economics_sync_status_select: "Sync Status",
  economics_sync_status_yes: "Synced",
  economics_sync_status_no: "Not Synced",
  //orders
  order: "Orders",
  order_status: "Alle bestillinger",
  holiday_status: "Feriestatus",
  order_status_completed: "Afsluttet",
  order_status_paid: "Faktureret",
  order_status_drafted: "Kladde",
  order_status_working: 'Arbejde',
  order_information: "Order Information",

  translation_type_placeholder: "Select Translation Type",
  order_for_dumb: "Hører denne ordre til døve?",
  is_address_same: "Er det samme som din adresse?",
  attach_document: "Attach Document",
  tel_message: "Message",
  tel_message_placeholder:
    "Please enter your message in the box below and end with the recipient's name and telephone no",
  from_language: "Language From",
  to_language: "Language To",
  language_placeholder: "Choose Language",
  date: "Date",
  date_placeholder: "Choose Date",
  order_from: "Order From",
  order_from_placeholder: "Choose Order From Time",
  order_to: "Order To",
  order_to_placeholder: "Choose Order To Time",
  client_name: "Client Name",
  client_name_placeholder: "Enter Client Name",
  client_cpr: "Client CPR No.",
  client_cpr_placeholder: "Enter Client CPR No.",
  client_ena: "Client ENA No.",
  employee_id: "Employee ID.",
  employee_id_placeholder: "Enter Employee ID",
  comment_for_translator: "Bemærkninger om tolken",
  comment_for_translator_placeholder: "Enter Comment For Translator",
  hr_comment: "Bemærkninger til HR fra tolken",
  billing_information: "Billing Information",
  choose_file: "Choose file",
  translator_name: "Translator name",
  contact_email: "Contact Person Email",
  contact_email_2: "Second Contact Person Email",
  contact_name: "Contact name",
  contact_number: "Contact number",
  contact_name_2: "Second Contact Person Name",
  contact_phone_2: "Second Contact Person Phone",
  contact_email_placeholder: "Enter Contact Person Email",
  contact_email_2_placeholder: "Enter Second Contact Person Email",
  second_contact_person: "Second Contact Person",

  progress: "Progress",
  doc_price: "Doc price",
  project: "Project",
  show_distance: "Vis afstand",
  total_distance: "Total Distance",
  travel_time: "Travel Time",
  order_detail: "Order detail",
  contact_info: "Contact Info",
  additional_info: "Additional Info",
  translator_gender: "Translator Gender",
  telephone_message: "Telephone Message",
  order_cancel: "Order Cancel",
  cancel_date: "Cancel Date",
  cancel_by: "Cancel By",
  cancel_paid: "Cancel Paid",
  cancel_amount: "Cancel amount",
  order_feedback: "Order Feedback",
  translator_comment: "Translator Comment",
  rating: "Rating",

  specific_interpreter: "Do you want a specific interpreter?",
  unwanted_interpreter: "Is there a particular unwanted interpreter?",
  interpreter_gender: "Preferably the interpreter's gender",
  notes_office: "Notes for the office",
  talk_translator: "Do you have already talk with this translator?",
  another_available_translator:
    "Distubete the order to another available translator.",
  specific_available_translator:
    "Contact me to find when the specific translator is available.",
  complete_order_time: "Complete Order Time",
  translator_busy: "If the selected interpreter is busy?",
  interpreter_free:
    "The assignment may be assigned to another free interpreter",
  interpreter_available:
    "Contact me to find out when the requested interpreter is available",
  // validations
  email_already_register: "E-mail allerede registreret. Prøv en anden",
  name_already_register: "Navn allerede registreret. Prøv en anden",
  value_too_short: "Denne værdi er for kort.",
  address_is_invalid:
    "Denne adresse er ugyldig, vær venlig at være mere specifik",
  required_field: "Dette er et obligatorisk indtastningsfelt.",
  required_trans_type: "Please select translation type.",
  required_meet_address: "Please enter meeting address.",
  required_tel_message: "Please enter telephone message.",
  required_tel_mobile: "Please enter recipient mobile number.",
  required_document: "Please upload document for translation.",
  required_from_lang: "Please select source language.",
  required_to_lang: "Please select target language.",
  required_order_date: "Please enter order date.",
  required_order_from: "Please enter order from time.",
  required_order_to: "Please enter order to date.",
  required_company: "Please enter your company.",
  required_department: "Please select your department.",
  required_department_unit: "Please select your department unit.",
  required_project: "Please select your project.",
  required_contact_name: "Please enter contact person name.",
  required_contact_email: "Please enter contact person email.",
  required_valid_email: "Please enter valid email.",
  required_contact_phone_no: "Please enter contact person phone number.",
  required_client_name: "Please enter client name.",
  required_client_cpr: "Please enter client cpr number.",
  required_address: "Dette felt skal udfyldes.",
  required_email_or_phone: "Please enter email or mobile no of contact person.",
  required_valid_mobile: "Dette felt skal udfyldes.",
  required_is_specific_translator:
    "Please choose you want any specific translator.",
  specific_translator: "Please choose your specific translator.",
  required_is_not_specific_translator:
    "Please choose you want any unwanted translator.",
  not_specific_translator: "Please choose your unwanted translator.",
  required_translator_gender: "Dette felt skal udfyldes.",
  //activity logs

  //email templates
  email_templates: "Email Templates",
  add_email_template: "Add New Email Template",
  edit_email_template: "Edit Email Template",
  //sms templates
  sms_templates: "Sms Templates",
  add_sms_template: "Add New Sms Template",
  edit_sms_template: "Edit Sms Template",
  //translator

  interview: "Interview",
  translators: "Tolke",
  all_translators: "Alle tolke",
  active_translators: "Aktiv",
  applicant_translators: "Ansøgere",
  inactive_translators: "Inaktiv",

  sign_translation: "Sign oversættelse",
  iban: "IBAN",
  iban_placeholder: "Enter IBAN",
  swift: "SWIFT",
  fee_type: "Fee Type",
  fee_type_1: "B-indkomst freelancer honorar",
  fee_type_2: "A-indkomst",
  fee_type_3: "Udenlandsk indkomstpligt",
  primary_income: "Primay Income",
  primary_income_1: "Hovedkort, (hvis det ens primær indkomst)",
  primary_income_2: "Bikort (hvis et andet sted er ens primær indkomst)",
  date_of_birth: "Date of birth",
  bank_name: "Bank name",
  bank_name_placeholder: "Enter bank name",
  holder_name: "Kontohavers navn",
  holder_name_placeholder: "Enter holder name",
  bank_accno_placeholder: "Enter bank account no",
  add_new_translator: "Tilføj ny tolk",

  bank_reg_placeholder: "Enter bank reg. no.",

  location: "Lokation",
  holiday: "Holiday",
  social_placeholder: "Enter social security no.",
  telephone_number: "Mobilnummer",
  order_contact_number: "Order Contact Number",
  telephone_number2: "Other Contact no.",
  telephone_placeholder: "Enter telephone number",

  driver_license_placeholder: "Enter driver's license",
  translator_type: "Translator Type",
  public_translator: "virksomhed",
  private_translator: "privatperson",
  is_vat_registered: "Momsregistreret?",
  vat_number: "VAT",
  own_transport: "bil",
  other_transport: "offentlig transport / andet",
  vehicle: "Vehicle",
  vehicle_placeholder: "Enter vehicle",
  certified: "Certificeret",
  verification_number: "Police verification number",
  verification_number_placeholder: "Enter verification number",
  certified_placeholder: "Enter certified",
  police_confirmed: "Politigodkendt",
  police_confirmed_yes: "ja",
  police_confirmed_no: "nej",
  police_conf_placeholder: "Enter police confirmed",
  zip_code_translator: "Postnummer",
  zip_code_trans_placeholder: "Enter zip code",
  sex: "Køn",
  translator_male: "Mand",
  translator_female: "Kvinde",
  sex_placeholder: "Enter sex",
  country_of_origin: "Oprindelsesland",
  country_of_ori_placeholder: "Enter country of origin",
  male: "Male",
  female: "Female",
  is_km_pay_title: "Is applicable for KM pay ?",
  is_transport_pay_title: "Is applicable for transport pay ?",

  agreed_income: "Agreed salary type ?",
  income: "Income",
  income_placeholder: "Enter Income",
  how_many_km_title: "After how many KM is applicable ?",
  how_many_km_placeholder: "Enter KM",
  how_much_pay_title: "How much pay for per KM ?",
  how_much_pay_placeholder: "Enter how much pay for per KM ?",
  hourly_rate_title: "How many percent of hourly rate ?",
  hourly_rate_placeholder: "Enter how many percent of hourly rate ?",
  exp_scale_title: "Expensive scale",
  average: "Gennermsnitlig",
  expensive: "Dyr",
  very_expensive: "Meget dyr",
  translator_created: "New Translator has been successfully created.",
  translator_updated: "Oplysningerne om tolken %{name} er opdateret.",
  notification_updated: "Notification setting has been successfully updated.",
  physical_rate: "Fremmødetolkning",
  physical_rate_placeholder: "Enter Physical Rate",
  telephone_rate: "Telefontolkning",
  telephone_rate_placeholder: "Enter Telephone Rate",
  video_rate: "Videotolkning",
  video_rate_placeholder: "Enter Video Rate",
  yesint: "1",
  noint: "0",
  send_mail: "Send mail",
  mail_send: "mail has been send successfully.",
  restore: "Restore",
  copy_to_clipboard: "Order copied successfully",
  restore_confirm: "Are you sure want to restore this translator ?",
  unpaid_confirm: "Tolken har ikke modtaget sin løn?",
  paid_unpaid_success: "Din status er blevet opdateret.",
  restore_success: "Translator has been restored successfully.",

  undescribe_recite: "Undescribe recite",
  undescribe_confirm_mail: "Undescribe auto confirmation mail",
  unsubscribe_feedback_mail: "Unsubscribe auto order feedback email",
  undescribe_auto_assign_translator:
    "Afmeld bekræftelse på tildeling af sprgopgave",
  undescribe_order_invitation_sent_to_translator:
    "Afmeld tilbud om ny sprogopgave",
  undescribe_send_order_cancellation_mail_to_translator:
    "Afmeld bekræftelse på annullering af en sprogopgave",
  undescribe_order_complete_notify_translator:
    "Afmeld bekræftelse på afslut af en sprogopgave",
  undescribe_update_order_for_translator:
    "Afmeld bekræftelse på rettelse af en sprogopgave",
  undescribe_translator_approved:
    "Afmeld bekræftelse på genaktivering af tolkeprofilen",
  undescribe_order_edit_request_complete_translator:
    "Afmeld bekræftelse på ændring foretaget af kunden på en sprogopgave",
  undescribe_translator_unapproved:
    "Afmeld bekræftelse på at tolkens profil er sat passiv",
  approve: "Approve",
  approved: "Bekræftet",
  send_contract: "Send freelanceaftale",
  resend_contract: "Resend Contract",
  contract_signed: "Kontrakt underskrevet",
  new_applicant: "Ny ansøger",
  rejected: "Afvist",
  show_contract: "Show Contract",
  online_test: "Online Test",
  sure_approve: "Are you sure want to approve this translator ?",
  sure_unapprove: "Are you sure want to unapprove this translator ?",
  success_approve: "Translator has been successfully approved.",
  success_unapprove: "Translator has been successfully unapproved.",
  contract_subject: "Mail Subject",

  contract_mail_send: "Contract has been send successfully.",
  sms_login: "Send nulstil adgangskode e-mail",
  sms_login_confirm: "Are you sure that you want to send login details?",
  qualification: "Kvalifikationer",
  interpreter_training: "Interpreter training?",
  highest_complete_edu: "Højeste gennemførte uddannelse",
  current_employment: "Nuværende beskæftigelse",
  motivation: "Motivation for at arbejde som tolk hos ViTolker?",
  tolk_ratings: "Indledende vurdering af danskkundskaber",
  qualification_title: "Telefonsamtale/kvalifikationer",
  level: "Level",
  accent: "Accent",
  mother_tongue: "Mother Tongue",
  how_much_hours: "How Much Hours",
  certificate: "Certificate",

  leave_type: "Hvor lang tid er du utilgængelig?",
  to: "To",
  reason: "Evt. bemærkninger",
  action: "Valgmuligheder",
  // add leave
  add_leave_title:
    "Fortæl os om din tilgængelighed ved at tilføje datoen, hvor du er optaget.",
  full_day: "Heldag",
  half_day: "Halvdag",
  multiple: "Optaget i en periode",
  type_of_half_day: "Hvornår i dagen er du optaget?",
  first_half: "Første halvdel af dagen",
  second_half: "Sidste halvdel af dagen",
  reason_placeholder: "Evt. bemærkninger",
  from_date: "Fra dato",
  from_date_placeholder: "Indtast fra dato",
  to_date: "Til dato",
  to_date_placeholder: "Indtast til dato",
  no_data_found: "Ingen data fundet",
  leave_added_success:
    "Indstillingen for utilgængelighed er succesfuldt tilføjet.",
  //permissions
  permission: "Permission",
  role: "Role",
  add_new_role: "Add Role",
  edit_role: "Edit Role",
  title: "Title",
  role_title_placeholder: "Enter Role Title",
  role_created: "New Role has been successfully created",
  role_updated: "Role has been successfully updated",
  //languages
  languages: "Sprogs",
  language: "Sprog",
  add_new_language: "Add Language",
  edit_language: "Edit Language",
  source_lang: "Source Language",
  target_lang: "Target Language",
  select_source_lang: "Vælg kildesprog",
  select_target_lang: "Vælg Målsprog",
  language_title: "Title",
  language_title_placeholder: "Indtast sprogtitel",
  language_created: "Nyt sprog er oprettet med succes",
  language_updated: "Sprog er blevet opdateret",
  select_country: "Vælg land",

  //admin users
  select_role: "Vælg rolle",
  add_admin_user: "Tilføj ny bruger",
  user_info: "User Info",
  user_details: "Manage User Information",
  user_permission: "User Permission",
  user_permission_details: "Manage User Permissions",
  edit_user: "Edit User",
  edit_user_permission: "Edit User Permission",

  change_password_details: "Change User Password",
  user_permission_update: "User permission has been successfully updated",
  //Profile Edit
  my_profile: "My Profile",
  profile_success: "Your profile has been successfully updated.",
  //Order Section

  order_list: "Order List",
  download_document: "Download",
  upload_file: "Uploaded file",
  active_orders: "Active Orders",
  order_name: "Order",
  active_order_name: "Active Order",
  edit_order: "Edit Order",
  update_order: "Update Order",
  view_order: "View Order",
  order_new_order: "ikke sendt ud",
  order_invitation_sent: "Under behandling",
  order_assigned: "bekræftet",
  order_cancelled: "Cancelled",
  order_complete: "Complete",
  under_qa: "Under QA",
  order_info: "Order Info",
  order_paid: "paid",
  add_order: "Add Order",
  assign_trans: "Assign Translator",
  assign_translator:
    "Afsat opgaven til én eller flere af de nedenstående tolke",
  select_distance: "Select Distance",
  send_mail_customer: "Send Mail To Customer",
  send_confirmation_for_customer_with_translator:
    "Send bekræftelse (med tolkens navn)",
  send_confirmation_for_customer_without_translator:
    "Send bekræftelse (uden tolkens navn)",
  send_confirmation_for_customer_with_translator_transport_info:
    "Send Bekræftelse (med tolkens transportinfo)",
  send_confirmation_for_customer_with_translator_video_address:
    "Bekræft med tolk-videoadresse",
  send_confirmation_for_customer_custom_mail: "Custom email to Customer",
  copy_content: "Copy Content",

  upload: "Upload",
  profile_pic: "Profile Pic",
  meeting_address: "Meeting Address",

  time: "Time",
  client_address: "Client Address",
  client: "Client",
  order_approved: "Order Approved",
  send_mail_translator: "Send Confirmation Mail To Translator",
  translator_task: "Translator Task",
  cancel_without_charge: "Cancel Order",
  cancel_text: "Afbestille",
  cancel_with_charge: "Cancel Order With Charge",
  cancel_order: "Cancel Order",
  how: "How",
  telephone: "Mobilnummer",
  mail: "Mail",
  chat: "Chat",
  via_trans: "Translator",
  when: "When",

  select_time: "Select time",
  enter_cancellation_message: "Enter cancellation message",
  comments: "Kommentarer",
  comment_title: "Her kan du se opgavens comments ",
  enter_comment: "Enter comments",
  created_at: "Created At",
  created_by: "Created By",
  fix_price: "Fix Price",
  enter_price: "Enter Price. eg: 123.45",
  set_price: "Set Price",
  send: "Send",
  push: "Push",
  cancel_orders: "Cancel Orders",
  complete_orders: "Complete Orders",
  history: "History",
  order_history_label: "Order History",
  close: "Close",
  activity: "Activity",
  platform: "Platform",
  active_order_title: "Oversigt over alle aktuelle bestillinger",
  complete_order_title: "Her er oversigten over alle de afsluttede opgaver",
  order_invoicing_title: 'Dashboard for gennemførte ordrer E-conomic-fakturering',
  cancel_order_title: "Oversigten over alle opgaver som er blevet annulleret",
  cancel_order_confirm: "Are you sure that you want to cancel this order?",
  restore_order: "Are you sure that you want to restore this order?",
  complete_order: "Complete order",
  translator_not_assigned: "Any translator not assigned yet for this order.",
  specify_trans_rate: "Angiv venligst oversætterens sats.",
  complete_order_conform: "Are you sure that you want to complete this order?",
  no_significance: "No significance",
  man: "Man",
  woman: "Woman",
  choose_country: "Choose Country",

  pdf: "PDF",
  copy_details: "Copy Details",
  see_message: "Se beskeden",
  remark: "Bemærkning",
  the_message: "Beskeden",
  office_note: "Bemærkninger til kontoret",
  remove_translator: "Fjern tolken",
  reason_remove_translator: "Fjern tolken fra opgaven",
  confirm_remove_translator:
    "Are your sure that you want to remove translator?",
  correction_request: "Anmod om rettelse",
  cancel_request: "Anmod om annullering",
  is_online: "Is online",
  account_status: "Account status",
  Old_customer: "Old Customer",
  last_login: "Last login",
  sign_in: "Sign In",
  last_login_platform: "Last login Platform",
  // payout section
  payout: "Payouts",
  pending_payout: "Pending",
  complete_payout: "Complete",
  paid_payout: "Paid",
  pending_payout_title: "Pending Payouts",
  complete_payout_title: "Complete Payouts",
  paid_payout_title: "Paid Payouts",
  approve_confirm: "Are you sure that you want to approve orders?",
  approve_all_confirm:
    "Are you sure that you want to approve orders from this translators?",
  back_to_confirm: "Are you sure that you want to move back to pending orders?",
  paid_confirm: "Are you sure that you want to paid orders?",
  back_paid_confirm: "Are you sure that you want to move back this?",
  see_orders: "Se tolkeopgaver",
  move_back: "Move Back",
  //Claim Section
  claims: "Claims",
  claim_created: "Claim has been successfully created.",
  claim_status_changed: "Claim status has been successfully changed.",
  claim_reply: "Claim reply has been successfully created.",
  claim_rejected: "Claim has been successfully rejected.",
  claim_resolved: "Claim has been successfully resolved.",
  add_new_claim: "Add New Claim",
  claim_reply_button: "Reply",
  claim_subject: "Subject",
  claim_reason: "Reason",
  claim_status_resolved: "Behandlet",
  claim_status_new: "Under behandling",
  claim_status_reject: "Uberettiget",
  claim_status_admin_replaed: "Under behandling",
  claim_status_translator_point: "Tolkesvar modtaget",
  claim_status_customer_replied: "Customer Replied",
  claim_creator_type_admin: "Admin",
  claim_creator_type_customer: "Customer",
  claim_creator_type_translator: "Translator",
  claim_detail: "Detail",
  is_claim_reject: "Are you sure want to reject this claim?",
  is_claim_resolve: "Are you sure claim has resolve?",
  // email
  select_users: "Select Users",
  all_users: "All Users",
  specific_users: "Specific Users",
  registered_interpreters: "Select all registered interpreters",
  user_status: "User status",
  from: "From",
  subject: "Subject",
  message_content: "Message content",
  mail_attchment: "Mail Attachment",

  send_email: "Send Email",
  custom_users: "Custom Users",
  see_mail: "See email",
  //SMS
  sms: "SMS",
  add_new_sms: "Add SMS",
  sms_title: "Title",
  sms_content: "SMS content",
  country_code: "Country Code",
  phone_number: "Phone number",
  send_sms: "Send SMS",
  sms_msg: "SMS  has been  successfully send.",
  //common-setting
  common_setting: "Common Setting",
  setting_name: "Setting Name",
  setting_value: "Setting Content",
  edit_setting: "Edit Setting",
  setting_updated: "Common setting has been successfully updated",
  // Customer calculation
  customer_calculation: "Default Customer Calculation",
  customer_time_calculation: "Customer Time Calculation",
  unfavorite_users: "Unfavorite Translator",
  favorite_users: "Favorite Translator",
  block_translator_users: "Block Translator",
  super_customer: "Super Customer",
  customer_update: "Super Customer has been  successfully added.",
  translator_calling: "Status",
  translator_calling_status:
    "Translator Calling Status has been  successfully added.",
  translator_languages_create: "Translator Languages Create.",
  translator_languages_update: "Translator Languages Update.",
  translator_rates_update: "Translator Rates Update.",
  documents: "Dokumenter",
  document: "Dokumenter",
  customer_document: "Customer's Document",
  CV: "CV",
  criminal_record: "Criminal record",
  børneattest: "Børneattest",
  document_upload: "Document has been  successfully uploaded.",
  // we learn
  learn: "Learn",
  we_learn: "We Learn",
  quiz: "Quiz",
  quiz_title: "Online Quiz",
  description: "Description",
  enter_title: "Enter Title",
  enter_description: "Enter Description",
  video: "Video",
  video_thumbnail: "Video Thumbnail",
  add_we_learn: "Add New Content",
  edit_we_learn: "Edit Content",
  view_video: "View Video",
  view_thumbnail: "View Thumbnail",
  // quiz
  quiz_type: "Quiz Type",
  online_quiz: "Online Quiz",
  is_quis_learn_p: "Is this quiz for learning purpose ?",
  select_learn: "Select We Learn",
  image: "Image",
  total_marks: "Total Marks",
  min_marks: "Minimum Marks",
  placeholder_total_marks: "Enter total marks ",
  placeholder_min_marks: "Enter minimum passing marks for quiz",
  placeholder_total_time: "Enter total time for quiz (in minutes)",
  edit_online_test_title: "Update Online Test",
  edit_online_test: "Online Test",
  edit_online_test_info: "Edit Online Test Information",
  edit_online_test_question: "Questions Info",
  edit_online_test_question_info: "Update Questions For Online Test",
  edit_online_quiz_title: "Update Online Quiz",
  edit_online_quiz: "Online Quiz",
  edit_online_quiz_info: "Edit Online Quiz Information",
  edit_online_quiz_question: "Questions Info",
  edit_online_quiz_question_info: "Update Questions For Online Quiz",
  question_title_quiz: "Online Quiz Questions",
  question_title_test: "Online Test Questions",
  add_question: "Add Question",
  edit_question: "Edit Question",
  question_type: "Question Type",
  subjective: "Subjective",
  OBJECTIVE: "Objective",
  MULTI_CHOICE: "Multi Choice",
  question: "Question",
  marks: "Marks",
  option: "Option",
  is_correct: "Is Correct",
  send_login_email: "successfully login details mail send.",
  show_cancelled: "Show Cancelled",
  show_late: "Show Late",
  show_weekend: "Show Weekend",
  show_rating: "Show Rating",
  newletter: "Newsletters",
  system_email: "System Email",
  system_sms: "System SMS",
  test_not_taken: "Online test is not taken yet.",
  logo_upload: "Logo Upload",
  // dashbaord
  dashboard: "Dashboard",
  refresh: "Refresh",
  recent_orders: "Recent Orders",
  recent_activities: "Recent Activities",
  new_users: "New Users",
  from_time: "from time",
  to_time: "to time",
  customer: "Customer",
  Load_more: "Load more",
  admin_notification: "Admin notification",
  latest_orders: "Latest Orders",
  system_activity: "System Activities",
  logs_activity: "Logs and activities",
  billing: "Billing",
  payout_statement: "Payouts  statements",
  // system information
  system_info: "System Information",
  enquiries: "Enquiries",
  // call log
  call_log: "Call Logs",
  call_start_time: "Call Start Time",
  call_end_time: "Call End Time",
  call_status: "Call Status",
  total_call_time: "Total Call Time",
  customer_name: "Customer",
  translatorname: "Translator",
  not_found: "Not Found  New Notification",
  view_file: "Download Sample File",
  translation_type_updated: "Translation Type has been successfully updated.",
  import_department_file: "Import Department & Department Unit File",
  import_excel_file: "Import department excel file",
  request_change: "Order Request Change & Cancel",
  request_cancel: "Order Cancel Request",

  badge_email_verification: "Email Verification",
  badge_gtep_test: "GTEP Test",
  recruitment_results: "Rekruttering Resultater",
  badge_preliminary_interview: "Pre-Interview",
  badge_final_interview: "Final Interview",
  badge_contract: "Contract",

  status_contract_sent: "Contract Sent",
  status_contract_not_sent: "Contract Not Sent",
  status_waiting_approval: "Contract Signed",
  status_online_test_sent: "Online Test Sent",
  status_online_test_taken: "Online Test Taken",
  status_online_test_not_sent: "Online Test Not Sent",
  status_preliminary_interview_not_taken: "Preliminary Interview Not Taken",
  final_interview_not_scheduled: "Final Interview Not Scheduled",
  final_interview_scheduled: "Final Interview Scheduled",
  status_verified: "Verificeret",
  status_un_verified: "Unverified",
  want_specific_translator_first: "Ønsker du en bestemt ",
  want_specific_translator_second: "  tolk?",
  not_want_specific_translator: "Er der en tolk, du ikke ønsker til denne opgave?",
  select_translator: "  tolk?",
  fill_the_details: "Fill the details",
  already_talk: "Er mødetidspunktet allerede aftalt med tolken?",
  if_trans_busy: "Hvis den valgte tolk er optaget?",
  contact_me_if_not_avail:
    "Kontakt mig for at finde ud af, hvornår den ønskede tolk er tilgængelig",
  trans_gender: "Foretrukket køn på tolken",
  no_sig: "Ligegyldigt",
  avail_for_another: "Opgaven må gerne tildeles en anden ledig tolk",
  from_to_not_same: "Fra og til sprog kan ikke være det samme.",
  online_test_not_found:
    "Beklager, du skal oprette en online-test, inden du sender.",
  status_request_change: "Request Change",
  status_request_cancel: "Request Cancel",
  reorder: "Reorder",
  order_day: "Order Day",
  customer_company_type: "Virksomhedstype",
  customer_company_type_public: "Offentlig Institution",
  customer_company_type_private: "Virksomhed",
  customer_company_type_association: "Forening",
  view: "Udsigt",
  exportEmail: "E-mail",
  exportSystemEmail: "System-E-mail",
  exportLanguages: "Sprog",
  exportOnlineTest: "Online-Test",
  exportQuiz: "Quiz",
  exportWeLearn: "Vi-lærer",
  exportActivityLogs: "Aktivitetslogfiler",
  exportClaims: "krav",
  exportCancelOrders: "Annuller-ordrer",
  exportCompleteOrder: "Komplet-ordrer",
  export_task_overview: "Opgaveoversigt",
  export_salary_calculation: "Lønberegning",
  exportCompletePayouts: "Komplet-Udbetalinger",
  exportPaidPayouts: "Betalte-udbetalinger",
  exportPaymentOrders: "Betalingsordrer",
  exportPendingPayouts: "Afventer-Udbetaling",
  exportRole: "Roller",
  exportCommonSetting: "Almindelige-indstillinger",
  exportCompany: "Virksomheder",
  exportDepartments: "Afdelinger",
  exportDepartmentUnit: "Department-Units",
  exportEmailTemplates: "E-mail-skabeloner",
  exportSmsTemplates: "Sms-skabeloner",
  exportSms: "SMS",
  exportSystemSms: "System-SMS",
  exportAdminUsers: "Admin-brugere",
  exportCustomers: "kunder",
  exportTranslators: "Oversættere",
  NA: "N/A",
  status_new_cancel_request: "Annuller anmodning",
  status_completed_cancel_request: "Annulleringsanmodning afsluttet",
  status_rejected_cancel_request: "Annuller anmodning afvist",
  status_new_edit_request: "Rediger anmodning",
  status_completed_edit_request: "Redigeringsanmodning afsluttet",
  status_rejected_edit_request: "Rediger anmodning afvist",
  changeStatusEditRequect: "Mark Udført",
  changeStatusCancelRequect: "Mark Udført",
  requestStatus: "Status",
  completeRequest: "Mark komplet",
  VT_ADMIN_1: "Alle",
  vt_trans_lang_64_registrer: "Registrer",
  add_date: "Tilføj dato",
  addition_info_max_length:
    "Yderligere adresseinfo kan ikke være mere end 255 tegn.",
  search_user: "Søg",
  select_status: "Vælg status",
  select_profile_status: "Vælg profilstatus",
  contract_send: "Kontrakt sendt",
  online_test_sent: "Online test sendt",
  online_test_taken: "Online test taget",
  select_gender: "Vælg køn",
  male_female: "Mand/Kvinde",
  select_rating: "Vælg bedømmelse",
  select_to_language: "Vælg til sprog",
  user_code: "ID",
  email_adress: "E-mailadresse",
  mobile_no: "Mobilnummer",
  origin_country: "Oprindelsesland",
  created_date: "Oprettelsesdato",
  pending_profile: "Afventende profil",
  passive: "Passiv",
  verified: "Verificeret",
  showing_record: "Viser 1-50 ud af XX",
  new_record: "Opret en ny tolk",
  fields: "Felter",
  qualifications: "Kvalifikationer",
  called_in_for_conversation: "Indkaldt til samtale",
  not_pick_up_the_phone: "Tager ikke telefonen ",
  nothing_interesting: "Ikke interessant",
  edit_translator: "Redigér tolkeprofil",
  email: "E-mailadresse",
  zip_code: "Postnummer",
  account_holder_name: "Kontohavers navn",
  bank_reg_no: "Reg.nr",
  bank_account_no: "Kontonr. ",
  social_security_no: "CPR-nummer",
  driver_license: "Kørekort",
  female_male: "Kvinde/Mand",
  country: "Oprindelsesland",
  yes_no: "Ja/Nej",
  expensive_scale: "Prisniveau",
  push_agreement: "Push-aftale",
  translator_info: "Tolkens oplysninger",
  language_from: "Sprog fra",
  language_to: "Sprog til",
  add_more: "Vælg flere",
  is_sign_translation: "Tegnsprogstolkning",
  translation_type: "Tolkningstype",
  orders: "Tolkeopgaver",
  search_orders: "Søg",
  select_date_range: "Vælg dato",
  choose_company: "Vælg institution",
  showing_records: "Viser 1-XX ud af XX",
  order_no: "Bestillingsnummer",
  order_date: "Dato",
  order_time: "Tidspunkt",
  translator: "Tolk",
  preference: "Præference",
  leaves: "Tilgængelighed",
  change_password: "Skift adgangskode",
  password: "Adgangskode",
  confirm_password: "Bekræft adgangskode",
  enter_password: "Indtast adgangskode",
  enter_password_again: "Indtast adgangskoden igen",
  pick_datetime: "Vælg en dato og et tidspunkt",
  schedule_pre_interview: "Planlæg et indledende interview",
  schedule_final_interview: "Planlæg et sidste interview",
  final_interview_appointment: "Endelig aftale om interview",
  pre_interview_event_title: "Foreløbig samtale med den nye ansøger",
  final_interview_event_title: "Afsluttende interview med den nye ansøger",
  notification: "Notifikationer",
  sprgopgave: "tolkeopgave",
  activity_logs: "Aktivitetshistorik",
  message: "Besked",
  mail_subject: "Emne",
  translator_contract: "Velkommen til ViTolker",
  mail_content: "Mail",
  contract_content: "Kontrakt",
  no_record_found: "Ingen optegnelser fundet",
  enter: "indføre",
  about_translator: "om oversætter",
  send_contract_mail_content: "Indhold",
  is_special_agreement: "Is special agreement",
  total_words: "Total words",

  // location module
  add_location: "Add New Location",
  edit_location: "Edit Location",
  location_update: "Location information successfully updated.",
  location_created: "New Location has been successfully created.",
  exportLocation: "Export Location",

  // holiday module
  add_holiday: "Add New Holiday",
  edit_holiday: "Edit Holiday",
  holiday_update: "Holiday information successfully updated.",
  holiday_created: "New Holiday has been successfully created.",
  exportHoliday: "Export Holiday",

  rate: "Rate",
  rate_created: "Rate created successfully",
  rate_updated: "Rate updated successfully",

  special_domain: "Special Domain",
  old_rate: "Gammel sats",
  old_sub_rate: "Gammel subrate",
  old_total: "Gammel total",
  total_rate: "Samlet sats",
  total_time: "Samlet Tim",
  view_calculation: "se beregning",
  mark_as_completed: "markere som afsluttet",
  order_move_completion: "Bestilling er blevet flyttet til afsluttet.",
  standard: "Standard",
  completed: "Completed",
  cancelled_with_charge: "Cancelled with charge",
  select_translator_string: "Vælg Tolke",
  select_booking_type_string: "Vælg reservationstype",
  select_price_type_string: "Vælg",
  price_status_option_special: "Special",
  price_status_option_standard: "Standard",
  price_status_option_fix_price: "Fix-Price",
};
