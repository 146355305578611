import * as React from 'react';
import Lang from "../../common/lang/Lang";
import {EditBreadcrumb} from "./Data";
import {DataSchema, Form, FormDefaultVal, FormFields, RequiredFields} from "./Model";
import API from './../../common/AxoisClient';
import {SubmitButton,showNotification, Breadcrumb,SwitchLoader} from "../../common";
import {LeaveDatePickerWrapper} from "../../common/LeaveDatePicker/LeaveDatePicker";
import {HolidayValidations} from "./Validations";
import {LoaderType} from "../../common/Loader";
import _ from "lodash";

interface IState{
  form: DataSchema;
  id: number;
}
export class HolidayEdit extends React.Component<{}, IState> {
  state: IState;

  constructor(props: any){
    super(props);

    this.state = {
      form: FormDefaultVal,
      id: props.match.params.id
    }
    HolidayValidations();
  }

   
  componentDidMount() {
    API.get(`settings/holiday/${this.state.id}`).then((res) => {
      this.setState({
        form: res.data
      })
    })
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target : any = event.target;
    let value = target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#create_holiday").valid()) {
      API.put(`settings/holiday/${this.state.id}`, this.state.form).then((res) => {
        showNotification("success", Lang.holiday_update);
      })
    }
  }


  render() {
    const form: Array<FormFields> = Form;
    type K1 = keyof DataSchema;
    return (
      <div>
        <Breadcrumb data={EditBreadcrumb}/>

        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                  <form className="kt-form" id="create_holiday"  onSubmit={this.handleSubmit}>
                    <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                      <div className="kt-heading kt-heading--md">{Lang.edit_holiday}</div>
                      <div className="kt-form__section kt-form__section--first">
                        <div className="kt-wizard-v2__form">
                          {form.map((item : FormFields, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="form-group" key={index}>
                                  <label>{Lang.getString(item.name)} <span className={'required'}>*</span></label>
                                  <input type={'text'} onChange={this.handleChange} value={this.state.form[item.name as K1]} name={item.name} className="form-control" placeholder={Lang.getString(`${item.name}_placeholder`)}/>
                                </div>
                                <div className={"col-md-12 col-lg-12"}>
                                  <div className="form-group">
                                    <div className="demo-section k-content">
                                      <LeaveDatePickerWrapper className="servicesPicker" id={item.on_date} name={'on_date'} onChange={this.handleChange} value={this.state.form[item.on_date as K1]} placeholder={Lang.date_placeholder} autoComplete="off"/>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}  
                        </div>
                        <div className="kt-form__actions">
                          <div className="kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                            <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}