import * as React from 'react';
import {EmailTemplateDataSchema} from "./Model";
import {SearchInput, SearchSelect} from "../../common/form/SearchInput";
import {IState as CommonState} from "../../common/State";
import {ShowRecords} from "../../common/ShowRecords";
import {ExportButton, getQueryStringParams, showNotification} from "../../common/Common";
import API from "../../common/AxoisClient";
import Lang from "../../common/lang/Lang";
import {Breadcrumb} from "../../common/page/Breadcrumb";
import Swal from "sweetalert2";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb} from "./Data";
import {CreateLink, EditLink} from "../../common/page/CreateButton";
import EmailTemplateFilter from './filter/EmailTemplateFilter';
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";

declare var window:any;

interface IState extends CommonState {
    UserData: Array<EmailTemplateDataSchema>;
   
}

export class EmailTemplates extends React.Component<RouteComponentProps, IState> {
    state: IState;
    records:React.RefObject<ShowRecords> = React.createRef();

    shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
        return  this.state !== nextState;
         
     }

    createMarkup =(content: string) => {
        return { __html: content };
    }

    showContent = (data: EmailTemplateDataSchema, index: number) => {
      let cols = [];
      cols.push(...[
        <td key={index}>{this.records.current.checkbox(data.id)}</td>,
        this.records.current.createdTd("id", index),
        this.records.current.createdTd("name", data.name),
        this.records.current.createdTd("type", data.type),
        this.records.current.createdTd("subject", data.subject),
        this.records.current.createdTd("content", () => {
          return <span dangerouslySetInnerHTML={ this.createMarkup(data.content) }/>
        }),
        this.records.current.createdTd("keywords", data.keywords),
        this.records.current.createdTd("status", () => {
          if(data.status === 1){return <span className={'badge badge-primary'}>{Lang.active}</span>;}
          if(data.status === 2){return <span className={'badge badge-warning'}>{Lang.inactive}</span>}
        }),
        this.records.current.createdTd("created_at", data.created_at),
        this.records.current.createdTd("action", () => {
          return <div>
            <EditLink scope={'email-template.update'} to={`${this.props.match.url}/edit/${data.id}`}  title={Lang.edit_email_template} />
          </div>;
        })
      ]);
      cols = _.compact(cols);
        return (
            <tr key={list_key(data.id)}>{cols}</tr>
        );
    }

  
    filterRecords = (data?: any) => {
        if( this.records && this.records.current !== null){
          if(data !== undefined){
            this.records.current.setFormFields(data);
          }else {
            this.records.current.setFormFields();
          }
        }
      }

     

    actions = (actionType: string) => {
        let typeText;
        switch (actionType) {
            case "active":typeText = Lang.active_confirm;break;
            case "inactive":typeText = Lang.inactive_confirm;break;
            case "delete":typeText = Lang.delete_confirm;break;
        }
        Swal.fire({
            title: Lang.is_sure,text: typeText,type: 'warning',showCancelButton: true,confirmButtonColor: '#3085d6',cancelButtonColor: '#d33',confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.value) {
                let ids: any = this.records.current.state.selected;
                API.post("emailtemplates/email-temp-action", {ids, actionType: actionType}).then(response => {
                    showNotification("success", response.data.message);
                    this.records.current.fetchData();
                });
            }
        })
    }

    render() {
        const actionButton = [
            {label: 'Activate', "class": "btn btn-sm btn-success", "onClick" : () => this.actions('active'), show: true},
            {label: 'DeActivate',  "class": "btn btn-sm btn-warning", "onClick" : () => this.actions('inactive'), show: true},
            {label: 'Delete', "class": "btn btn-sm btn-danger", "onClick" : () => this.actions('delete'), show: false},
        ];

        return (
            <div>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <Breadcrumb data={ListBreadcrumb}/>
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg">
                        <div className="kt-portlet__head-label">
                        <span className="kt-portlet__head-icon">
                            <i className="kt-font-brand flaticon2-new-email"/>
                        </span>
                            <h3 className="kt-portlet__head-title">
                                Email Templates
                            </h3>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <ExportButton records={this.records} fileName={Lang.exportEmailTemplates}/>
                                    &nbsp;
                                  <CreateLink to={`${this.props.match.url}/create`} scope={'email-template.store'} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <form method={'get'} id={'filter_form'}>
                        <div className="kt-portlet__body">
                            <div className="kt-form kt-form--label-right kt-margin-t-20 kt-margin-b-10">
                                <div className="row align-items-center">
                                    <div className="col-xl-8 order-2 order-xl-1">

                                        <EmailTemplateFilter  getResults={this.filterRecords} />
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ShowRecords
                            url={"emailtemplates/email-templates"}
                            showRecords={this.showContent}
                            //showCheckbox={false}
                            ref={this.records}
                            isNeedAction={true}
                            actionButtons={actionButton}
                            callBack={() => {
                                window.KTApp.initTooltips();
                            }}
                        />
                    </form>
                </div>
                </div>
            </div>
        )
    }
}
