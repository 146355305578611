import * as React from 'react';
import API from "../../common/AxoisClient";
import { Editor } from '@tinymce/tinymce-react';
import {CONST, showNotification} from "../../common/Common";
import Lang from "../../common/lang/Lang";
import {RouteComponentProps} from "react-router";
import {Breadcrumb} from "../../common/page/Breadcrumb";
import {EmailTemplateValidations} from "./Validations";
import {CreateBreadcrumb} from "./Data";
import {CommonSubmitButton,SwitchLoader} from "../../common";
import {LoaderType} from "../../common/Loader";
import * as _ from "lodash";

export interface ETemplateState {
    name : string;
    type : string;
    subject : string;
    content : string;
    keyword : string;
    typeList : Array<undefined>;
    keywordList : any;
    keywordArr : Array<any>;
}

export class CreateEmailTemplates extends React.Component<RouteComponentProps,ETemplateState> {
    state: ETemplateState;
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            name:'',
            type:'',
            subject:'',
            content:'',
            keyword:'',
            typeList: [],
            keywordList: {},
            keywordArr: []
        }
        EmailTemplateValidations();
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleEditorChange(content : any, editor : any) {
        if(content !== ''){
            $( '.tox-tinymce' ).css( 'border-color', '#ccc' );
            $("#errorEditor").html(" ");
            this.setState({ content });
        } else {
            $( '.tox-tinymce' ).css( 'border-color', '#fd397a' );
            $("#errorEditor").html("Dette felt skal udfyldes.");
        }
    }

    handleCheckboxChange = (event : React.ChangeEvent<any>) => {
        const targetCheck = event.target;
        const checkValue = targetCheck.value;
        if(event.target.checked === true) {
            this.setState((prevState:any) => ({
                keywordArr: [...prevState.keywordArr, checkValue],
                content : prevState.content+' '+ checkValue+''
            }) );
        } else {
            this.setState({
                keywordArr: this.state.keywordArr.filter(function(item: number) {
                    return item !== checkValue
                })
            });
        }
    }

    onChange = (event : React.ChangeEvent<any>) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        } as any);
    }

    handleTypeChange = (event : React.ChangeEvent<any>) => {
        const typeValues = event.target;
        const value = typeValues.value;
        let allKeywords:Array<any> = [];
        if(value !=='') {
            let parentKey:any = this.state.typeList[value];
            if(typeof (parentKey[0]['parent_key']) !== 'undefined' && parentKey[0]['parent_key'] !=''){
                let parentData:Array<any> = this.state.typeList[parentKey[0]['parent_key']];
                let objData:Array<any> = this.state.typeList[value];

                //allKeywords = _.uniqBy([...parentData, ...objData],'db_name');

                allKeywords['Parent'] = parentData;
                allKeywords['Child'] = objData;

            } else {
                allKeywords['Parent'] =this.state.typeList[value];
            }
        }

        this.setState({
            keywordList: allKeywords,
            type:value
        });
    }

    componentDidMount() {
        API.get("emailtemplates/get-email-type").then(res => {
            this.setState({
                typeList: res.data
            });
        })
    }
    
    handleSubmit(event : any) {
       
        event.preventDefault();
        SwitchLoader(LoaderType.BUTTON);
        if($("#create_emailTemplate").valid() || this.state.content === ''){
            if(this.state.content === ''){
                $( '.tox-tinymce' ).css( 'border-color', '#fd397a' );
                $("#errorEditor").html("Dette felt skal udfyldes.");
                return false;
            }
            API.post("emailtemplates/email-templates", this.state).then(response => {
                showNotification("success", Lang.email_temp_created);
                this.props.history.push(`/settings/email-templates`);
            });
        }
    }

    resetForm = () => { 
        this.setState({
            name:'',
            type:'',
            subject:'',
            content:'',
            keyword:'',
            typeList: [],
            keywordList: {},
            keywordArr: []
        });
    }
    render() {
        return (
            <div>
                <Breadcrumb data={CreateBreadcrumb}/>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <form className="kt-form" onSubmit={this.handleSubmit} id={'create_emailTemplate'}>
                    <div className="col-md-6" style={{float:'left'}}>
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        {Lang.add_email_template}
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" className="form-control" id="name" name="name"
                                           value={this.state.name} onChange={this.onChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleSelect1">Type</label>
                                    <select className="form-control" onChange={this.handleTypeChange}  id="type" name="type" >
                                        <option value="">Select</option>
                                        {  !_.isEmpty( this.state.typeList ) &&
                                            _.keys(this.state.typeList).map((item: string) => {
                                                return   <option>{item}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Subject</label>
                                    <input type="text" className="form-control"
                                           id="subject" name="subject" value={this.state.subject} onChange={this.onChange} />
                                </div>

                                <div className="form-group">
                                    <label>Content</label>
                                    <Editor  apiKey={CONST.TINY_MCE_KEY} id='content'
                                            init={{
                                                menubar:false,mode: "textareas",selector: 'textarea',branding: false,forced_root_block : false
                                            }} value={this.state.content} onEditorChange={this.handleEditorChange} />
                                <span id='errorEditor' style={{color:'#fd397a'}}></span>
                                </div>
                               
                            </div>
                            
                            <div className='reset-btn-section'>
                                <CommonSubmitButton/>
                                <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn'} data-skin={'dark'} title={Lang.reset} onClick={this.resetForm}>{Lang.reset}</button>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6" style={{float:'left'}}>
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                <br/>
                                {this.state.keywordList.Parent &&
                                this.state.keywordList.Parent.length > 0 &&
                                <label>Keywords For { this.state.keywordList.Parent[0].key_word_type }</label>
                                }
                                <br/>
                                <div className={'row'}>
                                {
                                    this.state.keywordList.Parent &&
                                    this.state.keywordList.Parent.length > 0 &&
                                    this.state.keywordList.Parent.map((value:any, key:number)=> {
                                        return <div className={'col-md-6'}><div className="form-group"><label><input  key={key} type="checkbox" id="keyword"  onChange={(e:any)=>this.handleCheckboxChange(e)} checked={this.state.keywordArr.indexOf('{{'+_.toUpper(value.db_name)+'}}')>-1} name="keyword" value={'{{'+_.toUpper(value.db_name)+'}}'} />&nbsp;&nbsp;{value.display_name} </label></div></div>
                                    })
                                }
                                </div>
                                <br/>
                                {this.state.keywordList.Child &&
                                this.state.keywordList.Child.length > 0 &&
                                <label>Keywords For { this.state.keywordList.Child[0].key_word_type }</label>
                                }
                                <br/>
                                <div className={'row'}>
                                    {
                                        this.state.keywordList.Child &&
                                        this.state.keywordList.Child.length > 0 &&
                                        this.state.keywordList.Child.map((value:any, key:number)=> {
                                            return <div className={'col-md-6'}><div className="form-group"><label><input  key={key} type="checkbox" id="keyword"  onChange={(e:any)=>this.handleCheckboxChange(e)} checked={this.state.keywordArr.indexOf('{{'+_.toUpper(value.db_name)+'}}')>-1} name="keyword" value={'{{'+_.toUpper(value.db_name)+'}}'} />&nbsp;&nbsp;{value.display_name} </label></div></div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            </div>
        )
    }
}
