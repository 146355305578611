import * as React from 'react';
import { ClaimReplyModel } from "../../model/ClaimReply";
import Lang from "../../common/lang/Lang";
import API, {SwitchLoader} from "../../common/AxoisClient";
import { showNotification } from "../../common/Common";
import { ClaimReplyValidations } from "./Validations";
import {Auth} from "../../Auth/Auth";
import Swal from 'sweetalert2';
import {LoaderType, SubmitButton} from "../../common";
import _ from "lodash";
let moment = require("moment");
declare var window: any;


export class ClaimsDetail extends React.Component<ClaimReplyModel> {
    state: ClaimReplyModel;
    constructor(props: any) {
        super(props);
        this.state = {
            id: props.match.params.id,
            claim_id : 0,
            creator_type : 1,
            user_id : Auth.userInfo.id,
            comment : '',
            created_at : '',
            chat: {},
            claim:{},
            order:{},
            status:3,
        }       
        ClaimReplyValidations();
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    moveToBottom = ()=>{
        $("html, body").animate({ scrollTop: $(document).height() }, 1000);
    }

    getChatData = ()=> {
        API.get(`orders/claim-reply/${this.state.id}`).then((res: any) => {
            this.setState({
                chat: res.data.data
            }, () => {
                window.KTApp.initTooltips();
            })
        });
    }

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        SwitchLoader(LoaderType.BUTTON);
        if ($("#reply_claim").valid()) {
            this.state.claim_id = this.state.claim.id;
            API.post('orders/claim-reply/create', this.state).then((res) => {
                this.setState({comment: ''})
                this.getChatData();
                this.render();
                showNotification("success", Lang.claim_reply);
            });
        }
    }

    handleReject = () => {
        if ($("#reply_claim").valid()) {
            Swal.fire({
                title: Lang.is_sure,
                text: Lang.is_claim_reject,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.value) {
                    this.state.claim_id = this.state.claim.id;
                    this.setState({status: 2});
                    API.post('orders/claim-reply/create', this.state).then((res) => {
                        showNotification("success", Lang.claim_rejected);
                        this.setState({comment: '', claim: { ...this.state.claim, status: 2} });
                    });
                }
            });
        }else{
            this.moveToBottom();
        }
    }

    handleResolved = () => {
        if ($("#reply_claim").valid()) {
            Swal.fire({
                title: Lang.is_sure,
                text: Lang.is_claim_resolve,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.value) {
                    this.state.claim_id = this.state.claim.id;
                    this.setState({status: 0});
                    API.post('orders/claim-reply/create', this.state).then((res) => {
                        showNotification("success", Lang.claim_resolved);
                        this.setState({ comment: '', claim: { ...this.state.claim, status: 0} });
                    });
                }
            });
        }else{
            this.moveToBottom();
        }
    }

    componentDidMount() {
      this.getChatData();
      API.get(`orders/claim/getClaim/${this.state.id}`, {params: {id: this.state.id}}).then((res) => {
          this.setState({
              claim: res.data,
              order:res.data.order
          });

      });

    }

    handleChange = (event: React.ChangeEvent<any>) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }


    render() {

        return (
            <div className="SpaceRemoveTop">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="kt-portlet">
                                    <div className="kt-portlet__head">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">
                                                {Lang.claim_detail}
                                            </h3>
                                        </div>
                                    </div>
                                    <form className="kt-form" onSubmit={this.handleSubmit} id={'reply_claim'}>
                                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content" id={'kt_chat_content'}>
                                            <div className="kt-chat">
                                                <div className="kt-portlet kt-portlet--head-lg kt-portlet--last">
                                                    <div className="kt-portlet__head fixHead">
                                                        <div className="kt-chat__head ">
                                                            <div className="kt-chat__left">
                                                            </div>
                                                            <div className="kt-chat__left">
                                                                {this.state.claim &&
                                                                (this.state.claim.status != 0 && this.state.claim.status != 2) &&
                                                                <div className="mb-4">
                                                                    <a onClick={this.handleResolved}
                                                                       style={{color: 'white'}} id={`Id-Resolved`}
                                                                       className="btn btn-success btn-sm">{Lang.claim_status_resolved}</a>
                                                                    &nbsp;
                                                                    <a onClick={this.handleReject} style={{color: 'white'}}
                                                                       id={`Id-Reject`}
                                                                       className="btn btn-warning btn-sm">{Lang.claim_status_reject}</a>
                                                                </div>
                                                                }
                                                                <div className="kt-chat__label">
                                                                    <a href="#" className="kt-chat__title">Order #{this.state.order.order_no}</a>
                                                                    <div className="mt-2 mb-2"><b>{Lang.claim_reason}</b>: {this.state.claim.reason}<br/>
                                                                        <b>{Lang.claim_subject}</b>: {this.state.claim.subject}</div>

                                                                    <span className="kt-chat__status">
                                                                        {this.state.claim.status == '0' && <span><span className="kt-badge kt-badge--dot kt-badge--success"/> {Lang.claim_status_resolved}</span>}
                                                                        {this.state.claim.status == '1' && <span><span className="kt-badge kt-badge--dot kt-badge--success"/> {Lang.claim_status_new}</span>}
                                                                        {this.state.claim.status == '2' && <span><span className="kt-badge kt-badge--dot kt-badge--danger"/> {Lang.claim_status_reject}</span>}
                                                                        {this.state.claim.status == '3' && <span><span className="kt-badge kt-badge--dot kt-badge--success"/> {Lang.claim_status_admin_replaed}</span>}
                                                                        {this.state.claim.status == '4' && <span><span className="kt-badge kt-badge--dot kt-badge--danger"/> {Lang.claim_status_customer_replied}</span>}
                                                                        {this.state.claim.status == '5' && <span><span className="kt-badge kt-badge--dot kt-badge--warning"/> {Lang.claim_status_translator_point}</span>}
                                                                    </span>
                                                                </div>
                                                                <div className="kt-chat__pic kt-hidden">
                                                                    <span className="kt-userpic kt-userpic--sm kt-userpic--circle" data-toggle="kt-tooltip"
                                                                          data-placement="right" title="Jason Muller" data-original-title="Tooltip title">
                                                                    </span>
                                                                    <span className="kt-userpic kt-userpic--sm kt-userpic--circle" data-toggle="kt-tooltip"
                                                                          data-placement="right" title="Nick Bold" data-original-title="Tooltip title">
                                                                    </span>
                                                                    <span className="kt-userpic kt-userpic--sm kt-userpic--circle" data-toggle="kt-tooltip"
                                                                          data-placement="right" title="Milano Esco" data-original-title="Tooltip title">
                                                                    </span>
                                                                    <span className="kt-userpic kt-userpic--sm kt-userpic--circle" data-toggle="kt-tooltip"
                                                                          data-placement="right" title="Teresa Fox" data-original-title="Tooltip title">
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="kt-chat__right">

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="kt-portlet__body">
                                                        <div className="kt-scroll kt-scroll--pull" data-mobile-height="300">
                                                            <div className="kt-chat__messages">
                                                                {
                                                                    this.state.chat &&
                                                                    this.state.chat.length > 0 &&
                                                                    this.state.chat.map((chat,index) => {
                                                                        if(chat.creator_type >= 2) {
                                                                            return  <div className={"kt-chat__message"}>
                                                                                <div className="kt-chat__user">
                                                                                        <span className="kt-userpic kt-userpic--circle kt-userpic--sm">
                                                                                            { chat.user !== null &&
                                                                                                <img
                                                                                                    src={chat.user.profile_image}
                                                                                                    alt="image"/>
                                                                                            }
                                                                                        </span>
                                                                                    <a href="#" className="kt-chat__username">{(chat.user !== null && chat.user.name !== null)?chat.user.name:''}</a>
                                                                                    <span className="kt-chat__datetime">{moment(new Date(chat.created_at)).format('DD-MM-YYYY')}</span>
                                                                                </div>
                                                                                <div className="kt-chat__text kt-bg-light-success">
                                                                                    {chat.comment}
                                                                                </div>
                                                                            </div>;
                                                                        } else {
                                                                            return <div className="kt-chat__message kt-chat__message--right">
                                                                                <div className="kt-chat__user">
                                                                                    <span className="kt-chat__datetime">{moment(new Date(chat.created_at)).format('DD-MM-YYYY')}</span>
                                                                                    <a href="#" className="kt-chat__username">{(!_.isEmpty(chat.user))?chat.user.name:''}</a>
                                                                                    <span className="kt-userpic kt-userpic--circle kt-userpic--sm">
																	                <img src={(!_.isEmpty(chat.user))?chat.user.profile_image:''} alt="image" />
																                </span>
                                                                                </div>
                                                                                <div className="kt-chat__text kt-bg-light-brand">
                                                                                    {(!_.isEmpty(chat.comment))?chat.comment:''}
                                                                                </div>
                                                                            </div>;
                                                                        }

                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.claim &&
                                                    (this.state.claim.status != 0 && this.state.claim.status != 2) &&
                                                        <div className="kt-portlet__foot">
                                                            <div className="kt-chat__input">
                                                                <div className="kt-chat__editor">
                                                                    <textarea name="comment"
                                                                              onChange={this.handleChange}
                                                                              value={this.state.comment}
                                                                              style={{height: '50px'}}
                                                                              placeholder="Type here..." />
                                                                </div>
                                                                <div className="kt-chat__toolbar">
                                                                    <div className="kt_chat__tools">

                                                                    </div>
                                                                    <div className="kt_chat__actions">
                                                                        <SubmitButton className={'btn btn-primary'} label={Lang.claim_reply_button}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.claim &&
                                                    (this.state.claim.status == 0) &&
                                                        <div className="kt-portlet__foot">
                                                            <div className="alert alert-success" role="alert">
                                                                {Lang.claim_resolved}
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.claim &&
                                                    (this.state.claim.status == 2) &&
                                                        <div className="kt-portlet__foot">
                                                            <div className="alert alert-outline-danger fade show" role="alert">
                                                                {Lang.claim_rejected}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}