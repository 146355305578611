import React, {useState, useEffect} from 'react';
import {Breadcrumb, RequestWaiting} from "../../common";
import Lang from "../../common/lang/Lang";
import {ChangePwdBreadcrumb} from "./Data";
import {RouteComponentProps} from "react-router";
import { QuizModel} from "../../model";
import {getOnlineQuizzes} from "../../services";
import {QuizResponseList} from "./QuizResponseList";

export function  TranslatorOnlineQuiz (props: RouteComponentProps) {
  const {id}: any = props.match.params;

  const [quiz, setQuiz] = useState([]);

  useEffect(() => {
    getOnlineQuizzes(id).then(res => {
      setQuiz(res);
    });
  }, []);

  return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
      <Breadcrumb data={ChangePwdBreadcrumb}/>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="row">
          <div className="col-xl-12">
            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">Taken online quizzes</h3>
                </div>
              </div>
              <br/>
              <div className={'kt-portlet__body'}>
                <RequestWaiting item={quiz}>
                  <div className="accordion accordion-light  accordion-toggle-arrow" id="accordionExample2">
                    {
                      quiz.length > 0 && quiz.map((q: QuizModel) => {
                        return (
                          <div className="card" key={q.id}>
                            <div className="card-header" id={`headingOne${q.id}`}>
                              <div className="card-title collapsed" data-toggle="collapse" data-target={`#collapseOne${q.id}`} aria-expanded="false" aria-controls="collapseOne2">
                                {q.title}
                              </div>
                            </div>
                            <div id={`collapseOne${q.id}`} className="collapse" aria-labelledby={`headingOne${q.id}`} data-parent="#accordionExample2">
                              <div className="card-body">
                                <QuizResponseList quiz={q}/>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </RequestWaiting>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}