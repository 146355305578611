import * as React from 'react';
import Lang from "../../../common/lang/Lang";
import * as _ from 'lodash';
 
export const OrderTranslatorInfo = (props?: any) => {
 if(props.info.translate_by){
  return (
    <div className="col-xl-4">
            <div className="kt-portlet kt-portlet--height-fluid">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title kt-font-success">
                     {Lang.translator_info}
                  </h3>
                </div>               
              </div>
              <div className="kt-portlet__body">
                <div className="kt-widget4">
                  <div className="kt-widget4__item">                   
                    <span className="kt-widget4__title kt-widget4__title--light">
                       {Lang.name}
                    </span>
                    <span className="kt-widget4__number kt-font-info">{props.info.translate_by.name}</span>
                  </div>
                  <div className="kt-widget4__item">                   
                    <span className="kt-widget4__title kt-widget4__title--light">
                       {Lang.translator_gender}
                    </span>
                    <span className="kt-widget4__number kt-font-info">{props.info.translate_by.gender == 1 ? Lang.male : Lang.female }</span>
                  </div>   
                  <div className="kt-widget4__item">                 
                    <span className="kt-widget4__title kt-widget4__title--light">
                      {Lang.email}
                    </span>
                    <span className="kt-widget4__number kt-font-info">{props.info.translate_by.email}</span>
                  </div>
                  <div className="kt-widget4__item">                 
                    <span className="kt-widget4__title kt-widget4__title--light">
                      {Lang.address}
                    </span>
                    <span className="kt-widget4__number kt-font-info">{props.info.translate_by.address}</span>
                  </div>
                  <div className="kt-widget4__item">                 
                    <span className="kt-widget4__title kt-widget4__title--light">
                      {Lang.contact_number}
                    </span>
                    <span className="kt-widget4__number kt-font-info">{props.info.translate_by.country_code+'-'+props.info.translate_by.mobile_no}</span>
                  </div>
                  
                </div>
                  
              </div>
            </div>
          </div>     
     
  )
                } else{
                  return null
                }

 }