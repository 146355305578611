import Lang from "../../common/lang/Lang";

declare var window: any;
export const ClaimValidations = function () {
  $(document).ready(function () {
    $( "#create_claim" ).validate({
      rules: {
        order_id: {
          required: true
        },
        subject: {
          required: true,
          maxlength: 255
        },
        reason: {
          required: true
        },
      },

      invalidHandler: function(event, validator) {
        var alert = $('#kt_form_1_msg');
        alert.removeClass('kt--hide').show();
      },
    });
  });
}

export const ClaimReplyValidations = function () {
  $(document).ready(function () {
    $( "#reply_claim" ).validate({
      rules: {
        id: {
          required: true
        },
        claim_id: {
          required: true,
        },
        creator_type: {
          required: true
        },
        user_id: {
          required: true
        },
        comment: {
          required: true,
          maxlength: 500
        },
        created_at: {
          required: true
        },
      },

      invalidHandler: function(event, validator) {
        var alert = $('#kt_form_1_msg');
        alert.removeClass('kt--hide').show();
      },
    });
  });
}