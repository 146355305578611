import * as React from 'react';
import Lang from "../../common/lang/Lang";
import {CreateBreadcrumb} from "./Data";
import {DataSchema, Form, FormDefaultVal, FormFields, RequiredFields} from "./Model";
import API from './../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {showNotification, CommonSubmitButton,SwitchLoader, LoaderType} from "../../common";
import {LeaveDatePickerWrapper} from "../../common/LeaveDatePicker/LeaveDatePicker";
import {HolidayValidations} from "./Validations";
import {CreateLayout} from "../../layout/CreateLayout";
import Cropper from 'cropperjs';
import _ from 'lodash';

const moment = require("moment");

interface IState{
  form: DataSchema;
}
export class HolidayCreate extends React.Component<RouteComponentProps, IState> {
  state: IState;
 
  constructor(props: RouteComponentProps){
    super(props);
    
    this.state = {
      form: FormDefaultVal
    }
    HolidayValidations();
  }

  

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    $(".servicesPicker").css({"color": "rgba(0,0,0,.87)"});
    const target : any = event.target;
    let value = target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

 

  componentDidMount() {
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#create_holiday").valid()) {
      API.post('settings/holiday', this.state.form).then((res) => {
        showNotification("success", Lang.holiday_created);
        this.props.history.push(`/settings/holiday`)
      })
    }
  }


  render() {
    const form: Array<FormFields> = Form;
    type K1 = keyof DataSchema;
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'add_holiday'}>
          <form className="kt-form" onSubmit={this.handleSubmit} id={'create_holiday'}>
            <div className="kt-portlet__body">
              <div className={'row'}>
                {form.map((item : FormFields, index) => {
                 return (
                    <React.Fragment key={index}>
                      <div className={"col-md-12 col-lg-12"}>
                        <div className="form-group">
                          <label>{Lang.getString(item.name)} <span className={'required'}>*</span></label>
                          <input type={'text'} onChange={this.handleChange} value={this.state.form[item.name as K1]} name={item.name} className="form-control" placeholder={Lang.getString(`${item.name}_placeholder`)}/>
                        </div>
                      </div>
                      <div className={"col-md-12 col-lg-12"}>
                        <div className="form-group">
                          <div className="demo-section k-content">
                            <LeaveDatePickerWrapper className="servicesPicker" id={item.on_date} name={'on_date'} onChange={this.handleChange} value={this.state.form[item.on_date as K1]} placeholder={Lang.date_placeholder} autoComplete="off"/>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            <CommonSubmitButton/>
          </form>
        </CreateLayout>
    )
  }
}