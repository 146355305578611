import React, {useState, useEffect} from 'react';
import {postComments, qualificationService, saveQualificationData} from "../../../services";
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {Select, showNotification, SubmitButton} from "../../../common";
import {Stars} from "../../../common/Stars";
import {UserModel} from "../../../model";
import CreateCalendarEvent from '../../../common/form/CreateCalendarEvent';
import _ from 'lodash';

const moment = require("moment");

interface FormValues {
  training: string | number
  highest_complete_edu: string
  current_employment: string
  motivation: string
  tolk_ratings: string
  interview_scheduled_at: string
  comments: string
}

export function Qualification() {
  const initial_form_values: FormValues = {
    training: '',
    highest_complete_edu: '',
    current_employment: '',
    motivation: '',
    tolk_ratings: '',
    interview_scheduled_at: '',
    comments: '',
  }

  const [show, setShow] = useState(false);
  const [user, setUser] = useState({} as UserModel);
  const [content, setContent] = useState<FormValues>(initial_form_values);
  const [schedulePreInterview, setSchedulePreInterview] = useState(false);
  const [scheduleFinalInterview, setScheduleFinalInterview] = useState(false);
  const [calendarEvent, setcalendarEvent] = useState(null);
  const [eventToUpdate, setEventToUpdate] = useState(null);
  
  useEffect(() => {
    qualificationService.getPopupState().subscribe((res:any) => {
      setSchedulePreInterview(false);
      const {show, user : {translator}, user} = res;
      setShow(show);
      if(show){
        setUser(user);
        setContent({
          training: translator.training,
          highest_complete_edu: translator.highest_complete_edu,
          current_employment: translator.current_employment,
          motivation: translator.motivation,
          tolk_ratings: translator.tolk_ratings,
          interview_scheduled_at: translator.interview_scheduled_at ? translator.interview_scheduled_at : "",
          comments: translator.comments ? translator.comments : '',
        });
        setScheduleFinalInterview(!_.isEmpty(translator.interview_scheduled_at))
      }
    })
  });

  if(show === false){
    return null;
  }

  async function handleSubmit(e) {
    e.preventDefault();
      // move forward with the qualifaction questions 
      // If the user chose to schedule the final interview and not the preliminary interview
      if (!schedulePreInterview) {
        // if the checkbox to schedule a final interview is checked but the calendarEvent is not defined: trigger alert
        if (scheduleFinalInterview && !calendarEvent) {
          // TO-DO: Make better validation, this is just temporary
          alert("Indtast venligst en gyldig dato og et gyldigt klokkeslæt.");
        } else {
          // get the final interview date from the calendarEvent and save it with the POST request content
          if (scheduleFinalInterview) content.interview_scheduled_at = moment(calendarEvent.event.start.dateTime).format("yyyy-MM-DD HH:mm:ss");
          saveQualificationData(user.id, content).then(res => {
            showNotification('success', res);
            if (scheduleFinalInterview) {
              // insert the calendarEvent in the user's calendar
              let request, notificationMessage;
              console.log("Event to update: ", eventToUpdate);
              // In case the event already exists in the calendar, we update it
              if (eventToUpdate) {
                console.log("Updating event");
                request = calendarEvent.gapi.client.calendar.events.patch({
                  'calendarId': 'primary',
                  'eventId': eventToUpdate,
                  'sendUpdates': 'all',
                  'resource': calendarEvent.event
                });
                notificationMessage = "Interviewet er blevet opdateret i din kalender";
              }
              // if the event is not already scheduled, we create a new one
              else {
                console.log("Creating new event");
                request = calendarEvent.gapi.client.calendar.events.insert({
                  'calendarId': 'primary',
                  'sendUpdates': 'all',
                  'resource': calendarEvent.event
                });
                notificationMessage = "Interviewet er blevet planlagt i din kalender";
              }
              request.execute((e) => {
                showNotification('success', notificationMessage);
                console.log(e.htmlLink);
              });
              closeSchedule();
            }
            qualificationService.hidePopup();
          });
        }
      }
      // schedule an appointment for a preliminary interview
      else if (calendarEvent) {
        let request = calendarEvent.gapi.client.calendar.events.insert({
          'calendarId': 'primary',
          'sendUpdates': 'all',
          'resource': calendarEvent.event
        });
        request.execute((e) => {
          showNotification('success', "Interviewet er blevet planlagt i din kalender");
          closeSchedule();
          qualificationService.hidePopup();
        });
      }
  }
  
  function handleChange(e){
    setContent({...content, [e.target.name]:e.target.value})
  }

  function handleStarsChange(e){
    setContent({...content, [e.target.name]:e.target.id.slice(-1)})
  }

  function schedulePreInterviewChecked() {
    setSchedulePreInterview(!schedulePreInterview);
    setcalendarEvent(null);
  }

  function scheduleFinalInterviewChecked() {
    setScheduleFinalInterview(!scheduleFinalInterview);
    setcalendarEvent(null);
  }

  function closeSchedule() {
    setSchedulePreInterview(false);
    setScheduleFinalInterview(false);
    setcalendarEvent(null);
  }

  function resetForm(event:any){ 
    event.preventDefault();
    setContent({...content,  training: 0, highest_complete_edu: '', current_employment: '', 
        motivation: '', tolk_ratings: ''});
    closeSchedule();
  }

  return(
    <Modal
      show={show}
      onHide={() => setShow(false) }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {Lang.qualification_title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
              <div className={'col-md-12'} >
                <div className={'form-group'}>
                  <label>{'Interpreter training?'}<span className={'required'}>*</span></label>
                  <Select disabled={schedulePreInterview} name='training' value={content.training} onChange={handleChange} required={true} className={'form-control'}>
                  <option value=''>Vælg status</option>
                    <option value={1}>Ingen tolkeuddannelse</option>
                    <option value={2}>Statsautoriseret translatør</option>
                    <option value={3}>Uddannet konferencetolk</option>
                    <option value={4}>Gennemført afgangseksamen fra Tolkeakademiet</option>
                    <option value={5}>Tilknytning til Rigspolitiets tolkeoversigt</option>
                  </Select>
                </div>
                <div className={'form-group'}>
                  <label>{Lang.highest_complete_edu}</label>
                  <input disabled={schedulePreInterview} type={'text'} name={'highest_complete_edu'} value={content.highest_complete_edu} onChange={handleChange}
                         className={'form-control'} required={true}/>
                </div>
                <div className={'form-group'}>
                  <label>{Lang.current_employment}</label>
                  <input disabled={schedulePreInterview} type={'text'} name={'current_employment'} value={content.current_employment} onChange={handleChange}
                         className={'form-control'} required={true}/>
                </div> 
                <div className={'form-group'}>
                  <label>{Lang.motivation}</label>
                  <input disabled={schedulePreInterview} type={'text'} name={'motivation'} value={content.motivation} onChange={handleChange}
                         className={'form-control'} required={true}/>
                </div>
                {/* HR Comment */}
                <div className={'form-group'}>
                  <label htmlFor="comments">{Lang.comment_for_translator}</label>
                  <textarea disabled={schedulePreInterview} rows={3} id="comments" name={'comments'} value={content.comments} onChange={handleChange}
                         className={'form-control'}/>
                </div>
                {/* stars rating */}
                <div className={'form-group'}>
                  <label>{Lang.tolk_ratings}</label>
                  <div>
                    <Stars disabled={schedulePreInterview} name={'tolk_ratings'} onChange={handleStarsChange} value={content.tolk_ratings} />
                  </div>
                </div>
                {/* Final interview appointment */}
                <div className={'form-group'}>
                  <label className="kt-checkbox">
                      <input disabled={schedulePreInterview} type="checkbox" className="custom-control-input" name={'schedule_pre_interview'}
                        checked={scheduleFinalInterview} onChange={scheduleFinalInterviewChecked} value={Number(scheduleFinalInterview)} />
                      <span/>
                    {Lang.schedule_final_interview}
                  </label>
                  <CreateCalendarEvent eventTitle={`${Lang.final_interview_event_title} - ${user.name}`} disabled={schedulePreInterview || !scheduleFinalInterview} 
                  required={!schedulePreInterview && scheduleFinalInterview} eventDuration={30} attendees={user} setcalendarEvent={setcalendarEvent} startDate={content.interview_scheduled_at}
                   interview_scheduled_at={content.interview_scheduled_at ? content.interview_scheduled_at : null} setEventToUpdate={setEventToUpdate}/>
                </div>
                
                <hr className={'horizontal-divider'}/> 
                
                {/* Schedule pre-interview */}
                <div className={'form-group'}>
                  <label className="kt-checkbox">
                      <input type="checkbox" className="custom-control-input" name={'schedule_pre_interview'}
                        checked={schedulePreInterview} onChange={schedulePreInterviewChecked} value={Number(schedulePreInterview)} />
                      <span/>
                    {Lang.schedule_pre_interview}
                  </label>
                </div>
                {
                  schedulePreInterview && (
                    <div className={'form-group'}>
                      <CreateCalendarEvent eventTitle={`${Lang.pre_interview_event_title} - ${user.name}`} required={schedulePreInterview} eventDuration={15} attendees={user} setcalendarEvent={setcalendarEvent}
                       interview_scheduled_at={content.interview_scheduled_at ? content.interview_scheduled_at : null} setEventToUpdate={setEventToUpdate} />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={'btn btn-info'} data-skin={'dark'} title={Lang.reset} onClick={resetForm}>{Lang.reset}</button>
          <SubmitButton className={'btn btn-success'} label={Lang.submit} />
          <button className={'btn btn-primary'} onClick={() =>  setShow(false) }>{Lang.close}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
