import React, { useEffect, useState, useReducer, useRef } from "react";
import { OrderfeedbackService, setfeedback } from "../../../services";
import Lang from "../../../common/lang/Lang";
import { Modal } from "react-bootstrap";
import { Stars } from "../../../common/Stars";
import { RequestWaiting, SubmitButton, EditLink, showNotification } from "../../../common";
import {
  InitialState,
  PopupState,
  ActionType,
  CAction,
  OrderFeedbackEditReducer,
} from "../order-reducers/OrderFeedbackEditReducer";
import { OrderfeedbackEditService } from "../../../services";
import { CancelProps } from "../order-reducers/CancelOrderReducer";

export function OrderFeedbackEditPopup(Props: CancelProps) {
  const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(
    OrderFeedbackEditReducer,
    InitialState
  );
  const { order_data } = state;

  const defaultFormState = {
    id: order_data.id,
    rating: order_data.rating,
    comment: order_data.message,
    again_rate: 0,
  };
  let rating: any = 0;
  const [order, setOrder] = useState();
  useEffect(() => {
    OrderfeedbackEditService.getPopupState().subscribe((message: any) => {
      dispatch({ type: message.action, payload: message });
    });
  }, []);

  useEffect(() => {
    clickStar(order_data.rating);
  }, [state.show]);

  if (state.show === false) {
    return null;
  }
  function handleChange(e: React.ChangeEvent<HTMLElement>) {
    const target: any = e.target;
    const value = target.value;
    const name = target.name;
    dispatch({ type: ActionType.HANDLE_CHANGE, payload: { value: value, name: name } as any });
  }

  function handleSubmit(event) {
    event.preventDefault();
    if ($("#feedback_edit_form").valid()) {
      setfeedback(order_data.id, state).then((res) => {
        showNotification("success", res.message);
        Props.refresh();
        OrderfeedbackEditService.hidePopup();
      });
    }
  }
  //add event listener
  function clickStar(ele) {
    var rating = document.querySelector(".rating");
    var clickedStar = ele;
    var ratingValue = parseInt(clickedStar);
    for (var i = 0; i < ratingValue; i++) {
      rating.children[i].classList.add("clicked-star");
      for (var j = ratingValue; j <= 4; j++) {
        if (rating.children[j].classList.contains("clicked-star")) {
          rating.children[j].classList.remove("clicked-star");
        }
      }
    }
    dispatch({ type: ActionType.HANDLE_CHANGE, payload: { value: ele, name: "rating" } as any });
  }

  return (
    <Modal
      show={state.show}
      onHide={() => {
        dispatch({ type: ActionType.HIDE_POPUP });
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form onSubmit={handleSubmit} id="feedback_edit_form">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {Lang.order_feedback} - #{order_data.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={"row rating-section"}>
              <div className="colData">
                <div className=" form-group group">
                  <label htmlFor="rating">{Lang.rating}</label>
                  <div className="rating">
                    <i
                      className="fa fa-star"
                      aria-hidden="true"
                      data-value="1"
                      onClick={(e) => clickStar("1")}
                    ></i>
                    <i
                      className="fa fa-star"
                      aria-hidden="true"
                      data-value="2"
                      onClick={(e) => clickStar("2")}
                    ></i>
                    <i
                      className="fa fa-star"
                      aria-hidden="true"
                      data-value="3"
                      onClick={(e) => clickStar("3")}
                    ></i>
                    <i
                      className="fa fa-star"
                      aria-hidden="true"
                      data-value="4"
                      onClick={(e) => clickStar("4")}
                    ></i>
                    <i
                      className="fa fa-star"
                      aria-hidden="true"
                      data-value="5"
                      onClick={(e) => clickStar("5")}
                    ></i>
                  </div>
                </div>
              </div>
              <div className="colData">
                <div className=" form-group group">
                  <label htmlFor="message">
                    {Lang.order_feedback} {Number(order_data.rating) < 3 && <>*</>}
                  </label>
                  <textarea
                    id="message"
                    name={"message"}
                    onChange={handleChange}
                    className="form-control form-control2"
                    maxLength={499}
                    placeholder={Lang.order_feedback}
                    defaultValue={order_data.message}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton className={"btn btn-primary"} label={Lang.submit} />
        </Modal.Footer>
      </form>
    </Modal>
  );
}
