import * as React from 'react';
import {SearchInput, SearchSelect} from "../../common/form/SearchInput";
import {IState as CommonState} from "../../common/State";
import {ShowRecords} from "../../common/ShowRecords";
import {CONST, ExportButton, getQueryStringParams} from "../../common/Common";
import {Icons} from "../../common/Icon";
import {actionButton} from "../../common/table/BlukActionButtons";
import {RouteComponentProps} from "react-router";
import {LanguageModel} from "../../model/Language";
import {ListBreadcrumb} from './Data';
import {ActionLink, CreateLink, EditLink, GeneratePermitLink} from "../../common/page/CreateButton";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {default as Lang} from "../../common/lang/Lang";
import LanguageFilter from './filter/LanguageFilter';
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";


interface IState extends CommonState{
  UserData: Array<LanguageModel>;
  
}


export class LanguageListing extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  
  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

   

    showContent = (data: LanguageModel, index: number) => {
       let cols = [];
        cols.push(...[
            <td key={index}>{this.records.current.checkbox(data.id)}</td>,
            this.records.current.createdTd("id", index),
           
            this.records.current.createdTd("language_name", () =>{return (data.language_name !== null) ? data.language_name : '--'; }),
            this.records.current.createdTd("countries.name", () =>{
              return  (data.countries !== null ) ? data.countries.name : '---';
              }),

          this.records.current.createdTd("status", () => {
              if(data.status === 1){
                  return <span className={'badge badge-primary'}>{'Active'}</span>
              } else{
                  return <span className={'badge badge-warning'}>{'Inactive'}</span>
              }
          }),

     this.records.current.createdTd("action", () => {
                    return (
                        <div>
                            <EditLink scope={'language.edit'} to={`/languages/language/edit/${data.id}`} data-skin={'dark'}  title={Lang.edit_language} />

                        </div>
                    )
                })

        ]);
        cols = _.compact(cols);

        return (
            <tr key={list_key(data.id)}>{cols}</tr>
        );
    
  }
 



  render() {
    const status = CONST.status;
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'languages'} icon={Icons.language} >
            <ExportButton records={this.records} fileName={Lang.exportLanguages}/>
            &nbsp;
           
          </ListHeader>

          <ListForm
            form={(
              <LanguageFilter status={status}  getResults={this.filterRecords} />
          )}

            table={(

              <ShowRecords
                  url={"settings/language"}
                  showRecords={this.showContent}
                  ref={this.records}
                isNeedAction={true}
                  actionButtons={actionButton({records: this.records, 'url': "settings/language/bulk-action"})}
              />
          )}
          />
        </ListingLayout>
    )
  }
}