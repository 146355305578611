import { ErrorMessage, Field } from 'formik';
import React from 'react';

const TextAreaInput = (props) => {
    const {
        name,
        label,
        required = false,
        ...fieldProps
    } = props;
    return (
        <div className="form-group">
            <label htmlFor={name}>
                {label}
                {
                    required && <span className={"required"}>*</span>
                }
            </label>
            <Field
                name={name}
                id={name}
                as="textarea"
                {...fieldProps}
            />
            <ErrorMessage render={msg => <div className="text-danger">{msg}</div>} name={name} />
        </div>
    );
}

export default TextAreaInput;
