import * as React from 'react';
import {
  SearchInput, SearchSelect, IState as CommonState, ShowRecords,
  CONST, ExportButton, getQueryStringParams, actionButton, EditLink, Icons, DateInput, showConfirmBox
} from "../../common";
import {RouteComponentProps} from "react-router";
import {UserModel} from "../../model";
import {ListBreadcrumb, Payouts} from "./Data";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import {SeeOrders} from "../orders/OrdersPopup";
import {PayoutServices, SeePayoutOrderService} from "../../services/PayoutServices";
import Lang from "../../common/lang/Lang";
import PendingFilter from './filter/PendingFilter';
import {list_key} from "../../helpers/OrderHelper";
import API from "../../common/AxoisClient";
import {showNotification, NoRecords} from "../../common/Common";
import {convert_comma_to_dot, convert_dot_to_comma, convert_to_decimal_format} from "../../helpers/UserHelper";

interface IState {
    data: Array<any>;  
    payoutData?:Array<any>; 
    status?:number;
    order_ids?:Array<any>;
    translators:Array<any>;  
    filterData?:Array<any>;
}

export class PendingPayouts extends React.Component<RouteComponentProps, IState> {
    state: IState;
    records:React.RefObject<ShowRecords> = React.createRef();

    constructor(props: RouteComponentProps){
        super(props);
        this.state = {
            payoutData: [],
            data:[],
            status:0,
            order_ids:[],
            translators:[],
            filterData:[]
        }
    }
    
    componentDidMount() {
        this.getPayoutListing();        
    } 

    getPayoutListing = (filterData? :any) => {
        API.post("/payouts/pendingPayoutListing", filterData).then(res => {
            console.log('res',res);
            this.setState({
                payoutData :res.data.data,
                translators:res.data.translators
            })
        });
    }

    approveAllOrders = (trans_id) => {
        PayoutServices.setApproveAllOrders(trans_id).then(res=> {
            this.filterRecords();
        })
    };
  
    shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        return  this.state !== nextState;
     
    }
    showContent = (data: Payouts, index: number) => {
        let cols = [];
        cols.push(...[
            this.records.current.createdTd("id", index),
            this.records.current.createdTd("name", data.user.name),
            this.records.current.createdTd("total_order", data.total_order),
            this.records.current.createdTd("bank_info", () => {
            return (
                <div>
                    <p>Account no - {!_.isEmpty(data.user.translator) &&  data.user.translator.account_no}</p>
                    <p>Bank registration - {!_.isEmpty(data.user.translator) && data.user.translator.bank_registration_no}</p>
                    <p>CPR No - {!_.isEmpty(data.user.translator) && data.user.translator.social_no}</p>
                </div>
            )
        }),
            this.records.current.createdTd("total_work", data.total_work),
            this.records.current.createdTd("total_amount", data.total_amount),
            this.records.current.createdTd("total_distance", data.total_distance),
            this.records.current.createdTd("total_travel_time", data.total_travel_time),
            this.records.current.createdTd("see_orders", () => {
                return (<button type={'button'} className="btn btn-warning" onClick={() => SeePayoutOrderService.showPopup(data.user.id, 'pending')}> {Lang.see_orders} </button>)
            }),
            this.records.current.createdTd("action", () =>  {
            return <button className="btn btn-warning" type={'button'} onClick={() => this.approveAllOrders(data.user.id)}>{Lang.approve}</button>;
            })
        ]);
        cols = _.compact(cols);

        return (
            <tr key={list_key(data.id)}>{cols}</tr>
        );
    };
 

    filterRecords = (data?: any) => {
        if(data !== undefined){
            {/* this.records.current.setFormFields(data);*/}
            this.setState({filterData:data});
            this.getPayoutListing(data);
        }else {
            this.setState({filterData:[]});
            this.getPayoutListing(data);
            {/*this.records.current.setFormFields();*/}
        }
    }

    checkAll = (event:any, index1:any) => {
        var index = (index1-1);
        var inputElement = document.getElementsByTagName('input');
        var ids = (this.state.order_ids && this.state.order_ids.length >0)? this.state.order_ids : [];

        const chk = event.target;
        for (var i = 0; i < inputElement.length; i++) {
            if(inputElement[i].className=='checkOrder_'+index){
                if(chk.checked===true){
                    inputElement[i].checked=true;
                    console.log('Addvalue', inputElement[i].value);
                    ids.push(Number(inputElement[i].value));
                }else if(chk.checked===false){
                    inputElement[i].checked=false;
                    var idIndex:any = ids.indexOf(Number(inputElement[i].value));
                    console.log('RemoveValue', inputElement[i].value);
                    if (idIndex !== -1) {
                      this.state.order_ids.splice(idIndex, 1);
                    }
                }
            }
        }
        this.setState({order_ids: ids});
    }

    check = (event:any, orderIndex:any, id:any) => {
        const chk = event.target;
        var ids = (this.state.order_ids && this.state.order_ids.length >0)? this.state.order_ids : [];
        if(chk.checked===true){
            ids.push(id);
        }else if(chk.checked===false){
            const index = ids.indexOf(id);
            if (index > -1) {
                ids.splice(index, 1);
            }
        }
        this.setState({order_ids: ids});
    }

    handleChangeStatus = (event: React.ChangeEvent <any>) => {
        if(this.state.order_ids && this.state.order_ids.length >0){
            showConfirmBox(Lang.payout_status_confirm, async () => {
                API.post('/payouts/updatePendingPayoutToCompleted',{ids:this.state.order_ids}).then(res => {
                    showNotification("success", Lang.order_move_completion);
                    this.setState({order_ids:[]})
                    this.getPayoutListing();
                });
            })
        }
    }

    getExtraCalc = (totalExtraSlots, totalSubRate = 0, finalExtraRate = 0) => {
        var calcTotalExtraRates:number = Number(totalSubRate) * totalExtraSlots;
        var returnFinalRate:number = calcTotalExtraRates;
        return returnFinalRate;
    }

    checkSpecialCalculations = (ordersData:any, specialCompanyRates:any) =>{
        console.log('Special');
        var specialRatesData: any = null;
        var specialCompanyRatesData: any = specialCompanyRates.special_company_rates;
        var calcPerKmRate = 0;
        var orderOldRate:any = (ordersData.old_transport_fee_fix_rate) ? ordersData.old_transport_fee_fix_rate : '0,00';
        var orderOldSubRate:any = (ordersData.old_transport_fee_sub_rate) ? ordersData.old_transport_fee_sub_rate : '0,00';
        var extra_per: any;
        var minKm:any;
        var totalTravelExtraRates = 0;
        var totalTravelRates = 0;
        
        if(ordersData.is_special_company==1){
            let getCompanyId = ordersData.company_id;
            let getEmail = ordersData.company_email.split('@');
            
            if(specialCompanyRatesData){
                specialCompanyRatesData.filter(function(item) {
                    if(item.special_domain && getEmail[1] && item.special_domain.toLowerCase() == getEmail[1].toLowerCase() || item.company_id == getCompanyId){
                        specialRatesData = item;
                    }
                });
            }
        }
        if(specialRatesData){
            if(ordersData.translation_type_id==1){
                var old_rate:any = 0;
                var telephone_fix_rate: any = 0;
                var telephone_fix_minute: any = 0;
                var old_sub_rate: any = 0;
                var telephone_sub_rate: any = 0;
                var telephone_sub_minute: any = 0;

                old_rate = convert_dot_to_comma(specialRatesData.special_telephone_fix_rate);
                telephone_fix_rate = convert_dot_to_comma(specialRatesData.special_telephone_fix_rate);
                telephone_fix_minute = specialRatesData.special_telephone_fix_minute;
                old_sub_rate = convert_dot_to_comma(specialRatesData.special_telephone_sub_rate);
                telephone_sub_rate = convert_dot_to_comma(specialRatesData.special_telephone_sub_rate);
                telephone_sub_minute = specialRatesData.special_telephone_sub_minute;


                var totalDiff = 0;
                var minuteDiff = 0;
                if(ordersData.complete_order_to && ordersData.status==5) {
                  var timeEnd = new Date(ordersData.order_date +' '+ ordersData.complete_order_to).getTime();
                } else {
                  var timeEnd = new Date(ordersData.order_date +' '+ ordersData.order_to).getTime();
                }
                var timeStart = new Date(ordersData.order_date +' '+ ordersData.order_from).getTime();
                totalDiff = timeEnd - timeStart;
                minuteDiff = Math.ceil(totalDiff / 60000);
                
                var totalRates:number = 0;
                var totalExtraRates:number = 0;
                if(minuteDiff > telephone_fix_minute){
                  var calcExtraMinutes = minuteDiff - telephone_fix_minute;
                  if(calcExtraMinutes >= telephone_sub_minute && Number(telephone_sub_minute) > 0){
                    var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes)/Number(telephone_sub_minute));
                    if(extraMinutesSlots==0){
                        extraMinutesSlots = 1;
                    }
                    totalExtraRates = this.getExtraCalc(extraMinutesSlots, convert_comma_to_dot(telephone_sub_rate), Number(convert_comma_to_dot(telephone_sub_rate)));
                    totalRates = Number(convert_comma_to_dot(telephone_fix_rate)) + Number(totalExtraRates);
                  } else {
                    var extraMinutesSlots: number = 1;
                    totalExtraRates = Number(convert_comma_to_dot(telephone_sub_rate));
                    totalRates = Number(convert_comma_to_dot(telephone_fix_rate)) + Number(convert_comma_to_dot(telephone_sub_rate));
                  }
                } else {
                    totalRates = Number(convert_comma_to_dot(telephone_fix_rate));
                }

                if(extra_per){
                  var before_extra_rate = totalRates;
                  totalRates = totalRates * ((100+Number(convert_comma_to_dot(extra_per)))/100);
                  var extraPerRate = totalRates - before_extra_rate;
                }
            }
            if(ordersData.translation_type_id==2){
                var calcPerKmRate = 0;
                var old_rate:any = 0;
                var physical_fix_rate: any = 0;
                var physical_fix_minute: any = 0;
                var old_sub_rate: any = 0;
                var physical_sub_rate: any = 0;
                var physical_sub_minute: any = 0;
                var min_km: any = 0;
                var old_per_km_rate: any = 0;
                var per_km_rate: any = 0;
                var old_transport_fee_fix_rate: any = 0;
                var transport_fee_minute: any = 0;
                var old_transport_fee_sub_rate: any = 0;
                var transport_fee_sub_minute: any = 0;
                var transport_fee_fix_rate: any = 0;
                var transport_fee_sub_rate: any = 0;
                var transport_fee_agreed: any = 0;
                            
                if(specialRatesData.special_min_km==null){
                    minKm = 0;
                }else{
                    minKm = specialRatesData.special_min_km;
                    
                }
                var calcRemainKm = ordersData.total_distance - (minKm * 2);
                if(calcRemainKm > 0 && specialRatesData.special_compensation_agreed==1){
                    calcPerKmRate = calcRemainKm * Number(convert_comma_to_dot(String(specialRatesData.special_per_km_rate)));             
                }

                if(specialRatesData.special_transport_fee_agreed==1){
                    if(ordersData.travel_time > specialRatesData.special_transport_fee_minute) {
                        var calcTravelExtraMinutes = ordersData.travel_time - (specialRatesData.special_transport_fee_minute * 2);

                        console.log('calcTravelExtraMinutes', calcTravelExtraMinutes);
                        console.log('special_transport_fee_sub_minute', specialRatesData.special_transport_fee_sub_minute);
                        
                        if(calcTravelExtraMinutes >= specialRatesData.special_transport_fee_sub_minute && Number(specialRatesData.special_transport_fee_sub_minute) > 0){
                            var extraTransportMinutesSlots: number = Math.ceil(Number(calcTravelExtraMinutes)/Number(specialRatesData.special_transport_fee_sub_minute));
                            if(extraTransportMinutesSlots==0){
                                extraTransportMinutesSlots = 1;
                            }
                            console.log('extraTransportMinutesSlots',extraTransportMinutesSlots);
                        
                            totalTravelExtraRates = this.getExtraCalc(extraTransportMinutesSlots, convert_comma_to_dot(specialRatesData.special_transport_fee_sub_rate), Number(convert_comma_to_dot(specialRatesData.special_transport_fee_sub_rate)));

                            console.log('special_transport_fee_sub_rate', specialRatesData.special_transport_fee_sub_rate);

                            console.log('totalTravelExtraRates',totalTravelExtraRates);

                            totalTravelRates = (Number(convert_comma_to_dot(specialRatesData.special_transport_fee_fix_rate)) * 2) + Number(totalTravelExtraRates); 

                            console.log('special_transport_fee_fix_rate', specialRatesData.special_transport_fee_fix_rate);

                            console.log('totalTravelRates',totalTravelRates);
                      } else {
                        var extraTransportMinutesSlots: number = 1;
                        totalTravelExtraRates = Number(convert_comma_to_dot(specialRatesData.special_transport_fee_sub_rate));
                        totalTravelRates = (Number(convert_comma_to_dot(specialRatesData.special_transport_fee_fix_rate)) * 2) + Number(specialRatesData.special_transport_fee_sub_rate);  
                      }
                    } else {
                        totalTravelRates = (Number(convert_comma_to_dot(specialRatesData.special_transport_fee_fix_rate)) * 2);
                    }
                }

                old_rate = convert_dot_to_comma(specialRatesData.special_physical_fix_rate);
                physical_fix_rate = convert_dot_to_comma(specialRatesData.special_physical_fix_rate);
                physical_fix_minute = specialRatesData.special_physical_fix_minute;
                old_sub_rate = convert_dot_to_comma(specialRatesData.special_physical_sub_rate);
                physical_sub_rate = convert_dot_to_comma(specialRatesData.special_physical_sub_rate);
                physical_sub_minute = specialRatesData.special_physical_sub_minute;
                min_km = specialRatesData.special_min_km;
                old_per_km_rate = convert_dot_to_comma(specialRatesData.special_per_km_rate);
                per_km_rate = convert_dot_to_comma(specialRatesData.special_per_km_rate);
                old_transport_fee_fix_rate = convert_dot_to_comma(specialRatesData.special_transport_fee_fix_rate);
                transport_fee_minute = specialRatesData.special_transport_fee_minute;
                old_transport_fee_sub_rate = convert_dot_to_comma(specialRatesData.special_transport_fee_sub_rate);
                transport_fee_sub_minute = specialRatesData.special_transport_fee_sub_minute;
                transport_fee_fix_rate = convert_dot_to_comma(specialRatesData.special_transport_fee_fix_rate);
                transport_fee_sub_rate = convert_dot_to_comma(specialRatesData.special_transport_fee_sub_rate);
                transport_fee_agreed = specialRatesData.special_transport_fee_agreed;

                var totalDiff = 0;
                var minuteDiff = 0;
                if (ordersData.complete_order_to && ordersData.status==5) {
                    var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.complete_order_to).getTime();
                } else {
                    var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.order_to).getTime();
                }
                var timeStart = new Date(ordersData.order_date + ' ' + ordersData.order_from).getTime();

                totalDiff = timeEnd - timeStart;
                minuteDiff = Math.ceil(totalDiff / 60000);

                var totalRates: number = 0;
                var totalExtraRates: number = 0;
                if (minuteDiff > physical_fix_minute) {
                    var calcExtraMinutes = minuteDiff - physical_fix_minute;
                    if (calcExtraMinutes >= physical_sub_minute && Number(physical_sub_minute) > 0) {
                        var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes) / Number(physical_sub_minute));
                        if(extraMinutesSlots==0){
                            extraMinutesSlots = 1;
                        }
                        totalExtraRates = this.getExtraCalc(extraMinutesSlots, convert_comma_to_dot(physical_sub_rate), Number(convert_comma_to_dot(physical_sub_rate)));
                        totalRates = Number(convert_comma_to_dot(physical_fix_rate)) + Number(totalExtraRates) + (calcPerKmRate) + (totalTravelRates * 2);
                    } else {
                        var extraMinutesSlots: number = 1;
                        totalExtraRates = Number(convert_comma_to_dot(physical_sub_rate));
                        totalRates = Number(convert_comma_to_dot(physical_fix_rate)) + Number(convert_comma_to_dot(physical_sub_rate)) + (calcPerKmRate) + (totalTravelRates * 2);
                    }
                } else {
                    totalRates = Number(convert_comma_to_dot(physical_fix_rate)) + (calcPerKmRate) + (totalTravelRates * 2);
                }

                if (extra_per) {
                    var before_extra_rate = totalRates;
                    totalRates = totalRates * ((100 + Number(convert_comma_to_dot(extra_per))) / 100);
                    var extraPerRate = totalRates - before_extra_rate;

                }
            }
            if(ordersData.translation_type_id==3){
                var old_rate:any = 0;
                var video_fix_rate: any = 0;
                var video_fix_minute: any = 0;
                var old_sub_rate: any = 0;
                var video_sub_rate: any = 0;
                var video_sub_minute: any = 0;

                old_rate = convert_dot_to_comma(specialRatesData.special_video_fix_rate);
                video_fix_rate = convert_dot_to_comma(specialRatesData.special_video_fix_rate);
                video_fix_minute = specialRatesData.special_video_fix_minute;
                old_sub_rate = convert_dot_to_comma(specialRatesData.special_video_sub_rate);
                video_sub_rate = convert_dot_to_comma(specialRatesData.special_video_sub_rate);
                video_sub_minute = specialRatesData.special_video_sub_minute;

                console.log('Video Special rates', specialRatesData);

                var totalDiff = 0;
                var minuteDiff = 0;
                if(ordersData.complete_order_to && ordersData.status==5) {
                  var timeEnd = new Date(ordersData.order_date +' '+ ordersData.complete_order_to).getTime();
                } else {
                  var timeEnd = new Date(ordersData.order_date +' '+ ordersData.order_to).getTime();
                }
                var timeStart = new Date(ordersData.order_date +' '+ ordersData.order_from).getTime();
                
                totalDiff = timeEnd - timeStart;
                minuteDiff = Math.ceil(totalDiff / 60000);
                var totalRates:number = 0;
                var totalExtraRates:number = 0;
                if(minuteDiff > video_fix_minute){
                  var calcExtraMinutes = minuteDiff - video_fix_minute;

                  console.log('video');
                  console.log('calcExtraMinutes', Number(calcExtraMinutes));
                  console.log('video_sub_minute', Number(video_sub_minute));
                  if(calcExtraMinutes >= video_sub_minute && Number(video_sub_minute) > 0){
                    var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes)/Number(video_sub_minute));
                    if(extraMinutesSlots==0){
                        extraMinutesSlots = 1;
                    }
                    totalExtraRates = this.getExtraCalc(extraMinutesSlots, convert_comma_to_dot(video_sub_rate), Number(convert_comma_to_dot(video_sub_rate)));
                    totalRates = Number(convert_comma_to_dot(video_fix_rate)) + Number(totalExtraRates); 
                  } else {
                    var extraMinutesSlots: number = 1;
                    totalExtraRates = Number(convert_comma_to_dot(video_sub_rate));
                    totalRates = Number(convert_comma_to_dot(video_fix_rate)) + Number(convert_comma_to_dot(video_sub_rate));
                  }
                } else {
                  totalRates = Number(convert_comma_to_dot(video_fix_rate));
                }

                console.log('extraMinutesSlots', extraMinutesSlots);
                console.log('totalExtraRates', totalExtraRates);

                if(extra_per){
                  var before_extra_rate = totalRates;
                  totalRates = totalRates * ((100+Number(convert_comma_to_dot(extra_per)))/100);
                  var extraPerRate = totalRates - before_extra_rate;
                }
            }
            if(ordersData.translation_type_id==5){
                var old_rate:any = 0;
                var tel_message_fix_rate: any = 0;
                var telephone_sub_rate: any = 0;
                var total_tel_message_fix_rate: any = 0;
                
                old_rate = convert_dot_to_comma(specialRatesData.special_tel_message_fix_rate);
                tel_message_fix_rate = convert_dot_to_comma(specialRatesData.special_tel_message_fix_rate);
                total_tel_message_fix_rate = Number(convert_comma_to_dot(specialRatesData.special_tel_message_fix_rate));
                
                var totalRates: number = total_tel_message_fix_rate;
            } 
            if(ordersData.translation_type_id==6){
                var old_rate:any = 0;
                var per_word_rate: any = 0;
                var min_word: any = 0;
                var totalRates: number
                old_rate = convert_dot_to_comma(specialRatesData.special_per_word_rate);
                per_word_rate = convert_dot_to_comma(specialRatesData.special_per_word_rate);
                min_word = specialRatesData.special_min_word ? specialRatesData.special_min_word: 0;
                if(ordersData.total_words){
                    if(Number(ordersData.total_words) >= min_word){
                        totalRates = Number(ordersData.total_words) * Number(convert_comma_to_dot(per_word_rate));
                    }
                }
            }
            return(
                <React.Fragment>
                    {ordersData.translation_type_id==1 && specialRatesData &&
                        <React.Fragment>
                        <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th>Type</th>
                            <th>Antal</th>
                            <th>Sats</th>
                            <th>Total</th>
                          </tr>
                          <tr>
                            <td>Telefon-tolkning, Påbegyndt {specialRatesData.special_telephone_fix_minute} min.</td>
                            <td>1</td>
                            <td>{convert_to_decimal_format(specialRatesData.special_telephone_fix_rate)} kr</td>
                            <td>{convert_to_decimal_format(specialRatesData.special_telephone_fix_rate)} kr</td>
                          </tr>
                          {extraMinutesSlots > 0 &&
                              <tr>
                                <td>Telefon-tolkning, Efterfølgende {specialRatesData.special_telephone_sub_minute} min.</td>
                                <td>{(extraMinutesSlots) ? extraMinutesSlots : '0'}</td>
                                <td>{convert_to_decimal_format(specialRatesData.special_telephone_sub_rate)} kr</td>
                                <td>{(totalExtraRates)?convert_to_decimal_format(String(totalExtraRates)):'0,00'} kr</td>
                              </tr>
                          }
                          { specialRatesData.special_holiday_charge_agreed==1 && ordersData.extra_percentage_rate &&
                              <tr>
                                <td>Er der aftalt aftens tillæg, weeekend (lørdag & søndag) og helligdage (Kl: 17:01-Kl: 07:59 )?</td>    
                                <td>{convert_to_decimal_format(specialRatesData.special_extra_holiday_percentage)}</td>
                                <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td>
                                <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td>
                              </tr>
                          }
                          </tbody>
                        </table>
                        </React.Fragment>
                    }
                    {ordersData.translation_type_id==2 && specialRatesData &&
                        <React.Fragment>
                        <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th>Type</th>
                            <th>Antal</th>
                            <th>Sats</th>
                            <th>Total</th>
                          </tr>
                          <tr>
                            <td>Fremmøde-tolkning, Påbegyndt {specialRatesData.physical_fix_minute} min.</td>
                            <td>1</td>
                            <td>{convert_to_decimal_format(specialRatesData.special_physical_fix_rate)} kr</td>
                            <td>{convert_to_decimal_format(specialRatesData.special_physical_fix_rate)} kr</td>
                          </tr>
                          {extraMinutesSlots > 0 &&
                              <tr>
                                <td>Fremmøde-tolkning, Efterfølgende {specialRatesData.special_physical_sub_minute} min.</td>
                                <td>{(extraMinutesSlots) ? extraMinutesSlots : '0'}</td>
                                <td>{convert_to_decimal_format(specialRatesData.special_physical_sub_rate)} kr</td>
                                <td>{(totalExtraRates)?convert_to_decimal_format(String(totalExtraRates)):'0,00'} kr</td>
                              </tr>
                          }
                          { specialRatesData.special_holiday_charge_agreed==1 && ordersData.extra_percentage_rate &&
                              <tr>
                                <td>Er der aftalt aftens tillæg, weeekend (lørdag & søndag) og helligdage (Kl: 17:01-Kl: 07:59 )?</td>
                                <td>{convert_to_decimal_format(specialRatesData.special_extra_holiday_percentage)}</td>
                                <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td>
                                <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td>
                              </tr>
                          }
                          {specialRatesData.special_compensation_agreed == 1 && ordersData.total_distance && specialRatesData.special_min_km && calcRemainKm > 0 &&
                              <tr>
                                  <td>KM (Both way)</td>
                                  <td>[{convert_dot_to_comma(ordersData.total_distance)} - {convert_to_decimal_format(String(specialRatesData.special_min_km * 2))}] = {convert_to_decimal_format(calcRemainKm)}</td>
                                  <td>{convert_to_decimal_format(String(specialRatesData.special_per_km_rate))} kr</td>
                                  <td>{convert_to_decimal_format(String(convert_comma_to_dot(specialRatesData.special_per_km_rate) * calcRemainKm))} kr</td>
                              </tr>
                          }
                          {specialRatesData.special_transport_fee_agreed == 1 && specialRatesData.special_transport_fee_fix_rate &&
                              <tr>
                                <td>Påbegyndt: {specialRatesData.special_transport_fee_fix_rate} for {specialRatesData.special_transport_fee_minute} minutter (Both way)</td>    
                                <td>2</td>
                                <td>{convert_to_decimal_format(String(specialRatesData.special_transport_fee_fix_rate))} kr</td>
                                <td>{convert_to_decimal_format(String(convert_comma_to_dot(specialRatesData.special_transport_fee_fix_rate) * 2))} kr</td>    
                              </tr>
                          }
                          {specialRatesData.special_transport_fee_agreed == 1 && specialRatesData.special_transport_fee_sub_rate && extraTransportMinutesSlots > 0 &&
                              <tr>
                                <td>Efterfølgende {specialRatesData.special_transport_fee_sub_rate} for {specialRatesData.special_transport_fee_sub_minute} minutter (Both way)</td>
                                <td>{extraTransportMinutesSlots}</td>
                                <td>{convert_to_decimal_format(String(totalTravelExtraRates))} kr</td>
                                <td>{convert_to_decimal_format(String(totalTravelExtraRates))} kr</td>
                              </tr>
                          }
                          </tbody>
                        </table>
                        </React.Fragment>
                    }
                    {ordersData.translation_type_id==3 && specialRatesData &&
                        <React.Fragment>
                        <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th>Type</th>
                            <th>Antal</th>
                            <th>Sats</th>
                            <th>Total</th>
                          </tr>
                          <tr>
                            <td>Video-tolkning, Påbegyndt {specialRatesData.special_video_fix_minute} min.</td>
                            <td>1</td>
                            <td>{convert_to_decimal_format(specialRatesData.special_video_fix_rate)} kr </td>
                            <td>{convert_to_decimal_format(specialRatesData.special_video_fix_rate)} kr</td>
                          </tr>
                            {extraMinutesSlots > 0 &&
                              <tr>
                                <td>Video-tolkning, Efterfølgende {specialRatesData.special_video_sub_minute} min.</td>
                                <td>{(extraMinutesSlots) ? extraMinutesSlots : '0'}</td>
                                <td>{convert_to_decimal_format(specialRatesData.special_video_sub_rate)} kr </td>
                                <td>{(totalExtraRates)?convert_to_decimal_format(String(totalExtraRates)):'0,00'} kr</td>
                              </tr>
                            }
                            { specialRatesData.special_holiday_charge_agreed==1 && specialRatesData.special_extra_holiday_percentage &&
                                <tr>
                                    <td>Er der aftalt aftens tillæg, weeekend (lørdag & søndag) og helligdage (Kl: 17:01-Kl: 07:59 )?</td>
                                    <td>{convert_to_decimal_format(specialRatesData.special_extra_holiday_percentage)}</td>
                                    <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td>
                                    <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td>
                                </tr>
                            }
                          </tbody>
                        </table>
                        </React.Fragment>
                    }
                    {ordersData.translation_type_id==5 && specialRatesData &&
                        <React.Fragment>
                         <table className="table table-bordered">
                         <tbody>
                          <tr>
                            <th>Type</th>
                            <th>Antal</th>
                            <th>Sats</th>
                            <th>Total</th>
                          </tr>
                          <tr>
                            <td>Telefonbesked {specialRatesData.special_tel_message_fix_rate}</td>
                            <td>-</td>
                            <td>{convert_to_decimal_format(String(ordersData.rate))} kr</td>
                            <td>{convert_to_decimal_format(String(ordersData.rate))} kr</td>
                          </tr>
                          </tbody>
                        </table>
                        </React.Fragment>
                    }
                    {ordersData.translation_type_id==6 && specialRatesData &&
                        <React.Fragment>
                        <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th>Type</th>
                            <th>Antal</th>
                            <th>Sats</th>
                            <th>Total</th>
                          </tr>
                          <tr>
                            <td>Skriftlig Oversættelse, Min words {specialRatesData.special_min_word}</td>
                            <td>{ordersData.total_word} X {specialRatesData.special_per_word_rate}</td>
                            <td>{convert_to_decimal_format(ordersData.rate)} kr </td>
                            <td>{convert_to_decimal_format(ordersData.rate)} kr</td>
                          </tr>
                        </tbody>
                        </table>
                        </React.Fragment>
                    }
                </React.Fragment>
            );
        }
    }

    checkStandardCalculations = (ordersData:any, person:any) => {
        console.log('Standard');
        var extra_per:any=0;
        if (ordersData.translation_type_id == 1) {
            var old_rate: any = 0;
            var telephone_fix_rate: any = 0;
            var telephone_fix_minute: any = 0;
            var old_sub_rate: any = 0;
            var telephone_sub_rate: any = 0;
            var telephone_sub_minute: any = 0;


            old_rate = convert_dot_to_comma(person.translator.telephone_fix_rate);
            telephone_fix_rate = convert_dot_to_comma(person.translator.telephone_fix_rate);
            telephone_fix_minute = person.translator.telephone_fix_minute;
            old_sub_rate = convert_dot_to_comma(person.translator.telephone_sub_rate);
            telephone_sub_rate = convert_dot_to_comma(person.translator.telephone_sub_rate);
            telephone_sub_minute = person.translator.telephone_sub_minute;


            var totalDiff = 0;
            var minuteDiff = 0;
            if (ordersData.complete_order_to && ordersData.status==5) {
                var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.complete_order_to).getTime();
            } else {
                var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.order_to).getTime();
            }
            var timeStart = new Date(ordersData.order_date + ' ' + ordersData.order_from).getTime();
            totalDiff = timeEnd - timeStart;
            minuteDiff = Math.ceil(totalDiff / 60000);


            var totalRates: number = 0;
            var totalExtraRates: number = 0;
            if (minuteDiff > telephone_fix_minute) {
                var calcExtraMinutes = minuteDiff - telephone_fix_minute;
                if (calcExtraMinutes >= telephone_sub_minute && Number(telephone_sub_minute) > 0) {
                    var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes) / Number(telephone_sub_minute));
                    if(extraMinutesSlots==0){
                        extraMinutesSlots = 1;
                    }
                    totalExtraRates = this.getExtraCalc(extraMinutesSlots, convert_comma_to_dot(telephone_sub_rate), Number(convert_comma_to_dot(telephone_sub_rate)));
                    totalRates = Number(convert_comma_to_dot(telephone_fix_rate)) + Number(totalExtraRates);
                } else {
                    var extraMinutesSlots: number = 1;
                    totalExtraRates = Number(convert_comma_to_dot(telephone_sub_rate));
                    totalRates = Number(convert_comma_to_dot(telephone_fix_rate)) + Number(convert_comma_to_dot(telephone_sub_rate));
                }
            } else {
                totalRates = Number(convert_comma_to_dot(telephone_fix_rate));
            }



            if (extra_per) {
                var before_extra_rate = totalRates;
                totalRates = totalRates * ((100 + Number(convert_comma_to_dot(extra_per))) / 100);
                var extraPerRate = totalRates - before_extra_rate;

            }
        } else if (ordersData.translation_type_id == 2) {
            var calcRemainKm = ordersData.total_distance - (person.translator.min_km * 2);
            var calcPerKmRate = 0;
            var old_rate: any = 0;
            var physical_fix_rate: any = 0;
            var physical_fix_minute: any = 0;
            var old_sub_rate: any = 0;
            var physical_sub_rate: any = 0;
            var physical_sub_minute: any = 0;
            var min_km: any = 0;
            var old_per_km_rate: any = 0;
            var per_km_rate: any = 0;
            var old_transport_fee_fix_rate: any = 0;
            var transport_fee_minute: any = 0;
            var old_transport_fee_sub_rate: any = 0;
            var transport_fee_sub_minute: any = 0;
            var transport_fee_fix_rate: any = 0;
            var transport_fee_sub_rate: any = 0;
            var transport_fee_agreed: any = 0;


            if (calcRemainKm > 0 && person.translator.compensation_agreed == 1) {
                calcPerKmRate = calcRemainKm * Number(person.translator.per_km_rate);
            }

            old_rate = convert_dot_to_comma(person.translator.physical_fix_rate);
            physical_fix_rate = convert_dot_to_comma(person.translator.physical_fix_rate);
            physical_fix_minute = person.translator.physical_fix_minute;
            old_sub_rate = convert_dot_to_comma(person.translator.physical_sub_rate);
            physical_sub_rate = convert_dot_to_comma(person.translator.physical_sub_rate);
            physical_sub_minute = person.translator.physical_sub_minute;
            min_km = person.translator.min_km;
            old_per_km_rate = convert_dot_to_comma(person.translator.per_km_rate);
            per_km_rate = convert_dot_to_comma(person.translator.per_km_rate);
            old_transport_fee_fix_rate = convert_dot_to_comma(person.translator.transport_fee_fix_rate);
            transport_fee_minute = person.translator.transport_fee_minute;
            old_transport_fee_sub_rate = convert_dot_to_comma(person.translator.transport_fee_sub_rate);
            transport_fee_sub_minute = person.translator.transport_fee_sub_minute;
            transport_fee_fix_rate = convert_dot_to_comma(person.translator.transport_fee_fix_rate);
            transport_fee_sub_rate = convert_dot_to_comma(person.translator.transport_fee_sub_rate);
            transport_fee_agreed = person.translator.transport_fee_agreed;

            var totalTravelRates: number = 0;
            var totalTravelExtraRates: number = 0;
            if (transport_fee_agreed == 1) {
                if (ordersData.travel_time > transport_fee_minute) {
                    var calcTravelExtraMinutes = ordersData.travel_time - (transport_fee_minute * 2);
                    if (calcTravelExtraMinutes >= transport_fee_sub_minute && Number(transport_fee_sub_minute) > 0) {
                        var extraTransportMinutesSlots: number = Math.ceil(Number(calcTravelExtraMinutes) / Number(transport_fee_sub_minute));
                        if(extraTransportMinutesSlots==0){
                            extraTransportMinutesSlots = 1;
                        }
                        totalTravelExtraRates = this.getExtraCalc(extraTransportMinutesSlots, convert_comma_to_dot(transport_fee_sub_rate), Number(convert_comma_to_dot(transport_fee_sub_rate)));

                        totalTravelRates = (Number(convert_comma_to_dot(transport_fee_fix_rate)) * 2) + Number(totalTravelExtraRates);
                    } else {
                        var extraTransportMinutesSlots: number = 1;
                        totalTravelExtraRates = Number(convert_comma_to_dot(transport_fee_sub_rate));
                        totalTravelRates = (Number(convert_comma_to_dot(transport_fee_fix_rate)) * 2) + Number(convert_comma_to_dot(transport_fee_sub_rate));
                    }
                } else {
                    totalTravelRates = (Number(convert_comma_to_dot(transport_fee_fix_rate)) * 2);
                }
            }
            var totalDiff = 0;
            var minuteDiff = 0;
            if (ordersData.complete_order_to && ordersData.status==5) {
                var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.complete_order_to).getTime();
            } else {
                var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.order_to).getTime();
            }
            var timeStart = new Date(ordersData.order_date + ' ' + ordersData.order_from).getTime();

            totalDiff = timeEnd - timeStart;
            minuteDiff = Math.ceil(totalDiff / 60000);

            var totalRates: number = 0;
            var totalExtraRates: number = 0;
            if (minuteDiff > physical_fix_minute) {
                var calcExtraMinutes = minuteDiff - physical_fix_minute;
                if (calcExtraMinutes >= physical_sub_minute && Number(physical_sub_minute) > 0) {
                    var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes) / Number(physical_sub_minute));
                    if(extraMinutesSlots==0){
                        extraMinutesSlots = 1;
                    }
                    totalExtraRates = this.getExtraCalc(extraMinutesSlots, convert_comma_to_dot(physical_sub_rate), Number(convert_comma_to_dot(physical_sub_rate)));
                    totalRates = Number(convert_comma_to_dot(physical_fix_rate)) + Number(totalExtraRates) + (calcPerKmRate) + (totalTravelRates * 2);
                } else {
                    var extraMinutesSlots: number = 1;
                    totalExtraRates = Number(convert_comma_to_dot(physical_sub_rate));
                    totalRates = Number(convert_comma_to_dot(physical_fix_rate)) + Number(convert_comma_to_dot(physical_sub_rate)) + (calcPerKmRate) + (totalTravelRates * 2);
                }
            } else {
                totalRates = Number(convert_comma_to_dot(physical_fix_rate)) + (calcPerKmRate) + (totalTravelRates * 2);
            }

            if (extra_per) {
                var before_extra_rate = totalRates;
                totalRates = totalRates * ((100 + Number(convert_comma_to_dot(extra_per))) / 100);
                var extraPerRate = totalRates - before_extra_rate;

            }
        } else if (ordersData.translation_type_id == 3) {
            var old_rate: any = 0;
            var video_fix_rate: any = 0;
            var video_fix_minute: any = 0;
            var old_sub_rate: any = 0;
            var video_sub_rate: any = 0;
            var video_sub_minute: any = 0;


            old_rate = convert_dot_to_comma(person.translator.video_fix_rate);
            video_fix_rate = convert_dot_to_comma(person.translator.video_fix_rate);
            video_fix_minute = person.translator.video_fix_minute;
            old_sub_rate = convert_dot_to_comma(person.translator.video_sub_rate);
            video_sub_rate = convert_dot_to_comma(person.translator.video_sub_rate);
            video_sub_minute = person.translator.video_sub_minute;


            var totalDiff = 0;
            var minuteDiff = 0;
            if (ordersData.complete_order_to && ordersData.status==5) {
                var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.complete_order_to).getTime();
            } else {
                var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.order_to).getTime();
            }
            var timeStart = new Date(ordersData.order_date + ' ' + ordersData.order_from).getTime();

            totalDiff = timeEnd - timeStart;
            minuteDiff = Math.ceil(totalDiff / 60000);


            var totalRates: number = 0;
            var totalExtraRates: number = 0;
            if (minuteDiff > video_fix_minute) {
                var calcExtraMinutes = minuteDiff - video_fix_minute;
                if (calcExtraMinutes >= video_sub_minute && Number(video_sub_minute) > 0) {
                    var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes) / Number(video_sub_minute));
                    if(extraMinutesSlots==0){
                        extraMinutesSlots = 1;
                    }
                    totalExtraRates = this.getExtraCalc(extraMinutesSlots, convert_comma_to_dot(video_sub_rate), Number(convert_comma_to_dot(video_sub_rate)));
                    totalRates = Number(convert_comma_to_dot(video_fix_rate)) + Number(totalExtraRates);
                } else {
                    var extraMinutesSlots: number = 1;
                    totalExtraRates = Number(convert_comma_to_dot(video_sub_rate));
                    totalRates = Number(convert_comma_to_dot(video_fix_rate)) + Number(convert_comma_to_dot(video_sub_rate));
                }
            } else {
                totalRates = Number(convert_comma_to_dot(video_fix_rate));
            }

            if (extra_per) {
                var before_extra_rate = totalRates;
                totalRates = totalRates * ((100 + Number(convert_comma_to_dot(extra_per))) / 100);
                var extraPerRate = totalRates - before_extra_rate;

            }
        } else if (ordersData.translation_type_id == 5) {
            var old_rate: any = 0;
            var tel_message_fix_rate: any = 0;
            var telephone_sub_rate: any = 0;
            var total_tel_message_fix_rate: any = 0;


            old_rate = convert_dot_to_comma(person.translator.tel_message_fix_rate);
            tel_message_fix_rate = convert_dot_to_comma(person.translator.tel_message_fix_rate);
            total_tel_message_fix_rate = Number(convert_comma_to_dot(person.translator.tel_message_fix_rate));

            var totalRates: number = total_tel_message_fix_rate;
        } else if (ordersData.translation_type_id == 6) {
            var old_rate: any = 0;
            var per_word_rate: any = 0;
            var min_word: any = 0;
            var totalRates: number

            old_rate = convert_dot_to_comma(person.translator.per_word_rate);
            per_word_rate = convert_dot_to_comma(person.translator.per_word_rate);
            min_word = person.translator.min_word ? person.translator.min_word : 0;

            if (ordersData.total_words) {
                if (Number(ordersData.total_words) >= min_word) {
                    totalRates = Number(ordersData.total_words) * Number(convert_comma_to_dot(per_word_rate));
                }
            }
        }
        var orderOldRate:any = (ordersData.old_transport_fee_fix_rate) ? ordersData.old_transport_fee_fix_rate : '0,00';
        var orderOldSubRate:any = (ordersData.old_transport_fee_sub_rate) ? ordersData.old_transport_fee_sub_rate : '0,00';
        if(ordersData.translation_type_id==2){
            console.log('specialCompanyRatesData', person.translator.min_km);
            console.log('Holiday Agreed', person.translator.holiday_charge_agreed);
            var calcPerKmRate = 0;
            var totalTravelExtraRates = 0;
            var totalTravelRates = 0;
            var minKm:any;
            
            if(person.translator.min_km==null){
                minKm = 0;
                console.log(1);
            }else{
                minKm = person.translator.min_km;
                console.log(2);
            }
            var calcRemainKm = ordersData.total_distance - (minKm * 2);
            if(calcRemainKm > 0 && person.translator.compensation_agreed==1){
                calcPerKmRate = convert_to_decimal_format(calcRemainKm * Number(person.translator.per_km_rate)).toFixed(2);
                console.log('calcPerKmRate',calcPerKmRate);                
            }


            if(person.translator.transport_fee_agreed==1){
                if(ordersData.travel_time > person.translator.transport_fee_minute) {
                    var calcTravelExtraMinutes = ordersData.travel_time - (person.translator.transport_fee_minute * 2);
                    if(calcTravelExtraMinutes >= person.translator.transport_fee_sub_minute && Number(person.translator.transport_fee_sub_minute) > 0){
                    var extraTransportMinutesSlots: number = Math.ceil(Number(calcTravelExtraMinutes)/Number(person.translator.transport_fee_sub_minute));
                    if(extraTransportMinutesSlots==0){
                        extraTransportMinutesSlots = 1;
                    }
                    totalTravelExtraRates = this.getExtraCalc(extraTransportMinutesSlots, convert_comma_to_dot(person.translator.transport_fee_sub_rate), Number(convert_comma_to_dot(person.translator.transport_fee_sub_rate)));
                    totalTravelRates = (Number(convert_comma_to_dot(person.translator.transport_fee_fix_rate)) * 2) + Number(totalTravelExtraRates); 
                  } else {
                    var extraTransportMinutesSlots: number = 1;
                    totalTravelExtraRates = Number(convert_comma_to_dot(person.translator.transport_fee_sub_rate));
                    totalTravelRates = (Number(convert_comma_to_dot(person.translator.transport_fee_fix_rate)) * 2) + Number(person.translator.transport_fee_sub_rate);  
                  }
                } else {
                    totalTravelRates = (Number(convert_comma_to_dot(person.translator.special_transport_fee_fix_rate)) * 2);
                }
            }
        }
        return (
            <React.Fragment>
                {ordersData.translation_type_id==1 && person.translator &&
                    <React.Fragment>
                    <table className="table table-bordered">
                     <tbody>
                      <tr>
                        <th>Type</th>
                        <th>Antal</th>
                        <th>Sats</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td>Telefon-tolkning, Påbegyndt {person.translator.telephone_fix_minute} min.</td>
                        <td>1</td>
                        <td>{convert_to_decimal_format(person.translator.telephone_fix_rate)} kr</td>
                        <td>{convert_to_decimal_format(person.translator.telephone_fix_rate)} kr</td>
                      </tr>
                      {extraMinutesSlots > 0 &&
                          <tr>
                            <td>Telefon-tolkning, Efterfølgende {person.translator.telephone_sub_minute} min.</td>
                            <td>{(extraMinutesSlots) ? extraMinutesSlots : '0'}</td>
                            <td>{convert_to_decimal_format(person.translator.telephone_sub_rate)} kr</td>
                            <td>{(totalExtraRates)?convert_to_decimal_format(String(totalExtraRates)):'0,00'} kr</td>
                          </tr>
                      }
                      { person.translator.holiday_charge_agreed==1 && ordersData.extra_percentage_rate &&
                        <tr>
                            <td>Er der aftalt aftens tillæg, weeekend (lørdag & søndag) og helligdage (Kl: 17:01-Kl: 07:59 )?</td>
                            <td>{convert_to_decimal_format(person.translator.extra_holiday_percentage)}</td>
                            <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00'}</td>
                            <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00'}</td>
                        </tr>
                      }
                     </tbody>
                    </table>
                    </React.Fragment>
                }
                {ordersData.translation_type_id==2 && person.translator &&
                    <React.Fragment>
                    <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Type</th>
                        <th>Antal</th>
                        <th>Sats</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td>Fremmøde-tolkning, Påbegyndt {person.translator.physical_fix_minute} min.</td>
                        <td>1</td>
                        <td>{convert_to_decimal_format(String(person.translator.physical_fix_rate))} kr</td>
                        <td>{convert_to_decimal_format(String(person.translator.physical_fix_rate))} kr</td>
                      </tr>
                      {extraMinutesSlots > 0 &&
                          <tr>
                            <td>Fremmøde-tolkning, Efterfølgende {person.translator.physical_sub_minute} min.</td>
                            <td>{(extraMinutesSlots) ? extraMinutesSlots : '0'}</td>
                            <td>{convert_to_decimal_format(String(person.translator.physical_sub_rate))} kr</td>
                            <td>{(totalExtraRates)?convert_to_decimal_format(String(totalExtraRates)):'0,00'} kr</td>
                          </tr>
                      }
                      { person.translator.holiday_charge_agreed==1 && ordersData.extra_percentage_rate &&
                        <tr>
                            <td>Er der aftalt aftens tillæg, weeekend (lørdag & søndag) og helligdage (Kl: 17:01-Kl: 07:59 )?</td>
                            <td>{convert_to_decimal_format(person.translator.extra_holiday_percentage)}</td>
                            <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00'}</td>
                            <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00'}</td> 
                        </tr>
                      }
                       {person.translator.compensation_agreed == 1 && ordersData.total_distance && person.translator.min_km && calcRemainKm > 0 &&
                          <tr>
                            <td>KM</td>
                            <td>[{convert_dot_to_comma(ordersData.total_distance)} - {convert_to_decimal_format(Number(person.translator.min_km * 2))}]= {convert_to_decimal_format(calcRemainKm)}</td>
                            <td>{convert_to_decimal_format(String(person.translator.per_km_rate))} kr</td>
                            <td>{convert_to_decimal_format(String(convert_comma_to_dot(person.translator.per_km_rate) * calcRemainKm))} kr</td>
                           </tr>
                        }
                        {person.translator.transport_fee_agreed == 1 && person.translator.transport_fee_fix_rate &&
                          <tr>
                            <td>Påbegyndt: {person.translator.transport_fee_fix_rate} for {person.translator.transport_fee_minute} minutter (Both way)</td>
                            <td>2</td>
                            <td>{convert_to_decimal_format(String(person.translator.transport_fee_fix_rate))} kr</td>
                            <td>{convert_to_decimal_format(String(Number(person.translator.transport_fee_fix_rate) * 2))} kr</td>
                          </tr>
                        }
                        {person.translator.transport_fee_agreed == 1 && person.translator.transport_fee_sub_rate && extraTransportMinutesSlots > 0 &&
                          <tr>
                            <td>Efterfølgende {person.translator.transport_fee_sub_rate} for {person.translator.transport_fee_sub_minute} minutter (Both way)</td>
                            <td>{extraTransportMinutesSlots}</td>
                            <td>{convert_to_decimal_format(String(totalTravelExtraRates))} kr</td>
                            <td>{convert_to_decimal_format(String(totalTravelExtraRates))} kr</td>
                          </tr>
                        }
                     </tbody>
                    </table>
                    </React.Fragment>
                }
                {ordersData.translation_type_id==3 && person.translator &&
                    <React.Fragment>
                    <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Type</th>
                        <th>Antal</th>
                        <th>Sats</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td>Video-tolkning, Påbegyndt {person.translator.video_fix_minute} min.</td>
                        <td>1</td>
                        <td>{person.translator.video_fix_rate} kr </td>
                        <td>{person.translator.video_fix_rate} kr</td>
                      </tr>
                      {extraMinutesSlots > 0 &&
                          <tr>
                            <td>Video-tolkning, Efterfølgende {person.translator.video_sub_minute} min.</td>
                            <td>{(extraMinutesSlots) ? extraMinutesSlots : '0'}</td>
                            <td>{person.translator.video_sub_rate} kr </td>
                            <td>{(totalExtraRates)?convert_to_decimal_format(String(totalExtraRates)):'0,00'} kr</td>
                          </tr>
                      }
                      { person.translator.holiday_charge_agreed==1 && ordersData.extra_percentage_rate &&
                        <tr>
                            <td>Er der aftalt aftens tillæg, weeekend (lørdag & søndag) og helligdage (Kl: 17:01-Kl: 07:59 )?</td>
                            <td>{convert_to_decimal_format(person.translator.extra_holiday_percentage)}</td>
                            <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td>
                            <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td> 
                        </tr>
                      }
                     </tbody>
                    </table>
                    </React.Fragment>
                }
                {ordersData.translation_type_id==5 && person.translator &&
                    <React.Fragment>
                     <table className="table table-bordered">
                     <tbody>
                      <tr>
                        <th>Type</th>
                        <th>Antal</th>
                        <th>Sats</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td>Telefonbesked {person.translator.tel_message_fix_rate}.</td>
                        <td>-</td>
                        <td>{convert_to_decimal_format(String(ordersData.rate))} kr</td>
                        <td>{convert_to_decimal_format(String(ordersData.rate))} kr</td>
                      </tr>
                     </tbody>
                    </table>
                    </React.Fragment>
                }
                {ordersData.translation_type_id==6 && person.translator &&
                    <React.Fragment>
                    <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Type</th>
                        <th>Antal</th>
                        <th>Sats</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td>Skriftlig Oversættelse, Min words {person.translator.min_word}.</td>
                        <td>{ordersData.total_word} X {person.translator.per_word_rate}</td>
                        <td>{convert_to_decimal_format(ordersData.rate)} kr </td>
                        <td>{convert_to_decimal_format(ordersData.rate)} kr</td>
                      </tr>
                     </tbody>
                    </table>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }

    checkFixPriceCalculations = (ordersData:any, person:any) => {
        console.log('Fix-Price');
        console.log('person', person.translator);
        var extra_per:any=0;
        if (ordersData.translation_type_id == 1) {
            var old_rate: any = 0;
            var telephone_fix_rate: any = 0;
            var telephone_fix_minute: any = 0;
            var old_sub_rate: any = 0;
            var telephone_sub_rate: any = 0;
            var telephone_sub_minute: any = 0;


            old_rate = convert_dot_to_comma(ordersData.fix_price.telephone_fix_rate);
            telephone_fix_rate = convert_dot_to_comma(ordersData.fix_price.telephone_fix_rate);
            telephone_fix_minute = ordersData.fix_price.telephone_fix_minute;
            old_sub_rate = convert_dot_to_comma(ordersData.fix_price.telephone_sub_rate);
            telephone_sub_rate = convert_dot_to_comma(ordersData.fix_price.telephone_sub_rate);
            telephone_sub_minute = ordersData.fix_price.telephone_sub_minute;


            var totalDiff = 0;
            var minuteDiff = 0;
            if (ordersData.complete_order_to && ordersData.status==5) {
                var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.complete_order_to).getTime();
            } else {
                var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.order_to).getTime();
            }
            var timeStart = new Date(ordersData.order_date + ' ' + ordersData.order_from).getTime();
            totalDiff = timeEnd - timeStart;
            minuteDiff = Math.ceil(totalDiff / 60000);


            var totalRates: number = 0;
            var totalExtraRates: number = 0;
            if (minuteDiff > telephone_fix_minute) {
                var calcExtraMinutes = minuteDiff - telephone_fix_minute;
                if (calcExtraMinutes >= telephone_sub_minute && Number(telephone_sub_minute) > 0) {
                    var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes) / Number(telephone_sub_minute));
                    if(extraMinutesSlots==0){
                        extraMinutesSlots = 1;
                    }
                    totalExtraRates = this.getExtraCalc(extraMinutesSlots, convert_comma_to_dot(telephone_sub_rate), Number(convert_comma_to_dot(telephone_sub_rate)));
                    totalRates = Number(convert_comma_to_dot(telephone_fix_rate)) + Number(totalExtraRates);
                } else {
                    var extraMinutesSlots: number = 1;
                    totalExtraRates = Number(convert_comma_to_dot(telephone_sub_rate));
                    totalRates = Number(convert_comma_to_dot(telephone_fix_rate)) + Number(convert_comma_to_dot(telephone_sub_rate));
                }
            } else {
                totalRates = Number(convert_comma_to_dot(telephone_fix_rate));
            }

            if (extra_per) {
                var before_extra_rate = totalRates;
                totalRates = totalRates * ((100 + Number(convert_comma_to_dot(extra_per))) / 100);
                var extraPerRate = totalRates - before_extra_rate;

            }
        } else if (ordersData.translation_type_id == 2) {
            var calcRemainKm = ordersData.total_distance - (ordersData.fix_price.min_km * 2);
            var calcPerKmRate = 0;
            var old_rate: any = 0;
            var physical_fix_rate: any = 0;
            var physical_fix_minute: any = 0;
            var old_sub_rate: any = 0;
            var physical_sub_rate: any = 0;
            var physical_sub_minute: any = 0;
            var min_km: any = 0;
            var old_per_km_rate: any = 0;
            var per_km_rate: any = 0;
            var old_transport_fee_fix_rate: any = 0;
            var transport_fee_minute: any = 0;
            var old_transport_fee_sub_rate: any = 0;
            var transport_fee_sub_minute: any = 0;
            var transport_fee_fix_rate: any = 0;
            var transport_fee_sub_rate: any = 0;
            var transport_fee_agreed: any = 0;


            if (calcRemainKm > 0 && ordersData.fix_price.compensation_agreed == 1) {
                calcPerKmRate = calcRemainKm * Number(ordersData.fix_price.per_km_rate);
            }

            old_rate = convert_dot_to_comma(ordersData.fix_price.physical_fix_rate);
            physical_fix_rate = convert_dot_to_comma(ordersData.fix_price.physical_fix_rate);
            physical_fix_minute = ordersData.fix_price.physical_fix_minute;
            old_sub_rate = convert_dot_to_comma(ordersData.fix_price.physical_sub_rate);
            physical_sub_rate = convert_dot_to_comma(ordersData.fix_price.physical_sub_rate);
            physical_sub_minute = ordersData.fix_price.physical_sub_minute;
            min_km = ordersData.fix_price.min_km;
            old_per_km_rate = convert_dot_to_comma(ordersData.fix_price.per_km_rate);
            per_km_rate = convert_dot_to_comma(ordersData.fix_price.per_km_rate);
            old_transport_fee_fix_rate = convert_dot_to_comma(ordersData.fix_price.transport_fee_fix_rate);
            transport_fee_minute = ordersData.fix_price.transport_fee_minute;
            old_transport_fee_sub_rate = convert_dot_to_comma(ordersData.fix_price.transport_fee_sub_rate);
            transport_fee_sub_minute = ordersData.fix_price.transport_fee_sub_minute;
            transport_fee_fix_rate = convert_dot_to_comma(ordersData.fix_price.transport_fee_fix_rate);
            transport_fee_sub_rate = convert_dot_to_comma(ordersData.fix_price.transport_fee_sub_rate);
            transport_fee_agreed = ordersData.fix_price.transport_fee_agreed;

            var totalTravelRates: number = 0;
            var totalTravelExtraRates: number = 0;
            if (transport_fee_agreed == 1) {
                if (ordersData.travel_time > transport_fee_minute) {
                    var calcTravelExtraMinutes = ordersData.travel_time - (transport_fee_minute * 2);
                    if (calcTravelExtraMinutes >= transport_fee_sub_minute && Number(transport_fee_sub_minute) > 0) {
                        var extraTransportMinutesSlots: number = Math.ceil(Number(calcTravelExtraMinutes) / Number(transport_fee_sub_minute));
                        if(extraTransportMinutesSlots==0){
                            extraTransportMinutesSlots = 1;
                        }
                        totalTravelExtraRates = this.getExtraCalc(extraTransportMinutesSlots, convert_comma_to_dot(transport_fee_sub_rate), Number(convert_comma_to_dot(transport_fee_sub_rate)));

                        totalTravelRates = (Number(convert_comma_to_dot(transport_fee_fix_rate)) * 2) + Number(totalTravelExtraRates);
                    } else {
                        var extraTransportMinutesSlots: number = 1;
                        totalTravelExtraRates = Number(convert_comma_to_dot(transport_fee_sub_rate));
                        totalTravelRates = (Number(convert_comma_to_dot(transport_fee_fix_rate)) * 2) + Number(transport_fee_sub_rate);
                    }
                } else {
                    totalTravelRates = (Number(convert_comma_to_dot(transport_fee_fix_rate)) * 2);
                }
            }
            var totalDiff = 0;
            var minuteDiff = 0;
            if (ordersData.complete_order_to && ordersData.status==5) {
                var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.complete_order_to).getTime();
            } else {
                var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.order_to).getTime();
            }
            var timeStart = new Date(ordersData.order_date + ' ' + ordersData.order_from).getTime();

            totalDiff = timeEnd - timeStart;
            minuteDiff = Math.ceil(totalDiff / 60000);

            var totalRates: number = 0;
            var totalExtraRates: number = 0;
            if (minuteDiff > physical_fix_minute) {
                var calcExtraMinutes = minuteDiff - physical_fix_minute;
                if (calcExtraMinutes >= physical_sub_minute && Number(physical_sub_minute) > 0) {
                    var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes) / Number(physical_sub_minute));
                    if(extraMinutesSlots==0){
                        extraMinutesSlots = 1;
                    }
                    totalExtraRates = this.getExtraCalc(extraMinutesSlots, convert_comma_to_dot(physical_sub_rate), Number(convert_comma_to_dot(physical_sub_rate)));
                    totalRates = Number(convert_comma_to_dot(physical_fix_rate)) + Number(totalExtraRates) + (calcPerKmRate) + (totalTravelRates * 2);
                } else {
                    var extraMinutesSlots: number = 1;
                    totalExtraRates = Number(convert_comma_to_dot(physical_sub_rate));
                    totalRates = Number(convert_comma_to_dot(physical_fix_rate)) + Number(convert_comma_to_dot(physical_sub_rate)) + (calcPerKmRate) + (totalTravelRates * 2);
                }
            } else {
                totalRates = Number(convert_comma_to_dot(physical_fix_rate)) + (calcPerKmRate) + (totalTravelRates * 2);
            }

            if (extra_per) {
                var before_extra_rate = totalRates;
                totalRates = totalRates * ((100 + Number(convert_comma_to_dot(extra_per))) / 100);
                var extraPerRate = totalRates - before_extra_rate;

            }
        } else if (ordersData.translation_type_id == 3) {
            var old_rate: any = 0;
            var video_fix_rate: any = 0;
            var video_fix_minute: any = 0;
            var old_sub_rate: any = 0;
            var video_sub_rate: any = 0;
            var video_sub_minute: any = 0;


            old_rate = convert_dot_to_comma(ordersData.fix_price.video_fix_rate);
            video_fix_rate = convert_dot_to_comma(ordersData.fix_price.video_fix_rate);
            video_fix_minute = ordersData.fix_price.video_fix_minute;
            old_sub_rate = convert_dot_to_comma(ordersData.fix_price.video_sub_rate);
            video_sub_rate = convert_dot_to_comma(ordersData.fix_price.video_sub_rate);
            video_sub_minute = ordersData.fix_price.video_sub_minute;


            var totalDiff = 0;
            var minuteDiff = 0;
            if (ordersData.complete_order_to && ordersData.status==5) {
                var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.complete_order_to).getTime();
            } else {
                var timeEnd = new Date(ordersData.order_date + ' ' + ordersData.order_to).getTime();
            }
            var timeStart = new Date(ordersData.order_date + ' ' + ordersData.order_from).getTime();

            totalDiff = timeEnd - timeStart;
            minuteDiff = Math.ceil(totalDiff / 60000);


            var totalRates: number = 0;
            var totalExtraRates: number = 0;
            if (minuteDiff > video_fix_minute) {
                var calcExtraMinutes = minuteDiff - video_fix_minute;
                if (calcExtraMinutes >= video_sub_minute && Number(video_sub_minute) > 0) {
                    var extraMinutesSlots: number = Math.ceil(Number(calcExtraMinutes) / Number(video_sub_minute));
                    if(extraMinutesSlots==0){
                        extraMinutesSlots = 1;
                    }
                    totalExtraRates = this.getExtraCalc(extraMinutesSlots, convert_comma_to_dot(video_sub_rate), Number(convert_comma_to_dot(video_sub_rate)));
                    totalRates = Number(convert_comma_to_dot(video_fix_rate)) + Number(totalExtraRates);
                } else {
                    var extraMinutesSlots: number = 1;
                    totalExtraRates = Number(convert_comma_to_dot(video_sub_rate));
                    totalRates = Number(convert_comma_to_dot(video_fix_rate)) + Number(convert_comma_to_dot(video_sub_rate));
                }
            } else {
                totalRates = Number(convert_comma_to_dot(video_fix_rate));
            }

            if (extra_per) {
                var before_extra_rate = totalRates;
                totalRates = totalRates * ((100 + Number(convert_comma_to_dot(extra_per))) / 100);
                var extraPerRate = totalRates - before_extra_rate;

            }
        } else if (ordersData.translation_type_id == 5) {
            var old_rate: any = 0;
            var tel_message_fix_rate: any = 0;
            var telephone_sub_rate: any = 0;
            var total_tel_message_fix_rate: any = 0;


            old_rate = convert_dot_to_comma(ordersData.fix_price.tel_message_fix_rate);
            tel_message_fix_rate = convert_dot_to_comma(ordersData.fix_price.tel_message_fix_rate);
            total_tel_message_fix_rate = Number(convert_comma_to_dot(ordersData.fix_price.tel_message_fix_rate));

            var totalRates: number = total_tel_message_fix_rate;
        } else if (ordersData.translation_type_id == 6) {
            var old_rate: any = 0;
            var per_word_rate: any = 0;
            var min_word: any = 0;
            var totalRates: number

            old_rate = convert_dot_to_comma(ordersData.fix_price.per_word_rate);
            per_word_rate = convert_dot_to_comma(ordersData.fix_price.per_word_rate);
            min_word = ordersData.fix_price.min_word ? ordersData.fix_price.min_word : 0;

            if (ordersData.total_words) {
                if (Number(ordersData.total_words) >= min_word) {
                    totalRates = Number(ordersData.total_words) * Number(convert_comma_to_dot(per_word_rate));
                }
            }
        }
        var orderOldRate:any = (ordersData.old_transport_fee_fix_rate) ? ordersData.old_transport_fee_fix_rate : '0,00';
        var orderOldSubRate:any = (ordersData.old_transport_fee_sub_rate) ? ordersData.old_transport_fee_sub_rate : '0,00';
        if(ordersData.translation_type_id==2){
            console.log('specialCompanyRatesData', ordersData.fix_price.min_km);
            var calcPerKmRate = 0;
            var totalTravelExtraRates = 0;
            var totalTravelRates = 0;
            var minKm:any;
            
            if(ordersData.fix_price.min_km==null){
                minKm = 0;
                console.log(1);
            }else{
                minKm = ordersData.fix_price.min_km;
                console.log(2);
            }
            console.log('total_distance', ordersData.total_distance);
            console.log('min_km', minKm);
            var calcRemainKm = ordersData.total_distance - (minKm * 2);
            if(calcRemainKm > 0 && ordersData.fix_price.compensation_agreed==1){
                calcPerKmRate = convert_to_decimal_format(calcRemainKm * Number(ordersData.fix_price.per_km_rate)).toFixed(2);
                console.log('calcPerKmRate',calcPerKmRate);                
            }
            if(ordersData.fix_price.transport_fee_agreed==1){
                if(ordersData.travel_time > ordersData.fix_price.transport_fee_minute) {
                    var calcTravelExtraMinutes = ordersData.travel_time - (ordersData.fix_price.transport_fee_minute * 2);
                    if(calcTravelExtraMinutes >= ordersData.fix_price.transport_fee_sub_minute && Number(ordersData.fix_price.transport_fee_sub_minute) > 0){
                    var extraTransportMinutesSlots: number = Math.ceil(Number(calcTravelExtraMinutes)/Number(ordersData.fix_price.transport_fee_sub_minute));
                    if(extraTransportMinutesSlots==0){
                        extraTransportMinutesSlots = 1;
                    }
                    totalTravelExtraRates = this.getExtraCalc(extraTransportMinutesSlots, convert_comma_to_dot(ordersData.fix_price.transport_fee_sub_rate), Number(convert_comma_to_dot(ordersData.fix_price.transport_fee_sub_rate)));
                    totalTravelRates = Number(convert_comma_to_dot(ordersData.fix_price.transport_fee_fix_rate)) + Number(totalTravelExtraRates); 
                  } else {
                    totalTravelRates = Number(convert_comma_to_dot(ordersData.fix_price.transport_fee_fix_rate)) + Number(ordersData.fix_price.transport_fee_sub_rate);  
                  }
                } else {
                    totalTravelRates = Number(convert_comma_to_dot(ordersData.fix_price.special_transport_fee_fix_rate));
                }

                console.log('totalTravelRates',totalTravelRates);
            }
        }

        return (
            <React.Fragment>
                {ordersData.translation_type_id==1 && person.translator &&
                    <React.Fragment>
                    <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Type</th>
                        <th>Antal</th>
                        <th>Sats</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td>Telefon-tolkning, Påbegyndt {ordersData.fix_price.telephone_fix_minute} min.</td>
                        <td>1</td>
                        <td>{convert_to_decimal_format(String(ordersData.fix_price.telephone_fix_rate))} kr</td>
                        <td>{convert_to_decimal_format(String(ordersData.fix_price.telephone_fix_rate))} kr</td>
                      </tr>
                      {extraMinutesSlots > 0 &&
                          <tr>
                            <td>Telefon-tolkning, Efterfølgende {ordersData.fix_price.telephone_sub_minute} min.</td>
                            <td>{(extraMinutesSlots) ? extraMinutesSlots : '0'}</td>
                            <td>{ordersData.fix_price.telephone_sub_rate} kr</td>
                            <td>{(totalExtraRates)?convert_to_decimal_format(String(totalExtraRates)):'0,00'} kr</td>
                          </tr>
                      }
                      { person.translator.holiday_charge_agreed==1 && ordersData.extra_percentage_rate &&
                        <tr>
                            <td>Er der aftalt aftens tillæg, weeekend (lørdag & søndag) og helligdage (Kl: 17:01-Kl: 07:59 )?</td>
                            <td>{convert_to_decimal_format(person.translator.extra_holiday_percentage)}</td>
                            <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td>
                            <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td>
                        </tr>
                      }
                     </tbody>
                    </table>
                    </React.Fragment>
                }
                {ordersData.translation_type_id==2 && ordersData.fix_price &&
                    <React.Fragment>
                    <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Type</th>
                        <th>Antal</th>
                        <th>Sats</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td>Fremmøde-tolkning, Påbegyndt {ordersData.fix_price.physical_fix_minute} min.</td>
                        <td>1</td>
                        <td>{convert_to_decimal_format(String(ordersData.fix_price.physical_fix_rate))} kr</td>
                        <td>{convert_to_decimal_format(String(ordersData.fix_price.physical_fix_rate))} kr</td>
                      </tr>
                      {extraMinutesSlots &&
                          <tr>
                            <td>Fremmøde-tolkning, Efterfølgende {ordersData.fix_price.physical_sub_minute} min.</td>
                            <td>{(extraMinutesSlots) ? extraMinutesSlots : '0'}</td>
                            <td>{ordersData.fix_price.physical_sub_rate} kr</td>
                            <td>{(totalExtraRates)?convert_to_decimal_format(String(totalExtraRates)):'0,00'} kr</td>
                          </tr>
                      }
                      { person.translator.holiday_charge_agreed==1 && ordersData.extra_percentage_rate &&
                       <tr>
                        <td>Er der aftalt aftens tillæg, weeekend (lørdag & søndag) og helligdage (Kl: 17:01-Kl: 07:59 )?</td>
                        <td>[{convert_to_decimal_format(ordersData.fix_price.extra_consider_amt)} X {convert_to_decimal_format(ordersData.fix_price.extra_holiday_percentage)}]/100</td>
                        <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td>
                        <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td> 
                       </tr>
                      }
                      {ordersData.fix_price.compensation_agreed == 1 && ordersData.total_distance && ordersData.fix_price.min_km && calcRemainKm > 0 &&
                          <tr>
                            <td>KM (Both way)</td>    
                            <td>[{convert_dot_to_comma(ordersData.total_distance)} - {convert_to_decimal_format(String(Number(ordersData.fix_price.min_km * 2)))}]= {convert_to_decimal_format(String(calcRemainKm))}</td>
                            <td>{convert_to_decimal_format(ordersData.fix_price.per_km_rate)} kr</td>
                            <td>{convert_to_decimal_format(String(convert_comma_to_dot(ordersData.fix_price.per_km_rate) * calcRemainKm))} kr</td>
                          </tr>
                      }
                      {ordersData.fix_price.transport_fee_agreed == 1 && ordersData.fix_price.transport_fee_fix_rate &&
                          <tr>
                            <td>Påbegyndt: {ordersData.fix_price.transport_fee_fix_rate} for {ordersData.fix_price.transport_fee_minute} minutter (Both way)</td> 
                            <td>2</td>
                            <td>{convert_to_decimal_format(String(ordersData.fix_price.transport_fee_fix_rate))} kr</td>
                            <td>{convert_to_decimal_format(String(convert_comma_to_dot(ordersData.fix_price.transport_fee_fix_rate) * 2))} kr</td>
                          </tr>
                      }
                      {ordersData.fix_price.transport_fee_agreed == 1 && ordersData.fix_price.transport_fee_sub_rate &&
                       <tr>
                        <td>Efterfølgende {ordersData.fix_price.transport_fee_sub_rate} for {ordersData.fix_price.transport_fee_sub_minute} minutter (Both way)</td>
                        <td>{extraTransportMinutesSlots}</td>
                        <td>{convert_to_decimal_format(String(totalTravelExtraRates))} kr</td>
                        <td>{convert_to_decimal_format(String(totalTravelExtraRates))} kr</td>
                       </tr>
                       }
                     </tbody>
                    </table>
                    </React.Fragment>
                }
                {ordersData.translation_type_id==3 && ordersData.fix_price &&
                    <React.Fragment>
                    <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Type</th>
                        <th>Antal</th>
                        <th>Sats</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td>Video-tolkning, Påbegyndt {ordersData.fix_price.video_fix_minute} min.</td>
                        <td>1</td>
                        <td>{convert_to_decimal_format(String(ordersData.fix_price.video_fix_rate))} kr </td>
                        <td>{convert_to_decimal_format(String(ordersData.fix_price.video_fix_rate))} kr</td>
                      </tr>
                      {extraMinutesSlots > 0 &&
                          <tr>
                            <td>Video-tolkning, Efterfølgende {ordersData.fix_price.video_sub_minute} min.</td>
                            <td>{(extraMinutesSlots) ? extraMinutesSlots : '0'}</td>
                            <td>{convert_to_decimal_format(String(ordersData.fix_price.video_sub_rate))} kr </td>
                            <td>{(totalExtraRates)?convert_to_decimal_format(String(totalExtraRates)):'0,00'} kr</td>
                          </tr>
                      }
                      { person.translator.holiday_charge_agreed==1 && ordersData.extra_percentage_rate && 
                          <tr>
                            <td>Er der aftalt aftens tillæg, weeekend (lørdag & søndag) og helligdage (Kl: 17:01-Kl: 07:59 )?</td>
                            <td>{convert_to_decimal_format(String(person.translator.extra_holiday_percentage))}</td>
                            <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td>
                            <td>{(ordersData.extra_percentage_rate) ? convert_to_decimal_format(ordersData.extra_percentage_rate)+ ' kr' : '0,00 kr'}</td> 
                          </tr>
                      }
                     </tbody>
                    </table>
                    </React.Fragment>
                }
                {ordersData.translation_type_id==5 && ordersData.fix_price &&
                    <React.Fragment>
                     <table className="table table-bordered">
                     <tbody>
                      <tr>
                        <th>Type</th>
                        <th>Antal</th>
                        <th>Sats</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td>Telefonbesked {ordersData.fix_price.tel_message_fix_rate}.</td>
                        <td>-</td>
                        <td>{convert_to_decimal_format(String(ordersData.rate))} kr</td>
                        <td>{convert_to_decimal_format(String(ordersData.rate))} kr</td>
                      </tr>
                     </tbody>
                    </table>
                    </React.Fragment>
                }
                {ordersData.translation_type_id==6 && ordersData.fix_price &&
                    <React.Fragment>
                    <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Type</th>
                        <th>Antal</th>
                        <th>Sats</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td>Skriftlig Oversættelse, Min words {ordersData.fix_price.min_word}.</td>
                        <td>{ordersData.total_word} X {ordersData.fix_price.per_word_rate}</td>
                        <td>{convert_to_decimal_format(ordersData.rate)} kr </td>
                        <td>{convert_to_decimal_format(ordersData.rate)} kr</td>
                      </tr>
                     </tbody>
                    </table>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
    
    render() {
        const {payoutData,translators} = this.state;
        return (
            <div>
                <ListingLayout breadcrumb={ListBreadcrumb}>
                    <ListHeader title={'pending_payout_title'} icon={Icons.dollor} >
                    </ListHeader>
                    <PendingFilter getResults={this.filterRecords} translator={translators}/>
                    <div className="container">
                        { (this.state.order_ids && this.state.order_ids.length>0) && 
                           <input type ="button" className={'btn btn-sm btn-info'} onClick={(e) =>this.handleChangeStatus(e)} value={Lang.mark_as_completed}/>
                        }

                        <div id="accordion" className="payout-accordion">
                            {payoutData && payoutData.length > 0 && payoutData.map((person, index) => (
                                <div className="card" key={(index+1)}>
                                    <div className="card-header" id={"headingOne_"+(index+1)}>
                                        <h5 className="mb-0 d-inline">
                                            
                                            <input type='checkbox' className={'check_all_'+index+1}  
                                            onChange={(e) => this.checkAll(e, index+1)}  id={'chk'+index+1} />
                                            <button className="btn" data-toggle="collapse" data-target={"#collapseOne_"+(index+1)} aria-expanded="true" aria-controls={"collapseOne_"+(index+1)}>
                                            {person.name} <span className="total_val_payout"><b> Total : </b> {convert_to_decimal_format(String(person.total_payout))} kr</span>
                                            </button>
                                        </h5>
                                    </div>
                                    <div id={"collapseOne_"+(index+1)} className="collapse payout-accordion-section" aria-labelledby={"headingOne_"+(index+1)} data-parent="#accordion">
                                        <div className="card-body" id={"child"+(index+1)}>
                                        {person.orders && (person.orders.length>0) && person.orders.map((ordersData, orderIndex) => (
                                            <div className="card" key={orderIndex}>
                                                <div className="card-header">
                                                    <input type='checkbox' className={'checkOrder_'+index} name='order_ids[]' value={ordersData.order_id} onChange={(e) => this.check(e, orderIndex, ordersData.order_id)} id={'checkedOrder_'+ordersData.order_id}/>
                                                    <a href="#" data-toggle="collapse" data-target={"#collapseOneA_"+orderIndex}>
                                                    <span className={ ordersData.status === 4 ? 'cancelled-payout-order' : ''}>{ ordersData.status === 4 &&
                                                        <>
                                                      <img src="/admin/assets/files/payout-cancelled.png" /> 
                                                        </>
                                                    }{ordersData.sel_type}</span>
                                                    {ordersData.translation_type_id==1 &&
                                                        <span>Telefon-tolkning</span>
                                                    }
                                                    {ordersData.translation_type_id==2 &&
                                                        <span>Fremmøde-tolkning</span>
                                                    }
                                                    {ordersData.translation_type_id==3 &&
                                                        <span>Video-tolkning</span>
                                                    }
                                                    {ordersData.translation_type_id==5 &&
                                                        <span>Telefonbesked Tolkning</span>
                                                    }
                                                    {ordersData.translation_type_id==6 &&
                                                        <span>Skriftlig Oversættelse</span>
                                                    }
                                                    <span>{ordersData.order_no}</span>
                                                    <span>{ordersData.company_name}</span>
                                                    <span>{ordersData.order_date}</span>
                                                    <span>{ordersData.order_from}-{ordersData.order_to}</span>
                                                    <span className="total_val_payout">{convert_to_decimal_format(String(ordersData.amount))} kr</span></a>
                                                </div>
                                                <div className="card-body collapse" data-parent={"#child"+((index+1))} id={"collapseOneA_"+orderIndex}>

                                                {ordersData.sel_type && ordersData.sel_type=='Standard' &&
                                                    <React.Fragment>
                                                        {this.checkStandardCalculations(ordersData, person)}
                                                    </React.Fragment>
                                                }

                                                {ordersData.sel_type && ordersData.sel_type=='Fix-Price' &&
                                                    <React.Fragment>
                                                        {this.checkFixPriceCalculations(ordersData, person)}
                                                    </React.Fragment>
                                                }

                                                {ordersData.sel_type && ordersData.sel_type=='Special' &&
                                                    <React.Fragment>
                                                        {this.checkSpecialCalculations(ordersData, person)}   
                                                    </React.Fragment>
                                                }
                                                </div>
                                            </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {payoutData && payoutData.length==0 && 
                                <NoRecords/>
                            }
                        </div>
                    </div>
                </ListingLayout>
            </div>
        )
    }
}
