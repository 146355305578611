import * as React from 'react';
import {Breadcrumb} from "../../common/page/Breadcrumb";
import Lang from "../../common/lang/Lang";
import {OrderBreadcrumb} from "./Data";
import {RouteComponentProps} from "react-router";
import {ShowRecords} from "../../common/ShowRecords";
import {IState as CommonState} from "../../common/State";
import {TimeCalculation} from "../../settings/customer-calculation/TimeCalculation";

 

export class CustomerCalculation extends React.Component<RouteComponentProps> {
  
   

  render() {
    const {id}: any = this.props.match.params;
    return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
     <Breadcrumb data={OrderBreadcrumb}/>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="row">
          <div className="col-xl-12">
            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">{Lang.customer_calculation}</h3>
                </div>
              </div><br/>
              <TimeCalculation id={id} />
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}