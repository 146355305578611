import * as React from 'react';
import Lang from "../../common/lang/Lang";
import {CreateBreadcrumb} from "./Data";
import {DataSchema, Form, FormDefaultVal, FormFields, RequiredFields} from "./Model";
import API from './../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {showNotification, CommonSubmitButton,SwitchLoader, LoaderType} from "../../common";
import {CompanyValidations} from "./Validations";
import {CreateLayout} from "../../layout/CreateLayout";
import {EditProfileImgPopup} from "./EditProfileImgPopup";
import Cropper from 'cropperjs';
import _ from 'lodash';

interface IState{
  form: DataSchema;
  uploadDocument?: File;
  perviewimage?: string;
}
var cropper:any;
export class CompanyCreate extends React.Component<RouteComponentProps, IState> {
  state: IState;
 
  constructor(props: RouteComponentProps){
    super(props);
    
    this.state = {
      form: FormDefaultVal,
      perviewimage:'',
    }
    CompanyValidations();
  }

  

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target : any = event.target;
    let value = target.value;
    const name = target.name;
    if(name === 'prefilled_fields'){
      let prefilled_fields: Array<string> = this.state.form.prefilled_fields.split(',');
      if(_.indexOf(prefilled_fields, value) > -1 ){
        prefilled_fields = _.pull(prefilled_fields, value);
      } else {
        prefilled_fields = _.concat(prefilled_fields, value);
      }
      prefilled_fields = _.compact(prefilled_fields);
      if(_.indexOf(prefilled_fields, "department_unit_id") <= -1 &&  prefilled_fields.length > 0){
        prefilled_fields.push('department_unit_id');
      }
      value = _.join(prefilled_fields, ',');
    }
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

 

  componentDidMount() {
    let logoupload = this;
    $(document).ready(function () {
      $("#updateProfilePictureOfUser").unbind().click(function(event) {
          event.preventDefault();
          let imgData = cropper.getCroppedCanvas().toDataURL("image/png");
          let formData = new FormData();
          formData.append('logo', imgData);
          $("#updateProfilePictureOfUser").hide();
           API.post('settings/uploadcompanylogo', formData).then((res) => {
             if (res.data[0]) {
               logoupload.setState({
                perviewimage : res.data[0],
                 form: { ...logoupload.state.form, logo: res.data[0]}
                });
                 $("#updateProfilePictureOfUser").show();
                  ($('#editProfileImagePopup') as any).modal('hide');
                  $('#profile_pic').val('');
              } else {
                  $("#updateProfilePictureOfUser").html(Lang.submit);
               }
          })
      });
  });
  }

   uploadProfilePic = async (e:React.ChangeEvent<HTMLElement>, callback?: (doc:string)=> void ) => {
    const target:any = e.target;

    let fileData:any = target.files[0];
    let reader:any = new FileReader();

    if (fileData.type.startsWith('image/')) {
        ($('#editProfileImagePopup') as any).modal('show');
        $('#uploadedImage').css({opacity:'0'});
        reader.addEventListener("load", function () {
            $('#uploadedImage').attr('src',reader.result);
            setTimeout(function () {
                $('#uploadedImage').css({opacity:'1'});
                const image:any = document.getElementById('uploadedImage');
                if(!_.isEmpty(cropper)) {
                    cropper.destroy();
                }
                image.src = reader.result;
                    cropper = new Cropper(image, {
                    aspectRatio: 5 / 5,
                    crop(event) {

                    },
                });

            },500);
        }, false);

        if (fileData) {
            reader.readAsDataURL(fileData);
        }
    }
};

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#create_company").valid()) {
      API.post('settings/company', this.state.form).then((res) => {
        showNotification("success", Lang.company_created);
        this.props.history.push(`/settings/company/edit/${res.data.id}/time-calculation`)
      })
    }
  }
    resetForm = () => { 
        for (var i = 0; i < Object.keys(RequiredFields).length; i++) {
            var ele = document.getElementById("prefilled_fields"+i) as HTMLInputElement;
            ele.checked = false;
        }

        this.setState({
            form: {
                ...this.state.form,
                name: '',
                email: '',
                prefilled_fields:'',
                logo:'',
                is_special_company : 0,
            },
            perviewimage:'',
        });
    }


  render() {
    const form: Array<FormFields> = Form;
    type K1 = keyof DataSchema;
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'add_company'}>
          <form className="kt-form" onSubmit={this.handleSubmit} id={'create_company'}>
            <div className="kt-portlet__body">
              <div className={'row'}>
                {form.map((item : FormFields, index) => {
                 return (
                    <div className={"col-md-6 col-lg-6"} key={index}>
                      <div className="form-group">
                        <label>{Lang.getString(item.name)} <span className={'required'}>*</span></label>
                        <input type={item.type !== undefined ? item.type : 'text'} onChange={this.handleChange} value={this.state.form[item.name as K1]} name={item.name} className="form-control" placeholder={Lang.getString(`${item.name}_placeholder`)}/>
                        {item.name === 'email' &&
                        <span className="form-text text-muted">{Lang.other_emails_hint}</span>
                        }
                      </div>
                     
                    
                    </div>
                     
                  );
                })}
                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                   <label>{Lang.logo_upload}:</label>
                     <div></div>
                     <div className="custom-file">
                     <input accept="image/*" onChange={(e)=>this.uploadProfilePic(e)} className="input-file-trigger" type="file" name="logo" id="profile_pic"/>
                       <label className="custom-file-label" htmlFor="profile_pic"/> 
                      </div>
                  
                    <p className="file-return"/>
                     </div>
                     { this.state.perviewimage !== '' &&
                      <div className="form-group">
                      <img src={this.state.perviewimage} width="60%" height="60%"/>
                       </div>  
                     }
                     </div>
                <div className={"col-md-12 col-lg-12"}>
                  <div className="form-group">
                    <label>Required Fields For Customers<span className={'required'}>*</span></label><br/>
                    {Object.keys(RequiredFields).map((item, index) => {
                      return (
                        <label className={"kt-checkbox kt-checkbox--brand" } key={index} style={{marginLeft: 20}} >
                          <input type={'checkbox'} name={'prefilled_fields'} value={item} onChange={this.handleChange}  id={'prefilled_fields'+index}/>
                          { RequiredFields[item] }
                          <span/>
                          &nbsp;
                        </label>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className='reset-btn-section'>
                <CommonSubmitButton/>
                <button className={'btn btn-default hvr-rectangle-out btnMove reset-btn'} data-skin={'dark'} title={Lang.reset} onClick={this.resetForm}>{Lang.reset}</button>
            </div>


          </form>
          <EditProfileImgPopup />
        </CreateLayout>
        
    )
  }
}