import * as React from 'react';
import {companySetup, IState} from "./Data";
import Lang from "../../common/lang/Lang";
import {CustomerFormDefaultVal} from "./Model";
import API from './../../common/AxoisClient';
import * as _ from 'lodash';
import {RouteComponentProps} from "react-router";
import {DepartmentModel, DepartmentUnitModel} from "../../model";
import {getDept, getDeptUnit} from "../../helpers/UserHelper";
import {CommonSubmitButton,InlineLoader, Select, showNotification, SwitchLoader, getMapContent, GooglePlace, LoaderType, CountryFlags, Select2Wrapper, SearchSelect, FormSearchSelect} from '../../common';
import {getCustomerInfo, isSpecialCompany, LanguageAndCompanyList} from "../../services";
import {CustomerValidations} from "./Validations";
import { cloneDeep, isEmpty, set } from 'lodash';
import CustomerEmailInput, { appendCompanyDomain } from './components/CustomerEmailInput/CustomerEmailInput';


export class CustomerEdit extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props:any){
    super(props);
    this.state={
      form: CustomerFormDefaultVal,
      is_special_company: false,
      id: props.match.params.id,
      department_units: [],
      is_prefilled: false,
      is_department_show: false,
      countries:[],
      countrycodes:[],
      is_ean_found: false,
      is_cvr_found: false,
      is_contact_found: false,
      companies: [],
      dept: [],
      units:[],
    }
    CustomerValidations();
  }

  async getEanOfDeptUnit(dept_id: number) {
    const dept_unit: DepartmentUnitModel = await (await API.get(`settings/department-unit/${dept_id}`)).data;
    return dept_unit.ean_number;
  }

  componentDidMount() {
    getCustomerInfo(this.state.id).then(async res => {
       const user_data = res;
       let is_special_company = user_data.company.is_special_company === 1;
        let user_data_state = {
          name: user_data.name,
          email: user_data.email,
          customer_type: user_data.customer.customer_type,
          address: user_data.address,
          gender: user_data.gender,
          country_code: user_data.country_code,
          profile_image: user_data.profile_image,
          status: user_data.status,
          zip_code: user_data.zip_code,
          city: user_data.city,
          mobile_no: user_data.mobile_no,
          order_contact_phone: user_data.customer.order_contact_phone ? user_data.customer.order_contact_phone: user_data.mobile_no,
          company_id: user_data.company.name,
          contact_person: user_data.customer.contact_person,
          contact_phone: user_data.customer.contact_phone,
          contact_phone_2: user_data.customer.contact_phone_2,
          ean_number: user_data.customer.ean_number,
          cpr_number: user_data.customer.cpr_number,
          department_id: is_special_company ? String(user_data.customer.department_id) : (user_data.customer.department)?user_data.customer.department.name:'',
          department_unit_id: user_data.customer.department_unit_id?String(user_data.customer.department_unit_id):'',
          economics_department_unit_id: user_data.customer.economics_department_unit_id?user_data.customer.economics_department_unit_id:'',
          identification_code: user_data.customer.identification_code,
          is_convert_to_public: user_data.customer.is_convert_to_public,
          converted_to_company_id: user_data.customer.converted_to_company_id ? user_data.customer.converted_to_company_id: 0,
          converted_to_department_id: user_data.customer.converted_to_department_id ? user_data.customer.converted_to_department_id: 0,
          converted_to_department_unit_id: user_data.customer.converted_to_department_unit_id ? user_data.customer.converted_to_department_unit_id: 0,
          converted_to_ean_number: (await this.getEanOfDeptUnit(user_data.customer.converted_to_department_unit_id)) || user_data.customer.ean_number,
        };
        const {state, required_fields} = companySetup.specialCompany(user_data.company, user_data_state, is_special_company, true);
      this.setState(state);
      this.checkSpecialCompany();
      if(is_special_company){
        companySetup.readOnly(this, required_fields, true);
      }

      if(user_data.customer.ean_number && user_data.customer.economics_department_unit_id){
        this.setState({is_cvr_found: true});
      }

      if(user_data.customer.cpr_number && user_data.customer.contact_phone_2){
        this.setState({is_contact_found: true});
      }

      LanguageAndCompanyList().then(res => {
        let makeCompanyArr: any = [];
        makeCompanyArr = res.companies;
        makeCompanyArr.push({label:'Silkeborg Kommune',value:'66',id:66});
        this.setState({
          companies: makeCompanyArr,
        }, function() {
          if(Number(this.state.form.converted_to_company_id) !== 0 && Number(this.state.form.converted_to_company_id) !== 66 && res.companies){
          const dept: any = getDept(res.companies, Number(this.state.form.converted_to_company_id));
          this.setState({
            dept: dept,
            units: getDeptUnit(dept, Number(this.state.form.converted_to_department_id))
          })
        }
        });
      });

    })

    API.get("users/customers/countries").then(res => {
      this.setState({
          countries: res.data.countryArr,
          countrycodes: res.data.countryCodeArr
      }, () => {
          ($(".selectpicker") as any).selectpicker('refresh');
      });
    });
  }

 

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if(name === "customer_type"){
      let resetObj:any = {
        is_prefilled: false,
        is_special_company: false,
        is_ean_found: false,
        is_cvr_found: false,
        is_contact_found: false,
        form: { ...this.state.form,
          customer_type: value,
          //email: '',
          //company_id: '',
          department_id: '',
          department_unit_id: '',
          economics_department_unit_id: '',
          ean_number: '',
          //cpr_number: ''
        },
        departments: [],
        department_units: []
      };
      this.setState(resetObj);
    } else {
      this.setState({ form: { ...this.state.form, [name]: value, company_id_number: name === 'company_id' ? null : this.state.form.company_id_number} }); // what was I trying to do with the company_id_number here???
    }

    if(name === "department_id"){
      // this.setState({ department_units: []});
      if(value > 0){
        let units = this.state.departments.filter(dept => {
          return String(dept.id) === value;
        });
        if(units.length > 0 ){
          this.setState({ department_units: units[0].units});
        }        
      }
    }

    /*if(name === 'ean_number'){  
      this.setState({form: { ...this.state.form, ean_number: value}});
      SwitchLoader(LoaderType.INLINE);
      API.get(`users/customers/get-details-of-ean/${value}`).then(res2 => {
        this.setState({is_cvr_found:true});
        this.setState({form: { ...this.state.form, cpr_number: res2.data.UnitCVR, economics_department_unit_id: res2.data.UnitName}});
      });
    }*/

    if(name === "department_unit_id"){
      if(value > 0){
        this.setState(companySetup.autoFill(this.state, value));
        SwitchLoader(LoaderType.INLINE);
        API.get(`/orders/department-unit-ean-number/${value}`).then(res => {
          this.setState({is_ean_found:true});
          this.setState({form: { ...this.state.form, ean_number: res.data.ean_number, mobile_no: res.data.mobile_no, order_contact_phone: res.data.mobile_no}});
          if(res.data.address && res.data.zip_code && res.data.city){
            let makeAddress = res.data.address+'+'+res.data.zip_code+'+'+res.data.city;
            SwitchLoader(LoaderType.INLINE);
            API.get(`users/customers/convert-address-to-geocode/${makeAddress}`).then(res3 => {
              if(res3.data.geometry){
                let postal_code_content = _.filter(res3.data.address_components, (item) => {
                  return _.indexOf(item.types, 'postal_code') > -1
                });

                let city_content = _.filter(res3.data.address_components, (item) => {
                  return _.indexOf(item.types, 'locality') > -1 && _.indexOf(item.types, 'political') > -1
                });

                let address = res3.data.formatted_address;
                let zip_code =  postal_code_content[0] !== undefined ? postal_code_content[0]['long_name'] : '';
                let city = city_content[0] !== undefined ? city_content[0]['long_name'] : '';
                this.setState(prevState => ({form: {...prevState.form, ...{ lat:res3.data.geometry.location.lat,
                  lng:res3.data.geometry.location.lat,
                  address: address,
                  zip_code: zip_code, city: city}}}as any
                ));
              } else {
                this.setState(prevState => ({form: {...prevState.form, ...{ lat:'',
                  lng:'',
                  address: '',
                  zip_code: '', city: ''}}}as any
                ));
              }
            });  
          }
          
          SwitchLoader(LoaderType.INLINE);
          API.get(`users/customers/get-details-of-ean/${res.data.ean_number}`).then(res2 => {
            this.setState({is_cvr_found:true});
            this.setState({form: { ...this.state.form, cpr_number: res2.data.UnitCVR, economics_department_unit_id: res2.data.UnitName}});
          });
        });
      }
    }

    // show dept filter and set data for it
    if(name === "converted_to_company_id"){
      const {companies} = this.state;
      if(Number(value)!==66){
        this.setState({dept: getDept(companies, Number(value)), units: [] });
      }
    }

    // show dept unit filter and set data for it.

    if(name === "converted_to_department_id"){
      this.setState({units: getDeptUnit(this.state.dept, Number(value)) });
    }

    if(name === "converted_to_department_unit_id") {
      // Setting the new EAN number to the dept unit EAN number
      const converted_to_ean_number = String((this.state.units as DepartmentUnitModel[]).find(unit => unit.id === Number(value)).ean_number);
      this.setState({ form: { ...this.state.form, converted_to_department_unit_id: value, converted_to_ean_number } })
    }

    if(name === "is_convert_to_public"){
      if(target.checked === true){
        this.setState({ form: { ...this.state.form, 'is_convert_to_public': 1} });
      } else {
        this.setState({ form: { ...this.state.form, 'is_convert_to_public': 0} });
      }
    }
  }

  makeFormNonSpecialCompany = () => {
    companySetup.readOnly(this, this.state.old_fields, false)
    this.setState(companySetup.blank(this.state.old_fields, this.state.form))
  }

  makeFormSpecialCompany = ({ state, required_fields }: ReturnType<typeof companySetup.specialCompany>) => {
    this.setState(state)
    companySetup.readOnly(this, required_fields, true)
  }

  checkSpecialCompany = async (e?: React.FocusEvent<HTMLInputElement>) => {
    const email = e ? e.target.value : this.state.form.email
    const special_company = await isSpecialCompany(email)
    if (isEmpty(special_company)) this.makeFormNonSpecialCompany()
    else this.makeFormSpecialCompany(companySetup.specialCompany(
        special_company, 
        set(cloneDeep(this.state.form), 'email', appendCompanyDomain(this.state.form.email, special_company.email)),
        true
      ))
  }
  
  checkEanDetails = () => {
    if(this.state.form.ean_number !== ''){
      SwitchLoader(LoaderType.INLINE);
      API.get(`users/customers/get-details-of-ean/${this.state.form.ean_number}`).then(res2 => {
        this.setState({is_cvr_found:true});
        this.setState({form: { ...this.state.form, cpr_number: res2.data.UnitCVR, economics_department_unit_id: res2.data.UnitName}});
      });
    } else {
      this.setState({is_cvr_found:false});
    }
  }
  
  checkCvrDetails = () => {
    if(this.state.form.customer_type!==1 && this.state.form.customer_type!=='1' && this.state.form.cpr_number!==''){
      this.setState(prevState => ({form: {...prevState.form, ...{ lat:'',
      lng:'',
      address: '',
      zip_code: '', city: ''}}}as any
      ));
      SwitchLoader(LoaderType.INLINE);
      API.get(`users/customers/get-details-of-cvr/${this.state.form.cpr_number}`).then(res2 => {
        this.setState({is_contact_found: true});
        this.setState({form: { ...this.state.form, company_id: res2.data.name ?? '', contact_phone_2: res2.data.phone ?? ''}});
        let makeAddress = res2.data.address+'+'+res2.data.zipcode+'+'+res2.data.city;
        SwitchLoader(LoaderType.INLINE);
        API.get(`users/customers/convert-address-to-geocode/${makeAddress}`).then(res3 => {
          if(res3.data.geometry){
            let postal_code_content = _.filter(res3.data.address_components, (item) => {
              return _.indexOf(item.types, 'postal_code') > -1
            });
            
            let city_content = _.filter(res3.data.address_components, (item) => {
              return _.indexOf(item.types, 'locality') > -1 && _.indexOf(item.types, 'political') > -1
            });
            
            let address = res3.data.formatted_address;
            let zip_code =  postal_code_content[0] !== undefined ? postal_code_content[0]['long_name'] : '';
            let city = city_content[0] !== undefined ? city_content[0]['long_name'] : '';
            this.setState(prevState => ({form: {...prevState.form, ...{ lat:res3.data.geometry.location.lat,
              lng:res3.data.geometry.location.lat,
              address: address,
              zip_code: zip_code, city: city}}}as any
              ));
            } else {
              this.setState(prevState => ({form: {...prevState.form, ...{ lat:'',
              lng:'',
              address: '',
              zip_code: '', city: ''}}}as any
              ));
            }
          });
        });
      } else {
        this.setState({is_contact_found: false});
      }
    }
    
    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      SwitchLoader(LoaderType.BUTTON);
      if($("#customer_form").valid()) {
        API.put(`users/customers/${this.state.id}`, this.state.form).then((res) => {
          showNotification("success", res.data.message);
          this.props.history.push(`/users/customers`)
        });
      }
    }
    
    googleGetAddress = (place) => {
      const {address, city, lat, lng, zip_code} =  getMapContent(place);
      this.setState(prevState => ({form: {...prevState.form, ...{ lat:lat,
        lng:lng,
        address: address,
        zip_code: zip_code, city: city}}}as any));
      }
      
      render() {
        return (
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet kt-portlet--mobile">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">
                      {Lang.edit_customer}
                    </h3>
                  </div>
                </div>
                <br/>
                <form className="kt-form" id={'customer_form'} onSubmit={this.handleSubmit}>
                  <div className="kt-portlet__body">
                    <div className={'row'}>
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <label>{Lang.customer_type} <span className={'required'}>*</span></label>
                          <Select onChange={this.handleChange} value={this.state.form.customer_type || ''} id={'customer_type'} name={'customer_type'} className="form-control" >
                                <option value={''}>{Lang.select_customer_type}</option>
                                <option value="1">Public</option>
                                <option value="2">Private</option>
                          </Select>
                        </div>
                      </div>
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <label>{Lang.name} <span className={'required'}>*</span></label>
                          <input type={'text'} onChange={this.handleChange} value={this.state.form.name || ''} name={'name'} className="form-control" placeholder={Lang.getString(`name_placeholder`)}/>
                        </div>
                      </div>
                      {this.state.form.customer_type==1 &&
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <InlineLoader>
                              <label>{Lang.ean_number} <span className={'required'}>*</span></label>
                            </InlineLoader>
                            <input type={'text'} onChange={this.handleChange} onBlur={this.checkEanDetails} readOnly={this.state.is_ean_found} value={this.state.form.ean_number || ''} ref={input => {this[`ean_number`] = input; }} name={'ean_number'} className="form-control" placeholder={Lang.getString(`ean_number_placeholder`)}/>
                          </div>
                        </div>
                      }
                      {(this.state.form.customer_type==1 || this.state.form.customer_type==2) &&
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <InlineLoader>
                              <label>{Lang.cvr_number} <span className={'required'}>*</span></label>
                            </InlineLoader>
                            <input type={'text'} onChange={this.handleChange} onBlur={this.checkCvrDetails} readOnly={this.state.is_cvr_found} value={this.state.form.cpr_number || ''} ref={input => {this[`cpr_number`] = input; }} name={'cpr_number'} className="form-control" placeholder={Lang.getString(`cvr_number_placeholder`)}/>
                          </div>
                        </div>
                      }

                      {(this.state.form.customer_type==1 || this.state.form.customer_type==2) && this.state.is_cvr_found &&
                        <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group">
                            <label>{Lang.other_department_unit}</label>
                            <input type={'text'} readOnly={true} value={this.state.form.economics_department_unit_id || ''} ref={input => {this[`economics_department_unit_id`] = input; }} name={'economics_department_unit_id'} className="form-control"/>
                          </div>
                        </div>
                      }

                      <CustomerEmailInput
                        value={this.state.form.email || ''}
                        handleChange={this.handleChange}
                        checkSpecialCompany={this.checkSpecialCompany}
                        makeFormSpecialCompany={this.makeFormSpecialCompany}
                        form={this.state.form}
                      />

                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group ">
                            <label>{Lang.order_contact_number} </label>
                            <input type={'text'} onChange={this.handleChange} value={this.state.form.order_contact_phone || ''}  ref={input => {this[`order_contact_phone`] = input; }} name={'order_contact_phone'}  className="form-control" placeholder={Lang.getString(`order_contact_placeholder`)}/>
                        </div>
                      </div>

                      <div className={"col-md-6 col-lg-6"}>
                            <div className="form-group">
                                <label>{Lang.company} <span className={'required'}>*</span></label>
                                <input type={'text'} onChange={this.handleChange} readOnly={this.state.is_special_company} value={this.state.form.company_id} name={'company_id'} className="form-control" placeholder={Lang.getString(`company_id_placeholder`)}/>
                            </div>
                        </div>

                        {this.state.is_special_company && this.state.is_department_show == true &&
                        <div className={"col-md-6 col-lg-6"}>
                            <div className="form-group">
                                <label>{Lang.department} <span className={'required'}>*</span></label>
                              
                              <Select onChange={this.handleChange} value={this.state.form.department_id || ''} id={'department_id'} name={'department_id'} className="form-control" >
                                <option value={''}>{Lang.select_dept}</option>
                                {this.state.departments.sort((a,b) => a.name > b.name? 1 : b.name > a.name? -1 : 0)
                                  .map((item: DepartmentModel, index) => {
                                  return <option key={index} value={item.id}>{item.name}</option>
                                })}
                              </Select>
                            </div>
                          </div>
                        }

                        { !this.state.is_special_company && 
                        <div className={"col-md-6 col-lg-6"}>
                            <div className="form-group">
                                <label>{Lang.department} <span className={'required'}>*</span></label>                              
                                <input type={'text'} id={'department_id'} onChange={this.handleChange} value={this.state.form.department_id || ''} name={'department_id'} className="form-control" placeholder={Lang.getString(`department_id_placeholder`)}/>
                             
                            </div>
                        </div>
                        }

                      {this.state.is_special_company &&
                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <InlineLoader>
                            <label>{Lang.department_unit} <span className={'required'}>*</span></label>
                          </InlineLoader>
                          <Select onChange={this.handleChange} value={this.state.form.department_unit_id || ''} name={'department_unit_id'} className="form-control" >
                            <option value={''}>{Lang.select_dept_unit}</option>
                            {this.state.department_units.sort((a,b) => a.name > b.name? 1 : b.name > a.name? -1 : 0)
                              .map((item: DepartmentUnitModel, index) => {
                              return <option key={index} value={item.id}>{item.name}</option>
                            })}
                          </Select>
                        </div>
                      </div>
                      }


                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group">
                          <label>{Lang.address} <span className={'required'}>*</span></label>
                          {!this.state._address &&
                            <GooglePlace onChooseAddress={this.googleGetAddress} onChange={(e) => {this.setState({...this.state, form:{ ...this.state.form, city: '', zip_code: '', lat: '', lng: ''}})}} name={'address'} defaultValue={this.state.form.address || ''} className="form-control" placeholder={Lang.getString(`address_placeholder`)} />
                          }
                          {this.state._address &&
                            <GooglePlace onChooseAddress={this.googleGetAddress} onChange={(e) => {this.setState({...this.state, form:{ ...this.state.form, city: '', zip_code: '', lat: '', lng: ''}})}} name={'address'} defaultValue={this.state.form.address || ''} className="form-control" placeholder={Lang.getString(`address_placeholder`)} />
                          }
                          { this.state.form.address &&
                            <span>
                              City: <strong>{this.state.form.city || <span style={{color:'red'}}>&lt;NONE&gt;</span>}</strong> |&nbsp;
                              ZIP Code (Postal Code): <strong>{this.state.form.zip_code || <span style={{color:'red'}}>&lt;NONE&gt;</span>}</strong>
                            </span>
                          }
                          <input type='hidden' id="zip_code" onChange={this.handleChange} value={this.state.form.zip_code || ''}  ref={input => {this[`zip_code`] = input; }} name={'zip_code'} className="form-control" placeholder={Lang.getString(`zip_code_placeholder`)}/>
                          <input type='hidden' id="city" onChange={this.handleChange} value={this.state.form.city || ''} ref={input => {this[`city`] = input; }} name={'city'} className="form-control" placeholder={Lang.getString(`city_placeholder`)}/>
                        </div>
                      </div>

                      <div className={"col-md-6 col-lg-6"}>
                        <div className="form-group ">
                            <label>{Lang.telephone_number} <span className={'required'}>*</span></label>
                            <div className={"row"}>
                                <div className={"col-md-4 col-lg-4"}>
                                    <Select2Wrapper   onChange={this.handleChange}
                                                    className={'form-control'} value={this.state.form.country_code || ''} id={'country_code'} name={'country_code'}
                                                    data={{
                                                        placeholder: "Select Options",
                                                        data: this.state.countrycodes,
                                                        templateResult: CountryFlags,
                                                        templateSelection: CountryFlags,
                                                    }}
                                    />
                          
                                </div>
                                <div className={"col-md-8 col-lg-8"}>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.mobile_no || ''} ref={input => {this[`mobile_no`] = input; }} name={'mobile_no'} className="form-control" placeholder={Lang.getString(`mobile_placeholder`)}/>
                                </div>
                            </div>
                        </div>
                       </div>

                      {this.state.form.contact_phone!=null &&  
                         <div className={"col-md-6 col-lg-6"}>
                          <div className="form-group ">
                              <label>{Lang.contact_phone} <span className={'required'}>*</span></label>
                              <div className={"row"}>
                                  <div className={"col-md-4 col-lg-4"}>
                                      <Select2Wrapper   onChange={this.handleChange}
                                                      className={'form-control'} value={this.state.form.country_code || ''} id={'contact_country_code'} name={'contact_country_code'}
                                                      data={{
                                                          placeholder: "Select Options",
                                                          data: this.state.countrycodes,
                                                          templateResult: CountryFlags,
                                                          templateSelection: CountryFlags,
                                                      }}
                                      />
                            
                                  </div>
                                  <div className={"col-md-8 col-lg-8"}>
                                  <input type={'text'} onChange={this.handleChange} value={this.state.form.contact_phone || ''} ref={input => {this[`contact_phone`] = input; }} name={'contact_phone'} className="form-control" placeholder={Lang.getString(`mobile_placeholder`)}/>
                                  </div>
                              </div>
                          </div>
                         </div>
                       }

                       { this.state.is_contact_found &&
                          <div className={"col-md-6 col-lg-6"}>
                            <div className="form-group ">
                                <label>{Lang.telephone_number2}</label>
                                <div className={"row"}>
                                    <div className={"col-md-8 col-lg-8"}>
                                    <input type={'text'} value={this.state.form.contact_phone_2 || ''}  readOnly={true} ref={input => {this[`contact_phone_2`] = input; }} name={'contact_phone_2'}  className="form-control" placeholder={Lang.getString(`telephone_number2`)}/>
                                    </div>
                                </div>
                            </div>
                          </div>
                        }
                    </div>
                  </div>
                  <input type={'hidden'} id={'is_special_company'} value={this.state.is_special_company ? 1 : 0}/>
                  
                  <CommonSubmitButton/>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}