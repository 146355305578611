import * as React from 'react';
import {DataSchema} from "./Model";
import {SearchInput, SearchSelect} from "../../common/form/SearchInput";
import {IState as CommonState} from "../../common/State";
import {ShowRecords} from "../../common/ShowRecords";
import {default as Lang} from "../../common/lang/Lang"
import {CONST, ExportButton, getQueryStringParams} from "../../common/Common";
import {Icons} from "../../common/Icon";
import {ListBreadcrumb} from "./Data";
import {actionButton} from "../../common/table/BlukActionButtons";
import {RouteComponentProps} from "react-router";
import { CreateLink, EditLink} from "../../common/page/CreateButton";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {HolidayModel} from "../../model";
import HolidayFilter from './filter/HolidayFilter';
import _ from 'lodash';
import {list_key} from "../../helpers/OrderHelper";

const moment = require("moment");

interface IState extends CommonState{
  UserData: Array<DataSchema>;
  form: {
    like: {
      name: string,
      on_date: string
    },
    equal: {
      status: number
    }
  }
}


export class HolidayListing extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

 

  rowActionButtons = (data: HolidayModel) => {
    return (
      <EditLink scope={'holiday.update'} to={`${this.props.match.url}/edit/${data.id}`} />
    )
  }

  showRecords = (data:HolidayModel, index:number) => {
     let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("id", index),
      this.records.current.createdTd("name", data.name),
      this.records.current.createdTd("on_date", moment(data.on_date).format("DD-MM-YYYY")),
      this.records.current.createdTd("status", () => {
        if(data.status === 1){
          return <span className={'badge badge-primary'}>{Lang.active}</span>
        }
        if(data.status === 2){
          return <span className={'badge badge-warning'}>{Lang.inactive}</span>
        }
      }),
      this.records.current.createdTd("created_at", data.created_at),
      this.records.current.createdTd("action", () => {
        return this.rowActionButtons(data);
      })
    ]);
    cols = _.compact(cols);
    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  render() {
    const status = CONST.status;
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'holiday'} icon={Icons.users} >
            <ExportButton records={this.records} fileName={Lang.exportHoliday}/>
            &nbsp;
            <CreateLink to={`${this.props.match.url}/create`} scope={'holiday.store'} />
          </ListHeader>

          <ListForm
            form={(
              <HolidayFilter status={status}  getResults={this.filterRecords} />
              
            )}
            table={(
              <ShowRecords
                url={"settings/holiday"}
                showRecords ={this.showRecords}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "settings/holiday/bulk-action", module: 'holiday'})}
              />
            )}
          />
        </ListingLayout>
    )
  }
}