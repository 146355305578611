import React from 'react'

// styles
import sheet from './CurrentJob.module.scss'

const NoCurrentJob = () => {
  return (
    <div className={sheet.empty_container}>Ingen fakturering er i gang.</div>
  )
}

export default NoCurrentJob