import * as React from 'react';
import Lang from "../../../common/lang/Lang";
import * as _ from 'lodash';
import {get_order_gender_and_color} from "../../../helpers/OrderHelper";
 
export const OrderPreferenceInfo = (props?: any) => {
   const {gender} = get_order_gender_and_color(props.info)
return (
    <div className="col-xl-4">
    <div className="kt-portlet kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title kt-font-success">
             {Lang.preference}
          </h3>
        </div>               
      </div>
      <div className="kt-portlet__body">
        <div className="kt-widget4">
        { !_.isEmpty(props.info.specific_translator) &&
          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
               {Lang.specific_interpreter}
            </span>
            <span className="kt-widget4__number kt-font-info">{props.info.specific_translator.name}</span>
          </div>
         }
         { !_.isEmpty(props.info.notspecific_translator) &&
          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
               {Lang.unwanted_interpreter}
            </span>
            <span className="kt-widget4__number kt-font-info">{props.info.notspecific_translator.name}</span>
          </div> 
        }
        { ! _.isNil(props.info.specific_translator_id) &&
        <>
        {props.info.is_talk_trans == 1 && 
        <>
        <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
               {Lang.talk_translator}
            </span>
            <span className="kt-widget4__number kt-font-info">{props.info.is_talk_trans == 1 ? Lang.yes : Lang.no}</span>
          </div>
           
         {/* {props.info.is_assign_other == 1 &&
          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
               {Lang.another_available_translator}
            </span>
            <span className="kt-widget4__number kt-font-info">{props.info.is_assign_other == 1 ? Lang.yes : Lang.no}</span>
          </div> 
        }
         */}
          </>
        }

      {props.info.is_talk_trans == 0 && 
        <>
        <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
               {Lang.translator_busy}
            </span>
            
          </div>
           
         { props.info.is_talk_trans == 0 && props.info.is_assign_other == 1 &&
          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
               {Lang.interpreter_free}
            </span>
            <span className="kt-widget4__number kt-font-info">{props.info.is_assign_other == 1 ? Lang.yes : Lang.no}</span>
          </div> 
        }
        {props.info.is_talk_trans == 0 &&  props.info.is_assign_other == 2 &&
          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
               {Lang.interpreter_available}
            </span>
            <span className="kt-widget4__number kt-font-info">{props.info.is_assign_other == 2 ? Lang.yes : Lang.no}</span>
          </div>
        }
          </>
        }
          </> 
          }
           { _.isNil(props.info.specific_translator_id) &&
          <div className="kt-widget4__item">                   
            <span className="kt-widget4__title kt-widget4__title--light">
               {Lang.interpreter_gender}
            </span>
            <span className="kt-widget4__number kt-font-info">{gender == "" ? Lang.no_significance : gender}</span>
          </div>  
         }   
         { ! _.isEmpty(props.info.comment_for_translator) &&
                  <div className="kt-widget4__item customtextalign">                 
                    <span className="kt-widget4__title kt-widget4__title--light">
                      {Lang.translator_comment}
                    </span>
                    <span className="kt-widget4__number kt-font-info">{props.info.comment_for_translator}</span>
                  </div>
         }
          {
           !_.isEmpty(props.info.office_note) &&
             <div className="kt-widget4__item customtextalign">
              <span className="kt-widget4__title kt-widget4__title--light">
                 {Lang.notes_office}
              </span>
               <span className="kt-widget4__number kt-font-info">{props.info.office_note}</span>
             </div>
          }

      </div>
      </div>
    </div>
  </div>     

  )
}