import { ErrorMessage, Field } from 'formik';
import React from 'react';

const CheckboxInput = (props) => {
    const { label, name, divProps, required, ...fieldProps } = props;
    
    return (
        <div
            className="form-group col-lg-12"
            {...divProps}
        >
            <Field
                type="checkbox"
                name={name}
                id={name}
                {...fieldProps}
            />
            <label className="form-check-label" htmlFor={name}>
                {label}
                { required && (<span className={"required"}>*</span>) }
            </label>
            <ErrorMessage render={msg => <div className="text-danger">{msg}</div>} name={name} />
        </div>
    );
}

export default CheckboxInput;
