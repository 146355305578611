import React from 'react';

export function TranslatorGender(state: any) {
  const busy = state.is_busy === 1 ? ' ( Busy ) ' : '';
  const unavailable = state.on_holiday === 1 ? ' ( Unavailable ) ' : '';
  if(state.gender === '1'){
    return $('<span><i class="fa fa-male text-primary"/> ' + state.text + busy + unavailable +'</span>');
  } else if(state.gender === '2'){
    return $('<span><i class="fa fa-female text-danger  "/> ' + state.text + busy + unavailable +'</span>');
  } else {
    return state.text;
  }
}

export function ShowTranslatorGender(state: any) {
  /*console.log("sd");*/
  if(state.gender === '1'){
    return <i className="fa fa-male text-primary"/>
  } else if(state.gender === '2'){
    return <i className="fa fa-female text-danger"/>;
  } else {
    return null;
  }
}