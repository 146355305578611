import Lang from "./lang/Lang";
import { Icons } from "./Icon";

export interface MenuTitleSchema {
  title: string;
  icon: string;
  link?: string;
  scope?: string | Array<string>;
  singlemenu?: number;
  menu?: Array<MenuSchema>;
}

export interface MenuSchema {
  label: string;
  link?: string;
  icon?: string;
  scope?: string;
}

export const menu = [
  {
    title: Lang.users,
    icon: Icons.users2,
    scope: ["customers", "admin-users", "translator"],
    menu: [
      { label: Lang.customers, link: "/users/customers", scope: "customers" },
      {
        label: Lang.admin_users,
        link: "/users/admin-users",
        scope: "admin-users",
      },
      {
        label: Lang.translators,
        link: "/users/translators",
        scope: "translator",
      },
    ],
  },
  {
    title: Lang.translators,
    icon: Icons.translators,
    scope: ["translator"],
    menu: [
      {
        label: Lang.all_translators,
        link: "/translators/all",
        scope: "translator",
      },
      {
        label: Lang.applicant_translators,
        link: "/translators/applicants",
        scope: "translator",
      },
      {
        label: Lang.active_translators,
        link: "/translators/active",
        scope: "translator",
      },
      {
        label: Lang.inactive_translators,
        link: "/translators/inactive",
        scope: "translator",
      },
    ],
  },
  {
    title: Lang.orders,
    icon: Icons.order,
    scope: [
      "orders",
      "orders.complete-orders-list",
      "orders.cancel-orders-list",
      "claim",
      "orders.request-change-cancel",
    ],
    menu: [
      {
        label: Lang.active_orders,
        link: "/orders/orders",
        scope: "orders.index",
      },
      {
        label: Lang.complete_orders,
        link: "/orders/complete-orders",
        scope: "orders.complete-orders-list",
      },
      {
        label: Lang.cancel_orders,
        link: "/orders/cancel-orders",
        scope: "orders.cancel-orders-list",
      },
      {
        label: Lang.request_change,
        link: "/orders/request-change-cancel",
        scope: "orders.request-changes-cancel",
      },
      {
        label: 'Invoicing',
        link: '/orders/invoicing',
        scope: 'orders.complete-orders-list'
      },
      { label: Lang.claims, link: "/orders/claims", scope: "claim" },
    ],
  },
  {
    title: Lang.payout,
    icon: Icons.dollor,
    scope: ["payouts-pending", "payouts-complete", "payouts-paid"],
    menu: [
      {
        label: Lang.pending_payout,
        link: "/payouts/pending",
        scope: "payouts-pending",
      },
      {
        label: Lang.complete_payout,
        link: "/payouts/completed",
        scope: "payouts-complete",
      },
      { label: Lang.paid_payout, link: "/payouts/paid", scope: "payouts-paid" },
    ],
  },
  {
    title: Lang.learn,
    icon: Icons.question,
    scope: ["we-learn", "online-test", "quiz"],
    menu: [
      { label: Lang.we_learn, link: "/learn/we-learn", scope: "we-learn" },
      {
        label: Lang.online_test,
        link: "/learn/online-test",
        scope: "online-test",
      },
      { label: Lang.quiz_title, link: "/learn/quiz", scope: "quiz" },
    ],
  },
  {
    title: Lang.settings,
    icon: Icons.setting,
    scope: [
      "location",
      "holiday",
      "company",
      "department",
      "department-unit",
      "email-template",
      "sms-template",
      "language",
      "common-setting",
      "customer-calculation",
    ],
    menu: [
      { label: Lang.location, link: "/settings/location", scope: "location" },
      { label: Lang.holiday, link: "/settings/holiday", scope: "holiday" },
      { label: Lang.company, link: "/settings/company", scope: "company" },
      {
        label: Lang.department,
        link: "/settings/department",
        scope: "department",
      },
      {
        label: Lang.department_unit,
        link: "/settings/department-unit",
        scope: "department-unit",
      },
      {
        label: Lang.email_templates,
        link: "/settings/email-templates",
        scope: "email-template",
      },
      {
        label: Lang.sms_templates,
        link: "/settings/sms-templates",
        scope: "sms-template",
      },
      {
        label: Lang.language,
        link: "/languages/language",
        scope: "language.index",
      },
      {
        label: Lang.common_setting,
        link: "/settings/common-setting",
        scope: "common-setting",
      },
      {
        label: Lang.customer_calculation,
        link: "/settings/customer-calculation/create",
        scope: "customer-calculation",
      },
    ],
  },
  {
    title: "Roles",
    icon: Icons.permission,
    link: "/permission/role",
    scope: "role",
    singlemenu: 1,
  },
  {
    title: Lang.newletter,
    icon: Icons.email,
    scope: ["email", "system-email"],
    menu: [
      { label: Lang.email, link: "/email/email", scope: "email.index" }, //{label: Lang.system_email,  link: '/email/system', scope: 'system-email'}, commented - 01-04-2020 permission issue
      {
        label: Lang.system_email,
        link: "/email/system",
        scope: "system-email.index",
      },
    ],
  },
  {
    title: Lang.sms,
    icon: Icons.sms,
    scope: ["sms", "system"],
    menu: [
      // {label: Lang.sms,         link: '/sms/sms', scope: 'sms'}, commented - 01-04-2020 permission issue
      { label: Lang.sms, link: "/sms/sms", scope: "sms.index" }, // {label: Lang.system_sms,  link: '/sms/system', scope: 'system-sms'}, commented - 01-04-2020 permission issue
      { label: Lang.system_sms, link: "/sms/system", scope: "system.index" },
    ],
  },
  {
    title: Lang.system_info,
    icon: Icons.view,
    scope: ["activity-log", "notification", "enquiries", "calllog"],
    menu: [
      {
        label: Lang.activity_logs,
        link: "/logs/activity-logs",
        scope: "activity-log",
      },
      {
        label: Lang.notification,
        link: "/systemInfo/notification",
        scope: "notification",
      },
      {
        label: Lang.enquiries,
        link: "/systemInfo/enquire",
        scope: "enquiries",
      },
      { label: Lang.call_log, link: "/systemInfo/calllog", scope: "calllog" },
    ],
  },
];
