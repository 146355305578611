import React, {useReducer} from 'react';
import {ClientData, OrderModel, OrderType} from "../../../model";
import _ from 'lodash';
import Lang from "../../../common/lang/Lang";
import {order_time, order_to_language} from "../../../helpers/OrderHelper";

// all actions for assign translator
export enum ActionType {
  SHOW_POPUP = 'SHOW_POPUP',
  HIDE_POPUP = 'HIDE_POPUP',
  HANDLE_CHANGE = 'HANDLE_CHANGE',
}

export interface TranslatorMailForm {
  is_send_mail: boolean;
  is_send_sms: boolean;
  sms_content: string;
  mail_content: string;
}

export interface PopupState  {
  show: boolean;
  order_data: OrderModel;
  order_id: number;
  form: TranslatorMailForm,
  copy_data: string;
}

export interface IAction {
  type: ActionType;
  payload?: PopupState
}

export interface PopupService{
  show: boolean;
  data?: OrderModel;
  action: ActionType;
}
export const initialPopupState: PopupState = {
  show: false,
  order_data: {} as OrderModel,
  order_id: 0,
  copy_data: '',
  form: {
    is_send_mail: true,
    is_send_sms: true,
    sms_content: '',
    mail_content: ''
  }
};




// reducer for assign send mail for order confirmation to translator popup

export function SendMailToTransReducer <PopupState, IAction> (state, action) {
  switch (action.type) {
    // show popup and set order data
    case ActionType.SHOW_POPUP:
      return {...state, show: true, order_data:action.payload, order_id: action.payload.id, form: setDefaultState(action.payload)};

    // hide popup and blank old state
    case ActionType.HIDE_POPUP:
      return {...state, ...initialPopupState};

    // handle change of mail drop-down
    case ActionType.HANDLE_CHANGE:
      const {name, value} = action.payload;
      return {...state, form: {...state.form, [name]: value}};

    default:
      throw new Error();
  }
}


export function setDefaultState(order_data: OrderModel) {
let copy_content = '', msg_content = '';
  if (!_.isEmpty(order_data)) {
    let meeting = '', meeting_msg = '';

    if (order_data.translation_type_id === OrderType.PHYSICAL) {
      meeting = '<b>' + Lang.meeting_address + '</b> - ' + order_data.meeting_address + ' ' + order_data.addition_info+ ' <br/>';
      meeting_msg = Lang.meeting_address + ' - ' + order_data.meeting_address + ' ' + order_data.addition_info;
    }
    
    function makeClientsString (order_data: OrderModel): string {
      return order_data.clients.map((client: ClientData, index: number) => {
        return `${index + 1}) ${client.client_name === ''? '----' : client.client_name}${index === order_data.clients.length - 1? '' : ' '}`;
      }).join('');
    }

      copy_content = `Hello {{NAME}}, <br/>
          <b>${Lang.translator_task}</b><br/><b>${Lang.order_no}</b> - ${order_data.order_no}<br/>
          <b>${Lang.date}</b> - ${order_data.order_date}<br/>
          <b>${Lang.time}</b> - ${order_time(order_data) }<br/>
          <b>${Lang.language}</b> - ${order_data.language_from.language_name}- ${order_to_language(order_data)}<br/>
          <b>${Lang.translation_type}</b> - ${order_data.translation_type.title}<br/>
          <b>${Lang.client}</b> - ${!_.isEmpty(order_data.clients)? makeClientsString(order_data) : '----'}<br/>
          <b>${Lang.company}</b> - ${order_data.company.name}<br/>
          <b>${Lang.department}</b> - ${order_data.department.name}<br/>
          <b>${Lang.contact_person}</b> - ${order_data.contact_persons[0].name}<br/>
          <b>${Lang.contact_phone}</b> - ${order_data.contact_persons[0].phone_no}<br/>
          ${meeting}`;

    msg_content = `${Lang.translator_task}\n${Lang.order_no} - ${order_data.order_no}\n${Lang.date} - ${order_data.order_date}
${Lang.time} - ${order_time(order_data) }
${Lang.language} - ${order_data.language_from.language_name}- ${order_to_language(order_data)}
${Lang.translation_type} - ${order_data.translation_type.title}
${Lang.client} - ${!_.isEmpty(order_data.clients)? makeClientsString(order_data) : '----'}
${Lang.company} - ${order_data.company.name}
${Lang.department} - ${order_data.department.name}
${Lang.contact_person} - ${order_data.contact_persons[0].name}
${Lang.contact_phone} - ${order_data.contact_persons[0].phone_no}
${meeting_msg}`;
  }

  return {
    is_send_mail: true,
    is_send_sms: true,
    sms_content: msg_content,
    mail_content: copy_content
  }
}